import { ButtonBase } from "@mui/material";
import { icons, palette } from "theme";
import Icon from "../Icons/Icon";
type CloseButtonProps = {
	onClick: (x?: React.MouseEvent) => any;
	top?: number;
	right?: number;
	left?: number;
	bottom?: number;
	styleType?: "big-blue" | "simple";
};
const CloseButton = (props: CloseButtonProps) => {
	let { onClick, styleType } = props;

	if (styleType) {
		if (styleType === "big-blue") {
			return <CloseButtonBigBlue {...props} />;
		} else if (styleType === "simple") {
			return <CloseButtonSimple {...props} />;
		}
	}
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				position: "absolute",
				top: "0rem",
				right: "0rem",
				height: "1rem",
				width: "1rem",
				borderRadius: "50%",
				color: palette.gray.darker,

				"& svg": {
					color: palette.gray.darker,
					fontSize: "1rem",
				},
			}}
		>
			<Icon fixedWidth icon={icons.close} />
		</ButtonBase>
	);
};

const CloseButtonBigBlue = ({ onClick }: CloseButtonProps) => {
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				position: "absolute",
				top: "1.25rem",
				right: "1rem",
				height: "2.5rem",
				width: "2.5rem",
				borderRadius: "50%",
				border: "2px solid",
				borderColor: palette.core.blue,
				color: palette.core.blue,
				boxShadow: 4,

				"& svg": {
					color: palette.gray.darker,
					fontSize: "1rem",
				},
			}}
		>
			<Icon fixedWidth icon={icons.close} />
		</ButtonBase>
	);
};

const CloseButtonSimple = ({ onClick }: CloseButtonProps) => {
	return (
		<ButtonBase
			onClick={onClick}
			sx={{
				height: "2rem",
				width: "2rem",
				borderRadius: "50%",
				color: palette.gray.dark,
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				"&:hover": { boxShadow: 4, cursor: "pointer" },
				"& svg": {
					color: palette.gray.dark,
					fontSize: "1rem",
				},
			}}
		>
			<Icon fixedWidth icon={icons.close} />
		</ButtonBase>
	);
};
export { CloseButton };
