/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API - Mobile
 * OpenAPI spec version: mobile
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerMobileGetConditionDetailMobileResult,
  ModelsClientLocation,
  GetConditionDetailMobileParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getConditionDetailMobile = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetConditionDetailMobileParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileGetConditionDetailMobileResult>(
      {url: `/api/consumer/ConsumerMobile_MedicalCode/GetConditionDetail_Mobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetConditionDetailMobileMutationResult = NonNullable<Awaited<ReturnType<typeof getConditionDetailMobile>>>
    export type GetConditionDetailMobileMutationBody = ModelsClientLocation
    export type GetConditionDetailMobileMutationError = unknown

    export const useGetConditionDetailMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getConditionDetailMobile>>, TError,{data: ModelsClientLocation;params?: GetConditionDetailMobileParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getConditionDetailMobile>>, {data: ModelsClientLocation;params?: GetConditionDetailMobileParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getConditionDetailMobile(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getConditionDetailMobile>>, TError, {data: ModelsClientLocation;params?: GetConditionDetailMobileParams}, TContext>(mutationFn, mutationOptions)
    }
    