import { Box, Button } from "@mui/material";
import { CSSProperties, ReactNode, useRef, useState } from "react";
import { CustomPopper } from "../DisplayElements/CustomPopper";

type OptionsButtonProps = {
	content: ReactNode;
	noDefaultBox?: boolean;
	buttonStyle?: CSSProperties;
	onClick?: () => any;
};
const OptionsButton = ({ content, noDefaultBox, buttonStyle, onClick }: OptionsButtonProps) => {
	const detailsRef = useRef<HTMLDivElement>();
	const [open, set_open] = useState<boolean>(false);

	if (noDefaultBox !== false) {
		content = <Box>{content}</Box>;
	}
	return (
		<>
			<Box className="view-options-btn">
				<Button
					onClick={() => {
						if (onClick) {
							onClick();
						}
						set_open(true); //transition this button fade
					}}
				>
					Options
				</Button>
				<Box ref={detailsRef}></Box>
			</Box>

			<CustomPopper
				anchorRef={detailsRef}
				set_open={(o: boolean) => {
					set_open(o);
				}}
				open={open}
			>
				{content}
			</CustomPopper>
		</>
	);
};

export { OptionsButton };
