import { Box, Stack, Typography, Avatar } from "@mui/material";

interface LegendRowProps {
  item: { name: string | number; value: string | number; [key: string]: any };
  color: any;
}

function LegendRow(props: LegendRowProps) {
  return (
    <Stack
      direction="row"
      width="100%"
      data-name="LegendRowMainStack"
      sx={{
        borderBottom: "2px solid",
        borderColor: "gray.main",
        pb: 2,
        pt: 2,
      }}
    >
      <Box>
        <Avatar
          sx={{
            color: props.color,
            borderColor: props.color,
            backgroundColor: "white",
          }}
        >
          {typeof props.item.value === "number"
            ? Math.round(props.item.value)
            : props.item.value}
        </Avatar>
      </Box>
      <Stack
        direction="column"
        width="100%"
        data-name="substack"
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          paddingLeft: 1,
          margin: 0,
          color: props.color,
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              color: props.color,
            }}
          >
            {props.item.name}
          </Typography>
        </Box>
        {props.item.description ? (
          <Typography component="p" sx={{ color: "gray.dark" }}>
            {props.item.description}
          </Typography>
        ) : null}
      </Stack>
    </Stack>
  );
}

export default LegendRow;
