import {
	ConsumerTreatmentSearchTreatmentsByQueryStringResponseModel,
	ConsumerTreatmentSearchTreatmentsByQueryStringResult,
	ModelsOperationStatus,
	searchTreatmentsByQueryString,
	SearchTreatmentsByQueryStringParams,
} from "api";
import { MedicationEntity } from "common/definitions/entity/MedicationEntity";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { useQuery, UseQueryResult } from "react-query";

interface SearchTreatmentsResponseData extends ConsumerTreatmentSearchTreatmentsByQueryStringResponseModel {
	medicationEntities: Array<MedicationEntity>;
}
interface SearchTreatmentsResponseModel {
	response: SearchTreatmentsResponseData;
}

async function retrieveSearchTreatments(_params: SearchTreatmentsByQueryStringParams): Promise<SearchTreatmentsResponseModel | ProcessedResponse<any>> {
	if (!_params.query) {
		return apiResponseReader.createProcessedResponse_NOREQUEST("Select a Condition to Search for Medications and Treatment Options");
	}
	let r = await searchTreatmentsByQueryString(_params).then(
		(data: ModelsOperationStatus | ConsumerTreatmentSearchTreatmentsByQueryStringResult): ProcessedResponse<any> | SearchTreatmentsResponseModel => {
			if (!apiResponseReader.typeCheck_ModelsOperationStatus(data)) {
				if (data.response) {
					let medicationEntities: Array<any>;
					if (data.response.searchResults) {
						if (Array.isArray(data.response.searchResults)) {
							medicationEntities = data.response.searchResults.map((x) => {
								return {
									entityTypeName: "medication",
									imageSrc: "",
									title: x.displayName,
									descriptor: x.isGeneric ? "Generic" : "Brand",
									displayInfo: [
										//	{ title: "Lowest Price", value: "Unknown", colorType: "Positive", dataType: "USD" }
									],
									actions: ["learn_more"],
									_medicationRecord: x,
								};
							});
							(data.response as CIM).medicationEntities = medicationEntities;
							return data as any as SearchTreatmentsResponseModel;
						}
					}
				}
			}
			return apiResponseReader.processResponse(data);
		}
	);
	console.debug(r);
	return r;
}

function useQ_retrieveSearchTreatments(params: SearchTreatmentsByQueryStringParams): UseQueryResult<SearchTreatmentsResponseModel | ProcessedResponse<any>> {
	return useQuery(["retrieveSearchTreatments", `retrieveSearchTreatments:${JSON.stringify(params)}`], () => retrieveSearchTreatments(params));
}

export { useQ_retrieveSearchTreatments };
export type { SearchTreatmentsResponseModel };
