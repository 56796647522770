import { Box, Grid, Stack, Typography } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { InfoPopper } from "common/components/DisplayElements/InfoPopper";
import { DashboardMenu } from "../../DashboardMenu";
import { HospitalSavings } from "./components/HospitalSavings";
import { CostOfCare } from "./components/CostOfCare";
import { MedicationSavings } from "./components/MedicationSavings";
import { ProviderSavings } from "./components/ProviderSavings";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useContext, useEffect, useMemo, useState } from "react";
import { GeolocationContext } from "common/context/GeolocationContext";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { DateRangeSelect } from "common/components/Inputs/DateRangeSelect";
import { StandardOption } from "common/types/generic";
import { useQ_costOfCareX } from "common/requests/patient_data/useQ_costOfCare";

const CareCostsAndSavings = () => {
	const geo = useContext(GeolocationContext);
	useEffect(() => {
		GeolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
	}, [geo.position]);

	const _startDate = useMemo(() => {
		let s = new Date();
		s.setFullYear(s.getFullYear() - 1);
		return s;
	}, []);
	const [startDate, set_startDate] = useState<Date | null>(_startDate);
	const [endDate, set_endDate] = useState<Date | null>(null);

	const dateRangeSelection = { startDate, set_startDate, endDate, set_endDate };

	const dateRangeOptions: Array<StandardOption<string, number | string>> = [
		{ name: "Past 12 Months", value: 12 },
		{ name: "Year to Date", value: "YTD" },
	];

	const CostOfCareResponse = useQ_costOfCareX({ dateRangeRequest: { yearsBack: 1 }, clientLocation: GeolocationManager.clientLocation() });

	return (
		<Box style={{ margin: 0, padding: 0, flexGrow: 1 }}>
			<DashboardMenu />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<ContentSection
						flexGrow={1}
						title="Your Cost Of Care"
						titleBarContent={
							<InfoPopper>
								<Typography variant="h4">Your Cost of Care</Typography>
								<Typography>
									This section displays the billing charges related to your healthcare. While you may not be paying out of pocket for the
									total billable amount it is important to understand your costs and how they trend overtime. Where applicable, Radius Care
									will display tips which may assist you in reducing the cost of your healthcare or making it more convenient for you with the
									use of services like Telehealth.
								</Typography>
							</InfoPopper>
						}
					>
						<Stack sx={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", paddingBottom: "1rem" }}>
							<DateRangeSelect
								labelId="specialty-select-label"
								id="specialty-select"
								variant="standard"
								dateRangeSelection={dateRangeSelection}
								dateRangeOptions={dateRangeOptions}
							/>
						</Stack>
						<QueryStatusDisplay queryResult={CostOfCareResponse}>
							{CostOfCareResponse.data?.data ? <CostOfCare costOfCareData={CostOfCareResponse.data.data} /> : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
				<Grid item xs={12} sm={4}>
					<ContentSection
						flexGrow={1}
						title="Your Medication Cost"
						titleBarContent={
							<InfoPopper>
								<Typography variant="h4">Your Medication Cost</Typography>
								<Typography>
									Prescription drug prices can vary by as much as 10 times between pharmacies. Radius Care shows you where the lowest cost
									prescriptions can be found.
								</Typography>
							</InfoPopper>
						}
					>
						<QueryStatusDisplay queryResult={CostOfCareResponse}>
							{CostOfCareResponse?.data?.data ? (
								<MedicationSavings
									// data={medicationSavingsDescriptions}
									costOfCareData={CostOfCareResponse.data.data}
								/>
							) : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
				<Grid item xs={12} sm={4}>
					<ContentSection
						flexGrow={1}
						title="Your Provider Cost"
						titleBarContent={
							<InfoPopper>
								<Typography variant="h4">Your Provider Cost</Typography>
								<Typography>
									Walk in clinics, such as MinuteClinic®, are an affordable option for non-emergency care as stated in the Urgent Care
									Association Benchmarking Report.
								</Typography>
							</InfoPopper>
						}
					>
						<QueryStatusDisplay queryResult={CostOfCareResponse}>
							{CostOfCareResponse.data?.data ? <ProviderSavings costOfCareData={CostOfCareResponse.data.data} /> : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
				<Grid item xs={12} sm={4}>
					<ContentSection
						flexGrow={1}
						title="Your Hospital Cost"
						titleBarContent={
							<InfoPopper>
								<Typography variant="h4">Your Hospital Cost</Typography>
								<Typography>
									Ambulatory Surgery Centers (ASCs), independent imaging centers (MRI’s, X-Rays, etc.) and other ancillary providers offering
									outpatient procedures and services, are usually much less expensive as sited by HIMSS Media and numerous healthcare economic
									studies. These accredited providers do not incur the often substantial administrative and overhead costs associated with
									hospitals.
								</Typography>
							</InfoPopper>
						}
					>
						<QueryStatusDisplay queryResult={CostOfCareResponse}>
							{CostOfCareResponse.data?.data ? <HospitalSavings costOfCareData={CostOfCareResponse.data.data} /> : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
			</Grid>
		</Box>
	);
};

export { CareCostsAndSavings };
