import { Box } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
type TilesDetailsSectionProps = React.PropsWithChildren<{
	open: boolean;
	set_open: React.Dispatch<React.SetStateAction<boolean>>;
}>;
const TilesDetailsSection = ({ children, open }: TilesDetailsSectionProps) => {
	const ref = useRef<HTMLDivElement>();
	const innerRef = useRef<HTMLDivElement>();
	const [fullHeight, set_fullHeight] = useState<number | null>(null);

	const [renderingActivated, set_renderingActivated] = useState<0 | 1 | 2 | 3>(0);
	// used to improve performance by avoiding rendering children invisibly for size info before requested
	// stages 1,2,3 are to retrigger the main useEffect in order to ensure that the child content has fully rendered when the size is retrieved

	const [currentHeight, set_currentHeight] = useState<number | string>(0);
	const [changeInstance, set_changeInstance] = useState<number>(-1);
	const [changeInstanceComplete, set_changeInstanceComplete] = useState<number>(-1);

	useEffect(() => {
		let inst = changeInstance + 1;
		set_changeInstance(inst);
		if (open) {
			if (fullHeight === null || !renderingActivated) {
				initialRender();
			}
			set_currentHeight(getFullHeight());
			setTimeout(() => {
				set_changeInstanceComplete(inst);
			}, 750);
		} else {
			set_currentHeight(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, renderingActivated, children]);

	const getFullHeight = () => {
		if (innerRef.current?.scrollHeight) {
			set_fullHeight(innerRef.current.scrollHeight);
			return innerRef.current.scrollHeight;
		}
		return "auto";
	};

	const initialRender = () => {
		set_renderingActivated(1);
		setTimeout(() => {
			set_renderingActivated(2);
		}, 500);
		setTimeout(() => {
			set_renderingActivated(3);
		}, 1000);
	};

	const renderChildren = useMemo(() => {
		return renderingActivated && (open || currentHeight !== 0 || (!open && !fullHeight) || changeInstanceComplete !== changeInstance);
	}, [changeInstance, open, currentHeight, fullHeight, changeInstanceComplete, renderingActivated]);

	return (
		<Box ref={ref} style={{ transition: "height .75s", height: currentHeight, margin: 0, overflow: "hidden", width: "100%" }}>
			{/* TilesDetails {changeInstance} {fullHeight} */}
			<Box ref={innerRef} sx={{ paddingTop: "1rem" }}>
				{renderChildren ? children : null}
			</Box>
		</Box>
	);
};

export { TilesDetailsSection };
