import { StaticDataManager } from "common/managers/StaticDataManager";
import { ICD10 } from "common/requests/codes_and_definitions/useQ_staticDataDefinitions";
// eslint-disable-next-line
import { ChangeEvent, ChangeEventHandler, useMemo, useState } from "react";
import { OpensearchDataExporter, downloadBlob } from "./OpensearchDataExporter";

type Alpha =
	| "a"
	| "b"
	| "c"
	| "d"
	| "e"
	| "f"
	| "g"
	| "h"
	| "i"
	| "j"
	| "k"
	| "l"
	| "m"
	| "n"
	| "o"
	| "p"
	| "q"
	| "r"
	| "s"
	| "t"
	| "u"
	| "v"
	| "w"
	| "x"
	| "y"
	| "z";

interface RiskCondition {
	category: string;
	condition: string;
	codes_raw: string;
	codeRanges: Array<string>;
	// [key: string]: any;
}
// eslint-disable-next-line
// interface RiskConditionEntry {
// 	icd: string;
// 	_rc: RiskCondition;
// 	_icd: ICD10;
// }

interface CodeQualifier {
	start: string;
	end: string | null;
	codeRange: string;
}

interface RiskModelQualifierItem {
	icd: string;
	category: string;
	condition: string;
	code_range: string;
	start: string;
	end?: string;
}

const RiskConditionManager = {
	process_uploadedText_into_riskConditions: (text: string | null) => {
		if (text) {
			let rows = text?.split("\r\n").filter((x) => x);
			if (rows) {
				var data: Array<RiskCondition> = rows.reduce((filtered: Array<RiskCondition>, row: string) => {
					let vals = row.split("|");
					let rc = { category: vals[0], condition: vals[1], codes_raw: vals[2] } as RiskCondition;
					RiskConditionManager.split_codeRanges_from_text(rc);
					filtered.push(rc);
					return filtered;
				}, []);
				return data;
			}
		}
		return null;
	},
	split_codeRanges_from_text: (rc: RiskCondition) => {
		if (rc.codes_raw) {
			rc.codeRanges = rc.codes_raw.split(",").map((x) => x.trim());
			rc.codeRanges.sort();
		}
	},

	construct_codeRangeMap_from_riskConditions(riskConditions: Array<RiskCondition>) {
		const riskConditionCodeRangeMap = new Map<string, Array<RiskCondition>>();

		riskConditions.forEach((row) => {
			if (row.codeRanges) {
				row.codeRanges.forEach((codeRange) => {
					if (riskConditionCodeRangeMap.has(codeRange)) {
						let x = riskConditionCodeRangeMap.get(codeRange);
						if (x) {
							x.push(row);
						} else {
							alert("Missing codeRange");
						}
					} else {
						riskConditionCodeRangeMap.set(codeRange, [row]);
					}
				});
			} else {
				console.debug("No CODES", row);
			}
		});
		return riskConditionCodeRangeMap;
	},

	construct_qualifier_from_codeRange(codeRange: string) {
		let x = codeRange
			.replaceAll(".", "")
			.split("-")
			.map((xx) => xx.trim());
		const qual: CodeQualifier = { start: x[0], end: x[1] ?? null, codeRange: codeRange };
		return qual;
	},

	filter_codeRanges_into_alpha_category: (map: Map<string, RiskCondition[]>) => {
		const codes_by_alpha: { [key in Alpha]?: CodeQualifier[] } = {};
		let keys = Array.from(map.keys());
		keys.sort();
		// console.debug(keys);
		keys.forEach((code) => {
			let char = code[0].toLowerCase() as Alpha;
			if (!codes_by_alpha[char]) {
				codes_by_alpha[char] = [];
			}
			codes_by_alpha[char]?.push(RiskConditionManager.construct_qualifier_from_codeRange(code));
		});

		return codes_by_alpha;
	},

	assign_codes_to_qualifier_riskConditions: (
		alphaQualifiers: { [key in Alpha]?: CodeQualifier[] },
		codes: Array<ICD10>,
		codeRangeMap: Map<string, RiskCondition[]>
	) => {
		let x: Array<RiskModelQualifierItem> = [];
		codes.forEach((c) => {
			let alpha = c.Alpha?.alpha.toLowerCase();
			// console.debug(alpha);
			if (!alpha) {
				// console.debug(c);
				return;
			}
			let alphaGroup = alphaQualifiers[alpha as Alpha];

			var codeToNewItemMap = new Map<string, RiskModelQualifierItem>();
			if (alphaGroup) {
				alphaGroup.forEach((qual) => {
					let match: boolean = false;
					if (qual.start <= c.ICD10Code) {
						if (qual.end && qual.end >= c.ICD10Code) {
							match = true;
						} else if (!qual.end && qual.start === c.ICD10Code) {
							match = true;
						}
					}

					if (match) {
						let riskCondition = codeRangeMap?.get(qual.codeRange);
						if (riskCondition && riskCondition.length > 0) {
							let category = riskCondition[0].category;
							let condition = riskCondition[0].condition;
							if (riskCondition.length > 1) {
								let _cat = [category];
								let _cond = [condition];
								riskCondition.forEach((rc) => {
									if (_cat.indexOf(rc.category) === -1) {
										_cat.push(rc.category);
										category += `|${rc.category}`;
									}
									if (_cond.indexOf(rc.condition) === -1) {
										_cond.push(rc.condition);
										condition += `|${rc.condition}`;
									}
								});
							}
							if (codeToNewItemMap.has(c.ICD10Code)) {
								let existingItem = codeToNewItemMap.get(c.ICD10Code);
								if (existingItem) {
									if (existingItem.category.indexOf(category) === -1) {
										existingItem.category += `|${category}`;
									}
									if (existingItem.condition.indexOf(condition) === -1) {
										existingItem.condition += `|${condition}`;
									}
									if (existingItem.code_range.indexOf(qual.codeRange) === -1) {
										existingItem.code_range += `|${qual.codeRange}`;
									}
								}
							} else {
								let newItem = {
									icd: c.ICD10Code,
									category: category,
									condition: condition,
									code_range: qual.codeRange,
									start: qual.start,
									end: qual.end,
								} as RiskModelQualifierItem;

								codeToNewItemMap.set(c.ICD10Code, newItem);

								x.push(newItem);
							}
							// if (riskCondition.length > 1) {
							// 	console.debug({
							// 		icd: c.ICD10Code,
							// 		category: category,
							// 		condition: condition,
							// 		code_range: qual.codeRange,
							// 		start: qual.start,
							// 		end: qual.end,
							// 	});
							// }
						} else {
							alert(`Failed to find riskCondition ${qual.codeRange}`);
						}
					}
				});
			} else {
				return;
			}
		});
		console.debug({ x });
		return x;
	},
};

const RiskImporter = () => {
	const [uploadedText, set_uploadedText] = useState<string | null>(null);

	const processUpload: ChangeEventHandler<HTMLInputElement> = (x: ChangeEvent<HTMLInputElement>) => {
		let doc: Document = document as Document;
		let el = doc.getElementById("avatar") as HTMLInputElement;
		if (el && el.files) {
			var fileToLoad = el.files[0];
			var fileReader = new FileReader();
			fileReader.onload = function (fileLoadedEvent) {
				var textFromFileLoaded = fileLoadedEvent.target?.result;
				if (typeof textFromFileLoaded === "string") {
					set_uploadedText(textFromFileLoaded);
				}
			};

			fileReader.readAsText(fileToLoad, "UTF-8");
		}
	};

	const [triggerConditionCodeMap, set_triggerConditionCodeMap] = useState<number>(0);

	const rangeCategories = useMemo(() => {
		return StaticDataManager.data.ICD10CM_Package.range_categories.sort((a, b) => {
			return a.range > b.range ? 1 : b.range > a.range ? -1 : 0;
		});
	}, []);

	const codes = useMemo(() => {
		return StaticDataManager.data.ICD10CM_Package.codes.sort((a, b) => {
			return a.ICD10Code > b.ICD10Code ? 1 : b.ICD10Code > a.ICD10Code ? -1 : 0;
		});
	}, []);

	/*
	
	
	// Process the raw file text
	
	
	
	*/
	const riskConditionDefinitions = useMemo(() => {
		return RiskConditionManager.process_uploadedText_into_riskConditions(uploadedText);
	}, [uploadedText]);

	// eslint-disable-next-line
	const riskConditionCodeRangeMap = useMemo(() => {
		if (riskConditionDefinitions) {
			return RiskConditionManager.construct_codeRangeMap_from_riskConditions(riskConditionDefinitions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [riskConditionDefinitions, triggerConditionCodeMap]);

	const codeQualifiersByAlpha = useMemo(() => {
		if (riskConditionCodeRangeMap) {
			return RiskConditionManager.filter_codeRanges_into_alpha_category(riskConditionCodeRangeMap);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [riskConditionCodeRangeMap]);

	const RiskConditionMarkerIcds = useMemo(() => {
		if (codeQualifiersByAlpha && riskConditionCodeRangeMap) {
			return RiskConditionManager.assign_codes_to_qualifier_riskConditions(codeQualifiersByAlpha, codes, riskConditionCodeRangeMap);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [codeQualifiersByAlpha, riskConditionCodeRangeMap]);

	const display = useMemo(() => {
		return (
			<table style={{ border: "solid 1px black" }}>
				<thead>
					<tr>
						<td>Categories</td>
						<td>Codes</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{rangeCategories.length}</td>
						<td>{codes.length}</td>
					</tr>
				</tbody>
			</table>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<p>ICD RANGE CATEGORY VIEWER</p>
			<input
				type="file"
				id="avatar"
				name="avatar"
				accept="text/csv"
				onChange={(x: any) => {
					processUpload(x);
				}}
			/>
			<button
				onClick={() => {
					set_triggerConditionCodeMap(triggerConditionCodeMap + 1);
				}}
			>
				triggerConditionCodeMap {triggerConditionCodeMap}
			</button>

			<button
				onClick={() => {
					if (RiskConditionMarkerIcds) {
						OpensearchDataExporter.batcher_contentLength(RiskConditionMarkerIcds, "risk_model_radiuscare", "icd");
					}
				}}
			>
				Export to Insert
			</button>
			<button
				onClick={() => {
					if (RiskConditionMarkerIcds) {
						let csvString = OpensearchDataExporter.create_csv_text(RiskConditionMarkerIcds, [
							"icd",
							"category",
							"condition",
							"code_range",
							"start",
							"end",
						]);
						let csvHeaderConstructor = `using Nest;

namespace Radius.DataProcessor.Entity
{
    [ElasticsearchType(IdProperty = nameof(icd))]
    public class risk_model_qualifiers_radiuscare
    {
		[Keyword]
        public string icd { get; set; }
        [Text]
        public string category { get; set; }
        [Text]
        public int condition { get; set; }
		[Text]
        public int code_range { get; set; }
        [Text]
        public string start { get; set; }
		[Text]
        public string? end { get; set; }
    }
}`;

						let fileContents = OpensearchDataExporter.create_paul_csv(csvString, csvHeaderConstructor);
						downloadBlob(fileContents, "OpenSearchCsv_Risk_Model_Qualifiers_RadiusCare.csv");
					}
				}}
			>
				Export to Paul-CSV
			</button>
			{/* <button
				onClick={() => {
					if (RiskConditionMarkerIcds) {
						let x = RiskConditionMarkerIcds?.map((i) => {
							return i.icd;
						});
						if (x) {
							let s = new Set();
							x.forEach((z) => {
								s.add(z);
							});
							console.debug(s.size, x.length);
						}
					}
				}}
			>
				Check Values
			</button> */}
			<button
				onClick={() => {
					if (RiskConditionMarkerIcds) {
						let dups = new Map<string, any[]>();

						RiskConditionMarkerIcds?.forEach((i) => {
							if (dups.has(i.icd)) {
								dups.get(i.icd)?.push(i);
							} else {
								dups.set(i.icd, [i]);
							}
						});
						let duplicates = Array.from(dups.values()).filter((v) => {
							return v.length > 1;
						});
						console.debug({ duplicates });
					}
				}}
			>
				Find Duplicates
			</button>
			{display}
			<div>
				<pre>{uploadedText}</pre>
			</div>
		</div>
	);
};

// PUT risk_model_radiuscare
// {
//   "mappings" : {
//       "properties" : {
//         "icd" : {
//           "type" : "keyword"
//         },
//         "category" : {
//           "type" : "text"
//         },
//         "condition" : {
//           "type" : "text"
//         },
//         "code_range" : {
//           "type" : "text"
//         },
//         "start" : {
//           "type" : "text"
//         },
//         "end" : {
//           "type" : "text"
//         }
//       }
//     }
// }

export { RiskImporter };
