import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { ProviderSavingsDetails } from "dashboard/pages/details/ProviderSavingsDetails";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ProviderSavingsDetailDialog = () => {
	const location = useLocation();
	const { providerSavingsDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("provider-savings-details");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("provider-savings-details");
			setSearchParams(searchParams);
			providerSavingsDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "provider-savings-details": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["provider-savings-details"].open = open;
		DialogManager.launchers["provider-savings-details"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== providerSavingsDisplay?.current) {
			providerSavingsDisplay.set_current(d_query);
		}
	}, [d_query, providerSavingsDisplay]);

	return (
		<Dialog id="DiagnosisDialog" fullScreen open={!!providerSavingsDisplay.current} onClose={close} TransitionComponent={Transition}>
			{providerSavingsDisplay.current ? <ProviderSavingsDetails /> : null}
		</Dialog>
	);
};

export { ProviderSavingsDetailDialog };
