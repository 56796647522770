import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { Entity } from "common/definitions/EntityDefinitions";
import { convertDatesToChartDataByMonth } from "common/utilities/chartDataConverters";
import { ModelsExplanationOfBenefitTreatmentAssessment } from "api";
import StandardLineChart from "common/components/Charts/StandardLineChart";
import { dateConverter } from "common/utilities/dateConverter";
import { MedicationHistoryTile } from "common/components/Tiles/MedicationHistoryTile";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";

type HealthSnapshotMedicationsPanelProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotMedicationsPanel = ({ patientEOBData }: HealthSnapshotMedicationsPanelProps) => {
	const treatments = useMemo(() => {
		if (patientEOBData?.data?.data?.medicationAssessments) {
			return patientEOBData?.data?.data?.medicationAssessments;
		}
	}, [patientEOBData?.data?.data?.medicationAssessments]);

	const treatments_ents: Entity[] = useMemo(() => {
		let ents: Entity[] = [];
		if (treatments) {
			Object.keys(treatments).forEach((k) => {
				let x = treatments[k];
				if (x) {
					let ent: Entity = {
						entityTypeName: "medication",
						title: (x as CIM)._ndc_data?.name ?? "Unidentified Medication: " + x.code,
						descriptor: `Most Recent: ${dateConverter["YYYY-MM-DD_to_mM/dD/YY"](x.dates[x.dates.length - 1])}`,
						displayInfo: [
							{ title: x.count > 1 ? "Occurences" : "Occurence", value: x.count },
							{ title: "Est. Total Payments", value: x.paymentAmountTotal, colorType: "USD", dataType: "USD" },
						],
						imageSrc: "",
						_data: x,
					};
					ents.push(ent);
				}
			});

			ents.sort((a: Entity, b: Entity) => {
				let al = a._data.dates?.length;
				let bl = b._data.dates?.length;
				if (!al || !bl) {
					if (!al && !bl) return 0;
					if (!al) return 1;
					return -1;
				}
				al = a._data.dates[al - 1];
				bl = b._data.dates[bl - 1];
				if (al > bl) return -1;
				if (bl > al) return 1;
				return 0;
			});
		}
		return ents;
	}, [treatments]);

	const tiles = useMemo(() => {
		return treatments_ents.map((medEntity, i) => {
			return (
				<Box key={i} sx={{ paddingBottom: ".5rem" }}>
					<MedicationHistoryTile key={i} medicationEntity={medEntity} medicationAssessment={medEntity._data} />
				</Box>
			);
		});
	}, [treatments_ents]);

	return (
		<Box style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<LoadMorePaginator
					pageSize={10}
					data={tiles}
					renderItem={(tile: any, i?: number) => {
						return tile;
					}}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

const MedicationAssessmentDetailsSection = ({ assessment }: { assessment: ModelsExplanationOfBenefitTreatmentAssessment }) => {
	let chartData;
	if (assessment.dates) {
		chartData = convertDatesToChartDataByMonth(assessment.dates);
	}
	console.debug({ chartData });
	return (
		<Box>
			<Box>
				<Typography component="div" variant="subsection-title" style={{ textAlign: "left", margin: "16px 0px" }}>
					History
				</Typography>
				<Box style={{ width: "100%", minHeight: "100px" }}>
					{chartData ? (
						<StandardLineChart
							data={chartData}
							xKey={"yyyymm"}
							yKeys={[{ dataKey: "count" }]}
							responsiveHeight={100}
							keyLabels={{ count: "Occurrences" }}
							xTickLine
							xTickFormatter={(x, y) => {
								return dateConverter["YYYY-MM_to_mM/YY"](x);
							}}
							yDomain={[0, "dataMax" as CIM]}
							dotSize={3}
						/>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

export { HealthSnapshotMedicationsPanel, MedicationAssessmentDetailsSection };
