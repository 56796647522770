import { Box, Modal, Typography } from "@mui/material";

type SimpleModalProps = {
	open: boolean;
	onClose: () => any;
	title?: string;
	description?: string;
	displayJSON?: string;
	maxWidth?: number;
};
const SimpleModal = ({ open, onClose, title, description, displayJSON, children, maxWidth }: React.PropsWithChildren<SimpleModalProps>) => {
	const maxWidthX = maxWidth ?? 400;
	return (
		<Modal
			open={open}
			onClose={onClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			sx={{
				display: "flex",
				padding: "1rem",
				alignItems: "center",
				justifyContent: "center",
				"& .MuiBackdrop-root": {
					backgroundColor: "rgba(255,255,255,0.75)",
				},
			}}
		>
			<Box
				sx={{
					backgroundColor: "#ffffff",
					borderRadius: "1rem",
					boxShadow: 4,
					padding: "1rem",
					maxWidth: maxWidthX,
					textAlign: "center",
					outline: "solid 0px transparent", // prevents mysterious blue outline
				}}
			>
				{title ? (
					<Typography variant="h1" id="parent-modal-title">
						{title}
					</Typography>
				) : null}
				{description ? (
					<Typography id="parent-modal-description">
						<pre>{description}</pre>
					</Typography>
				) : null}
				{displayJSON ? (
					<Typography component="div" sx={{ fontSize: 10 }}>
						<pre>{displayJSON}</pre>
					</Typography>
				) : null}
				{children}
			</Box>
		</Modal>
	);
};

export { SimpleModal };
