import { special_instructions } from "common/components/Redirecter/Redirecter";
import { HealthIcon, health_icons_svg, IconSVG } from "common/components/Icons/HealthIcon";
import { AccountManager } from "common/managers/AccountManager";
import { ReactNode } from "react";
import { DialogManager } from "common/managers/DialogManager";
import { X_updateRemindersUspstfCodes } from "common/requests/patient_data/X_updateRemindersUspstfCodes";

type EventTypeNames =
	| "diagnosis"
	| "condition"
	| "activate_achievements"
	| "costs"
	| "medication"
	| "treatments"
	| "provider"
	| "physician"
	| "facility"
	| "appointment"
	| "visits"
	| "collaborators"
	| "account"
	| "symptoms"
	| "care_maintenance"
	| "scan"
	| "lab_test"
	| "pharmacy"
	| "home_health_service"
	| "hospital"
	| "doctor_clinician"
	| "procedure"
	| "wellness_check"
	| "immunization"
	| "screening"
	| "preventive_medication"
	| "general"
	| "invite_collaborator"
	| "reminder_alert"
	| "vaccination"
	| "complete_user_profile"
	| "ambulatory_health_care_facilities"
	| "suppliers"
	| "hospitals"
	| "transportation_services"
	| "agencies"
	| "nursing_&_custodial_care_facilities"
	| "residential_treatment_facilities"
	| "hospital_units"
	| "respite_care_facility"
	| "laboratories"
	| "provider_visit"
	| "achievement";

function typeCheck_EventTypeNames(typeName?: string): typeName is EventTypeNames {
	if (typeName && event_type_definitions.hasOwnProperty(typeName)) {
		return true;
	}
	return false;
}

type EventTypeActionNames =
	| "learn_more"
	| "search_providers"
	| "view_provider_profile"
	| "add_to_calendar"
	| "add_care_collaborator"
	| "manage_care_collaborators"
	| "verify_email"
	| "connect_healthplan"
	| "view_calendar"
	| "update_user_profile"
	| "activate_achievements"
	| "update_user_location"
	| "complete_user_profile"
	| "accarent_alert"
	| "view_achievements"
	| "remove_achievement_reminder"
	| "reauthorize_datasource";

function typeCheck_EventTypeActionNames(actionName?: string): actionName is EventTypeActionNames {
	if (actionName && event_actions.hasOwnProperty(actionName)) {
		return true;
	}
	return false;
}

type EventAction = {
	action: (x: EventDescription | null, y?: EventActionParam) => any;
	text: string;
};

type EventActionParam = {
	id?: string;
};

const event_actions: { [key in EventTypeActionNames]: EventAction } = {
	learn_more: {
		action: (x: EventDescription | null) => {
			console.debug("learn_more", x);
			if (!x) {
				return;
			}
			if (x.type === "diagnosis") {
				if (x.typeID) {
					DialogManager.open("diagnosis-details", x.typeID);
				} else {
					console.warn("missing typeID");
				}
			} else if (x.type === "medication") {
				if (x.typeID) {
					DialogManager.open("medication-details", x.typeID);
				} else {
					console.warn("missing typeID");
				}
			}
		},
		text: "Learn More",
	},
	search_providers: {
		action: (x: EventDescription | null) => {
			console.debug("search_providers", x);
			alert("search_providers\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Search Providers",
	},
	view_provider_profile: {
		action: (x: EventDescription | null) => {
			console.debug("view_provider_profile", x);
			alert("view_provider_profile\r\n" + JSON.stringify(x, null, 2));
		},
		text: "View Profile",
	},
	add_to_calendar: {
		action: (x: EventDescription | null) => {
			console.debug("add_to_calendar", x);
			special_instructions.redirect(`/dashboard/carecalendar`);
		},
		text: "Add To Calendar",
	},
	add_care_collaborator: {
		action: (x: EventDescription | null) => {
			console.debug("add_care_collaborator", x);
			DialogManager.open("invite-care-collaborator");
		},
		text: "Invite",
	},
	manage_care_collaborators: {
		action: (x: EventDescription | null) => {
			console.debug("manage_care_collaborators", x);
			special_instructions.redirect(`/account/CareCollaborators`);
		},
		text: "Manage Care Collaborators",
	},
	view_calendar: {
		action: (x: EventDescription | null) => {
			console.debug("view_calendar", x);
			special_instructions.redirect(`/dashboard/carecalendar`);
		},
		text: "View Calendar",
	},
	verify_email: {
		action: (x: EventDescription | null) => {
			if (AccountManager.username) {
				DialogManager.open("verify-email", AccountManager.username);
			} else {
				console.warn("username/email not found");
			}
		},
		text: "Verify Email",
	},
	connect_healthplan: {
		action: (x: EventDescription | null) => {
			DialogManager.open("add-care-plan");
		},
		text: "Connect Insurance Plan",
	},
	update_user_profile: {
		action: (x: EventDescription | null) => {
			console.debug("update_user_profile", x);
			special_instructions.redirect(`/account?update-info=undefined`);
		},
		text: "Update Profile",
	},
	activate_achievements: {
		action: (x: EventDescription | null) => {
			console.debug("activate_achievements", x);
			special_instructions.redirect(`/account?activate-achievements=undefined`);
		},
		text: "Activate",
	},
	update_user_location: {
		action: (x: EventDescription | null) => {
			console.debug("update_user_location", x);
			special_instructions.redirect(`/account`);
		},
		text: "Update Profile",
	},
	complete_user_profile: {
		action: (x: EventDescription | null) => {
			console.debug("complete_user_profile", x);
			special_instructions.redirect(`/account?update-info=undefined`);
		},
		text: "Complete Profile",
	},
	accarent_alert: {
		action: (x: EventDescription | null) => {
			console.debug("accarent_alert", x);
			DialogManager.open("accarent-alert");
		},
		text: "Learn More",
	},
	view_achievements: {
		action: (x: EventDescription | null) => {
			console.debug("update_user_location", x);
			special_instructions.redirect(`/dashboard/achievements`);
		},
		text: "View Achievements",
	},
	remove_achievement_reminder: {
		action: (x: EventDescription | null, param?: EventActionParam) => {
			console.debug("remove_achievement_reminder", x, param);
			if (param && param.id) {
				X_updateRemindersUspstfCodes({ uspstfCodes: [param.id], removeItems: true });
			}
		},
		text: "Remove Reminder",
	},
	reauthorize_datasource: {
		action: (x: EventDescription | null, param?: EventActionParam) => {
			console.debug("reauthorize_datasource", x, param);
			if (param?.id) {
				console.debug(`/connect-healthplan/authorize/healthplan:${param?.id}`);
				special_instructions.redirect(`/connect-healthplan/authorize/healthplan:${param?.id}`);
			} else {
				window.RadiusCare.log_issue({ file: "EventDefinitions", message: "reauthorize_datasource - healthplan not provided" });
				special_instructions.redirect(`/connect-healthplan`);
			}
		},
		text: "Reauthorize",
	},
};

const convert_TaxonomyGrouping_EventTypeName = (taxonomy_grouping: string) => {
	return taxonomy_grouping.toLowerCase().replaceAll(" ", "_");
};

type EventTypeDefinition = {
	color: string;
	iconSVG: IconSVG;
	default_link?: string;
	default_text?: string;
	icon: ReactNode;
};
const event_type_definitions: { [key in EventTypeNames]: EventTypeDefinition } = {
	condition: {
		default_text: "Conditions",
		icon: null,
		color: "core.preventative",
		iconSVG: health_icons_svg.stethoscope,
		default_link: "/healthsnapshot/conditions",
	},
	costs: {
		default_text: "Costs",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.dollarsign,
		default_link: "/healthsnapshot/visits",
	},
	diagnosis: {
		default_text: "Diagnoses",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.stethoscope,
		default_link: "/healthsnapshot/conditions",
	},
	medication: {
		default_text: "Medications",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.pill3,
		default_link: "/healthsnapshot/medication",
	},
	treatments: {
		default_text: "Treatments",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.pill3,
		default_link: "/healthsnapshot/medication",
	},
	provider: {
		default_text: "Providers",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.doctorfemale,
		default_link: "/healthsnapshot/providers",
	},
	provider_visit: {
		default_text: "Provider Visits",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.doctorfemale,
		default_link: "/healthsnapshot/providers",
	},
	physician: {
		default_text: "Providers",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.doctormale,
		default_link: "/healthsnapshot/providers",
	},
	facility: {
		default_text: "Facilities",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.hospital,
		default_link: "/healthsnapshot/providers",
	},
	appointment: {
		default_text: "Appointments",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.calendarschedule,
		default_link: "/healthsnapshot/visits",
	},
	visits: {
		default_text: "Visits",
		icon: null,
		color: "core.purple",
		iconSVG: health_icons_svg.calendarschedule,
		default_link: "/healthsnapshot/visits",
	},
	collaborators: {
		default_text: "Collaborators",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.group_discussion_meeting_x3,
		default_link: undefined,
	},
	invite_collaborator: {
		default_text: "Invite Collaborator",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.group_discussion_meeting_x3,
		default_link: undefined,
	},
	reminder_alert: {
		default_text: "Invite Collaborator",
		icon: null,
		color: "error.main",
		iconSVG: health_icons_svg.faBellOn,
		default_link: undefined,
	},
	account: {
		default_text: "Account",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.faEnvelopeCircleCheck,
		default_link: undefined,
	},
	symptoms: {
		default_text: "Symptoms",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.symptoms,
		default_link: undefined,
	},
	care_maintenance: {
		default_text: "Care Maintenance",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.symptoms,
		default_link: undefined,
	},
	scan: {
		default_text: "Scans",
		icon: null,
		color: "core.purple",
		iconSVG: health_icons_svg.ct_scan,
		default_link: "/healthsnapshot/visits",
	},
	lab_test: {
		default_text: "Lab Tests",
		icon: null,
		color: "core.purple",
		iconSVG: health_icons_svg.ct_scan,
		default_link: "/healthsnapshot/visits",
	},
	pharmacy: {
		default_text: "Pharmacies",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.pharmacy,
		default_link: undefined,
	},
	home_health_service: {
		default_text: "Home Health Services",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.home,
		default_link: undefined,
	},
	hospital: {
		default_text: "Hospitals",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.hospital,
		default_link: undefined,
	},
	hospitals: {
		default_text: "Hospitals",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.hospital,
		default_link: undefined,
	},
	doctor_clinician: {
		default_text: "Doctors & Clinicians",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.doctorfemale,
		default_link: undefined,
	},
	procedure: {
		default_text: "Procedures",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.hospital,
		default_link: undefined,
	},
	wellness_check: {
		default_text: "Wellness Check",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.symptoms,
		default_link: undefined,
	},
	immunization: {
		default_text: "Immunization",
		icon: null,
		color: "core.preventative",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	screening: {
		default_text: "Screening Test",
		icon: null,
		color: "core.purple",
		iconSVG: health_icons_svg.exam_multiple_choice,
		default_link: undefined,
	},
	preventive_medication: {
		default_text: "Preventive Medication",
		icon: null,
		color: "core.preventative",
		iconSVG: health_icons_svg.pill3,
		default_link: undefined,
	},
	general: {
		default_text: "General",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.register_book,
		default_link: undefined,
	},
	complete_user_profile: {
		default_text: "Complete Profile",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.person,
		default_link: undefined,
	},
	activate_achievements: {
		default_text: "General",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.trophy,
		default_link: undefined,
	},
	vaccination: {
		default_text: "Vaccination",
		icon: null,
		color: "core.preventative",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	ambulatory_health_care_facilities: {
		default_text: "Ambulatory Facility",
		icon: null,
		color: "core.preventative",
		iconSVG: health_icons_svg.symptoms,
		default_link: undefined,
	},
	suppliers: {
		default_text: "Suppliers",
		icon: null,
		color: "core.lavender",
		iconSVG: health_icons_svg.ppe_sanitizer,
		default_link: undefined,
	},
	transportation_services: {
		default_text: "Transportation Services",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	agencies: {
		default_text: "Agencies",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	"nursing_&_custodial_care_facilities": {
		default_text: "Nursing & Custodial Care Facilities",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	residential_treatment_facilities: {
		//Home
		default_text: "Residential Treatment Facilities",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.syringe,
		default_link: undefined,
	},
	hospital_units: {
		default_text: "Hospital Units",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.hospital,
		default_link: undefined,
	},
	respite_care_facility: {
		default_text: "Respite Care Facility",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.hospital,
		default_link: undefined,
	},
	laboratories: {
		default_text: "Laboratories",
		icon: null,
		color: "core.forestgreen",
		iconSVG: health_icons_svg.test_tube,
		default_link: undefined,
	},
	achievement: {
		default_text: "Achievement",
		icon: null,
		color: "core.darkblue",
		iconSVG: health_icons_svg.calendarschedule,
		default_link: "/dashboard/achievements",
	},
};

Object.keys(event_type_definitions).forEach((x: string) => {
	// create .icon for the definitions - this will only run once
	let def = event_type_definitions[x as EventTypeNames];
	def.icon = <HealthIcon size={48} iconSVG={def.iconSVG} color={def.color} />;
});

type EventDescription = {
	code?: string;
	type: EventTypeNames;
	typeID?: number | string;
	title: string;
	date?: string | Date;
	actions?: Array<EventTypeActionNames>;
	ignorable?: boolean;
};

interface SavingsDescription extends EventDescription {
	value: number;
}

type AchievementType = "wellness_check" | "immunization" | "screening" | "preventive_medication" | "general" | "vaccination";

const create_EventActions_for_EventDescription = (eventDescription: EventDescription) => {
	return eventDescription.actions?.map((actionName) => {
		let a = event_actions[actionName];
		if (a) {
			return {
				action: a.action.bind(null, eventDescription) as () => any,
				text: a.text,
			};
		} else {
			window.RadiusCare.log_issue({ message: `Unrecognized event_actions: ${actionName}`, file: "EventTile" });
			return null;
		}
	});
};

export {
	event_type_definitions,
	event_actions,
	create_EventActions_for_EventDescription,
	convert_TaxonomyGrouping_EventTypeName,
	typeCheck_EventTypeNames,
	typeCheck_EventTypeActionNames,
};

export type { EventTypeNames, EventTypeActionNames, EventDescription, SavingsDescription, AchievementType };
