import { Stack, Typography } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { BasicTileX } from "common/components/Tiles/BasicTile";
import { DateRangeSelection } from "common/types/generic";
import { DateRangeSelect } from "common/components/Inputs/DateRangeSelect";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { InfoPopper } from "common/components/DisplayElements/InfoPopper";

type HealthSnapshotMenuProps = {
	dateRangeSelection: DateRangeSelection;
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotMenu = ({ dateRangeSelection, patientEOBData }: HealthSnapshotMenuProps) => {
	return (
		<ContentSection
			flexGrow={1}
			title="Your Health Snapshot"
			titleBarContent={
				<InfoPopper>
					<Typography variant="h4">Your Health Snapshot</Typography>
					<Typography>
						Your Health Snapshot shows up to 5 years of data. All your provider visits; diagnoses and treatments, your provider care team and care
						alerts about any conditions you may have. It also shows your medications and possible savings that are available to you.{" "}
					</Typography>
				</InfoPopper>
			}
		>
			<Stack sx={{ flexDirection: "row", alignItems: "flex-end", justifyContent: "flex-end", paddingBottom: "1rem" }}>
				{/* <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "space-between", flexGrow: 1 }}> */}
				{/* <Typography component="div" variant={"entity-descriptor"} style={{ padding: "8px" }}>
						Date of Last Record: {lastRecordDate}
					</Typography> */}
				<DateRangeSelect labelId="specialty-select-label" id="specialty-select" variant="standard" dateRangeSelection={dateRangeSelection} />
				{/* </Box> */}
			</Stack>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<Stack sx={{ flexDirection: "row" }}>
					<BasicTileX type={"visits"} title={"Visits"} value={patientEOBData.data?.data?.overview?.visitCount ?? "?"} link />
					<BasicTileX type={"provider"} title={"Providers"} value={patientEOBData.data?.data?.overview?.providerCount ?? "?"} link />

					<BasicTileX type={"condition"} title={"Conditions"} value={patientEOBData.data?.data?.overview?.conditionCount ?? "?"} link />
					<BasicTileX type={"medication"} title={"Medication"} value={patientEOBData.data?.data?.overview?.medicationCount ?? "?"} link />
				</Stack>
			</QueryStatusDisplay>
		</ContentSection>
	);
};

export { HealthSnapshotMenu };
