import { Slider } from "@mui/material";
import { rangePercentConverter } from "common/utilities/rangePercentConverter";
import { useEffect, useState } from "react";

type SliderStandardProps = {
  defaultValue?: number | Array<number>;
  ariaLabel?: string;
  onChange?: (event: Event | null, newValue: number) => void;
  onChangeRange?: (event: Event | null, newValue: Array<number>) => void;
  minValue?: number;
  maxValue?: number;
  disabled?: boolean;
};

function SliderStandard({ defaultValue, ariaLabel, onChange, onChangeRange, minValue, maxValue, disabled }: SliderStandardProps) {
  const [internalValue, set_internalValue] = useState<number | Array<number>>(defaultValue ?? 0);

  const sliderOnChange = (e: Event | null, percentValue: number | Array<number>) => {
    let newValue = percentValue;
    set_internalValue(percentValue);
    if (typeof percentValue === "number") {
      if (onChange && typeof newValue === "number") {
        onChange(e, rangePercentConverter.mapPercentToRange(percentValue, minValue, maxValue));
      }
    }
    if (onChangeRange && Array.isArray(percentValue)) {
      onChangeRange(e, [
        rangePercentConverter.mapPercentToRange(percentValue[0], minValue, maxValue),
        rangePercentConverter.mapPercentToRange(percentValue[1], minValue, maxValue),
      ]);
    }
  };

  useEffect(() => {
    if (defaultValue !== undefined && minValue !== undefined && maxValue !== undefined) {
      if (Array.isArray(defaultValue)) {
        sliderOnChange(null, [
          rangePercentConverter.mapRangeValueToPercent(defaultValue[0], minValue, maxValue),
          rangePercentConverter.mapRangeValueToPercent(defaultValue[1], minValue, maxValue),
        ]);
      } else {
        sliderOnChange(null, rangePercentConverter.mapRangeValueToPercent(defaultValue, minValue, maxValue));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, minValue, maxValue]);

  return (
    <Slider
      disabled={disabled}
      aria-label={ariaLabel}
      value={internalValue}
      onChange={(e, percentValue: number | Array<number>) => {
        sliderOnChange(e, percentValue);
      }}
    />
  );
}

export { SliderStandard };
