/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerMobileGetDrugPricesAndLocationsMobileResult,
  ModelsClientLocation,
  GetDrugPricesAndLocationsMobileParams,
  ConsumerMobileGetMedicationDetailsResult,
  GetMedicationDetailsParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDrugPricesAndLocationsMobile = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDrugPricesAndLocationsMobileParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileGetDrugPricesAndLocationsMobileResult>(
      {url: `/api/consumer/ConsumerMobile_Medication/GetDrugPricesAndLocations_Mobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDrugPricesAndLocationsMobileMutationResult = NonNullable<Awaited<ReturnType<typeof getDrugPricesAndLocationsMobile>>>
    export type GetDrugPricesAndLocationsMobileMutationBody = ModelsClientLocation
    export type GetDrugPricesAndLocationsMobileMutationError = unknown

    export const useGetDrugPricesAndLocationsMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDrugPricesAndLocationsMobile>>, TError,{data: ModelsClientLocation;params?: GetDrugPricesAndLocationsMobileParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDrugPricesAndLocationsMobile>>, {data: ModelsClientLocation;params?: GetDrugPricesAndLocationsMobileParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDrugPricesAndLocationsMobile(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDrugPricesAndLocationsMobile>>, TError, {data: ModelsClientLocation;params?: GetDrugPricesAndLocationsMobileParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getMedicationDetails = (
    params?: GetMedicationDetailsParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMobileGetMedicationDetailsResult>(
      {url: `/api/consumer/ConsumerMobile_Medication/GetMedicationDetails`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetMedicationDetailsQueryKey = (params?: GetMedicationDetailsParams,) => [`/api/consumer/ConsumerMobile_Medication/GetMedicationDetails`, ...(params ? [params]: [])];

    
export type GetMedicationDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getMedicationDetails>>>
export type GetMedicationDetailsQueryError = unknown

export const useGetMedicationDetails = <TData = Awaited<ReturnType<typeof getMedicationDetails>>, TError = unknown>(
 params?: GetMedicationDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMedicationDetails>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMedicationDetailsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMedicationDetails>>> = ({ signal }) => getMedicationDetails(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMedicationDetails>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

