import { Box, Grid, Stack, Typography } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { Loader } from "common/components/Loaders/Loader";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext } from "react";
import { palette } from "theme";
import { SentCareCollaborations } from "./SentCareCollaborations";
import { ReceivedCareCollaborations } from "./ReceivedCareCollaborations";
import { AcceptedCareCollaborations } from "./AcceptedCareCollaborations";

type ExistingCareCollaborationsProps = {
	displayLocation: "sidebar" | "modal" | "profile";
	noContentSectionContainers?: boolean;
	noSent?: boolean;
	// noRevoked?: boolean;
	// noPending?: boolean;
	// noRemoved?: boolean;
};

/* side bar
	noSent
	noRevoked
	noPending
	*/
function ExistingCareCollaborations({
	//displayLocation,
	noContentSectionContainers,
	noSent,
}: ExistingCareCollaborationsProps) {
	const accountContext = useContext(AccountManagerContext);

	const sent = accountContext.loadingCollaborators ? (
		<Loader />
	) : accountContext.careCollaborations ? (
		noSent ? null : (
			<SentCareCollaborations />
		)
	) : (
		<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
			<Typography>No Care Collaborations</Typography>
		</Stack>
	);

	const received = accountContext.loadingCollaborators ? (
		<Loader />
	) : accountContext.careCollaborations ? (
		<ReceivedCareCollaborations allowRemove={true} showCurrentlySelected={true} />
	) : (
		<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
			<Typography>No Care Collaborations</Typography>
		</Stack>
	);

	if (noContentSectionContainers) {
		return (
			<Box>
				<AcceptedCareCollaborations allowRemove={true} />

				{received}

				{sent}
			</Box>
		);
	} else {
		return (
			<>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ContentSection title="Care Collaborations - Accepted">
							<AcceptedCareCollaborations allowRemove={true} />
						</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<ContentSection title="Care Collaborations - Received">{received}</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<ContentSection title="Care Collaborations - Sent">{sent}</ContentSection>
					</Grid>
				</Grid>
			</>
		);
	}
}

export { ExistingCareCollaborations };
