/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API - Mobile
 * OpenAPI spec version: mobile
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerMobileOperationStatusGetRecommendedUspstfMobileResponse,
  GetRecommendedUspstfMobileParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getRecommendedUspstfMobile = (
    params?: GetRecommendedUspstfMobileParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMobileOperationStatusGetRecommendedUspstfMobileResponse>(
      {url: `/api/consumer/ConsumerMobile_Achievements/GetRecommendedUspstfMobile`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetRecommendedUspstfMobileQueryKey = (params?: GetRecommendedUspstfMobileParams,) => [`/api/consumer/ConsumerMobile_Achievements/GetRecommendedUspstfMobile`, ...(params ? [params]: [])];

    
export type GetRecommendedUspstfMobileQueryResult = NonNullable<Awaited<ReturnType<typeof getRecommendedUspstfMobile>>>
export type GetRecommendedUspstfMobileQueryError = unknown

export const useGetRecommendedUspstfMobile = <TData = Awaited<ReturnType<typeof getRecommendedUspstfMobile>>, TError = unknown>(
 params?: GetRecommendedUspstfMobileParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecommendedUspstfMobile>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecommendedUspstfMobileQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecommendedUspstfMobile>>> = ({ signal }) => getRecommendedUspstfMobile(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRecommendedUspstfMobile>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

