import { SwipeableDrawer, Stack, Box } from "@mui/material";
import { ModelsExplanationOfBenefit } from "api";
import AlertPopper from "common/components/DisplayElements/AlertPopper";
import { ListDisplay } from "common/components/DisplayElements/ListDisplay";
import { SectionLabel } from "common/components/Labels/SectionLabel";
import { DialogManager } from "common/managers/DialogManager";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { styleConverter } from "common/utilities/styleConverter";
import { palette, style } from "theme";

type RightAlertsDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => any;
	offset: number;
};

const eobRecord: ModelsExplanationOfBenefit = {
	diagnosisCodes: ["M4722", "M4182", "L309"],
	//updating to fulfill new model requirements from swagger - this hardcoded demo data should be removed
	patientReference: "",
	recordId: "",
};

const RightAlertsDrawer = (props: RightAlertsDrawerProps) => {
	return (
		<SwipeableDrawer
			anchor={"right"}
			variant="persistent"
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			onOpen={() => {
				props.setOpen(true);
			}}
			sx={{
				top: -30,
				zIndex: 2,
				"& .MuiDrawer-paper": {
					boxShadow: 4,
					borderLeft: "none",
				},
			}}
		>
			<Stack
				sx={{
					zIndex: 2,
					height: "100%",
					width: { xs: "100%", sm: "22rem" },
					backgroundColor: palette.core.lightBackground,
					paddingTop: style.headerHeight + props.offset + "px",
				}}
			>
				<SectionLabel>Alerts</SectionLabel>
				<Stack sx={{ padding: "1rem", width: "100%", boxSizing: "border-box" }}>
					<Box>
						<AlertPopper
							content={
								<>
									<Stack
										sx={{
											padding: "1rem",
											width: "100%",
											boxSizing: "border-box",
											"& li": {
												backgroundColor: palette.gray.light,
												marginBottom: ".5rem",
												borderRadius: ".5rem",
												color: palette.error.main,
											},
										}}
									>
										{eobRecord.diagnosisCodes && (
											<ListDisplay
												title=""
												items={eobRecord.diagnosisCodes?.map((d) => {
													let icd10 = StaticDataManager.data.ICD10CM_Package.code_map.get(d);
													if (icd10) {
														return (
															<Stack>
																<Box
																	component={"span"}
																	sx={{
																		color: palette.error.main,
																		cursor: "pointer",
																	}}
																	onClick={() => {
																		DialogManager.open("diagnosis-details", icd10?.ICD10Code);
																	}}
																>
																	{styleConverter.textColorMarkupX(icd10.Description, palette.error.main)}
																</Box>
															</Stack>
														);
													}
													return styleConverter.textColorMarkupX(d, palette.error.main);
												})}
											/>
										)}
									</Stack>
								</>
							}
						/>
					</Box>
				</Stack>
			</Stack>
		</SwipeableDrawer>
	);
};

export { RightAlertsDrawer };
