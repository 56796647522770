/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerOperationStatusRetrievePatientHealthDataResponseModel,
  ConsumerRetrievePatientHealthDataRequest,
  ConsumerOperationStatusApplyRiskModel,
  ConsumerApplyRiskModelRequest,
  ConsumerOperationStatusGetKeyFindingsResponseModel,
  ConsumerGetKeyFindingsRequest,
  ConsumerOperationStatusCareScoreResponseModel,
  ConsumerCareScoreRequest,
  ConsumerOperationStatusGetRecommendedUspstfResponse,
  GetRecommendedUSPSTFParams,
  ConsumerGetAllUSPSTFResult,
  GetAllUSPSTFParams,
  ConsumerPatientHealthDataAddUserIgnoreItemsResult,
  ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel,
  ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticResult,
  ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel,
  ConsumerPatientHealthDataUpdateRemindersUSPSTFResult,
  ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel,
  ConsumerOperationStatusCostOfCareResponseModel,
  ConsumerCostOfCareRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const retrievePatientEOBData = (
    consumerRetrievePatientHealthDataRequest: ConsumerRetrievePatientHealthDataRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusRetrievePatientHealthDataResponseModel>(
      {url: `/api/consumer/ConsumerPatientHealthData/RetrievePatientEOBData`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerRetrievePatientHealthDataRequest
    },
      options);
    }
  


    export type RetrievePatientEOBDataMutationResult = NonNullable<Awaited<ReturnType<typeof retrievePatientEOBData>>>
    export type RetrievePatientEOBDataMutationBody = ConsumerRetrievePatientHealthDataRequest
    export type RetrievePatientEOBDataMutationError = unknown

    export const useRetrievePatientEOBData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof retrievePatientEOBData>>, TError,{data: ConsumerRetrievePatientHealthDataRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof retrievePatientEOBData>>, {data: ConsumerRetrievePatientHealthDataRequest}> = (props) => {
          const {data} = props ?? {};

          return  retrievePatientEOBData(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof retrievePatientEOBData>>, TError, {data: ConsumerRetrievePatientHealthDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const applyRiskModel = (
    consumerApplyRiskModelRequest: ConsumerApplyRiskModelRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusApplyRiskModel>(
      {url: `/api/consumer/ConsumerPatientHealthData/ApplyRiskModel`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerApplyRiskModelRequest
    },
      options);
    }
  


    export type ApplyRiskModelMutationResult = NonNullable<Awaited<ReturnType<typeof applyRiskModel>>>
    export type ApplyRiskModelMutationBody = ConsumerApplyRiskModelRequest
    export type ApplyRiskModelMutationError = unknown

    export const useApplyRiskModel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof applyRiskModel>>, TError,{data: ConsumerApplyRiskModelRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof applyRiskModel>>, {data: ConsumerApplyRiskModelRequest}> = (props) => {
          const {data} = props ?? {};

          return  applyRiskModel(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof applyRiskModel>>, TError, {data: ConsumerApplyRiskModelRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getKeyFindings = (
    consumerGetKeyFindingsRequest: ConsumerGetKeyFindingsRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusGetKeyFindingsResponseModel>(
      {url: `/api/consumer/ConsumerPatientHealthData/GetKeyFindings`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerGetKeyFindingsRequest
    },
      options);
    }
  


    export type GetKeyFindingsMutationResult = NonNullable<Awaited<ReturnType<typeof getKeyFindings>>>
    export type GetKeyFindingsMutationBody = ConsumerGetKeyFindingsRequest
    export type GetKeyFindingsMutationError = unknown

    export const useGetKeyFindings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getKeyFindings>>, TError,{data: ConsumerGetKeyFindingsRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getKeyFindings>>, {data: ConsumerGetKeyFindingsRequest}> = (props) => {
          const {data} = props ?? {};

          return  getKeyFindings(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getKeyFindings>>, TError, {data: ConsumerGetKeyFindingsRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const careScore = (
    consumerCareScoreRequest: ConsumerCareScoreRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusCareScoreResponseModel>(
      {url: `/api/consumer/ConsumerPatientHealthData/CareScore`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerCareScoreRequest
    },
      options);
    }
  


    export type CareScoreMutationResult = NonNullable<Awaited<ReturnType<typeof careScore>>>
    export type CareScoreMutationBody = ConsumerCareScoreRequest
    export type CareScoreMutationError = unknown

    export const useCareScore = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof careScore>>, TError,{data: ConsumerCareScoreRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof careScore>>, {data: ConsumerCareScoreRequest}> = (props) => {
          const {data} = props ?? {};

          return  careScore(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof careScore>>, TError, {data: ConsumerCareScoreRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const getRecommendedUSPSTF = (
    params?: GetRecommendedUSPSTFParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerOperationStatusGetRecommendedUspstfResponse>(
      {url: `/api/consumer/ConsumerPatientHealthData/GetRecommendedUSPSTF`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetRecommendedUSPSTFQueryKey = (params?: GetRecommendedUSPSTFParams,) => [`/api/consumer/ConsumerPatientHealthData/GetRecommendedUSPSTF`, ...(params ? [params]: [])];

    
export type GetRecommendedUSPSTFQueryResult = NonNullable<Awaited<ReturnType<typeof getRecommendedUSPSTF>>>
export type GetRecommendedUSPSTFQueryError = unknown

export const useGetRecommendedUSPSTF = <TData = Awaited<ReturnType<typeof getRecommendedUSPSTF>>, TError = unknown>(
 params?: GetRecommendedUSPSTFParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecommendedUSPSTF>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecommendedUSPSTFQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecommendedUSPSTF>>> = ({ signal }) => getRecommendedUSPSTF(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRecommendedUSPSTF>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getAllUSPSTF = (
    params?: GetAllUSPSTFParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerGetAllUSPSTFResult>(
      {url: `/api/consumer/ConsumerPatientHealthData/GetAllUSPSTF`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetAllUSPSTFQueryKey = (params?: GetAllUSPSTFParams,) => [`/api/consumer/ConsumerPatientHealthData/GetAllUSPSTF`, ...(params ? [params]: [])];

    
export type GetAllUSPSTFQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUSPSTF>>>
export type GetAllUSPSTFQueryError = unknown

export const useGetAllUSPSTF = <TData = Awaited<ReturnType<typeof getAllUSPSTF>>, TError = unknown>(
 params?: GetAllUSPSTFParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUSPSTF>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllUSPSTFQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUSPSTF>>> = ({ signal }) => getAllUSPSTF(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUSPSTF>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const addUserIgnoreItems = (
    consumerPatientHealthDataAddUserIgnoreItemsRequestModel: ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerPatientHealthDataAddUserIgnoreItemsResult>(
      {url: `/api/consumer/ConsumerPatientHealthData/AddUserIgnoreItems`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerPatientHealthDataAddUserIgnoreItemsRequestModel
    },
      options);
    }
  


    export type AddUserIgnoreItemsMutationResult = NonNullable<Awaited<ReturnType<typeof addUserIgnoreItems>>>
    export type AddUserIgnoreItemsMutationBody = ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel
    export type AddUserIgnoreItemsMutationError = unknown

    export const useAddUserIgnoreItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserIgnoreItems>>, TError,{data: ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUserIgnoreItems>>, {data: ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  addUserIgnoreItems(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof addUserIgnoreItems>>, TError, {data: ConsumerPatientHealthDataAddUserIgnoreItemsRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const addUserIgnoreItemsDataAgnostic = (
    consumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel: ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticResult>(
      {url: `/api/consumer/ConsumerPatientHealthData/AddUserIgnoreItemsDataAgnostic`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel
    },
      options);
    }
  


    export type AddUserIgnoreItemsDataAgnosticMutationResult = NonNullable<Awaited<ReturnType<typeof addUserIgnoreItemsDataAgnostic>>>
    export type AddUserIgnoreItemsDataAgnosticMutationBody = ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel
    export type AddUserIgnoreItemsDataAgnosticMutationError = unknown

    export const useAddUserIgnoreItemsDataAgnostic = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addUserIgnoreItemsDataAgnostic>>, TError,{data: ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addUserIgnoreItemsDataAgnostic>>, {data: ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  addUserIgnoreItemsDataAgnostic(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof addUserIgnoreItemsDataAgnostic>>, TError, {data: ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const updateRemindersUspstfCodes = (
    consumerPatientHealthDataUpdateRemindersUSPSTFRequestModel: ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerPatientHealthDataUpdateRemindersUSPSTFResult>(
      {url: `/api/consumer/ConsumerPatientHealthData/UpdateRemindersUspstfCodes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerPatientHealthDataUpdateRemindersUSPSTFRequestModel
    },
      options);
    }
  


    export type UpdateRemindersUspstfCodesMutationResult = NonNullable<Awaited<ReturnType<typeof updateRemindersUspstfCodes>>>
    export type UpdateRemindersUspstfCodesMutationBody = ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel
    export type UpdateRemindersUspstfCodesMutationError = unknown

    export const useUpdateRemindersUspstfCodes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRemindersUspstfCodes>>, TError,{data: ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRemindersUspstfCodes>>, {data: ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  updateRemindersUspstfCodes(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateRemindersUspstfCodes>>, TError, {data: ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const costOfCare = (
    consumerCostOfCareRequest: ConsumerCostOfCareRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusCostOfCareResponseModel>(
      {url: `/api/consumer/ConsumerPatientHealthData/CostOfCare`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerCostOfCareRequest
    },
      options);
    }
  


    export type CostOfCareMutationResult = NonNullable<Awaited<ReturnType<typeof costOfCare>>>
    export type CostOfCareMutationBody = ConsumerCostOfCareRequest
    export type CostOfCareMutationError = unknown

    export const useCostOfCare = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costOfCare>>, TError,{data: ConsumerCostOfCareRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof costOfCare>>, {data: ConsumerCostOfCareRequest}> = (props) => {
          const {data} = props ?? {};

          return  costOfCare(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof costOfCare>>, TError, {data: ConsumerCostOfCareRequest}, TContext>(mutationFn, mutationOptions)
    }
    