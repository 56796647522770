import { ModelsDoctorRecord, ModelsDoctorStubX } from "api";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { ProviderTaxonomyDefinition } from "common/utilities/providerTaxonomyMapper";
import { DisplayInfo, Entity, EntityActionDescription, EntityActionNames } from "../EntityDefinitions";

interface DoctorRecord_Enhanced extends Omit<ModelsDoctorRecord, "code1" & "code2" & "code3" & "code4" & "code5"> {
	taxonomies: Array<ProviderTaxonomyDefinition>;
}

interface ProviderEntity extends Entity {
	entityTypeName: "provider" | "physician" | "facility";
	_providerRecord?: DoctorRecord_Enhanced;
}

const provider_entityTypeNames = ["provider", "physician", "facility"];
const isProviderEntity = (x: Entity): x is ProviderEntity => {
	if (provider_entityTypeNames.indexOf(x.entityTypeName) !== -1) {
		return true;
	}
	return false;
};

const ENHANCE_DoctorRecord = (doc_record: ModelsDoctorRecord): DoctorRecord_Enhanced => {
	if ((doc_record as CIM).taxonomies !== undefined) {
		return doc_record as DoctorRecord_Enhanced; // if it already went through return it back to avoid overwriting the taxonomies
	}
	// const taxonomy_code_slots: Array<"code1" | "code2" | "code3" | "code4" | "code5"> = ["code1", "code2", "code3", "code4", "code5"];
	const taxonomies: Array<ProviderTaxonomyDefinition> = [];

	// let dupCheck = new Set();
	doc_record.codes?.forEach((code) => {
		let t = StaticDataManager.data.providerTaxonomyPackage?.Code_Map.get(code);
		if (t) {
			taxonomies.push(t);
		} else {
			window.RadiusCare.log_issue({ message: `Taxonomy Code ${code} not found`, file: "ProviderEntity" });
		}
	});
	// taxonomy_code_slots.forEach((x) => {
	// 	let code = doc_record[x];
	// 	if (typeof code === "string") {
	// 		if (dupCheck.has(code)) {
	// 			return;
	// 		}
	// 		dupCheck.add(code);
	// 		let t = StaticDataManager.data.providerTaxonomyPackage?.Code_Map.get(code);
	// 		if (t) {
	// 			taxonomies.push(t);
	// 		} else {
	// 			window.RadiusCare.log_issue({ message: `Taxonomy Code ${code} not found`, file: "ProviderEntity" });
	// 		}
	// 	}
	// });
	// delete doc_record.code1;
	// delete doc_record.code2;
	// delete doc_record.code3;
	// delete doc_record.code4;
	// delete doc_record.code5;
	(doc_record as DoctorRecord_Enhanced).taxonomies = taxonomies;
	return doc_record as DoctorRecord_Enhanced;
};

const CONVERT_DoctorStubX_Entity = (
	doctor: ModelsDoctorStubX,
	displayInfo?: Array<DisplayInfo> | "AUTO",
	actions?: Array<EntityActionNames | EntityActionDescription>
): ProviderEntity => {
	if (displayInfo === "AUTO") {
		displayInfo = [];
		if (typeof doctor.distanceMiles === "number") {
			displayInfo.push({ title: "Distance", value: doctor.distanceMiles > 10 ? Math.round(doctor.distanceMiles) : doctor.distanceMiles });
		}
	} else {
		displayInfo = displayInfo ?? [];
	}

	// displayInfo.push({ title: "Accepting New Patients", value: "N/A", colorType: "Yes/No" });

	let ent: ProviderEntity = {
		entityTypeName: "provider",
		imageSrc: "",
		title: doctor.name ?? "",
		descriptor: doctor.specialties ?? "",
		displayInfo: displayInfo,
		actions: actions ?? [], // set default actions here
		radiusRating: doctor.radiusRating ?? -1,
		// _providerRecord: ENHANCE_DoctorRecord(doctor),
	};
	// if (doctor.entityTypeCode === "2") {
	// 	ent.title = doctor.organizationName ?? "Unknown";
	// }

	return ent;
};

const CONVERT_DoctorRecord_Entity = (
	doctor: ModelsDoctorRecord,
	displayInfo?: Array<DisplayInfo> | "AUTO",
	actions?: Array<EntityActionNames | EntityActionDescription>
): ProviderEntity => {
	if (displayInfo === "AUTO") {
		displayInfo = [];
		if (typeof doctor.distanceMiles === "number") {
			displayInfo.push({ title: "Distance", value: doctor.distanceMiles > 10 ? Math.round(doctor.distanceMiles) : doctor.distanceMiles });
		}
	} else {
		displayInfo = displayInfo ?? [];
	}

	// displayInfo.push({ title: "Accepting New Patients", value: "N/A", colorType: "Yes/No" });
	const enhancedRecord = ENHANCE_DoctorRecord(doctor);
	let ent: ProviderEntity = {
		entityTypeName: "provider",
		imageSrc: "",
		title: ConstructName_ModelDoctorRecord(doctor),
		descriptor: `${doctor.address ?? ""}, ${doctor.city ?? ""}, ${doctor.state ?? ""} ${doctor.zip ?? ""}`,
		displayInfo: displayInfo,
		actions: actions ?? [], // set default actions here
		radiusRating: doctor.radiusRating ?? -1,
		_providerRecord: enhancedRecord,
		specialty: enhancedRecord.taxonomies.map((x) => x.displayName).join(),
	};
	if (doctor.entityTypeCode === "2") {
		ent.title = doctor.organizationName ?? "Unknown";
		ent.entityTypeName = "facility";
	}

	return ent;
};

const ConstructName_ModelDoctorRecord = (doctor: ModelsDoctorRecord) => {
	if (doctor.entityTypeCode === "2") {
		return doctor.organizationName ?? "Organization (Name not Found)";
	}
	return `${doctor.namePrefix ?? ""} ${doctor.firstName ?? ""} ${doctor.lastName ?? ""}` + (doctor.credential ? " " + doctor.credential : "");
};

const CREATE_UNKNOWN_DoctorRecord_Entity = (
	partial_identification: { npi?: string; entityTypeCode?: string },
	displayInfo?: Array<DisplayInfo>
): ProviderEntity => {
	displayInfo = displayInfo ?? [];

	let title_prefix = "Unknown Provider";
	if (partial_identification?.entityTypeCode === "2") {
		title_prefix = "Unknown Organization";
	}

	let ent: ProviderEntity = {
		entityTypeName: "provider",
		imageSrc: "",
		title: `${title_prefix} ${partial_identification.npi ? "#" + partial_identification.npi : null}`,
		descriptor: ``,
		displayInfo: displayInfo,
		radiusRating: -2,
	};

	return ent;
};

export {
	isProviderEntity,
	ENHANCE_DoctorRecord,
	CONVERT_DoctorRecord_Entity,
	CREATE_UNKNOWN_DoctorRecord_Entity,
	ConstructName_ModelDoctorRecord,
	CONVERT_DoctorStubX_Entity,
};
export type { ProviderEntity, DoctorRecord_Enhanced };
