import {
  HealthIcon,
  health_icons_svg,
} from "common/components/Icons/HealthIcon";
import { IconToggle } from "common/components/Icons/IconToggle";

type MenuBadgeProps = {
  onClick: () => any;
  active?: boolean;
};

function MenuBadge({ onClick, active }: MenuBadgeProps) {
  return (
    <IconToggle
      onClick={onClick}
      icon={<HealthIcon iconSVG={health_icons_svg.ui_menu} />}
      active={active}
    />
  );
}

export { MenuBadge };
