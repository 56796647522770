import Box from "@mui/material/Box";
import { ConsumerEditConsumerUserInfoForm, editConsumerUser } from "api";
// import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
// import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { AppBar, Container, MenuItem, Stack, Typography, Button } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { style } from "theme";
import { useCallback, useContext, useMemo, useReducer, useState } from "react";
import { useQueryClient } from "react-query";
import { AccountManagerContext } from "common/managers/AccountManager";
import dayjs from "dayjs";
import { StandardDatePicker } from "common/components/Inputs/StandardDatePicker";
import { FormErrors, FormValidationResponse, formUtilities } from "common/forms/formUtilities";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import displayConverter from "common/utilities/displayConverter";
import { FormError } from "common/forms/FormAlerts";
import { PhoneMaskedInput } from "./InputMasks";

interface UpdateUserInformationFormValues {
	phoneNumber?: string;
	email?: string;
	zip?: string;
	gender?: string;
	name?: string;
	dateofbirth?: any;
}

function formatPhoneNumber(phoneNumberString: string | undefined) {
	var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? "+1 " : "";
		return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
	}
	return undefined;
}
function UpdateUserInformationForm() {
	const [errors, set_errors] = useState<FormErrors | undefined>();
	const accountContext = useContext(AccountManagerContext);
	const queryClient = useQueryClient(); // not having this here breaks the invalidateQueries
	const [consumerUser, demographic] = useMemo(() => {
		let userDetails = accountContext.accountManager.userDetails;
		return [userDetails?.consumerUser, userDetails?.patientDemographics ? userDetails?.patientDemographics[0] : undefined];
	}, [accountContext.accountManager.userDetails]);

	const { enqueueSnackbar } = useSnackbar();

	const name = useMemo(() => {
		if (demographic && demographic.name) {
			return demographic.name;
		}
		let name = consumerUser?.name;
		if (name) {
			return name;
		}
		return null;
	}, [consumerUser, demographic]);

	const [disableDOB, disableName, disableGender] = useMemo(() => {
		if (!demographic) {
			return [false, false, false];
		}
		let d_dob = demographic?.birth_date_string !== "" && dayjs(demographic?.birth_date_string)?.year() > 1 ? true : false;
		let d_name = !!demographic.name;
		let d_gender = !!demographic.gender;
		return [d_dob, d_name, d_gender];
	}, [demographic]);

	//VALUES
	const [formValues, dispatch_formValues] = useReducer(
		(x: UpdateUserInformationFormValues, action: Partial<UpdateUserInformationFormValues>) => {
			return formUtilities.formValuesReducer<UpdateUserInformationFormValues>(x, action);
		},
		{
			phoneNumber: consumerUser?.contact?.phone ?? "",
			email: consumerUser?.email ?? "",
			zip: consumerUser?.location?.zip ?? "",
			name: name ?? "",
			gender: demographic?.gender === "Male" || demographic?.gender === "Female" ? demographic.gender : consumerUser?.gender ?? "",
			dateofbirth:
				demographic && demographic?.birth_date_string !== "" && dayjs(demographic?.birth_date_string)?.year() > 1
					? dayjs(demographic?.birth_date_string)
					: dayjs(consumerUser?.dateOfBirthString),
		}
	);

	/*
██    ██  █████  ██      ██ ██████   █████  ████████ ██  ██████  ███    ██ 
██    ██ ██   ██ ██      ██ ██   ██ ██   ██    ██    ██ ██    ██ ████   ██ 
██    ██ ███████ ██      ██ ██   ██ ███████    ██    ██ ██    ██ ██ ██  ██ 
 ██  ██  ██   ██ ██      ██ ██   ██ ██   ██    ██    ██ ██    ██ ██  ██ ██ 
  ████   ██   ██ ███████ ██ ██████  ██   ██    ██    ██  ██████  ██   ████
	*/
	const validate = useMemo(() => {
		// console.debug("useMemo validate CareRiskForm", formValues);
		return (): FormValidationResponse<UpdateUserInformationFormValues> => {
			// set_apiResponse(null);
			// console.debug("CareRisk - validate");
			const errs: {
				[x: string]: FormError;
			} = {};
			if (formValues.zip && formValues.zip !== "" && formValues.zip.length < 5) {
				errs.zip = { type: "required", message: "Zip Code must be 5 digits" };
			}
			var cleaned = ("" + formValues.phoneNumber).replace(/\D/g, "");
			if (cleaned.length !== 11) {
				errs.phone = { type: "required", message: "Phone number invalid" };
			}
			set_errors(errs);
			// console.debug(errs);
			let validationResponse: FormValidationResponse<UpdateUserInformationFormValues> = {
				isValid: Object.getOwnPropertyNames(errs).length === 0,
				errors: errs,
				values: formValues as UpdateUserInformationFormValues,
			};
			return validationResponse;
		};
	}, [
		formValues,
		set_errors,
		//	, addressFormValidate
	]);

	const submit = useCallback(
		(data: UpdateUserInformationFormValues) => {
			let requestValues: ConsumerEditConsumerUserInfoForm = {
				contact: {
					email: data.email,
					phone: formatPhoneNumber(data.phoneNumber),
				},
				zip: data.zip,
				name: data.name,
				gender: data.gender,
				dateOfBirth: dayjs(data.dateofbirth).format(),
			};
			// console.debug(requestValues);
			// return false;

			return editConsumerUser(requestValues).then(
				//SUCCESS
				() => {
					enqueueSnackbar("Info Saved", {
						variant: "success",
					});
					queryClient?.invalidateQueries("retrieveRecommendedUSPSTF");
					queryClient?.invalidateQueries("useKeyFindings");
					accountContext.accountManager.setUser("REFRESH");
					DialogManager.close("update-info");
				},
				//FAILURE
				() => {
					enqueueSnackbar("Error Saving Changes", {
						variant: "error",
					});
				}
			);
		},
		[accountContext.accountManager, queryClient, enqueueSnackbar]
	);

	const handleSubmit = useMemo(() => {
		return () => {
			// console.debug(formValues);
			let v = validate();
			if (v.isValid) {
				submit(formValues);
			}
		};
	}, [formValues, validate, submit]);

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Update Info</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("update-info");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
				<Container maxWidth={"xs"}>
					<Box component="form">
						<ContentSection flexGrow={1} title="Update Info">
							<Stack spacing={2}>
								<StandardTextField
									data-styletype="infodisplay"
									label="Name"
									variant="filled"
									disabled={disableName}
									sx={{ flexGrow: 1, width: "100%", paddingBottom: ".5rem" }}
									value={formValues.name}
									fieldname="name"
									onChange={(x) => {
										dispatch_formValues({ name: x.target.value });
									}}
									errors={errors}
									// helperText={error ? error.message : null}
								/>

								<StandardSelect
									label="Gender"
									labelId="gender_label"
									adaptive_label={true}
									name="gender"
									value={formValues.gender}
									disabled={disableGender}
									variant="standard"
									onChange={(x) => {
										let val = x.target.value;
										if (val === "Female" || val === "Male" || val === "") {
											dispatch_formValues({ gender: val });
										}
									}}
								>
									<MenuItem value={""}>Not Selected</MenuItem>
									<MenuItem value={"Male"}>Male</MenuItem>
									<MenuItem value={"Female"}>Female</MenuItem>
								</StandardSelect>

								<StandardDatePicker
									label="Date of Birth"
									value={dayjs(formValues.dateofbirth ?? null)}
									disableFuture
									disabled={disableDOB}
									onChange={(x, y) => {
										if (x) {
											dispatch_formValues({ dateofbirth: x.toDate() });
										} else {
											dispatch_formValues({ dateofbirth: undefined });
										}
									}}
									fieldname="dob"
									errors={errors}
								/>
								<StandardTextField
									data-styletype="infodisplay"
									label="Phone Number"
									variant="filled"
									value={formValues.phoneNumber}
									errors={errors}
									fieldname={"phone"}
									onChange={(x) => {
										let val = x.target.value;

										dispatch_formValues({ phoneNumber: val.replace(/\D/g, "") });
									}}
									inputProps={{ unmask: true }}
									InputProps={{
										inputComponent: PhoneMaskedInput as any,
									}}
								/>
								<StandardTextField
									data-styletype="infodisplay"
									label="Zip code"
									variant="filled"
									sx={{ flexGrow: 1, width: "100%", paddingBottom: ".5rem" }}
									value={formValues.zip}
									onChange={(x) => {
										let value = displayConverter.filterNonNumeric(x.target.value);
										if (value.length <= 5) {
											dispatch_formValues({ zip: value });
										}
									}}
									errors={errors}
									fieldname={"zip"}
								/>

								<Box sx={{ paddingTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
									<Button type="button" onClick={handleSubmit}>
										Save Information
									</Button>
								</Box>
							</Stack>
						</ContentSection>
					</Box>
				</Container>
			</Stack>
		</>
	);
}

export { UpdateUserInformationForm };
