import { Navigate } from "react-router";
import { useAuthFlow } from "../../auth/useAuthFlow";
import SignUpForm from "../../forms/SignUpForm";

function SignUpPage() {
	const flow = useAuthFlow();
	if (flow.step === "SignIn") {
		return <SignUpForm {...flow} />;
	}
	return <Navigate replace to="/" />;
}

export default SignUpPage;
