import {
	findHigherRatedCare,
	ConsumerDoctorFindHigherRatedCareRequestModel,
	ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel,
	ModelsClientLocation,
	ConsumerDoctorProviderTypeScore,
	ConsumerDoctorProviderTypeScoresRequestModel,
} from "api";
import { useQuery, UseQueryResult } from "react-query";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { ConsumerCareScoreResponseModel_Extended } from "api_extended/consumer-patient-health-data/care-score_extended";

async function findHigherRatedCareX(
	params: FindHigherRatedCareParamsX
): Promise<ProcessedResponse<ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel | null>> {
	if (!params.requestPackage) {
		return apiResponseReader.createProcessedResponse_NOREQUEST<ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel>(
			"Missing Required Parameters"
		);
	}
	let result = await findHigherRatedCare(params.requestPackage);
	return apiResponseReader.processResponse(result as unknown as ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel);
}

type FindHigherRatedCareParamsX = {
	requestPackage: ConsumerDoctorFindHigherRatedCareRequestModel | null;
};
function useQ_findHigherRatedCare(
	paramsX: FindHigherRatedCareParamsX
): UseQueryResult<ProcessedResponse<ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel>> {
	let fac_token = paramsX.requestPackage?.providerTypeScores.facility?.reduce((rollup, val) => `${rollup}+${val.type}`, "");
	return useQuery(
		[
			"clientlocation",
			"retrieveCareScore",
			`retrieveCareScore[
				doctors-${paramsX.requestPackage?.providerTypeScores.doctor?.length}
				_facility-${fac_token}
				_lat-${paramsX.requestPackage?.clientLocation.latitude}
				_long-${paramsX.requestPackage?.clientLocation.longitude}
				_zip-${paramsX.requestPackage?.clientLocation.zipcode}
				_inNetwork-${paramsX.requestPackage?.inNetwork}`,
		],
		findHigherRatedCareX.bind(null, paramsX),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);
}

function create_ConsumerDoctorFindHigherRatedCareRequestModel(
	clientLocation: ModelsClientLocation,
	careScoreResponse: ConsumerCareScoreResponseModel_Extended,
	careScoreDetailsCategory: string,
	inNetwork: boolean
): ConsumerDoctorFindHigherRatedCareRequestModel {
	let providerTypeScores: ConsumerDoctorProviderTypeScoresRequestModel = { doctor: [], facility: [] };
	let findCareRequest: ConsumerDoctorFindHigherRatedCareRequestModel = { providerTypeScores, clientLocation, inNetwork };
	if (careScoreDetailsCategory === "doctor_clinician") {
		careScoreResponse.providers["doctor_clinician"].forEach((x) => {
			let pts: ConsumerDoctorProviderTypeScore;
			x.taxonomies.forEach((t) => {
				if (t.classification) {
					providerTypeScores.doctor?.some((v) => {
						if (v.type === t.classification) {
							pts = v;
							return true;
						}
						return false;
					});
					if (!pts) {
						pts = { type: t.classification, scores: [] };
						providerTypeScores.doctor?.push(pts);
					}
					pts.scores.push(x.radiusRating ?? 0);
				}
			});
		});
	} else {
		if (careScoreResponse.providers[careScoreDetailsCategory]) {
			careScoreResponse.providers[careScoreDetailsCategory].forEach((x) => {
				let pts: ConsumerDoctorProviderTypeScore;
				x.taxonomies.forEach((t) => {
					if (t.grouping) {
						providerTypeScores.facility?.some((v) => {
							if (v.type === t.grouping) {
								pts = v;
								return true;
							}
							return false;
						});
						if (!pts) {
							pts = { type: t.grouping, scores: [] };
							providerTypeScores.facility?.push(pts);
						}
						pts.scores.push(x.radiusRating ?? 0);
					}
				});
			});
		} else {
			window.RadiusCare.log_issue({
				file: "useQ_findHigherRatedCare.tsx",
				message: `"${careScoreDetailsCategory}" was not a valid property of careScoreResponse.providers`,
			});
		}
	}
	return findCareRequest;
}

export { useQ_findHigherRatedCare, create_ConsumerDoctorFindHigherRatedCareRequestModel };
