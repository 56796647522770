import { Box, Button, Stack, Typography } from "@mui/material";
import { acceptCareCollaboration, CareCollaboratorAuthorization, removeReceivedCareCollaborator, setActiveCareCollaboration } from "api";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import { StandardSwitch } from "common/components/Inputs/StandardSwitch";
import { SimpleModal } from "common/components/Modals/SimpleModal";
import { AccountManagerContext } from "common/managers/AccountManager";
import { dateConverter } from "common/utilities/dateConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { useContext, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { palette } from "theme";

function ReceivedCareColloboraterAuth({
	careCollaboratorAuthorization,
	allowRemove,
}: {
	careCollaboratorAuthorization: CareCollaboratorAuthorization;
	allowRemove: boolean;
}) {
	const [activateRemoveModal, set_activateRemoveModal] = useState<boolean>(false);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const account = useContext(AccountManagerContext);

	const isCurrentlySelected = useMemo(() => {
		return careCollaboratorAuthorization.isCurrentlySelectedByCollaborator;
	}, [careCollaboratorAuthorization]);

	const displayName = useMemo(() => {
		return careCollaboratorAuthorization.ownerDisplayName
			? `${careCollaboratorAuthorization.ownerDisplayName} (${careCollaboratorAuthorization.email})`
			: careCollaboratorAuthorization.email;
	}, [careCollaboratorAuthorization]);

	return (
		<Box
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
			}}
		>
			<Stack className="care-collaborator-tile" sx={{ alignItems: "center", flexDirection: { xs: "column", sm: "row" } }}>
				{careCollaboratorAuthorization.status === "ACTIVE" ? (
					<StandardSwitch
						color={"blue"}
						size="medium"
						disabled={false}
						onChange={async () => {
							if (careCollaboratorAuthorization.isCurrentlySelectedByCollaborator) {
								await setActiveCareCollaboration({
									authId: undefined,
									patientAccountEmail: undefined,
								});
							} else {
								await setActiveCareCollaboration({
									authId: careCollaboratorAuthorization.id,
									patientAccountEmail: careCollaboratorAuthorization.email,
								});

								setTimeout(() => {
									navigate("/healthsnapshot");
								}, 1000);
							}

							account.accountManager.set_displayModal(null); // close the 'InactiveCollaborationWithNoDirectDatasource' modal if active
							queryClient.invalidateQueries();
							account.refreshCollaborators();
						}}
						checked={careCollaboratorAuthorization.isCurrentlySelectedByCollaborator}
					/>
				) : null}
				<Box sx={{ flex: 1, width: { xs: "100%", sm: "auto" }, pb: { xs: 1, sm: 0 } }}>
					{isCurrentlySelected ? (
						<Typography
							style={{
								fontWeight: 600,
								color: palette.core.forestgreen,
							}}
						>
							Currently Active
						</Typography>
					) : null}

					<Typography>
						{careCollaboratorAuthorization.isAuthorized && careCollaboratorAuthorization.status !== "PENDING"
							? styleConverter.textColorMarkupX(`<<${displayName}>>`)
							: styleConverter.textColorMarkupX(
									`<<${displayName}>> on <<${dateConverter["YYYY-MM-DD_to_mM/dD/YY"](careCollaboratorAuthorization.createdAt)}>>`
							  )}
					</Typography>
				</Box>

				<Stack
					className="care-collaborator-actions"
					sx={{ width: { xs: "100%", sm: "auto" }, flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end" }}
				>
					{/* {careCollaboratorAuthorization.status === "PENDING" ? (
						<Button
							variant="outlined"
							color="success"
							onClick={async () => {
								await acceptCareCollaboration({
									authId: careCollaboratorAuthorization.id,
									patientAccountEmail: careCollaboratorAuthorization.email,
								});
								account.refreshCollaborators();
							}}
						>
							Accept
						</Button>
					) : null} */}

					{(careCollaboratorAuthorization.status === "ACTIVE" && !isCurrentlySelected && allowRemove) ||
					careCollaboratorAuthorization.status === "PENDING" ? (
						<Box style={{ display: "flex", flexDirection: "column" }}>
							<OptionsButton
								content={
									<Box>
										{careCollaboratorAuthorization.status === "PENDING" ? (
											<Button
												variant="text"
												onClick={async () => {
													await acceptCareCollaboration({
														authId: careCollaboratorAuthorization.id,
														patientAccountEmail: careCollaboratorAuthorization.email,
													});
													account.refreshCollaborators();
												}}
											>
												Accept
											</Button>
										) : null}
										{allowRemove ? (
											<Button
												variant="text"
												color="error"
												onClick={async () => {
													set_activateRemoveModal(true);
												}}
											>
												Remove
											</Button>
										) : null}
									</Box>
								}
								// content={
								// 	<Box
								// 		style={{ display: "flex", flexDirection: "column" }}
								// 		sx={{ "& > .MuiButton-root:not(:last-child)": { marginBottom: "16px" } }}
								// 	>
								// 		<Button
								// 			variant="outlined"
								// 			color="error"
								// 			// sx={{ marginTop: "8px" }}
								// 			onClick={async () => {
								// 				set_activateRemoveModal(true);
								// 			}}
								// 		>
								// 			Remove
								// 		</Button>
								// 		{/* ) : null} */}
								// 	</Box>
								// }
							/>
						</Box>
					) : null}
				</Stack>
			</Stack>

			{careCollaboratorAuthorization.status === "REMOVED_BY_COLLABORATOR" && careCollaboratorAuthorization.revokedAt ? (
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						style={{
							padding: "4px 8px",
							fontWeight: 600,
							color: palette.core.preventative,
						}}
					>
						Removed By Collaborator on ({dateConverter["YYYY-MM-DD_to_mM/dD/YY"](careCollaboratorAuthorization.revokedAt)})
					</Typography>
				</Box>
			) : careCollaboratorAuthorization.status === "REVOKED" || careCollaboratorAuthorization.revokedAt ? (
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						style={{
							padding: "4px 8px",
							fontWeight: 600,
							color: palette.core.preventative,
						}}
					>
						Invite Revoked ({dateConverter["YYYY-MM-DD_to_mM/dD/YY"](careCollaboratorAuthorization.revokedAt)})
					</Typography>
				</Box>
			) : null}

			<SimpleModal
				open={activateRemoveModal}
				title="Remove Care Collaboration"
				onClose={() => {
					set_activateRemoveModal(false);
				}}
			>
				<Button
					color="error"
					fullWidth={true}
					variant="outlined"
					sx={{ mb: 1, mt: 2 }}
					onClick={async () => {
						await removeReceivedCareCollaborator({ inviterEmail: careCollaboratorAuthorization.email });
						account.refreshCollaborators();
						set_activateRemoveModal(false);
					}}
				>
					Confirm
				</Button>
				<Button
					fullWidth={true}
					variant="outlined"
					onClick={async () => {
						set_activateRemoveModal(false);
					}}
				>
					Cancel
				</Button>
			</SimpleModal>
		</Box>
	);
}

export { ReceivedCareColloboraterAuth };
