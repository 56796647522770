/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerAddConsumerUserResult,
  ConsumerAddConsumerUserForm,
  ConsumerEditConsumerUserInfoResult,
  ConsumerEditConsumerUserInfoForm,
  ConsumerEditAlertPreferencesResult,
  EditAlertPreferencesParams,
  ConsumerEditConsumerUserLocationResult,
  EditConsumerUserLocationParams,
  ConsumerOperationStatusUpdateConsumerUserPreferences,
  ConsumerUpdateConsumerUserPreferencesRequestModel,
  ConsumerSendUserFeedbackResult,
  SendUserFeedbackParams,
  ConsumerConfirmVerificationCodeNotSignedInResult,
  ConsumerConfirmVerificationCodeNotSignedInForm,
  ConsumerResendVerificationCodeResult,
  ConsumerResendVerificationCodeCommand,
  ProvidersConfigDebugReport,
  GetConfigDebugReportParams,
  ConsumerGetConsumerUserDetailsResult
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const addConsumerUser = (
    consumerAddConsumerUserForm: ConsumerAddConsumerUserForm,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerAddConsumerUserResult>(
      {url: `/api/consumer/ConsumerUsers`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: consumerAddConsumerUserForm
    },
      options);
    }
  


    export type AddConsumerUserMutationResult = NonNullable<Awaited<ReturnType<typeof addConsumerUser>>>
    export type AddConsumerUserMutationBody = ConsumerAddConsumerUserForm
    export type AddConsumerUserMutationError = unknown

    export const useAddConsumerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addConsumerUser>>, TError,{data: ConsumerAddConsumerUserForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addConsumerUser>>, {data: ConsumerAddConsumerUserForm}> = (props) => {
          const {data} = props ?? {};

          return  addConsumerUser(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof addConsumerUser>>, TError, {data: ConsumerAddConsumerUserForm}, TContext>(mutationFn, mutationOptions)
    }
    export const editConsumerUser = (
    consumerEditConsumerUserInfoForm: ConsumerEditConsumerUserInfoForm,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerEditConsumerUserInfoResult>(
      {url: `/api/consumer/ConsumerUsers/EditConsumerUser`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerEditConsumerUserInfoForm
    },
      options);
    }
  


    export type EditConsumerUserMutationResult = NonNullable<Awaited<ReturnType<typeof editConsumerUser>>>
    export type EditConsumerUserMutationBody = ConsumerEditConsumerUserInfoForm
    export type EditConsumerUserMutationError = unknown

    export const useEditConsumerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editConsumerUser>>, TError,{data: ConsumerEditConsumerUserInfoForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editConsumerUser>>, {data: ConsumerEditConsumerUserInfoForm}> = (props) => {
          const {data} = props ?? {};

          return  editConsumerUser(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof editConsumerUser>>, TError, {data: ConsumerEditConsumerUserInfoForm}, TContext>(mutationFn, mutationOptions)
    }
    export const editAlertPreferences = (
    params?: EditAlertPreferencesParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerEditAlertPreferencesResult>(
      {url: `/api/consumer/ConsumerUsers/EditAlertPreferences`, method: 'post',
        params
    },
      options);
    }
  


    export type EditAlertPreferencesMutationResult = NonNullable<Awaited<ReturnType<typeof editAlertPreferences>>>
    
    export type EditAlertPreferencesMutationError = unknown

    export const useEditAlertPreferences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editAlertPreferences>>, TError,{params?: EditAlertPreferencesParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editAlertPreferences>>, {params?: EditAlertPreferencesParams}> = (props) => {
          const {params} = props ?? {};

          return  editAlertPreferences(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof editAlertPreferences>>, TError, {params?: EditAlertPreferencesParams}, TContext>(mutationFn, mutationOptions)
    }
    export const editConsumerUserLocation = (
    params?: EditConsumerUserLocationParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerEditConsumerUserLocationResult>(
      {url: `/api/consumer/ConsumerUsers/EditConsumerUserLocation`, method: 'post',
        params
    },
      options);
    }
  


    export type EditConsumerUserLocationMutationResult = NonNullable<Awaited<ReturnType<typeof editConsumerUserLocation>>>
    
    export type EditConsumerUserLocationMutationError = unknown

    export const useEditConsumerUserLocation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof editConsumerUserLocation>>, TError,{params?: EditConsumerUserLocationParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof editConsumerUserLocation>>, {params?: EditConsumerUserLocationParams}> = (props) => {
          const {params} = props ?? {};

          return  editConsumerUserLocation(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof editConsumerUserLocation>>, TError, {params?: EditConsumerUserLocationParams}, TContext>(mutationFn, mutationOptions)
    }
    export const updateConsumerUserPreferences = (
    consumerUpdateConsumerUserPreferencesRequestModel: ConsumerUpdateConsumerUserPreferencesRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusUpdateConsumerUserPreferences>(
      {url: `/api/consumer/ConsumerUsers/UpdateConsumerUserPreferences`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerUpdateConsumerUserPreferencesRequestModel
    },
      options);
    }
  


    export type UpdateConsumerUserPreferencesMutationResult = NonNullable<Awaited<ReturnType<typeof updateConsumerUserPreferences>>>
    export type UpdateConsumerUserPreferencesMutationBody = ConsumerUpdateConsumerUserPreferencesRequestModel
    export type UpdateConsumerUserPreferencesMutationError = unknown

    export const useUpdateConsumerUserPreferences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateConsumerUserPreferences>>, TError,{data: ConsumerUpdateConsumerUserPreferencesRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateConsumerUserPreferences>>, {data: ConsumerUpdateConsumerUserPreferencesRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  updateConsumerUserPreferences(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateConsumerUserPreferences>>, TError, {data: ConsumerUpdateConsumerUserPreferencesRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const sendUserFeedback = (
    params?: SendUserFeedbackParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerSendUserFeedbackResult>(
      {url: `/api/consumer/ConsumerUsers/SendUserFeedback`, method: 'post',
        params
    },
      options);
    }
  


    export type SendUserFeedbackMutationResult = NonNullable<Awaited<ReturnType<typeof sendUserFeedback>>>
    
    export type SendUserFeedbackMutationError = unknown

    export const useSendUserFeedback = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendUserFeedback>>, TError,{params?: SendUserFeedbackParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendUserFeedback>>, {params?: SendUserFeedbackParams}> = (props) => {
          const {params} = props ?? {};

          return  sendUserFeedback(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendUserFeedback>>, TError, {params?: SendUserFeedbackParams}, TContext>(mutationFn, mutationOptions)
    }
    export const confirmVerificationCode = (
    consumerConfirmVerificationCodeNotSignedInForm: ConsumerConfirmVerificationCodeNotSignedInForm,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerConfirmVerificationCodeNotSignedInResult>(
      {url: `/api/consumer/ConsumerUsers/ConfirmVerificationCode`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerConfirmVerificationCodeNotSignedInForm
    },
      options);
    }
  


    export type ConfirmVerificationCodeMutationResult = NonNullable<Awaited<ReturnType<typeof confirmVerificationCode>>>
    export type ConfirmVerificationCodeMutationBody = ConsumerConfirmVerificationCodeNotSignedInForm
    export type ConfirmVerificationCodeMutationError = unknown

    export const useConfirmVerificationCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof confirmVerificationCode>>, TError,{data: ConsumerConfirmVerificationCodeNotSignedInForm}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof confirmVerificationCode>>, {data: ConsumerConfirmVerificationCodeNotSignedInForm}> = (props) => {
          const {data} = props ?? {};

          return  confirmVerificationCode(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof confirmVerificationCode>>, TError, {data: ConsumerConfirmVerificationCodeNotSignedInForm}, TContext>(mutationFn, mutationOptions)
    }
    export const resendVerificationCode = (
    consumerResendVerificationCodeCommand: ConsumerResendVerificationCodeCommand,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerResendVerificationCodeResult>(
      {url: `/api/consumer/ConsumerUsers/ResendVerificationCode`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerResendVerificationCodeCommand
    },
      options);
    }
  


    export type ResendVerificationCodeMutationResult = NonNullable<Awaited<ReturnType<typeof resendVerificationCode>>>
    export type ResendVerificationCodeMutationBody = ConsumerResendVerificationCodeCommand
    export type ResendVerificationCodeMutationError = unknown

    export const useResendVerificationCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendVerificationCode>>, TError,{data: ConsumerResendVerificationCodeCommand}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendVerificationCode>>, {data: ConsumerResendVerificationCodeCommand}> = (props) => {
          const {data} = props ?? {};

          return  resendVerificationCode(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof resendVerificationCode>>, TError, {data: ConsumerResendVerificationCodeCommand}, TContext>(mutationFn, mutationOptions)
    }
    export const changePasswordComplete = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<void>(
      {url: `/api/consumer/ConsumerUsers/ChangePasswordComplete`, method: 'get', signal
    },
      options);
    }
  

export const getChangePasswordCompleteQueryKey = () => [`/api/consumer/ConsumerUsers/ChangePasswordComplete`];

    
export type ChangePasswordCompleteQueryResult = NonNullable<Awaited<ReturnType<typeof changePasswordComplete>>>
export type ChangePasswordCompleteQueryError = unknown

export const useChangePasswordComplete = <TData = Awaited<ReturnType<typeof changePasswordComplete>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof changePasswordComplete>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getChangePasswordCompleteQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof changePasswordComplete>>> = ({ signal }) => changePasswordComplete(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof changePasswordComplete>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getConfigDebugReport = (
    params?: GetConfigDebugReportParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ProvidersConfigDebugReport>(
      {url: `/api/consumer/ConsumerUsers/GetConfigDebugReport`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetConfigDebugReportQueryKey = (params?: GetConfigDebugReportParams,) => [`/api/consumer/ConsumerUsers/GetConfigDebugReport`, ...(params ? [params]: [])];

    
export type GetConfigDebugReportQueryResult = NonNullable<Awaited<ReturnType<typeof getConfigDebugReport>>>
export type GetConfigDebugReportQueryError = unknown

export const useGetConfigDebugReport = <TData = Awaited<ReturnType<typeof getConfigDebugReport>>, TError = unknown>(
 params?: GetConfigDebugReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getConfigDebugReport>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetConfigDebugReportQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getConfigDebugReport>>> = ({ signal }) => getConfigDebugReport(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getConfigDebugReport>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getUserDetails = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerGetConsumerUserDetailsResult>(
      {url: `/api/consumer/ConsumerUsers/GetUserDetails`, method: 'get', signal
    },
      options);
    }
  

export const getGetUserDetailsQueryKey = () => [`/api/consumer/ConsumerUsers/GetUserDetails`];

    
export type GetUserDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDetails>>>
export type GetUserDetailsQueryError = unknown

export const useGetUserDetails = <TData = Awaited<ReturnType<typeof getUserDetails>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDetails>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDetailsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDetails>>> = ({ signal }) => getUserDetails(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserDetails>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

