import { useRef } from "react";
import { Stack, TextField } from "@mui/material";

interface CodeInputProps {
  length: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  setCode: (val: Array<string>) => any;
  code: Array<string>;
  focusBeyond?: () => any;
}

const sx = {
  backgroundColor: "gray.main",
  width: "80px",
  height: "100px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginLeft: ".25rem",
  marginRight: ".25rem",
  "& input": {
    textAlign: "center",
    fontSize: "74px",
    padding: "0px",
    lineHeight: "100px",
    height: "100px",
  },
};

function CodeInput({ length, setCode, code, focusBeyond }: CodeInputProps) {
  const updateCode = (value: string, index: number, noFocusShift?: boolean) => {
    let newCode = code;
    if (value.length >= length) {
      for (let i = 0; i < length; i++) {
        newCode[i] = value[i];
      }
    } else {
      newCode[index] = value[0];
    }

    newCode.length = length;
    setCode(newCode.slice(0, 4));

    if (!noFocusShift) {
      if (inputRefs[index + 1]?.current) {
        console.log("+1 yes");
        inputRefs[index + 1].current?.children[0]?.children[0]?.focus();
      } else {
        console.log("focusBeyond");
        if (focusBeyond) {
          focusBeyond();
        }
      }
    }
  };

  let inputRefs = [
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
    useRef<any>(null),
  ];

  let inputs = [];
  for (let i = 0; i < length; i++) {
    inputs.push(
      <TextField
        key={i}
        className={"code-input"}
        InputProps={{ disableUnderline: true }}
        sx={sx}
        variant="standard"
        value={code[i] ?? ""}
        // onKeyPress={(x) => {
        //   // does not register backspace with no existing content
        //   // console.debug(x.nativeEvent);
        // }}
        onChange={(x) => {
          console.debug((x.nativeEvent as any)?.inputType);
          let noFocusShift =
            (x.nativeEvent as any)?.inputType === "deleteContentBackward" || (x.nativeEvent as any)?.inputType === "deleteContentForward" ? true : false;
          let data = (x.nativeEvent as any)?.data ?? false;
          if (data === " ") {
            data = "";
          }
          if (data === "") {
            noFocusShift = true;
            console.debug("no content");
          } else if (data === false) {
            data = "";
          }
          updateCode(data, i, noFocusShift);
        }}
        ref={inputRefs[i]}
      />
    );
  }
  return (
    <Stack
      sx={{
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
      }}
    >
      {inputs}
    </Stack>
  );
}

export default CodeInput;
