import { medicareConstructAuthorizationURI } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { base64Converter } from "common/utilities/base64Converter";
import { local_origin } from "config";

const medicareConnectManager = {
	constructAuthorizeURI: async () => {
		if (AccountManager.username) {
			// send 'state' as B64 encoded "email/username:timestamp"
			let state = base64Converter.b64EncodeUnicode(AccountManager.username + ":" + Date.now());

			let result: any = await medicareConstructAuthorizationURI({
				email: AccountManager.username,
				state: state,
				origin: await local_origin(),
			});

			if (result.response?.success) {
				let uri = result.response?.data?.MedicareAuthorizationURI;
				if (uri.indexOf("{origin}") !== -1) {
					uri = uri.replace("{origin}", window.location.origin);
				}
				return uri;
			}
		}
		return false;
	},

	async initiateAuthorization() {
		let authTarget = await medicareConnectManager.constructAuthorizeURI();
		if (authTarget) {
			console.log("send to ", authTarget);
			window.location.href = authTarget;
		} else {
			return "An Error Occurred Connecting to Medicare Authorization Page";
		}
	},
};

export { medicareConnectManager };
