import { anthemConstructAuthorizationURI } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { base64Converter } from "common/utilities/base64Converter";
import { local_origin } from "config";

const AnthemConnectManager = {
	constructAuthorizeURI: async (anthemPlan: string) => {
		if (AccountManager.username) {
			// send 'state' as B64 encoded "email/username:timestamp"
			let state = base64Converter.b64EncodeUnicode(AccountManager.username + ":" + Date.now() + "||" + anthemPlan);

			let result: any = await anthemConstructAuthorizationURI({
				email: AccountManager.username,
				state: state,
				origin: await local_origin(),
			});

			if (result.response?.success) {
				let uri = result.response?.data?.AnthemAuthorizationURI;

				console.debug(result.response?.data);
				if (uri.indexOf("{origin}") !== -1) {
					uri = uri.replace("{origin}", window.location.origin);
				}
				return uri;
			}
		}
		return false;
	},

	async initiateAuthorization(anthemPlan: string) {
		let authTarget = await AnthemConnectManager.constructAuthorizeURI(anthemPlan);
		window.location.href = authTarget;
	},
};

export { AnthemConnectManager };
