const base64Converter = {
  // encode: ✓ à la mode' => "4pyTIMOgIGxhIG1vZGU="
  b64EncodeUnicode: (str: string) => {
    return btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_match, p1) {
        return String.fromCharCode(("0x" + p1) as unknown as number);
      })
    );
  },

  // decode: '4pyTIMOgIGxhIG1vZGU=' => "✓ à la mode"
  b64DecodeUnicode: (str: string) => {
    return decodeURIComponent(
      Array.prototype.map
        .call(atob(str), function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
  },
};

export { base64Converter };
