/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerMobileGetConsumerUserDetailsMobileResult
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getUserDetailsMobile = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMobileGetConsumerUserDetailsMobileResult>(
      {url: `/api/consumer/ConsumerMobile_Users/GetUserDetails_Mobile`, method: 'get', signal
    },
      options);
    }
  

export const getGetUserDetailsMobileQueryKey = () => [`/api/consumer/ConsumerMobile_Users/GetUserDetails_Mobile`];

    
export type GetUserDetailsMobileQueryResult = NonNullable<Awaited<ReturnType<typeof getUserDetailsMobile>>>
export type GetUserDetailsMobileQueryError = unknown

export const useGetUserDetailsMobile = <TData = Awaited<ReturnType<typeof getUserDetailsMobile>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserDetailsMobile>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserDetailsMobileQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserDetailsMobile>>> = ({ signal }) => getUserDetailsMobile(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserDetailsMobile>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

