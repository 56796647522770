import { Box, Typography } from "@mui/material";
import { config } from "config";
import GoogleMapReact from "google-map-react";
import React from "react";
import { useMemo } from "react";
import MapMarker from "../DisplayElements/MapMarker";

type OverviewMapProps = {
	center?: {
		lat: number;
		lng: number;
	};
	zoom?: number;
	address?: string;
};

function OverviewMap(props: OverviewMapProps) {
	const zoomLevel = props.zoom ?? 12;
	const mapKey = useMemo(() => {
		return config.Keys.GoogleMaps ?? null;
	}, []);
	if (mapKey) {
		return (
			<Box style={{ height: "100%", width: "100%" }}>
				<GoogleMapReact
					bootstrapURLKeys={{
						key: mapKey,
						language: "en",
					}}
					center={props.center}
					// defaultCenter={props.center}
					defaultZoom={zoomLevel}
					// onChildMouseEnter={onChildMouseEnter}
					// onChildMouseLeave={onChildMouseLeave}
					layerTypes={["TransitLayer"]} //"TrafficLayer",
				>
					{props.center ? <MapMarker address={props.address} lat={props.center.lat} lng={props.center.lng} /> : null}
				</GoogleMapReact>
			</Box>
		);
	}
	window.RadiusCare.log_issue({ message: "Map Key did not exist", file: "OverviewMap" });
	return <Typography>Map data not available.</Typography>;
}

export default OverviewMap;
