import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { palette } from "theme";
import { ModelsExplanationOfBenefitTreatmentAssessment } from "api";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import { Entity, entity_actions } from "common/definitions/EntityDefinitions";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { dateConverter } from "common/utilities/dateConverter";

const MedicationAssessmentDetailsSection = ({
	medicationAssessment,
	medicationEntity,
}: {
	medicationAssessment: ModelsExplanationOfBenefitTreatmentAssessment;
	medicationEntity?: Entity;
}) => {
	return (
		<Box sx={{ paddingTop: "1rem" }}>
			<Table sx={{ "& .MuiTableCell-root": { padding: { xs: ".5rem", sm: "1rem" } }, "& .MuiTableHead-root .MuiTableCell-root": { color: "#ffffff" } }}>
				<TableHead>
					<TableRow sx={{ backgroundColor: palette.gray.dark }}>
						<TableCell>Service Date</TableCell>
						<TableCell>Prescriber Name</TableCell>
						<TableCell>Pharmacy Name</TableCell>
						<TableCell>Days Supply</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{medicationAssessment.prescribingEvents.map((prescribingEvent, i) => {
						let pharmacy = DynamicDataManager.npis.doctorRecord_NPIMap.get(prescribingEvent.pharmacy ?? "");
						let doc = DynamicDataManager.npis.doctorRecord_NPIMap.get(prescribingEvent.npi ?? "");
						return (
							<TableRow key={i} sx={{ backgroundColor: "#ffffff" }}>
								<TableCell>{dateConverter["YYYY-MM-DD_to_mM/dD/YY"](prescribingEvent.date)}</TableCell>
								<TableCell>
									{doc?.firstName} {doc?.lastName}
								</TableCell>
								<TableCell>{pharmacy?.organizationName}</TableCell>
								<TableCell>{prescribingEvent.daysSupply}</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
		</Box>
	);
};

export { MedicationAssessmentDetailsSection };

const MedicationOptionsActions = ({ medicationEntity }: { medicationEntity: Entity }) => {
	return (
		<OptionsButton
			content={
				<Box>
					<Button
						variant="text"
						onClick={() => {
							entity_actions.view_details.action(medicationEntity);
						}}
					>
						View Details
					</Button>
				</Box>
			}
		/>
	);
};

export { MedicationOptionsActions };
