import { getKeyFindings } from "api";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { dateConverter } from "common/utilities/dateConverter";
import { useQuery } from "react-query";

const X_getKeyFindings = async () => {
	const startDate = new Date();
	startDate.setFullYear(startDate.getFullYear() - 1);
	const startDateString = dateConverter.stringDateForAPI(startDate);
	const endDate = new Date();
	const endDateString = dateConverter.stringDateForAPI(endDate);
	const response = await getKeyFindings({
		dateRangeRequest: { startDate: startDateString, endDate: endDateString },
		clientLocation: GeolocationManager.clientLocation(),
	});
	// console.log("key findings response", response);
	return response;
};

const useKeyFindings = (email: string | undefined) => {
	// console.log("usefindings start", email);
	return useQuery(["useKeyFindings", email], () => X_getKeyFindings(), {
		staleTime: 86400000, // 24 * 1000 * 60 * 60 // 1DAY
		cacheTime: 86400000,
	});
};

export { useKeyFindings };
