import { Box, Grid, Typography } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medicationDetails/requests/useQ_getDrugStructureData";
import { MDP_Description as MDPDescription } from "./MDP_Description";

type SingleCareMedicationDetailProps = {
	drugStructureData: MedicationDetailDataPackage | null;
	medicationDetailItem: SingleCareMedicationDetail | null;
	sideEffects: string;
	description: string;
};

const MedicationDetailsMain = ({ drugStructureData, medicationDetailItem, sideEffects, description }: SingleCareMedicationDetailProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ContentSection title="Description">
					<MDPDescription description={description} drugStructureData={drugStructureData} medicationDetailItem={medicationDetailItem} />
				</ContentSection>
			</Grid>
			<Grid item xs={12}>
				<ContentSection title="Possible Side Effects">
					{sideEffects && sideEffects !== "" ? (
						<Box dangerouslySetInnerHTML={{ __html: sideEffects }}></Box>
					) : (
						<Typography variant="entity-descriptor">[Side effects unavailable]</Typography>
					)}
				</ContentSection>
			</Grid>
		</Grid>
	);
};

export { MedicationDetailsMain };
