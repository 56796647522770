import { ContentSection } from "common/components/Containers/ContentSection";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { ReactNode } from "react";
import { HealthSnapshotVisitsPanel } from "./HealthSnapshotVisitsPanel";

type HealthSnapshotVisitsProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
	healthPlanReauthTag: ReactNode;
};
const HealthSnapshotVisits = ({ healthPlanReauthTag, patientEOBData }: HealthSnapshotVisitsProps) => {
	return (
		<ContentSection flexGrow={5} title="Most Recent Visits" titleBarContent={healthPlanReauthTag}>
			<HealthSnapshotVisitsPanel patientEOBData={patientEOBData} />
		</ContentSection>
	);
};

export { HealthSnapshotVisits };
