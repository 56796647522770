import { ModelsHCPCS } from "api";
import { ICD10, StaticDataDefinitionsResult } from "common/requests/codes_and_definitions/useQ_staticDataDefinitions";

class StaticDataManagerClass {
	intializedAt?: number;
	// set_position: (...args: any[]) => any = () => null;

	constructor() {
		if (!(StaticDataManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(StaticDataManagerClass as any).instance = this;
		}
		return (StaticDataManagerClass as any).instance;
	}

	data: StaticDataDefinitionsResult = undefined as unknown as StaticDataDefinitionsResult; // set in MainContext before reliant code will run

	// Retrieve Functions - Add here as needed
	retrieveValue = {
		icd10cm: (code: string): ICD10 | null => {
			let code_def = (StaticDataManager.data as StaticDataDefinitionsResult).ICD10CM_Package.code_map.get(code);
			if (!code_def) {
				let rep_code = code.replace(".", "");
				code_def = (StaticDataManager.data as StaticDataDefinitionsResult).ICD10CM_Package.code_map.get(rep_code);
			}
			return code_def ?? null;
		},
		hcpcs: (code: string): ModelsHCPCS | null => {
			let code_def = (StaticDataManager.data as StaticDataDefinitionsResult).HCPCS.lookups.code.get(code);
			if (!code_def) {
				let rep_code = code.replace(".", "");
				code_def = (StaticDataManager.data as StaticDataDefinitionsResult).HCPCS.lookups.code.get(rep_code);
			}
			return code_def ?? null;
		},
	};
}

const StaticDataManager = new StaticDataManagerClass();

export { StaticDataManager, StaticDataManagerClass };
