import { ConsumerDoctorGetProviderDetailResult, getProviderDetail, GetProviderDetailParams, ModelsOperationStatus } from "api";
import { useQuery, UseQueryResult } from "react-query";

async function retrieveProviderDetail({ npi }: GetProviderDetailParams): Promise<ModelsOperationStatus | ConsumerDoctorGetProviderDetailResult> {
	let result = await getProviderDetail({ npi });

	return result;
}

function useQ_providerDetail(params: GetProviderDetailParams): UseQueryResult<ConsumerDoctorGetProviderDetailResult> {
	return useQuery(["retrieveProviderDetail", `retrieveProviderDetail${JSON.stringify(params)}`], retrieveProviderDetail.bind(null, params));
}

export { useQ_providerDetail };
