import { createContext, useState } from "react";
import { GeolocationManager, GeolocationManagerClass } from "common/managers/GeolocationManager";

const GeolocationContext = createContext<{
  position: GeolocationPosition | null;
  geolocationManager: GeolocationManagerClass;
}>({
  position: null,
  geolocationManager: GeolocationManager,
});

function GeolocationContextProvider(props: React.PropsWithChildren<{}>) {
  const [position, set_position] = useState<GeolocationPosition | null>(null);
  GeolocationManager.set_position = set_position;
  return <GeolocationContext.Provider value={{ position, geolocationManager: GeolocationManager }}>{props.children}</GeolocationContext.Provider>;
}

export { GeolocationContextProvider, GeolocationContext };
