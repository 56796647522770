import { Box } from "@mui/material";

const ProgressStep = (props: { label: string | number; filled: boolean }) => {
  return (
    <Box
      sx={{
        width: "52px",
        height: "52px",
        borderRadius: "26px",
        backgroundColor: props.filled ? "core.darkblue" : "white",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: "white",
        borderStyle: "solid",
        borderColor: "core.darkblue",
        borderWidth: "4px",
        boxSizing: "border-box",
      }}
    >
      <Box
        style={{
          fontSize: "20px",
          marginTop: "-4px",
          color: props.filled ? "white" : "gray",
          fontWeight: "600",
        }}
      >
        {props.label}
      </Box>
    </Box>
  );
};

const ProgressBar = () => {
  return <Box sx={{ flexGrow: 1, height: "4px", backgroundColor: "core.darkblue" }} />;
};

type ProgressStepsProps = {
  count: number;
  current: number;
  maxWidth?: string | number;
};
const ProgressSteps = ({ count, current, maxWidth }: ProgressStepsProps) => {
  let stepDisplay = [];
  for (let i = 0; i < count; i++) {
    stepDisplay.push(<ProgressStep label={i + 1} filled={i < current} key={i} />);
    if (count - i > 1) {
      stepDisplay.push(<ProgressBar key={i + "bar"} />);
    }
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: maxWidth,
        width: maxWidth,
      }}
    >
      {stepDisplay}
    </Box>
  );
};

export { ProgressSteps };
