import { Box, Stack } from "@mui/material";
import { palette } from "theme";
import { ReactNode } from "react";
import { ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { OptionsButton } from "../Buttons/OptionsButton";
import { renderDisplayInfo } from "./common/TilesCommon";
import { ProviderAvatarDisplay } from "./DetailsSections/ProviderAssessmentDetailsSection";

interface DoctorTileProps {
	Entity: ProviderEntity;
	content?: ReactNode;
}
const DoctorTile = ({ Entity, content }: DoctorTileProps) => {
	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
*/
	return (
		<Stack
			data-componentname="DoctorTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				overflow: "hidden",
			}}
		>
			<Stack
				sx={{
					justifyContent: "flex-start",
					alignItems: { xs: "flex-start", sm: "center" },
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Stack
					sx={{
						flex: "1",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<ProviderAvatarDisplay providerEntity={Entity} />
				</Stack>
				<Box
					sx={{
						width: { xs: "100%", sm: "auto" },
						paddingTop: { xs: ".5rem", sm: "0" },
						"& .MuiButton-root": { width: { xs: "100%", sm: "auto" } },
					}}
				>
					{Entity.displayInfo
						? Entity.displayInfo.map((di, i) => {
								return renderDisplayInfo(di, i);
						  })
						: null}
					{content ? <OptionsButton content={content} /> : null}
				</Box>
			</Stack>
		</Stack>
	);
};

export { DoctorTile };
