import { Button, Rating, Stack } from "@mui/material";
import { rangePercentConverter } from "common/utilities/rangePercentConverter";
import { useMemo } from "react";
import { palette } from "../../../theme";

type StarRatingInputProps = {
	rating?: number | null;
	set_rating: (option: number | null) => void;
	adjustValueForStarFit?: boolean;
	nullable?: boolean;
};
const StarRatingInput = ({ rating, adjustValueForStarFit, set_rating, nullable }: StarRatingInputProps) => {
	const adjusted_rating = useMemo(() => {
		// star rating is not visibly accurate due to the gaps between stars - this adjusts the display to the restricted range that is visible within the star
		if (rating && adjustValueForStarFit) {
			let fullOffset = 0.2;
			let visibleStarAmount = rangePercentConverter.toAdjustedRange(rating % 1, 1, 1 - fullOffset);
			return Math.floor(rating) + fullOffset / 2 + visibleStarAmount;
		}
		return rating;
	}, [rating, adjustValueForStarFit]);

	if (rating === undefined) {
		return null;
	}

	return (
		<Stack style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
			<Rating
				sx={{ cursor: "pointer", "& svg": { cursor: "pointer", color: palette.core.yellow } }}
				data-rating={rating}
				value={adjusted_rating}
				size="medium"
				precision={0.1}
				onChange={(_event, newValue) => {
					console.debug(newValue);
					set_rating(newValue);
				}}
			/>
			{nullable ? (
				<Button
					variant="text"
					sx={{ minWidth: "2rem", p: 0 }}
					onClick={() => {
						set_rating(null);
					}}
				>
					Clear
				</Button>
			) : null}
		</Stack>
	);
};

export { StarRatingInput };
