import { addUserIgnoreItemsDataAgnostic, ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { QueryClient } from "react-query";

const X_addUserIgnoreItemsDataAgnostic = async (params: ConsumerPatientHealthDataAddUserIgnoreItemsDataAgnosticRequestModel, queryClient?: QueryClient) => {
	// console.group("X_addUserIgnoreItems");
	let response = await addUserIgnoreItemsDataAgnostic(params);

	queryClient = queryClient ?? window.RadiusCare.queryClient; // window.RadiusCare.queryClient does not usually work ?? can troubleshoot or wrap useQueryClient in a function that will continually reset it to latest maybe? it checks out as === to useQueryClient()
	queryClient?.invalidateQueries("retrieveRecommendedUSPSTF");
	queryClient?.invalidateQueries("retrievePatientEOBDataX");

	AccountManager.setUser("REFRESH");
	// console.groupEnd();
	return response;
};

export { X_addUserIgnoreItemsDataAgnostic };
