import { Rating } from "@mui/material";
import { rangePercentConverter } from "common/utilities/rangePercentConverter";
import { useMemo } from "react";
import { palette } from "../../../theme";

type StarRatingProps = {
	rating?: number | null;
	adjustValueForStarFit?: boolean;
};
const StarRating = ({ rating, adjustValueForStarFit }: StarRatingProps) => {
	const adjusted_rating = useMemo(() => {
		// star rating is not visibly accurate due to the gaps between stars - this adjusts the display to the restricted range that is visible within the star
		if (rating && adjustValueForStarFit) {
			let fullOffset = 0.2;
			let visibleStarAmount = rangePercentConverter.toAdjustedRange(rating % 1, 1, 1 - fullOffset);
			return Math.floor(rating) + fullOffset / 2 + visibleStarAmount;
		}
		return rating;
	}, [rating, adjustValueForStarFit]);

	if (rating === undefined || rating === -1) {
		return null;
	}

	if (!rating) {
		return null;
	}
	return (
		<Rating
			sx={rating === -1 ? { opacity: 0.4, "& svg": { color: palette.core.yellow } } : undefined}
			data-rating={rating}
			value={adjusted_rating}
			size="medium"
			precision={0.1}
			readOnly
		/>
	);
};

export { StarRating };
