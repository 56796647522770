import { AppBar, Box, Button, Container, Grid, Typography } from "@mui/material";
import { MyCostGetNearbyAmbulatoryRequest, MyCostGetNearbyAmbulatoryResult } from "api";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { HospitalTile } from "common/components/Tiles/HospitalTile";
import { GeolocationContext } from "common/context/GeolocationContext";
import { DisplayInfo, Entity } from "common/definitions/EntityDefinitions";
import { DialogManager } from "common/managers/DialogManager";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { style } from "theme";
import { getNearbyAmbulatory } from "../../../api_mobile/my-cost/my-cost";

function useQ_NearbyAmbulatory(paramsX: MyCostGetNearbyAmbulatoryRequest) {
	let queryInvalidations = ["getNearbyAmbulatory", `getNearbyAmbulatory:${JSON.stringify(paramsX)}`];
	return useQuery(queryInvalidations, getNearbyAmbulatory.bind(null, paramsX), {
		staleTime: 86400000, // 24 * 1000 * 60 * 60 // 1DAY
		cacheTime: 86400000,
	});
}

function CareMaintenanceSavingsDetails() {
	const geo = useContext(GeolocationContext);
	useEffect(() => {
		GeolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
	}, [geo.position]);

	// const location = useMemo(() => {
	// 	GeolocationManager.getPosition();
	// 	return GeolocationManager.clientLocation();
	// }, []);

	const { data } = useQ_NearbyAmbulatory({
		clientLocation: geo.geolocationManager.clientLocation(),

		// "clientLocation.distanceRadiusMiles": 25,
		// "clientLocation.latitude": location.latitude ?? 0,
		// "clientLocation.longitude": location.longitude ?? 0,
		// "clientLocation.zipcode": location.zipcode ?? "",
	});

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					style={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Care Maintenance Savings Tips</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("care-maintenance-savings-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ContentSection title="Description">
							<Typography>
								Ambulatory Surgical Centers (ACS) can be utilized for procedures and treatments which you may need. Treatments and procedures
								that are reoccurring can often be done at an ASC location at a much lower cost. Below are a list of locations near you.
							</Typography>
						</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<ContentSection title="Ambulatory Surgical Centers">
							{(data as MyCostGetNearbyAmbulatoryResult)?.ambulatoryCenters?.map((hospital, i) => {
								// const address = hospital.address + ", " + hospital.city + ", " + hospital.state + ", " + hospital.zip;
								let distanceInfo: DisplayInfo = {
									title: "Distance",
									value: hospital.distanceMiles ?? "??",
								};
								let entity: Entity = {
									entityTypeName: "provider",
									title: hospital.organizationName ?? "",
									descriptor: "",
									displayInfo: [distanceInfo],
									_data: hospital,
								};

								let viewDetail = () => {
									DialogManager.open("provider-details", hospital.npi ?? "MISSING NPI");
								};

								let content = (
									<Box>
										<Button variant="text" onClick={viewDetail}>
											View Details
										</Button>
									</Box>
								);

								return (
									<Box key={i} sx={{ paddingBottom: ".5rem" }}>
										<HospitalTile Entity={entity} Npi={hospital.npi ?? ""} radiusRating={hospital.radiusRating} content={content} />
									</Box>
								);
							})}
						</ContentSection>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export { CareMaintenanceSavingsDetails };
