import { Routes, Route, Navigate } from "react-router";
import { CareOverview } from "../pages/overview/CareOverview";
import { CareCostsAndSavings } from "../pages/costs/CareCostsAndSavings";
import { Achievements } from "../pages/achievements/Achievements";
import { useEffect, useState } from "react";
import { useQ_retrievePatientEOBDataX } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { useQ_careScore } from "dashboard/requests/useQ_careScore";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { ConsumerKeyFindingsResponseModel } from "api";

function DashboardRoutes() {
	const [keyFindings, set_keyFindings] = useState<ConsumerKeyFindingsResponseModel | null>(null);

	const patientEOBData = useQ_retrievePatientEOBDataX({
		startDate: null,
		endDate: null,
		includeLookUps: false,
		includeKeyFindings: true,
		clientLocation: GeolocationManager.clientLocation(),
		// invalidateOnDateOnly: false,
		caller: "DashboardRoutes",
	});

	const careScoreData = useQ_careScore({ startDate: "default" });
	useEffect(() => {
		if (patientEOBData?.data?.success === true && patientEOBData.data.data?.keyFindings) {
			set_keyFindings(patientEOBData.data.data.keyFindings);
		}
	}, [patientEOBData]);

	return (
		<Routes>
			<Route path={"myoverview"} element={<CareOverview patientEOBData={patientEOBData} careScoreData={careScoreData} keyFindings={keyFindings} />} />
			<Route path={"mycost"} element={<CareCostsAndSavings />} />
			<Route path={"achievements"} element={<Achievements />} />
			<Route path={"*"} element={<Navigate to="myoverview" />} />
		</Routes>
	);
}

export { DashboardRoutes };
