import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import ProvideFeedback from "main/components/ProvideFeedback";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ProvideFeedbackDialog = () => {
	const location = useLocation();
	const { provideFeedbackDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("provide-feedback");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("provide-feedback");
			setSearchParams(searchParams);
			provideFeedbackDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "provide-feedback": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["provide-feedback"].open = open;
		DialogManager.launchers["provide-feedback"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== provideFeedbackDisplay?.current) {
			provideFeedbackDisplay.set_current(d_query);
		}
	}, [d_query, provideFeedbackDisplay]);

	return (
		<Dialog id="ProvideFeedbackDialog" fullScreen open={!!provideFeedbackDisplay.current} onClose={close} TransitionComponent={Transition}>
			{provideFeedbackDisplay.current ? <ProvideFeedback /> : null}
		</Dialog>
	);
};

export { ProvideFeedbackDialog };
