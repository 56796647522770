import { Box, Button, Card, CardActions, CardContent, FormControl, InputLabel, List, ListItem, MenuItem, Select, Stack, Typography } from "@mui/material";
// import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { FormErrorAlert } from "common/forms";
import { palette } from "theme";
import { useState, useContext, useMemo } from "react";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import AnthemDropdown from "./anthem/AnthemDropdown";
import FormContainer from "account/forms/FormContainer";
import { StaticDataDefinitionsContext } from "common/context/MainContext";
import { Loader } from "common/components/Loaders/Loader";
import UnitedHealthDropdown from "./unitedHealth/UnitedHealthDropdown";

type ConnectHealthPlanFormProps = {
	hideSkipConnection?: boolean;
};

function ConnectHealthPlanForm({ hideSkipConnection }: ConnectHealthPlanFormProps) {
	const [healthplan, set_healthplan] = useState<"BlueButtonMedicare" | "Aetna" | "UnitedHealth" | "Cigna" | "Humana" | "Anthem" | "HorizonBlue" | "CareRisk">(
		"BlueButtonMedicare"
	);
	const [anthemhealthplan, set_anthemhealthplan] = useState<string>("Amerigroup");
	const [unitedhealthplan, set_unitedhealthplan] = useState<string>("United Health");

	const navigate = useNavigate();
	const beginAuthorization = (learn_more?: boolean) => {
		if (healthplan === "UnitedHealth") {
			navigate(`/connect-healthplan/authorize/healthplan:${healthplan}${learn_more ? ":learnmore" : ""}?unitedHealthPlan=${unitedhealthplan}`);
		} else if (healthplan === "Anthem") {
			navigate(`/connect-healthplan/authorize/healthplan:${healthplan}${learn_more ? ":learnmore" : ""}?anthemPlan=${anthemhealthplan}`);
		} else {
			navigate(`/connect-healthplan/authorize/healthplan:${healthplan}${learn_more ? ":learnmore" : ""}`);
		}
	};

	const SDDC = useContext(StaticDataDefinitionsContext);

	const anthemOptions = useMemo(() => {
		if (SDDC.insuranceOptions) {
			var anthemEntry = SDDC.insuranceOptions.find((x) => {
				return x.dataSourceId.toLowerCase().indexOf("anthem") !== -1;
			});
			console.debug(anthemEntry);
			if (anthemEntry && anthemEntry.planSelectionOptions) {
				return anthemEntry.planSelectionOptions;
			}
		}
		return null;
	}, [SDDC]);

	const unitedHealthOptions = useMemo(() => {
		if (SDDC.insuranceOptions) {
			var unitedHealthEntry = SDDC.insuranceOptions.find((x) => {
				return x.dataSourceId.toLowerCase().indexOf("unitedhealth") !== -1;
			});
			console.debug(unitedHealthEntry);
			if (unitedHealthEntry && unitedHealthEntry.planSelectionOptions) {
				return unitedHealthEntry.planSelectionOptions;
			}
		}
		return null;
	}, [SDDC]);

	if (!SDDC.insuranceOptions) {
		return <Loader />;
	}

	return (
		<FormContainer
			noTopAndBottom={hideSkipConnection}
			bottomContent={
				<Box style={{ textAlign: "center" }}>
					<Link to="/account-active/">
						<Typography sx={{ color: "core.darkblue", fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>Skip Connection</Typography>
					</Link>
				</Box>
			}
		>
			<Card elevation={4} sx={{ backgroundColor: "white !important" }}>
				<CardContent>
					<Box sx={{ flexDirection: "row", justifyContent: "center", pb: 4 }}>
						<Typography variant="h1" component="h1">
							Connect to your health details?
						</Typography>
						<Typography>Please choose an option below</Typography>
					</Box>

					<Box>
						<FormControl fullWidth>
							<InputLabel variant="standard">Choose your insurance plan</InputLabel>
							<Select
								defaultValue="BlueButtonMedicare"
								variant="standard"
								onChange={(x) => {
									console.debug(x.target.value);
									set_healthplan(x.target.value as CIM);
								}}
							>
								{SDDC.insuranceOptions
									.filter((x) => {
										if (!x.enabled && x.hideWhenDisabled) {
											return false;
										}
										return true;
									})
									.map((x) => {
										return (
											<MenuItem key={x.dataSourceId} disabled={!x.enabled} value={x.dataSourceId}>
												{x.displayName}
											</MenuItem>
										);
									})}
							</Select>
						</FormControl>
					</Box>

					<FormErrorAlert label="Access Denied" />
					{healthplan === "Aetna" ? (
						<Box
							sx={{
								borderRadius: "1rem",
								fontSize: "1.25rem",
								p: 2,
								mt: 2,
								backgroundColor: palette.gray.light,
							}}
						>
							<Stack flexDirection={"row"} sx={{ pb: 1 }}>
								<Box>
									<HealthIcon color={palette.error.main} iconSVG={health_icons_svg.faBellOn} />
								</Box>

								<Typography sx={{ pl: 1, fontWeight: 500, color: palette.error.main }}>
									Aetna only shares data for select insurance plans.
								</Typography>
							</Stack>
							<Typography sx={{ fontWeight: 700 }}>Supported Plans:</Typography>
							<Typography component="div">
								<List
									sx={{
										listStyleType: "disc",
										pl: 4,
										"& .MuiListItem-root": {
											display: "list-item",
										},
									}}
								>
									<ListItem disablePadding>Aetna Medicare Advantage Plans</ListItem>
									<ListItem disablePadding>Aetna Health Plans Purchased on Healthcare.gov</ListItem>
									<ListItem disablePadding>Aetna Medicaid Plans</ListItem>
								</List>
							</Typography>

							<Stack sx={{ flexDirection: "row", justifyContent: "center", pt: 2 }}>
								<Link to={"/account-active"}>
									<Typography sx={{ fontSize: "1rem", color: palette.core.blue }}>Skip Connection</Typography>
								</Link>
							</Stack>
						</Box>
					) : null}
					{healthplan === "UnitedHealth" ? (
						<UnitedHealthDropdown setUnitedHealthPlan={set_unitedhealthplan} healthPlanOptions={unitedHealthOptions} />
					) : null}
					{healthplan === "Anthem" ? <AnthemDropdown setAnthemPlan={set_anthemhealthplan} healthPlanOptions={anthemOptions} /> : null}
					<Typography sx={{ fontWeight: 700, pt: 2 }}>
						* You will be redirected to your insurance portal. Please log in and authorize Radius Care to access your data on your behalf.
					</Typography>
				</CardContent>
				<CardActions>
					<Button
						type="submit"
						onClick={() => {
							beginAuthorization(false);
						}}
					>
						Connect
					</Button>
				</CardActions>
			</Card>
			{/* <Stack
				justifyContent={"center"}
				sx={{
					width: "100%",
					textAlign: "left",
					p: 2,
					height: "6rem",
					boxSizing: "border-box",
				}}
			>
				<Box style={{ textAlign: "center" }}>
					<Link to="/signin">
						<Typography sx={{ color: "core.darkblue", fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>Cancel</Typography>
					</Link>
				</Box>
			</Stack> */}
		</FormContainer>
	);
}

export { ConnectHealthPlanForm };
