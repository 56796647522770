import Axios, { AxiosRequestConfig } from "axios";

import { getRuntimeConfig } from "../config";
const { APIConfig } = getRuntimeConfig();
console.log("APICONFIG", APIConfig);
type TokenRef = () => Promise<string | undefined>;
const unauthenticated: TokenRef = () => Promise.resolve(undefined);

let getToken: TokenRef = unauthenticated;

export function setJwtToken(tokenRef: TokenRef) {
	getToken = tokenRef;
}

export function clearJwtToken() {
	getToken = unauthenticated;
}

export function getJwtToken() {
	return getToken;
}

export const AXIOS_INSTANCE = (() => {
	let baseURL = APIConfig.BaseUrl;
	// if (baseURL.indexOf("https://api.") !== -1) {
	// 	baseURL = baseURL.replace("https://api.", "https://cache.api.");
	// 	console.debug("Using cache.api");
	// }
	return Axios.create({
		baseURL: baseURL,
	});
})();

export const AXIOS_INSTANCE_CACHE_API = (() => {
	let baseURL = APIConfig.BaseUrl;
	if (baseURL.indexOf("https://api.") !== -1) {
		baseURL = baseURL.replace("https://api.", "https://static.api.");
		console.debug("Using cache apis (static.api)");
	}
	return Axios.create({
		baseURL: baseURL,
	});
})();

AXIOS_INSTANCE.interceptors.request.use(async (config) => {
	const token = await getToken();
	if (token && config.headers) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

interface AxiosRequestConfigExpanded extends AxiosRequestConfig {
	useCacheApi?: boolean;
}

export const apiRequest = <T>(config: AxiosRequestConfigExpanded, options?: AxiosRequestConfigExpanded): Promise<T> => {
	const source = Axios.CancelToken.source();

	let caller = AXIOS_INSTANCE;

	let headers: any = {};
	let axios_options = {
		...config,
		...(options ?? {}),
		cancelToken: source.token,
		headers,
	};
	// if (axios_options.useCacheApi) {
	// 	console.debug("USE CACHE API");
	// 	caller = AXIOS_INSTANCE_CACHE_API;
	// }

	const promise = caller(axios_options)
		.then(({ data }) => {
			// console.debug("CLIENT", { data });
			return data;
		})
		.catch((error: any) => {
			// console.debug("CLIENT_ERROR", { error });
			if (typeof error.response?.data === "object") {
				if (error.response.data.responseType === "OperationStatus") {
					// console.debug(error.response.data);
					return error.response.data;
				}
			}

			let errorResult: { errorType: string; errorMessage: string } | null = null;
			if (typeof error?.response?.data === "string") {
				let line1: string = error.response.data.split("\r\n")[0];
				let colonSplit = line1.indexOf(":");
				if (line1 && line1.length >= 2) {
					errorResult = {
						errorType: line1.substring(0, colonSplit).trim(),
						errorMessage: line1
							.substring(colonSplit + 1)
							.trim()
							.substring(0, 1024),
					};
				}
			}
			return {
				...error,
				errorResult,
			};
		});

	// @ts-expect-error
	promise.cancel = source.cancel;

	return promise;
};
