import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { SuggestSimilarProviders } from "details/suggestSimilarProviders/SuggestSimilarProviders";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const SuggestSimilarProvidersDialog = () => {
	const location = useLocation();
	const { suggestSimilarProvidersDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("suggest-similar-providers");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("suggest-similar-providers");
			setSearchParams(searchParams);
			suggestSimilarProvidersDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "suggest-similar-providers": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["suggest-similar-providers"].open = open;
		DialogManager.launchers["suggest-similar-providers"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== suggestSimilarProvidersDisplay?.current) {
			suggestSimilarProvidersDisplay.set_current(d_query);
		}
	}, [d_query, suggestSimilarProvidersDisplay]);

	return (
		<Dialog id="SuggestSimilarProvidersDialog" fullScreen open={!!suggestSimilarProvidersDisplay.current} onClose={close} TransitionComponent={Transition}>
			{suggestSimilarProvidersDisplay.current ? <SuggestSimilarProviders npi={suggestSimilarProvidersDisplay.current} /> : null}
		</Dialog>
	);
};

export { SuggestSimilarProvidersDialog };
