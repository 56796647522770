/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API - Mobile
 * OpenAPI spec version: mobile
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  MyCostGetProviderSavingsResult,
  GetProviderSavingsParams,
  MyCostGetNearbyAmbulatoryResult,
  MyCostGetNearbyAmbulatoryRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getProviderSavings = (
    params?: GetProviderSavingsParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<MyCostGetProviderSavingsResult>(
      {url: `/api/consumer/MyCost/GetProviderSavings`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetProviderSavingsQueryKey = (params?: GetProviderSavingsParams,) => [`/api/consumer/MyCost/GetProviderSavings`, ...(params ? [params]: [])];

    
export type GetProviderSavingsQueryResult = NonNullable<Awaited<ReturnType<typeof getProviderSavings>>>
export type GetProviderSavingsQueryError = unknown

export const useGetProviderSavings = <TData = Awaited<ReturnType<typeof getProviderSavings>>, TError = unknown>(
 params?: GetProviderSavingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviderSavings>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProviderSavingsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProviderSavings>>> = ({ signal }) => getProviderSavings(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProviderSavings>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getNearbyAmbulatory = (
    myCostGetNearbyAmbulatoryRequest: MyCostGetNearbyAmbulatoryRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<MyCostGetNearbyAmbulatoryResult>(
      {url: `/api/consumer/MyCost/GetNearbyAmbulatory`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: myCostGetNearbyAmbulatoryRequest
    },
      options);
    }
  


    export type GetNearbyAmbulatoryMutationResult = NonNullable<Awaited<ReturnType<typeof getNearbyAmbulatory>>>
    export type GetNearbyAmbulatoryMutationBody = MyCostGetNearbyAmbulatoryRequest
    export type GetNearbyAmbulatoryMutationError = unknown

    export const useGetNearbyAmbulatory = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getNearbyAmbulatory>>, TError,{data: MyCostGetNearbyAmbulatoryRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getNearbyAmbulatory>>, {data: MyCostGetNearbyAmbulatoryRequest}> = (props) => {
          const {data} = props ?? {};

          return  getNearbyAmbulatory(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getNearbyAmbulatory>>, TError, {data: MyCostGetNearbyAmbulatoryRequest}, TContext>(mutationFn, mutationOptions)
    }
    