import { AppBar, Box, Button, Container, Grid, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";
import { ReactNode, useContext, useMemo, useState } from "react";
import { useQ_suggestSimilarDoctors } from "../suggestSimilarProviders/requests/useQ_suggestSimilarDoctors";
import { GeolocationContext } from "common/context/GeolocationContext";
import { CONVERT_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { DoctorTile } from "common/components/Tiles/DoctorTile";
import { AccountManagerContext } from "common/managers/AccountManager";
import { InNetworkSelect } from "common/components/Inputs/InNetworkSelect";

function SuggestSimilarProviders({ npi }: { npi: string }) {
	const geoContext = useContext(GeolocationContext);

	const [descriptionSection] = useMemo(() => {
		let descSection: ReactNode | null = (
			<ContentSection title="Description">
				<p>
					Higher rated care providers have been found in your area. Below is a list of doctors that have had success in treating your particular
					concerns.
				</p>
			</ContentSection>
		);
		return [descSection];
	}, []);

	const accountContext = useContext(AccountManagerContext);

	const [inNetwork, set_inNetwork] = useState<boolean>(!!accountContext.activeProviderOptions);

	// accountContext.activeProviderOptions;
	const suggestSimilarDoctorsResponse = useQ_suggestSimilarDoctors({
		clientLocation: geoContext.geolocationManager.clientLocation(),
		npi: npi,
		inNetwork: inNetwork,
	});

	const provsByType = useMemo(() => {
		return suggestSimilarDoctorsResponse?.data?.data?.data?.doctorRecordsByType;
	}, [suggestSimilarDoctorsResponse]);

	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Similar Providers in your Area</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("suggest-similar-providers");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{descriptionSection}
					</Grid>
					<Grid item xs={12}>
						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "8px" }}>
							{accountContext.activeProviderOptions ? (
								<Box style={{ maxWidth: "160px", flexGrow: "1", paddingRight: "8px" }}>
									<InNetworkSelect set_inNetwork={set_inNetwork} inNetwork={inNetwork} />
									{/* <StandardSelect
										label="In Network"
										labelId="in-network-select-label"
										label_after={
											<Box>
												<HealthIcon iconSVG={health_icons_svg.polygon_network} color={palette.core.forestgreen} />
											</Box>
										}
										id="in-network-select"
										variant="standard"
										value={inNetwork ? "TRUE" : "FALSE"}
										onChange={(x) => {
											set_inNetwork(x.target.value === "TRUE");
										}}
									>
										{inNetworkSelectOptions}
									</StandardSelect> */}
								</Box>
							) : null}
						</Box>
						<QueryStatusDisplay queryResult={suggestSimilarDoctorsResponse}>
							{provsByType ? (
								Object.keys(provsByType).map((key) => {
									let x = provsByType[key];

									return (
										<ContentSection data-container={key} key={key} title={key}>
											{x.map((y, i) => {
												// var entity = CONVERT_DoctorStubX_Entity(y, "AUTO");
												var entity = CONVERT_DoctorRecord_Entity(y, "AUTO");
												entity.descriptor =
													(entity._providerRecord?.taxonomies[0]?.displayName &&
													entity._providerRecord?.taxonomies[0]?.displayName !== ""
														? entity._providerRecord?.taxonomies[0]?.displayName
														: entity._providerRecord?.specialties !== ""
														? entity._providerRecord?.specialties
														: entity._providerRecord?.hgSpecialty !== ""
														? entity._providerRecord?.hgSpecialty
														: "") ?? "";
												let viewProfile = () => {
													DialogManager.open("provider-details", entity._providerRecord?.npi ?? "MISSING NPI");
												};
												let content = (
													<Box>
														<Button variant="text" onClick={viewProfile}>
															View Profile
														</Button>
													</Box>
												);
												return (
													<Box sx={{ paddingBottom: ".5rem" }}>
														<DoctorTile key={i} Entity={entity} content={content} />
													</Box>
												);
											})}
											{x.length === 0 ? (
												<Typography>
													No higher rated providers found within {suggestSimilarDoctorsResponse?.data?.data?.data.distanceRadiusMiles}{" "}
													miles.
												</Typography>
											) : null}
										</ContentSection>
									);
								})
							) : (
								<ContentSection title={"No Results"}>
									<Typography>
										No higher rated providers found within {geoContext.geolocationManager.clientLocation().distanceRadiusMiles} miles
									</Typography>
								</ContentSection>
							)}
							{/* {typeof noResultsWithinDistance === "number" && noResultsWithinDistance !== -1 ? (
							<ContentSection title={"No Results"}>
								<Typography>No Higher-Rated Providers were found within {noResultsWithinDistance} miles</Typography>
							</ContentSection>
						) : null} */}
						</QueryStatusDisplay>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export { SuggestSimilarProviders };
