type ErrorCode = "USER_LOCATION_GENERAL_ERROR" | "USER_LOCATION_NOT_AVAILABLE" | "USER_LOCATION_PERMISSION_DENIED";
interface ErrorBasicDefinition {
  description: string;
  link?: string;
}

interface ErrorDefinition extends ErrorBasicDefinition {
  code: ErrorCode;
  isError: true;
}

const ErrorBasicDefinitions: { [key in ErrorCode]: ErrorBasicDefinition } = {
  USER_LOCATION_GENERAL_ERROR: {
    description: "Unable to determine your location",
  },
  USER_LOCATION_NOT_AVAILABLE: {
    description: "Unable to determine your location",
  },
  USER_LOCATION_PERMISSION_DENIED: {
    description: "Unable to determine your location",
  },
};

const Errors = (ec: ErrorCode): ErrorDefinition => {
  return Object.assign({ code: ec, isError: true } as any, ErrorBasicDefinitions[ec]);
};

function isError(toBeDetermined: any): toBeDetermined is ErrorDefinition {
  if ((toBeDetermined as ErrorDefinition).isError === true) {
    return true;
  }
  return false;
}

export { ErrorBasicDefinitions, Errors, isError };
export type { ErrorCode, ErrorDefinition, ErrorBasicDefinition };
