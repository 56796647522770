/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerOperationStatusApplicationDefinitions,
  ConsumerApplicationDefinitionsOptions,
  ConsumerPasswordRequirementsDefinition,
  ConsumerOperationStatusIcd10Range,
  RetrieveConditionsIcd10sByRangeParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const retrieveApplicationDefinitions = (
    consumerApplicationDefinitionsOptions: ConsumerApplicationDefinitionsOptions,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusApplicationDefinitions>(
      {url: `/api/consumer/Definitions/RetrieveApplicationDefinitions`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerApplicationDefinitionsOptions
    },
      options);
    }
  


    export type RetrieveApplicationDefinitionsMutationResult = NonNullable<Awaited<ReturnType<typeof retrieveApplicationDefinitions>>>
    export type RetrieveApplicationDefinitionsMutationBody = ConsumerApplicationDefinitionsOptions
    export type RetrieveApplicationDefinitionsMutationError = unknown

    export const useRetrieveApplicationDefinitions = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof retrieveApplicationDefinitions>>, TError,{data: ConsumerApplicationDefinitionsOptions}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof retrieveApplicationDefinitions>>, {data: ConsumerApplicationDefinitionsOptions}> = (props) => {
          const {data} = props ?? {};

          return  retrieveApplicationDefinitions(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof retrieveApplicationDefinitions>>, TError, {data: ConsumerApplicationDefinitionsOptions}, TContext>(mutationFn, mutationOptions)
    }
    export const retrievePasswordRequirements = (
    
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerPasswordRequirementsDefinition>(
      {url: `/api/consumer/Definitions/RetrievePasswordRequirements`, method: 'post'
    },
      options);
    }
  


    export type RetrievePasswordRequirementsMutationResult = NonNullable<Awaited<ReturnType<typeof retrievePasswordRequirements>>>
    
    export type RetrievePasswordRequirementsMutationError = unknown

    export const useRetrievePasswordRequirements = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof retrievePasswordRequirements>>, TError,TVariables, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof retrievePasswordRequirements>>, TVariables> = () => {
          

          return  retrievePasswordRequirements(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof retrievePasswordRequirements>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    export const retrieveConditionsIcd10sByRange = (
    params?: RetrieveConditionsIcd10sByRangeParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerOperationStatusIcd10Range>(
      {url: `/api/consumer/Definitions/RetrieveConditionsIcd10sByRange`, method: 'post',
        params
    },
      options);
    }
  


    export type RetrieveConditionsIcd10sByRangeMutationResult = NonNullable<Awaited<ReturnType<typeof retrieveConditionsIcd10sByRange>>>
    
    export type RetrieveConditionsIcd10sByRangeMutationError = unknown

    export const useRetrieveConditionsIcd10sByRange = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof retrieveConditionsIcd10sByRange>>, TError,{params?: RetrieveConditionsIcd10sByRangeParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof retrieveConditionsIcd10sByRange>>, {params?: RetrieveConditionsIcd10sByRangeParams}> = (props) => {
          const {params} = props ?? {};

          return  retrieveConditionsIcd10sByRange(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof retrieveConditionsIcd10sByRange>>, TError, {params?: RetrieveConditionsIcd10sByRangeParams}, TContext>(mutationFn, mutationOptions)
    }
    