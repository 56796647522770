import { Button, Card, CardActions, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, withFormStatus } from "common/forms";
import FormContainer from "../../../forms/FormContainer";

interface ResetPasswordFormProps {
	verificationCode?: string;
	onConfirmPassword: (password: string, code: string) => void;
}

function ResetPasswordForm({ verificationCode, onConfirmPassword }: ResetPasswordFormProps) {
	const { register, handleSubmit, formState } = useForm<{
		password: string;
		confirmPassword: string;
		verificationCode: string;
	}>({ defaultValues: { verificationCode } });

	const onSubmit = handleSubmit((form) => onConfirmPassword(form.password, form.verificationCode));

	console.debug("formState.errors", formState.errors);

	return (
		<FormContainer
			topContent={
				<Typography variant="h1" sx={{ fontWeight: "500" }} component="h1">
					Reset Password
				</Typography>
			}
		>
			<Card
				component="form"
				onSubmit={onSubmit}
				sx={{
					width: "100%",
					paddingLeft: "24px",
					paddingRight: "24px",
					paddingTop: "36px",
					paddingBottom: "24px",
					boxSizing: "border-box",
				}}
				elevation={4}
			>
				<CardContent>
					<Stack spacing={2}>
						<Typography>
							Please check your email for a 6 digit verification code. Once you've received the code, please enter that code below.
						</Typography>
						{verificationCode ? null : <TextField label="Verification Code" {...register("verificationCode")} variant="standard" />}
						<TextField label="New Password" type="password" {...register("password", { required: true })} variant="standard" />
						<TextField
							label="Confirm New Password"
							type="password"
							{...register("confirmPassword", {
								required: true,
								validate: {
									matchesPassword: () => {
										console.log("matchesPassword");
										return true;
									},
									// fail: () => {
									//   console.log("fail");

									//   return "fails fail";
									// },
								},
							})}
							variant="standard"
						/>
					</Stack>
				</CardContent>
				<FormErrorAlert />
				<CardActions sx={{ justifyContent: "flex-end" }}>
					<Button type="submit" sx={{ backgroundColor: "core.darkblue" }}>
						Change Password
					</Button>
					<Button sx={{ justifyContent: "flex-end", mt: 0, color: "gray.dark" }} component={Link} to="/signin" variant="text">
						Cancel
					</Button>
				</CardActions>
			</Card>
		</FormContainer>
	);
}

export default withFormStatus(ResetPasswordForm);
