import { Box, Typography } from "@mui/material";
import displayConverter from "common/utilities/displayConverter";
import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medicationDetails/requests/useQ_getDrugStructureData";

type MDP_DescriptionProps = {
	drugStructureData: MedicationDetailDataPackage | null;
	medicationDetailItem: SingleCareMedicationDetail | null;
	description?: string;
};
const MDP_Description = ({ medicationDetailItem, description }: MDP_DescriptionProps) => {
	return (
		<Box>
			{medicationDetailItem ? (
				<Box>
					<Box>
						<Typography component="span">{medicationDetailItem.Name}</Typography>{" "}
						<Typography component="span" variant="entity-descriptor">
							({medicationDetailItem.FullName})
						</Typography>
					</Box>
					<Box>
						{medicationDetailItem.Description && medicationDetailItem.Description !== "" ? (
							<Typography>{medicationDetailItem.Description}</Typography>
						) : description && description !== "" ? (
							<Typography>{description}</Typography>
						) : (
							<Typography variant="entity-descriptor">[Description unavailable]</Typography>
						)}
					</Box>
					{medicationDetailItem.PriceUsualAndCustomary ? (
						<Box>
							<Typography variant="entity-descriptor">
								Typical Price: {displayConverter.toUSD(medicationDetailItem.PriceUsualAndCustomary)}{" "}
								{medicationDetailItem.Quantity ? <>for {medicationDetailItem.Quantity}</> : null}
							</Typography>
							{medicationDetailItem.Quantity && medicationDetailItem.PriceUsualAndCustomary ? (
								<Typography variant="entity-descriptor" style={{ marginLeft: "8px" }}>
									({displayConverter.toUSD(medicationDetailItem.PriceUsualAndCustomary / medicationDetailItem.Quantity)} each)
								</Typography>
							) : null}
						</Box>
					) : null}
				</Box>
			) : null}
		</Box>
	);
};

export { MDP_Description };
