import { AppBar, Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";
import { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useQ_careScore } from "dashboard/requests/useQ_careScore";
import { create_ConsumerDoctorFindHigherRatedCareRequestModel, useQ_findHigherRatedCare } from "./requests/useQ_findHigherRatedCare";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { ConsumerDoctorFindHigherRatedCareRequestModel, ConsumerDoctorFindHigherRatedCareResponseModelDoctorRecordsByType } from "api";
import { GeolocationContext } from "common/context/GeolocationContext";
import { CONVERT_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { DoctorTile } from "common/components/Tiles/DoctorTile";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";
import { HospitalsList } from "./HospitalsList";
import { InNetworkSelect } from "common/components/Inputs/InNetworkSelect";
import { AccountManagerContext } from "common/managers/AccountManager";

function OptimizeCareRatings({ careScoreDetailsCategory }: { careScoreDetailsCategory: string }) {
	const careScoreData = useQ_careScore({ startDate: "default" });
	const geoContext = useContext(GeolocationContext);

	const isHospitals = careScoreDetailsCategory === "hospitals";

	const accountContext = useContext(AccountManagerContext);

	const [inNetwork, set_inNetwork] = useState<boolean>(!!accountContext.activeProviderOptions);

	const [descriptionSection, categoryDisplayName] = useMemo(() => {
		let descSection: ReactNode | null = null;
		let category_display_name: string | undefined;
		if (careScoreDetailsCategory === "doctor_clinician") {
			descSection = (
				<ContentSection title="Description">
					<Typography>
						Other healthcare providers in your radius could increase your overall care team score. Consider revising your doctors with others that
						have more experience with your particular health concerns. Often a second opinion is a smart choice. Below you will find higher rated
						providers in your care categories and local to you.
					</Typography>
				</ContentSection>
			);
		} else {
			if (careScoreData.data?.data?.providers?.[careScoreDetailsCategory]) {
				careScoreData.data.data.providers[careScoreDetailsCategory].some((x) => {
					return x.taxonomies.some((t) => {
						if (t.grouping) {
							category_display_name = t.grouping;
							return true;
						}
						return false;
					});
				});
			}

			descSection = (
				<ContentSection title="Description">
					<Typography>
						Other {category_display_name ?? "health care facilities"} in your radius could increase your overall care team score. Consider revising
						your health care organizations with others that have more experience with your particular health concerns. Often a second opinion is a
						smart choice. Below you will find higher rated {category_display_name ?? "facilities"} that are local to you.
					</Typography>
				</ContentSection>
			);
		}
		return [descSection, category_display_name];
	}, [careScoreDetailsCategory, careScoreData.data?.data]);

	/*
██████  ██    ██ ██ ██      ██████      ██   ██ ██████   ██████     ██████  ███████  ██████  ██    ██ ███████ ███████ ████████ 
██   ██ ██    ██ ██ ██      ██   ██     ██   ██ ██   ██ ██          ██   ██ ██      ██    ██ ██    ██ ██      ██         ██    
██████  ██    ██ ██ ██      ██   ██     ███████ ██████  ██          ██████  █████   ██    ██ ██    ██ █████   ███████    ██    
██   ██ ██    ██ ██ ██      ██   ██     ██   ██ ██   ██ ██          ██   ██ ██      ██ ▄▄ ██ ██    ██ ██           ██    ██    
██████   ██████  ██ ███████ ██████      ██   ██ ██   ██  ██████     ██   ██ ███████  ██████   ██████  ███████ ███████    ██ 
	*/
	useEffect(() => {
		GeolocationManager.getPosition();
	}, []);

	const findHigherRatedCareRequest: ConsumerDoctorFindHigherRatedCareRequestModel | null = useMemo(() => {
		if (!careScoreData.data?.data || isHospitals) {
			return null;
		} else {
			let clientLocation = GeolocationManager.clientLocation({ useAccountPrefs: true });
			// let clientLocation = GeolocationManager.clientLocation();
			let findCareRequestX = create_ConsumerDoctorFindHigherRatedCareRequestModel(
				clientLocation,
				careScoreData.data.data,
				careScoreDetailsCategory,
				inNetwork
			);
			// console.debug(findCareRequestX);
			return findCareRequestX;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [careScoreDetailsCategory, careScoreData.data?.data, geoContext.position?.coords, inNetwork]);

	const higherRatedCare = useQ_findHigherRatedCare({ requestPackage: findHigherRatedCareRequest });

	const [noResultsWithinDistance, provsByType] = useMemo(() => {
		let provsByType: ConsumerDoctorFindHigherRatedCareResponseModelDoctorRecordsByType | undefined;
		let distance = higherRatedCare?.data?.data?.data?.distanceRadiusMiles;
		if (careScoreDetailsCategory === "doctor_clinician") {
			provsByType = higherRatedCare?.data?.data?.data?.doctorRecordsByType;
		} else {
			provsByType = higherRatedCare?.data?.data?.data?.facilityRecordsByType;
		}
		let noResultsWithinDistance = null;
		if (provsByType) {
			if (
				Object.keys(provsByType).some((key) => {
					if (provsByType && provsByType[key]?.length > 0) {
						return true;
					}
					return false;
				})
			) {
				noResultsWithinDistance = -1;
			} else {
				noResultsWithinDistance = distance ?? null;
			}
		}
		return [noResultsWithinDistance, provsByType];
	}, [higherRatedCare, careScoreDetailsCategory]);

	return (
		<Box style={{ minWidth: style.minWidth }}>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Stack
					sx={{
						minHeight: style.headerHeight,
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff",
					}}
				>
					<Typography variant="h1">Optimize Your Care</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("optimize-care-ratings");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Stack>
			</AppBar>
			<Container maxWidth={"md"} sx={{ paddingY: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						{descriptionSection}

						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "8px" }}>
							{accountContext.activeProviderOptions ? (
								<Box style={{ maxWidth: "160px", flexGrow: "1", paddingRight: "8px" }}>
									<InNetworkSelect set_inNetwork={set_inNetwork} inNetwork={inNetwork} />
								</Box>
							) : null}
						</Box>
					</Grid>

					{isHospitals ? (
						<HospitalsList />
					) : (
						<QueryStatusDisplay queryResult={higherRatedCare}>
							{provsByType
								? Object.keys(provsByType).map((key) => {
										let x = provsByType[key];
										if (x.length === 0) {
											return null;
										}
										return (
											<Grid item key={key} xs={12}>
												<ContentSection title={key}>
													<LoadMorePaginator
														pageSize={5}
														data={x.map((y, i) => {
															var entity = CONVERT_DoctorRecord_Entity(y, "AUTO");
															entity.descriptor =
																(entity._providerRecord?.taxonomies[0]?.displayName &&
																entity._providerRecord?.taxonomies[0]?.displayName !== ""
																	? entity._providerRecord?.taxonomies[0]?.displayName
																	: entity._providerRecord?.specialties !== ""
																	? entity._providerRecord?.specialties
																	: entity._providerRecord?.hgSpecialty !== ""
																	? entity._providerRecord?.hgSpecialty
																	: "") ?? "";
															let viewProfile = () => {
																DialogManager.open("provider-details", entity._providerRecord?.npi ?? "MISSING NPI");
															};
															let content = (
																<Box>
																	<Button variant="text" onClick={viewProfile}>
																		View Profile
																	</Button>
																</Box>
															);
															return (
																<Box key={i} sx={{ paddingBottom: ".5rem" }}>
																	<DoctorTile key={i} Entity={entity} content={content} />
																</Box>
															);
														})}
														renderItem={(providerTile: any, i?: number) => {
															return providerTile;
														}}
													/>

													{x.length === 0 ? (
														<Typography>
															No Higher-Rated {key} {careScoreDetailsCategory === "doctor_clinician" ? "Providers" : null} found
															within {higherRatedCare?.data?.data?.data.distanceRadiusMiles} miles.
														</Typography>
													) : null}
												</ContentSection>
											</Grid>
										);
								  })
								: null}
							{typeof noResultsWithinDistance === "number" && noResultsWithinDistance !== -1 ? (
								careScoreDetailsCategory === "doctor_clinician" ? (
									<Grid item xs={12}>
										<ContentSection title={"No Results"}>
											<Typography>No Higher-Rated Providers were found within {noResultsWithinDistance} miles</Typography>
										</ContentSection>
									</Grid>
								) : (
									<Grid item xs={12}>
										<ContentSection title={"No Results"}>
											<Typography>
												No Higher-Rated {categoryDisplayName} were found within {noResultsWithinDistance} miles
											</Typography>
										</ContentSection>
									</Grid>
								)
							) : null}
						</QueryStatusDisplay>
					)}
				</Grid>
			</Container>
		</Box>
	);
}

export { OptimizeCareRatings };
