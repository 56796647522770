import { Stack, Button } from "@mui/material";
import { ConsumerCostOfCareResponseModel } from "api";
import { LabelValueTile } from "common/components/Tiles/LabelValueTile";
import { DialogManager } from "common/managers/DialogManager";
import displayConverter from "common/utilities/displayConverter";

type MedicationSavingsProps = {
	costOfCareData: ConsumerCostOfCareResponseModel;
};
const MedicationSavings = ({ costOfCareData }: MedicationSavingsProps) => {
	const medicationSavings = costOfCareData.savingsModel.medicationSavings !== undefined ? costOfCareData.savingsModel.medicationSavings / 100 : 0;
	return (
		<Stack>
			<LabelValueTile title="Total Cost" value={displayConverter.toUSD(costOfCareData.savingsModel.medicationCost)} color={"core.forestgreen"} />
			<LabelValueTile title="Radius Care Found Savings Up To" value={displayConverter.toPercent(medicationSavings)} color={"core.forestgreen"} />
			<Stack justifyContent={"center"} flexDirection={"row"} sx={{ "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}>
				<Button
					onClick={() => {
						DialogManager.open("medication-savings-details");
					}}
				>
					View Details
				</Button>
			</Stack>
		</Stack>
	);
};

export { MedicationSavings };
