import { FullscreenLoader } from "common/components/Loaders/FullscreenLoader";
import { createContext, Dispatch, SetStateAction, useEffect, useState } from "react";
import { StaticDataDefinitionsResult, retrieveStaticDataDefinitions } from "../requests/codes_and_definitions/useQ_staticDataDefinitions";
import { ProviderTaxonomyPackage } from "common/utilities/providerTaxonomyMapper";
import { StaticDataManager } from "common/managers/StaticDataManager";

/*
██████  ██  █████  ██       ██████   ██████  ███████ 
██   ██ ██ ██   ██ ██      ██    ██ ██       ██      
██   ██ ██ ███████ ██      ██    ██ ██   ███ ███████ 
██   ██ ██ ██   ██ ██      ██    ██ ██    ██      ██ 
██████  ██ ██   ██ ███████  ██████   ██████  ███████ 
*/

type DiagnosisDetailOption = {
	topDoctors?: "hide" | "only_IcdRangeLookupTaxonomy" | null;
};

type DialogsContextValue = {
	diagnosisDetailDisplay: {
		current: null | string;
		set_current: Dispatch<SetStateAction<null | string>>;
		options: null | DiagnosisDetailOption;
		set_options: Dispatch<SetStateAction<null | DiagnosisDetailOption>>;
	};
	providerSavingsDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	careSavingsDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	medicationSavingsDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	medicationDetailDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	providerDetailDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	hospitalDetailDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	optimizeCareRatingsDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	careAlertDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	suggestSimilarProvidersDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	inviteCareCollaboratorDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	provideFeedbackDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	updateInfoDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	changePasswordDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	verifyEmailDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	addCarePlanDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	activateAchievementsDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
	accarentAlertDisplay: { current: null | string; set_current: Dispatch<SetStateAction<null | string>> };
};

const DialogsContext = createContext<DialogsContextValue>({
	diagnosisDetailDisplay: { current: null, set_current: () => null, options: null, set_options: () => null },
	providerSavingsDisplay: { current: null, set_current: () => null },
	careSavingsDisplay: { current: null, set_current: () => null },
	medicationSavingsDisplay: { current: null, set_current: () => null },
	medicationDetailDisplay: { current: null, set_current: () => null },
	providerDetailDisplay: { current: null, set_current: () => null },
	hospitalDetailDisplay: { current: null, set_current: () => null },
	optimizeCareRatingsDisplay: { current: null, set_current: () => null },
	careAlertDisplay: { current: null, set_current: () => null },
	suggestSimilarProvidersDisplay: { current: null, set_current: () => null },
	inviteCareCollaboratorDisplay: { current: null, set_current: () => null },
	provideFeedbackDisplay: { current: null, set_current: () => null },
	updateInfoDisplay: { current: null, set_current: () => null },
	changePasswordDisplay: { current: null, set_current: () => null },
	verifyEmailDisplay: { current: null, set_current: () => null },
	addCarePlanDisplay: { current: null, set_current: () => null },
	activateAchievementsDisplay: { current: null, set_current: () => null },
	accarentAlertDisplay: { current: null, set_current: () => null },
});

/*
███████ ████████  █████  ████████ ██  ██████     ██████   █████  ████████  █████  
██         ██    ██   ██    ██    ██ ██          ██   ██ ██   ██    ██    ██   ██ 
███████    ██    ███████    ██    ██ ██          ██   ██ ███████    ██    ███████ 
     ██    ██    ██   ██    ██    ██ ██          ██   ██ ██   ██    ██    ██   ██ 
███████    ██    ██   ██    ██    ██  ██████     ██████  ██   ██    ██    ██   ██ 
*/
const StaticDataDefinitionsContext = createContext<StaticDataDefinitionsResult>({
	ICD10CM_Package: null as CIM,
	HCPCS: null as CIM,
	providerTaxonomyPackage: null as unknown as ProviderTaxonomyPackage,
	providerTypes: [],
	physicianTypes: [],
	facilityTypes: [], // used FindCareProviders
	medicationBrandTypes: [],
	// conditionTypes: [],
	genders: [],
	// specialties: [],
	conditions: [],
	languages: [],
	insuranceOptions: [],
	providerTaxonomyClassifications: [],
});

//// ApplicationEventsContext
// type ApplicationEventNotifierState<T1> = {
// 	current: T1;
// 	set_current: Dispatch<SetStateAction<T1>>;
// };
// type ApplicationEventsContextModel = {
// 	CareCollaboratorUpdate: ApplicationEventNotifierState<number>;
// };

// const ApplicationEventsContext = createContext<ApplicationEventsContextModel>({
// 	CareCollaboratorUpdate: null as CIM,
// });

function MainContextProvider(props: React.PropsWithChildren<{}>) {
	const [diagnosisDetailCurrent, set_diagnosisDetailCurrent] = useState<null | string>(null);
	const [diagnosisDetailOption, set_diagnosisDetailOption] = useState<DiagnosisDetailOption | null>(null);
	const [providerSavingsDisplayCurrent, set_providerSavingsDisplayCurrent] = useState<null | string>(null);
	const [medicationSavingsDisplayCurrent, set_medicationSavingsDisplayCurrent] = useState<null | string>(null);
	const [careSavingsDisplayCurrent, set_careSavingsDisplayCurrent] = useState<null | string>(null);
	const [medicationDetailCurrent, set_medicationDetailCurrent] = useState<null | string>(null);
	const [providerDetailCurrent, set_providerDetailCurrent] = useState<null | string>(null);
	const [hospitalDetailCurrent, set_hospitalDetailCurrent] = useState<null | string>(null);
	const [optimizeCareRatingsCurrent, set_optimizeCareRatingsCurrent] = useState<null | string>(null);
	const [careAlertCurrent, set_careAlertCurrent] = useState<null | string>(null);
	const [suggestSimilarProvidersCurrent, set_suggestSimilarProvidersCurrent] = useState<null | string>(null);
	const [inviteCareCollaboratorDisplayCurrent, set_inviteCareCollaboratorDisplayCurrent] = useState<null | string>(null);
	// const [currentCareCollaboratorUpdate, set_currentCareCollaboratorUpdate] = useState<number>(0);
	const [provideFeedbackDisplayCurrent, set_provideFeedbackDisplayCurrent] = useState<null | string>(null);
	const [updateInfoDisplayCurrent, set_updateInfoDisplayCurrent] = useState<null | string>(null);
	const [changePasswordDisplayCurrent, set_changePasswordDisplayCurrent] = useState<null | string>(null);
	const [verifyEmailDisplayCurrent, set_verifyEmailDisplayCurrent] = useState<null | string>(null);
	const [addCarePlanDisplayCurrent, set_addCarePlanDisplayCurrent] = useState<null | string>(null);
	const [activateAchievementsDisplayCurrent, set_activateAchievementsDisplayCurrent] = useState<null | string>(null);
	const [accarentAlertDisplayCurrent, set_accarentAlertDisplayCurrent] = useState<null | string>(null);
	const [initialRender, set_initialRender] = useState<boolean>(false);

	// const staticDataDefinitions = useQ_staticDataDefinitions();

	// useEffect(() => {
	// 	if (staticDataDefinitions.data) {
	// 		StaticDataManager.data = staticDataDefinitions.data;
	// 		set_initialRender(true);
	// 	}
	// }, [staticDataDefinitions.data]);

	useEffect(() => {
		retrieveStaticDataDefinitions().then((sdd) => {
			StaticDataManager.data = sdd;
			set_initialRender(true);
		});
	}, []);

	if (StaticDataManager.data && initialRender) {
		return (
			<StaticDataDefinitionsContext.Provider value={StaticDataManager.data}>
				<DialogsContext.Provider
					value={{
						diagnosisDetailDisplay: {
							current: diagnosisDetailCurrent,
							set_current: set_diagnosisDetailCurrent,
							options: diagnosisDetailOption,
							set_options: set_diagnosisDetailOption,
						},
						providerSavingsDisplay: { current: providerSavingsDisplayCurrent, set_current: set_providerSavingsDisplayCurrent },
						medicationSavingsDisplay: { current: medicationSavingsDisplayCurrent, set_current: set_medicationSavingsDisplayCurrent },
						careSavingsDisplay: { current: careSavingsDisplayCurrent, set_current: set_careSavingsDisplayCurrent },
						medicationDetailDisplay: { current: medicationDetailCurrent, set_current: set_medicationDetailCurrent },
						providerDetailDisplay: { current: providerDetailCurrent, set_current: set_providerDetailCurrent },
						hospitalDetailDisplay: { current: hospitalDetailCurrent, set_current: set_hospitalDetailCurrent },
						optimizeCareRatingsDisplay: { current: optimizeCareRatingsCurrent, set_current: set_optimizeCareRatingsCurrent },
						careAlertDisplay: { current: careAlertCurrent, set_current: set_careAlertCurrent },
						suggestSimilarProvidersDisplay: { current: suggestSimilarProvidersCurrent, set_current: set_suggestSimilarProvidersCurrent },
						inviteCareCollaboratorDisplay: { current: inviteCareCollaboratorDisplayCurrent, set_current: set_inviteCareCollaboratorDisplayCurrent },
						provideFeedbackDisplay: { current: provideFeedbackDisplayCurrent, set_current: set_provideFeedbackDisplayCurrent },
						updateInfoDisplay: { current: updateInfoDisplayCurrent, set_current: set_updateInfoDisplayCurrent },
						changePasswordDisplay: { current: changePasswordDisplayCurrent, set_current: set_changePasswordDisplayCurrent },
						verifyEmailDisplay: { current: verifyEmailDisplayCurrent, set_current: set_verifyEmailDisplayCurrent },
						addCarePlanDisplay: { current: addCarePlanDisplayCurrent, set_current: set_addCarePlanDisplayCurrent },
						activateAchievementsDisplay: { current: activateAchievementsDisplayCurrent, set_current: set_activateAchievementsDisplayCurrent },
						accarentAlertDisplay: { current: accarentAlertDisplayCurrent, set_current: set_accarentAlertDisplayCurrent },
					}}
				>
					{/* <ApplicationEventsContext.Provider
						value={{
							CareCollaboratorUpdate: { current: currentCareCollaboratorUpdate, set_current: set_currentCareCollaboratorUpdate },
						}}
					> */}
					{props.children}
					{/* </ApplicationEventsContext.Provider> */}
				</DialogsContext.Provider>
			</StaticDataDefinitionsContext.Provider>
		);
	} else {
		return (
			<>
				<FullscreenLoader />
			</>
		);
	}
}

export {
	MainContextProvider,
	StaticDataDefinitionsContext,
	DialogsContext,
	// , ApplicationEventsContext
};
export type { DiagnosisDetailOption };
