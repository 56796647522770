import { Card, CardContent } from "@mui/material";
import FormContainer from "../../../forms/FormContainer";
import { ProgressDisplay } from "common/components/StepProgress/ProgressDisplay";
import { useEffect, useMemo, useState } from "react";
import { AccountManager } from "common/managers/AccountManager";
import { useNavigate } from "react-router-dom";
import { CariskConnectForm } from "account/pages/CariskCigna/CariskConnectForm";
import { CariskConnectFormValues, connectCariskPlanX } from "account/pages/CariskCigna/CariskConnectionAddonForm";
import { useQueryClient } from "react-query";
import { DialogManager } from "common/managers/DialogManager";
import { ErrorDisplay } from "common/components/DisplayElements/ErrorDisplay";

function CariskConnect({ searchParams }: { searchParams: URLSearchParams }) {
	const [progress, setProgress] = useState(0);
	const [showConnectionForm, set_showConnectionForm] = useState<boolean>(false);
	const [errorMessage, set_errorMessage] = useState<null | string>(null);

	const navigate = useNavigate();

	const complete = () => {
		set_showConnectionForm(false);
		setProgress(25);
		setTimeout(() => {
			setProgress(51);
			setTimeout(() => {
				setProgress(90);
				setTimeout(() => {
					setProgress(100);
					setTimeout(() => {
						AccountManager.setUser("REFRESH");
						DialogManager.closeAll();
						queryClient.invalidateQueries();
						// CHANGE navigate Comment/Uncomment below to go to the carisk success screen
						// navigate("/dashboard/myoverview");
						navigate("/connect-healthplan/carisk-success");
					}, 500);
				}, 500);
			}, 1000);
		}, 1000);
	};

	const queryClient = useQueryClient();

	const storedValues = useMemo(() => {
		let x = localStorage.getItem("CariskConnectFormValues");
		console.debug(x);

		let cariskConnectFormValues;

		try {
			cariskConnectFormValues = JSON.parse(x as string);
		} catch (e) {
			console.debug(e);
		}

		return cariskConnectFormValues;
	}, []);

	const connectAccount = async (storedValues?: Partial<CariskConnectFormValues>) => {
		console.group("connectAccount");
		console.debug({ AccountManager });

		if (storedValues) {
			let response = await connectCariskPlanX(storedValues as CIM, queryClient);
			console.debug(response);

			if (response.success === true) {
				complete();
			} else {
				set_showConnectionForm(true);
				if (response.errorCode === "CARISK_CONNECTION_ALREADY_EXISTS") {
					set_errorMessage(response.errorMessage ?? "Connection Error - Please Try Again");
				} else {
					set_errorMessage(response.errorMessage ?? "Connection Error - Please Try Again");
				}
			}
		} else {
			set_showConnectionForm(true);
		}
		console.groupEnd();
	};

	useEffect(() => {
		connectAccount(storedValues);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storedValues]);

	return (
		<FormContainer noTopAndBottom>
			{showConnectionForm ? (
				<CariskConnectForm
					onSuccess={complete}
					defaultValues={storedValues}
					onSubmit={() => {
						set_errorMessage(null);
					}}
					errorContent={errorMessage ? <ErrorDisplay errorMessage={errorMessage} /> : null}
				/>
			) : (
				<Card
					sx={{
						width: "100%",
						marginTop: "1rem",
						boxSizing: "border-box",
					}}
				>
					<CardContent>
						<ProgressDisplay progress={progress} type={"onboarding"} />
					</CardContent>
				</Card>
			)}
		</FormContainer>
	);
}

export { CariskConnect };
