import { getRecommendedUSPSTF } from "api";
import { ProcessedResponse, apiResponseReader } from "common/utilities/apiResponseReader";
import { useQuery, UseQueryResult } from "react-query";
import { ConsumerOperationStatusGetRecommendedUspstfResponse, ModelsOperationStatus } from "../../../api/backend.schemas";

// type retrieveRecommendedUSPSTF_APIParam = {} | undefined;

async function _getRecommendedUSPSTF_X(): Promise<ProcessedResponse<ConsumerOperationStatusGetRecommendedUspstfResponse | null>> {
	// params?: retrieveRecommendedUSPSTF_APIParam
	return await getRecommendedUSPSTF({}).then(async (data: ModelsOperationStatus | ConsumerOperationStatusGetRecommendedUspstfResponse) => {
		return apiResponseReader.processResponse(data) as ProcessedResponse<ConsumerOperationStatusGetRecommendedUspstfResponse | null>;
	});
}

function useQ_retrieveRecommendedUSPSTF(): UseQueryResult<ProcessedResponse<ConsumerOperationStatusGetRecommendedUspstfResponse | null>> {
	// params: retrieveRecommendedUSPSTF_APIParam
	// console.debug("useQ_retrieveRecommendedUSPSTF");
	return useQuery(["retrieveRecommendedUSPSTF"], _getRecommendedUSPSTF_X.bind(null), {
		staleTime: 86400000, // 24 * 1000 * 60 * 60 // 1DAY
		cacheTime: 86400000,
	});
}

export { useQ_retrieveRecommendedUSPSTF };
