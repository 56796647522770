import { Typography } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import { palette } from "theme";

const LinkLike = ({
	children,
	onClick,
}: PropsWithChildren<{
	onClick?: (x: any) => any;
}>) => {
	const [transientClick, setTransientClick] = useState(false);
	return (
		<Typography
			sx={{
				opacity: transientClick ? 0.5 : 1,
				color: palette.core.blue,
				cursor: "pointer",
				transition: "opacity 250ms",
			}}
			component="span"
			onClick={(x: any) => {
				if (onClick) {
					setTransientClick(true);
					setTimeout(() => {
						setTransientClick(false);
					}, 250);
					onClick(x);
				}
			}}
		>
			{children}
		</Typography>
	);
};

export { LinkLike };
