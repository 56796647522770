import { Box, Typography } from "@mui/material";
import { ConsumerGetConditionDetailResult } from "api";
import { ReactNode, useMemo } from "react";
import { UseQueryResult } from "react-query";

type DiagnosisDetailDescriptionProps = {
	conditionDetailResult: UseQueryResult<ConsumerGetConditionDetailResult>;
	medlineErrorRefreshButton: ReactNode;
};

const DiagnosisDetailDescription = ({ conditionDetailResult, medlineErrorRefreshButton }: DiagnosisDetailDescriptionProps) => {
	const detailResponse = useMemo(() => {
		if (conditionDetailResult?.data?.response && conditionDetailResult.isSuccess) {
			return conditionDetailResult.data.response;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conditionDetailResult]);

	return (
		<Box>
			{detailResponse?.icd10Cm?.medlineSummary ? (
				<Typography component={"span"} dangerouslySetInnerHTML={{ __html: detailResponse.icd10Cm.medlineSummary }}></Typography>
			) : (
				<Typography component="div">
					{detailResponse?.icd10Cm
						? [
								detailResponse.icd10Cm.icd10Code ? (
									<Typography key="code" component="div">
										<Typography variant="entity-descriptor">Code:</Typography> {detailResponse.icd10Cm.icd10Code}
									</Typography>
								) : null,
								detailResponse.icd10Cm.title ? (
									<Typography key="title" component="div">
										<Typography variant="entity-descriptor">Title:</Typography> {detailResponse.icd10Cm.title}
									</Typography>
								) : null,
								detailResponse.icd10Cm.description ? (
									<Typography key="description" component="div">
										<Typography variant="entity-descriptor">Description:</Typography> {detailResponse.icd10Cm.description}
									</Typography>
								) : null,
								detailResponse.icd10Cm.rangeCategory ? (
									<Typography key="range" component="div">
										<Typography variant="entity-descriptor">Category:</Typography> {detailResponse.icd10Cm.rangeCategory}
									</Typography>
								) : null,
						  ]
						: null}
					[Additional Information Unavailable]
					{/* <pre>{JSON.stringify(dd, null, 2)}</pre> */}
					{/* <pre>{JSON.stringify(consumerMedicalCodeGetConditionDetailResponseModel, null, 2)}</pre> */}
				</Typography>
			)}
			{detailResponse?.icd10Cm.medlineSummaryRequestFailed === true && !detailResponse?.icd10Cm?.medlineSummary ? medlineErrorRefreshButton : null}
		</Box>
	);
};

export { DiagnosisDetailDescription };
