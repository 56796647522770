import { useQuery, UseQueryResult } from "react-query";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
// import { ConsumerCareScoreResponseModel_Extended } from "api_extended/consumer-patient-health-data/care-score_extended";
import { findHospitalsByLocation } from "../../../api/consumer-doctor/consumer-doctor";
import { ConsumerDoctorFindHospitalsByLocationRequestModel, ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel } from "api";

async function findHospitalsByLocationX(
	params: ConsumerDoctorFindHospitalsByLocationRequestModel
): Promise<ProcessedResponse<ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel | null>> {
	// if (!params.requestPackage) {
	// 	return apiResponseReader.createProcessedResponse_NOREQUEST<ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel>(
	// 		"Missing Required Parameters"
	// 	);
	// }
	let result = await findHospitalsByLocation(params);

	return apiResponseReader.processResponse(result as unknown as ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel);
}

function useQ_findHospitalsByLocation(
	params: ConsumerDoctorFindHospitalsByLocationRequestModel
): UseQueryResult<ProcessedResponse<ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel>> {
	return useQuery(
		[
			"clientlocation",
			"findHospitalsByLocation",
			`[
				_lat-${params.clientLocation.latitude}
				_long-${params.clientLocation.longitude}
				_zip-${params.clientLocation.zipcode}
				_dist-${params.clientLocation.distanceRadiusMiles}`,
		],
		findHospitalsByLocationX.bind(null, params),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);
}

export { useQ_findHospitalsByLocation };
