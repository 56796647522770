/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerMedicalCodeGetAllICD10CMCodesResult,
  ConsumerMedicalCodeGetICD10CMCodesPackageResult,
  ConsumerMedicalCodeGetAllHCPCSCodesResult,
  ConsumerGetConditionDetailResult,
  ModelsClientLocation,
  GetConditionDetailParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getAllICD10CMCodes = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMedicalCodeGetAllICD10CMCodesResult>(
      {url: `/api/consumer/ConsumerMedicalCode/GetAllICD10CMCodes`, method: 'get', signal
    },
      options);
    }
  

export const getGetAllICD10CMCodesQueryKey = () => [`/api/consumer/ConsumerMedicalCode/GetAllICD10CMCodes`];

    
export type GetAllICD10CMCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllICD10CMCodes>>>
export type GetAllICD10CMCodesQueryError = unknown

export const useGetAllICD10CMCodes = <TData = Awaited<ReturnType<typeof getAllICD10CMCodes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllICD10CMCodes>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllICD10CMCodesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllICD10CMCodes>>> = ({ signal }) => getAllICD10CMCodes(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllICD10CMCodes>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getICD10CMCodesPackage = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMedicalCodeGetICD10CMCodesPackageResult>(
      {url: `/api/consumer/ConsumerMedicalCode/GetICD10CMCodesPackage`, method: 'get', signal
    },
      options);
    }
  

export const getGetICD10CMCodesPackageQueryKey = () => [`/api/consumer/ConsumerMedicalCode/GetICD10CMCodesPackage`];

    
export type GetICD10CMCodesPackageQueryResult = NonNullable<Awaited<ReturnType<typeof getICD10CMCodesPackage>>>
export type GetICD10CMCodesPackageQueryError = unknown

export const useGetICD10CMCodesPackage = <TData = Awaited<ReturnType<typeof getICD10CMCodesPackage>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getICD10CMCodesPackage>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetICD10CMCodesPackageQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getICD10CMCodesPackage>>> = ({ signal }) => getICD10CMCodesPackage(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getICD10CMCodesPackage>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getAllHCPCSCodes = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerMedicalCodeGetAllHCPCSCodesResult>(
      {url: `/api/consumer/ConsumerMedicalCode/GetAllHCPCSCodes`, method: 'get', signal
    },
      options);
    }
  

export const getGetAllHCPCSCodesQueryKey = () => [`/api/consumer/ConsumerMedicalCode/GetAllHCPCSCodes`];

    
export type GetAllHCPCSCodesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllHCPCSCodes>>>
export type GetAllHCPCSCodesQueryError = unknown

export const useGetAllHCPCSCodes = <TData = Awaited<ReturnType<typeof getAllHCPCSCodes>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllHCPCSCodes>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAllHCPCSCodesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllHCPCSCodes>>> = ({ signal }) => getAllHCPCSCodes(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllHCPCSCodes>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getConditionDetail = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetConditionDetailParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerGetConditionDetailResult>(
      {url: `/api/consumer/ConsumerMedicalCode/GetConditionDetail`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetConditionDetailMutationResult = NonNullable<Awaited<ReturnType<typeof getConditionDetail>>>
    export type GetConditionDetailMutationBody = ModelsClientLocation
    export type GetConditionDetailMutationError = unknown

    export const useGetConditionDetail = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getConditionDetail>>, TError,{data: ModelsClientLocation;params?: GetConditionDetailParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getConditionDetail>>, {data: ModelsClientLocation;params?: GetConditionDetailParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getConditionDetail(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getConditionDetail>>, TError, {data: ModelsClientLocation;params?: GetConditionDetailParams}, TContext>(mutationFn, mutationOptions)
    }
    