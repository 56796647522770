import { NavigationItemHorizontal } from "../main/navigation/NavigationItemHorizontal";
import { HealthIcon, health_icons_svg } from "../common/components/Icons/HealthIcon";
import { AdaptiveMenu } from "common/components/Menus/AdaptiveMenu";

const FindCareMenu = () => {
  return (
    <AdaptiveMenu id="FindCareMenu">
      <NavigationItemHorizontal label="Providers" icon={<HealthIcon iconSVG={health_icons_svg.doctormale} />} to="/findcare/providers" />
      <NavigationItemHorizontal label="Medication" icon={<HealthIcon iconSVG={health_icons_svg.pill3} />} to="/findcare/medication" />
      <NavigationItemHorizontal label="Conditions" icon={<HealthIcon iconSVG={health_icons_svg.stethoscope} />} to="/findcare/conditions" />
    </AdaptiveMenu>
  );
};

export { FindCareMenu };
