import { ModelsDoctorRecord } from "api";
import { IconSVG } from "common/components/Icons/HealthIcon";
import { DataDisplayType } from "common/utilities/displayConverter";
import { ColorType } from "common/utilities/styleConverter";
import { palette } from "theme";
import { isMedicationEntity } from "common/definitions/entity/MedicationEntity";
import { DialogManager } from "common/managers/DialogManager";
import { CONVERT_DoctorRecord_Entity, CREATE_UNKNOWN_DoctorRecord_Entity, isProviderEntity } from "./entity/ProviderEntity";
import { event_type_definitions } from "./EventDefinitions";
import { isConditionEntity } from "./entity/ConditionEntity";
import { ReactNode } from "react";

type EntityTypeNames = "provider" | "medication" | "condition" | "diagnosis" | "pharmacy" | "physician" | "facility";
interface EntityType {
	iconSVG: IconSVG;
	iconColor?: string;
	titleColor?: string;
}
type EntityTypes = {
	[key in EntityTypeNames]: EntityType;
};

type EntityActionNames =
	| "make_appointment"
	| "learn_more"
	| "view_details"
	| "suggest_similar"
	| "view_map"
	| "view_all_prices"
	| "price_alerts"
	| "send_message"
	| "view_care_path"
	| "request_refill"
	| "care_alert"
	| "order"
	| "rate_doctor"
	| "find_doctor";

type EntityActionDescription = {
	action_name: EntityActionNames;
};

const typeCheck_EntityActionDescription = (x: any): x is EntityActionDescription => {
	if (x && x.hasOwnProperty("action_name") && entity_actions.hasOwnProperty(x.action_name)) {
		return true;
	}
	return false;
};

type EntityAction = {
	action: (x: any) => any;
	text: string;
	special_style?: React.CSSProperties;
};

type EntityActions = {
	[key in EntityActionNames]: EntityAction;
};

interface DisplayInfo {
	title: string;
	value: string | boolean | number;
	colorType?: ColorType;
	dataType?: DataDisplayType;
	explicitNode?: ReactNode;
}
interface Entity {
	entityTypeName: EntityTypeNames;
	imageSrc?: string;
	title: string;
	descriptor: string;
	displayInfo: Array<DisplayInfo>;
	actions?: Array<EntityActionNames | EntityActionDescription>;
	radiusRating?: number | null;
	_record?: ModelsDoctorRecord;
	_providerRecord?: ModelsDoctorRecord;
	_data?: any;
	imageURL?: string;
	websiteUrl?: string;
	directionsUrl?: string;
	genderCode?: string;
	specialty?: string;
}

export type { EntityTypeNames, EntityType, EntityTypes, Entity, EntityAction, EntityActions, EntityActionNames, EntityActionDescription, DisplayInfo };

const entity_types: EntityTypes = {
	provider: {
		iconColor: event_type_definitions["provider"].color,
		titleColor: event_type_definitions["provider"].color,
		iconSVG: event_type_definitions["provider"].iconSVG,
	},
	physician: {
		iconColor: event_type_definitions["physician"].color,
		titleColor: event_type_definitions["physician"].color,
		iconSVG: event_type_definitions["physician"].iconSVG, //health_icons_svg.doctormale,
	},
	facility: {
		iconColor: event_type_definitions["facility"].color,
		titleColor: event_type_definitions["facility"].color,
		iconSVG: event_type_definitions["facility"].iconSVG,
	},
	medication: {
		iconColor: event_type_definitions["medication"].color,
		titleColor: event_type_definitions["medication"].color,
		iconSVG: event_type_definitions["medication"].iconSVG,
	},
	condition: {
		iconColor: event_type_definitions["condition"].color,
		titleColor: event_type_definitions["condition"].color,
		iconSVG: event_type_definitions["condition"].iconSVG, // health_icons_svg.stethoscope,
	},
	diagnosis: {
		iconColor: event_type_definitions["diagnosis"].color,
		titleColor: event_type_definitions["diagnosis"].color,
		iconSVG: event_type_definitions["diagnosis"].iconSVG,
	},
	pharmacy: {
		iconColor: event_type_definitions["pharmacy"].color,
		titleColor: palette.gray.darker,
		iconSVG: event_type_definitions["pharmacy"].iconSVG,
	},
};

const special_styles = {
	alert: { color: palette.error.main, borderColor: palette.error.main },
};

const openDialogForEntity = (x: Entity) => {
	// console.debug("openDialogForEntity", x);
	if (isMedicationEntity(x)) {
		if (x._medicationRecord || x._data?.code) {
			let code = x._medicationRecord?.ndc ?? x._data?.code;
			console.debug(x);
			if (x.title) {
				code += "|" + x.title;
			}
			DialogManager.open("medication-details", code);
		}
	} else if (isConditionEntity(x)) {
		// console.log("condition----");
		// console.debug(x);
		if (x._conditionRecord || x._data?.diagnosisCode) {
			DialogManager.open("diagnosis-details", (x._conditionRecord as CIM)?.code ?? (x._conditionRecord as CIM)?.ICD10Code ?? x._data?.diagnosisCode);
		}
	} else if (isProviderEntity(x)) {
		// console.debug(x);
		if (x._record || x._providerRecord) {
			DialogManager.open("provider-details", x._record?.npi ?? x._providerRecord?.npi ?? "MISSING NPI");
			// console.log("open");
		}
	}
};

const entity_actions: EntityActions = {
	learn_more: {
		action: (x: Entity) => {
			console.debug("learn_more", x);
			openDialogForEntity(x);
		},
		text: "Learn More",
	},
	view_details: {
		action: (x: Entity) => {
			console.debug("view_details", x);
			openDialogForEntity(x);
		},
		text: "View Details",
	},
	suggest_similar: {
		action: (x: Entity) => {
			console.debug("suggest_similar", x);
			DialogManager.open("suggest-similar-providers", x._record?.npi ?? x._providerRecord?.npi ?? "MISSING NPI");
		},
		text: "Suggest Similar",
	},
	make_appointment: {
		action: (x: any) => {
			console.debug("make_appointment", x);
			alert("make_appointment\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Make Appointment",
	},
	view_map: {
		action: (x: any) => {
			console.debug("view_map", x);
			alert("view_map\r\n" + JSON.stringify(x, null, 2));
		},
		text: "View Map",
	},
	view_all_prices: {
		action: (x: any) => {
			console.debug("view_all_prices", x);
			alert("view_all_prices\r\n" + JSON.stringify(x, null, 2));
		},
		text: "View All Prices",
	},
	price_alerts: {
		action: (x: any) => {
			console.debug("price_alerts", x);
			alert("price_alerts\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Price Alerts",
	},
	send_message: {
		action: (x: any) => {
			console.debug("send_message", x);
			alert("send_message\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Send Message",
	},
	view_care_path: {
		action: (x: any) => {
			console.debug("view_care_path", x);
			alert("view_care_path\r\n" + JSON.stringify(x, null, 2));
		},
		text: "View Care Path",
	},
	request_refill: {
		action: (x: any) => {
			console.debug("request_refill", x);
			alert("request_refill\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Request Refill",
	},
	care_alert: {
		action: (x: any) => {
			console.debug("care_alert", x);
			alert("care_alert\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Care Alert",
		special_style: special_styles["alert"],
	},
	order: {
		action: (x: any) => {
			console.debug("order", x);
			alert("order\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Order",
	},
	rate_doctor: {
		action: (x: any) => {
			console.debug("rate_doctor", x);
			alert("rate_doctor\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Rate Doctor",
	},
	find_doctor: {
		action: (x: any) => {
			console.debug("find_doctor", x);
			alert("find_doctor\r\n" + JSON.stringify(x, null, 2));
		},
		text: "Find Doctor",
	},
};
export { entity_types, entity_actions };

const create_EntityActions_for_Entity = (entity: Entity) => {
	return entity.actions?.map((actionName) => {
		let a = entity_actions[typeCheck_EntityActionDescription(actionName) ? actionName.action_name : actionName];
		if (a) {
			return {
				action: a.action.bind(null, entity) as () => any,
				text: a.text,
			};
		} else {
			window.RadiusCare.log_issue({ message: `Unrecognized entity_action: ${actionName}`, file: "EntityDefinition" });
			return null;
		}
	});
};

/*
███    ███  █████  ██████      ██   ██     ████████  ██████      ███████ ███    ██ ████████ ██ ████████ ████████ ██    ██ 
████  ████ ██   ██ ██   ██      ██ ██         ██    ██    ██     ██      ████   ██    ██    ██    ██       ██     ██  ██  
██ ████ ██ ███████ ██████        ███          ██    ██    ██     █████   ██ ██  ██    ██    ██    ██       ██      ████   
██  ██  ██ ██   ██ ██           ██ ██         ██    ██    ██     ██      ██  ██ ██    ██    ██    ██       ██       ██    
██      ██ ██   ██ ██          ██   ██        ██     ██████      ███████ ██   ████    ██    ██    ██       ██       ██    
*/

export { CONVERT_DoctorRecord_Entity, CREATE_UNKNOWN_DoctorRecord_Entity, create_EntityActions_for_Entity };
