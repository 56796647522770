import { ButtonBase } from "@mui/material";

type IconToggleProps = {
	onClick?: (...args: any[]) => any;
	icon?: React.ReactNode;
	activeColor?: string;
	active?: boolean;
	color?: string;
};

function IconToggle({ activeColor, onClick, icon, active, color }: IconToggleProps) {
	return (
		<ButtonBase
			className={active ? "active" : undefined}
			onClick={onClick}
			sx={{
				transition: "border-color 0.5s, color 0.5s, box-shadow 0.25s",
				height: "2.5rem",
				width: "2.5rem",
				borderRadius: "2.5rem",
				borderStyle: "solid",
				borderWidth: "2px",
				backgroundColor: "transparent",
				color: color ?? "gray.dark",
				boxShadow: 0,
				borderColor: "transparent",
				"&.active": {
					backgroundColor: "transparent",
					color: activeColor ?? "core.blue",
					boxShadow: 4,
					borderColor: activeColor ?? "core.blue",
				},
				"&:hover": {
					boxShadow: 4,
					color: color ?? "core.blue",
					borderColor: color ?? "core.blue",
				},
				"& .MuiButtonBase-root": {
					height: "100%",
					px: 1,
					fontSize: ".85rem",
					"& svg": {
						fontSize: "1.25rem",
					},
				},
			}}
		>
			{icon}
		</ButtonBase>
	);
}

export { IconToggle };
