import { SwipeableDrawer, Stack } from "@mui/material";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { StyleContext } from "common/context/StyleContext";
import { useContext, useEffect } from "react";
import { palette, style } from "theme";
import { NavigationItem } from "../navigation/NavigationItem";

type LeftNavigationDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => any;
	offset: number;
};

const LeftNavigationDrawer = ({ open, setOpen, offset }: LeftNavigationDrawerProps) => {
	const { trigger } = useContext(StyleContext);
	useEffect(() => {
		setTimeout(trigger, 250);
	}, [open, trigger]);
	return (
		<SwipeableDrawer
			anchor={"left"}
			variant="persistent"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			onOpen={() => {
				setOpen(true);
			}}
			sx={{
				zIndex: 2,
				"& .MuiDrawer-paper": {
					boxShadow: 4,
					borderRight: "none",
				},
			}}
		>
			<Stack
				sx={{
					height: "100%",
					width: style.leftNavWidth,
					backgroundColor: palette.core.lightBackground,
					paddingTop: style.headerHeight + offset + "px",
					justifyContent: "space-between",
				}}
			>
				<Stack sx={{ width: "100%" }}>
					<NavigationItem
						label="Your Dashboard"
						icon={<HealthIcon iconSVG={health_icons_svg.home_alt} />}
						to="dashboard"
						additionalMatches={["dashboard"]}
					/>
					<NavigationItem
						label="Health Snapshot"
						icon={<HealthIcon iconSVG={health_icons_svg.cardiogram} />}
						to="healthsnapshot"
						additionalMatches={["healthsnapshot"]}
					/>
					<NavigationItem
						label="Find Care"
						icon={<HealthIcon iconSVG={health_icons_svg.magnifyingglass} />}
						to="findcare"
						additionalMatches={["findcare"]}
					/>
				</Stack>
				<Stack
					sx={{
						width: "100%",
						"& .MuiButtonBase-root": {
							borderBottom: "none",
						},
					}}
				>
					<NavigationItem label="Website Feedback" icon={<HealthIcon iconSVG={health_icons_svg.support} />} query="provide-feedback=undefined" />
				</Stack>
			</Stack>
		</SwipeableDrawer>
	);
};

export { LeftNavigationDrawer };
