import { Box, Button, Stack, Typography } from "@mui/material";
import { entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { StarRating } from "../DisplayElements/StarRating";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { ModelsSimpleHospital } from "../../../api/backend.schemas";
import { DialogManager } from "common/managers/DialogManager";
import { OptionsButton } from "../Buttons/OptionsButton";
import { renderDisplayInfo } from "./common/TilesCommon";
interface OptimizeCareSimpleHospitalTileProps {
	hospital: ModelsSimpleHospital;
}
const OptimizeCareSimpleHospitalTile = ({ hospital }: OptimizeCareSimpleHospitalTileProps) => {
	const entityType = entity_types["facility"];

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
*/
	return (
		<Stack
			data-componentname="OptimizeCareSimpleHospitalTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				overflow: "hidden",
			}}
		>
			<Stack
				sx={{
					justifyContent: "flex-start",
					alignItems: { xs: "flex-start", sm: "center" },
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Stack
					sx={{
						flex: "1",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<AvatarDisplay
						imageAlt={hospital.name}
						icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={health_icons_svg.hospital} />}
					/>
					<Stack sx={{ display: "flex", flexDirection: "column", paddingLeft: ".5rem", textAlign: "left" }}>
						<Typography
							color={palette.core.blue}
							style={{ cursor: "pointer", fontSize: "1.25rem", fontWeight: 400 }}
							onClick={() => {
								DialogManager.open("hospital-details", hospital.recordId);
							}}
						>
							{hospital.name}
						</Typography>
						<StarRating rating={hospital.hospitalRating} adjustValueForStarFit />
					</Stack>
				</Stack>

				<Box
					sx={{ width: { xs: "100%", sm: "auto" }, paddingTop: { xs: ".5rem", sm: "0" }, "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}
				>
					{typeof hospital.distanceMiles === "number" ? renderDisplayInfo({ title: "Distance", value: hospital.distanceMiles }, 0) : null}
					<OptionsButton
						content={
							<Box>
								<Button
									variant="text"
									onClick={() => {
										DialogManager.open("hospital-details", hospital.recordId);
									}}
								>
									View Profile
								</Button>
							</Box>
						}
					/>
				</Box>
			</Stack>
		</Stack>
	);
};

export { OptimizeCareSimpleHospitalTile };
