import { Stack } from "@mui/material";

const FullscreenLoader = () => {
	return (
		<Stack
			justifyContent={"center"}
			alignItems={"center"}
			sx={{
				height: "100vh",
				"& > img": { width: "12rem", height: "12rem" },
			}}
		>
			<img alt="radius care logo" src={"/images/radius-loading-indicator.svg"} />
		</Stack>
	);
};

export { FullscreenLoader };
