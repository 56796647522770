import { Avatar, Box, Button, Stack, Typography } from "@mui/material";
import { event_actions } from "common/definitions/EventDefinitions";
import { palette } from "theme";
import { OptionsButton } from "../Buttons/OptionsButton";
import { ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel, PatientDataAuthorizationItem } from "../../../api/backend.schemas";
import { removePatientDataAuthorization } from "api";
import { DataSourceID, getDatasourceDefinitionByDataSourceID } from "common/definitions/DataSourceDefinitions";
import { useMemo, useContext, useState } from "react";
import { AccountManagerContext } from "common/managers/AccountManager";
import { SimpleModal } from "../Modals/SimpleModal";
import { X_setUserSelectedPatientDataAuthorizationItem } from "common/requests/care_plan/X_setUserSelectedPatientDataAuthorizationItem";
import { useQueryClient } from "react-query";
import { StandardSwitch } from "../Inputs/StandardSwitch";

type CarePlanTileProps = {
	description: string;
	showOptions?: boolean;
	patientDataAuthorization?: ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel;
	dataSourceID?: string;
	imageSrc?: string;
	title?: string;
};

const CarePlanTile = ({ title, description, imageSrc, showOptions, patientDataAuthorization, dataSourceID }: CarePlanTileProps) => {
	const queryClient = useQueryClient();

	const datasourceDefinition = useMemo(() => {
		if (patientDataAuthorization) {
			return getDatasourceDefinitionByDataSourceID(patientDataAuthorization.dataSourceID as DataSourceID);
		} else if (dataSourceID) {
			return getDatasourceDefinitionByDataSourceID(dataSourceID as DataSourceID);
		}
		return null;
	}, [patientDataAuthorization, dataSourceID]);

	const account = useContext(AccountManagerContext);

	const [openConfirmDeactive, set_openConfirmDeactive] = useState<boolean>(false);

	const patientItems = useMemo(() => {
		if (!showOptions) {
			return null;
		}
		return patientDataAuthorization && patientDataAuthorization.patients && patientDataAuthorization.patients.length > 0 ? (
			<Box style={{ width: "100%" }}>
				{patientDataAuthorization.patients.map((x) => {
					return (
						<PatientItemDisplay
							key={patientDataAuthorization.id + "-" + x.id}
							setAsUserSelected={(toggleOff: boolean = false) => {
								X_setUserSelectedPatientDataAuthorizationItem(
									{
										patientDataAuthorizationID: patientDataAuthorization.id,
										patientDataAuthorizationItemID: x.id,
										toggleOff: toggleOff,
									},
									queryClient
								);
							}}
							patientItem={x}
							patientDataAuthorization={patientDataAuthorization}
						/>
					);
				})}
			</Box>
		) : null;
	}, [patientDataAuthorization, queryClient, showOptions]);

	return (
		<Stack
			data-componentname="CarePlanTile-Outer"
			sx={{
				flexDirection: "column",
				alignItems: "center",
				width: "100%",
				"&:not(:last-child)": {
					marginBottom: ".5rem",
				},
			}}
		>
			<Stack
				sx={{
					flexDirection: { xs: "column", sm: "row" },
					alignItems: "center",
					"&:not(:last-child)": {
						marginBottom: ".5rem",
					},
					width: "100%",
				}}
			>
				<Stack flexDirection={"row"} alignItems={"center"} sx={{ width: { xs: "100%", sm: "auto" }, flex: "1" }}>
					<Avatar
						sx={{
							borderColor: palette.gray.main,
							backgroundColor: "#ffffff",
							width: { xs: "3rem", sm: "4.5rem" },
							height: { xs: "3rem", sm: "4.5rem" },
							boxSizing: "border-box",
							p: 1,
							"& > img": { objectFit: "contain" },
						}}
						alt={datasourceDefinition?.title ?? title}
						src={datasourceDefinition?.imageSrc ?? imageSrc}
					/>
					<Box sx={{ pl: 1 }}>
						<Typography
							sx={{
								color: palette.gray.dark,
							}}
						>
							{datasourceDefinition?.title ?? title}
						</Typography>
						<Typography sx={{ fontSize: "1.25rem" }}>{description}</Typography>
					</Box>
				</Stack>
				{showOptions === true ? (
					<Box sx={{ width: { xs: "100%", sm: "auto" }, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
						<Box
							sx={{
								width: { xs: "100%", sm: "auto" },
								paddingTop: { xs: ".5rem", sm: "0" },
								"& .MuiButton-root": { width: { xs: "100%", sm: "auto" } },
							}}
						>
							<OptionsButton
								content={
									<Box>
										<Button
											variant="text"
											fullWidth
											sx={{ textAlign: "center" }}
											onClick={() => {
												event_actions.reauthorize_datasource.action(null, { id: dataSourceID });
											}}
										>
											Reauthorize
										</Button>
										{patientDataAuthorization &&
										patientDataAuthorization.email === account.accountManager.userDetails?.consumerUser?.email ? (
											<Button
												variant="text"
												fullWidth
												color="error"
												sx={{ textAlign: "center" }}
												onClick={async () => {
													set_openConfirmDeactive(true);
												}}
											>
												Remove
											</Button>
										) : null}
									</Box>
								}
							/>
						</Box>
					</Box>
				) : null}
				{patientDataAuthorization && patientDataAuthorization.email === account.accountManager.userDetails?.consumerUser?.email ? (
					<SimpleModal
						open={openConfirmDeactive}
						title="Remove Insurance Plan"
						onClose={() => {
							set_openConfirmDeactive(false);
						}}
					>
						<Typography sx={{ my: 2 }}>
							{datasourceDefinition?.title ?? title}:{description}
						</Typography>
						<Button
							color="error"
							fullWidth={true}
							variant="outlined"
							sx={{ mb: 1 }}
							onClick={async () => {
								let x = await removePatientDataAuthorization({
									patientRecordID: patientDataAuthorization.patientRecordID,
									dataSourceID: patientDataAuthorization.dataSourceID,
								});
								account.accountManager.setUser("REFRESH");
								console.debug("removed patient data authorization", x);
							}}
						>
							Confirm
						</Button>
						<Button
							fullWidth={true}
							variant="outlined"
							onClick={() => {
								set_openConfirmDeactive(false);
							}}
						>
							Cancel
						</Button>
					</SimpleModal>
				) : null}
			</Stack>
			{patientItems}
		</Stack>
	);
};

export { CarePlanTile };
/*























*/
type PatientItemDisplayProps = {
	patientItem: PatientDataAuthorizationItem;
	setAsUserSelected: (toggleOff?: boolean) => any;
	patientDataAuthorization: ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel;
};
const PatientItemDisplay = ({ patientItem, setAsUserSelected }: PatientItemDisplayProps) => {
	const patientNameText = useMemo(() => {
		return (patientItem.demographicInfo?.name ?? "<Missing Name>") + (patientItem.patientType === "Main" ? " - Primary" : "");
	}, [patientItem]);

	return (
		<Box
			key={patientItem.id}
			style={{
				flexGrow: 1,
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-between",
				margin: "8px",
				backgroundColor: palette.gray.main,
				borderRadius: "16px",
			}}
			sx={{ "& > p": { padding: "4px" } }}
		>
			<Box style={{ display: "flex", flexGrow: 1, flexDirection: "row", justifyContent: "space-between" }} sx={{ "& > p": { padding: "4px" } }}>
				{patientItem.isActivePatientItem ? (
					// <StandardSwitch
					// 	color={"blue"}
					// 	size="medium"
					// 	disabled={true}
					// 	onChange={() => {}}
					// 	checked={true}
					// 	text={patientNameText}
					// 	// textStyle={{ color: "green", fontWeight: 600 }}
					// />
					<StandardSwitch
						color={"blue"}
						size="medium"
						disabled={false}
						onChange={() => {
							setAsUserSelected(true);
						}}
						checked={true}
						text={patientNameText}
						// textStyle={{ color: "green", fontWeight: 600 }}
					/>
				) : (
					<StandardSwitch
						color={"blue"}
						size="medium"
						onChange={() => {
							setAsUserSelected(false);
						}}
						checked={false}
						text={patientNameText}
					/>
				)}
			</Box>
			{/* <Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "10em" }}>

			</Box> */}
		</Box>
	);
};
