import { useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { Entity } from "common/definitions/EntityDefinitions";
import { convertDatesToChartDataByMonth } from "common/utilities/chartDataConverters";
import { ModelsExplanationOfBenefitTreatmentAssessment } from "api";
import StandardLineChart from "common/components/Charts/StandardLineChart";
import { dateConverter } from "common/utilities/dateConverter";
import { Paginator } from "common/components/Paginator/Paginator";
import { MedicationTile } from "common/components/Tiles/MedicationTile";
import { DialogManager } from "common/managers/DialogManager";

type MedicationListPanelProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const MedicationListPanel = ({ patientEOBData }: MedicationListPanelProps) => {
	console.log("FULLDATA", patientEOBData);
	const treatments = useMemo(() => {
		if (patientEOBData?.data?.data?.medicationAssessments) {
			return patientEOBData?.data?.data?.medicationAssessments;
		}
	}, [patientEOBData?.data?.data?.medicationAssessments]);

	const treatments_ents: Entity[] = useMemo(() => {
		let ents: Entity[] = [];
		if (treatments) {
			Object.keys(treatments).forEach((k) => {
				let x = treatments[k];
				if (x) {
					let hcpcs_code = x.code ? StaticDataManager.data.HCPCS.lookups.code.get(x.code) : undefined;
					let title = (x as any)._ndc_data?.name;
					// let cpt_category = null;
					// if (hcpcs_code && hcpcs_code.cpt_category) {
					// 	cpt_category = StaticDataManager.data.HCPCS.lookups.cpt_category.get(hcpcs_code.cpt_category) ?? null;
					// }
					ents.push({
						entityTypeName: "medication",
						title: title ?? (hcpcs_code ? hcpcs_code?.description : "Unidentified Medication: " + x.code),
						// descriptor: cpt_category ? cpt_category.name : "",
						descriptor: "",
						displayInfo: [
							{ title: x.count > 1 ? "Occurences" : "Occurence", value: x.count },
							{ title: "Est. Total Payments", value: x.paymentAmountTotal, colorType: "USD", dataType: "USD" },
						],
						imageSrc: "",
						_data: x,
					});
				}
			});

			ents.sort((a: Entity, b: Entity) => {
				let al = a._data.dates?.length;
				let bl = b._data.dates?.length;
				if (!al || !bl) {
					if (!al && !bl) return 0;
					if (!al) return 1;
					return -1;
				}
				al = a._data.dates[al - 1];
				bl = b._data.dates[bl - 1];
				if (al > bl) return -1;
				if (bl > al) return 1;
				return 0;
			});
		}
		return ents;
	}, [treatments]);

	const tiles = useMemo(() => {
		return treatments_ents.map((r) => {
			return (
				<Box key={r._data.code} sx={{ paddingBottom: ".5rem" }}>
					<MedicationTile
						Entity={r}
						// detailsSection={<MedicationAssessmentDetailsSection assessment={r._data} />}
						content={
							<Box>
								<Button
									variant="text"
									onClick={() => {
										DialogManager.open("medication-details", r._data.code ?? "MISSING CODE");
									}}
								>
									View Details
								</Button>
							</Box>
						}
					/>
				</Box>
			);
		});
	}, [treatments_ents]);

	return (
		<Box style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<Paginator
					pageSize={10}
					data={tiles}
					renderItem={(tile: any, i?: number) => {
						return tile;
					}}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

const MedicationAssessmentDetailsSection = ({ assessment }: { assessment: ModelsExplanationOfBenefitTreatmentAssessment }) => {
	let chartData;
	if (assessment.dates) {
		chartData = convertDatesToChartDataByMonth(assessment.dates);
	}
	console.debug({ chartData });
	return (
		<Box>
			<Box>
				<Typography component="div" variant="subsection-title" style={{ textAlign: "left", margin: "16px 0px" }}>
					History
				</Typography>
				<Box style={{ width: "100%", minHeight: "100px" }}>
					{chartData ? (
						<StandardLineChart
							data={chartData}
							xKey={"yyyymm"}
							yKeys={[{ dataKey: "count" }]}
							responsiveHeight={100}
							keyLabels={{ count: "Occurrences" }}
							xTickLine
							xTickFormatter={(x, y) => {
								return dateConverter["YYYY-MM_to_mM/YY"](x);
							}}
							yDomain={[0, "dataMax" as CIM]}
							dotSize={3}
						/>
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

export { MedicationListPanel, MedicationAssessmentDetailsSection };
