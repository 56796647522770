import { ModelsClientLocation } from "api";
import { ErrorDefinition, Errors } from "common/definitions/ErrorDefinitions";
import { AccountManager } from "./AccountManager";

class GeolocationManagerClass {
	intializedAt?: number;
	set_position: (...args: any[]) => any = () => null;

	constructor() {
		if (!(GeolocationManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(GeolocationManagerClass as any).instance = this;
		}
		return (GeolocationManagerClass as any).instance;
	}

	getPosition = async (options?: { type?: "manual" }): Promise<GeolocationPosition | ErrorDefinition> => {
		if (this.last_position && this.last_position.timestamp > Date.now() - this.time_to_stale) {
			return this.last_position;
		}
		if (navigator.geolocation) {
			return new Promise((resolve, reject) => {
				navigator.geolocation.getCurrentPosition(
					(position: GeolocationPosition) => {
						// console.debug("geolocation success", position);
						this.last_position = position;
						this.set_position(position);
						resolve(position);
					},
					(error) => {
						// console.debug("geolocation error", error);
						if (options?.type === "manual") {
							console.log("You may need to manually enable Location Permissions or reload the page");
							reject("MANUAL_SETTINGS_REQUIRED");
						}
						reject(error);
					}
				);
			});
		} else {
			return Errors("USER_LOCATION_GENERAL_ERROR");
		}
	};

	clientLocation = (options?: { useAccountPrefs?: boolean; distanceRadiusMilesOverride?: number; forceZipcode?: string }): ModelsClientLocation => {
		let gm = GeolocationManager;
		let latitude = gm.last_position?.coords.latitude ?? null;
		let longitude = gm.last_position?.coords.longitude ?? null;
		let zipcode = gm.preferZipCode && gm.lastZipCode ? gm.lastZipCode : null;

		if (options?.useAccountPrefs !== false) {
			//Need to check AccountPreferences once implemented
			let defaultZ = AccountManager.userDetails?.consumerUser?.location?.zip;
			if (defaultZ) {
				zipcode = defaultZ;
				latitude = null;
				longitude = null;
			}
		}

		if (options?.forceZipcode && options?.forceZipcode.length === 5) {
			zipcode = options.forceZipcode;
			latitude = null;
			longitude = null;
		}

		let distanceRadiusMiles = options?.distanceRadiusMilesOverride ?? gm.distanceRadiusMiles ?? null;

		return { latitude, longitude, zipcode, distanceRadiusMiles };
	};

	time_to_stale = 15 * 60000; // 15 mins
	last_position: GeolocationPosition | null = null;
	preferZipCode?: false;
	lastZipCode?: string;
	distanceRadiusMiles?: number = 60;
}

const GeolocationManager = new GeolocationManagerClass();

export { GeolocationManager, GeolocationManagerClass };
