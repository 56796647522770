import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
	faFileInvoiceDollar,
	faCog,
	faBell,
	faUser,
	faAngleDown,
	faAngleUp,
	faAngleRight,
	faTimes,
	faCheckCircle,
	faTimesCircle,
	faSave,
	faArrowCircleRight,
	faArrowLeft,
	faArrowRight,
	faUndo,
	faNotesMedical,
	faUsers,
	faEye,
	faHeartbeat,
	faMapMarkedAlt,
	faCalendarAlt,
	faSearch,
	faChartLine,
	faHandshake,
	faStethoscope,
	faPrescriptionBottle,
	faShoppingBasket,
	faInfo,
	faBars,
	faHouseMedical,
	faHeartPulse,
	faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { faHeadSideBrain, faUsdCircle, faChartNetwork, faBellOn, faClipboardMedical, faEnvelopeCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { IconProps, Typography } from "@mui/material";
import { createTheme, lighten } from "@mui/material/styles"; //darken
import type {} from "@mui/x-data-grid/themeAugmentation";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

export const icons = {
	market: faShoppingBasket as IconProps,
	prescription: faPrescriptionBottle as IconProp,
	procedure: faStethoscope as IconProp,
	handshake: faHandshake as IconProp,
	lineChart: faChartLine as IconProp,
	moneyCircle: faUsdCircle as IconProp,
	network: faChartNetwork as IconProp,
	revenue: faFileInvoiceDollar as IconProp,
	diagnosis: faHeadSideBrain as IconProp,
	settings: faCog as IconProp,
	alerts: faBell as IconProp,
	alertsOn: faBellOn as IconProp,
	user: faUser as IconProp,
	users: faUsers as IconProp,
	angleDown: faAngleDown as IconProp,
	angleUp: faAngleUp as IconProp,
	angleRight: faAngleRight as IconProp,
	reporting: faNotesMedical as IconProp,
	close: faTimes as IconProp,
	next: faArrowCircleRight as IconProp,
	arrowLeft: faArrowLeft as IconProp,
	arrowRight: faArrowRight as IconProp,
	save: faSave as IconProp,
	success: faCheckCircle as IconProp,
	error: faTimesCircle as IconProp,
	restore: faUndo as IconProp,
	riskScore: faHeartbeat as IconProp,
	distance: faMapMarkedAlt as IconProp,
	calendar: faCalendarAlt as IconProp,
	search: faSearch as IconProp,
	info: faInfo as IconProp,
	bars: faBars as IconProp,
	houseMedical: faHouseMedical as IconProp,
	clipboardMedical: faClipboardMedical as IconProp,
	heartPulse: faHeartPulse as IconProp,
	eyeSlash: faEyeSlash as IconProp,
	eye: faEye as IconProp,
	envelopeCircleCheck: faEnvelopeCircleCheck as IconProp,
};

export const palette = {
	core: {
		orange: "#e68341",

		lavender: "#95acda",

		yellow: "#fbbe28",

		green: "#96cf5d",
		forestgreen: "#419b77",

		electricblue: "#21afc6",
		darkblue: "#02024c",
		lightblue: "#f4f4fa",
		xlightblue: "#e4e5f5",
		blue: "#000a6d",

		purple: "#78429b",

		followUps: "#78429b",
		careTasks: "#419b77",
		preventative: "#9b4b6f",
		lightBackground: "#ffffff",
	},
	primary: {
		// attention: "#fbbe28",
		// purple: "#151c44",
		// cyan: "#24b1f7",
		// lightBackground: "#ffffff",
		// lightContrastBackground: "#f9f9f9",
		// light: "#424279",
		main: "#ffffff",
		dark: "#010126",
		contrastText: "#151c44",
	},
	secondary: {
		light: "#499F68",
		main: "#499F68",
		dark: "#499F68",
		contrastText: "#ffffff",
	},
	success: {
		light: "#499F68",
		main: "#499F68",
		dark: "#499F68",
		contrastText: "#ffffff",
	},
	error: {
		light: "#d92450",
		main: "#d92450",
		dark: "#d92450",
		contrastText: "#ffffff",
	},
	warning: {
		light: "#fbbe28",
		main: "#F1863F",
		dark: "#F1863F",
		contrastText: "#ffffff",
	},
	info: {
		light: "#0E6BA8",
		main: "#0E6BA8",
		dark: "#0E6BA8",
		contrastText: "#ffffff",
	},
	green: {
		light: "#499F68",
		main: "#499F68",
		dark: "#499F68",
		contrastText: "#ffffff",
	},
	red: {
		light: "#E83E3E",
		main: "#E83E3E",
		dark: "#E83E3E",
		contrastText: "#ffffff",
	},
	orange: {
		light: "#F1863F",
		main: "#F1863F",
		dark: "#F1863F",
		contrastText: "#ffffff",
	},
	blue: {
		light: "#000a6d",
		main: "#000a6d",
		dark: "#000a6d",
		contrastText: "#ffffff",
	},
	lightBlue: {
		light: "#0E6BA8",
		main: "#0E6BA8",
		dark: "#0E6BA8",
		contrastText: "#ffffff",
	},
	purple: {
		light: "#601F91",
		main: "#151c44",
		dark: "#151c44",
		contrastText: "#ffffff",
	},
	gray: {
		lightest: "#ffffff",
		light: "#F9F9F9",
		main: "#E0E0E0",
		dark: "#848484",
		darker: "#3A3A3A",
		darkest: "#000000",
		contrastText: "#3A3A3A",
		darkText: "rgba(0, 0, 0, 0.87)",
	},
	grid: {
		altRow: "#EBEBEB",
		rowHover: "#DAECE1",
	},
};

const defaults = createTheme();

export const data_label_colors = new Map([
	["male", palette.core.careTasks],
	["female", palette.core.preventative],
]);

declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		dashed: true;
		"form-label": true;
		"form-label-dark": true;
		"tile-header": true;
		"tile-header-dark": true;
		"list-title": true;
		"tiny-title": true;
		"description-paragraph": true;
		"summary-paragraph": true;
		"content-section-title": true;
		"subsection-title": true;
		"entity-title": true;
		"entity-descriptor": true;
		"entity-detail": true;
		"missing-data": true;
		"large-value-display": true;
		"standard-gray-title": true;
		"large-gray-title": true;
		"clickable-blue": true;
		"error-text": true;
	}
}
declare module "@mui/material/Button" {
	interface ButtonPropsVariantOverrides {
		"clickable-text-button": true;
		"outline-error": true;
	}
}

declare module "@mui/material/Switch" {
	interface SwitchPropsColorOverrides {
		blue: true;
	}
}

export const colorTransforms = {
	lightenWayUp: 0.85,
	lightenUp: 0.625,
	lightenDown: 0.375,
};

export const theme = createTheme({
	palette,

	breakpoints: {
		values: {
			xs: 0,
			sm: 700,
			md: 960,
			lg: 1400,
			xl: 1800,
		},
	},

	components: {
		/*
 ██████  ██████  ███    ██ ████████  █████  ██ ███    ██ ███████ ██████  
██      ██    ██ ████   ██    ██    ██   ██ ██ ████   ██ ██      ██   ██ 
██      ██    ██ ██ ██  ██    ██    ███████ ██ ██ ██  ██ █████   ██████  
██      ██    ██ ██  ██ ██    ██    ██   ██ ██ ██  ██ ██ ██      ██   ██ 
 ██████  ██████  ██   ████    ██    ██   ██ ██ ██   ████ ███████ ██   ██ 
*/
		MuiContainer: {
			styleOverrides: {
				root: {
					// "&.MediumFlexBreak": {
					//   [defaults.breakpoints.up("md")]: {
					//     flexDirection: "row",
					//   },
					//   [defaults.breakpoints.down("md")]: {
					//     flexDirection: "column",
					//   },
					// },
				},
			},
		},
		/*
██████  ██  █████  ██       ██████   ██████  
██   ██ ██ ██   ██ ██      ██    ██ ██       
██   ██ ██ ███████ ██      ██    ██ ██   ███ 
██   ██ ██ ██   ██ ██      ██    ██ ██    ██ 
██████  ██ ██   ██ ███████  ██████   ██████ 
*/
		MuiDialog: {
			styleOverrides: {
				root: {
					"& .MuiBackdrop-root": {
						backgroundColor: "rgba(255, 255, 255, 0.85)",
					},
					"& .MuiPaper-root": {
						backgroundColor: palette.gray.light,
					},
				},
			},
		},
		/*
████████ ██    ██ ██████   ██████   ██████  ██████   █████  ██████  ██   ██ ██    ██ 
   ██     ██  ██  ██   ██ ██    ██ ██       ██   ██ ██   ██ ██   ██ ██   ██  ██  ██  
   ██      ████   ██████  ██    ██ ██   ███ ██████  ███████ ██████  ███████   ████   
   ██       ██    ██      ██    ██ ██    ██ ██   ██ ██   ██ ██      ██   ██    ██    
   ██       ██    ██       ██████   ██████  ██   ██ ██   ██ ██      ██   ██    ██  
*/
		MuiTypography: {
			variants: [
				{
					props: { variant: "dashed" },
					style: {
						textTransform: "none",
						border: `2px dashed grey`,
						textAlign: "left",
					},
				},
				{
					props: { variant: "form-label" },
					style: {
						textAlign: "left",
						color: palette.gray.dark,
						fontWeight: "600",
						padding: "2px 0px 4px",
						fontSize: "1rem",
					},
				},
				{
					props: { variant: "form-label-dark" },
					style: {
						textAlign: "left",
						color: palette.gray.darker,
						fontWeight: "700",
						padding: "2px 0px 4px",
						fontSize: "1rem",
					},
				},
				{
					props: { variant: "tile-header" },
					style: {
						textAlign: "left",
						color: palette.core.lightBackground,
						fontWeight: "600",
						padding: "2px 0px",
						fontSize: "1.5rem",
					},
				},
				{
					props: { variant: "tile-header-dark" },
					style: {
						textAlign: "left",
						color: palette.gray.darker,
						fontWeight: "600",
						padding: "2px 0px",
						fontSize: "1.5rem",
					},
				},
				{
					props: { variant: "list-title" },
					style: {
						color: palette.gray.darker,
						fontWeight: "bold",
						fontSize: "1.1rem",
						textTransform: "uppercase",
					},
				},
				{
					props: { variant: "tiny-title" },
					style: {
						color: palette.gray.dark,
						// fontWeight: "bold",
						fontSize: "1rem",
						// textTransform: "uppercase",
					},
				},
				{
					props: { variant: "description-paragraph" },
					style: {
						color: palette.gray.dark,
						fontSize: "1.25rem",
						paddingBottom: "8px",
						display: "inline-block",
					},
				},
				{
					props: { variant: "summary-paragraph" },
					style: {
						color: palette.gray.dark,
						fontSize: "1rem",
						paddingBottom: "8px",
						display: "inline-block",
					},
				},
				{
					props: { variant: "content-section-title" },
					style: {
						textAlign: "left",
						textTransform: "uppercase",
						fontSize: "1.25rem",
						paddingBottom: "8px",
						paddingTop: "4px",
						fontWeight: "bold",
						color: palette.gray.darkText,
					},
				},
				{
					props: { variant: "subsection-title" },
					style: {
						textAlign: "left",
						fontSize: "1rem",
						fontWeight: "600",
						// textDecoration: "underline",
						color: palette.gray.darker,
					},
				},
				{
					props: { variant: "entity-title" },
					style: {
						fontSize: "1.1rem",
						fontWeight: "500",
						color: palette.core.electricblue,
						lineHeight: "1.4rem",
						letterSpacing: ".02rem",
					},
				},
				{
					props: { variant: "entity-descriptor" },
					style: {
						fontSize: "1rem",
						color: palette.gray.dark,
						fontWeight: "300",
						lineHeight: "1.2rem",
					},
				},
				{
					props: { variant: "entity-detail" },
					style: {
						textAlign: "left",
						fontSize: "1rem",
						fontWeight: "600",
						color: palette.gray.dark,
					},
				},
				{
					props: { variant: "missing-data" },
					style: {
						textAlign: "left",
						fontSize: "1rem",
						fontWeight: "600",
						fontStyle: "italic",
						color: palette.gray.darker,
					},
				},
				{
					props: { variant: "large-value-display" },
					style: {
						lineHeight: "2.2rem",
						color: palette.gray.darker,
						fontSize: "2.2rem",
						fontWeight: "bold",
						textDecoration: "none",
					},
				},
				{
					props: { variant: "standard-gray-title" },
					style: {
						color: palette.gray.dark,
						fontSize: "1.1rem",
						lineHeight: "1.1rem",
						padding: "8px",
						textDecoration: "none",
						fontWeight: 600,
					},
				},
				{
					props: { variant: "large-gray-title" },
					style: {
						color: palette.gray.dark,
						fontSize: "1.8rem",
						lineHeight: "1.1rem",
						padding: "8px",
						textDecoration: "none",
						fontWeight: 600,
						textTransform: "uppercase",
					},
				},
				{
					props: { variant: "clickable-blue" },
					style: {
						color: palette.core.electricblue,
						cursor: "pointer",
						"&:hover": {
							opacity: 0.625,
						},
					},
				},
				{
					props: { variant: "error-text" },
					style: {
						color: palette.error.main,
						padding: "1rem",
						fontWeight: 600,
						fontSize: "1.25rem",
					},
				},
			],
			styleOverrides: {
				root: {
					fontSize: "1rem",
					"&.MuiLink-root": { color: palette.core.blue, ":hover": { color: palette.core.darkblue } },
				},

				h1: {
					color: palette.gray.darker,
					fontWeight: 700,
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.75rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "2rem",
					},
				},

				h2: {
					color: palette.gray.darker,
					marginBottom: "1rem",
					fontWeight: 700,
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.5rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.75rem",
					},
				},

				h3: {
					color: palette.gray.darker,
					marginBottom: "1rem",
					fontWeight: 700,
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.5rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.5rem",
					},
				},

				h4: {
					color: palette.gray.darker,
					marginBottom: "1rem",
					fontWeight: 700,
					[defaults.breakpoints.up("xs")]: {
						fontSize: "1rem",
					},
					[defaults.breakpoints.up("md")]: {
						fontSize: "1.25rem",
					},
					[defaults.breakpoints.up("xl")]: {
						fontSize: "1.25rem",
					},
				},

				h5: {
					color: palette.gray.darker,
					marginBottom: "1rem",
					fontWeight: 700,
					fontSize: "1rem",
				},

				subtitle1: {
					fontSize: "22px",
					lineHeight: "32px",
				},
			},
		},

		MuiLinearProgress: {
			styleOverrides: {
				root: {
					backgroundColor: palette.gray.light,
					height: "16px",
					borderRadius: "8px",
					"& > span.MuiLinearProgress-bar": {
						backgroundColor: palette.core.electricblue,
						borderRadius: "8px",
					},
				},
			},
		},
		/*
███████ ██      ██ ██████  ███████ ██████  
██      ██      ██ ██   ██ ██      ██   ██ 
███████ ██      ██ ██   ██ █████   ██████  
     ██ ██      ██ ██   ██ ██      ██   ██ 
███████ ███████ ██ ██████  ███████ ██   ██
*/
		MuiSlider: {
			styleOverrides: {
				root: {
					fontSize: ".75rem",
					fontWeight: 700,
					// backgroundColor: palette.gray.main,
					color: palette.core.blue,
					"& .MuiSlider-track": {
						backgroundColor: lighten(palette.core.blue, colorTransforms.lightenDown),
						border: "none",
						height: 6,
					},
					"& .MuiSlider-rail": {
						backgroundColor: palette.gray.dark,
						height: 8,
					},
				},
			},
		},
		/*
 █████  ██    ██  █████  ████████  █████  ██████  
██   ██ ██    ██ ██   ██    ██    ██   ██ ██   ██ 
███████ ██    ██ ███████    ██    ███████ ██████  
██   ██  ██  ██  ██   ██    ██    ██   ██ ██   ██ 
██   ██   ████   ██   ██    ██    ██   ██ ██   ██
    */
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: ".75rem",
					fontWeight: 700,
					backgroundColor: palette.gray.main,
					color: palette.gray.dark,
					border: "2px solid",
					borderColor: palette.gray.dark,

					"&.green": {
						color: palette.success.main,
						borderColor: palette.success.main,
					},

					"&.red": {
						color: palette.error.main,
						borderColor: palette.error.main,
					},

					"&.orange": {
						color: palette.warning.main,
						borderColor: palette.warning.main,
					},

					"&.blue": {
						color: palette.core.lightBackground,
						borderColor: palette.core.lightBackground,
					},

					"&.light-blue": {
						color: palette.info.main,
						borderColor: palette.info.main,
					},

					"&.purple": {
						color: palette.purple.main,
						borderColor: palette.purple.main,
					},
				},
			},
		},
		/*
 █████  ██    ██ ████████  ██████   ██████  ██████  ███    ███ ██████  ██      ███████ ████████ ███████ 
██   ██ ██    ██    ██    ██    ██ ██      ██    ██ ████  ████ ██   ██ ██      ██         ██    ██      
███████ ██    ██    ██    ██    ██ ██      ██    ██ ██ ████ ██ ██████  ██      █████      ██    █████   
██   ██ ██    ██    ██    ██    ██ ██      ██    ██ ██  ██  ██ ██      ██      ██         ██    ██      
██   ██  ██████     ██     ██████   ██████  ██████  ██      ██ ██      ███████ ███████    ██    ███████
*/
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-root": {
						border: "none",
						borderRadius: "16px",
					},
					"& .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline": {
						borderStyle: "solid",
						borderColor: "transparent",
					},
					"& .MuiOutlinedInput-root:hover fieldset.MuiOutlinedInput-notchedOutline": {
						borderStyle: "solid",
						borderColor: `${lighten(palette.core.blue, colorTransforms.lightenUp)} !important`, // override mui use of important
					},
					"& .MuiOutlinedInput-root:active fieldset.MuiOutlinedInput-notchedOutline": {
						borderStyle: "solid",
						borderColor: `${palette.core.blue} !important`, // override mui use of important
					},
					"& .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
						borderStyle: "solid",
						borderColor: `${palette.core.blue} !important`, // override mui use of important
					},
					"& .MuiOutlinedInput-root input": {
						color: palette.core.blue,
						fontWeight: "bold",
						padding: "4px 8px",
					},
					"& .MuiChip-root": {
						backgroundColor: palette.core.blue,
						color: palette.gray.lightest,
						fontSize: "1em",
					},
				},
				popper: {
					border: "2px solid #ffffff",
					borderRadius: "1rem",
					boxShadow: defaults.shadows[4],
					background: palette.gray.main,
					"& > div": {
						background: palette.gray.main,
						boxShadow: defaults.shadows[0],
						borderRadius: "1rem",
					},
					"& ul": {
						background: palette.gray.main,
					},
				},
			},
		},

		/*
██████  ██    ██ ████████ ████████  ██████  ███    ██ 
██   ██ ██    ██    ██       ██    ██    ██ ████   ██ 
██████  ██    ██    ██       ██    ██    ██ ██ ██  ██ 
██   ██ ██    ██    ██       ██    ██    ██ ██  ██ ██ 
██████   ██████     ██       ██     ██████  ██   ████
*/
		MuiButton: {
			defaultProps: {
				variant: "contained",
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					fontSize: "1rem",
					[defaults.breakpoints.down("sm")]: {
						fontSize: "1rem",
					},
					border: "2px solid",
					borderRadius: "2rem",
					boxShadow: defaults.shadows[0],
					lineHeight: "normal",
					padding: ".5rem 1.5rem",
					boxSizing: "border-box",
					minWidth: "10rem",
					"&.Mui-disabled": {
						color: palette.gray.dark,
						borderColor: palette.gray.main,
						backgroundColor: palette.gray.main,
					},
				},

				sizeLarge: {
					fontSize: "1.5rem",
					padding: "1rem",
				},

				contained: {
					borderColor: "transparent",
					"&:hover": {
						borderColor: "transparent",
					},
				},

				containedPrimary: {
					borderColor: palette.core.blue,
					color: "#ffffff",
					backgroundColor: palette.core.blue,

					"&:hover": {
						borderColor: palette.core.darkblue,
						backgroundColor: palette.core.darkblue,
					},
				},

				containedSecondary: {
					borderColor: palette.gray.dark,
					color: "#ffffff",
					backgroundColor: palette.gray.dark,

					"&:hover": {
						borderColor: palette.core.lightBackground,
						color: "#ffffff",
						backgroundColor: palette.core.lightBackground,
					},
				},

				outlined: {
					backgroundColor: "#ffffff",

					"&:hover": {
						boxShadow: defaults.shadows[4],
						border: "2px solid",
						backgroundColor: "#ffffff",
					},

					"&.MuiButton-outlinedPrimary": {
						borderColor: palette.core.blue,
						color: palette.core.blue,
					},
				},

				text: {
					display: "block",
					borderColor: "transparent",
				},

				textPrimary: {
					color: palette.core.blue,
					"&:hover": {
						color: palette.core.darkblue,
					},
				},
			},
			variants: [
				{
					props: { variant: "clickable-text-button" },
					style: {
						textAlign: "left",
						fontSize: "1rem",
						fontWeight: "400",
						padding: ".25rem .5rem",
						minWidth: "4rem",
						display: "inline",
						lineHeight: "1.2rem",
						marginBottom: ".25rem",
						color: palette.core.blue,
						border: "none",
					},
				},
				{
					props: { variant: "outline-error" },
					style: {
						backgroundColor: "#ffffff",
						borderColor: palette.error.main,
						color: palette.error.main,
						"&:hover": {
							boxShadow: defaults.shadows[4],
							border: "2px solid",
							backgroundColor: "#ffffff",
						},
					},
				},
			],
		},
		MuiButtonGroup: {
			defaultProps: {
				variant: "contained",
			},
			styleOverrides: {
				contained: {},
			},
		},

		/*
██      ██ ███    ██ ██   ██ 
██      ██ ████   ██ ██  ██  
██      ██ ██ ██  ██ █████   
██      ██ ██  ██ ██ ██  ██  
███████ ██ ██   ████ ██   ██
*/
		MuiLink: {
			styleOverrides: {
				root: {
					color: palette.secondary.main,
					textDecoration: "none",
					"&:hover": {
						color: palette.core.lightBackground,
						textDecoration: "none",
					},
				},
			},
		},
		/*
██████   █████   ██████  ██ ███    ██  █████  ████████ ██  ██████  ███    ██ 
██   ██ ██   ██ ██       ██ ████   ██ ██   ██    ██    ██ ██    ██ ████   ██ 
██████  ███████ ██   ███ ██ ██ ██  ██ ███████    ██    ██ ██    ██ ██ ██  ██ 
██      ██   ██ ██    ██ ██ ██  ██ ██ ██   ██    ██    ██ ██    ██ ██  ██ ██ 
██      ██   ██  ██████  ██ ██   ████ ██   ██    ██    ██  ██████  ██   ████
*/
		MuiPagination: {
			styleOverrides: {
				root: {
					color: palette.secondary.main,
					"& .MuiPaginationItem-root": {
						borderWidth: "2px",
						borderStyle: "solid",
						borderColor: "transparent",
						transition: "border-color .5s, color .5s",
					},
					"& .MuiPaginationItem-root.Mui-selected": {
						backgroundColor: "transparent",
						fontWeight: 500,
						borderColor: palette.core.blue,
						color: palette.core.blue,
					},
				},
			},
		},
		/*
██      ██ ███████ ████████ 
██      ██ ██         ██    
██      ██ ███████    ██    
██      ██      ██    ██    
███████ ██ ███████    ██ 
*/
		MuiList: {
			styleOverrides: {
				root: {
					"& .Mui-selected": {
						backgroundColor: "#ffffff !important",
					},
					"& .Mui-selected:hover": {
						backgroundColor: `${palette.gray.light} !important`,
					},
					"&.variant_circle": {
						color: palette.core.electricblue,
						border: `solid ${palette.core.electricblue} 2px`,
						"& > li > .MuiListItemIcon-root": {
							border: "solid black px",
							backgroundColor: "black",
							borderRadius: "8px",
							minWidth: "16px",
							width: "16px",
							height: "16px",
						},
					},
				},
			},
		},

		/*
 ██████  ██    ██ ████████ ██      ██ ███    ██ ███████ ██████      ██ ███    ██ ██████  ██    ██ ████████ 
██    ██ ██    ██    ██    ██      ██ ████   ██ ██      ██   ██     ██ ████   ██ ██   ██ ██    ██    ██    
██    ██ ██    ██    ██    ██      ██ ██ ██  ██ █████   ██   ██     ██ ██ ██  ██ ██████  ██    ██    ██    
██    ██ ██    ██    ██    ██      ██ ██  ██ ██ ██      ██   ██     ██ ██  ██ ██ ██      ██    ██    ██    
 ██████   ██████     ██    ███████ ██ ██   ████ ███████ ██████      ██ ██   ████ ██       ██████     ██ 
    */
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: palette.gray.main,
					borderRadius: "4rem",
					boxShadow: defaults.shadows[2],
					borderColor: palette.gray.light,

					"& fieldset": {
						top: "0",
					},

					"& fieldset legend": {
						display: "none",
					},

					"&:hover fieldset": {
						borderColor: `${palette.core.blue} !important`,
					},

					"&.Mui-focused fieldset": {
						borderColor: `${palette.core.blue} !important`,
					},
				},
				notchedOutline: {
					borderColor: palette.gray.light,
					borderWidth: "2px !important",
					transition: "border-color .5s ease",
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: palette.gray.darker,
					fontWeight: "800",
					"&.Mui-focused": {
						color: palette.gray.darker,
					},
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					backgroundColor: "none",
					color: palette.gray.dark,
					fontWeight: "500",
					borderTopRightRadius: "4px",
					borderTopLeftRadius: "4px",
					borderColor: palette.gray.light,
					"& > input": {
						padding: "8px 8px",
					},
					"&:after": {
						borderColor: palette.gray.darker,
					},
					"&:before": {
						borderWidth: "2px",
						borderColor: palette.gray.darker,
					},
				},
			},
		},

		/*
███████ ███████ ██      ███████  ██████ ████████ 
██      ██      ██      ██      ██         ██    
███████ █████   ██      █████   ██         ██    
     ██ ██      ██      ██      ██         ██    
███████ ███████ ███████ ███████  ██████    ██ 
*/
		MuiSelect: {
			styleOverrides: {
				standard: {
					textAlign: "left",
					whiteSpace: "normal",
				},
			},
		},
		/*
 ██████ ██   ██ ███████  ██████ ██   ██ ██████   ██████  ██   ██ 
██      ██   ██ ██      ██      ██  ██  ██   ██ ██    ██  ██ ██  
██      ███████ █████   ██      █████   ██████  ██    ██   ███   
██      ██   ██ ██      ██      ██  ██  ██   ██ ██    ██  ██ ██  
 ██████ ██   ██ ███████  ██████ ██   ██ ██████   ██████  ██   ██ 
                                                                
*/
		MuiCheckbox: {
			styleOverrides: {
				root: {
					// padding: 4,
					// "&.MuiCheckbox-root.SolidSquare": {
					//   color: palette.gray.main,
					//   backgroundColor: palette.gray.main,
					//   borderRadius: 8,
					//   width: "2rem",
					//   height: "2rem",
					//   transition: "background-color .5s, border-color 0.25s",
					//   borderColor: palette.gray.main,
					//   borderWidth: 2,
					//   borderStyle: "solid",
					//   "&:not(.Mui-checked):hover": {
					//     borderColor: lighten(palette.core.electricblue, colorTransforms.lightenUp),
					//   },
					//   "&.Mui-checked": {
					//     borderColor: palette.core.electricblue,
					//     backgroundColor: palette.core.electricblue,
					//     color: palette.core.electricblue,
					//     width: "2rem",
					//     height: "2rem",
					//     opacity: 1,
					//     "&:hover": {
					//       backgroundColor: lighten(palette.core.electricblue, colorTransforms.lightenDown),
					//     },
					//   },
					//   "& svg": {
					//     display: "none",
					//   },
					// },
				},
			},
		},

		MuiRadio: {
			styleOverrides: {
				root: {
					padding: ".175rem",
					"& input": {
						// opacity: 1,
					},
					"& svg": {
						fill: palette.core.blue,
						width: "1em",
						height: "1em",
						marginRight: "4px",
					},
				},
				// colorSecondary: {
				// 	"&$checked": {
				// 		color: "green",
				// 	},
				// },
			},
		},

		/*
██████   █████  ██████  ███████ ██████  
██   ██ ██   ██ ██   ██ ██      ██   ██ 
██████  ███████ ██████  █████   ██████  
██      ██   ██ ██      ██      ██   ██ 
██      ██   ██ ██      ███████ ██   ██ 
*/
		MuiPaper: {
			styleOverrides: {
				root: {
					"& .MuiGridPanelWrapper-root .MuiDataGridPanelHeader-root": {
						padding: defaults.spacing(2),
					},
					"& .MuiGridPanelWrapper-root .MuiGridPanelContent-root": {
						padding: defaults.spacing(1),
					},
					"& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root": {
						paddingTop: defaults.spacing(2),
						paddingBottom: defaults.spacing(2),
						paddingRight: defaults.spacing(1),
						paddingLeft: defaults.spacing(1),
					},

					"& .MuiGridPanelWrapper-root .MuiGridPanelFooter-root .MuiButton-root": {
						flex: 1,
						marginLeft: defaults.spacing(1),
						marginRight: defaults.spacing(1),
					},

					"& .MuiDataGrid-panelWrapper .MuiDataGrid-panelFooter .MuiButton-root": {
						backgroundColor: palette.gray.dark,
						height: "2rem",
						fontSize: "0.875rem",
						margin: defaults.spacing(1),
						padding: defaults.spacing(2),
					},
					"& .MuiDayCalendar-monthContainer .Mui-selected": {
						color: "white",
						backgroundColor: palette.core.blue,
					},
					"& .MuiInputBase-root.MuiInputBase-adornedEnd": {
						backgroundColor: "white",
						borderRadius: "0rem",
						boxShadow: "none",
					},
				},
				rounded: {
					borderRadius: "1.5rem",
				},
			},
		},

		/*
 ██████  █████  ██████  ██████  
██      ██   ██ ██   ██ ██   ██ 
██      ███████ ██████  ██   ██ 
██      ██   ██ ██   ██ ██   ██ 
 ██████ ██   ██ ██   ██ ██████
*/
		/*! card */
		MuiCard: {
			defaultProps: {
				elevation: 4,
			},
			styleOverrides: {
				root: {
					backgroundColor: palette.core.lightBackground,
					flexGrow: 1,
					borderRadius: "16px",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "12px",
				},
			},
		},
		MuiCardHeader: {
			styleOverrides: {
				root: {
					"& .MuiCardHeader-title": {
						[defaults.breakpoints.down("md")]: {
							fontSize: "1rem",
						},
						[defaults.breakpoints.up("md")]: {
							fontSize: "1.25rem",
						},
						fontWeight: 700,
						textTransform: "Uppercase",
					},
				},

				action: {
					margin: defaults.spacing(0),
				},
			},
		},

		MuiCardContent: {
			styleOverrides: {
				root: {
					"&:last-child": { paddingBottom: "1rem" },
					width: "100%",
				},
			},
		},

		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: defaults.spacing(2),
				},
			},
		},

		/*
██████   ██████  ██████       ██████  ██    ██ ███████ ██████  
██   ██ ██    ██ ██   ██     ██    ██ ██    ██ ██      ██   ██ 
██████  ██    ██ ██████      ██    ██ ██    ██ █████   ██████  
██      ██    ██ ██          ██    ██  ██  ██  ██      ██   ██ 
██       ██████  ██           ██████    ████   ███████ ██   ██
*/
		MuiPopover: {
			styleOverrides: {
				paper: {
					background: palette.gray.main,
					border: "2px solid #ffffff",
					borderRadius: "1rem",
					boxShadow: defaults.shadows[4],
				},
			},
		},

		/*
██████   █████  ████████  █████       ██████  ██████  ██ ██████  
██   ██ ██   ██    ██    ██   ██     ██       ██   ██ ██ ██   ██ 
██   ██ ███████    ██    ███████     ██   ███ ██████  ██ ██   ██ 
██   ██ ██   ██    ██    ██   ██     ██    ██ ██   ██ ██ ██   ██ 
██████  ██   ██    ██    ██   ██      ██████  ██   ██ ██ ██████
*/
		MuiDataGrid: {
			styleOverrides: {
				root: {
					border: "none",

					"& .MuiDataGrid-toolbarContainer .MuiOutlinedInput-root": {
						height: "2rem",
						fontSize: "0.875rem",
					},

					"& .MuiDataGrid-row:hover": {
						backgroundColor: `${palette.grid.rowHover} !important`,
					},

					"& .MuiDataGrid-cell": {
						borderBottom: `none`,
					},

					"& .MuiDataGrid-columnHeaders": {
						top: "-2px",
						borderBottom: `2px solid ${palette.gray.dark}`,
					},

					"& .MuiDataGrid-columnHeaderTitleContainer": {
						padding: 0,
					},

					"& .MuiDataGrid-columnHeaderTitle": {
						fontWeight: 700,
						textTransform: "uppercase",
					},

					"& .MuiDataGrid-columnSeparator": {
						display: "none",
					},

					"& .MuiDataGrid-columnsContainer": {
						borderBottom: `1px solid ${palette.gray.dark}`,
						boxSizing: "border-box",
					},

					"& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
						outline: `1px solid ${palette.secondary.main}`,
					},

					"& .MuiDataGrid-row:nth-of-type(odd)": {
						backgroundColor: palette.grid.altRow,
					},
				},
			},
		},

		MuiGrid: {
			styleOverrides: {
				root: {},
			},
		},

		/*
████████  █████  ██████  ███████ 
   ██    ██   ██ ██   ██ ██      
   ██    ███████ ██████  ███████ 
   ██    ██   ██ ██   ██      ██ 
   ██    ██   ██ ██████  ███████
*/
		MuiTabs: {
			styleOverrides: {
				root: {
					boxShadow: defaults.shadows[3],
					borderRadius: "3rem",
					minHeight: "3rem",

					"& .MuiTab-root": {
						color: palette.gray.dark,
						borderRadius: "3rem",
						zIndex: "1",
						transition: "color .5s ease",
					},

					"& .MuiTab-root.Mui-selected": {
						color: "#fff",
					},
					"& .MuiTabs-indicator": {
						zIndex: "0",
						borderRadius: "3rem",
						backgroundColor: palette.core.lightBackground,
						height: "100%",
					},
				},
			},
		},
	},
});

export const style = {
	layoutBorder: "2px #ececec solid",
	layoutBorder2: "2px #eeeeee solid",
	layoutBorderRadius: "8px",
	headerHeight: 80, //height of header and used for offsets of drawers
	leftNavWidth: 120,
	rightAccountWidth: "22rem",
	minWidth: 300,
	primaryGradientVertical: "linear-gradient(180deg, rgba(228,229,245,1) 0%, rgba(255,255,255,0) 100%)",
	primaryGradientVerticalAlt: "linear-gradient(0deg, rgba(228,229,245,1) 0%, rgba(255,255,255,0) 100%)",
	primaryGradientHorizontal: "linear-gradient(90deg, rgba(0,10,109,1) 0%, rgba(224,159,13,1) 100%)",
	primaryGradientHorizontalAlt: "linear-gradient(90deg, rgba(0,10,109,1) 100%, rgba(224,159,13,1) 0%)",
};

const TypographyDemo = () => {
	let t = [
		"dashed",
		"form-label",
		"form-label-dark",
		"tile-header",
		"tile-header-dark",
		"list-title",
		"tiny-title",
		"description-paragraph",
		"content-section-title",
		"subsection-title",
		"entity-title",
		"entity-descriptor",
		"entity-detail",
		"missing-data",
		"large-value-display",
		"standard-gray-title",
	];
	return (
		<div>
			{t.map((s) => {
				return (
					<div>
						<Typography variant={s as CIM}>{s}</Typography>
					</div>
				);
			})}
		</div>
	);
};
export { TypographyDemo };
