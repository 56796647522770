import { Box, Stack } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";
import { VisitTile } from "common/components/Tiles/VisitTile";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { useMemo, useState } from "react";
import { ModelsExplanationOfBenefit } from "../../../api/backend.schemas";
import { HealthSnapshotVisitsFilter } from "./HealthSnapshotVisitsFilter";

type HealthSnapshotVisitsPanelProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotVisitsPanel = ({ patientEOBData }: HealthSnapshotVisitsPanelProps) => {
	const eob_visits = useMemo(() => {
		let v: ModelsExplanationOfBenefit[] = [];
		let visit_RecordIDs = null;
		if (patientEOBData?.data?.data?.EOB_RecordID_Map && patientEOBData?.data?.data?.visitRecordIDs) {
			visit_RecordIDs = patientEOBData?.data?.data?.visitRecordIDs;
			visit_RecordIDs.forEach((id) => {
				let x = (patientEOBData.data.data as CIM).EOB_RecordID_Map.get(id);
				if (x) {
					v.push(x);
				}
			});

			v.sort((a: ModelsExplanationOfBenefit, b: ModelsExplanationOfBenefit) => {
				let al = a.date;
				let bl = b.date;
				if (!al || !bl) {
					if (!al && !bl) return 0;
					if (!al) return 1;
					return -1;
				}
				if (al > bl) return -1;
				if (bl > al) return 1;
				return 0;
			});
		}
		return v;
	}, [patientEOBData.data?.data]);

	const [filtered_visits, set_filtered_visits] = useState<Array<ModelsExplanationOfBenefit>>(eob_visits);

	const visitTiles = useMemo(() => {
		if (filtered_visits) {
			return filtered_visits.map((eob: ModelsExplanationOfBenefit, i) => {
				return (
					<Box key={i} sx={{ paddingBottom: ".5rem" }}>
						<VisitTile visit_eob={eob} key={eob.recordId} />
					</Box>
				);
			});
		}
		return [];
	}, [filtered_visits]);
	return (
		<Box>
			<Stack sx={{ flexDirection: "row", justifyContent: "flex-end", paddingBottom: "1rem" }}>
				<HealthSnapshotVisitsFilter set_filtered_visits={set_filtered_visits} eob_visits={eob_visits} />
			</Stack>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<LoadMorePaginator
					pageSize={5}
					data={visitTiles}
					renderItem={(tile: any, i?: number) => {
						return tile;
					}}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

export { HealthSnapshotVisitsPanel };
