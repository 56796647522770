import { Box, Button, Card, CardActions, CardContent, Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { FormErrorAlert, withFormStatus } from "common/forms";
import FormContainer from "../../../forms/FormContainer";
import { ConsumerResendVerificationCodeResponseModel, ConsumerResendVerificationCodeResult, resendVerificationCode } from "api";
import { useState } from "react";
import useQueryString from "common/useQueryString";

interface ForgotPasswordFormProps {
	username?: string;
	onForgotPassword(username: string): void;
}

function ForgotPasswordForm({ username, onForgotPassword }: ForgotPasswordFormProps) {
	const { query } = useQueryString();

	const email = username ?? query.get("username") ?? "";

	const { register, handleSubmit } = useForm<{
		username: string;
	}>({ defaultValues: { username: email } });

	const [emailVerified, set_emailVerified] = useState<boolean | null>(null);
	const [unverifiedEmail, set_unverifiedEmail] = useState<string | null>(null);

	const checkEmailVerificationStatus = async (email: string) => {
		let statusCheckResponse = await resendVerificationCode({ email, checkStatus: true });
		console.debug("statusCheckResponse", statusCheckResponse);
		console.debug((statusCheckResponse as any).response);
		if (statusCheckResponse.hasOwnProperty("response")) {
			let response: undefined | ConsumerResendVerificationCodeResponseModel = (statusCheckResponse as ConsumerResendVerificationCodeResult).response;
			if (response && response.success) {
				if ((response.data as any).hasOwnProperty("VerificationCodeStatus")) {
					// set_verifiedStatusCheck((response.data as any).VerificationCodeStatus);
					// alert((response.data as any).VerificationCodeStatus);
					let status = (response.data as any).VerificationCodeStatus;
					if (status === "NotVerified") {
						set_emailVerified(false);
						set_unverifiedEmail(email);
					} else if (status === "Verified") {
						set_emailVerified(true);
					}
					return status;
				}
			}
		}
	};
	const onSubmit = handleSubmit(async (form) => {
		let status = await checkEmailVerificationStatus(form.username);
		if (status === "NotVerified") {
			return;
		}
		return onForgotPassword(form.username);
	});

	return (
		<FormContainer
			bottomContent={
				<Box style={{ textAlign: "center" }}>
					<Button sx={{ textAlign: "center" }} component={Link} to="/signin" variant="text">
						Cancel
					</Button>
				</Box>
			}
		>
			<Card component="form" onSubmit={onSubmit} elevation={4}>
				<CardContent>
					<Typography variant="h1" component="h1">
						Forgot Password
					</Typography>
					<Typography sx={{ pb: 4 }}>Please enter your email below.</Typography>
					<Stack spacing={2}>
						<TextField label="Email" {...register("username", { required: true })} variant="standard" />
					</Stack>
					{unverifiedEmail ? (
						<Box style={{ padding: "8px" }}>
							<Typography variant="subtitle1">{unverifiedEmail} is not verified.</Typography>
							<Typography> You must verify your email in order to reset your password.</Typography>
							<Typography>
								You can verify your email <Link to={`/code-verification/?email=${encodeURIComponent(unverifiedEmail)}`}>here</Link>.
							</Typography>
						</Box>
					) : null}
				</CardContent>
				<FormErrorAlert />
				<CardActions>
					<Stack sx={{ flexDirection: "column" }}>
						<Button type="submit" disabled={emailVerified === false}>
							Submit
						</Button>
					</Stack>
				</CardActions>
			</Card>
		</FormContainer>
	);
}

export default withFormStatus(ForgotPasswordForm);
