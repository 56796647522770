import { Stack } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { StyleContext } from "common/context/StyleContext";

type AdaptiveMenuProps = {
	id: string;
};

const AdaptiveMenu = ({ id, children }: React.PropsWithChildren<AdaptiveMenuProps>) => {
	let styleContext = useContext(StyleContext);
	let [displayVertical, set_displayVertical] = useState<boolean>(false);
	let [horizontalWidth, set_horizontalWidth] = useState<number>(0);
	useEffect(() => {
		let menuWidth = document.getElementById(id)?.clientWidth;
		if (menuWidth && !displayVertical) {
			if (horizontalWidth !== menuWidth) {
				set_horizontalWidth(menuWidth);
			}
		}
		if (horizontalWidth > styleContext.screenWidth) {
			if (!displayVertical) {
				set_displayVertical(true);
			}
		} else if (displayVertical) {
			set_displayVertical(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [styleContext.screenWidth, horizontalWidth, id]);
	return (
		<Stack id={id} flexDirection={displayVertical ? "column" : "row"} justifyContent="center">
			{children}
		</Stack>
	);
};

export { AdaptiveMenu };
