import { Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { EventDescription } from "common/definitions/EventDefinitions";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { ReactNode } from "react";
import { HealthSnapshotConditionsKeyFindings } from "./HealthSnapshotConditionsKeyFindings";
import { HealthSnapshotConditionsPanel } from "./HealthSnapshotConditionsPanel";

type HealthSnapshotConditionsProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
	keyFindings: EventDescription[];
	healthPlanReauthTag: ReactNode;
};

const HealthSnapshotConditions = ({ healthPlanReauthTag, patientEOBData, keyFindings }: HealthSnapshotConditionsProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<ContentSection title="Conditions" titleBarContent={healthPlanReauthTag}>
					<HealthSnapshotConditionsPanel patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
			<Grid item xs={12} md={4}>
				<ContentSection title="Key Findings">
					<HealthSnapshotConditionsKeyFindings keyFindings={keyFindings} patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
		</Grid>
	);
};

export { HealthSnapshotConditions };
