import { Avatar, Box } from "@mui/material";
import { EntityTypeNames } from "common/definitions/EntityDefinitions";
import { EventTypeNames, event_type_definitions } from "common/definitions/EventDefinitions";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { palette } from "theme";
import { HealthIcon } from "./HealthIcon";

type AvatarDisplayProps = {
	objectFit?: "contain" | "cover";
	typeName?: EntityTypeNames | EventTypeNames;
	imageAlt?: string;
	imageSrc?: string;
	circumference?: number;
	color?: string;
	icon?: ReactNode;
	disableBlurImage?: boolean;
	inNetwork?: boolean;
};
const AvatarDisplay = ({ objectFit, typeName, imageAlt, imageSrc, circumference, color, icon, disableBlurImage, inNetwork }: AvatarDisplayProps) => {
	const [useBlurImage, set_useBlurImage] = useState<boolean>(!!imageSrc);
	circumference = circumference ?? 72;
	if (!icon) {
		if (typeName) {
			icon = <HealthIcon color={color ?? event_type_definitions[typeName].color} iconSVG={event_type_definitions[typeName].iconSVG} />;
		}
	}

	useEffect(() => {
		if (disableBlurImage) {
			return;
		}
		let i: CIM = new Image();
		i.src = imageSrc;
		i.addEventListener("load", function () {
			if (i.naturalWidth === i.naturalHeight) {
				set_useBlurImage(false); // no reason for blur Image if square
			} else {
				set_useBlurImage(true);
			}
		});
	}, [imageSrc, disableBlurImage]);

	const applyBlurImage = useMemo(() => {
		if (imageSrc && useBlurImage && !disableBlurImage) {
			return true;
		}
		return false;
	}, [imageSrc, useBlurImage, disableBlurImage]);

	// Render stretched version of image with a blur effect to soften and fill out sharp cut-off edges of undersized image.
	// Can optionally add logic to read image dimension and only apply where applicable.
	return (
		<Box
			data-id="AvatarDisplay"
			sx={{
				"& .MuiAvatar-root": {
					border: inNetwork ? `2px solid ${palette.core.forestgreen}` : `2px solid ${palette.gray.main}`,
				},
				width: { xs: "3rem", sm: circumference },
				height: { xs: "3rem", sm: circumference },
				minWidth: { xs: "3rem", sm: circumference },
				borderRadius: "50%",
				overflow: "hidden",
			}}
		>
			{applyBlurImage ? (
				<Box style={{ height: 0 }}>
					<Box
						sx={{
							backgroundColor: "transparent",
							width: { xs: "3rem", sm: circumference },
							minWidth: { xs: "3rem", sm: circumference },
							height: { xs: "3rem", sm: circumference },
							borderRadius: "50%",
							boxSizing: "border-box",
							filter: "blur(2px)",
							backgroundImage: `url(${imageSrc})`,
							backgroundPosition: "center",
							backgroundSize: "cover",
							overflow: "hidden",
						}}
					/>
				</Box>
			) : null}
			<Avatar
				sx={{
					backgroundColor: applyBlurImage ? "transparent" : "#ffffff",
					width: { xs: "3rem", sm: circumference },
					height: { xs: "3rem", sm: circumference },
					boxSizing: "border-box",
					"& > img": { objectFit: objectFit ?? "contain" },
					"& .HealthIcon": { width: { xs: "2rem !important", sm: "3rem !important" }, height: { xs: "2rem !important", sm: "3rem !important" } },
				}}
				alt={imageAlt}
				src={imageSrc}
			>
				{icon}
			</Avatar>
		</Box>
	);
};

export { AvatarDisplay };
