const mapper = {
  mapAdd: <T1>(map: Map<any, Array<T1>>, item_prop: string, items: Array<T1>, config?: { allowEmptyIndex?: boolean; singleItemNotArray?: boolean }) => {
    items.forEach((item: any) => {
      if (map.has(item[item_prop])) {
        if (config?.singleItemNotArray) {
          console.warn("mapper( ...,{singleItemNotArray}) encountered duplicate key");
          return;
        }
        (map.get(item[item_prop]) as Array<T1>).push(item);
      } else {
        if (item[item_prop] || config?.allowEmptyIndex) {
          if (config?.singleItemNotArray) {
            map.set(item[item_prop], item);
          } else {
            map.set(item[item_prop], [item]);
          }
        }
      }
    });
  },

  uniqueValues: <T1>(item_prop: string, items: Array<T1>, config?: { allowEmptyIndex: boolean }): Array<string | number | boolean> => {
    let s = new Set();
    items.forEach((item: any) => {
      if (!s.has(item[item_prop])) {
        if (item[item_prop] || config?.allowEmptyIndex) {
          s.add(item[item_prop]);
        }
      }
    });

    return Array.from(s).sort() as Array<string | number | boolean>;
  },
};

export { mapper };
