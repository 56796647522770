import { AppBar, Box, Button, Container, Typography } from "@mui/material";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { AdaptiveMenu } from "common/components/Menus/AdaptiveMenu";
import { NavigationItemHorizontal } from "main/navigation/NavigationItemHorizontal";
import { useContext, useEffect, useMemo, useState } from "react";
import { DiagnosisDetailOverview } from "./pages/overview/DiagnosisDetailOverview";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { palette, style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { DiagnosisDetailsTab } from "details/diagnosisDetails/pages/details/DiagnosisDetailsTab";
import { GeolocationContext } from "common/context/GeolocationContext";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { useQ_conditionDetail } from "./requests/useQ_conditionDetail";
import { DiagnosisDetailOption } from "common/context/MainContext";
import { useQueryClient } from "react-query";

function DiagnosisDetails({ diagnosisID, diagnosisDetailOption }: { diagnosisID: string; diagnosisDetailOption?: DiagnosisDetailOption | null }) {
	const [searchRadius, set_searchRadius] = useState<number>(25);

	const geo = useContext(GeolocationContext);
	useEffect(() => {
		GeolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
	}, [geo.position]);

	const clientLocation = useMemo(() => {
		GeolocationManager.getPosition();
		return GeolocationManager.clientLocation({ useAccountPrefs: true, distanceRadiusMilesOverride: searchRadius });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geo.geolocationManager.last_position, geo.geolocationManager.lastZipCode, searchRadius]);

	var queryClient = useQueryClient();

	const [invalidatedUpdateAt, set_invalidatedUpdateAt] = useState(0);

	const conditionDetailResult = useQ_conditionDetail(clientLocation, { icd10: diagnosisID });
	const medlineErrorRefreshButton = useMemo(() => {
		return (
			<Box style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
				<Typography variant="error-text">An error occurred in the code lookup process</Typography>
				<Button
					variant="outline-error"
					onClick={() => {
						// queryClient.invalidateQueries({ queryKey: [`retrieveConditionDetail-${diagnosisId}`, "retrieveConditionDetail"] });
						// Very stubborn and inconsistent - only using one useQueryClientProvider in the app
						queryClient.invalidateQueries("retrieveConditionDetail");
						queryClient.invalidateQueries(`retrieveConditionDetail-${diagnosisID}`);
						window.RadiusCare.queryClient.invalidateQueries("retrieveConditionDetail");
						window.RadiusCare.queryClient.invalidateQueries(`retrieveConditionDetail-${diagnosisID}`);
						set_invalidatedUpdateAt(conditionDetailResult.dataUpdatedAt);
						// console.debug(`retrieveConditionDetail-${diagnosisID}`);
						// console.debug(diagnosisID);
					}}
				>
					Retry
				</Button>
			</Box>
		);
	}, [diagnosisID, queryClient, conditionDetailResult]);
	// const [consumerGetConditionDetailResponseModel, set_responseModel] = useState<ConsumerGetConditionDetailResponseModel | null>(null);
	const queryData = useMemo(() => {
		if (conditionDetailResult?.data?.response && conditionDetailResult.isSuccess) {
			return conditionDetailResult.data.response;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [conditionDetailResult]);

	const [activeSection, set_activeSection] = useState<"overview" | "details">("overview");

	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						minHeight: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: palette.core.lightBackground,
					}}
				>
					{queryData ? (
						!!queryData.icd10Cm.title ? (
							<Typography variant="h1">{queryData.icd10Cm.title}</Typography>
						) : (
							<Typography variant="h1" style={{ fontSize: "1.5rem" }}>
								{queryData.icd10Cm.description}
							</Typography>
						)
					) : (
						<Typography variant="missing-data">Retrieving Diagnosis Data: {diagnosisID}</Typography>
					)}
					<CloseButton
						onClick={() => {
							DialogManager.close("diagnosis-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth={"md"} sx={{ paddingY: "1rem" }}>
				<QueryStatusDisplay
					queryResult={conditionDetailResult}
					// forceRenderChildren={queryData !== null}
					showAsLoading={invalidatedUpdateAt !== 0 && conditionDetailResult.dataUpdatedAt === invalidatedUpdateAt ? true : undefined} // Fix POS useQuery not set loading value
				>
					<AdaptiveMenu id="DiagnosisDetailsMenu">
						<NavigationItemHorizontal
							onClick={() => {
								set_activeSection("overview");
							}}
							isActive={activeSection === "overview"}
							label="Overview"
							icon={<HealthIcon iconSVG={health_icons_svg.stethoscope} />}
							to="/details/diagnosis/overview"
						/>
						<NavigationItemHorizontal
							onClick={() => {
								set_activeSection("details");
							}}
							isActive={activeSection === "details"}
							label="Details"
							icon={<HealthIcon iconSVG={health_icons_svg.document} />}
							to="/details/diagnosis/details"
						/>
					</AdaptiveMenu>
					{queryData?.icd10Cm
						? [
								activeSection === "overview" ? (
									<DiagnosisDetailOverview
										key="overview"
										queryData={queryData}
										diagnosisDetailOption={diagnosisDetailOption}
										searchRadius={searchRadius}
										set_searchRadius={set_searchRadius}
										conditionDetailResult={conditionDetailResult}
										// diagnosisId={diagnosisID}
										medlineErrorRefreshButton={medlineErrorRefreshButton}
									/>
								) : null,
								activeSection === "details" ? (
									<DiagnosisDetailsTab key="details" queryData={queryData} medlineErrorRefreshButton={medlineErrorRefreshButton} />
								) : null,
						  ]
						: null}
				</QueryStatusDisplay>
			</Container>
		</Box>
	);
}

export { DiagnosisDetails };
