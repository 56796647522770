/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ModelsOperationStatusString,
  ConsumerMobileRegisterDeviceIdentifierRequest,
  ConsumerMobileSendPushNotificationRequest,
  TestNewEobNotifierRequest,
  ConsumerMobileSendMassVersionUpdateNotificationRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const registerDeviceIdentifier = (
    consumerMobileRegisterDeviceIdentifierRequest: ConsumerMobileRegisterDeviceIdentifierRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/RegisterDeviceIdentifier`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileRegisterDeviceIdentifierRequest
    },
      options);
    }
  


    export type RegisterDeviceIdentifierMutationResult = NonNullable<Awaited<ReturnType<typeof registerDeviceIdentifier>>>
    export type RegisterDeviceIdentifierMutationBody = ConsumerMobileRegisterDeviceIdentifierRequest
    export type RegisterDeviceIdentifierMutationError = unknown

    export const useRegisterDeviceIdentifier = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof registerDeviceIdentifier>>, TError,{data: ConsumerMobileRegisterDeviceIdentifierRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof registerDeviceIdentifier>>, {data: ConsumerMobileRegisterDeviceIdentifierRequest}> = (props) => {
          const {data} = props ?? {};

          return  registerDeviceIdentifier(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof registerDeviceIdentifier>>, TError, {data: ConsumerMobileRegisterDeviceIdentifierRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const sendPushNotificationIos = (
    consumerMobileSendPushNotificationRequest: ConsumerMobileSendPushNotificationRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/SendPushNotificationIos`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileSendPushNotificationRequest
    },
      options);
    }
  


    export type SendPushNotificationIosMutationResult = NonNullable<Awaited<ReturnType<typeof sendPushNotificationIos>>>
    export type SendPushNotificationIosMutationBody = ConsumerMobileSendPushNotificationRequest
    export type SendPushNotificationIosMutationError = unknown

    export const useSendPushNotificationIos = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendPushNotificationIos>>, TError,{data: ConsumerMobileSendPushNotificationRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendPushNotificationIos>>, {data: ConsumerMobileSendPushNotificationRequest}> = (props) => {
          const {data} = props ?? {};

          return  sendPushNotificationIos(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendPushNotificationIos>>, TError, {data: ConsumerMobileSendPushNotificationRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const sendPushNotificationAndroid = (
    consumerMobileSendPushNotificationRequest: ConsumerMobileSendPushNotificationRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/SendPushNotificationAndroid`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileSendPushNotificationRequest
    },
      options);
    }
  


    export type SendPushNotificationAndroidMutationResult = NonNullable<Awaited<ReturnType<typeof sendPushNotificationAndroid>>>
    export type SendPushNotificationAndroidMutationBody = ConsumerMobileSendPushNotificationRequest
    export type SendPushNotificationAndroidMutationError = unknown

    export const useSendPushNotificationAndroid = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendPushNotificationAndroid>>, TError,{data: ConsumerMobileSendPushNotificationRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendPushNotificationAndroid>>, {data: ConsumerMobileSendPushNotificationRequest}> = (props) => {
          const {data} = props ?? {};

          return  sendPushNotificationAndroid(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendPushNotificationAndroid>>, TError, {data: ConsumerMobileSendPushNotificationRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const testNewEobNotifier = (
    testNewEobNotifierRequest: TestNewEobNotifierRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/TestNewEobNotifier`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: testNewEobNotifierRequest
    },
      options);
    }
  


    export type TestNewEobNotifierMutationResult = NonNullable<Awaited<ReturnType<typeof testNewEobNotifier>>>
    export type TestNewEobNotifierMutationBody = TestNewEobNotifierRequest
    export type TestNewEobNotifierMutationError = unknown

    export const useTestNewEobNotifier = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof testNewEobNotifier>>, TError,{data: TestNewEobNotifierRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof testNewEobNotifier>>, {data: TestNewEobNotifierRequest}> = (props) => {
          const {data} = props ?? {};

          return  testNewEobNotifier(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof testNewEobNotifier>>, TError, {data: TestNewEobNotifierRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const sendMassVersionUpdateNotification = (
    consumerMobileSendMassVersionUpdateNotificationRequest: ConsumerMobileSendMassVersionUpdateNotificationRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/SendMassVersionUpdateNotification`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileSendMassVersionUpdateNotificationRequest
    },
      options);
    }
  


    export type SendMassVersionUpdateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof sendMassVersionUpdateNotification>>>
    export type SendMassVersionUpdateNotificationMutationBody = ConsumerMobileSendMassVersionUpdateNotificationRequest
    export type SendMassVersionUpdateNotificationMutationError = unknown

    export const useSendMassVersionUpdateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendMassVersionUpdateNotification>>, TError,{data: ConsumerMobileSendMassVersionUpdateNotificationRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendMassVersionUpdateNotification>>, {data: ConsumerMobileSendMassVersionUpdateNotificationRequest}> = (props) => {
          const {data} = props ?? {};

          return  sendMassVersionUpdateNotification(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendMassVersionUpdateNotification>>, TError, {data: ConsumerMobileSendMassVersionUpdateNotificationRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const sendTestVersionUpdateNotification = (
    consumerMobileSendMassVersionUpdateNotificationRequest: ConsumerMobileSendMassVersionUpdateNotificationRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/ConsumerMobile_Notification/SendTestVersionUpdateNotification`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileSendMassVersionUpdateNotificationRequest
    },
      options);
    }
  


    export type SendTestVersionUpdateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof sendTestVersionUpdateNotification>>>
    export type SendTestVersionUpdateNotificationMutationBody = ConsumerMobileSendMassVersionUpdateNotificationRequest
    export type SendTestVersionUpdateNotificationMutationError = unknown

    export const useSendTestVersionUpdateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendTestVersionUpdateNotification>>, TError,{data: ConsumerMobileSendMassVersionUpdateNotificationRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendTestVersionUpdateNotification>>, {data: ConsumerMobileSendMassVersionUpdateNotificationRequest}> = (props) => {
          const {data} = props ?? {};

          return  sendTestVersionUpdateNotification(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof sendTestVersionUpdateNotification>>, TError, {data: ConsumerMobileSendMassVersionUpdateNotificationRequest}, TContext>(mutationFn, mutationOptions)
    }
    