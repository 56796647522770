import { Box, Button, Card, CardActions, CardContent, Stack, Typography } from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { FormErrorAlert } from "common/forms";
import { icons } from "theme";
import FormContainer from "../../../forms/FormContainer";
import Icon from "common/components/Icons/Icon";
import { ProgressSteps } from "common/components/StepProgress/ProgressSteps";
import { VerticalDescriptionSteps } from "common/components/StepProgress/VerticalDescriptionSteps";
import { AnthemConnectManager } from "./AnthemConnectManager";
import { useCallback, useEffect } from "react";
import { FullscreenLoader } from "common/components/Loaders/FullscreenLoader";

function AnthemAuthorize({ learn_more }: { learn_more?: boolean }) {
	const [searchParams] = useSearchParams();
	const anthemPlan = searchParams.get("anthemPlan");
	console.log("Anthem authorize " + anthemPlan);
	const submit = useCallback(() => {
		AnthemConnectManager.initiateAuthorization(anthemPlan ?? "");
	}, [anthemPlan]);
	useEffect(() => {
		if (!learn_more) {
			submit();
		}
	}, [learn_more, submit]);

	if (!learn_more) {
		return <FullscreenLoader />;
	}
	const steps = [
		<Box key={0}>
			First we will be directing you to the Anthem account login page. If you already have an account please login, if you don't have an account please
			select the{" "}
			<Box component="span" sx={{ color: "core.electricblue", fontWeight: "600" }}>
				create an account{" "}
			</Box>
			now link to begin setting up your account.
		</Box>,
		<Box key={1}>
			Once logged in you'll be asked for sharing authorization. Please select the{" "}
			<Box component="span" sx={{ color: "core.electricblue", fontWeight: "600" }}>
				share all your data
			</Box>{" "}
			option, this will allow us to more accurately get your location and make better suggestions about your health.
		</Box>,
	];
	return (
		<FormContainer
			topContent={
				<Stack sx={{ flexDirection: "row", justifyContent: "flex-start" }}>
					<Box sx={{ paddingRight: "12px" }}>
						<Typography variant="h1" sx={{ fontWeight: "500" }} component="h1">
							<Link to="/connect-healthplan" style={{ color: "inherit" }}>
								<Icon icon={icons.arrowLeft} />
							</Link>
						</Typography>
					</Box>
					<Box>
						<Typography variant="h1" sx={{ fontWeight: "500" }} component="h1">
							Sharing your data
						</Typography>
						<Typography variant="subtitle1" sx={{ fontSize: "14px", marginBottom: "-8px" }} component="p">
							We'll help you through sharing access to your Medicare info with us
						</Typography>
					</Box>
				</Stack>
			}
			bottomContent={
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
					}}
				>
					<ProgressSteps maxWidth={"300px"} count={3} current={3} />
				</div>
			}
		>
			<Card
				component="form"
				onSubmit={submit}
				sx={{
					width: "100%",
					paddingLeft: "24px",
					paddingRight: "24px",
					paddingTop: "16px",
					paddingBottom: "24px",
					boxSizing: "border-box",
				}}
				elevation={4}
			>
				<CardContent>
					<Stack spacing={2}>
						<Typography variant="subtitle1" style={{ paddingBottom: "16px" }}>
							In order to collect and monitor valuable data about your health we'll need you to share your health data with us.
						</Typography>
						<VerticalDescriptionSteps steps={steps} current={3} color="core.electricblue" />
					</Stack>
				</CardContent>
				<FormErrorAlert label="Access Denied" />
				<CardActions sx={{ justifyContent: "right", marginTop: 0, paddingTop: 0 }}>
					<Button onClick={submit} sx={{ backgroundColor: "core.darkblue" }}>
						Continue
					</Button>
				</CardActions>
			</Card>
			<button
				onClick={async () => {
					let authTarget = await AnthemConnectManager.constructAuthorizeURI(anthemPlan ?? "");
					console.debug(authTarget);
				}}
			>
				Get Authorization details
			</button>
		</FormContainer>
	);
}

export { AnthemAuthorize };
