import { Box, Stack, Typography } from "@mui/material";
import { CSSProperties, ReactNode } from "react";
import { palette } from "theme";

type ContentSectionProps = React.PropsWithChildren<{
	flexGrow?: number;
	flexBasis?: number | string;
	minHeight?: number;
	title?: string;
	titleBarContent?: ReactNode;
	style?: CSSProperties;
	width?: string;
}>;

const ContentSection = (props: ContentSectionProps) => {
	return (
		<Stack
			style={{ ...props.style }}
			sx={{
				width: "100%",
				marginBottom: "8px",
				// height: "100%",
			}}
		>
			<Box
				className="ContentSection"
				sx={{
					backgroundColor: palette.core.lightBackground,
					width: props.width ?? "100%",
					flexGrow: 1,
					borderRadius: "1rem",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "1rem",
					boxShadow: 4,
					boxSizing: "border-box",
				}}
			>
				{props.title ? (
					<Box
						style={{
							flexGrow: 0,
							width: "100%",
							textAlign: "left",
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							paddingBottom: ".5rem",
						}}
					>
						<Typography variant="h2" sx={{ marginBottom: "0" }}>
							{props.title}
						</Typography>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							{props.titleBarContent}
						</Box>
					</Box>
				) : null}
				<Box
					style={{
						flexGrow: 1,
						width: "100%",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "stretch",
					}}
				>
					{props.children}
				</Box>
			</Box>
		</Stack>
	);
};

export { ContentSection };
