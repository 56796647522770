import { useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { styleConverter } from "../../utilities/styleConverter";
import { palette } from "theme";
import { HealthIcon, IconSVG } from "../Icons/HealthIcon";

interface TileWithImageProps {
	icon: IconSVG;
	color: any;
	date?: string | Date | null;
	title: string;
	imageSrc: string;
	minimal?: boolean;
}

const TileWithImage = ({ icon, title, color, imageSrc }: TileWithImageProps) => {
	let titleColorMarkup = useMemo(() => {
		return styleConverter.textColorMarkupX(title);
	}, [title]);

	return (
		<Box
			data-componentname="TileWithImage-Outer"
			sx={{
				paddingBottom: ".5rem",
			}}
		>
			<Box
				sx={{
					width: "100%",
					borderRadius: "1rem",
					boxSizing: "border-box",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "stretch",
					overflow: "hidden",
					maxHeight: "6.5rem",
					backgroundColor: palette.gray.light,
				}}
			>
				<Stack
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "space-between",
						padding: "1rem",
						flex: 1.7,
					}}
				>
					<Box
						sx={{
							color: color,
							flexGrow: 0,
							border: palette.gray.main + ".1rem",
							borderRadius: "6rem",
							display: "flex",
							alignItems: "center",
							borderStyle: "solid",
							p: 1.4,
							backgroundColor: "white",
						}}
					>
						<HealthIcon size={48} iconSVG={icon} color={color} />
					</Box>
					<Box
						style={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Box>
							<Typography
								sx={{
									color: palette.gray.darker,
									fontSize: "1rem",
									paddingLeft: "8px",
									paddingRight: "8px",
									fontWeight: "bold",
									textAlign: "left",
									"& .colorText": {
										color: color,
									},
								}}
							>
								{titleColorMarkup}
							</Typography>
						</Box>
					</Box>
				</Stack>
				<Box
					sx={{
						overflow: "hidden",
						flex: 4,
						flexDirection: "row",
						display: "flex",
						alignItems: "stretch",
						flexGrow: 1,
					}}
				>
					<Box sx={{ objectFit: "cover", width: "100%" }} component="img" src={imageSrc} alt="logo" />
				</Box>
			</Box>
		</Box>
	);
};

export { TileWithImage };
