import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { useContext, useMemo } from "react";
import { AccountManagerContext } from "common/managers/AccountManager";
import { InfoPopper } from "common/components/DisplayElements/InfoPopper";
import { useSnackbar } from "notistack";
import { CarePlans } from "account/pages/myProfile/UserDetails/CarePlans";
import { GeneralInfo } from "./GeneralInfo";
import { palette } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import UserLocationSettings from "./UserLocationSettings";
import { AlertSettings } from "./AlertSettings";

function UserDetails() {
	const accountContext = useContext(AccountManagerContext);

	const userDetails = useMemo(() => {
		return accountContext.accountManager.userDetails;
		// return [userDetails, userDetails?.consumerUser];
	}, [accountContext.accountManager.userDetails]);

	const { enqueueSnackbar } = useSnackbar();

	function successCallback() {
		console.debug("succeed");
		enqueueSnackbar("Preferences saved", {
			variant: "success",
		});
		accountContext.accountManager.setUser("REFRESH");
	}

	function failureCallback() {
		console.debug("fail");
		enqueueSnackbar("Error while saving changes", {
			variant: "error",
		});
	}

	// if (!consumerUser) {
	// 	return null; // This prevents logout
	// }
	return (
		<Container maxWidth={"md"}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<GeneralInfo />
				</Grid>
				<Grid item xs={12}>
					<ContentSection title="Insurance Plans">
						{userDetails?.patientDataAuthorizations && userDetails.patientDataAuthorizations.length > 0 ? (
							<CarePlans
								patientDataAuthorizations={userDetails?.patientDataAuthorizations}
								patientDemographics={userDetails?.patientDemographics}
								tileBackground={true}
								showOptions={true}
							/>
						) : (
							<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
								<Typography>No Connected Insurance Plans</Typography>
							</Stack>
						)}

						{accountContext.careCollaborationUserDetails?.activeCareCollaboratorAuthorization ? null : (
							<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", pt: 2 }}>
								<Button
									disabled={!!accountContext.careCollaborationUserDetails?.activeCareCollaboratorAuthorization}
									sx={{ width: { xs: "100%", sm: "auto" } }}
									onClick={() => {
										DialogManager.open("add-care-plan");
									}}
								>
									Add Insurance Plan
								</Button>
							</Box>
						)}
					</ContentSection>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<ContentSection
								title="Set Your Location"
								titleBarContent={
									<InfoPopper>
										<Typography variant="h4">Set Your Location</Typography>
										<Typography>
											The zip code you specify here will determine the location of the doctors and providers Radius Care will recommend in
											your radius. When you are away or in another state, you can simply change the zip code to reflect your new location
											and we will adjust your recommendations accordingly.
										</Typography>
									</InfoPopper>
								}
							>
								<UserLocationSettings
								//zipCode={consumerUser?.location?.zip}
								/>
							</ContentSection>
						</Grid>

						{userDetails ? (
							<Grid item xs={12} md={6}>
								<AlertSettings successCallback={successCallback} failureCallback={failureCallback} userDetails={userDetails} />
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
}

export { UserDetails };
