import { careScore, ConsumerCareScoreRequest } from "api";
import { ConsumerOperationStatusCareScoreResponseModel_Extended } from "api_extended/consumer-patient-health-data/care-score_extended";
import { ENHANCE_DoctorRecord } from "common/definitions/entity/ProviderEntity";
import { dateConverter } from "common/utilities/dateConverter";
import { useQuery, UseQueryResult } from "react-query";

async function retrieveCareScore(params: ConsumerCareScoreRequest): Promise<ConsumerOperationStatusCareScoreResponseModel_Extended> {
	let result = await careScore(params).then((result) => {
		let r = result as ConsumerOperationStatusCareScoreResponseModel_Extended;
		if (r.success && r.data?.providers) {
			Object.keys(r.data.providers)
				.map((x) => r.data.providers[x])
				.forEach((provs) => {
					provs.forEach((prov) => {
						ENHANCE_DoctorRecord(prov);
					});
				});
		}
		return result;
	});
	return result as ConsumerOperationStatusCareScoreResponseModel_Extended;
}

type CareScoreParamsX = {
	year?: number;
	startDate?: Date | "default";
	endDate?: Date;
};
function useQ_careScore(paramsX: CareScoreParamsX): UseQueryResult<ConsumerOperationStatusCareScoreResponseModel_Extended> {
	if (paramsX.startDate === "default") {
		let s = new Date();
		s.setFullYear(s.getFullYear() - 1);
		paramsX.startDate = s;
	}
	let s = paramsX.startDate ? dateConverter.stringDateForAPI(paramsX.startDate) : undefined;
	let e = paramsX.endDate ? dateConverter.stringDateForAPI(paramsX.endDate) : undefined;
	let params: ConsumerCareScoreRequest = {
		dateRangeRequest: { fullYear: paramsX.year, startDate: s, endDate: e },
		// patientID: paramsX.patientID
	};
	return useQuery(["retrieveCareScore", `retrieveCareScore${s}_${e}_${paramsX.year}`], retrieveCareScore.bind(null, params), {
		staleTime: Infinity,
		cacheTime: Infinity,
	});
}

export { useQ_careScore };
// export type { CostOfCareResult };
