import { AppBar, Box, Button, Container, Grid, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";
import { useContext, useEffect } from "react";
import { useQ_suggestSimilarDoctors } from "../suggestSimilarProviders/requests/useQ_suggestSimilarDoctors";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { CONVERT_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { X_addUserIgnoreItems } from "common/requests/patient_data/X_addUserIgnoreItems";
import { useQueryClient } from "react-query";
import { GeolocationContext } from "common/context/GeolocationContext";
import { DoctorTile } from "common/components/Tiles/DoctorTile";

function CareAlert({ npi }: { npi: string }) {
	const queryClient = useQueryClient(); // not having this here breaks the invalidateQueries
	useEffect(() => {
		GeolocationManager.getPosition();
	}, []);

	const geolocationContext = useContext(GeolocationContext);

	const suggestSimilarDoctorsResponse = useQ_suggestSimilarDoctors({ clientLocation: geolocationContext.geolocationManager.clientLocation(), npi: npi });

	const provsByType = suggestSimilarDoctorsResponse?.data?.data?.data?.doctorRecordsByType;

	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Care Alert</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("care-alert");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ContentSection title="Description">
							<Typography>
								Higher rated care providers have been found in your area. Below is a list of doctors that have had success in treating your
								particular concerns.
							</Typography>
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "flex-end",
									"& .MuiButton-root": { width: { xs: "100%", sm: "auto" } },
									paddingTop: { xs: ".5rem", sm: "0" },
								}}
							>
								<Button
									color="error"
									onClick={async () => {
										await X_addUserIgnoreItems(
											{
												careAlertNPIs: [npi],
											},
											queryClient
										);
										DialogManager.close("care-alert");
									}}
								>
									Disregard Alert
								</Button>
							</Box>
						</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<QueryStatusDisplay queryResult={suggestSimilarDoctorsResponse}>
							{provsByType
								? Object.keys(provsByType).map((key) => {
										let x = provsByType[key];
										return (
											<ContentSection key={key} title={key}>
												{x.map((y, i) => {
													//var entity = CONVERT_DoctorStubX_Entity(y, "AUTO");
													var entity = CONVERT_DoctorRecord_Entity(y, "AUTO");
													entity.descriptor =
														(entity._providerRecord?.taxonomies[0]?.displayName &&
														entity._providerRecord?.taxonomies[0]?.displayName !== ""
															? entity._providerRecord?.taxonomies[0]?.displayName
															: entity._providerRecord?.specialties !== ""
															? entity._providerRecord?.specialties
															: entity._providerRecord?.hgSpecialty !== ""
															? entity._providerRecord?.hgSpecialty
															: "") ?? "";
													let viewProfile = () => {
														DialogManager.open("provider-details", entity._providerRecord?.npi ?? "MISSING NPI");
													};
													let content = (
														<Box>
															<Button variant="text" onClick={viewProfile}>
																View Profile
															</Button>
														</Box>
													);
													return (
														<Box key={i} sx={{ paddingBottom: ".5rem" }}>
															<DoctorTile Entity={entity} content={content} />
														</Box>
													);
												})}
												{x.length === 0 ? (
													<Typography>
														No higher rated providers found within{" "}
														{suggestSimilarDoctorsResponse?.data?.data?.data.distanceRadiusMiles} miles.
													</Typography>
												) : null}
											</ContentSection>
										);
								  })
								: null}
						</QueryStatusDisplay>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
}

export { CareAlert };
