import React from "react";
import { Navigate } from "react-router";
import { useForgotPasswordFlow } from "../../../auth/useForgotPasswordFlow";
import ForgotPasswordForm from "./ForgotPasswordForm";

function ForgotPasswordPage() {
  const flow = useForgotPasswordFlow();

  switch (flow.step) {
    case "ForgotPasswordStep":
      return <ForgotPasswordForm {...flow} />;

    case "ResetPasswordStep":
      return <Navigate replace to={`/reset-password?username=${encodeURIComponent(flow.username)}`} />;

    case "ForgotPasswordCompleteStep":
      return <Navigate replace to="/" />;
  }
}

export default ForgotPasswordPage;
