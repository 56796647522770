import { Box, Stack, Typography } from "@mui/material";
import { EventTypeNames, event_type_definitions } from "common/definitions/EventDefinitions";
import { ReactNode } from "react";
import { Link, useMatch } from "react-router-dom";
import { palette } from "theme";
import { InfoPopperPropsWithChildren } from "../DisplayElements/InfoPopper";
import { InfoPopper } from "common/components/DisplayElements/InfoPopper";

type BasicTileXProps = {
	type: EventTypeNames;
	title?: string;
	value: string | number;
	link?: string | true;
	borderActive?: boolean;
	infoPopperProps?: InfoPopperPropsWithChildren;
	onClick?: () => any;
};
const BasicTileX = ({ type, title, value, link, infoPopperProps, borderActive, onClick }: BasicTileXProps) => {
	let x = event_type_definitions[type];

	let link_: string | undefined = undefined;
	if (link === true) {
		link_ = x?.default_link;
	} else if (typeof link === "string") {
		link_ = link;
	}

	let match: any = useMatch({
		path: link_ ?? "--------------",
	});

	if (!x) {
		return null;
	}

	title = title ?? x.default_text ?? "";
	return (
		<BasicTile
			title={title}
			color={x.color}
			value={value}
			icon={x.icon}
			link={link_}
			borderActive={(link && match) || borderActive}
			infoPopperProps={infoPopperProps}
			onClick={onClick}
		/>
	);
};

type BasicTileProps = {
	icon: ReactNode;
	title: string | number;
	value: string | number;
	link?: string;
	color?: string;
	borderActive?: boolean;
	infoPopperProps?: InfoPopperPropsWithChildren;
	onClick?: () => any;
};
const BasicTile = ({ icon, title, value, link, color, borderActive, infoPopperProps, onClick }: BasicTileProps) => {
	let guts = (
		<Stack
			onClick={onClick}
			sx={{
				position: "relative",
				flexGrow: 1,
				height: "100%",
				padding: { xs: ".5rem", sm: "1rem" },
				borderRadius: "1rem",
				boxSizing: "border-box",
				backgroundColor: palette.gray.light,
				flexDirection: "column",
				alignItems: "center",
				justifyContent: { xs: "flex-start", sm: "center" },
				textDecoration: "none",
				borderColor: borderActive ? color : "transparent",
				borderWidth: "2px",
				borderStyle: "solid",
				transition: "border-color .5s",
				"&:hover": {
					borderColor: color,
					cursor: onClick ? "pointer" : "normal",
				},
				"& .HealthIcon": { width: { xs: "2rem !important", sm: "3rem !important" }, height: { xs: "2rem !important", sm: "3rem !important" } },
			}}
		>
			{infoPopperProps ? (
				<Box data-name="Popper" style={{ position: "absolute", right: 8, top: 8 }}>
					<InfoPopper {...infoPopperProps} />
				</Box>
			) : null}
			<Box>{icon}</Box>
			<Box sx={{ textAlign: "center" }}>
				<Typography component={"span"} sx={{ fontSize: { xs: "1.25rem", sm: "2.5rem" }, fontWeight: 700, color: color }}>
					{value}
				</Typography>{" "}
				<Typography variant="h4" sx={{ color: palette.gray.dark, mb: "0", fontSize: { xs: "75%", sm: "100%" } }}>
					{title}
				</Typography>
			</Box>
		</Stack>
	);
	if (link) {
		guts = <Link to={link}>{guts}</Link>;
	}

	return (
		<Box
			data-componentname="BasicTile-Outer"
			style={{
				flexBasis: 1,
				flexGrow: 1,
				margin: "0px",
				padding: "4px",
				boxSizing: "border-box",
			}}
		>
			{guts}
		</Box>
	);
};

export { BasicTile, BasicTileX };
