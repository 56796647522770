import { Box, Button, Stack, Typography } from "@mui/material";
import { Loader } from "common/components/Loaders/Loader";
import { AccountManagerContext } from "common/managers/AccountManager";
import { event_actions } from "common/definitions/EventDefinitions";
import { useContext, useMemo } from "react";
import { SentCareColloboraterAuth } from "./SentCareColloboraterAuth";
import { palette } from "theme";

type SentCareCollaborationsProps = {
	noRevoked?: boolean;
};
function SentCareCollaborations({ noRevoked }: SentCareCollaborationsProps) {
	const accountContext = useContext(AccountManagerContext);
	console.debug(accountContext?.careCollaborations?.sent);
	const sentContent = useMemo(() => {
		if (!accountContext?.careCollaborations?.sent) {
			return null;
		}

		let items = accountContext.careCollaborations.sent.filter((i) => {
			if (noRevoked && i.status === "REVOKED") {
				return false;
			}
			return true;
		});

		if (items.length === 0) {
			return null;
		}

		return items.map((cc) => {
			return <SentCareColloboraterAuth key={cc.id} careCollaboratorAuthorization={cc} allowRevoke={true} />;
		});
	}, [accountContext.careCollaborations, noRevoked]);

	return (
		<Box>
			{accountContext.loadingCollaborators ? (
				<Loader />
			) : (
				<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
					{accountContext.careCollaborations && sentContent ? (
						<Stack sx={{ "& > .MuiBox-root:not(:last-child)": { marginBottom: "8px" }, textAlign: "center" }}>{sentContent}</Stack>
					) : (
						<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
							<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
								<Typography>No Sent Care Collaborations</Typography>
							</Stack>
						</Box>
					)}
					<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end", marginTop: "1rem" }}>
						<Button
							onClick={() => {
								event_actions.add_care_collaborator.action(null);
							}}
						>
							{event_actions.add_care_collaborator.text}
						</Button>
					</Stack>
				</Box>
			)}
		</Box>
	);
}

export { SentCareCollaborations };
