import { Box, Stack, Typography } from "@mui/material";
import { ModelsUSPSTF, ConsumerCompletionTracker, ConsumerRecommendedUspstfResponseCompletions } from "api";
import { StandardRadialBarChart } from "common/components/Charts/StandardRadialBarChart";
import { BasicTileX } from "common/components/Tiles/BasicTile";
import { AchievementType } from "common/definitions/EventDefinitions";
import { useMemo } from "react";
import { palette, theme } from "theme";

type CurrentHealthAchievementsProps = {
	uspstfItems: ModelsUSPSTF[];
	completions: ConsumerRecommendedUspstfResponseCompletions;
	categoryFilter: "ALL" | AchievementType;
	set_categoryFilter: React.Dispatch<React.SetStateAction<"ALL" | AchievementType>>;
};

const get_completion_for_type = (t: string, completions: ConsumerRecommendedUspstfResponseCompletions) => {
	if (completions[t]) {
		return `${completions[t].totalCompleted}/${completions[t].total}`;
	}
	return "?/?";
};

const CurrentHealthAchievements = ({ categoryFilter, set_categoryFilter, completions }: CurrentHealthAchievementsProps) => {
	const percentData = useMemo(() => {
		// console.debug(completions);

		let x: ConsumerCompletionTracker = completions["TOTAL"];
		if (categoryFilter !== "ALL") {
			x = completions[categoryFilter];
		}
		if (x) {
			let percent = Math.round((x.totalCompleted / x.total) * 100);
			return {
				name: "Total",
				val: percent,
			};
		}
		return {
			name: "Total",
			val: 0,
		};
	}, [completions, categoryFilter]);

	const set_categoryFilterX = useMemo(() => {
		return (achType: AchievementType) => {
			if (categoryFilter !== achType) {
				set_categoryFilter(achType);
			} else {
				set_categoryFilter("ALL");
			}
		};
	}, [set_categoryFilter, categoryFilter]);

	return (
		<Stack
			sx={{
				width: "100%",
				flexDirection: "row",
				justifyContent: "center",
				[theme.breakpoints.down("md")]: {
					flexDirection: "column",
				},
			}}
		>
			<Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", sm: "25%" } }}>
				<StandardRadialBarChart
					data={[percentData]}
					nameKey="name"
					dataKey="val"
					innerRadius={50}
					outerRadius={80}
					responsiveHeight={300}
					colors={[palette.core.careTasks]}
					maxValue={100}
					showAverage
					showCustom={
						<Box style={{ display: "flex", flexDirection: "column" }}>
							<Typography variant="large-value-display">{Number.isNaN(percentData.val) ? "?" : percentData.val + "%"}</Typography>
							<Typography variant="standard-gray-title">Complete</Typography>
						</Box>
					}
					startAngle="straightup"
				/>
			</Stack>
			<Stack
				sx={{
					flex: "1",
					flexDirection: "row",
					justifyContent: "center",
					[theme.breakpoints.down("md")]: {
						flexDirection: "row",
					},
				}}
			>
				<BasicTileX
					type={"wellness_check"}
					value={get_completion_for_type("wellness_check", completions)}
					onClick={set_categoryFilterX.bind(null, "wellness_check")}
					borderActive={categoryFilter === "wellness_check"}
					infoPopperProps={{
						children: (
							<>
								<Typography variant="h4">Wellness Check</Typography>
								<Typography>
									Wellness checks are important for maintaining optimal health. These checks help you set baselines for comparison. Below you
									will find a list of wellness checks suggested for your age group as defined by current medical care guidelines.
								</Typography>
							</>
						),
					}}
				></BasicTileX>
				<BasicTileX
					type={"screening"}
					value={get_completion_for_type("screening", completions)}
					onClick={set_categoryFilterX.bind(null, "screening")}
					borderActive={categoryFilter === "screening"}
					infoPopperProps={{
						children: (
							<>
								<Typography variant="h4">Screenings</Typography>
								<Typography>
									Health screenings as identified by your current medical care guidelines are a smart part of your healthcare routine.
									Identifying health concerns early is often easier to address and gets you back on your path to health faster.
								</Typography>
							</>
						),
					}}
				></BasicTileX>
				{/* <BasicTileX
					type={"general"}
					value={get_completion_for_type("general", completions)}
					onClick={set_categoryFilterX.bind(null, "general")}
					borderActive={categoryFilter === "general"}
					infoPopperProps={{
						children: (
							<>
								<Typography variant="tile-header-dark">Preventive Medication</Typography>
								<Typography>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
									Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
									dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
									proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
								</Typography>
							</>
						),
					}}
				></BasicTileX> */}
				<BasicTileX
					type={"vaccination"}
					value={get_completion_for_type("vaccination", completions)}
					onClick={set_categoryFilterX.bind(null, "vaccination")}
					borderActive={categoryFilter === "vaccination"}
					infoPopperProps={{
						children: (
							<>
								<Typography variant="h4">Vaccinations</Typography>
								<Typography>
									According to the National Library of Medicine, vaccinations can reduce disease, disability, and death from a variety of
									infectious diseases.
								</Typography>
							</>
						),
					}}
				></BasicTileX>
			</Stack>
		</Stack>
	);
};

export { CurrentHealthAchievements };
