import { CognitoUser } from "amazon-cognito-identity-js";
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FullscreenLoader } from "common/components/Loaders/FullscreenLoader";
import {
	ConsumerGetConsumerUserDetailsResult,
	ConsumerGetCurrentCareCollaborationUserDetailsResult,
	ConsumerRetrieveCareCollaborationsResponse,
	getCurrentCareCollaborationUserDetails,
	retrieveCareCollaborations,
} from "api";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { X_getUserDetails } from "common/requests/patient_data/X_getUserDetails";

/*










 ██████ ██       █████  ███████ ███████ 
██      ██      ██   ██ ██      ██      
██      ██      ███████ ███████ ███████ 
██      ██      ██   ██      ██      ██ 
 ██████ ███████ ██   ██ ███████ ███████
*/
class AccountManagerClass {
	userDetails: ConsumerGetConsumerUserDetailsResult | null = null;
	user: CognitoUser | null = null;
	keyPrefix: string | null = null;
	username: string = "UNINITIALIZED";
	signInUserSession: any = null;
	intializedAt?: number;
	setValues?: (acctMan: AccountManagerClass) => any;
	set_updateTrigger?: Dispatch<SetStateAction<number>>;
	set_displayModal: (x: AccountManagerDisplayModalTypes) => any = (x: AccountManagerDisplayModalTypes) => null; //Dispatch<SetStateAction<AccountManagerDisplayModalTypes>=>void //| null = null

	idToken = () => {
		return localStorage.getItem(`${this.keyPrefix}.${this.username}.idToken`);
	};

	clear = () => {
		this.user = null;
		this.keyPrefix = null;
		this.username = "UNINITIALIZED";
		this.userDetails = null;
	};

	setUser = async (user: CognitoUser | null | "REFRESH") => {
		if (user) {
			if (user === "REFRESH") {
				// Dont change the user - just refresh the details
			} else {
				this.user = user;
			}
			this.keyPrefix = (this.user as any).keyPrefix ?? null;
			this.username = (this.user as any).username ?? null;

			// let ud: ModelsOperationStatus | ConsumerGetConsumerUserDetailsResult = await X_getUserDetails();
			// if (!apiResponseReader.typeCheck_ModelsOperationStatus(ud)) {
			// 	this.userDetails = ud;
			// }
			this.userDetails = await X_getUserDetails();
			// console.debug("this.userDetails", this.userDetails);
		} else {
			this.clear();
		}
		if (this.setValues) {
			this.setValues(this);
		}
		if (this.set_updateTrigger) {
			this.set_updateTrigger(Date.now());
		}

		if (this.set_displayModal) {
			if (this.userDetails?.userAssessments) {
				if (this.userDetails.userAssessments.inactiveCollaborationWithNoDirectDatasource) {
					this.set_displayModal("InactiveCollaborationWithNoDirectDatasource");
				}
			}
		}
	};

	constructor() {
		if (!(AccountManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(AccountManagerClass as any).instance = this;
		}
		return (AccountManagerClass as any).instance;
	}
}
const AccountManager = new AccountManagerClass();

/*







 ██████  ██████  ███    ██ ████████ ███████ ██   ██ ████████ 
██      ██    ██ ████   ██    ██    ██       ██ ██     ██    
██      ██    ██ ██ ██  ██    ██    █████     ███      ██    
██      ██    ██ ██  ██ ██    ██    ██       ██ ██     ██    
 ██████  ██████  ██   ████    ██    ███████ ██   ██    ██  
*/

type AccountManagerDisplayModalTypes = "InactiveCollaborationWithNoDirectDatasource" | null;
const AccountManagerContext = createContext<{
	user: CognitoUser | null;
	username: string | null;
	accountManager: AccountManagerClass;
	updateTrigger: number;
	set_updateTrigger: Dispatch<SetStateAction<number>>;
	careCollaborationUserDetails: ConsumerGetCurrentCareCollaborationUserDetailsResult | null;
	careCollaborations: ConsumerRetrieveCareCollaborationsResponse | null;
	refreshCollaborators: () => void;
	//set_refreshCollaborators: Dispatch<SetStateAction<number>>;
	loadingCollaborators: boolean;
	displayModal: AccountManagerDisplayModalTypes;
	set_displayModal: Dispatch<SetStateAction<AccountManagerDisplayModalTypes>> | null;
	careCollaboratorAlertClosed: boolean;
	set_careCollaboratorAlertClosed: Dispatch<SetStateAction<boolean>> | null;
	activeProviderOptions: boolean | Array<string>;
}>({
	user: AccountManager.user,
	username: AccountManager.username,
	accountManager: AccountManager,
	updateTrigger: 0,
	set_updateTrigger: () => null,
	careCollaborationUserDetails: null,
	careCollaborations: null,
	refreshCollaborators: () => null,
	loadingCollaborators: false,
	displayModal: null,
	set_displayModal: null,
	careCollaboratorAlertClosed: false,
	set_careCollaboratorAlertClosed: null,
	activeProviderOptions: false,
});

function AccountManagerContextProvider(props: { children: React.ReactNode }) {
	const [user, setUser] = useState(AccountManager.user);
	const [username, setUsername] = useState(AccountManager.username);
	const [updateTrigger, set_updateTrigger] = useState(0);
	const [loadingCollaborators, set_loadingCollaborators] = useState(false);

	//Collaborators
	const [refreshCollaboratorState, set_refreshCollaboratorState] = useState<number>(0);

	const [careCollaborationUserDetails, set_careCollaborationUserDetails] = useState<ConsumerGetCurrentCareCollaborationUserDetailsResult | null>(null);
	const [careCollaborations, set_careCollaborations] = useState<ConsumerRetrieveCareCollaborationsResponse | null>(null);
	const [displayModal, set_displayModal] = useState<AccountManagerDisplayModalTypes>(null);
	const [careCollabAlertClosed, set_careCollabAlertClosed] = useState<boolean>(false);

	useEffect(() => {
		set_loadingCollaborators(true);
		Promise.all([
			getCurrentCareCollaborationUserDetails().then((r) => {
				// console.debug("getCurrentCareCollaborationUserDetails", r);
				if (!apiResponseReader.typeCheck_ModelsOperationStatus(r)) {
					set_careCollaborationUserDetails(r);
				}
			}),
			retrieveCareCollaborations().then((r) => {
				if (r.success && r.data) {
					set_careCollaborations(r.data);
				}
			}),
		]).then((r) => {
			set_loadingCollaborators(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshCollaboratorState]);

	const refreshCollaborators = useMemo(() => {
		return () => {
			set_refreshCollaboratorState(refreshCollaboratorState + 1);
		};
	}, [set_refreshCollaboratorState, refreshCollaboratorState]);

	const setUserValues = (AcctMan: AccountManagerClass) => {
		if (AcctMan.user !== user) {
			if (window.RadiusCare.queryClient) {
				window.RadiusCare.queryClient.invalidateQueries(); // do not invalidate definitions!!!!
				console.log("Invalidated All Cached Queries");
			}
		}
		setUser(AcctMan.user);
		setUsername(AcctMan.username);
	};

	useEffect(() => {
		AccountManager.set_displayModal = (x) => {
			set_displayModal(x);
		};
	}, [set_displayModal]);

	AccountManager.setValues = setUserValues;
	AccountManager.set_updateTrigger = set_updateTrigger;

	const activeProviderOptions: boolean | Array<string> = useMemo(() => {
		if (careCollaborationUserDetails?.activeCareCollaboratorAuthorization) {
			if (
				careCollaborationUserDetails?.consumerUserDetails?.userAssessments?.providerOptions &&
				careCollaborationUserDetails.consumerUserDetails.userAssessments.providerOptions.length > 0
			) {
				return careCollaborationUserDetails.consumerUserDetails.userAssessments.providerOptions;
			}
		} else if (AccountManager.userDetails?.userAssessments?.providerOptions && AccountManager.userDetails.userAssessments.providerOptions.length > 0) {
			return AccountManager.userDetails.userAssessments.providerOptions;
		}
		return false;
		// AccountManager.userDetails is required for this to function correctly
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		careCollaborationUserDetails?.activeCareCollaboratorAuthorization,
		careCollaborationUserDetails?.consumerUserDetails?.userAssessments?.providerOptions,
		AccountManager.userDetails,
	]);
	// console.debug("activeProviderOptions", activeProviderOptions);
	return (
		<AccountManagerContext.Provider
			value={{
				user: user,
				username: username,
				accountManager: AccountManager,
				set_updateTrigger: set_updateTrigger,
				updateTrigger: updateTrigger,
				refreshCollaborators: refreshCollaborators,
				careCollaborationUserDetails: careCollaborationUserDetails,
				careCollaborations: careCollaborations,
				loadingCollaborators: loadingCollaborators,
				displayModal: displayModal,
				set_displayModal: set_displayModal,
				careCollaboratorAlertClosed: careCollabAlertClosed,
				set_careCollaboratorAlertClosed: set_careCollabAlertClosed,
				activeProviderOptions: activeProviderOptions,
			}}
		>
			{user && username ? props.children : <FullscreenLoader />}
		</AccountManagerContext.Provider>
	);
}

export { AccountManager, AccountManagerContextProvider, AccountManagerContext };

export type { AccountManagerDisplayModalTypes };
