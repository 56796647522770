import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { useGetUserDetails } from "api";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import CodeVerificationPage from "account/pages/public/CodeVerificationPage";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyEmailDialog = () => {
	const location = useLocation();
	const { verifyEmailDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("verify-email");

	const response = useGetUserDetails();

	const consumerUserDetails = useMemo(() => {
		if (!apiResponseReader.typeCheck_ModelsOperationStatus(response.data) && response.data !== undefined) {
			return response.data.consumerUser;
		}
		return null;
	}, [response.data]);

	const close = useMemo(() => {
		return () => {
			searchParams.delete("verify-email");
			setSearchParams(searchParams);
			verifyEmailDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "verify-email": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["verify-email"].open = open;
		DialogManager.launchers["verify-email"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== verifyEmailDisplay?.current) {
			verifyEmailDisplay.set_current(d_query);
		}
	}, [d_query, verifyEmailDisplay]);

	return (
		<Dialog id="VerifyEmailDialog" fullScreen open={!!verifyEmailDisplay.current} onClose={close} TransitionComponent={Transition}>
			{<CodeVerificationPage username={consumerUserDetails?.email} hideBackButton={true} />}
		</Dialog>
	);
};

export { VerifyEmailDialog };
