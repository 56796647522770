import LZString from "lz-string";

const apiResponseCache = {
	checkCache: async (
		cacheName: string,
		retrieve?: (useCacheApi: boolean) => any,
		options?: {
			compress?: boolean;
			cacheValidMinutes: number;
			forceRenewCacheToken?: boolean | string;
			forceRetrieve?: boolean;
			cacheRule?: (response: any) => boolean;
			allowRecursion?: boolean;
		}
	): Promise<any> => {
		// forceRenewCacheToken system will allow API to request re-retrieval
		let frc_token = options?.forceRenewCacheToken ?? null;
		let forceRenewCacheTokenApplied = false;
		let forceRetrieveOption = options?.forceRetrieve ?? false;
		let applied_frc_tokens: Array<string> | null = null;
		if (frc_token) {
			if (typeof frc_token === "string") {
				frc_token = cacheName + "_" + frc_token;
				let frct_string = localStorage.getItem(`ForceRenewCacheTokens`);
				if (frct_string) {
					try {
						let x = JSON.parse(frct_string);
						applied_frc_tokens = x;
					} catch (e) {}
				}
				if (!Array.isArray(applied_frc_tokens)) {
					applied_frc_tokens = [];
				}
				if (applied_frc_tokens.indexOf(frc_token) === -1) {
					forceRenewCacheTokenApplied = true;
					applied_frc_tokens.push(frc_token); // add token but dont save it yet until we get the response
				}
			}
		}

		let dNow = Date.now();
		let cacheValidMinutes = options?.cacheValidMinutes ?? 60; // default 1 hour
		let compress = options?.compress !== undefined ? options.compress : true; // default true
		let expiresAt = localStorage.getItem(`${cacheName}_expiresAt`);
		// expiresAt = expiresAt ? parseInt(expiresAt) : null;
		let cacheExpired = 0;
		if (expiresAt && parseInt(expiresAt) < dNow) {
			cacheExpired = dNow - parseInt(expiresAt);
		} else if (!expiresAt) {
			cacheExpired = 1;
		}

		if (!cacheExpired && !forceRenewCacheTokenApplied && !forceRetrieveOption) {
			// console.log(expiresAt);
			console.log(`Retrieving ${cacheName} from cache`);
			let cachedResponse = localStorage.getItem(cacheName);
			if (cachedResponse !== null) {
				if (compress) {
					cachedResponse = LZString.decompressFromUTF16(cachedResponse);
				}
				if (cachedResponse !== null) {
					let response = JSON.parse(cachedResponse);
					if (options?.cacheRule && !options.cacheRule(response) && options.allowRecursion !== false) {
						console.debug("CACHE INVALID " + cacheName);
						apiResponseCache.clearCache(cacheName);
						options.allowRecursion = false;
						return await apiResponseCache.checkCache(cacheName, retrieve, options);
					}
					return response;
				}
			}
		}
		if (retrieve) {
			console.log(
				`Retrieving ${cacheName} from source. ${
					forceRenewCacheTokenApplied
						? `forceRenewCacheToken was applied (${frc_token}).`
						: forceRetrieveOption
						? "forceRetrieveOption was supplied"
						: cacheExpired
						? "Cache was expired"
						: "..."
				}`
			);
			let response = await retrieve(true);
			if (response && (!options?.cacheRule || options.cacheRule(response))) {
				let toCache = JSON.stringify(response);
				if (compress) {
					toCache = LZString.compressToUTF16(toCache);
				}
				localStorage.setItem(cacheName, toCache);
				localStorage.setItem(`${cacheName}_expiresAt`, String(Date.now() + cacheValidMinutes * 60000));

				if (forceRenewCacheTokenApplied && Array.isArray(applied_frc_tokens)) {
					localStorage.setItem(`ForceRenewCacheTokens`, JSON.stringify(applied_frc_tokens));
				}
				return response;
			} else {
				if (response && options?.cacheRule && !options.cacheRule(response)) {
					window.RadiusCare.log_issue({ file: "apiResponseCache", message: `cacheRule failed ${cacheName}` });
				}
			}
		}
		return null;
	},

	clearCache: (cacheName: string) => {
		localStorage.setItem(cacheName, "null");
		localStorage.setItem(`${cacheName}_expiresAt`, "null");
	},
};

export { apiResponseCache };
