import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { useGetUserDetails } from "api";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import ChangePassword from "account/components/ChangePassword";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePasswordDialog = () => {
	const location = useLocation();
	const { changePasswordDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("change-password");

	const response = useGetUserDetails();

	const consumerUserDetails = useMemo(() => {
		if (!apiResponseReader.typeCheck_ModelsOperationStatus(response.data) && response.data !== undefined) {
			return response.data.consumerUser;
		}
		return null;
	}, [response.data]);

	const close = useMemo(() => {
		return () => {
			searchParams.delete("change-password");
			setSearchParams(searchParams);
			changePasswordDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "change-password": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["change-password"].open = open;
		DialogManager.launchers["change-password"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== changePasswordDisplay?.current) {
			changePasswordDisplay.set_current(d_query);
		}
	}, [d_query, changePasswordDisplay]);

	return (
		<Dialog id="ChangePasswordDialog" fullScreen open={!!changePasswordDisplay.current} onClose={close} TransitionComponent={Transition}>
			{changePasswordDisplay.current && consumerUserDetails && <ChangePassword username={consumerUserDetails.email} />}
		</Dialog>
	);
};

export { ChangePasswordDialog };
