import { Box, FormControlLabel, Stack, Switch } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { Controller, useForm } from "react-hook-form";
import { ConsumerGetConsumerUserDetailsResult, editAlertPreferences } from "api";
import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import { palette } from "theme";

interface AlertPreferences {
	newDiagnosisAlert: boolean;
	remindersAlert: boolean;
	careCollaboratorInviteAlert: boolean;
	updateUserInfoAlert: boolean;
}

function AlertSettings({
	successCallback,
	failureCallback,
	userDetails,
}: {
	successCallback: () => any;
	failureCallback: () => any;
	userDetails: ConsumerGetConsumerUserDetailsResult;
}) {
	const { handleSubmit, getValues, control } = useForm<AlertPreferences>({
		mode: "onChange",
		defaultValues: {
			newDiagnosisAlert: userDetails.consumerUser?.alertPreferences?.newDiagnosis,
			remindersAlert: userDetails.consumerUser?.alertPreferences?.reminders,
			careCollaboratorInviteAlert: userDetails.consumerUser?.alertPreferences?.careCollaboratorInvite,
			updateUserInfoAlert: userDetails.consumerUser?.alertPreferences?.updateUserInfo,
		},
	});

	const onSubmit = handleSubmit(() => {
		console.log("ONSUBMIT", getValues("newDiagnosisAlert"));
		return editAlertPreferences({
			"alertPreferences.newDiagnosis": getValues("newDiagnosisAlert"),
			"alertPreferences.reminders": getValues("remindersAlert"),
			"alertPreferences.careCollaboratorInvite": getValues("careCollaboratorInviteAlert"),
			"alertPreferences.updateUserInfo": getValues("updateUserInfoAlert"),
		}).then(successCallback, failureCallback);
	});

	return (
		<ContentSection title="Alert Settings">
			<Box sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: ".5rem", display: "flex", mb: 1 }}>
				<Controller
					name="newDiagnosisAlert"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<FormControlLabel
							control={<Switch color={"blue"} size="medium" onChange={onChange} checked={value} value={value} />}
							label="New Diagnosis Alert"
						/>
					)}
				/>
			</Box>
			<Box sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: ".5rem", display: "flex", mb: 1 }}>
				<Controller
					name="remindersAlert"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<FormControlLabel
							control={<Switch color={"blue"} size="medium" onChange={onChange} checked={value} value={value} />}
							label="Reminders Alerts"
						/>
					)}
				/>
			</Box>
			<Box sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: ".5rem", display: "flex", mb: 1 }}>
				<Controller
					name="careCollaboratorInviteAlert"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<FormControlLabel
							control={<Switch color={"blue"} size="medium" onChange={onChange} checked={value} value={value} />}
							label="Care Collaborator Invite Alerts"
						/>
					)}
				/>
			</Box>
			<Box sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: ".5rem", display: "flex" }}>
				<Controller
					name="updateUserInfoAlert"
					control={control}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<FormControlLabel
							control={<Switch color={"blue"} size="medium" onChange={onChange} checked={value} value={value} />}
							label="Update User Info Alerts"
						/>
					)}
				/>
			</Box>
			<Stack sx={{ paddingTop: "1rem", flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end" }}>
				<FormSubmitButton onClick={onSubmit}>Save</FormSubmitButton>
			</Stack>
		</ContentSection>
	);
}

export { AlertSettings };
