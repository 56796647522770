import { Box, LinearProgress, Typography } from "@mui/material";
import { FormAlert } from "common/forms/FormAlerts";
import { ReactNode } from "react";
const type_frames = {
  onboarding: [
    {
      img: "/images/radius-care-onboarding-loading-1.png",
      text: "Importing your Medical History",
      start: 0,
      end: 50,
    },
    {
      img: "/images/radius-care-onboarding-loading-2.png",
      text: "Analyzing your care team...",
      start: 51,
      end: 100,
    },
  ],
};

const ProgressDisplay = ({
  progress,
  type,
  error,
  failureDisplay,
}: {
  progress: number;
  type: "onboarding";
  error?: string | null;
  failureDisplay?: ReactNode;
}) => {
  if (progress > 100) {
    progress = 100;
  }
  let frames = type_frames[type];
  let frame =
    frames[
      (() => {
        for (let i = 0; i < frames.length; i++) {
          if (frames[i].start <= progress && frames[i].end >= progress) {
            return i;
          }
        }
      })() ?? 0
    ];
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <>
          <FormAlert message={error} />
          {failureDisplay ?? null}
        </>
      ) : (
        <>
          <img
            src={frame.img}
            alt={"progress display: " + frame.text}
            width="100%"
          />
          <Box
            sx={{
              width: "72%",
              marginRight: "auto",
              marginLeft: "auto",
            }}
          >
            <LinearProgress variant="determinate" value={progress} />
          </Box>
          <Typography
            sx={{
              paddingTop: "16px",
              fontSize: "1.2rem",
              color: "core.darkText",
            }}
          >
            {frame.text}
          </Typography>
        </>
      )}
    </Box>
  );
};

export { ProgressDisplay };
