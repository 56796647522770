import { ConsumerCostOfCareRequest, ConsumerOperationStatusCostOfCareResponseModel, costOfCare } from "api";
import { useQuery, UseQueryResult } from "react-query";

async function retrieveCostOfCareX(paramsX: ConsumerCostOfCareRequest): Promise<ConsumerOperationStatusCostOfCareResponseModel | null> {
	var x = await costOfCare(paramsX);
	console.log("COST OF CARE RESPONSE");
	console.debug(x);
	return x;
}

function useQ_costOfCareX(paramsX: ConsumerCostOfCareRequest): UseQueryResult<ConsumerOperationStatusCostOfCareResponseModel | null> {
	let queryInvalidations = ["CostOfCareX", `CostOfCareX:${JSON.stringify(paramsX)}`];
	return useQuery(queryInvalidations, retrieveCostOfCareX.bind(null, paramsX), {
		staleTime: 86400000, // 24 * 1000 * 60 * 60 // 1DAY
		cacheTime: 86400000,
	});
}

export { useQ_costOfCareX };
