/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerDoctorGetDoctorsByLocationResult,
  ModelsClientLocation,
  GetDoctorsByLocationParams,
  ConsumerDoctorGetDoctorsByZipCodesResult,
  GetDoctorsByZipCodesParams,
  ConsumerDoctorGetDoctorsByNPIsResult,
  GetDoctorsByNPIsBody,
  ConsumerDoctorGetProviderTaxonomiesResult,
  ConsumerDoctorGetProviderDetailResult,
  GetProviderDetailParams,
  ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel,
  ConsumerDoctorFindHigherRatedCareRequestModel,
  ConsumerDoctorGetSimpleHospitalResult,
  GetSimpleHospitalParams,
  ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel,
  ConsumerDoctorFindHospitalsByLocationRequestModel,
  ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel,
  ConsumerDoctorSuggestSimilarDoctorsRequestModel
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDoctorsByLocation = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDoctorsByLocationParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorGetDoctorsByLocationResult>(
      {url: `/api/consumer/ConsumerDoctor/GetDoctorsByLocation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDoctorsByLocationMutationResult = NonNullable<Awaited<ReturnType<typeof getDoctorsByLocation>>>
    export type GetDoctorsByLocationMutationBody = ModelsClientLocation
    export type GetDoctorsByLocationMutationError = unknown

    export const useGetDoctorsByLocation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDoctorsByLocation>>, TError,{data: ModelsClientLocation;params?: GetDoctorsByLocationParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDoctorsByLocation>>, {data: ModelsClientLocation;params?: GetDoctorsByLocationParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDoctorsByLocation(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDoctorsByLocation>>, TError, {data: ModelsClientLocation;params?: GetDoctorsByLocationParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByZipCodes = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDoctorsByZipCodesParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorGetDoctorsByZipCodesResult>(
      {url: `/api/consumer/ConsumerDoctor/GetDoctorsByZipCodes`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDoctorsByZipCodesMutationResult = NonNullable<Awaited<ReturnType<typeof getDoctorsByZipCodes>>>
    export type GetDoctorsByZipCodesMutationBody = ModelsClientLocation
    export type GetDoctorsByZipCodesMutationError = unknown

    export const useGetDoctorsByZipCodes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDoctorsByZipCodes>>, TError,{data: ModelsClientLocation;params?: GetDoctorsByZipCodesParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDoctorsByZipCodes>>, {data: ModelsClientLocation;params?: GetDoctorsByZipCodesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDoctorsByZipCodes(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDoctorsByZipCodes>>, TError, {data: ModelsClientLocation;params?: GetDoctorsByZipCodesParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByNPIs = (
    getDoctorsByNPIsBody: GetDoctorsByNPIsBody,
 options?: SecondParameter<typeof apiRequest>,) => {const formData = new FormData();
if(getDoctorsByNPIsBody.npis !== undefined) {
 formData.append('npis', getDoctorsByNPIsBody.npis)
 }

      return apiRequest<ConsumerDoctorGetDoctorsByNPIsResult>(
      {url: `/api/consumer/ConsumerDoctor/GetDoctorsByNPIs`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      options);
    }
  


    export type GetDoctorsByNPIsMutationResult = NonNullable<Awaited<ReturnType<typeof getDoctorsByNPIs>>>
    export type GetDoctorsByNPIsMutationBody = GetDoctorsByNPIsBody
    export type GetDoctorsByNPIsMutationError = unknown

    export const useGetDoctorsByNPIs = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDoctorsByNPIs>>, TError,{data: GetDoctorsByNPIsBody}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDoctorsByNPIs>>, {data: GetDoctorsByNPIsBody}> = (props) => {
          const {data} = props ?? {};

          return  getDoctorsByNPIs(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDoctorsByNPIs>>, TError, {data: GetDoctorsByNPIsBody}, TContext>(mutationFn, mutationOptions)
    }
    export const getProviderTaxonomies = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerDoctorGetProviderTaxonomiesResult>(
      {url: `/api/consumer/ConsumerDoctor/GetProviderTaxonomies`, method: 'get', signal
    },
      options);
    }
  

export const getGetProviderTaxonomiesQueryKey = () => [`/api/consumer/ConsumerDoctor/GetProviderTaxonomies`];

    
export type GetProviderTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof getProviderTaxonomies>>>
export type GetProviderTaxonomiesQueryError = unknown

export const useGetProviderTaxonomies = <TData = Awaited<ReturnType<typeof getProviderTaxonomies>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviderTaxonomies>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProviderTaxonomiesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProviderTaxonomies>>> = ({ signal }) => getProviderTaxonomies(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProviderTaxonomies>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getProviderDetail = (
    params?: GetProviderDetailParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerDoctorGetProviderDetailResult>(
      {url: `/api/consumer/ConsumerDoctor/GetProviderDetail`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetProviderDetailQueryKey = (params?: GetProviderDetailParams,) => [`/api/consumer/ConsumerDoctor/GetProviderDetail`, ...(params ? [params]: [])];

    
export type GetProviderDetailQueryResult = NonNullable<Awaited<ReturnType<typeof getProviderDetail>>>
export type GetProviderDetailQueryError = unknown

export const useGetProviderDetail = <TData = Awaited<ReturnType<typeof getProviderDetail>>, TError = unknown>(
 params?: GetProviderDetailParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProviderDetail>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProviderDetailQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProviderDetail>>> = ({ signal }) => getProviderDetail(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProviderDetail>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const findHigherRatedCare = (
    consumerDoctorFindHigherRatedCareRequestModel: ConsumerDoctorFindHigherRatedCareRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorOperationStatusFindHigherRatedCareResponseModel>(
      {url: `/api/consumer/ConsumerDoctor/FindHigherRatedCare`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerDoctorFindHigherRatedCareRequestModel
    },
      options);
    }
  


    export type FindHigherRatedCareMutationResult = NonNullable<Awaited<ReturnType<typeof findHigherRatedCare>>>
    export type FindHigherRatedCareMutationBody = ConsumerDoctorFindHigherRatedCareRequestModel
    export type FindHigherRatedCareMutationError = unknown

    export const useFindHigherRatedCare = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof findHigherRatedCare>>, TError,{data: ConsumerDoctorFindHigherRatedCareRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof findHigherRatedCare>>, {data: ConsumerDoctorFindHigherRatedCareRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  findHigherRatedCare(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof findHigherRatedCare>>, TError, {data: ConsumerDoctorFindHigherRatedCareRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const getSimpleHospital = (
    params?: GetSimpleHospitalParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerDoctorGetSimpleHospitalResult>(
      {url: `/api/consumer/ConsumerDoctor/GetSimpleHospital`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetSimpleHospitalQueryKey = (params?: GetSimpleHospitalParams,) => [`/api/consumer/ConsumerDoctor/GetSimpleHospital`, ...(params ? [params]: [])];

    
export type GetSimpleHospitalQueryResult = NonNullable<Awaited<ReturnType<typeof getSimpleHospital>>>
export type GetSimpleHospitalQueryError = unknown

export const useGetSimpleHospital = <TData = Awaited<ReturnType<typeof getSimpleHospital>>, TError = unknown>(
 params?: GetSimpleHospitalParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSimpleHospital>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSimpleHospitalQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSimpleHospital>>> = ({ signal }) => getSimpleHospital(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSimpleHospital>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const findHospitalsByLocation = (
    consumerDoctorFindHospitalsByLocationRequestModel: ConsumerDoctorFindHospitalsByLocationRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorOperationStatusFindHospitalsByLocationResponseModel>(
      {url: `/api/consumer/ConsumerDoctor/FindHospitalsByLocation`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerDoctorFindHospitalsByLocationRequestModel
    },
      options);
    }
  


    export type FindHospitalsByLocationMutationResult = NonNullable<Awaited<ReturnType<typeof findHospitalsByLocation>>>
    export type FindHospitalsByLocationMutationBody = ConsumerDoctorFindHospitalsByLocationRequestModel
    export type FindHospitalsByLocationMutationError = unknown

    export const useFindHospitalsByLocation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof findHospitalsByLocation>>, TError,{data: ConsumerDoctorFindHospitalsByLocationRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof findHospitalsByLocation>>, {data: ConsumerDoctorFindHospitalsByLocationRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  findHospitalsByLocation(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof findHospitalsByLocation>>, TError, {data: ConsumerDoctorFindHospitalsByLocationRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const suggestSimilarDoctors = (
    consumerDoctorSuggestSimilarDoctorsRequestModel: ConsumerDoctorSuggestSimilarDoctorsRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel>(
      {url: `/api/consumer/ConsumerDoctor/SuggestSimilarDoctors`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerDoctorSuggestSimilarDoctorsRequestModel
    },
      options);
    }
  


    export type SuggestSimilarDoctorsMutationResult = NonNullable<Awaited<ReturnType<typeof suggestSimilarDoctors>>>
    export type SuggestSimilarDoctorsMutationBody = ConsumerDoctorSuggestSimilarDoctorsRequestModel
    export type SuggestSimilarDoctorsMutationError = unknown

    export const useSuggestSimilarDoctors = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suggestSimilarDoctors>>, TError,{data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof suggestSimilarDoctors>>, {data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  suggestSimilarDoctors(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof suggestSimilarDoctors>>, TError, {data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    