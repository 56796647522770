// import { SystemDateOnly, SystemDayOfWeek } from "api";
import { StandardOption } from "common/types/generic";

const dateConverter = {
	getFullMonthSpan(d1: Date, d2: Date): number {
		let [y1, y2] = [d1.getFullYear(), d2.getFullYear()];
		let [m1, m2] = [d1.getMonth(), d2.getMonth()]; // January is 0 index
		let yearDiff = y2 - y1;

		let monthDiff = m2 - m1;

		// console.debug({ yearDiff, monthDiff, d1, d2 });
		// console.debug(yearDiff * 12 + monthDiff);
		return yearDiff * 12 + monthDiff;
	},
	getDateForMonthsAgo(monthsAgo: number): Date {
		// console.debug("getDateForMonthsAgo", monthsAgo);
		const d = new Date();
		let y = d.getFullYear();
		let m = d.getMonth(); // 0 index

		let yearsAgo = Math.floor(monthsAgo / 12);
		let adjustedMonthsAgo = monthsAgo % 12;
		d.setFullYear(y - yearsAgo);
		d.setMonth(m - adjustedMonthsAgo);
		// console.debug({ monthsAgo, yearsAgo, adjustedMonthsAgo, d });

		return d;
	},
	reactiveSetValueFromDate(d: Date, options: Array<StandardOption<string, number | string>>, intervalType: DateRangeSelect_IntervalType): number | "" {
		const nowDate = new Date();
		let target_value: number;
		if (intervalType === "month") {
			target_value = dateConverter.getFullMonthSpan(d, nowDate);
		}

		let closestMatchOption: StandardOption<string, number> | null = null;
		let surpassed = false;
		options.some((o, i) => {
			if (typeof o.value === "number") {
				if (o.value < target_value) {
					if (i === options.length) {
						closestMatchOption = o as StandardOption<string, number>;
					}
				} else {
					if (!surpassed) {
						closestMatchOption = o as StandardOption<string, number>;
						return true;
					}
				}
			}

			return false;
		});
		// console.debug("reactiveSetValueFromDate >>>> closestMatchOption", closestMatchOption);
		return closestMatchOption ? (closestMatchOption as CIM)?.value : "";
	},

	// systemDateOnlyForAPI(d: Date, options?: { includeTime?: boolean }): SystemDateOnly {
	// 	// will need to implement includeTime if needed
	// 	// return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;

	// 	let sysDate = {
	// 		year: d.getFullYear(),
	// 		month: d.getMonth(),
	// 		day: d.getDate(),
	// 		dayOfWeek: d.getDay() as 0 | 1 | 2 | 3 | 4 | 5 | 6,
	// 	};

	// 	return sysDate;
	// },
	// export interface SystemDateOnly {
	// 	year?: number;
	// 	month?: number;
	// 	day?: number;
	// 	dayOfWeek?: SystemDayOfWeek;
	// 	readonly dayOfYear?: number;
	// 	readonly dayNumber?: number;
	//   }

	stringDateForAPI(d: Date, options?: { includeTime?: boolean }): string {
		// will need to implement includeTime if needed
		return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
	},

	dateFromDateStringYMD(dateString: string): Date | undefined {
		let elements: Array<string> | null = null;
		if (dateString.indexOf("-") !== -1 && dateString.indexOf("-") < 6) {
			elements = dateString.split("-");
		} else if (dateString.indexOf("/") !== -1 && dateString.indexOf("/") < 6) {
			elements = dateString.split("/");
		}
		if (!elements || elements.length < 3) {
			return undefined;
		}
		elements = elements.map((s) => s.trim());
		let d = new Date();
		d.setFullYear(parseInt(elements[0]));
		d.setMonth(parseInt(elements[1]) - 1);
		d.setDate(parseInt(elements[2]));
		return d;
	},

	stringDateForDisplay(d: Date, options?: { includeTime?: boolean; zeroPadDate?: boolean }): string {
		// will need to implement includeTime if needed
		return `${String(d.getMonth() + 1).padStart(options?.zeroPadDate ? 2 : 0, "0")}/${d.getDate()}/${d.getFullYear()}`;
	},
	"YYYY-MM_to_mM/YY"(s: string | unknown, fullYear: boolean = false) {
		if (typeof s === "string") {
			let d = s.split("-");
			return `${d[1].slice(d[1][0] === "0" ? 1 : 0)}/${fullYear ? d[0] : d[0].slice(2)}`;
		}
		return "?";
	},
	"YYYY-MM-DD_to_mM/dD/YY"(s: string | unknown, fullYear: boolean = false) {
		if (typeof s === "string") {
			let d = s.split("T")[0].split("-");
			return `${d[1].slice(d[1][0] === "0" ? 1 : 0)}/${d[2].slice(d[2][0] === "0" ? 1 : 0)}/${fullYear ? d[0] : d[0].slice(2)}`;
		}
		return "??/??";
	},
};

export { dateConverter };

type DateRangeSelect_IntervalType = "month";
export type { DateRangeSelect_IntervalType };
