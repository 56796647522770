import { Box, Stack, Typography } from "@mui/material";
import { Entity, entity_actions, entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { ReactNode, useContext } from "react";
import { isProviderEntity } from "common/definitions/entity/ProviderEntity";
import { isMedicationEntity } from "common/definitions/entity/MedicationEntity";
import { isConditionEntity } from "common/definitions/entity/ConditionEntity";
import { OptionsButton } from "../Buttons/OptionsButton";
import { DiagnosisDetailOption, DialogsContext } from "common/context/MainContext";

interface ConditionSearchTileProps {
	Entity: Entity;
	content?: ReactNode;
	diagnosisDetailOption?: DiagnosisDetailOption | null;
}
const ConditionSearchTile = ({ Entity, content, diagnosisDetailOption }: ConditionSearchTileProps) => {
	const { title, descriptor } = Entity;
	const entityType = entity_types["condition"];
	const { diagnosisDetailDisplay } = useContext(DialogsContext);

	return (
		<Stack
			data-componentname="ConditionSearchTile"
			className="search-result-tile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				overflow: "hidden",
			}}
		>
			<Stack
				sx={{
					justifyContent: "flex-start",
					alignItems: { xs: "flex-start", sm: "center" },
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Stack
					sx={{
						flex: "1",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<AvatarDisplay
						imageAlt={title}
						imageSrc={Entity._providerRecord?.imageUrl ?? ""}
						icon={
							<HealthIcon
								color={entityType.iconColor ?? palette.core.electricblue}
								size={48}
								iconSVG={
									Entity._providerRecord?.genderCode
										? Entity._providerRecord?.genderCode === "M"
											? health_icons_svg.doctormale
											: health_icons_svg.doctorfemale
										: entityType.iconSVG
								}
							/>
						}
					/>
					<Stack sx={{ display: "flex", flexDirection: "column", paddingLeft: ".5rem", textAlign: "left" }}>
						<Typography sx={{ color: palette.gray.dark, fontWeight: 500 }}>{descriptor}</Typography>

						<Typography sx={{ color: palette.core.blue, fontSize: "1.25rem", cursor: "pointer" }}>
							{isProviderEntity(Entity) || isMedicationEntity(Entity) || isConditionEntity(Entity) ? (
								<Box
									component={"span"}
									onClick={() => {
										if (diagnosisDetailOption) {
											diagnosisDetailDisplay.set_options(diagnosisDetailOption);
										}
										entity_actions.view_details.action(Entity);
									}}
								>
									{title}
								</Box>
							) : (
								title
							)}
						</Typography>
					</Stack>
				</Stack>

				<Stack sx={{ justifyContent: "center", alignItems: "center", width: { xs: "100%", sm: "auto" }, paddingTop: { xs: ".5rem", sm: "0" } }}>
					<OptionsButton content={content} />
				</Stack>
			</Stack>
		</Stack>
	);
};

export { ConditionSearchTile };
