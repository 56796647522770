import Box from "@mui/material/Box";
import { ContentSection } from "common/components/Containers/ContentSection";
import { AppBar, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { palette, style } from "theme";
import { health_icons_svg } from "common/components/Icons/HealthIcon";
import { TileWithImage } from "common/components/Tiles/TileWithImage";

function AccarentAlert() {
	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">New Member Benefit Available</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("accarent-alert");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center", mt: -3 }}>
				<Container maxWidth={"sm"}>
					<Box component="form" display={"flex"} flexDirection={"column"} p={5}>
						<ContentSection flexGrow={1} title="">
							<Grid item xs={12} md={3} sx={{ textAlign: "center" }}>
								<Box sx={{ width: { xs: "12rem", sm: "50%" }, pb: 2 }} component="img" src={"/images/datasource/LogoAccarent.svg"} alt="logo" />
							</Grid>
							<Typography textAlign={"center"} p={2} mb={1}>
								The Accarent Health platform web platform provides access to transparent pricing options and high-quality care providers, when
								considering a surgical procedure or treatment.
							</Typography>
							<Box>
								<TileWithImage
									imageSrc={"/images/datasource/Hospital.svg"}
									icon={health_icons_svg.clinic}
									color={palette.core.forestgreen}
									title={"Choose Center of Excellence"}
								/>
								<TileWithImage
									imageSrc={"/images/datasource/PatientWithDoctor.svg"}
									icon={health_icons_svg.calendar}
									color={palette.core.purple}
									title={"Schedule Consult & Procedure"}
								/>
								<TileWithImage
									imageSrc={"/images/datasource/DoctorXray.svg"}
									icon={health_icons_svg.doctormale}
									color={palette.core.lavender}
									title={"Follow Up with Provider"}
								/>
								<Typography sx={{ display: "flex", justifyContent: "center", fontWeight: "bold", fontSize: "1rem", p: 1 }}>
									CONTACT ACCARENT TODAY
								</Typography>
								<Button sx={{ width: "100%", mb: 1 }}>Call: 1-866-071-0897</Button>
								<Button sx={{ width: "100%" }}>Email: casemanagement@accarenthealth.com</Button>
							</Box>
						</ContentSection>
					</Box>
				</Container>
			</Stack>
		</>
	);
}

export default AccarentAlert;
