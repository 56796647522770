import { Box, Button, Stack, Typography } from "@mui/material";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { Paginator } from "common/components/Paginator/Paginator";
import { ConditionSearchTile } from "common/components/Tiles/ConditionSearchTile";
import { DiagnosisDetailOption, DialogsContext } from "common/context/MainContext";
import { ConditionEntity } from "common/definitions/entity/ConditionEntity";
import { DialogManager } from "common/managers/DialogManager";
import { StandardOption } from "common/types/generic";
import { FilterFunctions } from "common/utilities/filterFunctions";
import { SearchConditionsResult_ModelsOperationStatus } from "findCare/requests/useQ_retrieveSearchConditions";
import { useContext, useMemo, useState } from "react";
import { UseQueryResult } from "react-query";

type FindCareConditionResultsProps = {
	conditionType: StandardOption<string, string>;
	conditionResults: UseQueryResult<SearchConditionsResult_ModelsOperationStatus>;
};

const FindCareConditionResults = ({ conditionResults }: FindCareConditionResultsProps) => {
	const [keywords, set_keywords] = useState<string>("");

	const filterSortedByKeywords: Array<ConditionEntity> = useMemo(() => {
		return FilterFunctions.filter_by_keywords(conditionResults?.data?.data ?? [], ["title", "descriptor"], keywords);
	}, [keywords, conditionResults?.data?.data]);
	const { diagnosisDetailDisplay } = useContext(DialogsContext);
	return (
		<Box>
			<Stack sx={{ paddingBottom: "1rem", width: "100%" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Keywords
				</Typography>
				<StandardTextField
					placeholder='"Condition Name"'
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						set_keywords(e.currentTarget.value ?? "");
					}}
					value={keywords}
				/>
			</Stack>
			<QueryStatusDisplay queryResult={conditionResults}>
				<Paginator
					pageSize={6}
					data={filterSortedByKeywords}
					renderItem={(r, i) => {
						let option: DiagnosisDetailOption = { topDoctors: "only_IcdRangeLookupTaxonomy" };
						let content = (
							<Box>
								<Button
									variant="text"
									onClick={() => {
										diagnosisDetailDisplay.set_options(option);
										DialogManager.open("diagnosis-details", r._conditionRecord?.ICD10Code);
									}}
								>
									View Details
								</Button>
							</Box>
						);
						return (
							<Box key={i} sx={{ paddingBottom: ".5rem" }}>
								<ConditionSearchTile Entity={r} content={content} diagnosisDetailOption={option} />
							</Box>
						);
					}}
					hasNextBatch={false}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

export { FindCareConditionResults };
