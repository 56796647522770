import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { DeviceContext } from "./DeviceContext";

const StyleContext = createContext<{
	screenWidth: number;
	lastTriggered: number;
	trigger: () => any;
	headerOffset: number;
}>({
	screenWidth: window.innerWidth,
	lastTriggered: 0,
	trigger: () => {
		return;
	},
	headerOffset: 0,
});

const resize: {
	action: any;
	watch: (this: Window, ev: Event) => any;
	setter: Dispatch<SetStateAction<number>> | (() => any);
	setter_lastTriggered: Dispatch<SetStateAction<number>> | (() => any);
	lastSet: number;
	lastScheduled: number;
	throttleInterval: number;
} = {
	action: (w: number, time: number) => {
		resize.lastSet = time;
		resize.setter(w);
	},
	watch: (_ev: Event) => {
		let time = Date.now();
		let w = window.innerWidth;
		if (resize.lastSet + resize.throttleInterval < time) {
			resize.action(w, time);
		} else {
			resize.lastScheduled = time;
			setTimeout(() => {
				if (resize.lastScheduled === time) {
					resize.action(w, time);
					resize.setter_lastTriggered(time);
				}
			}, resize.throttleInterval + 120);
		}
	},
	setter: () => {},
	setter_lastTriggered: () => {},
	lastSet: 1,
	lastScheduled: 1,
	throttleInterval: 250,
};

window.addEventListener("resize", resize.watch);

function StyleContextProvider(props: React.PropsWithChildren<{}>) {
	const [screenWidth, set_screenWidth] = useState<number>(window.screen.width);
	const [lastTriggered, set_lastTriggered] = useState<number>(0);

	const deviceContext = useContext(DeviceContext);

	const headerOffsetPx = useMemo(() => {
		return deviceContext.installAppBannerHeightCurrent;
	}, [deviceContext.installAppBannerHeightCurrent]);

	resize.setter = set_screenWidth;
	resize.setter_lastTriggered = set_lastTriggered;
	useEffect(() => {
		let time = Date.now();
		resize.action(window.innerWidth, time);
	}, []);

	const trigger = useMemo(() => {
		return () => {
			let time = Date.now();
			resize.action(window.innerWidth, time);
			resize.setter_lastTriggered(time);
		};
	}, []);
	return <StyleContext.Provider value={{ screenWidth, lastTriggered, trigger, headerOffset: headerOffsetPx }}>{props.children}</StyleContext.Provider>;
}

export { StyleContextProvider, StyleContext };
