import { useState } from "react";
import { Box, Button, Card, CardActions, CardContent, IconButton, Stack, TextField, Typography, Link } from "@mui/material";
import { useForm } from "react-hook-form";

import { FormErrorAlert, withFormStatus } from "common/forms";
import { icons } from "theme";
import FormContainer from "./FormContainer";
import Icon from "common/components/Icons/Icon";

interface SignInFormProps {
	username?: string;
	onSignIn(username: string, password: string, target?: string): void;
}

function SignInForm({ username, onSignIn }: SignInFormProps) {
	const { register, handleSubmit } = useForm<{
		username: string;
		password: string;
	}>({ defaultValues: { username } });

	const onSubmit = handleSubmit((form) => {
		let username = form.username.toLowerCase();
		return onSignIn(username, form.password);
	});
	const [passwordVisibility, setPasswordVisibility] = useState(false);
	return (
		<FormContainer
			bottomContent={
				<Box style={{ textAlign: "center" }}>
					<Link href="/signup" sx={{ color: "core.darkblue", fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
						Don't have an account?
						<br />
						Click here to create one!
					</Link>
				</Box>
			}
		>
			<Card component="form" onSubmit={onSubmit} elevation={4}>
				<CardContent>
					<Typography variant="h1" component="h1">
						Sign in
					</Typography>
					<Typography sx={{ pb: 4 }}>Sign in below and take control of your health</Typography>
					<Stack spacing={2}>
						<TextField label="Enter your email address" {...register("username", { required: true })} variant="standard" />
						<Stack
							style={{
								width: "100%",
								position: "relative",
							}}
						>
							<TextField
								label="Enter your password"
								type={passwordVisibility ? "text" : "password"}
								{...register("password", { required: true })}
								variant="standard"
							/>
							<IconButton
								style={{
									position: "absolute",
									right: 8,
									top: 12,
									opacity: 0.4,
								}}
								onClick={() => {
									setPasswordVisibility(!passwordVisibility);
								}}
							>
								{passwordVisibility ? <Icon icon={icons.eyeSlash} /> : <Icon icon={icons.eye} />}
							</IconButton>
						</Stack>
						<Stack alignItems={"flex-end"}>
							<Link href="/forgot-password">Forgot Password</Link>
						</Stack>
					</Stack>
				</CardContent>
				<FormErrorAlert label="Access Denied" />
				<CardActions sx={{ justifyContent: "right", marginTop: 0, paddingTop: 0 }}>
					<Button type="submit" sx={{ backgroundColor: "core.darkblue" }}>
						Sign In
					</Button>
				</CardActions>
			</Card>
		</FormContainer>
	);
}

export default withFormStatus(SignInForm);
