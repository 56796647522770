import { AccarentAlertDialog } from "./dialogs/AccarentAlertDialog";
import { ActivateAchievementsDialog } from "./dialogs/ActivateAchievementsDialog";
import { AddCarePlanDialog } from "./dialogs/AddCarePlanDialog";
import { CareAlertDialog } from "./dialogs/CareAlertDialog";
import { CareMaintenanceSavingsDialog } from "./dialogs/CareMaintenanceSavingsDialog";
import { ChangePasswordDialog } from "./dialogs/ChangePasswordDialog";
import { DiagnosisDetailDialog } from "./dialogs/DiagnosisDetailDialog";
import { HospitalDetailDialog } from "./dialogs/HospitalDetailDialog";
import { InviteCareCollaboratorDialog } from "./dialogs/InviteCareCollaborator/InviteCareCollaboratorDialog";
import { MedicationDetailDialog } from "./dialogs/MedicationDetailDialog";
import { MedicationSavingsDetailDialog } from "./dialogs/MedicationSavingsDetailDialog";
import { OptimizeCareRatingsDialog } from "./dialogs/OptimizeCareRatingsDialog";
import { ProvideFeedbackDialog } from "./dialogs/ProvideFeedbackDialog";
import { ProviderDetailDialog } from "./dialogs/ProviderDetailDialog";
import { ProviderSavingsDetailDialog } from "./dialogs/ProviderSavingsDetailDialog";
import { SuggestSimilarProvidersDialog } from "./dialogs/SuggestSimilarProvidersDialog";
import { UpdateInfoDialog } from "./dialogs/UpdateInfoDialog";
import { VerifyEmailDialog } from "./dialogs/VerifyEmailDialog";

const DialogContainer = () => {
	return (
		<>
			<DiagnosisDetailDialog />
			<MedicationDetailDialog />
			<ProviderDetailDialog />
			<HospitalDetailDialog />
			<MedicationSavingsDetailDialog />
			<ProviderSavingsDetailDialog />
			<CareMaintenanceSavingsDialog />
			<OptimizeCareRatingsDialog />
			<SuggestSimilarProvidersDialog />
			<CareAlertDialog />
			<InviteCareCollaboratorDialog />
			<ProvideFeedbackDialog />
			<UpdateInfoDialog />
			<AccarentAlertDialog />
			<ActivateAchievementsDialog />
			<ChangePasswordDialog />
			<VerifyEmailDialog />
			<AddCarePlanDialog />
		</>
	);
};

export { DialogContainer };
