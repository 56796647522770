import { Box, Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardOption } from "common/types/generic";
import { FindCareMenu } from "findCare/FindCareMenu";
import { useQ_retrieveSearchConditions } from "findCare/requests/useQ_retrieveSearchConditions";
import { useState } from "react";
import { FindCareConditionFilters } from "./FindCareConditionFilters";
import { FindCareConditionResults } from "./FindCareConditionResults";

const FindCareConditions = () => {
	const [conditionType, set_conditionType] = useState<StandardOption<string, string>>({ name: "All", value: "All" });

	const conditionResults = useQ_retrieveSearchConditions({ ICD10RangeCategory: conditionType.value });

	return (
		<Box>
			<FindCareMenu />
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<ContentSection title="Filters">
						<FindCareConditionFilters conditionType={conditionType} set_conditionType={set_conditionType} />
					</ContentSection>
				</Grid>
				<Grid item xs={12} md={8}>
					<ContentSection title="Results">
						<FindCareConditionResults conditionType={conditionType} conditionResults={conditionResults} />
					</ContentSection>
				</Grid>
			</Grid>
		</Box>
	);
};

export { FindCareConditions };
