import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { data_label_colors, palette } from "../../../theme";
import * as CSS from "csstype";
import { HorizontalAlignmentType, VerticalAlignmentType } from "recharts/types/component/DefaultLegendContent";
import { Typography } from "@mui/material";
import { useContext } from "react";
import { StyleContext } from "common/context/StyleContext";
import displayConverter, { DataDisplayType } from "common/utilities/displayConverter";

type StandardStackedBarChartProps = {
	data?: Array<{
		[key: string]: any;
	}>;
	xKey?: string;
	yKeys: Array<{ key: string; color?: CSS.Property.Color }>;
	legendAlign?: HorizontalAlignmentType;
	legendVerticalAlign?: VerticalAlignmentType;
	layout?: "horizontal" | "vertical";
	showGrid?: boolean;
	responsiveHeight?: number;
	minHeight?: number;
	stack?: string | false;
	keyLabels?: { [key: string]: string };
	keyFormats?: { [key: string]: DataDisplayType };
};

function StandardStackedBarChart(props: StandardStackedBarChartProps) {
	const { lastTriggered } = useContext(StyleContext);
	// console.debug(lastTriggered);
	return (
		<ResponsiveContainer key={lastTriggered} height={props.responsiveHeight} minHeight={props.minHeight ?? 200} width="100%">
			<BarChart data={props.data} layout={props.layout ?? "horizontal"}>
				{props.showGrid ? <CartesianGrid strokeDasharray="3 3" /> : null}
				{props.layout === "vertical" ? (
					<>
						<XAxis type="number" style={{ opacity: 0.3 }} />
						<YAxis type="category" dataKey={props.xKey} width={200} />
					</>
				) : (
					<>
						<XAxis dataKey={props.xKey} tickLine={false} style={{ fontWeight: 700, color: palette.gray.darker }} />
						<YAxis style={{ opacity: 0.3 }} />
					</>
				)}

				<Tooltip
					contentStyle={{ textTransform: "capitalize", fontWeight: 400 }}
					formatter={(value: string | number, key: string) => {
						if (props.keyFormats) {
							if (props.keyFormats[key]) {
								value = String(displayConverter.dataTypeConvert(props.keyFormats[key], value));
							} else if (props.keyFormats["*"]) {
								value = String(displayConverter.dataTypeConvert(props.keyFormats["*"], value));
							}
						}
						return [
							props.keyFormats && props.keyFormats[key] ? displayConverter.dataTypeConvert(props.keyFormats[key], value) : value,
							props.keyLabels && props.keyLabels[key] ? props.keyLabels[key] : key,
						];
					}}
				/>
				<Legend
					verticalAlign={props.legendVerticalAlign}
					align={props.legendAlign}
					iconType="circle"
					iconSize={24}
					formatter={(key: any) => {
						return (
							<Typography
								component="span"
								style={{
									textTransform: "capitalize",
									color: palette.gray.darker,
									height: "24px",
									position: "relative",
									top: "2px",
									fontWeight: 400,
								}}
							>
								{props.keyLabels && props.keyLabels[key] ? props.keyLabels[key] : key}
							</Typography>
						);
					}}
					// formatter={(key) => {
					//   return (
					//     <span style={{ color: palette.gray.darker, textTransform: "capitalize" }}>
					//       {props.keyLabels && props.keyLabels[key] ? props.keyLabels[key] : key}
					//     </span>
					//   );
					// }}
				/>
				{props.yKeys.map((yk, x) => {
					let color = palette.gray.dark;
					if (yk.color) {
						color = yk.color;
					} else if (data_label_colors.has(yk.key)) {
						color = data_label_colors.get(yk.key) as string;
					}
					return <Bar key={x} barSize={10} dataKey={yk.key} stackId={props.stack === false ? undefined : props.stack ?? "a"} fill={color} />;
				})}
			</BarChart>
		</ResponsiveContainer>
	);
}

export { StandardStackedBarChart };
