import { Navigate } from "react-router";
import { useAuthFlow } from "../../auth/useAuthFlow";

import SignUpForm from "account/forms/SignUpForm";
import { CariskConnectionAddonForm, CariskConnectFormValues } from "./CariskConnectionAddonForm";
import { useState } from "react";
import { FormValidationResponse } from "common/forms/formUtilities";
import { ModelsOperationStatus } from "api";

function CariskSignUpAndConnect() {
	const flow = useAuthFlow();

	const [cariskFormSend, set_cariskFormSend] = useState<(() => Promise<boolean | ModelsOperationStatus>) | undefined>();

	const [cariskFormValidate, set_cariskFormValidate] = useState<(() => FormValidationResponse<CariskConnectFormValues>) | undefined>();

	if (flow.step === "SignIn") {
		return (
			<SignUpForm
				onSignIn={flow.onSignIn}
				addonFormValidate={cariskFormValidate}
				addonFormSendBefore={cariskFormSend}
				onSignInTarget="/connect-healthplan/receive/healthplan:Carisk"
				additionalContent={
					<CariskConnectionAddonForm
						set_cariskFormSend={set_cariskFormSend}
						set_cariskFormValidate={set_cariskFormValidate}
						sendMode="LocalStorageDefer"
						displayType="Sub"
					/>
				}
			/>
		);
	}
	return <Navigate replace to="/" />;
}

export { CariskSignUpAndConnect };
