import { ModelsExplanationOfBenefit } from "api";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { StandardOption } from "common/types/generic";
import React, { useEffect, useMemo, useState } from "react";
import { Dispatch } from "react";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { Box, FormControlLabel, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import { StaticDataManager } from "common/managers/StaticDataManager";

type HealthSnapshotVisitsFilterProps = {
	set_filtered_visits: Dispatch<React.SetStateAction<ModelsExplanationOfBenefit[]>>;
	eob_visits: ModelsExplanationOfBenefit[];
};
const HealthSnapshotVisitsFilter = ({ set_filtered_visits, eob_visits }: HealthSnapshotVisitsFilterProps) => {
	const [selectedProviderOption, set_selectedProviderOption] = useState("");
	const [activeFilterType, set_activeFilterType] = useState<"PROVIDER" | "DIAGNOSIS" | "NONE">("NONE");
	const providerOptions = useMemo(() => {
		let npis = new Set<string>();
		let options: Array<StandardOption<string, string> & { [key: string]: any }> = [];
		eob_visits.forEach((vis) => {
			if (vis.providerNpi) {
				if (!npis.has(vis.providerNpi)) {
					npis.add(vis.providerNpi);
					let doc = DynamicDataManager.npis.doctorRecord_NPIMap.get(vis.providerNpi);
					if (doc) {
						let name = "";
						if (doc.firstName || doc.lastName) {
							if (doc.firstName) {
								name = doc.firstName + " ";
							}
							if (doc.lastName) {
								name += doc.lastName ?? "";
							}
						} else if (doc.organizationName) {
							name = doc.organizationName;
						}
						options.push({ name: name, value: vis.providerNpi, doc: doc });
					}
				}
			}
		});
		return options.sort((a, b) => {
			return a.doc?.lastName < b.doc?.lastName ? -1 : a.doc?.lastName > b.doc?.lastName ? 1 : 0;
		});
	}, [eob_visits]);

	const [selectedDiagnosisOption, set_selectedDiagnosisOption] = useState("");
	const diagnosisOptions = useMemo(() => {
		let icds = new Set<string>();
		let options: Array<StandardOption<string, string> & { [key: string]: any }> = [];
		eob_visits.forEach((vis) => {
			if (vis.diagnosisCodes) {
				vis.diagnosisCodes.forEach((icd) => {
					if (!icds.has(icd)) {
						icds.add(icd);
						let icd10cm = StaticDataManager.retrieveValue.icd10cm(icd);
						if (icd10cm) {
							options.push({ name: icd10cm.Description, value: icd, icd10cm: icd10cm });
						}
					}
				});
			}
		});
		return options.sort((a, b) => {
			return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
		});
	}, [eob_visits]);

	useEffect(() => {
		if (activeFilterType === "PROVIDER" && selectedProviderOption !== "") {
			set_filtered_visits(
				eob_visits.filter((v) => {
					if (v.providerNpi === selectedProviderOption) {
						return true;
					}
					return false;
				})
			);
		} else if (activeFilterType === "DIAGNOSIS" && selectedDiagnosisOption !== "") {
			set_filtered_visits(
				eob_visits.filter((v) => {
					if (Array.isArray(v.diagnosisCodes) && v.diagnosisCodes.indexOf(selectedDiagnosisOption) !== -1) {
						return true;
					}
					return false;
				})
			);
		} else {
			set_filtered_visits(eob_visits);
		}
	}, [eob_visits, selectedDiagnosisOption, diagnosisOptions, selectedProviderOption, providerOptions, activeFilterType, set_filtered_visits]);

	return (
		<>
			<RadioGroup
				defaultValue="NONE"
				value={activeFilterType}
				name="filter-type-select"
				row
				sx={{
					width: "100%",
					display: "flex",
					alignItems: "center",
					height: { xs: "auto", sm: "2rem" },
					justifyContent: { xs: "flex-start", sm: "flex-end" },
					opacity: activeFilterType === "NONE" ? 1 : 1,
				}}
			>
				<Typography sx={{ paddingRight: "1rem", fontWeight: 700 }}>Filter By:</Typography>

				<FormControlLabel
					value="PROVIDER"
					control={
						<Radio
							onClick={(_x: React.MouseEvent<HTMLButtonElement>) => {
								if (activeFilterType !== "PROVIDER") {
									set_activeFilterType("PROVIDER");
								} else {
									set_activeFilterType("NONE");
								}
							}}
						/>
					}
					label="Provider"
				/>
				<FormControlLabel
					value="DIAGNOSIS"
					control={
						<Radio
							onClick={(_x: React.MouseEvent<HTMLButtonElement>) => {
								if (activeFilterType !== "DIAGNOSIS") {
									set_activeFilterType("DIAGNOSIS");
								} else {
									set_activeFilterType("NONE");
								}
							}}
						/>
					}
					label="Diagnosis"
				/>
				<Box sx={{ width: { xs: "100%", sm: "auto" }, "& .MuiInput-root": { minWidth: { xs: "100%", sm: "10rem" } } }}>
					{activeFilterType === "PROVIDER" ? (
						<StandardSelect
							nullable
							onChange={(x: any, y: any) => {
								console.debug(x, y);
								let val = y.props.value;
								if (val === "CLEAR_SELECTION") {
									val = "";
								}
								set_selectedProviderOption(val);
							}}
							value={selectedProviderOption}
						>
							{providerOptions.map((x) => {
								return (
									<MenuItem key={x.value} value={x.value}>
										<Typography>{x.name}</Typography>
									</MenuItem>
								);
							})}
						</StandardSelect>
					) : null}
					{activeFilterType === "DIAGNOSIS" ? (
						<StandardSelect
							nullable
							onChange={(x: any, y: any) => {
								console.debug(x, y);
								let val = y.props.value;
								if (val === "CLEAR_SELECTION") {
									val = "";
								}
								set_selectedDiagnosisOption(val);
							}}
							value={selectedDiagnosisOption}
						>
							{diagnosisOptions.map((x) => {
								return (
									<MenuItem key={x.value} value={x.value}>
										<Typography>{x.name}</Typography>
									</MenuItem>
								);
							})}
						</StandardSelect>
					) : null}
				</Box>
			</RadioGroup>
		</>
	);
};

export { HealthSnapshotVisitsFilter };
