import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { entity_types } from "common/definitions/EntityDefinitions";
import displayConverter from "common/utilities/displayConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { palette } from "theme";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon } from "../Icons/HealthIcon";
import { ReactNode, useRef, useState } from "react";
import { ConsumerTreatmentRxSenseCouponAPIResponse, getCoupon, ModelsRxSenseTieredPricingPharmacyPricing } from "api";
import { SimpleModal } from "../Modals/SimpleModal";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { Loader } from "../Loaders/Loader";

interface MedicationPharmacyTileProps {
	RxSensePharmacyPricing: ModelsRxSenseTieredPricingPharmacyPricing;
}
const MedicationPharmacyTile = ({ RxSensePharmacyPricing }: MedicationPharmacyTileProps) => {
	const [modalOpen, set_modalOpen] = useState<boolean>(false);
	const [couponResponse, set_couponResponse] = useState<ConsumerTreatmentRxSenseCouponAPIResponse | null>(null);
	const entityType = entity_types["pharmacy"];
	let star_rating: null | ReactNode = null;

	const mainRef = useRef<HTMLDivElement>();
	const optionsRef = useRef<HTMLDivElement>();

	let { pharmacy, prices } = RxSensePharmacyPricing;
	let mainPrice = prices?.[0];

	if (!mainPrice) {
		return null;
	}
	return (
		<Box
			ref={mainRef}
			data-componentname="MedicationPharmacyTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
				<Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", flex: 1, width: { xs: "100%", sm: "auto" } }}>
					<AvatarDisplay
						imageAlt={RxSensePharmacyPricing.pharmacy?.name}
						imageSrc={RxSensePharmacyPricing.pharmacy?.logoUrl}
						icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={entityType.iconSVG} />}
						disableBlurImage
					/>
					<Box sx={{ paddingLeft: ".5rem" }}>
						<Typography sx={{ color: palette.gray.darker, fontSize: "1.25rem" }}>{pharmacy?.name}</Typography>
						<Typography
							sx={{ color: palette.gray.dark, fontWeight: 500, display: "none" }}
						>{`${pharmacy?.address?.address1} ${pharmacy?.address?.city} ${pharmacy?.address?.state} ${pharmacy?.address?.postalCode}`}</Typography>
						{star_rating}
					</Box>
				</Stack>
				<Box sx={{ width: { xs: "100%", sm: "auto" }, paddingTop: { xs: ".5rem", sm: "0" } }}>
					<Stack
						flexDirection={"row"}
						alignItems="center"
						sx={{
							border: `solid 2px ${palette.gray.main}`,
							backgroundColor: palette.core.lightBackground,
							padding: ".5rem",
							borderRadius: ".5rem",
							marginBottom: ".5rem",
							boxSizing: "border-box",
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Typography style={{ paddingRight: ".25rem" }}>Distance: </Typography>
						<Typography
							style={{
								fontSize: "1rem",
								color: palette.gray.darker,
								fontWeight: "500",
							}}
						>
							{Number(pharmacy?.distance).toFixed(2)}
						</Typography>
					</Stack>
					<Stack
						flexDirection={"row"}
						alignItems="center"
						sx={{
							border: `solid 2px ${palette.gray.main}`,
							backgroundColor: palette.core.lightBackground,
							padding: ".5rem",
							borderRadius: ".5rem",
							marginBottom: ".5rem",
							boxSizing: "border-box",
							width: { xs: "100%", sm: "auto" },
						}}
					>
						<Typography style={{ paddingRight: ".25rem" }}>Price:</Typography>{" "}
						<Typography
							style={{
								fontSize: "1rem",
								color: styleConverter.colorTypeAssign("USD"),
								fontWeight: "500",
							}}
						>
							{displayConverter.dataTypeConvert("USD", mainPrice.price ?? "?")}
						</Typography>
					</Stack>

					<Box ref={optionsRef} sx={{ width: { xs: "100%", sm: "auto" }, "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}>
						<Button
							onClick={async () => {
								set_modalOpen(true);
								let couponResponse = await getCoupon({
									partnerPassword: mainPrice?.partnerPassword,
									partnerUsername: mainPrice?.partnerUser,
								});
								if (!apiResponseReader.typeCheck_ModelsOperationStatus(couponResponse)) {
									if (couponResponse.response?.rxSenseCouponAPIResponse) {
										set_couponResponse(couponResponse.response.rxSenseCouponAPIResponse);
									}
								}
							}}
						>
							Get Coupon
						</Button>
					</Box>
				</Box>
			</Stack>

			{mainPrice ? (
				<SimpleModal
					open={modalOpen}
					onClose={async () => {
						console.debug("closed modal");
						set_modalOpen(false);
					}}
				>
					<Box>
						{couponResponse && couponResponse.contacts && couponResponse.contacts.length > 0 ? (
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<Typography variant="h4" sx={{ marginBottom: "0" }}>
										Auth:
									</Typography>
									<Typography>{couponResponse.contacts[0].memberNumber}</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography variant="h4" sx={{ marginBottom: "0" }}>
										Bin:
									</Typography>
									<Typography>{couponResponse.contacts[0].bin}</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography variant="h4" sx={{ marginBottom: "0" }}>
										Group:
									</Typography>
									<Typography>{couponResponse.contacts[0].groupNumber}</Typography>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Typography variant="h4" sx={{ marginBottom: "0" }}>
										PCN:
									</Typography>
									<Typography>{couponResponse.contacts[0].pcn}</Typography>
								</Grid>
								<Grid item xs={12} sx={{ textAlign: "center" }}>
									<Link href={couponResponse.contacts[0].pdfUrl} target="_blank">
										View PDF
									</Link>
								</Grid>
							</Grid>
						) : (
							<Loader />
						)}
					</Box>
				</SimpleModal>
			) : null}
		</Box>
	);
};

export { MedicationPharmacyTile };
