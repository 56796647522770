import Box from "@mui/material/Box";
import { editConsumerUser } from "api";
import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { AppBar, Container, FormControl, InputLabel, MenuItem, Select, Stack, Typography, Alert } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { style } from "theme";
import { useContext, useMemo } from "react";
import { useQueryClient } from "react-query";
import { AccountManagerContext } from "common/managers/AccountManager";
// import { DatePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { StandardDatePicker } from "common/components/Inputs/StandardDatePicker";

interface ActivateAchievementsForm {
	gender?: string;
	dateofbirth?: any;
}

function ActivateAchievements() {
	const navigate = useNavigate();
	const accountContext = useContext(AccountManagerContext);
	const queryClient = useQueryClient(); // not having this here breaks the invalidateQueries
	const [consumerUser, mostRecentPatientDemographic] = useMemo(() => {
		let userDetails = accountContext.accountManager.userDetails;
		return [userDetails?.consumerUser, userDetails?.patientDemographics ? userDetails?.patientDemographics[0] : undefined];
	}, [accountContext.accountManager.userDetails]);

	const { handleSubmit, control } = useForm<ActivateAchievementsForm>({
		mode: "onChange",
		defaultValues: {
			gender:
				mostRecentPatientDemographic?.gender === "Male" || mostRecentPatientDemographic?.gender === "Female"
					? mostRecentPatientDemographic.gender
					: consumerUser?.gender ?? "",
			dateofbirth:
				mostRecentPatientDemographic &&
				mostRecentPatientDemographic?.birth_date_string !== "" &&
				dayjs(mostRecentPatientDemographic?.birth_date_string)?.year() > 1
					? dayjs(mostRecentPatientDemographic?.birth_date_string)
					: dayjs(consumerUser?.dateOfBirthString),
		},
	});
	const { enqueueSnackbar } = useSnackbar();
	const onSubmit = (data: ActivateAchievementsForm) => {
		console.log("DATA", data);
		queryClient?.invalidateQueries("retrieveRecommendedUSPSTF");
		queryClient?.invalidateQueries("useKeyFindings");
		return editConsumerUser({
			gender: data.gender,
			dateOfBirth: dayjs(data.dateofbirth).format(),
		}).then(successCallback, failureCallback);
	};

	function successCallback() {
		enqueueSnackbar("Info saved", {
			variant: "success",
		});
		accountContext.accountManager.setUser("REFRESH");
		DialogManager.close("activate-achievements");
		navigate("/dashboard/achievements");
	}

	function failureCallback() {
		enqueueSnackbar("Error while saving changes", {
			variant: "error",
		});
	}

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Activate Achievements</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("activate-achievements");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
				<Container maxWidth={"xs"}>
					<Box onSubmit={handleSubmit(onSubmit)} component="form">
						<ContentSection flexGrow={1} title="">
							<Typography>
								Care Achievements help you stay on track with your health. Recommendations for preventative services include screenings, tests
								and/or vaccinations. To unlock your Healthcare Achievements, please complete the form below.
							</Typography>
							<Box sx={{ pt: 2 }}>
								<InputLabel variant="standard" sx={{ fontSize: ".75rem" }}>
									Enter Date of Birth
								</InputLabel>
								<Controller
									name="dateofbirth"
									control={control}
									rules={{
										validate: { validdate: (v) => (dayjs(v).isValid() ? true : "Date of Birth required") },
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<>
											{/* <DatePicker
												value={value}
												onChange={(...args) => {
													onChange(...args);
												}}
												disableFuture
												sx={{ width: "100%", borderBottom: ".125rem solid" }}
											/> */}
											<StandardDatePicker
												value={value}
												onChange={(...args) => {
													onChange(...args);
												}}
												disableFuture
												sx={{ width: "100%", borderBottom: ".125rem solid" }}
											/>
											{error && (
												<Alert severity="error" sx={{ mt: 1 }}>
													{error.message}
												</Alert>
											)}
										</>
									)}
								/>
							</Box>
							<Box sx={{ pt: 1 }}>
								<FormControl fullWidth>
									<InputLabel variant="standard">Enter Gender</InputLabel>
									<Controller
										name="gender"
										control={control}
										rules={{ required: "Gender required" }}
										render={({ field: { onChange, value }, fieldState: { error } }) => (
											<>
												<Select
													name="gender"
													value={value}
													variant="standard"
													sx={{ color: "black", paddingLeft: ".5rem" }}
													onChange={onChange}
												>
													<MenuItem value={"Male"}>Male</MenuItem>
													<MenuItem value={"Female"}>Female</MenuItem>
												</Select>

												{error && (
													<Alert severity="error" sx={{ mt: 1 }}>
														{error.message}
													</Alert>
												)}
											</>
										)}
									/>
								</FormControl>
							</Box>

							<Box sx={{ paddingTop: "1rem", display: "flex", justifyContent: "flex-end" }}>
								<FormSubmitButton>Activate</FormSubmitButton>
							</Box>
						</ContentSection>
					</Box>
				</Container>
			</Stack>
		</>
	);
}

export default ActivateAchievements;
