import {
	ConsumerDoctorGetDoctorsByLocationResponseModel,
	ConsumerDoctorGetDoctorsByLocationResult,
	getDoctorsByLocation,
	GetDoctorsByLocationParams,
	ModelsClientLocation,
	ModelsDoctorRecord,
} from "api";
import { CONVERT_DoctorRecord_Entity, ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { useQuery, UseQueryResult } from "react-query";

type DoctorsByLocationData = ConsumerDoctorGetDoctorsByLocationResponseModel & {
	providerEntities: Array<ProviderEntity>;
};

type GetDoctorsByLocationParamsX = {
	params: GetDoctorsByLocationParams;
	clientLocation: ModelsClientLocation;
	noRequest?: boolean;
};

const Process_DoctorsZipCoordinateResult = (doctorRecords: Array<ModelsDoctorRecord>) => {
	return doctorRecords.map((doctor) => {
		return CONVERT_DoctorRecord_Entity(doctor, doctor?.zip ? [{ title: "Distance", value: doctor.distanceMiles ?? "?" }] : undefined);
	});
};

async function retrieveProvidersByLocation(pX: GetDoctorsByLocationParamsX): Promise<ProcessedResponse<DoctorsByLocationData | null>> {
	if (pX.noRequest) {
		return apiResponseReader.createProcessedResponse_NOREQUEST("NO REQUEST");
	}
	if (!pX.clientLocation.latitude && !pX.clientLocation.longitude && !pX.clientLocation.zipcode) {
		// console.debug("NO LOCATION");
		return apiResponseReader.createProcessedResponse_NOREQUEST("Determining your Location");
	}
	// console.debug("pX.params", pX.params);
	let result = await getDoctorsByLocation(pX.clientLocation, pX.params).then(
		(data: ConsumerDoctorGetDoctorsByLocationResult): ProcessedResponse<DoctorsByLocationData | null> => {
			// console.debug("getDoctorsByLocation", data);
			if (data) {
				if (data.response) {
					const providerEntities: Array<ProviderEntity> = [];
					providerEntities.push(...Process_DoctorsZipCoordinateResult(data.response.doctorRecords));
					const doctorsByLocationData: DoctorsByLocationData = Object.assign({ providerEntities }, data.response);
					let x = apiResponseReader.processResponse(doctorsByLocationData);
					// console.debug({ x });
					return x;
				}
				if (apiResponseReader.typeCheck_ModelsOperationStatus(data)) {
					// console.debug("MODELOPSTAT!!!!");
					return apiResponseReader.processResponse<null>(data as CIM);
				}
			}
			return apiResponseReader.createProcessedResponse_FAIL("An Error Occurred");
		}
	);

	return result;
}

function useQ_retrieveProvidersByLocation(pX: GetDoctorsByLocationParamsX): UseQueryResult<ProcessedResponse<DoctorsByLocationData | null>> {
	return useQuery(["retrieveProvidersByLocation", `retrieveProvidersByLocation:${JSON.stringify(pX)}`], retrieveProvidersByLocation.bind(null, pX), {
		staleTime: Infinity,
		cacheTime: Infinity,
	});
}

export { useQ_retrieveProvidersByLocation, Process_DoctorsZipCoordinateResult };
export type { DoctorsByLocationData, GetDoctorsByLocationParamsX };
