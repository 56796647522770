import { Box, Button, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import AccountPageWrapper from "account/components/AccountPageWrapper";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { useNavigate } from "react-router-dom";
import { palette, theme } from "theme";

function CariskSuccess({ useAccountPageWrapper }: { useAccountPageWrapper: boolean }) {
	const navigate = useNavigate();

	const content = (
		<Container maxWidth="md" sx={{ py: 14 }}>
			<Card elevation={4}>
				<CardContent>
					<Box sx={{ p: 1 }}>
						<Box sx={{ textAlign: "center" }}>
							<Box
								component={"img"}
								alt="radius care logo"
								src={"/images/carisk-partners-logo.png"}
								sx={{
									[theme.breakpoints.down("sm")]: {
										height: "5rem",
									},
									[theme.breakpoints.up("sm")]: {
										height: "7.5rem",
									},
									mb: 2,
								}}
							></Box>
							<Typography variant="h1" color={palette.core.darkblue}>
								Welcome to your Carisk Partners Benefits!
							</Typography>
							<Typography variant="h3" pt={2} mb={1} sx={{ fontWeight: 700 }}>
								Radius Care is your Healthcare Companion!
							</Typography>
							<Typography variant="h4" sx={{ fontWeight: 400 }}>
								Let’s Get Started…What do you want to do?
							</Typography>
						</Box>
						<Grid container spacing={1}>
							<Grid item xs={12} md={4} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										mt: 1,
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.blue} size={48} iconSVG={health_icons_svg.home_alt} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center" }}>
											Learn your total care score, key findings, and reminders.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/dashboard/myoverview");
										}}
										sx={{minWidth: "12rem"}}
									>
										Your Dashboard
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										mt: 1,
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.blue} size={48} iconSVG={health_icons_svg.cardiogram} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center", width: "100%" }}>
											See your doctor visits, your care team, diagnoses, and medications.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/healthsnapshot/visits");
										}}
										sx={{minWidth: "12rem"}}
									>
										Health Snapshot
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} md={4} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										mt: 1,
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.blue} size={48} iconSVG={health_icons_svg.magnifyingglass} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center" }}>
											Search for doctors within your radius to find a suitable care provider.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/findcare/providers");
										}}
										sx={{minWidth: "12rem"}}
									>
										Find Care
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</Card>
		</Container>
	);

	if (useAccountPageWrapper) {
		return <AccountPageWrapper>{content}</AccountPageWrapper>;
	}
	return content;
}

export { CariskSuccess };
