import { Typography } from "@mui/material";
import { ConsumerGetConditionDetailResponseModel, ModelsInfoCategory, ModelsSiteList } from "api";
import { ContentSection } from "common/components/Containers/ContentSection";
import { ReactNode, useMemo } from "react";

type DiagnosisDetailsTabProps = {
	queryData?: ConsumerGetConditionDetailResponseModel | null;
	medlineErrorRefreshButton: ReactNode;
};
const DiagnosisDetailsTab = ({ queryData, medlineErrorRefreshButton }: DiagnosisDetailsTabProps) => {
	const details = useMemo(() => {
		if (queryData && Array.isArray(queryData.icd10Cm.details) && queryData.icd10Cm.details.length) {
			return queryData.icd10Cm.details;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [queryData, queryData?.icd10Cm]);

	return (
		<ContentSection title="Details">
			{details ? (
				details.map((x: ModelsInfoCategory, i) => {
					return (
						<span key={i}>
							<h3 style={{ margin: 0 }}>{x.name}</h3>
							<ul>
								{x?.siteLists?.map((site: ModelsSiteList, ii) => {
									const organization = site.organization;
									const orgDisplay = organization === null ? null : <>({organization})</>;

									return (
										<li key={ii}>
											<Typography sx={{ paddingBottom: ".25rem" }}>
												<a rel="noopener noreferrer" target="_blank" href={site.url}>
													{site.name}
												</a>
												{orgDisplay}
												{site.languageMappedUrl && (
													<a rel="noopener noreferrer" target="_blank" href={site.languageMappedUrl}>
														(Spanish/Español)
													</a>
												)}
											</Typography>
										</li>
									);
								})}
							</ul>
						</span>
					);
				})
			) : (
				<Typography>[Additional Information Unavailable]</Typography>
			)}
			{/* {medlineErrorRefreshButton} */}
			{queryData?.icd10Cm.medlineSummaryRequestFailed === true && queryData?.icd10Cm?.details ? medlineErrorRefreshButton : null}
		</ContentSection>
	);
};

export { DiagnosisDetailsTab };
