import { SwipeableDrawer, MenuItem, Box, Typography, Button, Container, Stack } from "@mui/material";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { special_instructions } from "common/components/Redirecter/Redirecter";
import { StaticDataDefinitionsContext } from "common/context/MainContext";
import { useContext, useState } from "react";

import { palette, style } from "theme";

type TopSearchDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => any;
	offset: number;
};

type TopSearchDrawerSearchType = "Doctors" | "Facilities" | "";
const TopSearchDrawer = (props: TopSearchDrawerProps) => {
	const SDDC = useContext(StaticDataDefinitionsContext);

	const [searchType, setSearchType] = useState<TopSearchDrawerSearchType>("Doctors");

	const [doctorSpecialtyValue, set_doctorSpecialtyValue] = useState<string>("");
	const [facilityTypeValue, set_facilityTypeValue] = useState<string>("");
	const updateSearchType = (x: TopSearchDrawerSearchType) => {
		set_doctorSpecialtyValue("");
		set_facilityTypeValue("");
		setSearchType(x);
	};

	const performSearchRedirect = () => {
		let type2 = searchType === "Doctors" ? doctorSpecialtyValue : facilityTypeValue;
		let urlGet = new URLSearchParams({ type: searchType, type2 }).toString();
		// special_instructions.redirect(`/findcare/providers?type=${searchType}&type2=${type2}`);
		special_instructions.redirect(`/findcare/providers?${urlGet}`);
	};

	return (
		<SwipeableDrawer
			anchor={"top"}
			variant="persistent"
			open={props.open}
			onClose={() => {
				props.setOpen(false);
			}}
			onOpen={() => {
				props.setOpen(true);
			}}
			sx={{
				zIndex: 3,
				"& .MuiDrawer-paper": {
					boxShadow: 4,
					borderBottom: "none",
				},
			}}
		>
			<Box
				style={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "center",
					alignItems: "center",
					width: "100%",
					paddingTop: style.headerHeight + props.offset + "px",
					backgroundColor: palette.core.lightBackground,
				}}
			>
				<Container maxWidth="md" sx={{ paddingY: "2rem" }}>
					<Stack sx={{ width: "100%", flexDirection: { xs: "column", sm: "row" } }}>
						<Box sx={{ flex: 1, p: 1 }}>
							<Typography sx={{ fontWeight: 700 }}>Search For?</Typography>
							<StandardSelect
								variant="standard"
								onChange={(x) => {
									updateSearchType(x.target.value as TopSearchDrawerSearchType);
								}}
								value={searchType}
							>
								<MenuItem value={"Doctors"}>Doctors</MenuItem>
								<MenuItem value={"Facilities"}>Facilities</MenuItem>
								{/* <MenuItem value={"providers"}>Providers</MenuItem>
							<MenuItem value={"healthplans"}>Health Plans</MenuItem>
							<MenuItem value={"pharmacies"}>Pharmacies</MenuItem> */}
							</StandardSelect>
						</Box>

						{searchType === "Doctors" ? (
							<Box sx={{ flex: 1, p: 1 }}>
								<Typography id="specialty-select-label" sx={{ fontWeight: 700 }}>
									Specialty
								</Typography>
								<StandardSelect
									nullable
									labelId="specialty-select-label"
									id="specialty-select"
									variant="standard"
									value={doctorSpecialtyValue ?? ""}
									onChange={(x) => {
										let v: string | undefined;
										if (x.target.value) {
											v = String(x.target.value);
											set_doctorSpecialtyValue(v);
										} else {
											set_doctorSpecialtyValue("");
										}
									}}
								>
									{SDDC.providerTaxonomyPackage?.Specialization_Values?.map((spec, i) => {
										return (
											<MenuItem key={i} value={spec}>
												{spec}
											</MenuItem>
										);
									})}
								</StandardSelect>
							</Box>
						) : null}
						{searchType === "Facilities" ? (
							<Box sx={{ flex: 1, p: 1 }}>
								<Typography id="facilityTypes-select-label" sx={{ fontWeight: 700 }}>
									Facility Type
								</Typography>
								<StandardSelect
									nullable={true}
									labelId="condition-select-label"
									id="condition-select"
									variant="standard"
									value={facilityTypeValue ?? ""}
									onChange={(x) => {
										console.debug(x);
										set_facilityTypeValue((x.target.value as string) ?? "");
									}}
								>
									{SDDC.facilityTypes.map((item, i) => {
										return (
											<MenuItem key={i} value={item.value}>
												{item.name}
											</MenuItem>
										);
									})}
								</StandardSelect>
							</Box>
						) : null}
						<Box
							sx={{
								p: 1,
								width: { xs: "100%", sm: "auto" },
								"& .MuiButton-root": { width: { xs: "100%", sm: "auto" } },
								boxSizing: "border-box",
							}}
						>
							<Button onClick={performSearchRedirect}>Search</Button>
						</Box>
					</Stack>
				</Container>
			</Box>
		</SwipeableDrawer>
	);
};

export { TopSearchDrawer };
