import { ConsumerNameValue } from "api";

const FormValidater = {
	email: (val: string, message: string | false = false) => {
		return val.indexOf("@") === -1 ? message : true;
	},
	password: (_val: string, _message: string | false = false) => {
		// not implemented

		return true;
	},
	regexCheck: (_val: string, regexes: Array<ConsumerNameValue>, _message: string | false = false) => {
		// console.group("regexCheck");
		let response: true | string = true;
		regexes.some((reg) => {
			let regExp = new RegExp(reg.value, "g");
			let result = regExp.exec(_val);
			if (result === null) {
				// console.debug("failed regex", reg);
				response = reg.name;
				return true;
			}
			return false;
		});
		// console.groupEnd();
		return response;
	},
};

export { FormValidater };
