import { Box, Typography, Button } from "@mui/material";
import { ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel } from "api";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext, useMemo } from "react";
import { dateConverter } from "common/utilities/dateConverter";
import { ConsumerRetrievePatientHealthDataResponseModel_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { DataSourceID, getDatasourceDefinitionByDataSourceID } from "common/definitions/DataSourceDefinitions";
import { event_actions } from "common/definitions/EventDefinitions";
import { useNavigate } from "react-router-dom";
const HealthPlanReauthTag = ({ eobData }: { eobData: ConsumerRetrievePatientHealthDataResponseModel_Extended | null }) => {
	const account = useContext(AccountManagerContext);

	let lastRecordDate = useMemo(() => {
		if (eobData) {
			if (eobData.visitRecordIDs.length && eobData.EOB_RecordID_Map) {
				let x = eobData.EOB_RecordID_Map.get(eobData.visitRecordIDs[0]);
				if (x && x.date) {
					return dateConverter["YYYY-MM-DD_to_mM/dD/YY"](x.date, true);
				}
			}
		}
		return null;
	}, [eobData]);

	const resyncHealthplans: ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel[] | null = useMemo(() => {
		if (account.accountManager.userDetails?.patientDataAuthorizations) {
			return account.accountManager.userDetails.patientDataAuthorizations.filter((x) => x.reauthorizationRequired);
		}
		return null;
	}, [account.accountManager.userDetails]);

	const mostRecentLoadTime = useMemo(() => {
		return eobData?.overview.mostRecentLoadTime;
	}, [eobData]);

	const navigate = useNavigate();

	const syncNowReauth = useMemo(() => {
		if (resyncHealthplans && resyncHealthplans.length > 0) {
			let datasourceDefinition = getDatasourceDefinitionByDataSourceID(resyncHealthplans[0].dataSourceID as DataSourceID);
			return (
				<>
					-
					<Button
						variant={"clickable-text-button"}
						onClick={
							datasourceDefinition
								? () => {
										event_actions.reauthorize_datasource.action(null, { id: resyncHealthplans[0].dataSourceID as DataSourceID });
								  }
								: () => {
										navigate("/account");
								  }
						}
					>
						Sync Now
					</Button>
				</>
			);
		}
		return null;
	}, [resyncHealthplans, navigate]);

	if (mostRecentLoadTime) {
		return (
			<Box>
				<Typography component="div">
					Last Record Update: {dateConverter["YYYY-MM-DD_to_mM/dD/YY"](mostRecentLoadTime, true)} {syncNowReauth}
				</Typography>
			</Box>
		);
	}

	if (lastRecordDate) {
		return (
			<Box>
				<Typography component="div">
					Last Record Date: {lastRecordDate} {syncNowReauth}
				</Typography>
			</Box>
		);
	}

	if (syncNowReauth) {
		return (
			<Box>
				<Typography component="div">{syncNowReauth}</Typography>
			</Box>
		);
	}
	return null;
};

export { HealthPlanReauthTag };
