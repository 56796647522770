import { Container, Grid } from "@mui/material";
import { useContext, useMemo } from "react";
import { AccountManagerContext } from "common/managers/AccountManager";
import { ExistingCareCollaborations } from "account/pages/myProfile/CareCollaborators/ExistingCareCollaborations";

function CareCollaborators() {
	const accountContext = useContext(AccountManagerContext);

	const consumerUser = useMemo(() => {
		let userDetails = accountContext.accountManager.userDetails;
		return userDetails?.consumerUser;
	}, [accountContext.accountManager.userDetails]);

	// const { enqueueSnackbar } = useSnackbar();

	if (!consumerUser) {
		return null;
	}
	console.debug(accountContext.careCollaborationUserDetails);
	return (
		<Container maxWidth={"md"}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<ExistingCareCollaborations displayLocation="profile" noContentSectionContainers={false} />
				</Grid>
			</Grid>
		</Container>
	);
}

export { CareCollaborators };
