import { ContentSection } from "common/components/Containers/ContentSection";
import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medicationDetails/requests/useQ_getDrugStructureData";
// import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medication/requests/useQ_getDrugStructureData";
import { MDP_Pharmacies as MDPPharmacies } from "./MDP_Pharmacies";

type SingleCareMedicationDetailProps = {
	drugStructureData: MedicationDetailDataPackage | null;
	medicationDetailItem: SingleCareMedicationDetail | null;
	set_medicationDetailItem: React.Dispatch<React.SetStateAction<null | SingleCareMedicationDetail>>;
};

const MedicationDetailPricing = ({ drugStructureData, medicationDetailItem, set_medicationDetailItem }: SingleCareMedicationDetailProps) => {
	return (
		<ContentSection flexGrow={2} title="Pharmacies">
			{drugStructureData ? (
				<MDPPharmacies
					drugStructureData={drugStructureData}
					medicationDetailItem={medicationDetailItem}
					set_medicationDetailItem={set_medicationDetailItem}
				/>
			) : null}
		</ContentSection>
	);
};

export { MedicationDetailPricing };
