import { SwipeableDrawer, Stack, Typography, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { palette, style } from "theme";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext } from "react";
import { UseQueryResult } from "react-query";
import { ProcessedResponse } from "common/utilities/apiResponseReader";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { ConsumerRetrievePatientHealthDataResponseModel_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { CarePlans } from "../../../account/pages/myProfile/UserDetails/CarePlans";
import { BasicTileX } from "common/components/Tiles/BasicTile";
import { SectionLabel } from "common/components/Labels/SectionLabel";
import { DialogManager } from "common/managers/DialogManager";
import { AcceptedCareCollaborations } from "account/pages/myProfile/CareCollaborators/AcceptedCareCollaborations";

type RightAccountDrawerProps = {
	open: boolean;
	setOpen: (open: boolean) => any;
	patientEOBData: UseQueryResult<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>, unknown>;
	closeWingsIfMinScreenWidth: () => void;
	offset: number;
};

const RightAccountDrawer = ({ open, setOpen, patientEOBData, closeWingsIfMinScreenWidth, offset }: RightAccountDrawerProps) => {
	const account = useContext(AccountManagerContext);
	const navigate = useNavigate();

	return (
		<SwipeableDrawer
			anchor={"right"}
			variant="persistent"
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			onOpen={() => {
				setOpen(true);
			}}
			sx={{
				zIndex: 2,
				"& .MuiDrawer-paper": {
					boxShadow: 4,
					borderLeft: "none",
				},
			}}
		>
			<Stack
				sx={{
					zIndex: 2,
					height: "100%",
					width: { xs: "100%", sm: style.rightAccountWidth },
					backgroundColor: palette.core.lightBackground,
					paddingTop: style.headerHeight + offset + "px",
				}}
			>
				<Box sx={{ p: 2 }}>
					<Box sx={{ textAlign: "center", pb: 2 }}>
						<Typography sx={{ fontWeight: 700 }}>Hi, {account.username}</Typography>
					</Box>

					<QueryStatusDisplay queryResult={patientEOBData}>
						{patientEOBData?.data?.data ? (
							<>
								<Stack flexDirection={"row"}>
									<BasicTileX link="/healthsnapshot/conditions" type="condition" value={patientEOBData.data.data.overview.conditionCount} />
									<BasicTileX link="/healthsnapshot/medication" type="medication" value={patientEOBData.data.data.overview.medicationCount} />
								</Stack>
								<Box sx={{ textAlign: "center" }}>
									<Typography variant="subsection-title">Past 12 Months</Typography>
								</Box>
							</>
						) : null}
					</QueryStatusDisplay>
					<Stack sx={{ pt: 2 }}>
						<Button
							onClick={() => {
								closeWingsIfMinScreenWidth();
								navigate("/account");
							}}
						>
							Manage Profile
						</Button>
					</Stack>
				</Box>

				<Box sx={{ marginTop: 1 }}>
					<SectionLabel>Insurance Plans</SectionLabel>
					<Box sx={{ opacity: account.careCollaborationUserDetails?.consumerUserDetails?.patientDemographics ? 0.5 : 1, p: 2 }}>
						<Stack>
							<CarePlans
								patientDataAuthorizations={account.accountManager.userDetails?.patientDataAuthorizations}
								patientDemographics={account.accountManager.userDetails?.patientDemographics}
							/>
						</Stack>
						{account.careCollaborationUserDetails?.activeCareCollaboratorAuthorization ? null : (
							<Stack sx={{ pt: 2 }}>
								<Button
									sx={{ width: { xs: "100%", sm: "auto" } }}
									onClick={() => {
										DialogManager.open("add-care-plan");
									}}
								>
									Add Insurance Plan
								</Button>
							</Stack>
						)}
					</Box>

					<Box>
						<SectionLabel>Care Collaboration Accounts</SectionLabel>
						<Box sx={{ p: 2 }}>
							<Box
								sx={{
									"& .care-collaborator-tile": {},
									"& .care-collaborator-actions": { width: "100%", pt: 1 },
								}}
							>
								{/* <ReceivedCareCollaborations allowRemove={false} showPending={false} showRevoked={false} showRemoved={false} showTitle={false} /> */}
								<AcceptedCareCollaborations allowRemove={false} />
							</Box>
							<Stack sx={{ pt: 1 }}>
								<Button onClick={() => navigate("/account/CareCollaborators")}>Manage Collaborators</Button>
							</Stack>
						</Box>
					</Box>
				</Box>
			</Stack>
			{/* <button
				style={{ opacity: 0.1 }}
				onClick={async () => {
					if (s === "") {
					} else {
						let r = await getConfigDebugReport({ s: s });
						set_s("");
						console.debug(r);
					}
				}}
				onKeyPress={(x: any) => {
					if (x.key) {
						console.debug(s + x.key);
						set_s(s + x.key);
					}
				}}
			>
				test
			</button> */}
		</SwipeableDrawer>
	);
};

export { RightAccountDrawer };
