import { Entity } from "../EntityDefinitions";
import { ModelsICD10CM } from "../../../api/backend.schemas";
import { ICD10 } from "common/requests/codes_and_definitions/useQ_staticDataDefinitions";

interface ConditionEntity extends Entity {
	entityTypeName: "condition";
	_conditionRecord?: ModelsICD10CM | ICD10;
}

const isConditionEntity = (x: Entity): x is ConditionEntity => {
	if (x.entityTypeName === "condition") {
		return true;
	}
	return false;
};

export { isConditionEntity };
export type { ConditionEntity };
