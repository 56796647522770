import React from "react";
import { Navigate } from "react-router";
import useQueryString from "common/useQueryString";
import { useResetPasswordFlow } from "../../../auth/useForgotPasswordFlow";
import ResetPasswordForm from "./ResetPasswordForm";

function ResetPasswordPage() {
  const { query } = useQueryString();
  const username = query.get("username");
  const code = query.get("code") ?? undefined;
  console.log(code);
  if (!username) throw new Error("Username is required.");

  const flow = useResetPasswordFlow(username);

  switch (flow.step) {
    case "ResetPasswordStep":
      return <ResetPasswordForm {...flow} />;

    case "ForgotPasswordCompleteStep":
      return <Navigate replace to="/" />;

    default:
      return <Navigate replace to="/forgot-password" />;
  }
}

export default ResetPasswordPage;
