import { Box, Stack, Typography } from "@mui/material";
import { Loader } from "common/components/Loaders/Loader";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext, useMemo } from "react";
import { ReceivedCareColloboraterAuth } from "./ReceivedCareColloboraterAuth";
import { CareCollaboratorAuthorization } from "api";
import { palette } from "theme";

type ReceivedCareCollaborationsProps = {
	showAccepted?: boolean;
	showRevoked?: boolean;
	showCurrentlySelected?: boolean;
	showPending?: boolean;
	showRemoved?: boolean;
	allowRemove: boolean;
};
function ReceivedCareCollaborations({
	allowRemove,
	showAccepted = false,
	showCurrentlySelected = true,
	showPending = true,
	showRevoked = true,
	showRemoved = true,
}: ReceivedCareCollaborationsProps) {
	const accountContext = useContext(AccountManagerContext);

	const receivedContent = useMemo(() => {
		if (!accountContext?.careCollaborations?.received) {
			return null;
		}

		let accepted: Array<CareCollaboratorAuthorization> = [];
		if (accountContext.careCollaborations.receivedAccepted && showAccepted) {
			accepted = accountContext.careCollaborations.receivedAccepted;
			if (!showCurrentlySelected) {
				accepted = [];
				accountContext.careCollaborations.receivedAccepted.forEach((x) => {
					if (!x.isCurrentlySelectedByCollaborator) {
						accepted.push(x);
					}
				});
				console.debug({ accepted });
			}
		}

		let pending: Array<CareCollaboratorAuthorization> = [];
		if (showPending) {
			if (accountContext.careCollaborations.receivedPending) {
				pending = accountContext.careCollaborations.receivedPending;
			}
		}

		let revoked: Array<CareCollaboratorAuthorization> = [];
		if (showRevoked) {
			if (accountContext.careCollaborations.receivedRevoked) {
				revoked = accountContext.careCollaborations.receivedRevoked;
			}
		}

		let removed: Array<CareCollaboratorAuthorization> = [];
		if (showRemoved) {
			if (accountContext.careCollaborations.receivedRemoved) {
				removed = accountContext.careCollaborations.receivedRemoved;
			}
		}
		return [...accepted, ...pending, ...revoked, ...removed].map((cc) => {
			return (
				<Box sx={{ mb: 1 }} key={cc.id}>
					<ReceivedCareColloboraterAuth careCollaboratorAuthorization={cc} allowRemove={allowRemove} />
				</Box>
			);
		});
	}, [accountContext.careCollaborations, showPending, showRevoked, showRemoved, allowRemove, showAccepted, showCurrentlySelected]);

	return accountContext.loadingCollaborators ? (
		<Loader />
	) : (
		<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", "& > .MuiBox-root:not(:last-child)": { marginBottom: "8px" } }}>
			{accountContext.careCollaborations && receivedContent && receivedContent.length > 0 ? (
				receivedContent
			) : (
				<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
					<Typography>No Current Invitations</Typography>
				</Stack>
			)}
		</Box>
	);
}

export { ReceivedCareCollaborations };
