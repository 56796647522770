import { Routes, Route, Navigate } from "react-router-dom";
import { AccountRoutes } from "./account/router/AccountRoutes";
import SignOutPage from "account/pages/public/SignOutPage";
import { DashboardRoutes } from "dashboard/router/DashboardRoutes";
import { FindCareRoutes } from "findCare/router/FindCareRoutes";
import { ConnectHealthPlanRouter } from "account/router/ConnectHealthPlanRouter";
import { Redirecter, special_instructions } from "common/components/Redirecter/Redirecter";
import CodeVerificationPage from "account/pages/public/CodeVerificationPage";
import AccountPageWrapper from "account/components/AccountPageWrapper";
import { useContext, useState, PropsWithChildren } from "react";
import { MainLayout } from "main/MainLayout";
import { AccountManagerContext } from "common/managers/AccountManager";
import { FullscreenLoader } from "common/components/Loaders/FullscreenLoader";
import { HealthSnapshotRoutes } from "healthSnapshot/router/HealthSnapshotRoutes";
import { AccountActive } from "account/pages/connectHealthPlan/_common/AccountActive";
import DeveloperRouter from "./developer/DeveloperRouter";
// import { FindCareDemo } from "findCare/demo/FindCareDemo";
function RouterMain() {
	const [forceRerender, setForceRerender] = useState(0);
	special_instructions.force = () => {
		setForceRerender(forceRerender + 1);
	};
	const user = useContext(AccountManagerContext);

	if (special_instructions.target) {
		return <Redirecter force={special_instructions.force} />;
	}

	if (!user) {
		return <FullscreenLoader />;
	}

	return (
		<Routes>
			<Route path="/connect-healthplan/*" element={<ConnectHealthPlanRouter />} />
			<Route path="/account-active/" element={<AccountActive useAccountPageWrapper={true} />} />
			<Route
				path="/code-verification/*"
				element={
					<AccountPageWrapper>
						<CodeVerificationPage />
					</AccountPageWrapper>
				}
			/>
			<Route
				path="*"
				element={
					<MainLayout>
						<Routes>
							<Route path="/signout" element={<SignOutPage />} />
							<Route path="/account/*" element={<AccountRoutes />} />
							<Route path="/dashboard/*" element={<DashboardRoutes />} />
							<Route path="/healthsnapshot/*" element={<HealthSnapshotRoutes />} />
							<Route path="/findcare/*" element={<FindCareRoutes />} />
							<Route path="/developer/*" element={<DeveloperRouter />} />
							<Route path={"*"} element={<Navigate to="dashboard" />} />
						</Routes>
					</MainLayout>
				}
			/>
		</Routes>
	);
}

function ExternalRouter({ children }: PropsWithChildren<{}>) {
	return (
		<Routes>
			{/* <Route path="/findcare-demo" element={<FindCareDemo />} />  Use this for exposing demos sections - potetnially going to show a limited findcare feature in the marketing site*/}
			<Route path="*" element={children} />
		</Routes>
	);
}

export { RouterMain, ExternalRouter };
