import { Stack, Typography } from "@mui/material";
import { palette } from "theme";

type LabelValueTileProps = {
	title: string | number;
	value: string | number;
	color?: string;
};
const LabelValueTile = ({ color, title, value }: LabelValueTileProps) => {
	return (
		<Stack
			data-componentname="LabelValueTile-Outer"
			sx={{
				height: "100%",
				padding: "1rem",
				boxSizing: "border-box",
				backgroundColor: palette.gray.light,
				borderRadius: "1rem",
				marginBottom: ".5rem",
				textAlign: "center",
			}}
		>
			<Typography
				sx={{
					color: color ?? "core.darkText",
					fontSize: { xs: "1.75rem", sm: "2.25rem" },
					fontWeight: "bold",
				}}
			>
				{value}
			</Typography>
			<Typography
				variant="h4"
				sx={{
					color: "gray.dark",
					marginBottom: "0",
				}}
			>
				{title}
			</Typography>
		</Stack>
	);
};

export { LabelValueTile };
