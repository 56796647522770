import { Button, Container, Box, Dialog, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { typeCheck_EventTypeNames, event_type_definitions, typeCheck_EventTypeActionNames, event_actions } from "common/definitions/EventDefinitions";
import { AccountManager } from "common/managers/AccountManager";
import { X_addUserIgnoreItemsDataAgnostic } from "common/requests/patient_data/X_addUserIgnoreItemsDataAgnostic";
import { dateConverter } from "common/utilities/dateConverter";
import { useRef, useState, ReactNode, useMemo } from "react";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { EventTile } from "../Tiles/EventTile";

type AlertPopperProps = {
	content: ReactNode;
};

const useStyles = makeStyles((theme) => ({
	popoverRoot: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "sm",
	},
}));

const AlertPopper = ({ content }: AlertPopperProps) => {
	const [anchor, set_anchor] = useState<Element | null>(null);
	// const environment: any = ((globalThis as any)?.["__RUNTIME_CONFIG"] as any)?.ENVIRONMENT_NAME;
	// console.log("ENV", environment);

	const open = !!anchor;
	const ref = useRef();
	// const handleClick = () => {
	// 	set_anchor(ref.current ?? null);
	// };

	// const id = open ? "simple-popover" : undefined;
	const classes = useStyles();

	const alerts = useMemo(() => {
		if (AccountManager.userDetails?.reminders) {
			return AccountManager.userDetails.reminders
				.map((reminder, i) => {
					if (typeCheck_EventTypeNames(reminder.type) && reminder.isAlert) {
						let x = event_type_definitions[reminder.type];
						console.log("reminder", reminder);
						return (
							<EventTile
								key={i}
								color={x.color}
								icon={x.icon}
								date={reminder.date ? dateConverter["YYYY-MM-DD_to_mM/dD/YY"](reminder.date) : null}
								title={reminder.markupTitle}
								closeAction={(x) => X_addUserIgnoreItemsDataAgnostic({ reminders: [reminder.actionableId] })}
								buttons={reminder.actions?.map((actionString) => {
									let actionConfig = actionString.split("||");
									let actionName = actionConfig[0];
									let actionParams: { [attr: string]: string } = {};
									if (actionConfig.length > 1) {
										for (let i = 1; i < actionConfig.length; i++) {
											let p = actionConfig[i].split("|");
											actionParams[p[0]] = p[1];
										}
									}
									if (typeCheck_EventTypeActionNames(actionName)) {
										let a = event_actions[actionName];
										if (a) {
											return {
												action: a.action.bind(null, null, actionParams) as () => any,
												text: a.text,
											};
										} else {
											console.warn(`actionName "${actionName}" not recognized`);
										}
									} else {
										window.RadiusCare.log_issue({ file: "Reminders", message: `unrecognized action: ${actionName}` });
									}
									return null;
								})}
								ignorable={reminder.ignorable}
							/>
						);
					}
					return null;
				})
				.filter((x) => x !== undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AccountManager.userDetails]);

	return (
		<Box>
			<Box ref={ref}>
				<Stack>
					{alerts && alerts.length > 0 ? (
						alerts
					) : (
						<Typography variant="entity-descriptor">
							You have no Alerts<div></div>
						</Typography>
					)}
				</Stack>
				{/* {environment !== "Production" && (
					<Button sx={{ width: "100%" }} aria-describedby={id} variant="contained" onClick={handleClick}>
						New Diagnosis Care Alert
					</Button>
				)} */}
			</Box>
			<Dialog
				open={open}
				onClose={() => {
					set_anchor(null);
				}}
				classes={{
					root: classes.popoverRoot,
				}}
			>
				<Container maxWidth={"xs"} disableGutters sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", padding: "1rem" }}>
					<Box>
						<HealthIcon color={"#fbbe28"} size={150} iconSVG={health_icons_svg.alert_triangle} />
					</Box>
					<Stack>
						<Typography variant="h2" sx={{ textAlign: "center" }}>
							A New Diagnosis has been detected in your health file
						</Typography>
					</Stack>
					{content}

					<Button
						size="large"
						sx={{ marginBottom: ".5rem" }}
						onClick={() => {
							set_anchor(null);
						}}
					>
						Return to Dashboard
					</Button>
				</Container>
			</Dialog>
		</Box>
	);
};

export default AlertPopper;
