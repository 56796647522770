import { Box, Grid, InputLabel, MenuItem } from "@mui/material";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { useEffect, useMemo, useState } from "react";
import { StandardOption } from "common/types/generic";
import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medicationDetails/requests/useQ_getDrugStructureData";

type MDP_PharmaciesFiltersProps = {
	drugStructureData: MedicationDetailDataPackage;
	medicationDetailItem: SingleCareMedicationDetail | null;
	set_medicationDetailItem: React.Dispatch<React.SetStateAction<null | SingleCareMedicationDetail>>;
	narrow?: boolean;
};

const MDP_PharmaciesFilters = ({ drugStructureData, medicationDetailItem, set_medicationDetailItem, narrow }: MDP_PharmaciesFiltersProps) => {
	const [type, set_type] = useState("");
	const [formOfDrug, set_formOfDrug] = useState("");
	const [dosage, set_dosage] = useState("");
	const [quantity, set_quantity] = useState("");

	const treatmentTypeOptions: Array<StandardOption<string, string>> = useMemo(() => {
		return Object.keys(drugStructureData).map((x) => {
			return { name: x, value: x };
		});
	}, [drugStructureData]);
	const [formOfDrugOptions, set_formOfDrugOptions] = useState<Array<StandardOption<string, string>>>([]);
	const [dosageOptions, set_dosageOptions] = useState<Array<StandardOption<string, string>>>([]);
	const [quantityOptions, set_quantityOptions] = useState<Array<StandardOption<string, string>>>([]);

	/*
██    ██ ██████  ██████   █████  ████████ ███████     ███████ ██ ██      ████████ ███████ ██████  ███████ 
██    ██ ██   ██ ██   ██ ██   ██    ██    ██          ██      ██ ██         ██    ██      ██   ██ ██      
██    ██ ██████  ██   ██ ███████    ██    █████       █████   ██ ██         ██    █████   ██████  ███████ 
██    ██ ██      ██   ██ ██   ██    ██    ██          ██      ██ ██         ██    ██      ██   ██      ██ 
 ██████  ██      ██████  ██   ██    ██    ███████     ██      ██ ███████    ██    ███████ ██   ██ ███████
  */
	// form of drug
	useEffect(() => {
		if (!type && treatmentTypeOptions.length) {
			set_type(treatmentTypeOptions[0].value);
		}
		if (treatmentTypeOptions.length > 0 && drugStructureData) {
			if (drugStructureData[type]) {
				set_formOfDrugOptions(
					Object.keys(drugStructureData[type]).map((x, i) => {
						if (i === 0) {
							set_formOfDrug(x);
						}
						return { name: x, value: x };
					})
				);
			}
		} else {
			set_formOfDrugOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [treatmentTypeOptions, type]);
	//dosage
	useEffect(() => {
		if (type && formOfDrug && formOfDrugOptions.length > 0) {
			if (drugStructureData[type][formOfDrug]) {
				set_dosageOptions(
					Object.keys(drugStructureData[type][formOfDrug]).map((x, i) => {
						if (i === 0) {
							set_dosage(x);
						}
						return { name: x, value: x };
					})
				);
			}
		} else {
			set_dosageOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formOfDrugOptions, formOfDrug]);
	//quantity
	useEffect(() => {
		if (type && formOfDrug && dosage && dosageOptions.length > 0) {
			if (drugStructureData[type][formOfDrug][dosage]) {
				// console.log("dosage", drugStructureData[type][formOfDrug][dosage]);
				set_quantityOptions(
					Object.keys(drugStructureData[type][formOfDrug][dosage]).map((x, i) => {
						if (i === 0) {
							set_quantity(x);
						}
						return { name: x, value: x };
					})
				);
			}
		} else {
			set_quantityOptions([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dosageOptions, dosage]);
	/*
███    ███ ███████ ██████  ██  ██████  █████  ████████ ██  ██████  ███    ██     ██████  ███████ ████████  █████  ██ ██          ██ ████████ ███████ ███    ███ 
████  ████ ██      ██   ██ ██ ██      ██   ██    ██    ██ ██    ██ ████   ██     ██   ██ ██         ██    ██   ██ ██ ██          ██    ██    ██      ████  ████ 
██ ████ ██ █████   ██   ██ ██ ██      ███████    ██    ██ ██    ██ ██ ██  ██     ██   ██ █████      ██    ███████ ██ ██          ██    ██    █████   ██ ████ ██ 
██  ██  ██ ██      ██   ██ ██ ██      ██   ██    ██    ██ ██    ██ ██  ██ ██     ██   ██ ██         ██    ██   ██ ██ ██          ██    ██    ██      ██  ██  ██ 
██      ██ ███████ ██████  ██  ██████ ██   ██    ██    ██  ██████  ██   ████     ██████  ███████    ██    ██   ██ ██ ███████     ██    ██    ███████ ██      ██
*/
	useEffect(() => {
		if (
			type &&
			formOfDrug &&
			dosage &&
			quantity &&
			drugStructureData[type] &&
			drugStructureData[type][formOfDrug] &&
			drugStructureData[type][formOfDrug][dosage] &&
			drugStructureData[type][formOfDrug][dosage][quantity]
		) {
			let drugDataEntry = drugStructureData[type][formOfDrug][dosage][quantity];
			// console.debug({ type, formOfDrug, dosage, quantity, drugDataEntry });
			// console.debug("NDC: " + drugDataEntry.NDC);
			// console.debug(drugDataEntry);
			set_medicationDetailItem(drugDataEntry);
		} else {
			set_medicationDetailItem(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, formOfDrug, dosage, quantity, drugStructureData]);

	/*
██████  ███████ ███    ██ ██████  ███████ ██████      ██ ██████  ███████ ████████ ██    ██ ██████  ███    ██ 
██   ██ ██      ████   ██ ██   ██ ██      ██   ██    ██  ██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████    ██   ██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██  ██    ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ ██     ██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
                                                                                                             
*/

	return (
		<Box sx={{ paddingBottom: "1rem" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Box>
						<InputLabel style={{ paddingBottom: "8px" }} id="medication-type-label">
							Medication
						</InputLabel>
						<StandardSelect
							labelId="medication-type-select-label"
							id="medication-type-select"
							variant="standard"
							value={type}
							onChange={(x) => {
								if (x.target.value) {
									set_type(String(x.target.value));
								}
							}}
						>
							{Object.keys(drugStructureData).map((typename, i) => {
								return (
									<MenuItem key={i} value={typename}>
										{typename}
									</MenuItem>
								);
							})}
						</StandardSelect>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Box>
						<InputLabel id="medication-formOfDrug-label">Format</InputLabel>
						<StandardSelect
							labelId="medication-formOfDrug-label"
							id="medication-formOfDrug-select"
							variant="standard"
							value={
								formOfDrugOptions.find((x) => {
									return x.value === formOfDrug;
								})
									? formOfDrug
									: ""
							}
							disabled={formOfDrugOptions.length === 1 && formOfDrugOptions[0].value === formOfDrug}
							onChange={(x) => {
								if (typeof x.target.value === "string") {
									set_formOfDrug(x.target.value);
								}
							}}
						>
							{formOfDrugOptions.map(buildMenuItem)}
						</StandardSelect>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Box>
						<InputLabel id="medication-dosage-label">Dosage</InputLabel>
						<StandardSelect
							labelId="medication-dosage-label"
							id="medication-dosage-select"
							variant="standard"
							value={
								dosageOptions.find((x) => {
									return x.value === dosage;
								})
									? dosage
									: ""
							}
							disabled={dosageOptions.length === 1 && dosageOptions[0].value === dosage}
							onChange={(x) => {
								if (typeof x.target.value === "string") {
									set_dosage(x.target.value);
								}
							}}
						>
							{dosageOptions.map(buildMenuItem)}
						</StandardSelect>
					</Box>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Box>
						<InputLabel id="medication-quantity-label">Quantity</InputLabel>
						<StandardSelect
							labelId="medication-quantity-label"
							id="medication-quantity-select"
							variant="standard"
							value={
								quantityOptions.find((x) => {
									return x.value === quantity;
								})
									? quantity
									: ""
							}
							disabled={quantityOptions.length === 1 && quantityOptions[0].value === quantity}
							onChange={(x) => {
								if (typeof x.target.value === "string") {
									set_quantity(x.target.value);
								}
							}}
						>
							{quantityOptions.map((option, i) => {
								return (
									<MenuItem key={i} value={option.value}>
										{option.name}
									</MenuItem>
								);
							})}
						</StandardSelect>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

const buildMenuItem = (option: StandardOption<string | number, string | number>, i: number) => {
	return (
		<MenuItem key={i} value={option.value}>
			{option.name}
		</MenuItem>
	);
};

export { MDP_PharmaciesFilters };
