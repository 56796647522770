import { Stack } from "@mui/material";
import { StandardRadialBarChart } from "common/components/Charts/StandardRadialBarChart";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { BasicTileX } from "common/components/Tiles/BasicTile";
import { palette, theme } from "theme";
import { convert_TaxonomyGrouping_EventTypeName, EventTypeNames, event_type_definitions } from "common/definitions/EventDefinitions";
import { styleConverter } from "common/utilities/styleConverter";
import { useMemo } from "react";
import { DialogManager } from "common/managers/DialogManager";
import displayConverter from "common/utilities/displayConverter";
import { ConsumerCareScoreValue, ConsumerOperationStatusCareScoreResponseModel } from "api";
import { UseQueryResult } from "react-query";

type TotalCareScoresProps = {
	careScoreData: UseQueryResult<ConsumerOperationStatusCareScoreResponseModel, unknown>;
};

const TotalCareScore = ({ careScoreData }: TotalCareScoresProps) => {
	// const visible_count = 4;

	const chart_dataX = useMemo(() => {
		if (careScoreData.data?.data?.careScores) {
			let chart_data: Array<{ type: string; score: number }> = [];
			let colors: Array<string | undefined> = [];

			Object.keys(careScoreData.data.data.careScores).forEach((key) => {
				if (key !== "doctor_clinician" && key !== "hospitals") {
					return;
				}
				let v: ConsumerCareScoreValue = careScoreData.data.data.careScores[key];
				let score = (v.score / v.maxScoreValue) * 10;
				chart_data.push({
					type: v.type,
					score: score > 0 ? score : 0.1,
				});
				colors.push(
					styleConverter.getPaletteColor((event_type_definitions as CIM)[convert_TaxonomyGrouping_EventTypeName(v.type)]?.color ?? palette.gray.dark)
				);
			});
			return { colors: colors, scores: chart_data };
		}
		return null;
	}, [careScoreData.data?.data?.careScores]);

	return (
		<Stack
			sx={{
				flexDirection: "row",
				justifyContent: "center",
				[theme.breakpoints.down("sm")]: {
					flexDirection: "column",
				},
			}}
		>
			<QueryStatusDisplay queryResult={careScoreData}>
				{chart_dataX ? (
					<>
						<Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", sm: "25%" } }}>
							<StandardRadialBarChart
								data={chart_dataX.scores}
								nameKey="type"
								dataKey="score"
								innerRadius={50}
								outerRadius={80}
								responsiveHeight={300}
								colors={chart_dataX.colors}
								maxValue={10}
								showAverage
							/>
						</Stack>
						<Stack sx={{ flexDirection: { xs: "row", sm: "row" }, flex: "1" }}>
							{careScoreData.data?.data?.careScores
								? Object.keys(careScoreData.data?.data?.careScores).map((key: string, i: number) => {
										// if (i < visible_count) {
										if (key === "doctor_clinician" || key === "hospitals") {
											let cs = careScoreData.data?.data?.careScores[key];
											return (
												<BasicTileX
													type={convert_TaxonomyGrouping_EventTypeName(cs.type) as EventTypeNames}
													value={displayConverter.displayConsumerCareScoreValueFraction(cs)}
													key={i}
													onClick={() => {
														DialogManager.open("optimize-care-ratings", convert_TaxonomyGrouping_EventTypeName(cs.type));
													}}
												/>
											);
										} else {
											return null;
										}
								  })
								: null}
						</Stack>
					</>
				) : null}
			</QueryStatusDisplay>
		</Stack>
	);
};

export { TotalCareScore };
