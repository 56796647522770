/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerTreatmentGetDrugPricesAndLocationsResult,
  ModelsClientLocation,
  GetDrugPricesAndLocationsParams,
  ConsumerTreatmentGetDrugCouponResult,
  GetCouponParams,
  ConsumerTreatmentSearchTreatmentsByQueryStringResult,
  SearchTreatmentsByQueryStringParams,
  ConsumerTreatmentGetDrugStructureDataResult,
  GetDrugStructureDataParams,
  ConsumerTreatmentGetBatchDrugDataResult,
  ConsumerTreatmentGetBatchDrugDataRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getDrugPricesAndLocations = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDrugPricesAndLocationsParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerTreatmentGetDrugPricesAndLocationsResult>(
      {url: `/api/consumer/ConsumerTreatment/GetDrugPricesAndLocations`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDrugPricesAndLocationsMutationResult = NonNullable<Awaited<ReturnType<typeof getDrugPricesAndLocations>>>
    export type GetDrugPricesAndLocationsMutationBody = ModelsClientLocation
    export type GetDrugPricesAndLocationsMutationError = unknown

    export const useGetDrugPricesAndLocations = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDrugPricesAndLocations>>, TError,{data: ModelsClientLocation;params?: GetDrugPricesAndLocationsParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDrugPricesAndLocations>>, {data: ModelsClientLocation;params?: GetDrugPricesAndLocationsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDrugPricesAndLocations(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDrugPricesAndLocations>>, TError, {data: ModelsClientLocation;params?: GetDrugPricesAndLocationsParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getCoupon = (
    params?: GetCouponParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerTreatmentGetDrugCouponResult>(
      {url: `/api/consumer/ConsumerTreatment/GetCoupon`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetCouponQueryKey = (params?: GetCouponParams,) => [`/api/consumer/ConsumerTreatment/GetCoupon`, ...(params ? [params]: [])];

    
export type GetCouponQueryResult = NonNullable<Awaited<ReturnType<typeof getCoupon>>>
export type GetCouponQueryError = unknown

export const useGetCoupon = <TData = Awaited<ReturnType<typeof getCoupon>>, TError = unknown>(
 params?: GetCouponParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCoupon>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCouponQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCoupon>>> = ({ signal }) => getCoupon(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCoupon>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const searchTreatmentsByQueryString = (
    params?: SearchTreatmentsByQueryStringParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerTreatmentSearchTreatmentsByQueryStringResult>(
      {url: `/api/consumer/ConsumerTreatment/SearchTreatmentsByQueryString`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getSearchTreatmentsByQueryStringQueryKey = (params?: SearchTreatmentsByQueryStringParams,) => [`/api/consumer/ConsumerTreatment/SearchTreatmentsByQueryString`, ...(params ? [params]: [])];

    
export type SearchTreatmentsByQueryStringQueryResult = NonNullable<Awaited<ReturnType<typeof searchTreatmentsByQueryString>>>
export type SearchTreatmentsByQueryStringQueryError = unknown

export const useSearchTreatmentsByQueryString = <TData = Awaited<ReturnType<typeof searchTreatmentsByQueryString>>, TError = unknown>(
 params?: SearchTreatmentsByQueryStringParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchTreatmentsByQueryString>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSearchTreatmentsByQueryStringQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchTreatmentsByQueryString>>> = ({ signal }) => searchTreatmentsByQueryString(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof searchTreatmentsByQueryString>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getDrugStructureData = (
    params?: GetDrugStructureDataParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerTreatmentGetDrugStructureDataResult>(
      {url: `/api/consumer/ConsumerTreatment/GetDrugStructureData`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getGetDrugStructureDataQueryKey = (params?: GetDrugStructureDataParams,) => [`/api/consumer/ConsumerTreatment/GetDrugStructureData`, ...(params ? [params]: [])];

    
export type GetDrugStructureDataQueryResult = NonNullable<Awaited<ReturnType<typeof getDrugStructureData>>>
export type GetDrugStructureDataQueryError = unknown

export const useGetDrugStructureData = <TData = Awaited<ReturnType<typeof getDrugStructureData>>, TError = unknown>(
 params?: GetDrugStructureDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDrugStructureData>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDrugStructureDataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDrugStructureData>>> = ({ signal }) => getDrugStructureData(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDrugStructureData>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getBatchDrugData = (
    consumerTreatmentGetBatchDrugDataRequest: ConsumerTreatmentGetBatchDrugDataRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerTreatmentGetBatchDrugDataResult>(
      {url: `/api/consumer/ConsumerTreatment/GetBatchDrugData`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerTreatmentGetBatchDrugDataRequest
    },
      options);
    }
  


    export type GetBatchDrugDataMutationResult = NonNullable<Awaited<ReturnType<typeof getBatchDrugData>>>
    export type GetBatchDrugDataMutationBody = ConsumerTreatmentGetBatchDrugDataRequest
    export type GetBatchDrugDataMutationError = unknown

    export const useGetBatchDrugData = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getBatchDrugData>>, TError,{data: ConsumerTreatmentGetBatchDrugDataRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getBatchDrugData>>, {data: ConsumerTreatmentGetBatchDrugDataRequest}> = (props) => {
          const {data} = props ?? {};

          return  getBatchDrugData(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getBatchDrugData>>, TError, {data: ConsumerTreatmentGetBatchDrugDataRequest}, TContext>(mutationFn, mutationOptions)
    }
    