import { Box, Button, Stack, Typography } from "@mui/material";
import { Entity, entity_actions, entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon } from "../Icons/HealthIcon";
import { useState } from "react";
import { OpenCloseArrow } from "../UIParticles/OpenCloseArrow";
import { ModelsExplanationOfBenefitTreatmentAssessment } from "api";

import { MedicationAssessmentDetailsSection, MedicationOptionsActions } from "./DetailsSections/MedicationAssessmentDetailsSection";
import { TilesDetailsSection } from "./common/TilesDetailsSection";

interface MedicationHistoryTileProps {
	medicationEntity: Entity;
	medicationAssessment: ModelsExplanationOfBenefitTreatmentAssessment;
}
const MedicationHistoryTile = ({ medicationEntity, medicationAssessment }: MedicationHistoryTileProps) => {
	const [detailsOpen, set_detailsOpen] = useState(false);
	const { title } = medicationEntity;
	const entityType = entity_types["medication"];
	console.log(medicationEntity);
	return (
		<Box
			data-componentname="MedicationHistoryTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				"& .MuiButton-root, .view-options-btn ": {
					width: { xs: "100%", sm: "auto" },
				},
				"& .MuiButton-contained": {
					marginRight: { xs: "0", sm: ".5rem" },
					marginBottom: { xs: ".5rem", sm: "0" },
				},
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "flex-start", sm: "center" }, width: "100%" }}>
				<Box sx={{ flex: "1", paddingBottom: { xs: ".5rem", sm: "0" } }}>
					<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
						<AvatarDisplay
							imageAlt={title}
							icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={entityType.iconSVG} />}
						/>
						<Box sx={{ paddingLeft: ".5rem" }}>
							<Typography component="div" sx={{ fontSize: "1.25rem", color: palette.core.blue }}>
								<Box
									sx={{
										cursor: "pointer",
									}}
									onClick={() => {
										entity_actions.view_details.action(medicationEntity);
									}}
								>
									{title}
								</Box>
							</Typography>
						</Box>
					</Stack>
				</Box>
				<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center", width: { xs: "100%", sm: "auto" } }}>
					<MedicationOptionsActions medicationEntity={medicationEntity} />
					<Button
						variant="outlined"
						sx={{ width: { xs: "100%", sm: "auto" } }}
						onClick={() => {
							set_detailsOpen(!detailsOpen);
						}}
					>
						{detailsOpen ? "Close" : "History"}&nbsp;
						<OpenCloseArrow open={detailsOpen} />
					</Button>
				</Stack>
			</Stack>
			<TilesDetailsSection open={detailsOpen} set_open={set_detailsOpen}>
				<MedicationAssessmentDetailsSection medicationEntity={medicationEntity} medicationAssessment={medicationAssessment} />
			</TilesDetailsSection>
		</Box>
	);
};

export { MedicationHistoryTile };
