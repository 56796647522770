import { StaticDataManager } from "common/managers/StaticDataManager";
import { ReactElement, useMemo } from "react";

const ICDRangeCategoryViewer = () => {
	const display = useMemo(() => {
		let tableRows: Array<ReactElement> = [];
		StaticDataManager.data.ICD10CM_Package.range_categories
			.sort((a, b) => {
				return a.range > b.range ? 1 : b.range > a.range ? -1 : 0;
			})
			.forEach((x, y, z) => {
				console.debug(x, y, z);

				tableRows.push(
					<tr>
						<td>{x.range}</td>
						<td>{x.description}</td>
						<td>{x.taxonomy_codes?.join("|")}</td>
						<td>
							{x.taxonomy_codes?.map((x) => {
								let taxonomy = StaticDataManager.data.providerTaxonomyPackage.Code_Map.get(x);
								let text;
								if (!taxonomy) {
									text = `[Missing Code ${x}]`;
								}
								text = `${taxonomy?.displayName ?? "[NoDisplayName]"}->${taxonomy?.code}`;
								return <div>{text}</div>;
							})}
						</td>
					</tr>
				);
			});

		return (
			<table>
				<thead>
					<tr>
						<td>Range</td>
						<td>Range Description</td>
						<td>TaxonomyCodes</td>
						<td>TaxonomyCodeDisplayNames</td>
					</tr>
				</thead>
				<tbody>{tableRows}</tbody>
			</table>
		);
	}, []);

	return (
		<div>
			<p>ICD RANGE CATEGORY VIEWER</p>
			{display}
		</div>
	);
};

export { ICDRangeCategoryViewer };
