import { ConsumerTreatmentGetBatchDrugDataResult, getBatchDrugData, ModelsTerminalDrugStructureData } from "api";
import { DynamicDataManager } from "common/managers/DynamicDataManager";

async function GetDrugDataMapByNDCs(commaDelimitedNDCs: string): Promise<Map<string, ModelsTerminalDrugStructureData> | false> {
	if (commaDelimitedNDCs === "") {
		return DynamicDataManager.ndcs.drugData_NDCMap;
	}
	let ndcMap = DynamicDataManager.ndcs.drugData_NDCMap;
	let ndcs = commaDelimitedNDCs.split(",");
	ndcs = ndcs.filter((npi) => (ndcMap.has(npi) ? false : true));
	if (ndcs.length > 0) {
		let getBatchDrugData_response: ConsumerTreatmentGetBatchDrugDataResult = (await getBatchDrugData({
			ndCs: ndcs,
		})) as ConsumerTreatmentGetBatchDrugDataResult;

		// console.debug(getBatchDrugData_response);

		if (Array.isArray(getBatchDrugData_response?.response?.ndCs)) {
			getBatchDrugData_response?.response?.ndCs.forEach((x) => {
				if (x.ndc) {
					ndcMap.set(x.ndc, x);
				}
				// console.debug(x);
			});
		}
	}
	return ndcMap;
}
export { GetDrugDataMapByNDCs };
