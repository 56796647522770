import {
	ConsumerDoctorGetDoctorsByZipCodesResponseModel,
	ConsumerDoctorGetDoctorsByZipCodesResult,
	getDoctorsByZipCodes,
	GetDoctorsByZipCodesParams,
	ModelsClientLocation,
	ModelsOperationStatus,
} from "api";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { useQuery, UseQueryResult } from "react-query";

async function retrieveProvidersByZipCodes(
	_paramsX: GetDoctorsByZipCodesParamsX
): Promise<ProcessedResponse<any> | ConsumerDoctorGetDoctorsByZipCodesResponseModel> {
	// console.debug("getDoctorsByZipCodes", _paramsX);
	return getDoctorsByZipCodes(_paramsX.clientLocation, _paramsX.params).then(
		(data: ModelsOperationStatus | ConsumerDoctorGetDoctorsByZipCodesResult): ProcessedResponse<any> | ConsumerDoctorGetDoctorsByZipCodesResponseModel => {
			if (!apiResponseReader.typeCheck_ModelsOperationStatus(data)) {
				if (data.response) {
					return data.response;
				}
			}
			return apiResponseReader.processResponse(data);
		}
	);
}

type GetDoctorsByZipCodesParamsX = {
	params: GetDoctorsByZipCodesParams;
	clientLocation: ModelsClientLocation;
};
function useQ_retrieveProvidersByZipCodes(
	paramsX: GetDoctorsByZipCodesParamsX
): UseQueryResult<ProcessedResponse<any> | ConsumerDoctorGetDoctorsByZipCodesResponseModel> {
	return useQuery(
		["retrieveProvidersByZipCodes", `retrieveProvidersByZipCode:${JSON.stringify(paramsX.params)}:${JSON.stringify(paramsX.clientLocation)}`],
		retrieveProvidersByZipCodes.bind(null, paramsX),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);
}

export { useQ_retrieveProvidersByZipCodes, retrieveProvidersByZipCodes };
