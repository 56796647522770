import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { AccountManagerContext } from "common/managers/AccountManager";
import AccarentAlert from "dashboard/pages/overview/components/AccarentAlert";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AccarentAlertDialog = () => {
	const location = useLocation();
	const { accarentAlertDisplay } = useContext(DialogsContext);
	const accountContext = useContext(AccountManagerContext);
	let consumerUser = accountContext.accountManager.userDetails?.consumerUser;
	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("accarent-alert");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("accarent-alert");
			setSearchParams(searchParams);
			accarentAlertDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "accarent-alert": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["accarent-alert"].open = open;
		DialogManager.launchers["accarent-alert"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== accarentAlertDisplay?.current) {
			accarentAlertDisplay.set_current(d_query);
		}
	}, [d_query, accarentAlertDisplay]);

	return (
		<Dialog id="AccarentAlertDialog" fullScreen open={!!accarentAlertDisplay.current} onClose={close} TransitionComponent={Transition}>
			{accarentAlertDisplay.current && consumerUser && <AccarentAlert />}
		</Dialog>
	);
};

export { AccarentAlertDialog };
