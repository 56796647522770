/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useMutation
} from 'react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ModelsOperationStatus,
  CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest,
  CarePlanConfigurationOperationStatusDeactivatePatientDataAuthorization,
  CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel,
  CarePlanConfigurationMedicareConstructAuthorizationURIResult,
  MedicareConstructAuthorizationURIParams,
  ModelsOperationStatusString,
  ConnectMedicareAccountParams,
  CarePlanConfigurationAetnaConstructAuthorizationURIResult,
  AetnaConstructAuthorizationURIParams,
  ConnectAetnaAccountParams,
  CarePlanConfigurationUnitedHealthConstructAuthorizationURIResult,
  UnitedHealthConstructAuthorizationURIParams,
  ConnectUnitedHealthAccountParams,
  RetrieveMedicareDataParams,
  CarePlanConfigurationAnthemConstructAuthorizationURIResult,
  AnthemConstructAuthorizationURIParams,
  ConnectAnthemAccountParams,
  CarePlanConfigurationHorizonBlueConstructAuthorizationURIResult,
  HorizonBlueConstructAuthorizationURIParams,
  ConnectHorizonBlueAccountParams,
  CarePlanConfigurationCignaCariskConnectionInformation
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const setUserSelectedPatientDataAuthorizationItem = (
    carePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest: CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/CarePlanConfiguration/SetUserSelectedPatientDataAuthorizationItem`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: carePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest
    },
      options);
    }
  


    export type SetUserSelectedPatientDataAuthorizationItemMutationResult = NonNullable<Awaited<ReturnType<typeof setUserSelectedPatientDataAuthorizationItem>>>
    export type SetUserSelectedPatientDataAuthorizationItemMutationBody = CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest
    export type SetUserSelectedPatientDataAuthorizationItemMutationError = unknown

    export const useSetUserSelectedPatientDataAuthorizationItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setUserSelectedPatientDataAuthorizationItem>>, TError,{data: CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setUserSelectedPatientDataAuthorizationItem>>, {data: CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest}> = (props) => {
          const {data} = props ?? {};

          return  setUserSelectedPatientDataAuthorizationItem(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof setUserSelectedPatientDataAuthorizationItem>>, TError, {data: CarePlanConfigurationSetUserSelectedPatientDataAuthorizationItemRequest}, TContext>(mutationFn, mutationOptions)
    }
    export const removePatientDataAuthorization = (
    carePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel: CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<CarePlanConfigurationOperationStatusDeactivatePatientDataAuthorization>(
      {url: `/api/consumer/CarePlanConfiguration/RemovePatientDataAuthorization`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: carePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel
    },
      options);
    }
  


    export type RemovePatientDataAuthorizationMutationResult = NonNullable<Awaited<ReturnType<typeof removePatientDataAuthorization>>>
    export type RemovePatientDataAuthorizationMutationBody = CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel
    export type RemovePatientDataAuthorizationMutationError = unknown

    export const useRemovePatientDataAuthorization = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removePatientDataAuthorization>>, TError,{data: CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removePatientDataAuthorization>>, {data: CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  removePatientDataAuthorization(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof removePatientDataAuthorization>>, TError, {data: CarePlanConfigurationDeactivatePatientDataAuthorizationInitialRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const medicareConstructAuthorizationURI = (
    params?: MedicareConstructAuthorizationURIParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<CarePlanConfigurationMedicareConstructAuthorizationURIResult>(
      {url: `/api/consumer/CarePlanConfiguration/MedicareConstructAuthorizationURI`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getMedicareConstructAuthorizationURIQueryKey = (params?: MedicareConstructAuthorizationURIParams,) => [`/api/consumer/CarePlanConfiguration/MedicareConstructAuthorizationURI`, ...(params ? [params]: [])];

    
export type MedicareConstructAuthorizationURIQueryResult = NonNullable<Awaited<ReturnType<typeof medicareConstructAuthorizationURI>>>
export type MedicareConstructAuthorizationURIQueryError = unknown

export const useMedicareConstructAuthorizationURI = <TData = Awaited<ReturnType<typeof medicareConstructAuthorizationURI>>, TError = unknown>(
 params?: MedicareConstructAuthorizationURIParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof medicareConstructAuthorizationURI>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getMedicareConstructAuthorizationURIQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof medicareConstructAuthorizationURI>>> = ({ signal }) => medicareConstructAuthorizationURI(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof medicareConstructAuthorizationURI>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectMedicareAccount = (
    params?: ConnectMedicareAccountParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectMedicareAccount`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getConnectMedicareAccountQueryKey = (params?: ConnectMedicareAccountParams,) => [`/api/consumer/CarePlanConfiguration/ConnectMedicareAccount`, ...(params ? [params]: [])];

    
export type ConnectMedicareAccountQueryResult = NonNullable<Awaited<ReturnType<typeof connectMedicareAccount>>>
export type ConnectMedicareAccountQueryError = unknown

export const useConnectMedicareAccount = <TData = Awaited<ReturnType<typeof connectMedicareAccount>>, TError = unknown>(
 params?: ConnectMedicareAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof connectMedicareAccount>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConnectMedicareAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof connectMedicareAccount>>> = ({ signal }) => connectMedicareAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof connectMedicareAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const aetnaConstructAuthorizationURI = (
    params?: AetnaConstructAuthorizationURIParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<CarePlanConfigurationAetnaConstructAuthorizationURIResult>(
      {url: `/api/consumer/CarePlanConfiguration/AetnaConstructAuthorizationURI`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAetnaConstructAuthorizationURIQueryKey = (params?: AetnaConstructAuthorizationURIParams,) => [`/api/consumer/CarePlanConfiguration/AetnaConstructAuthorizationURI`, ...(params ? [params]: [])];

    
export type AetnaConstructAuthorizationURIQueryResult = NonNullable<Awaited<ReturnType<typeof aetnaConstructAuthorizationURI>>>
export type AetnaConstructAuthorizationURIQueryError = unknown

export const useAetnaConstructAuthorizationURI = <TData = Awaited<ReturnType<typeof aetnaConstructAuthorizationURI>>, TError = unknown>(
 params?: AetnaConstructAuthorizationURIParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof aetnaConstructAuthorizationURI>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAetnaConstructAuthorizationURIQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof aetnaConstructAuthorizationURI>>> = ({ signal }) => aetnaConstructAuthorizationURI(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof aetnaConstructAuthorizationURI>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectAetnaAccount = (
    params?: ConnectAetnaAccountParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectAetnaAccount`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getConnectAetnaAccountQueryKey = (params?: ConnectAetnaAccountParams,) => [`/api/consumer/CarePlanConfiguration/ConnectAetnaAccount`, ...(params ? [params]: [])];

    
export type ConnectAetnaAccountQueryResult = NonNullable<Awaited<ReturnType<typeof connectAetnaAccount>>>
export type ConnectAetnaAccountQueryError = unknown

export const useConnectAetnaAccount = <TData = Awaited<ReturnType<typeof connectAetnaAccount>>, TError = unknown>(
 params?: ConnectAetnaAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof connectAetnaAccount>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConnectAetnaAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof connectAetnaAccount>>> = ({ signal }) => connectAetnaAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof connectAetnaAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const unitedHealthConstructAuthorizationURI = (
    params?: UnitedHealthConstructAuthorizationURIParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<CarePlanConfigurationUnitedHealthConstructAuthorizationURIResult>(
      {url: `/api/consumer/CarePlanConfiguration/UnitedHealthConstructAuthorizationURI`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getUnitedHealthConstructAuthorizationURIQueryKey = (params?: UnitedHealthConstructAuthorizationURIParams,) => [`/api/consumer/CarePlanConfiguration/UnitedHealthConstructAuthorizationURI`, ...(params ? [params]: [])];

    
export type UnitedHealthConstructAuthorizationURIQueryResult = NonNullable<Awaited<ReturnType<typeof unitedHealthConstructAuthorizationURI>>>
export type UnitedHealthConstructAuthorizationURIQueryError = unknown

export const useUnitedHealthConstructAuthorizationURI = <TData = Awaited<ReturnType<typeof unitedHealthConstructAuthorizationURI>>, TError = unknown>(
 params?: UnitedHealthConstructAuthorizationURIParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof unitedHealthConstructAuthorizationURI>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getUnitedHealthConstructAuthorizationURIQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof unitedHealthConstructAuthorizationURI>>> = ({ signal }) => unitedHealthConstructAuthorizationURI(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof unitedHealthConstructAuthorizationURI>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectUnitedHealthAccount = (
    params?: ConnectUnitedHealthAccountParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectUnitedHealthAccount`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getConnectUnitedHealthAccountQueryKey = (params?: ConnectUnitedHealthAccountParams,) => [`/api/consumer/CarePlanConfiguration/ConnectUnitedHealthAccount`, ...(params ? [params]: [])];

    
export type ConnectUnitedHealthAccountQueryResult = NonNullable<Awaited<ReturnType<typeof connectUnitedHealthAccount>>>
export type ConnectUnitedHealthAccountQueryError = unknown

export const useConnectUnitedHealthAccount = <TData = Awaited<ReturnType<typeof connectUnitedHealthAccount>>, TError = unknown>(
 params?: ConnectUnitedHealthAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof connectUnitedHealthAccount>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConnectUnitedHealthAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof connectUnitedHealthAccount>>> = ({ signal }) => connectUnitedHealthAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof connectUnitedHealthAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const retrieveMedicareData = (
    params?: RetrieveMedicareDataParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/CarePlanConfiguration/RetrieveMedicareData`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getRetrieveMedicareDataQueryKey = (params?: RetrieveMedicareDataParams,) => [`/api/consumer/CarePlanConfiguration/RetrieveMedicareData`, ...(params ? [params]: [])];

    
export type RetrieveMedicareDataQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveMedicareData>>>
export type RetrieveMedicareDataQueryError = unknown

export const useRetrieveMedicareData = <TData = Awaited<ReturnType<typeof retrieveMedicareData>>, TError = unknown>(
 params?: RetrieveMedicareDataParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveMedicareData>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveMedicareDataQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveMedicareData>>> = ({ signal }) => retrieveMedicareData(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveMedicareData>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const anthemConstructAuthorizationURI = (
    params?: AnthemConstructAuthorizationURIParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<CarePlanConfigurationAnthemConstructAuthorizationURIResult>(
      {url: `/api/consumer/CarePlanConfiguration/AnthemConstructAuthorizationURI`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAnthemConstructAuthorizationURIQueryKey = (params?: AnthemConstructAuthorizationURIParams,) => [`/api/consumer/CarePlanConfiguration/AnthemConstructAuthorizationURI`, ...(params ? [params]: [])];

    
export type AnthemConstructAuthorizationURIQueryResult = NonNullable<Awaited<ReturnType<typeof anthemConstructAuthorizationURI>>>
export type AnthemConstructAuthorizationURIQueryError = unknown

export const useAnthemConstructAuthorizationURI = <TData = Awaited<ReturnType<typeof anthemConstructAuthorizationURI>>, TError = unknown>(
 params?: AnthemConstructAuthorizationURIParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof anthemConstructAuthorizationURI>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAnthemConstructAuthorizationURIQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof anthemConstructAuthorizationURI>>> = ({ signal }) => anthemConstructAuthorizationURI(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof anthemConstructAuthorizationURI>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectAnthemAccount = (
    params?: ConnectAnthemAccountParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectAnthemAccount`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getConnectAnthemAccountQueryKey = (params?: ConnectAnthemAccountParams,) => [`/api/consumer/CarePlanConfiguration/ConnectAnthemAccount`, ...(params ? [params]: [])];

    
export type ConnectAnthemAccountQueryResult = NonNullable<Awaited<ReturnType<typeof connectAnthemAccount>>>
export type ConnectAnthemAccountQueryError = unknown

export const useConnectAnthemAccount = <TData = Awaited<ReturnType<typeof connectAnthemAccount>>, TError = unknown>(
 params?: ConnectAnthemAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof connectAnthemAccount>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConnectAnthemAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof connectAnthemAccount>>> = ({ signal }) => connectAnthemAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof connectAnthemAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const horizonBlueConstructAuthorizationURI = (
    params?: HorizonBlueConstructAuthorizationURIParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<CarePlanConfigurationHorizonBlueConstructAuthorizationURIResult>(
      {url: `/api/consumer/CarePlanConfiguration/HorizonBlueConstructAuthorizationURI`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getHorizonBlueConstructAuthorizationURIQueryKey = (params?: HorizonBlueConstructAuthorizationURIParams,) => [`/api/consumer/CarePlanConfiguration/HorizonBlueConstructAuthorizationURI`, ...(params ? [params]: [])];

    
export type HorizonBlueConstructAuthorizationURIQueryResult = NonNullable<Awaited<ReturnType<typeof horizonBlueConstructAuthorizationURI>>>
export type HorizonBlueConstructAuthorizationURIQueryError = unknown

export const useHorizonBlueConstructAuthorizationURI = <TData = Awaited<ReturnType<typeof horizonBlueConstructAuthorizationURI>>, TError = unknown>(
 params?: HorizonBlueConstructAuthorizationURIParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof horizonBlueConstructAuthorizationURI>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getHorizonBlueConstructAuthorizationURIQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof horizonBlueConstructAuthorizationURI>>> = ({ signal }) => horizonBlueConstructAuthorizationURI(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof horizonBlueConstructAuthorizationURI>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectHorizonBlueAccount = (
    params?: ConnectHorizonBlueAccountParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectHorizonBlueAccount`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getConnectHorizonBlueAccountQueryKey = (params?: ConnectHorizonBlueAccountParams,) => [`/api/consumer/CarePlanConfiguration/ConnectHorizonBlueAccount`, ...(params ? [params]: [])];

    
export type ConnectHorizonBlueAccountQueryResult = NonNullable<Awaited<ReturnType<typeof connectHorizonBlueAccount>>>
export type ConnectHorizonBlueAccountQueryError = unknown

export const useConnectHorizonBlueAccount = <TData = Awaited<ReturnType<typeof connectHorizonBlueAccount>>, TError = unknown>(
 params?: ConnectHorizonBlueAccountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof connectHorizonBlueAccount>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getConnectHorizonBlueAccountQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof connectHorizonBlueAccount>>> = ({ signal }) => connectHorizonBlueAccount(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof connectHorizonBlueAccount>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const connectCariskPlan = (
    carePlanConfigurationCignaCariskConnectionInformation: CarePlanConfigurationCignaCariskConnectionInformation,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ModelsOperationStatusString>(
      {url: `/api/consumer/CarePlanConfiguration/ConnectCariskPlan`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: carePlanConfigurationCignaCariskConnectionInformation
    },
      options);
    }
  


    export type ConnectCariskPlanMutationResult = NonNullable<Awaited<ReturnType<typeof connectCariskPlan>>>
    export type ConnectCariskPlanMutationBody = CarePlanConfigurationCignaCariskConnectionInformation
    export type ConnectCariskPlanMutationError = unknown

    export const useConnectCariskPlan = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof connectCariskPlan>>, TError,{data: CarePlanConfigurationCignaCariskConnectionInformation}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof connectCariskPlan>>, {data: CarePlanConfigurationCignaCariskConnectionInformation}> = (props) => {
          const {data} = props ?? {};

          return  connectCariskPlan(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof connectCariskPlan>>, TError, {data: CarePlanConfigurationCignaCariskConnectionInformation}, TContext>(mutationFn, mutationOptions)
    }
    