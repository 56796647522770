import { AppBar, Box, Container, Typography } from "@mui/material";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { AdaptiveMenu } from "common/components/Menus/AdaptiveMenu";
import { NavigationItemHorizontal } from "main/navigation/NavigationItemHorizontal";
import { useEffect, useMemo, useState } from "react";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { SingleCareMedicationDetail, useQ_getDrugStructureData } from "./requests/useQ_getDrugStructureData";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { DialogManager } from "common/managers/DialogManager";
import { MedicationDetailPricing } from "./pages/pricing/MedicationDetailPricing";
import { MedicationDetailsMain } from "./pages/MedicationDetailsMain";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { Loader } from "common/components/Loaders/Loader";

type MedicationDetailsProps = {
	medicationID: string;
	medicationName?: string | null;
};
function MedicationDetails({ medicationID, medicationName }: MedicationDetailsProps) {
	const drugStructureData: any = useQ_getDrugStructureData({ medicationID });
	const [medicationDetailItem, set_medicationDetailItem] = useState<null | SingleCareMedicationDetail>(null);

	const drugStructureDataX = useMemo(() => {
		if (!apiResponseReader.typeCheck_ProcessedResponse(drugStructureData.data)) {
			return drugStructureData.data?.response?.queryResult ?? null;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [drugStructureData, drugStructureData.status, drugStructureData.data]);

	useEffect(() => {
		if (!medicationDetailItem && drugStructureDataX) {
			let x = drugStructureDataX;
			for (let i = 0; i < 4; i++) {
				if (x && !x.hasOwnProperty("Description")) {
					x = x[Object.keys(x)[0]];
				}
			}
			set_medicationDetailItem(x);
		}
	}, [drugStructureDataX, medicationDetailItem]);
	const [activeSection, set_activeSection] = useState<"details" | "pricing">("details");
	// console.log("desc", drugStructureData?.data?.response?.drugInformation.summary);
	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">
						{medicationDetailItem?.Name ?? DynamicDataManager.ndcs.drugData_NDCMap.get(medicationID)?.name ?? medicationName ?? (
							//`Medication "${medicationID}" Was Not Found`
							<Loader size="small" />
						)}
					</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("medication-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<QueryStatusDisplay queryResult={drugStructureData}>
					<AdaptiveMenu id="MedicationDetailsMenu">
						<NavigationItemHorizontal
							onClick={() => {
								set_activeSection("details");
							}}
							isActive={activeSection === "details"}
							label="Details"
							icon={<HealthIcon iconSVG={health_icons_svg.pill3} />}
							to="N/A"
						/>
						<NavigationItemHorizontal
							onClick={() => {
								set_activeSection("pricing");
							}}
							isActive={activeSection === "pricing"}
							label="Low Pricing"
							icon={<HealthIcon iconSVG={health_icons_svg.dollarsign} />}
							to="N/A"
						/>
					</AdaptiveMenu>

					{activeSection === "details" ? (
						<MedicationDetailsMain
							drugStructureData={drugStructureDataX}
							medicationDetailItem={medicationDetailItem}
							sideEffects={drugStructureData?.data?.response?.drugInformation.sideEffects ?? ""}
							description={drugStructureData?.data?.response?.drugInformation.summary ?? ""}
						/>
					) : activeSection === "pricing" ? (
						<MedicationDetailPricing
							drugStructureData={drugStructureDataX}
							medicationDetailItem={medicationDetailItem}
							set_medicationDetailItem={set_medicationDetailItem}
						/>
					) : null}
				</QueryStatusDisplay>
			</Container>
		</Box>
	);
}

export { MedicationDetails };
