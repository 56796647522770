import { StaticDataManager } from "common/managers/StaticDataManager";
import { useQuery, UseQueryResult } from "react-query";
import { ModelsOperationStatus } from "api";
import { ConditionEntity } from "common/definitions/entity/ConditionEntity";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";

type SearchConditionsQueryParams = {
	ICD10RangeCategory: string;
};
interface SearchConditionsResult_ModelsOperationStatus extends ModelsOperationStatus {
	data: Array<ConditionEntity>;
}

async function retrieveSearchConditions(_params: SearchConditionsQueryParams): Promise<SearchConditionsResult_ModelsOperationStatus | ProcessedResponse<any>> {
	if (!_params.ICD10RangeCategory) {
		return apiResponseReader.createProcessedResponse_NOREQUEST("Select a Condition Type to Search for Conditions");
	}

	// console.debug(_params.ICD10RangeCategory);
	let icd10s =
		_params.ICD10RangeCategory !== "All"
			? StaticDataManager.data.ICD10CM_Package.range_category_map_to_codes.get(_params.ICD10RangeCategory)
			: StaticDataManager.data.ICD10CM_Package.codes;
	// console.debug(StaticDataManager.data.ICD10CM_Package);
	// console.debug(icd10s);

	let response: SearchConditionsResult_ModelsOperationStatus = {
		success: true,
		errorMessage: null,
		errorCode: null,
		errorType: null,
		data: [],
		responseType: "OperationStatus",
	};
	if (icd10s) {
		response.data = icd10s.map((icd) => {
			return {
				entityTypeName: "condition",
				title: icd.Description ?? icd.ICD10Code ?? "",
				descriptor: icd.Title ?? icd.alphaCategory ?? icd.rangeCategory ?? "",
				displayInfo: [],
				actions: ["learn_more"],
				_conditionRecord: icd,
			};
		});
	}

	await new Promise((respond) => {
		setTimeout(() => {
			respond(true);
		}, 500);
	});

	return response;
	// let result = await new Promise((resolve, _reject) => {
	// 	setTimeout(() => {
	// 		return resolve([
	// 			{
	// 				entityTypeName: "condition",
	// 				title: "Condition 1",
	// 				descriptor: "Respiratory",
	// 				displayInfo: [
	// 					{ title: "Distance in Miles", value: 1.25 },
	// 					{ title: "Lowest Price", value: 5.31, colorType: "Positive", dataType: "USD" },
	// 				],
	// 				actions: ["view_all_prices", "price_alerts"],
	// 			},
	// 			{
	// 				entityTypeName: "condition",
	// 				title: "Condition 2",
	// 				descriptor: "Circulatory",
	// 				displayInfo: [
	// 					{ title: "Distance in Miles", value: 1.25 },
	// 					{ title: "Lowest Price", value: 5.21, colorType: "Positive", dataType: "USD" },
	// 				],
	// 				actions: ["view_all_prices", "price_alerts"],
	// 			},
	// 			{
	// 				entityTypeName: "condition",
	// 				title: "Condition 3",
	// 				descriptor: "Neurological",
	// 				displayInfo: [
	// 					{ title: "Distance in Miles", value: 1.25 },
	// 					{ title: "Lowest Price", value: 280.29, colorType: "Positive", dataType: "USD" },
	// 				],
	// 				actions: ["view_all_prices", "price_alerts"],
	// 			},
	// 		]);
	// 	}, 500);
	// }).then((data) => {
	// 	//Restructure data if needed
	// 	return data;
	// });
	// return result as SearchConditionsResult_ModelsOperationStatus;
}

function useQ_retrieveSearchConditions(params: SearchConditionsQueryParams): UseQueryResult<SearchConditionsResult_ModelsOperationStatus> {
	return useQuery(["retrieveSearchConditions", `retrieveSearchConditions:${JSON.stringify(params)}`], retrieveSearchConditions.bind(null, params));
}

export { useQ_retrieveSearchConditions };
export type { SearchConditionsResult_ModelsOperationStatus };
