import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";

import { DialogManager } from "common/managers/DialogManager";
import { HospitalDetail } from "details/hospitalDetails/HospitalDetail";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const HospitalDetailDialog = () => {
	const location = useLocation();
	const { hospitalDetailDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("hospital-detail");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("hospital-detail");
			setSearchParams(searchParams);
			hospitalDetailDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "hospital-detail": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["hospital-details"].open = open;
		DialogManager.launchers["hospital-details"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== hospitalDetailDisplay?.current) {
			hospitalDetailDisplay.set_current(d_query);
		}
	}, [d_query, hospitalDetailDisplay]);

	return (
		<Dialog id="HospitalDetailDialog" fullScreen open={!!hospitalDetailDisplay.current} onClose={close} TransitionComponent={Transition}>
			{hospitalDetailDisplay.current ? <HospitalDetail id={hospitalDetailDisplay.current} /> : null}
		</Dialog>
	);
};

export { HospitalDetailDialog };
