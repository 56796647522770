/*
Example usage with useQuery in consumer\src\findCare\pages\providers\FindCareProviders.tsx
*/
class Throttle {
	interval: number = 1000; //milliseconds
	last_fire_time: number = 0;
	last_scheduled_time: number = 0;
	last_fire_hash: string | null = null;
	leading_edge: boolean = true;
	action: (...args: any[]) => any;
	constructor(action: (...args: any[]) => any, interval?: number, leading_edge?: boolean) {
		this.action = action;
		this.interval = interval ?? 1000;
		this.leading_edge = leading_edge !== undefined ? leading_edge : true;
	}

	trigger = (...args: any[]) => {
		let triggered_time = Date.now();
		if (this.last_fire_time < triggered_time - this.interval && this.leading_edge) {
			// console.log("STRAIGHT FIRE " + triggered_time);
			this.fire_action(args, triggered_time);
		} else {
			this.last_scheduled_time = triggered_time;
			setTimeout(() => {
				if (triggered_time >= this.last_scheduled_time) {
					// console.log("SCHEDULED FIRE  " + triggered_time);
					this.fire_action(args, triggered_time);
				}
			}, this.interval);
		}
	};

	fire_action = (args: any[], time: number) => {
		let fire_hash = JSON.stringify(args);
		if (this.last_fire_hash !== fire_hash) {
			this.last_fire_hash = fire_hash;
			this.last_fire_time = time;
			//   console.count("FIRE!!!!!!!");
			this.action(...args);
		}
	};
}

export { Throttle };
