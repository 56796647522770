import { Stack, Button } from "@mui/material";
import { ConsumerCostOfCareResponseModel } from "api";
import { LabelValueTile } from "common/components/Tiles/LabelValueTile";
import { DialogManager } from "common/managers/DialogManager";
import displayConverter from "common/utilities/displayConverter";

type ProviderSavingsProps = {
	costOfCareData: ConsumerCostOfCareResponseModel;
};
const ProviderSavings = ({ costOfCareData }: ProviderSavingsProps) => {
	const providerCost = costOfCareData.savingsModel.providerCost ?? 0;
	const providerSavings = providerCost > 0 ? "40%" : "0%";
	return (
		<Stack>
			<LabelValueTile title="Total Cost" value={displayConverter.toUSD(providerCost)} color={"core.lavender"} />
			<LabelValueTile title="Radius Care Found Savings Up To" value={providerSavings} color={"core.lavender"} />
			<Stack justifyContent={"center"} flexDirection={"row"} sx={{ "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}>
				<Button
					onClick={() => {
						DialogManager.open("provider-savings-details");
					}}
				>
					View Details
				</Button>
			</Stack>
		</Stack>
	);
};

export { ProviderSavings };
