import { MenuItem } from "@mui/material";
import { AccountManagerContext } from "common/managers/AccountManager";
// import { inNetworkSelectOptions } from "details/diagnosisDetails/pages/overview/DiagnosisDetailTopDoctors";
import { useContext, useEffect } from "react";
import { StandardSelect } from "./StandardSelect";

interface InNetworkSelectProps {
	inNetwork: boolean;
	set_inNetwork: React.Dispatch<React.SetStateAction<boolean>>;
}
const inNetworkSelectOptions = [
	<MenuItem sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }} key={"FALSE"} value={"FALSE"}>
		All
	</MenuItem>,
	<MenuItem sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }} key={"TRUE"} value={"TRUE"}>
		In Network
	</MenuItem>,
];

const InNetworkSelect = ({ inNetwork, set_inNetwork }: InNetworkSelectProps) => {
	const accountContext = useContext(AccountManagerContext);

	useEffect(() => {
		if (accountContext.activeProviderOptions) {
			set_inNetwork(!!accountContext.activeProviderOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [accountContext.activeProviderOptions]);

	return accountContext.activeProviderOptions ? (
		// <Box style={{ maxWidth: "160px", flexGrow: "1", paddingRight: "8px" }}>
		<StandardSelect
			label="In Network"
			labelId="in-network-select-label"
			id="in-network-select"
			variant="standard"
			value={inNetwork ? "TRUE" : "FALSE"}
			onChange={(x) => {
				set_inNetwork(x.target.value === "TRUE");
			}}
		>
			{inNetworkSelectOptions}
		</StandardSelect>
	) : // </Box>
	null;
};

export { InNetworkSelect, inNetworkSelectOptions };
