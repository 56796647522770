import { ReactNode } from "react";
import { ButtonBase, Typography } from "@mui/material";
import { Link, useLocation, useMatch } from "react-router-dom";
import { style } from "theme";

interface NavigationItemProps {
	label: string;
	icon: ReactNode;
	to?: string;
	additionalMatches?: Array<string>;
	query?: string;
}

function NavigationItem({ label, icon, to, additionalMatches, query }: NavigationItemProps) {
	const location = useLocation();
	const partialPathname = location.pathname.split("/")[1];
	// console.log("path", partialPathname);
	let match: any = useMatch({
		path: to ?? partialPathname + "?" + query,
	});
	if (!match && additionalMatches !== undefined) {
		match = additionalMatches.some((x) => x === partialPathname);
	}

	return (
		<ButtonBase
			component={Link}
			to={to ?? partialPathname + "?" + query}
			className={match ? "active" : undefined}
			sx={{
				position: "relative",
				flexDirection: "column",
				p: 1,
				minHeight: "7rem",
				fontSize: "1rem",
				color: "gray.dark",
				borderBottom: "solid 2px",
				borderColor: "gray.light",
				"&:hover": {
					borderColor: "core.blue",
					color: "core.blue",
					background: style.primaryGradientVerticalAlt,
				},
				"&.active, &.active:hover": {
					borderColor: "core.blue",
					color: "core.blue",
					background: style.primaryGradientVertical,
				},
			}}
		>
			{icon}
			<Typography
				component="div"
				sx={{
					fontSize: "1rem",
					marginTop: 0.5,
					textAlign: "center",
					lineHeight: "1.25rem",
				}}
			>
				{label}
			</Typography>
		</ButtonBase>
	);
}

export { NavigationItem };
