import { IconToggle } from "common/components/Icons/IconToggle";
import { HealthIcon, health_icons_svg } from "../../common/components/Icons/HealthIcon";

type SearchBadgeProps = {
  onClick: () => any;
  active?: boolean;
};

function SearchBadge({ onClick, active }: SearchBadgeProps) {
  return <IconToggle onClick={onClick} icon={<HealthIcon iconSVG={health_icons_svg.magnifyingglass} />} active={active} />;
}

export { SearchBadge };
