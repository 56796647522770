/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerMobileOperationStatusOptimizeCareDoctorsResponseModel,
  ConsumerMobileOptimizeCareDoctorsRequestModel,
  ConsumerDoctorOperationStatusSuggestSimilarDoctorsMobileResponseModel,
  ConsumerDoctorSuggestSimilarDoctorsRequestModel,
  ConsumerDoctorGetDoctorsByLocationMobileResponse,
  ModelsClientLocation,
  GetDoctorsByLocationMobileParams,
  ConsumerDoctorGetDoctorsByZipCodesMobileResponse,
  GetDoctorsByZipCodesMobileParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const optimizeCareDoctors = (
    consumerMobileOptimizeCareDoctorsRequestModel: ConsumerMobileOptimizeCareDoctorsRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileOperationStatusOptimizeCareDoctorsResponseModel>(
      {url: `/api/consumer/ConsumerMobile_Doctor/OptimizeCareDoctors`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileOptimizeCareDoctorsRequestModel
    },
      options);
    }
  


    export type OptimizeCareDoctorsMutationResult = NonNullable<Awaited<ReturnType<typeof optimizeCareDoctors>>>
    export type OptimizeCareDoctorsMutationBody = ConsumerMobileOptimizeCareDoctorsRequestModel
    export type OptimizeCareDoctorsMutationError = unknown

    export const useOptimizeCareDoctors = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof optimizeCareDoctors>>, TError,{data: ConsumerMobileOptimizeCareDoctorsRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof optimizeCareDoctors>>, {data: ConsumerMobileOptimizeCareDoctorsRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  optimizeCareDoctors(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof optimizeCareDoctors>>, TError, {data: ConsumerMobileOptimizeCareDoctorsRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const suggestSimilarDoctorsMobile = (
    consumerDoctorSuggestSimilarDoctorsRequestModel: ConsumerDoctorSuggestSimilarDoctorsRequestModel,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorOperationStatusSuggestSimilarDoctorsMobileResponseModel>(
      {url: `/api/consumer/ConsumerMobile_Doctor/SuggestSimilarDoctorsMobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerDoctorSuggestSimilarDoctorsRequestModel
    },
      options);
    }
  


    export type SuggestSimilarDoctorsMobileMutationResult = NonNullable<Awaited<ReturnType<typeof suggestSimilarDoctorsMobile>>>
    export type SuggestSimilarDoctorsMobileMutationBody = ConsumerDoctorSuggestSimilarDoctorsRequestModel
    export type SuggestSimilarDoctorsMobileMutationError = unknown

    export const useSuggestSimilarDoctorsMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof suggestSimilarDoctorsMobile>>, TError,{data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof suggestSimilarDoctorsMobile>>, {data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}> = (props) => {
          const {data} = props ?? {};

          return  suggestSimilarDoctorsMobile(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof suggestSimilarDoctorsMobile>>, TError, {data: ConsumerDoctorSuggestSimilarDoctorsRequestModel}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByLocationMobile = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDoctorsByLocationMobileParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorGetDoctorsByLocationMobileResponse>(
      {url: `/api/consumer/ConsumerMobile_Doctor/GetDoctorsByLocationMobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDoctorsByLocationMobileMutationResult = NonNullable<Awaited<ReturnType<typeof getDoctorsByLocationMobile>>>
    export type GetDoctorsByLocationMobileMutationBody = ModelsClientLocation
    export type GetDoctorsByLocationMobileMutationError = unknown

    export const useGetDoctorsByLocationMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDoctorsByLocationMobile>>, TError,{data: ModelsClientLocation;params?: GetDoctorsByLocationMobileParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDoctorsByLocationMobile>>, {data: ModelsClientLocation;params?: GetDoctorsByLocationMobileParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDoctorsByLocationMobile(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDoctorsByLocationMobile>>, TError, {data: ModelsClientLocation;params?: GetDoctorsByLocationMobileParams}, TContext>(mutationFn, mutationOptions)
    }
    export const getDoctorsByZipCodesMobile = (
    modelsClientLocation: ModelsClientLocation,
    params?: GetDoctorsByZipCodesMobileParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerDoctorGetDoctorsByZipCodesMobileResponse>(
      {url: `/api/consumer/ConsumerMobile_Doctor/GetDoctorsByZipCodesMobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type GetDoctorsByZipCodesMobileMutationResult = NonNullable<Awaited<ReturnType<typeof getDoctorsByZipCodesMobile>>>
    export type GetDoctorsByZipCodesMobileMutationBody = ModelsClientLocation
    export type GetDoctorsByZipCodesMobileMutationError = unknown

    export const useGetDoctorsByZipCodesMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDoctorsByZipCodesMobile>>, TError,{data: ModelsClientLocation;params?: GetDoctorsByZipCodesMobileParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDoctorsByZipCodesMobile>>, {data: ModelsClientLocation;params?: GetDoctorsByZipCodesMobileParams}> = (props) => {
          const {data,params} = props ?? {};

          return  getDoctorsByZipCodesMobile(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getDoctorsByZipCodesMobile>>, TError, {data: ModelsClientLocation;params?: GetDoctorsByZipCodesMobileParams}, TContext>(mutationFn, mutationOptions)
    }
    