import { useQuery, UseQueryResult } from "react-query";
import { SingleCareMedicationDetail } from "./useQ_getDrugStructureData";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import {
	ConsumerTreatmentGetDrugPricesAndLocationsResult,
	getDrugPricesAndLocations,
	ModelsClientLocation,
	ModelsOperationStatus,
	ModelsRxSenseTieredPricingValue,
} from "api";

// type MedicationPharmaciesResult = Array<Entity> | null;

type useQ_retrieveMedicationPharmaciesParams = {
	medicationDetailItem: SingleCareMedicationDetail | null;
	clientLocation: ModelsClientLocation;
};

type retrieveMedicationPharmaciesParams = {
	ndc?: string;
	quantity?: number;
	clientLocation: ModelsClientLocation;
};

type RetrieveMedicationPharmaciesResult = ProcessedResponse<ModelsRxSenseTieredPricingValue | null>;

async function retrieveMedicationPharmacies({
	ndc,
	quantity,
	clientLocation,
}: retrieveMedicationPharmaciesParams): Promise<RetrieveMedicationPharmaciesResult | ModelsOperationStatus> {
	if (ndc === undefined || quantity === undefined) {
		return apiResponseReader.createProcessedResponse({
			errorCode: "NO-REQUEST",
			errorType: "NEUTRAL",
			errorMessage: "Select medication options to search available pharamacies",
		});
	}

	let result: ProcessedResponse<ModelsRxSenseTieredPricingValue | null | ModelsOperationStatus> = await getDrugPricesAndLocations(clientLocation, {
		ndc: ndc,
		quantity: quantity,
	}).then(
		(
			data: ModelsOperationStatus | ConsumerTreatmentGetDrugPricesAndLocationsResult
		): ProcessedResponse<ModelsRxSenseTieredPricingValue | null | ModelsOperationStatus> => {
			if (!apiResponseReader.typeCheck_ModelsOperationStatus(data)) {
				// console.debug(let pricingModel = data?.response?.pricingModel;

				if (data?.response?.data?.pricingModel?.success === true) {
					// value = pricingModel?.value as ModelsRxSenseTieredPricingValue;
					if (data.response.data.pricingModel.value?.pharmacyPricings) {
						// return value.pharmacyPricings.map((x) => {
						// 	console.debug(x);
						// 	if (x.pharmacy) {
						// 		return {
						// 			entityTypeName: "pharmacy",
						// 			title: x.pharmacy.name,
						// 			descriptor: `${x.pharmacy?.address?.address1} ${x.pharmacy?.address?.city} ${x.pharmacy?.address?.state} ${x.pharmacy?.address?.postalCode}`,
						// 			imageSrc: x.pharmacy.logoUrl,
						// 			displayInfo: [
						// 				{ title: "Distance in Miles", value: Number(x.pharmacy.distance).toFixed(2) },
						// 				{ title: "Current Price", value: x.prices?.[0].price, colorType: "USD", dataType: "USD" },
						// 			],
						// 			actions: ["order"],
						// 			_data: x,
						// 		};
						// 	}
						// });
						// return pricingModel?.value;
						return apiResponseReader.createProcessedResponse({ data: data.response.data.pricingModel.value, success: true });
					}
				}

				return apiResponseReader.createProcessedResponse({
					errorCode: "GENERIC",
					errorMessage: "Unexpected response structure",
				});
			}
			return apiResponseReader.processResponse(data);
		}
	);

	return apiResponseReader.createProcessedResponse(result);
}

function useQ_medicationPharmacies(params: useQ_retrieveMedicationPharmaciesParams): UseQueryResult<RetrieveMedicationPharmaciesResult> {
	const _params: retrieveMedicationPharmaciesParams = {
		ndc: params.medicationDetailItem?.NDC,
		quantity: params.medicationDetailItem?.Quantity,
		clientLocation: params.clientLocation,
	};
	return useQuery(
		["retrieveMedicationPharmacies", `retrieveMedicationPharmacies${JSON.stringify(_params)}`],
		retrieveMedicationPharmacies.bind(null, _params)
	);
}

export { useQ_medicationPharmacies };
// export type { MedicationPharmaciesResult };
