import { AppBar, Box, Container, Dialog, Slide, SlideProps, Typography } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { ConnectHealthPlanForm } from "account/pages/connectHealthPlan/ConnectHealthPlanForm";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddCarePlanDialog = () => {
	const location = useLocation();
	const { addCarePlanDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("add-care-plan");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("add-care-plan");
			setSearchParams(searchParams);
			addCarePlanDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "add-care-plan": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["add-care-plan"].open = open;
		DialogManager.launchers["add-care-plan"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== addCarePlanDisplay?.current) {
			addCarePlanDisplay.set_current(d_query);
		}
	}, [d_query, addCarePlanDisplay]);

	return (
		<Dialog id="AddCarePlanDialog" fullScreen open={!!addCarePlanDisplay.current} onClose={close} TransitionComponent={Transition}>
			{
				<>
					<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
						<Box
							sx={{
								height: style.headerHeight,
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "#ffffff !important",
							}}
						>
							<Typography variant="h1">Connect Insurance Plan</Typography>
							<CloseButton
								onClick={() => {
									DialogManager.close("add-care-plan");
								}}
								right={12}
								styleType="big-blue"
							/>
						</Box>
					</AppBar>
					<Container sx={{ justifyContent: "center", display: "flex" }}>
						<ConnectHealthPlanForm hideSkipConnection />
					</Container>
				</>
			}
		</Dialog>
	);
};

export { AddCarePlanDialog };
