import { useMemo, ReactNode } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CloseButton } from "../Buttons/CloseButton";
import { styleConverter } from "../../utilities/styleConverter";
import { palette } from "theme";
import { create_EventActions_for_EventDescription, EventDescription, event_type_definitions } from "common/definitions/EventDefinitions";

interface EventTileXProps extends Omit<EventTileProps, "icon" | "color" | "date" | "title" | "buttons"> {
	eventDescription: EventDescription;
}

const EventTileX = ({ eventDescription, closeAction, minimal }: EventTileXProps) => {
	let t = event_type_definitions[eventDescription.type];
	if (t) {
		return (
			<EventTile
				color={t.color}
				icon={t.icon}
				date={eventDescription.date}
				title={eventDescription.title}
				buttons={create_EventActions_for_EventDescription(eventDescription)}
				closeAction={closeAction}
			/>
		);
	} else {
		window.RadiusCare.log_issue({ message: `Unrecognized event_type_definitions: ${eventDescription.type}`, file: "EventTile" });
		return null;
	}
};

interface EventTileProps {
	icon: ReactNode;
	color: string;
	date?: string | Date | null;
	title: string;
	buttons?: Array<{ action: () => any; text: string } | null | undefined> | null;
	minimal?: boolean;
	closeAction?: (x?: any) => any;
	ignorable?: boolean;
}

const EventTile = ({ closeAction, icon, title, date, color, buttons, minimal, ignorable }: EventTileProps) => {
	let titleColorMarkup = useMemo(() => {
		return styleConverter.textColorMarkupX(title);
	}, [title]);
	let buttonDisplay: any = null;
	const paletteColor = styleConverter.getPaletteColor(color);
	if (buttons) {
		buttonDisplay = (
			<>
				{buttons.map((x, y) => {
					if (!x) {
						return null;
					}
					return (
						<Button sx={{ width: { xs: "100%", sm: "auto", backgroundColor: paletteColor, borderColor: paletteColor } }} key={y} onClick={x.action}>
							{x.text}
						</Button>
					);
				})}
			</>
		);
	}

	return (
		<Box
			data-componentname="EventTile-Outer"
			sx={{
				paddingBottom: ".5rem",
			}}
		>
			<Box
				sx={{
					width: "100%",
					padding: "1rem",
					borderRadius: "1rem",
					boxSizing: "border-box",
					backgroundColor: palette.gray.light,
				}}
			>
				<Stack
					style={{
						width: "100%",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "space-between",
					}}
				>
					<Box sx={{ color: color, flexGrow: 0 }}>{icon}</Box>
					<Box
						style={{
							flexGrow: 1,
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Box>
							<Typography
								sx={{
									color: palette.gray.dark,
									fontSize: "1rem",
									lineHeight: "1.2rem",
									paddingLeft: "8px",
									paddingRight: "8px",
									textAlign: "left",
								}}
							>
								{date}
							</Typography>
							<Typography
								sx={{
									color: palette.gray.darker,
									fontSize: "1.1rem",
									paddingLeft: "8px",
									paddingRight: "8px",
									textAlign: "left",
									"& .colorText": {
										color: color,
									},
								}}
							>
								{titleColorMarkup}
							</Typography>
						</Box>
						{minimal ? <Box>{buttonDisplay}</Box> : null}
					</Box>
					{closeAction && ignorable !== false ? (
						<Box style={{ position: "relative", width: "20px" }}>
							<CloseButton
								onClick={(x) => {
									closeAction(x);
								}}
								right={-8}
								top={-8}
							/>
						</Box>
					) : null}
				</Stack>
				{!minimal ? (
					<Stack
						style={{
							width: "100%",
							flexDirection: "row",
							justifyContent: "flex-end",
							alignItems: "center",
							paddingTop: ".5rem",
						}}
					>
						{buttonDisplay}
					</Stack>
				) : null}
			</Box>
		</Box>
	);
};

export { EventTile, EventTileX };
