import { Navigate } from "react-router";
import { useAuthFlow } from "../../auth/useAuthFlow";
import NewPasswordForm from "../../forms/NewPasswordForm";
import SignInForm from "../../forms/SignInForm";

function SignInPage() {
  const flow = useAuthFlow();

  switch (flow.step) {
    case "SignIn":
      return <SignInForm {...flow} />;
    case "NewPasswordRequired":
      return <NewPasswordForm {...flow} />;
    case "Authenticated":
      return <Navigate replace to={flow.target ?? "/"} />;
  }
}

export default SignInPage;
