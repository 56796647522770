import { Box, Button, Stack, Typography } from "@mui/material";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { DoctorsByLocationData } from "findCare/requests/useQ_retrieveProvidersByLocation";
import { UseQueryResult } from "react-query";
import { ProcessedResponse, apiResponseReader } from "common/utilities/apiResponseReader";
import { Paginator } from "common/components/Paginator/Paginator";
import { useEffect, useMemo, useState } from "react";
import { ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { FilterFunctions } from "common/utilities/filterFunctions";
import { DoctorTile } from "common/components/Tiles/DoctorTile";
import { DialogManager } from "common/managers/DialogManager";

type FindCareProviderResultsProps = {
	data: DoctorsByLocationData | undefined | null;
	doctorsByLocationQueryResult: UseQueryResult<ProcessedResponse<any> | DoctorsByLocationData, unknown>;
	retrieveNextBatch: (currentCount: number) => any;
	hasNextBatch: boolean;
	loadingNextBatch: boolean;
};

const FindCareProviderResults = ({ data, doctorsByLocationQueryResult, retrieveNextBatch, hasNextBatch, loadingNextBatch }: FindCareProviderResultsProps) => {
	const [providers, set_providers] = useState<Array<ProviderEntity> | null>(null);
	const [keywords, set_keywords] = useState<string>("");
	const [loading, set_loading] = useState<boolean>(false);

	useEffect(() => {
		if (doctorsByLocationQueryResult.isLoading) {
			set_loading(true);
		} else {
			if (apiResponseReader.typeCheck_ProcessedResponse(data) || apiResponseReader.typeCheck_ModelsOperationStatus(data)) {
				set_loading(false);
			} else if (data && data.providerEntities) {
				set_providers(data.providerEntities.map((x) => x));
			}
		}
	}, [data, doctorsByLocationQueryResult]);

	const filterSortedByKeywords: Array<ProviderEntity> = useMemo(() => {
		let filtered: Array<ProviderEntity> = FilterFunctions.filter_by_keywords(providers ?? [], ["title", "descriptor", "specialty"], keywords);
		if (loading) {
			set_loading(false);
		}
		return filtered;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keywords, providers]);

	return (
		<Box>
			<Stack sx={{ paddingBottom: "1rem", width: "100%" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Keywords
				</Typography>
				<StandardTextField
					placeholder='"Name or Specialty"'
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						set_keywords(e.currentTarget.value ?? "");
					}}
					value={keywords}
				/>
			</Stack>
			{/* {loading ? "Y" : "N"} {doctorsByLocationQueryResult.isLoading ? "Y" : "N"} */}
			<QueryStatusDisplay queryResult={doctorsByLocationQueryResult} showAsLoading={loading}>
				{/* <FilterSorter initialData={providers} filterTerms={filterTerms} setFinalData={set_filteredSortedProviders} /> */}
				<Paginator
					pageSize={6}
					data={filterSortedByKeywords}
					renderItem={(provEnt: ProviderEntity, i?: number) => {
						provEnt.descriptor = provEnt._providerRecord?.subSpecialties ?? "";
						let viewProfile = () => {
							DialogManager.open("provider-details", provEnt._providerRecord?.npi ?? "MISSING NPI");
						};

						let content = (
							<Box>
								<Button variant="text" onClick={viewProfile}>
									View Profile
								</Button>
							</Box>
						);
						return (
							<Box key={i} sx={{ paddingBottom: ".5rem" }}>
								<DoctorTile key={i} Entity={provEnt} content={content} />
							</Box>
						);
					}}
					retrieveItems={(data_: Array<any>) => {
						retrieveNextBatch(data_.length);
					}}
					hasNextBatch={hasNextBatch}
					loadingNextBatch={loadingNextBatch}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

export { FindCareProviderResults };
