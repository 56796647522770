import { CareCollaborators } from "account/pages/myProfile/CareCollaborators/CareCollaborators";
import { ManageAccountMenu } from "account/pages/myProfile/ManageAccountMenu";
import { UserDetails } from "account/pages/myProfile/UserDetails/UserDetails";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";

function AccountRoutes() {
	return (
		<>
			<ManageAccountMenu />
			<Routes>
				<Route path="/carecollaborators" element={<CareCollaborators />} />
				<Route path="/profile" element={<UserDetails />} />
				<Route path="*" element={<Navigate replace to="profile" />} />
			</Routes>
		</>
	);
}
export { AccountRoutes };
