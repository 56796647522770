import { Stack, Typography } from "@mui/material";
import { UserLocationSettingsModal } from "common/components/Modals/UserLocationSettingsModal";
import { EventTile } from "common/components/Tiles/EventTile";
import { event_type_definitions, event_actions, typeCheck_EventTypeNames, typeCheck_EventTypeActionNames } from "common/definitions/EventDefinitions";
import { AccountManager } from "common/managers/AccountManager";
import { dateConverter } from "common/utilities/dateConverter";
import { useMemo, useState } from "react";

const Reminders = () => {
	const [locationModalOpen, set_locationModalOpen] = useState<boolean>(false);

	const reminders = useMemo(() => {
		if (AccountManager.userDetails?.reminders) {
			return AccountManager.userDetails.reminders
				.map((reminder, i) => {
					if (typeCheck_EventTypeNames(reminder.type)) {
						let x = event_type_definitions[reminder.type];
						return (
							<EventTile
								key={i}
								color={x.color}
								icon={x.icon}
								date={reminder.date ? dateConverter["YYYY-MM-DD_to_mM/dD/YY"](reminder.date) : null}
								title={reminder.markupTitle}
								buttons={reminder.actions?.map((actionString) => {
									let actionConfig = actionString.split("||");

									let actionName = actionConfig[0];
									let actionParams: { [attr: string]: string } = {};
									if (actionConfig.length > 1) {
										for (let i = 1; i < actionConfig.length; i++) {
											let p = actionConfig[i].split("|");
											actionParams[p[0]] = p[1];
										}
									}
									if (typeCheck_EventTypeActionNames(actionName)) {
										let a = event_actions[actionName];
										if (a) {
											return {
												action: a.action.bind(null, null, actionParams) as () => any,
												text: a.text,
											};
										} else {
											console.warn(`actionName "${actionName}" not recognized`);
										}
									} else {
										window.RadiusCare.log_issue({ file: "Reminders", message: `unrecognized action: ${actionName}` });
									}
									return null;
								})}
								ignorable={reminder.ignorable}
							/>
						);
					}
					return null;
				})
				.filter((x) => x !== undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AccountManager.userDetails]);

	return (
		<Stack>
			{reminders && reminders.length > 0 ? (
				reminders
			) : (
				<Typography variant="entity-descriptor">
					You have no Reminders<div></div>
				</Typography>
			)}

			<UserLocationSettingsModal
				open={locationModalOpen}
				onClose={() => {
					set_locationModalOpen(false);
				}}
			/>
		</Stack>
	);
};

export { Reminders };
