/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ModelsOperationStatus
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getErrorSummary = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/getErrorSummary`, method: 'get', signal
    },
      options);
    }
  

export const getGetErrorSummaryQueryKey = () => [`/getErrorSummary`];

    
export type GetErrorSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getErrorSummary>>>
export type GetErrorSummaryQueryError = unknown

export const useGetErrorSummary = <TData = Awaited<ReturnType<typeof getErrorSummary>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getErrorSummary>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetErrorSummaryQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getErrorSummary>>> = ({ signal }) => getErrorSummary(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getErrorSummary>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

