import { Typography } from "@mui/material";
import { ModelsGeoLocation, retrieveGoogleMapsLocation } from "api";
import { useEffect, useState } from "react";
import { Loader } from "../Loaders/Loader";
import OverviewMap from "./OverviewMap";

type AddressMapProps = {
	address?: string | null;
	zoom?: number;
};

function AddressMap(props: AddressMapProps) {
	let [coordinates, set_coordinates] = useState<ModelsGeoLocation | null>(null);
	let [loading, set_loading] = useState<boolean>(false);

	useEffect(() => {
		if (props.address) {
			set_loading(true);
			retrieveGoogleMapsLocation({ address: props.address }).then((r) => {
				if (r.response && r.response.success && r.response.data) {
					if (r.response.data.results && r.response.data.results.length > 0) {
						let result = r.response.data.results[0];
						if (result.geometry?.location) {
							set_coordinates(result.geometry.location);
						}
					}
				}
				set_loading(false);
			});
		}
	}, [props.address]);

	if (coordinates && coordinates.lat && coordinates.lng) {
		return (
			<OverviewMap
				center={{
					lat: coordinates.lat,
					lng: coordinates.lng,
				}}
				zoom={props.zoom ?? 12}
				address={props.address ?? ""}
			/>
		);
	}

	if (loading) {
		return <Loader />;
	}

	return <Typography>Map data not available.</Typography>;
}

export default AddressMap;
