import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, styled, Stack } from "@mui/material";
import { icons } from "theme";
import Icon from "../components/Icons/Icon";
import useFormStatus from "./useFormStatus";

type Variant = "submit" | "save";
type IndicatorState = "idle" | "loading" | "success" | "error";

type SubmitButtonProps = React.PropsWithChildren<{
  variant?: Variant;
  disabled?: boolean;
}>;

function SubmitButton({
  variant = "submit",
  disabled,
  children,
}: SubmitButtonProps) {
  const { isLoading, isSuccess, isError } = useFormStatus();
  const timeoutHandle = useRef<any>();

  const [state, setState] = useState<IndicatorState>("idle");

  useEffect(() => {
    if (isLoading) setState("loading");

    if (state !== "idle" && isSuccess) {
      timeoutHandle.current = setTimeout(() => setState("success"), 500);
    }

    if (state !== "idle" && isError) {
      timeoutHandle.current = setTimeout(() => setState("error"), 200);
    }

    if (state === "success" || state === "error") {
      timeoutHandle.current = setTimeout(() => setState("idle"), 3000);
    }

    return () => clearTimeout(timeoutHandle.current);
  }, [state, setState, isLoading, isSuccess, isError]);

  return (
    <Button
      type="submit"
      disabled={disabled || isLoading || false}
      sx={{
        position: "relative",
        [getAlignment(variant) === "left" ? "pl" : "pr"]: 4,
      }}
    >
      {children ?? getDefaultVariantText(variant)}
      {getIndicator(state, variant)}
    </Button>
  );
}

interface IndicatorProps {
  variant: Variant;
  color: string;
  label: string;
  icon: React.ReactNode;
}

function IconIndicator({ variant, color, label, icon }: IndicatorProps) {
  return (
    <Stack
      component="span"
      direction={getAlignment(variant) === "left" ? "row" : "row-reverse"}
      alignItems="center"
      sx={{
        color: `${color}.main`,
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 2,
        px: 1,
      }}
      title={label}
    >
      {icon}
    </Stack>
  );
}

function getIndicator(state: IndicatorState, variant: Variant) {
  switch (state) {
    case "idle":
      return (
        <IconIndicator
          variant={variant}
          color="inherit"
          label={getDefaultVariantText(variant)}
          icon={
            <Icon
              fixedWidth
              icon={variant === "save" ? icons.save : icons.next}
            />
          }
        />
      );
    case "loading":
      return (
        <IconIndicator
          variant={variant}
          color="primary"
          label="Working"
          icon={<CircularProgress size={14} sx={{ mx: "2px" }} />}
        />
      );
    case "success":
      return (
        <IconIndicator
          variant={variant}
          color="success"
          label="Success"
          icon={<Icon fixedWidth icon={icons.success} />}
        />
      );
    case "error":
      return (
        <IconIndicator
          variant={variant}
          color="error"
          label="Error"
          icon={<Icon fixedWidth icon={icons.error} />}
        />
      );
  }
}

function getDefaultVariantText(variant: Variant) {
  if (variant === "submit") {
    return "Submit";
  }

  if (variant === "save") {
    return "Save Changes";
  }

  return "Continue";
}

function getAlignment(variant: Variant): "left" | "right" {
  if (variant === "save") {
    return "left";
  }

  return "right";
}

export default styled(SubmitButton)({});
