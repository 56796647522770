import { Box, Button, MenuItem, Stack, Typography } from "@mui/material";
import { ButtonBar } from "common/components/Buttons/ButtonBar";
import { useContext, useEffect, useMemo, useState } from "react";
// import { CheckBoxGrid } from "common/components/Inputs/CheckBoxGrid";
import { StandardOption } from "common/types/generic";
import { SliderStandard } from "common/components/Inputs/SliderStandard";
import { palette } from "theme";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { StaticDataDefinitionsContext } from "common/context/MainContext";
import { GeolocationContext } from "common/context/GeolocationContext";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import displayConverter from "common/utilities/displayConverter";
import { StarRatingInput } from "common/components/Inputs/StarRatingInput";
import { useSearchParams } from "react-router-dom";
import { StandardAutocomplete } from "common/components/Inputs/StandardAutocomplete";

type FindCareProviderFiltersProps = {
	rating: number | null;
	set_rating: (option: number | null) => void;
	inNetwork: boolean;
	set_inNetwork: null | ((option: boolean) => void);
	providerType: StandardOption<string, string>;
	set_providerType: (option: StandardOption<string, string>) => void;
	facilityType: StandardOption<string, string>;
	set_facilityType: (option: StandardOption<string, string>) => void;
	physicianType: StandardOption<string, string>;
	set_physicianType: (option: StandardOption<string, string>) => void;
	classification: StandardOption<string, string>;
	set_classification: (option: StandardOption<string, string>) => void;
	gender: StandardOption<string, string>;
	set_gender: (option: StandardOption<string, string>) => void;
	languages: Array<string | number>;
	set_languages: (languages: Array<string | number>) => void;
	distance: number | null;
	set_distance: (distance: number) => void;
	zipcode: string | null;
	set_zipcode: (zipcode: string) => void;
	set_locationModalOpen?: () => void;
};

const inNetworkOptions: Array<StandardOption<string, boolean>> = [
	{ name: "All", value: false },
	{ name: "Network", value: true },
];

const FindCareProviderFilters = ({
	rating,
	set_rating,
	inNetwork,
	set_inNetwork,
	providerType,
	set_providerType,
	facilityType,
	set_facilityType,
	physicianType,
	set_physicianType,
	classification,
	set_classification,
	gender,
	set_gender,
	languages,
	set_languages,
	distance,
	set_distance,
	zipcode,
	set_zipcode,
	set_locationModalOpen,
}: FindCareProviderFiltersProps) => {
	const geo = useContext(GeolocationContext);
	useEffect(() => {
		GeolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
	}, [geo.position]);

	const SDDC = useContext(StaticDataDefinitionsContext);

	const [zipcodeDisplayValue, set_zipcodeDisplayValue] = useState<string>("");

	useEffect(() => {
		if (zipcode && zipcode !== zipcodeDisplayValue) {
			set_zipcodeDisplayValue(zipcode);
		}
		// set_geolocationText(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [zipcode]);

	// const [classificationAutocompleteValue, set_classificationAutocompleteValue] = useState<Array<string>>([]);

	// const [geolocationText, set_geolocationText] = useState<string | null>(null);

	/*
██ ███    ██ ██ ████████     ███████ ██████   ██████  ███    ███     ██    ██ ██████  ██          ██████   █████  ██████   █████  ███    ███ ███████ 
██ ████   ██ ██    ██        ██      ██   ██ ██    ██ ████  ████     ██    ██ ██   ██ ██          ██   ██ ██   ██ ██   ██ ██   ██ ████  ████ ██      
██ ██ ██  ██ ██    ██        █████   ██████  ██    ██ ██ ████ ██     ██    ██ ██████  ██          ██████  ███████ ██████  ███████ ██ ████ ██ ███████ 
██ ██  ██ ██ ██    ██        ██      ██   ██ ██    ██ ██  ██  ██     ██    ██ ██   ██ ██          ██      ██   ██ ██   ██ ██   ██ ██  ██  ██      ██ 
██ ██   ████ ██    ██        ██      ██   ██  ██████  ██      ██      ██████  ██   ██ ███████     ██      ██   ██ ██   ██ ██   ██ ██      ██ ███████ 
	*/
	// Initialize from URL Params
	const [searchParams] = useSearchParams();
	// console.debug("searchParams", searchParams);

	useEffect(() => {
		// console.log("EFFECT:searchParams", searchParams);
		let urlGet = (() => {
			let z: { [key: string]: string } = {};
			Array.from(searchParams.entries()).forEach((x) => {
				z[x[0]] = x[1];
			});
			return z;
		})();
		// console.log(urlGet);

		if (urlGet.type === "Doctors") {
			set_providerType({ name: "Doctor", value: "physician" });
			set_physicianType({ name: "Specialists", value: "specialists" });
			if (typeof urlGet.type2 === "string") {
				set_classification({ name: urlGet.type2, value: urlGet.type2 });
				// set_classificationAutocompleteValue([urlGet.type2]);
			}
		} else if (urlGet.type === "Facilities") {
			set_providerType({ name: "Facility", value: "facility" });
			if (typeof urlGet.type2 === "string") {
				set_facilityType({ name: urlGet.type2, value: urlGet.type2 });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	// const specialtyOptions = useMemo(() => {
	// 	return SDDC.providerTaxonomyClassifications.map((classification, i) => {
	// 		return (
	// 			<MenuItem key={i} value={classification}>
	// 				{classification}
	// 			</MenuItem>
	// 		);
	// 	});
	// 	// return SDDC.providerTaxonomyPackage.Classification_Values.map((classification, i) => {
	// 	// 	return (
	// 	// 		<MenuItem key={i} value={classification}>
	// 	// 			{classification}
	// 	// 		</MenuItem>
	// 	// 	);
	// 	// });
	// }, [SDDC.providerTaxonomyPackage.Classification_Values]);

	const specialtyAutoCompleteOptions = useMemo(() => {
		return SDDC.providerTaxonomyClassifications.map((classification, i) => {
			return { name: classification, value: classification };
		});
		// return SDDC.providerTaxonomyPackage.Classification_Values.map((classification, i) => {
		// 	// return { label: classification, id: classification };
		// 	return { name: classification, value: classification };
		// 	// return classification;
		// });
	}, [SDDC.providerTaxonomyClassifications]);

	/*
██████  ██   ██ ██    ██ ███████ ██  ██████ ██  █████  ███    ██     ███████ ██ ██      ████████ ███████ ██████  ███████ 
██   ██ ██   ██  ██  ██  ██      ██ ██      ██ ██   ██ ████   ██     ██      ██ ██         ██    ██      ██   ██ ██      
██████  ███████   ████   ███████ ██ ██      ██ ███████ ██ ██  ██     █████   ██ ██         ██    █████   ██████  ███████ 
██      ██   ██    ██         ██ ██ ██      ██ ██   ██ ██  ██ ██     ██      ██ ██         ██    ██      ██   ██      ██ 
██      ██   ██    ██    ███████ ██  ██████ ██ ██   ██ ██   ████     ██      ██ ███████    ██    ███████ ██   ██ ███████
*/
	const physicianFilters = (
		<>
			<Box sx={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Physician Type
				</Typography>
				<ButtonBar
					buttons={SDDC.physicianTypes}
					selected={physicianType.value}
					setSelected={(value: string) => {
						let option = SDDC.physicianTypes.find((g: StandardOption<string, string>) => {
							return g.value === value;
						});
						if (option) {
							set_physicianType(option);
						}
					}}
					equalSize={true}
				/>
			</Box>

			{physicianType.value === "specialists" ? (
				<Box sx={{ paddingBottom: "1rem" }}>
					<Typography id="classification-select-label" variant="h5" marginBottom={".5rem"}>
						Specialization
					</Typography>
					{/* Leave this - may use in future*/}
					<StandardAutocomplete
						multiple={false}
						autoComplete
						value={classification.value !== "" ? classification : undefined}
						// options={SDDC.providerTaxonomyPackage.Specialization_Values}
						options={specialtyAutoCompleteOptions}
						onChange={(_event, option) => {
							let x: StandardOption<string, string> = { name: "", value: "" };

							if (typeof option === "string") {
								x = { name: option, value: option };
							} else if (option && typeof option === "object") {
								if (Array.isArray(option)) {
									option = option[0];
								}
								if ((option as CIM).value) {
									x = { name: (option as CIM).value, value: (option as CIM).value };
								}
							}
							// set_classificationAutocompleteValue([x.value]);
							set_classification(x);
						}}
						getOptionLabel={(option: CIM) => {
							if (typeof option === "string") {
								option = { name: option, value: option };
							}
							if (option.value) {
								return option.value;
							}
							return "";
						}}
					/>
					{/* <StandardSelect
						nullable
						labelId="classification-select-label"
						id="classification-select"
						variant="standard"
						value={classificationAutocompleteValue && classificationAutocompleteValue.length > 0 ? classificationAutocompleteValue[0] : ""}
						onChange={(x) => {
							let v: string | undefined;
							if (x.target.value) {
								v = String(x.target.value);
							}
							if (v && v !== "CLEAR_SELECTION") {
								set_classificationAutocompleteValue([v]);
								set_classification({ name: v, value: v });
							} else {
								set_classificationAutocompleteValue([]);
								set_classification({ name: "", value: "" });
							}
						}}
					>
						{specialtyOptions}
					</StandardSelect> */}
				</Box>
			) : null}
			{set_inNetwork ? (
				<Box sx={{ paddingBottom: "1rem" }}>
					<Typography variant="h5" marginBottom={".5rem"} component="div" sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
						In Network
					</Typography>
					<ButtonBar
						buttons={inNetworkOptions}
						selected={inNetwork}
						setSelected={(value: boolean) => {
							// let option = inNetworkOptions.find((g: StandardOption<string, boolean>) => {
							// 	return g.value === value;
							// });
							// if (option) {
							set_inNetwork(!!value);
							// }
						}}
						equalSize={true}
					/>
				</Box>
			) : null}
			<Box sx={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Gender
				</Typography>
				<ButtonBar
					buttons={SDDC.genders}
					selected={gender.value}
					setSelected={(value: string) => {
						let option = SDDC.genders.find((g: StandardOption<string, string>) => {
							return g.value === value;
						});
						if (option) {
							set_gender(option);
						}
					}}
					equalSize={true}
				/>
			</Box>
			{/* <Box sx={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Languages Spoken
				</Typography>
				<CheckBoxGrid activeOptionValues={languages} setActiveOptionValues={set_languages} columnCount={2} options={SDDC.languages} />
			</Box> */}
		</>
	);
	/*
███████  █████   ██████ ██ ██      ██ ████████ ██    ██     ███████ ██ ██      ████████ ███████ ██████  ███████ 
██      ██   ██ ██      ██ ██      ██    ██     ██  ██      ██      ██ ██         ██    ██      ██   ██ ██      
█████   ███████ ██      ██ ██      ██    ██      ████       █████   ██ ██         ██    █████   ██████  ███████ 
██      ██   ██ ██      ██ ██      ██    ██       ██        ██      ██ ██         ██    ██      ██   ██      ██ 
██      ██   ██  ██████ ██ ███████ ██    ██       ██        ██      ██ ███████    ██    ███████ ██   ██ ███████
*/
	const facilityFilters = (
		<Box sx={{ paddingBottom: "1rem" }}>
			<Typography id="facilityTypes-select-label" variant="h5" marginBottom={".5rem"}>
				Facility Type
			</Typography>
			<StandardSelect
				nullable={true}
				labelId="condition-select-label"
				id="condition-select"
				variant="standard"
				value={facilityType.value ?? ""}
				onChange={(x) => {
					if (x.target.value === "CLEAR_SELECTION") {
						set_facilityType({ name: "", value: "" });
					}
					let option = SDDC.facilityTypes.find((g: StandardOption<string, string>) => {
						return g.value === x.target.value;
					});
					if (option) {
						set_facilityType(option);
					}
				}}
			>
				{SDDC.facilityTypes.map((item, i) => {
					return (
						<MenuItem key={i} value={item.value}>
							{item.name}
						</MenuItem>
					);
				})}
			</StandardSelect>
		</Box>
	);

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██
*/
	return (
		<Stack>
			<Box sx={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Radius Rating
				</Typography>
				<StarRatingInput rating={rating} set_rating={set_rating} nullable />
			</Box>
			<Box sx={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Provider Type
				</Typography>
				<ButtonBar
					buttons={SDDC.providerTypes}
					selected={providerType.value}
					setSelected={(value: string) => {
						let option = SDDC.providerTypes.find((g: StandardOption<string, string>) => {
							return g.value === value;
						});
						if (option) {
							set_providerType(option);
						}
					}}
					equalSize={true}
				/>
			</Box>
			{providerType.value === "physician" ? physicianFilters : facilityFilters}
			<Box>
				<Box sx={{ paddingBottom: "1rem" }}>
					<Typography variant="h5" marginBottom={".5rem"}>
						Distance
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "row" }}>
						<Box sx={{ flexBasis: 4, flexGrow: 4, padding: "0px 8px" }}>
							<SliderStandard
								// disabled={!(geo.position && geo.position.coords && geo.position.coords.latitude && geo.position.coords.longitude)}
								aria-label="Volume"
								onChange={(_event, newValue) => {
									set_distance(Math.round(newValue));
								}}
								defaultValue={25}
								minValue={10}
								maxValue={150}
							/>
						</Box>
						<Box sx={{ flexBasis: 1, flexGrow: 1 }}>
							<Typography
								sx={{
									width: "100%",
									fontWeight: "500",
									textAlign: "right",
									borderBottom: `2px solid ${palette.gray.dark}`,
									color: palette.gray.dark,
								}}
							>
								{distance !== null ? distance + "mi" : null}&nbsp;
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={{ paddingBottom: "1rem" }}>
					<Typography variant="h5" marginBottom={".5rem"}>
						ZIP Code
					</Typography>
					<StandardTextField
						sx={{ width: "100%" }}
						placeholder="5 digit zip code"
						value={zipcodeDisplayValue}
						onChange={(x: React.ChangeEvent<HTMLInputElement>) => {
							let value = displayConverter.filterNonNumeric(x.target.value);
							if (value.length <= 5) {
								set_zipcodeDisplayValue(value);
								if (value.length === 5) {
									set_zipcode(value);
								} else {
									set_zipcode("");
								}
							}
						}}
					/>
					<Typography marginTop={".5rem"}>{zipcode ? `Searching by Zip Code ${zipcode}` : "Searching by your location"}</Typography>
				</Box>
				{!zipcode && !(geo.position && geo.position.coords && geo.position.coords.latitude && geo.position.coords.longitude) ? (
					// <>
					// 	<Button
					// 		onClick={() => {
					// 			set_geolocationText(null);
					// 			GeolocationManager.getPosition({ type: "manual" })
					// 				// .then((x) => {
					// 				// 	// console.debug("x", x);
					// 				// })
					// 				.catch((e) => {
					// 					// console.debug("e", e);
					// 					if (e === "MANUAL_SETTINGS_REQUIRED") {
					// 						set_geolocationText("You may need to update your permissions manually or reload the page");
					// 					}
					// 				});
					// 		}}
					// 	>
					// 		Enable Location Permissions
					// 	</Button>
					// 	{geolocationText ? (
					// 		<Typography component="div" variant="missing-data">
					// 			{geolocationText}
					// 		</Typography>
					// 	) : null}
					// </>
					<Typography component="div" variant="missing-data">
						We were unable to access your location, please enter your zipcode above
					</Typography>
				) : null}
				{set_locationModalOpen ? <Button onClick={set_locationModalOpen}>Set a default Zipcode</Button> : null}
			</Box>
		</Stack>
	);
};

export { FindCareProviderFilters };
