import { Box, Popover, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { palette } from "theme";

type CustomPopperProps = {
	open: boolean;
	set_open: React.Dispatch<boolean>;
	anchorRef: React.MutableRefObject<any>;
	title?: string;
	texts?: Array<string>;
	maxWidth?: number;
};
const CustomPopper = ({ anchorRef, open, set_open, title, texts, maxWidth, children }: PropsWithChildren<CustomPopperProps>) => {
	if (anchorRef.current) {
		return (
			<Popover
				className="custom-popper"
				open={open}
				anchorEl={anchorRef.current}
				onClose={() => {
					set_open(false);
				}}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					"& > .MuiPopover-paper": {
						boxShadow: 4,
						backgroundColor: "#ffffff",
						padding: "1rem",
					},
					"& .MuiButton-text": {
						width: "100%",
						borderBottom: `2px solid ${palette.gray.main}`,
						borderRadius: "0",
					},
					"& .MuiButton-root:hover": {
						backgroundColor: "transparent"
					}
				}}
			>
				<Box>
					{title ? <Typography component="div">{title}</Typography> : null}
					{texts
						? texts.map((t: string, i: number) => {
								return (
									<Typography key={i} component="div">
										{t}
									</Typography>
								);
						  })
						: null}
					{children}
				</Box>
			</Popover>
		);
	}
	return null;
};
export { CustomPopper };
