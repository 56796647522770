import { EventDescription } from "common/definitions/EventDefinitions";
// import { dateConverter } from "common/utilities/dateConverter";
import displayConverter from "common/utilities/displayConverter";
import { HealthSnapshotConditions } from "healthSnapshot/pages/conditions/HealthSnapshotConditions";
import { HealthSnapshotMedications } from "healthSnapshot/pages/medications/HealthSnapshotMedications";
import { HealthSnapshotProviders } from "healthSnapshot/pages/providers/HealthSnapshotProviders";
import { HealthSnapshotVisits } from "healthSnapshot/pages/visits/HealthSnapshotVisits";
import { useQ_retrievePatientEOBDataX } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { useMemo, useState } from "react";
import { Routes, Route, Navigate } from "react-router";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { HealthPlanReauthTag } from "healthSnapshot/components/HealthPlanReauthTag";
import { HealthSnapshotMenu } from "healthSnapshot/HealthSnapshotMenu";
import { Grid } from "@mui/material";

function HealthSnapshotRoutes() {
	const _startDate = useMemo(() => {
		let s = new Date();
		s.setFullYear(s.getFullYear() - 1);
		return s;
	}, []);
	const [startDate, set_startDate] = useState<Date | null>(_startDate);
	const [endDate, set_endDate] = useState<Date | null>(null);

	const dateRangeSelection = { startDate, set_startDate, endDate, set_endDate };

	const patientEOBData = useQ_retrievePatientEOBDataX({
		startDate: startDate,
		endDate: endDate,
		includeLookUps: false,
		includeKeyFindings: _startDate === startDate ? true : false, // only on initial call which matches the default calls thus utilizing the useQuery cached response
		clientLocation: GeolocationManager.clientLocation(),
		caller: "HealthSnapshotRoutes",
	});
	// May want again
	// let startDateString = useMemo(() => {
	// 	return dateRangeSelection.startDate ? dateConverter.stringDateForDisplay(dateRangeSelection.startDate, { zeroPadDate: false }) : null;
	// }, [dateRangeSelection.startDate]);
	// May want again
	// let lastRecordDate = useMemo(() => {
	// 	if (patientEOBData.data?.data) {
	// 		if (patientEOBData.data.data.visitRecordIDs.length && patientEOBData.data.data.EOB_RecordID_Map) {
	// 			let x = patientEOBData.data.data.EOB_RecordID_Map.get(patientEOBData.data.data.visitRecordIDs[0]);
	// 			if (x && x.date) {
	// 				return dateConverter["YYYY-MM-DD_to_mM/dD/YY"](x.date, true);
	// 			}
	// 		}
	// 	}
	// 	return null;
	// }, [patientEOBData.data]);

	const healthPlanReauthTag = useMemo(() => {
		return <HealthPlanReauthTag eobData={patientEOBData.data?.data ? patientEOBData.data.data : null} />;
	}, [patientEOBData]);

	const keyFindings: EventDescription[] = useMemo(() => {
		if (patientEOBData.data?.data) {
			let kf: EventDescription[] = [];
			if (patientEOBData.data.data.overview) {
				kf.push({
					type: "provider",
					title: `You have <<${patientEOBData.data.data.overview.explanationOfBenefitRecordCount}>> total <<Medical History Records>>`,
					// date: `Since ${startDateString}`,
				});
				kf.push({
					type: "condition",
					title: `You have been diagnosed with <<${patientEOBData.data.data.overview.conditionCount}>> distinct <<Diagnosis Codes>>`,
					// date: `Since ${startDateString}`,
				});
				kf.push({
					type: "provider",
					title: `You have had interactions with <<${patientEOBData.data.data.overview.providerCount}>> distinct healthcare <<Providers>>`,
					// date: `Since ${startDateString}`,
				});
				kf.push({
					type: "appointment",
					title: `You have had <<${patientEOBData.data.data.overview.visitCount}>> healthcare <<Visits>>`,
					// date: `Since ${startDateString}`,
				});
				kf.push({
					type: "costs",
					title: `You have made <<${displayConverter.toUSD(patientEOBData.data.data.overview.totalPaymentAmount)}>> in healthcare <<Payments>>`,
					// date: `Since ${startDateString}`,
				});
			}
			return kf;
		} else {
			return [];
		}
	}, [patientEOBData.data?.data]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<HealthSnapshotMenu patientEOBData={patientEOBData} dateRangeSelection={dateRangeSelection} />
			</Grid>
			<Grid item xs={12}>
				<Routes>
					<Route path="visits" element={<HealthSnapshotVisits patientEOBData={patientEOBData} healthPlanReauthTag={healthPlanReauthTag} />} />
					<Route
						path="providers"
						element={
							<HealthSnapshotProviders keyFindings={keyFindings} patientEOBData={patientEOBData} healthPlanReauthTag={healthPlanReauthTag} />
						}
					/>
					<Route
						path="conditions"
						element={
							<HealthSnapshotConditions keyFindings={keyFindings} patientEOBData={patientEOBData} healthPlanReauthTag={healthPlanReauthTag} />
						}
					/>
					<Route
						path="medication"
						element={
							<HealthSnapshotMedications keyFindings={keyFindings} patientEOBData={patientEOBData} healthPlanReauthTag={healthPlanReauthTag} />
						}
					/>
					<Route path={"*"} element={<Navigate to="visits" />} />
				</Routes>
			</Grid>
		</Grid>
	);
}

export { HealthSnapshotRoutes };
