import { Box, Stack, Typography } from "@mui/material";
import { Entity, entity_actions, entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { ReactNode } from "react";
import { isProviderEntity } from "common/definitions/entity/ProviderEntity";
import { isMedicationEntity } from "common/definitions/entity/MedicationEntity";
import { isConditionEntity } from "common/definitions/entity/ConditionEntity";
import { OptionsButton } from "../Buttons/OptionsButton";

interface MedicationSearchTileProps {
	Entity: Entity;
	content?: ReactNode;
}
const MedicationSearchTile = ({ Entity, content }: MedicationSearchTileProps) => {
	const { entityTypeName, title, descriptor } = Entity;
	const entityType = entity_types[entityTypeName];

	return (
		<Stack
			data-componentname="MedicationSearchTile"
			className="search-result-tile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				overflow: "hidden",
			}}
		>
			<Stack
				sx={{
					justifyContent: "flex-start",
					alignItems: { xs: "flex-start", sm: "center" },
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Stack
					sx={{
						flex: "1",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<AvatarDisplay
						imageAlt={title}
						imageSrc={Entity._providerRecord?.imageUrl ?? ""}
						icon={
							<HealthIcon
								color={entityType.iconColor ?? palette.core.electricblue}
								size={48}
								iconSVG={
									Entity._providerRecord?.genderCode
										? Entity._providerRecord?.genderCode === "M"
											? health_icons_svg.doctormale
											: health_icons_svg.doctorfemale
										: entityType.iconSVG
								}
							/>
						}
					/>
					{/* ENTITY DETAILS */}
					<Stack sx={{ display: "flex", flexDirection: "column", paddingLeft: ".5rem", textAlign: "left" }}>
						<Typography sx={{ color: palette.gray.dark, fontWeight: 500 }}>{descriptor}</Typography>
						<Typography sx={{ color: palette.core.blue, fontSize: "1.25rem", cursor: "pointer" }}>
							{isProviderEntity(Entity) || isMedicationEntity(Entity) || isConditionEntity(Entity) ? (
								<Box
									component={"span"}
									onClick={() => {
										console.log("click", Entity);
										entity_actions.view_details.action(Entity);
									}}
								>
									{title}
								</Box>
							) : (
								title
							)}
						</Typography>
					</Stack>
				</Stack>

				<Stack sx={{ justifyContent: "center", alignItems: "center", width: { xs: "100%", sm: "auto" }, paddingTop: { xs: ".5rem", sm: "0" } }}>
					{<OptionsButton content={content} />}
				</Stack>
			</Stack>
		</Stack>
	);
};

export { MedicationSearchTile };
