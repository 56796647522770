export const OpensearchDataExporter = {
	insertLimit: 2500,
	maxContentLength: 1040000, //1048576, // use healthy fudge factor
	//maxContentLength: 50000, //1048576, // use healthy fudge factor
	insertHeader: "POST _bulk\r\n",
	itemHeader: (id: string | number, index: string) => {
		if (typeof id === "string") {
			id = '"' + id + '"';
		}
		return `{ "create": { "_index":"${index}", "_id": ${id} } }\r\n`;
	},
	batcher: (data: any[]) => {
		const batches: any[] = [];
		let curBatch = 0;
		let lim = OpensearchDataExporter.insertLimit;
		while (curBatch * lim < data.length) {
			console.debug("batch", curBatch);
			batches.push(data.slice(curBatch * lim, (curBatch + 1) * lim));

			curBatch++;
		}
		console.log(data.length);
		return batches;
	},
	outputter: (indexName: string, IDKey: string, batches: Array<any[]>, itemMapper: (x: any) => any = (x: any) => x) => {
		batches.forEach((batch, curBatch) => {
			let command = OpensearchDataExporter.insertHeader;
			batch.forEach((x) => {
				command += OpensearchDataExporter.itemHeader(x[IDKey], indexName);
				command += JSON.stringify(itemMapper(x)) + "\r\n";
			});
			downloadBlob(command, `${indexName}_${curBatch}.txt`);
		});
	},

	batcher_contentLength: (data: Array<any>, indexName: string, IDKey: string, itemMapper: (x: any) => any = (x: any) => x) => {
		let IEC = OpensearchDataExporter;
		const batches: Array<BatchedCommandItem> = [];
		let z: BatchedCommandItem = { startIndex: 0, endIndex: -1, command: "" };

		data.forEach((x: any, index) => {
			let curCommand = IEC.itemHeader(x[IDKey], indexName) + JSON.stringify(itemMapper(x)) + "\r\n";
			if (z.command.length + curCommand.length > IEC.maxContentLength) {
				//seal current batch and start a new one
				z.endIndex = index - 1;
				z.command = IEC.insertHeader + z.command;
				batches.push(z);
				z = { startIndex: index, endIndex: -1, command: "" };
			}
			z.command += curCommand;

			if (data.length - 1 === index) {
				z.endIndex = index;
				z.command = IEC.insertHeader + z.command;
				batches.push(z);
			}
		});

		batches.forEach((b, i) => {
			downloadBlob(b.command, `${indexName}_${i}_${b.startIndex}-${b.endIndex}.txt`);
		});
		return batches;
	},

	/*
	
		CSV exporter
	*/
	create_csv_text(x: Array<{ [key: string]: string | number | boolean | any }>, keys: Array<string>) {
		let csvString = keys.join(",") + "\r\n";

		for (let i = 0; i < x.length; i++) {
			csvString += keys.map((key) => x[i][key]).join(",") + "\r\n";
		}

		console.debug(csvString);

		return csvString;
	},

	create_paul_csv(csvString: string, header: string) {
		var fileContents = header + "\r\n----------\r\n" + csvString;
		return fileContents;
	},
};

type BatchedCommandItem = { startIndex: number; endIndex: number; command: string };
export function downloadBlob(content: string, filename: string, contentType: string = "text/csv;charset=utf-8;") {
	// Create a blob
	var blob = new Blob([content], { type: contentType });
	var url = URL.createObjectURL(blob);

	// Create a link to download it
	var pom = document.createElement("a");
	pom.href = url;
	pom.setAttribute("download", filename);
	pom.click();
}
