import { Box, MenuItem, Typography } from "@mui/material";
import { ButtonBar } from "common/components/Buttons/ButtonBar";
import { useContext } from "react";
import { StandardOption } from "common/types/generic";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { StaticDataDefinitionsContext } from "common/context/MainContext";

type FindCareMedicationFiltersProps = {
	medicationBrandType: StandardOption<string, string>;
	set_medicationBrandType: (option: StandardOption<string, string>) => void;
	condition: StandardOption<string, string>;
	set_condition: (option: StandardOption<string, string>) => void;
	// distance: number | null;
	// set_distance: (distance: number) => void;
};

const FindCareMedicationFilters = ({
	medicationBrandType,
	set_medicationBrandType,
	condition,
	set_condition,
}: // distance,
// set_distance,
FindCareMedicationFiltersProps) => {
	const SDDC = useContext(StaticDataDefinitionsContext);

	return (
		<Box>
			<Box style={{ paddingBottom: "1rem" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Type
				</Typography>
				<ButtonBar
					buttons={SDDC.medicationBrandTypes}
					selected={medicationBrandType.value}
					setSelected={(value: string) => {
						let option = SDDC.medicationBrandTypes.find((g: StandardOption<string, string>) => {
							return g.value === value;
						});
						if (option) {
							set_medicationBrandType(option);
						}
					}}
					equalSize={true}
				/>
			</Box>
			<Box style={{ paddingBottom: "1rem" }}>
				<Typography id="condition-select-label" variant="h5" marginBottom={".5rem"}>
					Condition
				</Typography>
				<StandardSelect
					labelId="condition-select-label"
					id="condition-select"
					variant="standard"
					value={condition.value}
					onChange={(x) => {
						let option = SDDC.conditions.find((opt: StandardOption<string, string>) => {
							return opt.value === x.target.value;
						});
						if (option) {
							set_condition(option);
						}
					}}
				>
					{SDDC.conditions.map((cond, i) => {
						return (
							<MenuItem key={i} value={cond.value}>
								{cond.name}
							</MenuItem>
						);
					})}
				</StandardSelect>
			</Box>
			{/* <Box sx={{}}>
        <Typography component="div" variant="form-label">
          Distance
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flexBasis: 4, flexGrow: 4, padding: "0px 8px" }}>
            <SliderStandard
              aria-label="Volume"
              onChange={(_event, newValue) => {
                set_distance(Math.round(newValue));
              }}
              defaultValue={25}
              minValue={10}
              maxValue={150}
            />
          </Box>
          <Box sx={{ flexBasis: 1, flexGrow: 1 }}>
            <Typography sx={{ width: "100%", fontWeight: "500", textAlign: "right", borderBottom: `2px solid ${palette.gray.dark}`, color: palette.gray.dark }}>
              {distance !== null ? distance + "mi" : null}&nbsp;
            </Typography>
          </Box>
        </Box>
      </Box> */}
		</Box>
	);
};

export { FindCareMedicationFilters };
