import { ConsumerOperationStatusRetrievePatientHealthDataResponseModel, retrievePatientEOBData } from "api";
import { GetProviderMapByNPIs } from "api_extended/consumer-doctor/GetProviderMapByNPIs";
import {
	ConsumerRetrievePatientHealthDataResponseModel_Extended,
	ModelsExplanationOfBenefitTreatmentAssessment_Extended,
} from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { GetDrugDataMapByNDCs } from "api_extended/consumer-treatment/GetDrugDataMapByNDCs";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { ProcessedResponse, apiResponseReader } from "common/utilities/apiResponseReader";
import { dateConverter } from "common/utilities/dateConverter";
import { useQuery, UseQueryResult } from "react-query";
import { ModelsClientLocation, ModelsExplanationOfBenefit, ModelsOperationStatus, ModelsTerminalDrugStructureData } from "../../../api/backend.schemas";

interface retrievePatientEOBData_APIParamX {
	startDate: Date | null;
	endDate: Date | null;
	includeLookUps?: boolean;
	includeKeyFindings?: boolean;
	clientLocation?: ModelsClientLocation | null;
	// invalidateOnDateOnly?: boolean;
	caller?: string;
}

interface retrievePatientEOBData_APIParam extends Omit<retrievePatientEOBData_APIParamX, "startDate"> {
	startDate: Date | null;
}

async function _retrievePatientEOBDataX(
	params: retrievePatientEOBData_APIParam
): Promise<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>> {
	console.count("SENT _retrievePatientEOBDataX");
	let result = await retrievePatientEOBData({
		clientLocation: params.clientLocation ?? undefined,
		dateRangeRequest: { startDate: params.startDate ? dateConverter.stringDateForAPI(params.startDate) : undefined },
		includeLookUps: params.includeLookUps,
		includeKeyFindings: params.includeKeyFindings,
	}).then(async (data: ModelsOperationStatus | ConsumerOperationStatusRetrievePatientHealthDataResponseModel) => {
		let pr: ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null> = apiResponseReader.processResponse(
			data
		) as ProcessedResponse<any>;
		let EOB_RecordID_Map = new Map<string, ModelsExplanationOfBenefit>();
		if (pr.success && apiResponseReader.typeCheck_ModelsOperationStatus(pr.data)) {
			pr.data = pr.data.data as ConsumerRetrievePatientHealthDataResponseModel_Extended;

			if (pr.data.keyFindings) {
				keyFindingsLedger.hasRetrievedKeyFindings = true;
			}

			pr.data.EOB_RecordID_Map = EOB_RecordID_Map;
			if (pr.data?.uniqueNPIs) {
				let npis: string = pr.data?.uniqueNPIs.join(",");
				let doctorRecords_NPIMap = await GetProviderMapByNPIs(npis);
				if (doctorRecords_NPIMap) {
					if (npis) {
						npis.split(",").forEach((npi) => {
							if (pr.data?.providerAssessments) {
								let prov = pr.data.providerAssessments[npi];
								if (prov) {
									(prov as CIM).doctorRecord = (doctorRecords_NPIMap as CIM).get(npi);
								}
							}
						});
					}
				} else {
					window.RadiusCare.log_issue({ message: "Failed to retrieve DoctorRecords by NPIS", file: "useQ_retrievePatientEOBData" });
				}
			}

			if (pr.data?.medicationAssessments || pr.data.keyFindings?.mostRecentMedication) {
				let ndcSet = new Set<string>();

				if (pr.data.medicationAssessments) {
					Object.keys(pr.data.medicationAssessments).forEach((id) => {
						ndcSet.add(id);
					});
				}

				if (pr.data.keyFindings?.mostRecentMedication?.ids) {
					pr.data.keyFindings.mostRecentMedication.ids.forEach((id) => {
						ndcSet.add(id);
					});
				}

				let ndcs: string = Array.from(ndcSet).join(",");

				let drugData_NDCMap: false | Map<string, ModelsTerminalDrugStructureData> = await GetDrugDataMapByNDCs(ndcs);
				if (drugData_NDCMap !== false) {
					let map = drugData_NDCMap;
					if (ndcs) {
						ndcs.split(",").forEach((ndc) => {
							if (pr.data?.medicationAssessments) {
								let medAssess = pr.data.medicationAssessments[ndc];
								if (medAssess) {
									(medAssess as CIM)._ndc_data = map.get(ndc);
								}
							}
						});
					}
					if (pr.data.keyFindings?.mostRecentMedication?.subItems) {
						pr.data.keyFindings.mostRecentMedication.subItems.forEach((si: ModelsExplanationOfBenefitTreatmentAssessment_Extended) => {
							si._ndc_data = map.get(si.code);
						});
					}
				} else {
					window.RadiusCare.log_issue({ message: "Failed GetDrugDataMapByNDCs", file: "useQ_retrievePatientEOBData" });
				}
			}

			if (pr.data?.diagnosisAssessments) {
				let diagnosisCodes: Array<string> = Object.keys(pr.data.diagnosisAssessments);
				diagnosisCodes.forEach((code: string, i) => {
					let code_def = (StaticDataManager.data as CIM).ICD10CM_Package.code_map.get(code);
					if (!code_def) {
						let rep_code = code.replace(".", "");
						code_def = (StaticDataManager.data as CIM).ICD10CM_Package.code_map.get(rep_code);
					}
					(pr.data?.diagnosisAssessments as CIM)[code].ICD10Definition = code_def;
				});
			}
			if (pr.data.explanationOfBenefits) {
				pr.data.explanationOfBenefits.forEach((x: ModelsExplanationOfBenefit) => {
					if (x.recordId) {
						EOB_RecordID_Map.set(x.recordId, x);
					}
				});
			}
		} else {
			pr.data = null;
		}
		return pr;
	});
	return result;
}

type useQ_retrievePatientEOBDataX_Response = UseQueryResult<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>>;

const keyFindingsLedger = {
	hasRetrievedKeyFindings: false,
};

function useQ_retrievePatientEOBDataX(
	paramsX: retrievePatientEOBData_APIParamX
): UseQueryResult<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>> {
	if (!paramsX.startDate) {
		let defaultDate = new Date();
		defaultDate.setFullYear(defaultDate.getFullYear() - 1);
		paramsX.startDate = defaultDate;
	}
	let s = dateConverter.stringDateForAPI(paramsX.startDate);
	let e = paramsX.endDate ? dateConverter.stringDateForAPI(paramsX.endDate) : "--";

	let params: retrievePatientEOBData_APIParam = Object.assign({}, paramsX);

	let queryInvalidations: Array<string>;

	let keyFindingsToken = params.includeKeyFindings ? "x" : "";
	if (keyFindingsLedger.hasRetrievedKeyFindings) {
		keyFindingsToken = "x";
		params.includeKeyFindings = false;
	}

	queryInvalidations = ["retrievePatientEOBDataX", `retrievePatientEOBData:${s}|${e}|${params.includeLookUps ? "x" : ""}|${keyFindingsToken}`];

	// console.debug(queryInvalidations, paramsX.caller);

	return useQuery(queryInvalidations, _retrievePatientEOBDataX.bind(null, params), {
		staleTime: 86400000, // 24 * 1000 * 60 * 60 // 1DAY
		cacheTime: 86400000,
	});
}

export { useQ_retrievePatientEOBDataX };
export type { useQ_retrievePatientEOBDataX_Response };
