import { Box, Stack, Typography } from "@mui/material";
import { Entity, entity_actions, entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon } from "../Icons/HealthIcon";
import { ReactNode } from "react";
import { isProviderEntity } from "common/definitions/entity/ProviderEntity";
import { isMedicationEntity } from "common/definitions/entity/MedicationEntity";
import { isConditionEntity } from "common/definitions/entity/ConditionEntity";
import { OptionsButton } from "../Buttons/OptionsButton";

interface MedicationTileProps {
	Entity: Entity;
	content?: ReactNode;
}
const MedicationTile = ({ Entity, content }: MedicationTileProps) => {
	const { entityTypeName, title, descriptor } = Entity;
	const entityType = entity_types[entityTypeName];

	return (
		<Box
			data-componentname="MedicationTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
				<Stack sx={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
					<AvatarDisplay
						imageAlt={title}
						imageSrc={Entity._providerRecord?.imageUrl ?? ""}
						icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={entityType.iconSVG} />}
					/>
					{/* ENTITY DETAILS */}
					<Box sx={{ paddingLeft: ".5rem" }}>
						<Typography variant="entity-descriptor">{descriptor}</Typography>
						<Typography sx={{ color: palette.core.blue, fontSize: "1.25rem", cursor: "pointer" }}>
							{isProviderEntity(Entity) || isMedicationEntity(Entity) || isConditionEntity(Entity) ? (
								<Box
									component={"span"}
									onClick={() => {
										entity_actions.view_details.action(Entity);
									}}
								>
									{title}
								</Box>
							) : (
								title
							)}
						</Typography>
					</Box>
				</Stack>
				<Stack
					sx={{ paddingTop: { xs: ".5rem", sm: "0" }, width: { xs: "100%", sm: "auto" }, "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}
				>
					<OptionsButton content={content} />
				</Stack>
			</Stack>
		</Box>
	);
};

export { MedicationTile };
