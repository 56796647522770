import { ConsumerDoctorGetSimpleHospitalResult, GetSimpleHospitalParams } from "api";
import { useQuery, UseQueryResult } from "react-query";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { getSimpleHospital } from "../../../api/consumer-doctor/consumer-doctor";

async function retrieveSimpleHospital({ id }: GetSimpleHospitalParams): Promise<ProcessedResponse<ConsumerDoctorGetSimpleHospitalResult>> {
	let result = await getSimpleHospital({ id });
	return apiResponseReader.processResponse(result as ConsumerDoctorGetSimpleHospitalResult);
}

function useQ_getSimpleHospital(params: GetSimpleHospitalParams): UseQueryResult<ProcessedResponse<ConsumerDoctorGetSimpleHospitalResult>> {
	return useQuery(["retrieveSimpleHospital", `retrieveSimpleHospital${JSON.stringify(params)}`], retrieveSimpleHospital.bind(null, params));
}

export { useQ_getSimpleHospital };
