import { DoctorRecord_Enhanced } from "common/definitions/entity/ProviderEntity";
// Used to incrementally store response (created for use cacheing npi lookup as doctors continue to be retrieved)
// Use for additional similar cases to speed up and lighten load on api
class DynamicDataManagerClass {
	intializedAt?: number;
	npis: { doctorRecord_NPIMap: Map<string, DoctorRecord_Enhanced> } = {
		doctorRecord_NPIMap: new Map(),
	};
	ndcs: { drugData_NDCMap: Map<string, any> } = {
		drugData_NDCMap: new Map(),
	};

	constructor() {
		if (!(DynamicDataManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(DynamicDataManagerClass as any).instance = this;
		}
		return (DynamicDataManagerClass as any).instance;
	}
}

const DynamicDataManager = new DynamicDataManagerClass();

export { DynamicDataManager, DynamicDataManagerClass };
