import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { MedicationDetails } from "details/medicationDetails/MedicationDetails";
import { DialogManager } from "common/managers/DialogManager";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const MedicationDetailDialog = () => {
	const location = useLocation();
	const { medicationDetailDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("medication-detail");
	const query_medname = searchParams.get("medication-name");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("medication-detail");
			searchParams.delete("medication-name");
			setSearchParams(searchParams);
			medicationDetailDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			if (x.indexOf("|") !== -1) {
				setSearchParams({ "medication-detail": x.split("|")[0], "medication-name": x.split("|")[1] });
			} else {
				setSearchParams({ "medication-detail": x });
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["medication-details"].open = open;
		DialogManager.launchers["medication-details"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== medicationDetailDisplay?.current) {
			medicationDetailDisplay.set_current(d_query);
		}
	}, [d_query, medicationDetailDisplay]);

	return (
		<Dialog id="MedicationDialog" fullScreen open={!!medicationDetailDisplay.current} onClose={close} TransitionComponent={Transition}>
			{medicationDetailDisplay.current ? <MedicationDetails medicationID={medicationDetailDisplay.current} medicationName={query_medname} /> : null}
		</Dialog>
	);
};

export { MedicationDetailDialog };
