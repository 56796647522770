import { Box, Popover, Typography } from "@mui/material";
import { PropsWithChildren, useRef, useState } from "react";
import { palette } from "theme";

type InfoPopperProps = {
	title?: string;
	texts?: Array<string>;
	maxWidth?: number;
};
const InfoPopper = ({ title, texts, maxWidth, children }: PropsWithChildren<InfoPopperProps>) => {
	const ref = useRef();
	const [anchor, set_anchor] = useState<Element | null>(null);

	const open = !!anchor;
	return (
		<Box ref={ref}>
			<Box
				sx={{
					margin: 0,
					backgroundColor: palette.gray.main,
					borderRadius: "50%",
					width: 16,
					height: 16,
					padding: 0,
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					cursor: "pointer",
					transition: "background-color .5s",
					"&:hover": {
						backgroundColor: palette.gray.dark,
					},
					"& > svg > path": {
						fill: palette.gray.dark,
						transition: "fill .5s",
					},
					"&:hover > svg > path": {
						fill: palette.gray.darker,
					},
				}}
				onClick={() => {
					set_anchor(ref.current ?? null);
				}}
			>
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="10" height="10" viewBox="0 0 30.5 30.5">
					<path
						d="M17.629,26.584l2.391,0.127v3.353h-9.977v-3.187l1.384-0.125c0.754-0.086,1.215-0.336,1.215-1.342V13.756
		c0-0.926-0.252-1.219-1.049-1.219l-1.466-0.082V8.976h7.502V26.584z M14.696,0c1.845,0,3.187,1.387,3.187,3.146
		c0,1.763-1.342,3.062-3.229,3.062c-1.927,0-3.187-1.301-3.187-3.062C11.467,1.386,12.727,0,14.696,0z"
						fillRule="evenodd"
						clipRule="evenodd"
					/>
				</svg>
			</Box>
			<Popover
				open={open}
				anchorEl={anchor}
				onClose={() => {
					set_anchor(null);
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					backgroundColor: "rgba(255,255,255,0.75)",
					"& > .MuiPopover-paper": {
						padding: 2,
						boxShadow: 4,
						backgroundColor: "#ffffff",
						maxWidth: { sm: "18rem" },
						right: "1rem",
					},
					"& h4": {
						textTransform: "uppercase",
					},
					"& .MuiTypography-body1": {
						marginBottom: "1rem",
					},
				}}
			>
				{title ? (
					<Typography variant="tile-header-dark" component="div">
						{title}
					</Typography>
				) : null}
				{texts
					? texts.map((t: string, i: number) => {
							return (
								<Typography key={i} component="div">
									{t}
								</Typography>
							);
					  })
					: null}
				{children}
			</Popover>
		</Box>
	);
};
export { InfoPopper };
export type InfoPopperPropsWithChildren = PropsWithChildren<InfoPopperProps>;
