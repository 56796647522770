import { Box, Stack, Typography } from "@mui/material";
import { ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel, ModelsOpenSearchPatient } from "api";
import { CarePlanTile } from "common/components/Tiles/CarePlanTile";
import { useMemo } from "react";
import { palette } from "theme";

type CarePlansProps = {
	patientDataAuthorizations?: ConsumerGetConsumerUserDetailsPatientDataAuthorizationResponseModel[];
	patientDemographics?: ModelsOpenSearchPatient[];
	absenceMessage?: string;
	tileBackground?: boolean;
	showOptions?: boolean;
};

const CarePlans = ({ patientDataAuthorizations, patientDemographics, absenceMessage, tileBackground, showOptions }: CarePlansProps) => {
	const tiles = useMemo(() => {
		if (patientDataAuthorizations && patientDataAuthorizations.length > 0) {
			let tyls = patientDataAuthorizations.map((x, i) => {
				if (x.isValid) {
					let desc = x.patient;
					if (patientDemographics) {
						patientDemographics.some((dem) => {
							if (dem.record_id === x.patientRecordID && dem.name) {
								desc = dem.name;
								return true;
							}
							return false;
						});
					}
					return (
						<CarePlanTile
							key={`${x.dataSourceID}-${x.patientRecordID}-${x.id}`}
							description={desc}
							showOptions={showOptions}
							dataSourceID={x.dataSourceID}
							patientDataAuthorization={x}
						/>
					);
				}
				return null;
			});

			if (tileBackground) {
				tyls = tyls.map((t, i) => {
					return (
						<Box
							key={i}
							style={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: ".5rem", display: "flex" }}
							sx={{
								"&:not(:last-child)": {
									marginBottom: ".5rem",
								},
							}}
						>
							{t}
						</Box>
					);
				});
			}
			return tyls;
		}
		return null;
	}, [patientDataAuthorizations, patientDemographics, tileBackground, showOptions]);

	if (tiles) {
		return <>{tiles}</>;
	}

	return (
		<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center" }}>
			<Typography>{absenceMessage ?? "No Connected Insurance Plans"}</Typography>
		</Stack>
	);
};

export { CarePlans };
