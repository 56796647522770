import { Alert, Box, Typography } from "@mui/material";
import { FormErrors, FormErrorX } from "./formUtilities";

type RequestStatus = {
	status: "pending" | "error" | "success" | null;
	messages?: Array<string>;
};

type FormError = {
	severity?: "error" | "info" | "success" | "warning";
	title?: string;
	message?: string;
	type?: string;
};

interface FormAlertProps extends FormError {
	active?: boolean;
}

function FormAlert({ active, title, message, severity }: FormAlertProps) {
	if (active === undefined || active === true) {
		return (
			<Alert severity={severity ?? "error"} sx={{ margin: "8px 0px" }}>
				<Typography variant="body1" sx={{ fontWeight: "bold" }}>
					{title}
				</Typography>
				<Typography variant="body2">{message}</Typography>
			</Alert>
		);
	}
	return null;
}

interface FormAlertProps {
	errors?: Array<FormAlertProps>;
	formStateErrors?: FormErrors;
}

function FormAlerts({ errors, formStateErrors }: FormAlertProps) {
	if (!errors || errors === undefined) {
		errors = [];
	}

	if (formStateErrors) {
		Object.keys(formStateErrors).forEach((key) => {
			let message = formStateErrors[key].message;
			if (message) {
				(errors as Array<FormAlertProps>).push({ title: message });
			}
		});
	}

	if (errors.length === 0) {
		return null;
	}

	return (
		<Box style={{ paddingTop: "16px" }}>
			{errors.map((e, i) => {
				return <FormAlert {...e} key={i} />;
			})}
		</Box>
	);
}

function FormErrorDisplayInline({ error }: { error: FormErrorX | null }) {
	if (error) {
		return (
			<Box style={{ paddingTop: "8px" }}>
				{/* <FormAlert {...error} /> */}
				<Alert
					severity={error.severity ?? "error"}
					sx={{ margin: "0px 0px", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", padding: "0px 8px" }}
				>
					<Typography variant="body1" sx={{ fontWeight: "bold" }}>
						{error.title ? error.title : error.message}
					</Typography>
					{error.title ? <Typography variant="body2">{error.message}</Typography> : null}
				</Alert>
			</Box>
		);
	}
	return null;
}

export { FormErrorDisplayInline, FormAlerts, FormAlert };
export type { FormError, RequestStatus };
