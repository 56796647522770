import { palette } from "theme";

type ColorType = "Yes/No" | "Positive" | "USD";

export type { ColorType };

const styleConverter = {
	getPaletteColor: (paletteDotNotation?: string) => {
		if (!paletteDotNotation) {
			return paletteDotNotation;
		}
		let a: Array<string> = paletteDotNotation.split(".");
		let value: any = palette;
		while (a.length && value !== undefined) {
			value = value[a.shift() as string];
		}
		return value;
	},
	textColorMarkup(s: string, color?: string, css_style: string = "") {
		// string formatted with "lorem ipsum <<colored text>> lorem ipsum"
		s = s.replaceAll(
			"<<",
			color ? `<span style="color:${color};font-weight:500;${css_style}">` : `<span style="font-weight:500;${css_style}"class="colorText">`
		);
		s = s.replaceAll(">>", "</span>");
		return s;
	},
	textColorMarkupX(s: string, color?: string, css_style: string = "") {
		return styleConverter.renderTextHTML(styleConverter.textColorMarkup(s, color, css_style));
	},
	colorTypeAssign(colorType: ColorType, value?: string | number | boolean) {
		switch (colorType) {
			case "Yes/No":
				if (value === true || value === "Yes" || ["true", "yes"].indexOf(String(value).toLowerCase()) !== -1) {
					return palette.success.main;
				} else {
					if (String(value).toLowerCase() === "n/a") {
						return palette.gray.dark;
					}
					return palette.error.main;
				}
			case "Positive":
				return palette.success.main;
			case "USD":
				return palette.success.main;
		}
	},
	renderTextHTML(htmlString: string) {
		return (
			<span
				dangerouslySetInnerHTML={{
					__html: htmlString,
				}}
			/>
		);
	},
};

export { styleConverter };
