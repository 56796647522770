import { Box, Button, Stack, Typography } from "@mui/material";
import displayConverter from "common/utilities/displayConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { palette } from "theme";
import { DisplayInfo } from "common/definitions/EntityDefinitions";

const renderDisplayInfo = (di: DisplayInfo, i: number) => {
	return (
		<Stack
			key={i}
			sx={{
				width: { xs: "100%", sm: "auto" },
				flexDirection: "row",
				alignItems: "center",
				border: `solid 2px ${palette.gray.main}`,
				backgroundColor: palette.core.lightBackground,
				padding: ".5rem",
				borderRadius: ".5rem",
				boxSizing: "border-box",
				marginBottom: ".5rem",
			}}
		>
			<Typography sx={{ color: palette.gray.dark, fontWeight: "500", paddingRight: ".25rem" }}>{di.title}: </Typography>
			<Typography
				style={{
					fontSize: "1rem",
					color: di.colorType ? styleConverter.colorTypeAssign(di.colorType, di.value) : palette.gray.darker,
					fontWeight: "500",
				}}
			>
				{di.dataType ? displayConverter.dataTypeConvert(di.dataType, di.value) : di.value}
			</Typography>
		</Stack>
	);
};

export { renderDisplayInfo };

const renderOptionsButton = (title: string, onClick: () => any) => {
	return (
		<Box>
			<Button variant="text" onClick={onClick}>
				{title}
			</Button>
		</Box>
	);
};

export { renderOptionsButton };
