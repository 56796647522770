import { ConsumerGetConditionDetailResult, getConditionDetail } from "api";
import { useQuery, UseQueryResult } from "react-query";
import { GetConditionDetailParams, ModelsClientLocation } from "../../../api/backend.schemas";

const getConditionDetailX = async (
	clientLocation: ModelsClientLocation,
	params: GetConditionDetailParams
): Promise<ConsumerGetConditionDetailResult | null> => {
	if ((clientLocation.latitude && clientLocation.longitude) || clientLocation.zipcode) {
		return await getConditionDetail(clientLocation, params);
	} else {
		alert("NULL");
		return null;
	}
};

function useQ_conditionDetail(clientLocation: ModelsClientLocation, params: GetConditionDetailParams): UseQueryResult<ConsumerGetConditionDetailResult> {
	// console.count("useQ_conditionDetail-------------------------");
	// console.debug(params);
	// console.debug(`retrieveConditionDetail-${params.icd10}`);
	let cacheKeys = [
		`retrieveConditionDetail-${params.icd10}`,
		"retrieveConditionDetail",
		`retrieveConditionDetail-${params.icd10}+${JSON.stringify(clientLocation)}`,
	];
	// console.debug(cacheKeys);
	return useQuery(cacheKeys, getConditionDetailX.bind(null, clientLocation, params), {
		staleTime: 1000 * 60 * 120,
		cacheTime: 1000 * 60 * 120,
	});
}

export { useQ_conditionDetail };
