import { AppBar, Box, Container, Grid, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { DialogManager } from "common/managers/DialogManager";
import { useQ_retrievePatientEOBDataX } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { useMemo } from "react";
import { palette, style } from "theme";
import { MedicationListPanel } from "./MedicationListPanel";

function MedicationSavingsDetails() {
	const _startDate = useMemo(() => {
		let s = new Date();
		s.setFullYear(s.getFullYear() - 1);
		return s;
	}, []);
	const patientEOBData = useQ_retrievePatientEOBDataX({ startDate: _startDate, endDate: null, caller: "MedicationSavingsDetails" });
	return (
		<>
			<AppBar sx={{ position: "sticky" }}>
				<Box
					style={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: palette.core.lightBackground,
					}}
				>
					<Typography variant="h1">Possible Medication Savings</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("medication-savings-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ContentSection title="Description">
							<Typography>
								RadiusCare has detected fluctuations in the price of your medications. Please select a medication form from the list below to
								see the lowest cash price at area pharmacies.
							</Typography>
						</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<ContentSection title="Your Medication">
							<MedicationListPanel patientEOBData={patientEOBData} />
						</ContentSection>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export { MedicationSavingsDetails };
