import { Box, Stack, Typography } from "@mui/material";
import { entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { StarRating } from "../DisplayElements/StarRating";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { ReactNode } from "react";
import { ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { OptionsButton } from "../Buttons/OptionsButton";
import { renderDisplayInfo } from "./common/TilesCommon";

interface ClinicTileProps {
	Entity: ProviderEntity;
	content?: ReactNode;
	url?: string;
}
const ClinicTile = ({ Entity, content, url }: ClinicTileProps) => {
	const { entityTypeName, displayInfo, title, descriptor } = Entity;
	const entityType = entity_types[entityTypeName];

	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
*/
	return (
		<Box
			data-componentname="ClinicTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
				<Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", flex: 1, width: { xs: "100%", sm: "auto" } }}>
					<AvatarDisplay
						imageAlt={title}
						imageSrc={Entity._providerRecord?.imageUrl ?? ""}
						icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={health_icons_svg.clinic} />}
					/>
					<Box sx={{ paddingLeft: ".5rem" }}>
						<Typography sx={{ color: palette.core.blue, fontSize: "1.25rem", cursor: "pointer" }}>
							<Box
								onClick={() => {
									window.open(url, "_blank");
								}}
							>
								{title}
							</Box>
						</Typography>
						<Typography variant="entity-descriptor">{descriptor}</Typography>
						<StarRating rating={Entity.radiusRating} adjustValueForStarFit />
					</Box>
				</Stack>
				<Box
					sx={{ paddingTop: { xs: ".5rem", sm: "0" }, width: { xs: "100%", sm: "auto" }, "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}
				>
					{displayInfo
						? displayInfo.map((di, i) => {
								return renderDisplayInfo(di, i);
						  })
						: null}
					<OptionsButton content={content} />
				</Box>
			</Stack>
		</Box>
	);
};

export { ClinicTile };
