type DataSourceID = "default" | "BlueButton" | "Aetna" | "UnitedHealth" | "Humana" | "Anthem" | "HorizonBlue" | "Carisk" | "CareRisk";

type HealthPlanName = "" | "medicare" | "aetna" | "unitedhealth" | "humana" | "anthem" | "horizonblue" | "carisk";

type DataSourceDefinition = {
	imageSrc: string;
	title: string;
	healthplan: HealthPlanName;
};

const DatasourceDefinitions: { [key in DataSourceID]: DataSourceDefinition } = {
	default: {
		healthplan: "",
		imageSrc: "",
		title: "unidentified",
	},
	BlueButton: {
		healthplan: "medicare",
		imageSrc: "/images/datasource/LogoMedicare.svg",
		title: "Medicare",
	},
	Aetna: {
		imageSrc: "/images/datasource/LogoAetna.svg",
		title: "Aetna",
		healthplan: "aetna",
	},
	Humana: {
		imageSrc: "/images/datasource/TempHumana.png",
		title: "Humana",
		healthplan: "humana",
	},
	UnitedHealth: {
		imageSrc: "/images/datasource/LogoUnitedHealthcare.svg",
		title: "United Health",
		healthplan: "unitedhealth",
	},
	Anthem: {
		imageSrc: "/images/datasource/LogoAnthem.svg",
		title: "Anthem",
		healthplan: "anthem",
	},
	CareRisk: {
		imageSrc: "/images/carisk-partners-logo.png",
		// imageSrc: "/images/carisk-logo-square.png",
		title: "Carisk",
		healthplan: "carisk",
	},
	Carisk: {
		imageSrc: "/images/carisk-partners-logo.png",
		// imageSrc: "/images/carisk-logo-square.png",
		title: "Carisk",
		healthplan: "carisk",
	},
	HorizonBlue: {
		imageSrc: "/images/datasource/LogoHorizonBlue.svg",
		title: "HorizonBlue",
		healthplan: "horizonblue",
	},
};

const getDatasourceDefinitionByDataSourceID = (id: DataSourceID): DataSourceDefinition | null => {
	if (DatasourceDefinitions.hasOwnProperty(id)) {
		return DatasourceDefinitions[id];
	}

	let key: DataSourceID | null = null;
	let idx = id.toLowerCase();

	if (idx.indexOf("bluebutton") === 0 || idx.indexOf("medicare") === 0) {
		key = "BlueButton";
	} else if (idx.indexOf("aetna") === 0) {
		key = "Aetna";
	} else if (idx.indexOf("humana") === 0) {
		key = "Humana";
	} else if (idx.indexOf("unitedhealth") === 0 || idx.indexOf("united_health") === 0) {
		key = "UnitedHealth";
	} else if (idx.indexOf("anthem") === 0) {
		key = "Anthem";
	} else if (idx.indexOf("carisk") === 0) {
		key = "Carisk";
	} else if (idx.indexOf("horizonblue") === 0) {
		key = "HorizonBlue";
	}
	if (key) {
		return DatasourceDefinitions[key];
	}
	return null;
};

export type { DataSourceID, HealthPlanName };
export { DatasourceDefinitions, getDatasourceDefinitionByDataSourceID };
