import { Box, Button, Stack } from "@mui/material";
import { palette } from "theme";
import { useRef, useState } from "react";
import { DoctorRecord_Enhanced, ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { OpenCloseArrow } from "../UIParticles/OpenCloseArrow";
import { ModelsExplanationOfBenefitProviderAssessment } from "api";
import { ProviderAssessmentDetailsSection, ProviderAvatarDisplay, ProviderOptionsActions } from "./DetailsSections/ProviderAssessmentDetailsSection";
import { TilesDetailsSection } from "./common/TilesDetailsSection";

interface ProviderHistoryTileProps {
	providerEntity: ProviderEntity;
	provAssessment: ModelsExplanationOfBenefitProviderAssessment;
	showOptionsButton?: boolean;
}
const ProviderHistoryTile = ({ providerEntity, provAssessment }: ProviderHistoryTileProps) => {
	const [detailsOpen, set_detailsOpen] = useState(false);
	const providerRecord = providerEntity._providerRecord as DoctorRecord_Enhanced | undefined;

	if (!providerRecord) {
		console.warn("providerRecord -- providerEntity._providerRecord was not present", providerEntity);
	}
	const mainRef = useRef<HTMLDivElement>();

	return (
		<Box
			ref={mainRef}
			data-componentname="ProviderHistoryTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				"& .MuiButton-root, .view-options-btn ": {
					width: { xs: "100%", sm: "auto" },
				},
				"& .MuiButton-contained": {
					marginRight: { xs: "0", sm: ".5rem" },
					marginBottom: { xs: ".5rem", sm: "0" },
				},
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: { xs: "flex-start", sm: "center" }, width: "100%" }}>
				<Box sx={{ flex: "1", paddingBottom: { xs: ".5rem", sm: "0" } }}>
					<ProviderAvatarDisplay providerEntity={providerEntity} />
				</Box>

				<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center", width: { xs: "100%", sm: "auto" } }}>
					<ProviderOptionsActions providerEntity={providerEntity} providerCareAlertsIgnored={provAssessment.providerCareAlertsIgnored} />
					<Button
						variant="outlined"
						sx={{ width: { xs: "100%", sm: "auto" } }}
						onClick={() => {
							set_detailsOpen(!detailsOpen);
						}}
					>
						{detailsOpen ? "Close" : "History"}&nbsp;
						<OpenCloseArrow open={detailsOpen} />
					</Button>
				</Stack>
			</Stack>
			<TilesDetailsSection open={detailsOpen} set_open={set_detailsOpen}>
				<ProviderAssessmentDetailsSection providerEntity={providerEntity} provAssessment={provAssessment} />
			</TilesDetailsSection>
		</Box>
	);
};

export { ProviderHistoryTile };
