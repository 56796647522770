import { Stack, Box, Typography, Card, CardContent } from "@mui/material";
import FormContainer from "account/forms/FormContainer";
import Icon from "common/components/Icons/Icon";
import { ProgressSteps } from "common/components/StepProgress/ProgressSteps";
import { FormErrorAlert } from "common/forms";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "theme";
import { MedicareAuthorize } from "../connectHealthPlan/medicare/MedicareAuthorize";
import { AetnaAuthorize } from "./aetna/AetnaAuthorize";
import { AnthemAuthorize } from "./anthem/AnthemAuthorize";
import { HorizonBlueAuthorize } from "./horizonBlue/HorizonBlueAuthorize";
import { UnitedHealthAuthorize } from "./unitedHealth/UnitedHealthAuthorize";
import { Loader } from "common/components/Loaders/Loader";
function ConnectHealthPlanAuthorize({ healthplan, learn_more }: { healthplan?: string; learn_more?: boolean }) {
	const navigate = useNavigate();

	useEffect(() => {
		if (healthplan === "" || !healthplan) {
			navigate(`/connect-healthplan`);
		} else if (healthplan === "carisk") {
			navigate("/connect-healthplan/receive/healthplan:Carisk");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [healthplan]);

	console.debug("healthplan", healthplan);
	switch (healthplan) {
		case "medicare":
			return <MedicareAuthorize learn_more={learn_more} />;
		case "aetna":
			return <AetnaAuthorize learn_more={learn_more} />;
		case "unitedhealth":
			return <UnitedHealthAuthorize learn_more={learn_more} />;
		case "anthem":
			return <AnthemAuthorize learn_more={learn_more} />;
		case "carisk":
			navigate("/connect-healthplan/recieve/healthplan:Carisk");
			return <Loader />;
		case "horizonblue":
			return <HorizonBlueAuthorize learn_more={learn_more} />;
		case "":
		case undefined:
			return null;
		default:
			return (
				<FormContainer
					topContent={
						<Stack sx={{ flexDirection: "row", justifyContent: "flex-start" }}>
							<Box sx={{ paddingRight: "12px" }}>
								<Typography variant="h1" sx={{ fontWeight: "500" }} component="h1">
									<Link to="/connect-healthplan" style={{ color: "inherit" }}>
										<Icon icon={icons.arrowLeft} />
									</Link>
								</Typography>
							</Box>
							<Box>
								<Typography variant="h1" sx={{ fontWeight: "500" }} component="h1">
									Sharing your data
								</Typography>
							</Box>
						</Stack>
					}
					bottomContent={
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								alignItems: "center",
								width: "100%",
							}}
						>
							<ProgressSteps maxWidth={"200px"} count={3} current={2} />
						</div>
					}
				>
					<Card
						sx={{
							width: "100%",
							paddingLeft: "24px",
							paddingRight: "24px",
							paddingTop: "16px",
							paddingBottom: "24px",
							boxSizing: "border-box",
						}}
						elevation={4}
					>
						<CardContent>
							<Stack spacing={2}>
								<Typography variant="subtitle1" style={{ paddingBottom: "16px" }}>
									"
									<Typography component={"span"} variant="subtitle1" style={{ fontWeight: "bold" }}>
										{healthplan}
									</Typography>
									" healthplans are not currently supported
								</Typography>
							</Stack>
						</CardContent>
						<FormErrorAlert label="Access Denied" />
					</Card>
				</FormContainer>
			);
	}
}

export { ConnectHealthPlanAuthorize };
