const FilterFunctions = {
	filter_by_keywords: <TDataListType>(data: Array<{ [key: string]: any }>, keyword_attrs: Array<string>, keyword_text: string): Array<TDataListType> => {
		let filtered: Array<[TDataListType, number]> = [];
		let textKeys = keyword_text
			.split(" ")
			.map((x) => x.trim().toLowerCase())
			.filter((x) => !!x);
		if (textKeys.length < 1) {
			return data as Array<TDataListType>;
		}
		if (data) {
			data.forEach((p) => {
				let matchCnt = 0;
				let cur_compare_string: string;
				textKeys.forEach((tk) => {
					keyword_attrs.forEach((keyword_attr) => {
						if (p.hasOwnProperty(keyword_attr) && typeof (p as CIM)[keyword_attr] === "string") {
							cur_compare_string = (p as CIM)[keyword_attr].toLowerCase().trim().replaceAll(tk, "*");
							for (let i = 0; i < cur_compare_string.length; i++) {
								if (cur_compare_string[i] === "*") {
									matchCnt++;
								}
							}
						}
					});
				});
				if (matchCnt > 0) {
					filtered.push([p as TDataListType, matchCnt]);
				}
			});
		}

		return filtered
			.sort((a, b) => {
				if (a[1] > b[1]) {
					return -1;
				} else if (b[1] > a[1]) {
					return 1;
				} else {
					return 0;
				}
			})
			.map((x) => x[0]);
	},
};
export { FilterFunctions };
