import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { UpdateUserInformationForm } from "account/forms/UpdateUserInformationForm";
// import { AccountManagerContext } from "common/managers/AccountManager";
// import UpdateInfoOLD from "account/components/UpdateInfo_OLD";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const UpdateInfoDialog = () => {
	const location = useLocation();
	const { updateInfoDisplay } = useContext(DialogsContext);
	// const accountContext = useContext(AccountManagerContext);
	// let consumerUser = accountContext.accountManager.userDetails?.consumerUser;
	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("update-info");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("update-info");
			setSearchParams(searchParams);
			updateInfoDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "update-info": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["update-info"].open = open;
		DialogManager.launchers["update-info"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== updateInfoDisplay?.current) {
			updateInfoDisplay.set_current(d_query);
		}
	}, [d_query, updateInfoDisplay]);

	return (
		<Dialog id="UpdateInfoDialog" fullScreen open={!!updateInfoDisplay.current} onClose={close} TransitionComponent={Transition}>
			{/* {updateInfoDisplay.current && consumerUser && <UpdateInfoOLD />} */}
			<UpdateUserInformationForm />
			{/* <div style={{ backgroundColor: "red" }}>
				<UpdateInfoOLD />
			</div> */}
		</Dialog>
	);
};

export { UpdateInfoDialog };
