import { Box, Button, Stack, Typography } from "@mui/material";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { Paginator } from "common/components/Paginator/Paginator";
import { MedicationSearchTile } from "common/components/Tiles/MedicationSearchTile";
import { MedicationEntity } from "common/definitions/entity/MedicationEntity";
import { DialogManager } from "common/managers/DialogManager";
import { StandardOption } from "common/types/generic";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { FilterFunctions } from "common/utilities/filterFunctions";
import { SearchTreatmentsResponseModel } from "findCare/requests/useQ_retrieveSearchTreatments";
import { useMemo, useState } from "react";
import { UseQueryResult } from "react-query";

type FindCareMedicationResultsProps = {
	medicationBrandType: StandardOption<string, string>;
	condition: StandardOption<string, string>;
	medicationResults: UseQueryResult<SearchTreatmentsResponseModel | ProcessedResponse<any>>;
};

const FindCareMedicationResults = ({ medicationResults, medicationBrandType }: FindCareMedicationResultsProps) => {
	const [keywords, set_keywords] = useState<string>("");

	const filteredData = useMemo(() => {
		console.debug("medicationResults.data", medicationResults.data);
		if (medicationResults.data && !apiResponseReader.typeCheck_ProcessedResponse(medicationResults.data)) {
			switch (medicationBrandType.value) {
				case "generic":
					return medicationResults.data.response.medicationEntities.filter((x) => {
						if (x._medicationRecord?.isGeneric === true) {
							return true;
						}
						return false;
					});
				case "brand":
					return medicationResults.data.response.medicationEntities.filter((x) => {
						if (x._medicationRecord?.isGeneric === false) {
							return true;
						}
						return false;
					});
				case "all":
				default:
					return medicationResults.data.response.medicationEntities;
			}
		}
		return null;
	}, [medicationResults?.data, medicationBrandType.value]);

	const filterSortedByKeywords: Array<MedicationEntity> = useMemo(() => {
		return FilterFunctions.filter_by_keywords(filteredData ?? [], ["title"], keywords);
	}, [keywords, filteredData]);

	return (
		<Box>
			<Stack sx={{ paddingBottom: "1rem", width: "100%" }}>
				<Typography variant="h5" marginBottom={".5rem"}>
					Keywords
				</Typography>
				<StandardTextField
					placeholder='"Medication Name"'
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						set_keywords(e.currentTarget.value ?? "");
					}}
					value={keywords}
				/>
			</Stack>
			<QueryStatusDisplay queryResult={medicationResults}>
				{medicationResults.data && !apiResponseReader.typeCheck_ProcessedResponse(medicationResults.data) ? (
					<Paginator
						pageSize={6}
						data={filterSortedByKeywords}
						renderItem={(docEnt: any, i?: number) => {
							return (
								<Box key={i} sx={{ paddingBottom: ".5rem" }}>
									<MedicationSearchTile
										Entity={docEnt}
										content={
											<Box>
												<Button
													variant="text"
													onClick={() => {
														DialogManager.open("medication-details", docEnt?._medicationRecord?.ndc);
													}}
												>
													View Details
												</Button>
											</Box>
										}
									/>
								</Box>
							);
						}}
					/>
				) : (
					"No Results"
				)}
			</QueryStatusDisplay>
		</Box>
	);
};

export { FindCareMedicationResults };
