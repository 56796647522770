import { Stack } from "@mui/material";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers-pro";
import { FormErrorDisplayInline } from "common/forms/FormAlerts";
import dayjs from "dayjs";
import { palette } from "theme";
import { ErrorableInputProps, useInlineError } from "./StandardTextField";

type StandardDatePickerProps = ErrorableInputProps & DatePickerProps<dayjs.Dayjs> & React.RefAttributes<HTMLDivElement>;

const StandardDatePicker = (props: StandardDatePickerProps) => {
	const error = useInlineError(props.fieldname, props.errors);

	return (
		<Stack
			sx={{
				marginTop: 0,
				"& > div.MuiFormControl-root.MuiTextField-root > .MuiFormLabel-root.MuiInputLabel-root": {
					border: "none",
					padding: "0px",
					margin: "0px",
					left: "-14px",
					top: "4px",
					color: error ? palette.error.main : undefined,
				},
				"& > div.MuiFormControl-root.MuiTextField-root": {
					outline: "none",
					borderWidth: "0px 0px 2px 0px",
					borderColor: palette.gray.dark,
					borderStyle: "solid",
					transition: "border-color .5s ease",
					padding: "0px",
					margin: "0px",
					"& > div.MuiInputBase-root > input.MuiInputBase-input": {
						margin: 0,
						paddingBottom: "2px",
						paddingTop: "20px",
						paddingLeft: "8px",
						fontWeight: 500,
						fontSize: "1rem",
						color: palette.gray.dark,
					},
				},
				// "& > .MuiFormControl-root.MuiTextField-root > input.MuiInputBase-input": {
				// 	margin: 0,
				// 	padding: 0,
				// },
				"& > .MuiFormControl-root.MuiTextField-root > .MuiOutlinedInput-root > fieldset": {
					border: "none",
					outline: "none",
				},
			}}
			data-id="DatePickerStack"
		>
			<DatePicker {...props} />
			{error ? <FormErrorDisplayInline error={error} /> : null}
		</Stack>
	);
};

export { StandardDatePicker };
