/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerMobileOperationStatusCareScoreOverviewResponseModel,
  ModelsClientLocation,
  OverviewParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const overview = (
    modelsClientLocation: ModelsClientLocation,
    params?: OverviewParams,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileOperationStatusCareScoreOverviewResponseModel>(
      {url: `/api/consumer/ConsumerMobile_Overview/Overview`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: modelsClientLocation,
        params
    },
      options);
    }
  


    export type OverviewMutationResult = NonNullable<Awaited<ReturnType<typeof overview>>>
    export type OverviewMutationBody = ModelsClientLocation
    export type OverviewMutationError = unknown

    export const useOverview = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof overview>>, TError,{data: ModelsClientLocation;params?: OverviewParams}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof overview>>, {data: ModelsClientLocation;params?: OverviewParams}> = (props) => {
          const {data,params} = props ?? {};

          return  overview(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof overview>>, TError, {data: ModelsClientLocation;params?: OverviewParams}, TContext>(mutationFn, mutationOptions)
    }
    