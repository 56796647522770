import {
  HealthIcon,
  health_icons_svg,
} from "common/components/Icons/HealthIcon";
import { IconToggle } from "common/components/Icons/IconToggle";

type ProfileBadgeOptions = {
  onClick?: (...args: any[]) => any;
  active?: boolean;
};

function ProfileBadge({ onClick, active }: ProfileBadgeOptions) {
  return (
    <IconToggle
      onClick={onClick}
      icon={<HealthIcon iconSVG={health_icons_svg.person} />}
      active={active}
    />
  );
}

export { ProfileBadge };
