import { Routes, Route, Navigate } from "react-router-dom";
import { APITest } from "developer/apiTest/apiTest";
import { ICDRangeCategoryViewer } from "./icdViewer/icdRangeCategory";
import { RiskImporter } from "./riskImporter/riskImporter";
export default function DeveloperRouter() {
	return (
		<Routes>
			<Route path="/apitest" element={<APITest />} />
			<Route path="/icdRangeCategory" element={<ICDRangeCategoryViewer />} />
			<Route path="/riskImporter" element={<RiskImporter />} />
			<Route path={"*"} element={<Navigate to="dashboard" />} />
		</Routes>
	);
}
