// import {
// 	getConditionDetail,
// 	getConditionDetailMobile,
// 	getProviderTaxonomies,
// 	// getConditionDetailMobile,
// 	healthSnapShot,
// 	optimizeCareDoctors,
// 	overview,
// 	retrievePatientEOBData,
// } from "api";
import { useEffect, useMemo, useReducer, useState } from "react";
import { GeolocationManager } from "common/managers/GeolocationManager";

import { Loader } from "common/components/Loaders/Loader";
import {
	costOfCareMobile,
	getConditionDetail,
	getProviderTaxonomies,
	getUserDetails,
	getUserDetailsMobile,
	healthSnapShot,
	optimizeCareDoctors,
	overview,
	retrievePatientEOBData,
	suggestSimilarDoctorsMobile,
	getRecommendedUSPSTF,
	getDrugPricesAndLocations,
	retrieveConditionsIcd10sByRange,
	getMedicationDetails,
	getDrugPricesAndLocationsMobile,
	getErrorSummary,
	getDoctorsByLocationMobile,
	registerDeviceIdentifier,
	sendPushNotificationIos,
	testNewEobNotifier,
	sendMassVersionUpdateNotification,
	sendTestVersionUpdateNotification,
	careScore,
	applyRiskModel,
	getPatientRiskModelScores,
} from "api";
import { getConditionDetailMobile } from "api_mobile";
import { getRecommendedUspstfMobile } from "../../api_mobile/consumermobile-achievements/consumermobile-achievements";

const responseMap = new Map<string, any>();

type ApiTestValues = { [key: string]: string };

const basic_object_reducer = (state: ApiTestValues, action: any) => {
	console.debug({ state, action });
	return Object.assign({}, state, action);
};
const APITest = () => {
	const [apiResponse, set_apiResponse] = useState<any>(null);

	const apis = [
		"overview",
		"retrievePatientEOBData",
		"getProviderTaxonomies",
		"healthSnapShot",
		"optimizeCareDoctors",
		"getConditionDetail",
		"getConditionDetail_II",
		"getConditionDetail_Mobile",
		"getUserDetails",
		"getUserDetails_Mobile",
		"costOfCareMobile",
		"suggestSimilarDoctorsMobile",
		"getRecommendedUSPSTF",
		"getRecommendedUspstfMobile",
		"getDrugPricesAndLocations",
		"getDrugPricesAndLocations2",
		"retrieveConditionsIcd10sByRange",
		"getMedicationDetails",
		"GetDrugPricesAndLocationsMobile",
		"getErrorLogs",
		"GetDoctorsByLocationMobile",
		"RegisterDeviceIdentifier",
		"SendPushNotificationIos",
		"TestNewEobNotifier",
		"SendMassVersionUpdateNotification_Android",
		"SendMassVersionUpdateNotification_IOS",
		"SendTestVersionUpdateNotification",
		"CareScore",
		"ApplyRiskModel",
		"GetPatientRiskModelScores",
		"Clear Stored Responses",
	];

	//M47816

	const [activeAPI, set_activeAPI] = useState<string>("overview");
	const [triggerCount, set_triggerCount] = useState<number>(0);

	const [loading, set_loading] = useState<boolean>(false);

	const [values, dispatch_values] = useReducer(
		//
		basic_object_reducer,
		{}
	);
	// const [formValues, dispatch_formValues] = useReducer(
	// 	(x: CareRiskSignUpPrimaryFormValues, action: Partial<CareRiskSignUpPrimaryFormValues>) => {
	// 		return Object.assign({}, state, action);
	// 	},
	// 	{
	// 		firstname: defaultValues && defaultValues.firstName ? defaultValues.firstName : "",
	// 		lastname: defaultValues && defaultValues.lastName ? defaultValues.lastName : "",
	// 		id: defaultValues && defaultValues.careRiskPatientId ? defaultValues.careRiskPatientId : "",
	// 		dob: defaultValues && defaultValues.dateOfBirth ? dateConverter.dateFromDateStringYMD(defaultValues.dateOfBirth) : undefined,
	// 		zip: defaultValues && defaultValues.zipCode ? defaultValues.zipCode : "",
	// 	}
	// );

	const setActiveApiClearToggle = useMemo(() => {
		return (val: string) => {
			if (val === activeAPI) {
				responseMap.set(val, undefined);
			}
			set_triggerCount(triggerCount + 1);
			set_activeAPI(val);
		};
	}, [set_activeAPI, activeAPI, triggerCount, set_triggerCount]);

	useEffect(() => {
		if (activeAPI === "Clear Stored Responses") {
			responseMap.clear();
			set_apiResponse("Cleared");
			return;
		}

		if (responseMap.has(activeAPI) && responseMap.get(activeAPI) !== undefined) {
			set_apiResponse(responseMap.get(activeAPI));
			return;
		}

		console.debug(values);
		console.debug(values[activeAPI]);

		let value = values[activeAPI];

		set_loading(true);
		switch (activeAPI) {
			case "overview":
				overview(GeolocationManager.clientLocation(), { yearsBack: 1 }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "retrievePatientEOBData":
				retrievePatientEOBData({ dateRangeRequest: { yearsBack: 1 } }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "getProviderTaxonomies":
				getProviderTaxonomies().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "healthSnapShot":
				healthSnapShot({ fullYear: 2022 }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "optimizeCareDoctors":
				optimizeCareDoctors({ clientLocation: GeolocationManager.clientLocation() }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getConditionDetail":
				getConditionDetail(GeolocationManager.clientLocation(), { icd10: value ?? "M47816" }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getConditionDetail_II":
				getConditionDetailMobile(GeolocationManager.clientLocation(), { icd10: value ?? "U071" }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getConditionDetail_Mobile":
				getConditionDetailMobile(GeolocationManager.clientLocation(), { icd10: value ?? "U071", includeDoctors: false, inNetwork: false }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;

			case "getUserDetails":
				getUserDetails().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getUserDetails_Mobile":
				getUserDetailsMobile().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "costOfCareMobile":
				costOfCareMobile({ dateRangeRequest: { yearsBack: 1 }, clientLocation: GeolocationManager.clientLocation() }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "suggestSimilarDoctorsMobile":
				suggestSimilarDoctorsMobile({ npi: value ?? "1184684482", clientLocation: GeolocationManager.clientLocation() }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getRecommendedUSPSTF":
				getRecommendedUSPSTF().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getRecommendedUspstfMobile":
				getRecommendedUspstfMobile().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getDrugPricesAndLocations":
				// getDrugPricesAndLocations(GeolocationManager.clientLocation(), { ndc: "316047", quantity: 30 }).then((r) => {
				getDrugPricesAndLocations(GeolocationManager.clientLocation(), { ndc: value ?? "51991070405", quantity: 30 }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getDrugPricesAndLocations2":
				getDrugPricesAndLocations(GeolocationManager.clientLocation(), { ndc: value ?? "61703032618", quantity: 30 }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "retrieveConditionsIcd10sByRange":
				retrieveConditionsIcd10sByRange({ range: value ?? "R70.0-R79.9" }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getMedicationDetails":
				//00573018432 advil cold and sinus
				// 00487278401 asthma
				getMedicationDetails({ query: value ?? "00573018432" }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "GetDrugPricesAndLocationsMobile":
				//00573018432 advil cold and sinus
				// 00487278401 asthma
				getDrugPricesAndLocationsMobile(GeolocationManager.clientLocation(), { ndc: value ?? "51991070405", quantity: 30 }).then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "getErrorLogs":
				//00573018432 advil cold and sinus
				// 00487278401 asthma
				getErrorSummary().then((r) => {
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "GetDoctorsByLocationMobile":
				getDoctorsByLocationMobile(
					{
						distanceRadiusMiles: 25,
						longitude: -122.03031802,
						latitude: 37.332595519999998,
					},
					{ providerType: value ?? "inVaLidTyPe" }
				).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "RegisterDeviceIdentifier":
				registerDeviceIdentifier({
					deviceType: "ios",
					deviceId: value ?? "DaNsMoBiLeDeViCeIos_2023.05.18",
					remove: false,
				}).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "SendPushNotificationIos":
				sendPushNotificationIos({
					// serviceType: "IOS",
					//deviceIds: ["2ec6959a3fac1466c71d10724337528da871d4dfc3b8db238280dd821b22210a"], //-Scott
					deviceIds: [
						// "642d1f7ba9e57856c1700af9778a645e293687b0e62587580b3348337d43422f",
						// "63e49b01a68a93d75db7ce3dc5c30ef832115e4c049f9fdd06094769ca71cccb",
						value ?? "InVaLidDeviCeId",
					], //-Guy
					eventId: "TEST_EVENT_!@#$",
					refId: "Reference Object !@#$$",
				}).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;

			case "TestNewEobNotifier":
				testNewEobNotifier({ icd: value ?? "B880||C74||R11||E11" }).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});

				break;
			case "npm start":
				if (value) {
					sendMassVersionUpdateNotification({ android: false, ios: true, latestVersion: value }).then((r) => {
						console.debug(r);
						set_apiResponse(r);
						responseMap.set(activeAPI, r);
						set_loading(false);
					});
				} else {
					set_apiResponse(`${activeAPI}: set value to send`);
					set_loading(false);
				}

				break;
			case "SendMassVersionUpdateNotification_Android":
				if (value) {
					sendMassVersionUpdateNotification({ android: true, ios: false, latestVersion: value }).then((r) => {
						console.debug(r);
						set_apiResponse(r);
						responseMap.set(activeAPI, r);
						set_loading(false);
					});
				} else {
					set_apiResponse(`${activeAPI}: set value to send`);
					set_loading(false);
				}
				break;
			case "SendTestVersionUpdateNotification":
				if (value) {
					sendTestVersionUpdateNotification({ android: true, ios: true, latestVersion: value }).then((r) => {
						console.debug(r);
						set_apiResponse(r);
						responseMap.set(activeAPI, r);
						set_loading(false);
					});
				} else {
					set_apiResponse(`${activeAPI}: set value to send`);
					set_loading(false);
				}

				break;
			case "CareScore":
				careScore({ dateRangeRequest: { yearsBack: 1 } }).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "ApplyRiskModel":
				applyRiskModel({ dateRangeRequest: { yearsBack: 4 } }).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			case "GetPatientRiskModelScores":
				getPatientRiskModelScores({
					//memberTypes: ["RadiusInsurance", "RadiusInsurance_Test1", "RadiusInsurance_Test2", "RadiusInsurance_Test3"],
					memberTypes: ["RadiusInsurance"],
					riskModel: "RadiusCare",
				}).then((r) => {
					console.debug(r);
					set_apiResponse(r);
					responseMap.set(activeAPI, r);
					set_loading(false);
				});
				break;
			default:
				set_apiResponse(`{"Invalid API Name":"${activeAPI}"}`);
				set_loading(false);
				break;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeAPI, triggerCount]);

	if (loading) {
		return <Loader />;
	}

	return (
		<div style={{ maxWidth: "100vw", overflowY: "scroll", overflowX: "auto" }}>
			{apis.map((x) => {
				return (
					<button key={x} style={{ color: activeAPI === x ? "limegreen" : "black" }} onClick={setActiveApiClearToggle.bind(null, x)}>
						{x}
					</button>
				);
			})}
			<br />
			<div>
				<input
					style={{ marginTop: "16px" }}
					type="text"
					onChange={(y) => {
						console.debug(y);
						console.debug(y.target.value);
						let x: any = {};
						x[activeAPI] = y.target.value;
						dispatch_values(x);
					}}
					value={values[activeAPI] ?? ""}
				/>
			</div>
			<div>
				<div>
					<pre>{JSON.stringify(values, null, 4)}</pre>
				</div>
			</div>
			<p>API TEST</p>
			<pre style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>{JSON.stringify(apiResponse, null, 4)}</pre>
		</div>
	);
};

export { APITest };
