import { useSearchParams } from "react-router-dom";
import { AetnaReceive } from "./aetna/AetnaReceive";
import { AnthemReceive } from "./anthem/AnthemReceive";
import { CariskConnect } from "./carerisk/CariskConnect";
import { HorizonBlueReceive } from "./horizonBlue/HorizonBlueReceive";
import { MedicareReceive } from "./medicare/MedicareReceive";
import { UnitedHealthReceive } from "./unitedHealth/UnitedHealthReceive";

function ConnectHealthPlanReceive({ healthplan }: { healthplan?: string }) {
	const [searchParams] = useSearchParams();

	console.debug({ healthplan });
	switch (healthplan) {
		case "medicare":
			return <MedicareReceive searchParams={searchParams} />;
		case "aetna":
			return <AetnaReceive searchParams={searchParams} />;
		case "unitedhealth":
			return <UnitedHealthReceive searchParams={searchParams} />;
		case "anthem":
			return <AnthemReceive searchParams={searchParams} />;
		case "carisk":
			return <CariskConnect searchParams={searchParams} />;
		case "horizonblue":
			return <HorizonBlueReceive searchParams={searchParams} />;
		default:
			return <p>Healthplan not Recognized</p>;
	}
}

export { ConnectHealthPlanReceive };
