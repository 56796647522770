import { Button, Container, Box, Dialog, Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useState, ReactNode } from "react";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";

type NewDiagnosisAlertProps = {
	content: ReactNode;
};

const useStyles = makeStyles(() => ({
	popoverRoot: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "sm",
	},
}));

const NewDiagnosisAlert = ({ content }: NewDiagnosisAlertProps) => {
	const [open, setOpen] = useState<boolean>(true);
	const classes = useStyles();

	return (
		<Box>
			<Dialog
				open={open}
				onClose={() => {
					setOpen(false);
				}}
				classes={{
					root: classes.popoverRoot,
				}}
			>
				<Container maxWidth={"xs"} disableGutters sx={{ display: "flex", flexDirection: "column", backgroundColor: "white", padding: "1rem" }}>
					<Box>
						<HealthIcon color={"#fbbe28"} size={150} iconSVG={health_icons_svg.alert_triangle} />
					</Box>
					<Stack>
						<Typography variant="h2" sx={{ textAlign: "center" }}>
							Health Alert
						</Typography>
						<Typography variant="h3" sx={{ textAlign: "center" }}>
							Health Change Detected
						</Typography>
					</Stack>
					{content}

					<Button
						size="large"
						sx={{ marginBottom: ".5rem" }}
						onClick={() => {
							setOpen(false);
						}}
					>
						Return to Dashboard
					</Button>
				</Container>
			</Dialog>
		</Box>
	);
};

export { NewDiagnosisAlert };
