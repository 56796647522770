import { Box, Stack, Typography } from "@mui/material";
import { Loader } from "common/components/Loaders/Loader";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext, useMemo } from "react";
import { ReceivedCareColloboraterAuth } from "./ReceivedCareColloboraterAuth";
import { palette } from "theme";

type AcceptedCareCollaborationsProps = {
	allowRemove: boolean;
};
function AcceptedCareCollaborations({ allowRemove }: AcceptedCareCollaborationsProps) {
	const accountContext = useContext(AccountManagerContext);

	const content = useMemo(() => {
		// let accepted: Array<CareCollaboratorAuthorization> = [];
		if (accountContext.careCollaborations?.receivedAccepted && accountContext.careCollaborations.receivedAccepted.length > 0) {
			return accountContext.careCollaborations.receivedAccepted.map((cc) => {
				return (
					<Box sx={{ mb: 1 }} key={cc.id}>
						<ReceivedCareColloboraterAuth careCollaboratorAuthorization={cc} allowRemove={allowRemove} />
					</Box>
				);
			});
		}

		return null;
	}, [accountContext.careCollaborations, allowRemove]);

	return accountContext.loadingCollaborators ? (
		<Loader />
	) : (
		<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
			{content ? (
				content
			) : (
				<Stack sx={{ backgroundColor: palette.gray.light, borderRadius: "1rem", padding: "1rem", textAlign: "center", mb: 1 }}>
					<Typography>No Accepted Care Collaborations</Typography>
				</Stack>
			)}
		</Box>
	);
}

export { AcceptedCareCollaborations };
