import { Box, Grid, MenuItem, Stack } from "@mui/material";
import { ModelsUSPSTF } from "api";
import { AchievementTile } from "common/components/Tiles/AchievementTile";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { StyleContext } from "common/context/StyleContext";
import { AchievementType } from "common/definitions/EventDefinitions";
import { useContext, useEffect, useMemo, useRef, useState } from "react";

type AchievementsToConsiderProps = {
	categoryFilter: "ALL" | AchievementType;
	// uspstfItems: ConsumerOperationStatusGetAllUSPSTFResponseModel;
	uspstfItems: ModelsUSPSTF[];
};

const AchievementsToConsider = ({ uspstfItems, categoryFilter }: AchievementsToConsiderProps) => {
	let [achievementGradeFilterValue /*, set_achievementGradeFilterValue*/] = useState<"ALL" | "A" | "B" | "C" /*| "D" | "I"*/>("ALL");
	let [achievementCompletionFilterValue, set_achievementCompletionFilterValue] = useState<"ALL" | "COMPLETED" | "NOT_COMPLETED">("NOT_COMPLETED");

	const styleContext = useContext(StyleContext);
	const bayRef = useRef<HTMLElement>();

	const category_filtered_1stLevel = useMemo(() => {
		let items = Array.from(uspstfItems);
		if (categoryFilter !== "ALL") {
			items = items.filter((x) => {
				if (x.inferredType === categoryFilter) {
					return true;
				}
				return false;
			});
		}
		return items;
	}, [uspstfItems, categoryFilter]);

	const grade_filtered_2ndLevel = useMemo(() => {
		let items = Array.from(category_filtered_1stLevel);
		switch (achievementGradeFilterValue) {
			case "A":
			case "B":
			case "C":
				//case "D":
				//case "I":
				items = items.filter((x) => {
					if (x.grade !== achievementGradeFilterValue) {
						return false;
					}
					return true;
				});
				break;
			case "ALL":
			default:
				break;
		}
		return items;
	}, [category_filtered_1stLevel, achievementGradeFilterValue]);

	const completed_filtered_3rdLevel = useMemo(() => {
		let items = Array.from(grade_filtered_2ndLevel);
		switch (achievementCompletionFilterValue) {
			case "NOT_COMPLETED":
				items = items.filter((x) => {
					return !x.userHasCompleted;
				});
				break;
			case "COMPLETED":
				items = items.filter((x) => {
					return !!x.userHasCompleted;
				});
				break;
			case "ALL":
			default:
				break;
		}
		return items;
	}, [grade_filtered_2ndLevel, achievementCompletionFilterValue]);

	useEffect(() => {
		styleContext.trigger(); // kicks off useMemo BayColumns with bayRef established
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Box ref={bayRef}>
			<Stack flexDirection={"row"} justifyContent="flex-end" sx={{ paddingBottom: "1rem" }}>
				<Box>
					<StandardSelect
						labelId="achievement-completion-filter-label"
						id="achievement-completion-filter"
						variant="standard"
						value={achievementCompletionFilterValue}
						onChange={(x, y) => {
							set_achievementCompletionFilterValue(x.target.value as CIM);
						}}
					>
						<MenuItem value={"ALL"}>All Achievements</MenuItem>
						<MenuItem value={"COMPLETED"}>Completed</MenuItem>
						<MenuItem value={"NOT_COMPLETED"}>Not Completed</MenuItem>
					</StandardSelect>
				</Box>
			</Stack>
			<Grid container spacing={2}>
				{completed_filtered_3rdLevel.map((x, i) => {
					return (
						<Grid key={i} item xs={12} md={6}>
							<AchievementTile
								key={x.id}
								achievementType={x.inferredType as AchievementType}
								title={(x as CIM).markupTitle ?? ""}
								completed={x.userHasCompleted ?? false}
								uspstf={x}
							/>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};

export { AchievementsToConsider };
