import { useEffect, useMemo, useReducer, useState } from "react";
import { CardContent, Stack, Typography } from "@mui/material";
import { FormError } from "common/forms/FormAlerts";
import { FormErrors, formUtilities, FormValidationResponse } from "common/forms/formUtilities";
// import AddressFormSection, { AddressFormSectionValues } from "./AddressFormSection";
import { connectCariskPlan } from "../../../api_mobile/care-plan-configuration/care-plan-configuration";
import { CarePlanConfigurationCignaCariskConnectionInformation, ModelsOperationStatus, ModelsOperationStatusString } from "api";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { StandardDatePicker } from "common/components/Inputs/StandardDatePicker";
import dayjs from "dayjs";
import displayConverter from "common/utilities/displayConverter";
import { dateConverter } from "common/utilities/dateConverter";
import { OperationStatusErrorDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useQueryClient, QueryClient } from "react-query";

interface CariskConnectionAddonFormProps {
	set_cariskFormSend: React.Dispatch<React.SetStateAction<(() => Promise<boolean | ModelsOperationStatus>) | undefined>>;
	set_cariskFormValidate: React.Dispatch<React.SetStateAction<(() => FormValidationResponse<CariskConnectFormValues>) | undefined>>;
	sendMode: "MakeRequest" | "LocalStorageDefer";
	displayType: "Primary" | "Sub";
	defaultValues?: Partial<CarePlanConfigurationCignaCariskConnectionInformation>;
}

interface CariskSignUpPrimaryFormValues {
	firstname: string;
	lastname: string;
	id: string;
	dob?: Date;
	zip: string;
}

type CariskConnectFormValues = CariskSignUpPrimaryFormValues; // & AddressFormSectionValues;

const convertCariskConnectFormValuestoCariskConnectionRequest = (
	values: Required<CariskConnectFormValues>
): CarePlanConfigurationCignaCariskConnectionInformation => {
	return {
		firstName: values.firstname,
		lastName: values.lastname,
		cariskPatientId: values.id,
		dateOfBirth: dateConverter.stringDateForAPI(values.dob),
		zipCode: values.zip,
		// address: {
		// 	addressLine1: values.street1,
		// 	addressLine2: values.street2,
		// 	city: values.city,
		// 	state: values.state,
		// 	zip: values.zip,
		// },
	};
};

const connectCariskPlanX = async (request: CarePlanConfigurationCignaCariskConnectionInformation, queryClient: QueryClient) => {
	localStorage.removeItem("CariskConnectFormValues");
	let response = await connectCariskPlan(request);
	return response;
};

function CariskConnectionAddonForm({ set_cariskFormSend, set_cariskFormValidate, sendMode, displayType, defaultValues }: CariskConnectionAddonFormProps) {
	const [primaryErrors, set_primaryErrors] = useState<FormErrors | undefined>();

	const [apiResponse, set_apiResponse] = useState<ModelsOperationStatus | null>(null);
	// const [addressErrors, set_addressErrors] = useState<FormErrors | undefined>();
	// const [addressFormValidate, set_addressFormValidate] = useState<(() => FormValidationResponse<AddressFormSectionValues>) | undefined>();
	const [formValues, dispatch_formValues] = useReducer(
		(x: CariskSignUpPrimaryFormValues, action: Partial<CariskSignUpPrimaryFormValues>) => {
			return formUtilities.formValuesReducer<CariskSignUpPrimaryFormValues>(x, action);
		},
		{
			firstname: defaultValues && defaultValues.firstName ? defaultValues.firstName : "",
			lastname: defaultValues && defaultValues.lastName ? defaultValues.lastName : "",
			id: defaultValues && defaultValues.cariskPatientId ? defaultValues.cariskPatientId : "",
			dob: defaultValues && defaultValues.dateOfBirth ? dateConverter.dateFromDateStringYMD(defaultValues.dateOfBirth) : undefined,
			zip: defaultValues && defaultValues.zipCode ? defaultValues.zipCode : "",
		}
	);

	// useEffect(() => {
	// 	if (defaultValues) {
	// 		dispatch_formValues(defaultValues);
	// 	}
	// }, [defaultValues]);

	/*
██    ██  █████  ██      ██ ██████   █████  ████████ ██  ██████  ███    ██ 
██    ██ ██   ██ ██      ██ ██   ██ ██   ██    ██    ██ ██    ██ ████   ██ 
██    ██ ███████ ██      ██ ██   ██ ███████    ██    ██ ██    ██ ██ ██  ██ 
 ██  ██  ██   ██ ██      ██ ██   ██ ██   ██    ██    ██ ██    ██ ██  ██ ██ 
  ████   ██   ██ ███████ ██ ██████  ██   ██    ██    ██  ██████  ██   ████
	*/
	const validate = useMemo(() => {
		// console.debug("useMemo validate CariskForm", formValues);
		return (): FormValidationResponse<CariskConnectFormValues> => {
			set_apiResponse(null);
			// console.debug("Carisk - validate");
			const errs: {
				[x: string]: FormError;
			} = {};
			if (formValues.firstname === "") {
				errs.firstname = { type: "required", message: "First Name is Required" };
			}
			if (formValues.lastname === "") {
				errs.lastname = { type: "required", message: "Last Name is Required" };
			}
			if (formValues.id === "") {
				errs.id = { type: "required", message: "Care-Risk ID is Required" };
			}
			if (!formValues.dob) {
				errs.dob = { type: "required", message: "Date of Birth is Required" };
			} else {
				console.debug(formValues.dob);
			}
			if (!formValues.zip) {
				errs.zip = { type: "required", message: "Zip Code is Required" };
			} else if (formValues.zip.length !== 5) {
				errs.zip = { type: "invalid", message: "Zip Code must be 5 digits" };
			}

			set_primaryErrors(errs);

			let validationResponse: FormValidationResponse<CariskConnectFormValues> = {
				isValid: Object.getOwnPropertyNames(errs).length === 0,
				errors: errs,
				values: formValues as CariskConnectFormValues,
			};
			return validationResponse;
		};
	}, [
		formValues,
		set_primaryErrors,
		//	, addressFormValidate
	]);
	/*

	
	
	*/
	const queryClient = useQueryClient();

	const send: () => Promise<ModelsOperationStatusString | boolean> = useMemo(() => {
		if (sendMode === "LocalStorageDefer") {
			return async () => {
				let x = validate();

				if (x.isValid && x.values) {
					let requestStructure = convertCariskConnectFormValuestoCariskConnectionRequest(x.values as Required<CariskSignUpPrimaryFormValues>);
					localStorage.setItem("CariskConnectFormValues", JSON.stringify(requestStructure));
					// CANT CONNECT HERE - AUTH IS NOT YET ESTABLISHED
				}
				return true;
			};
		} else if (sendMode === "MakeRequest") {
			return async () => {
				let x = validate();
				// return false;
				// alert("send");
				if (x.isValid && x.values) {
					// alert("send actual request");
					let requestStructure = convertCariskConnectFormValuestoCariskConnectionRequest(x.values as Required<CariskSignUpPrimaryFormValues>);
					let response = await connectCariskPlanX(requestStructure, queryClient);

					console.debug(response);
					// alert("have response");
					set_apiResponse(response);
					// console.group("connectCariskPlanX RESPONSE");
					// console.debug(response);
					// console.groupEnd();

					if (response.success) {
						return response;
					}
				}
				return false;
			};
		}
		return async () => {
			// alert("Invalid SendMode Specified");
			console.error("Invalid SendMode Specified");
			return false;
		};
	}, [
		//formValues,
		validate,
		sendMode,
		queryClient,
	]);

	useEffect(() => {
		set_cariskFormValidate(() => {
			return validate; // It runs the function in the setter which was unexpected
		});
	}, [set_cariskFormValidate, validate]);

	useEffect(() => {
		set_cariskFormSend(() => {
			return send; // It runs the function in the setter which was unexpected
		});
	}, [set_cariskFormSend, send]);

	const errors = useMemo(() => {
		let errors = {};
		if (primaryErrors) {
			Object.assign(errors, primaryErrors);
		}
		// if (addressErrors) {
		// 	Object.assign(errors, addressErrors);
		// }
		return errors;
	}, [
		primaryErrors,
		// addressErrors,
		//	formValues
	]);
	/*



*/

	/*
	
	
	
	
	
	
	
	
	
	
	
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
	
	
	
	
	
	
	
	*/
	return (
		<CardContent>
			{displayType === "Primary" ? (
				<Typography variant="h1" component="h1" style={{ marginBottom: "8px" }}>
					Carisk User Connection
				</Typography>
			) : displayType === "Sub" ? (
				<Typography variant="h4" component="h4">
					Carisk User Connection
				</Typography>
			) : null}
			{/* <form onSubmit={handleSubmit(onSubmit)}> */}
			{/* <pre>{JSON.stringify(defaultValues, null, 4)}</pre> */}
			<Stack spacing={2}>
				<StandardTextField
					label="First Name"
					value={formValues.firstname}
					variant="standard"
					onChange={(x) => {
						dispatch_formValues({ firstname: x.target.value });
					}}
					fieldname="firstname"
					errors={errors}
				/>
				<StandardTextField
					label="Last Name"
					value={formValues.lastname}
					variant="standard"
					onChange={(x) => {
						dispatch_formValues({ lastname: x.target.value });
					}}
					fieldname="lastname"
					errors={errors}
				/>
				<StandardTextField
					label="Carisk ID"
					value={formValues.id}
					variant="standard"
					onChange={(x) => {
						dispatch_formValues({ id: x.target.value });
					}}
					fieldname="id"
					errors={errors}
				/>
				<StandardDatePicker
					label="Date of Birth"
					value={dayjs(formValues.dob ?? null)}
					onChange={(x, y) => {
						console.debug(x, y);
						if (x) {
							console.debug(x.date());
							console.debug(new Date(x.toDate()));
							console.debug(x.toDate());
							dispatch_formValues({ dob: x.toDate() });
						} else {
							dispatch_formValues({ dob: undefined });
						}
					}}
					fieldname="dob"
					errors={errors}
				/>
				<StandardTextField
					label="Zip"
					value={formValues.zip}
					variant="standard"
					onChange={(x) => {
						let value = displayConverter.filterNonNumeric(x.target.value);
						if (value.length <= 5) {
							dispatch_formValues({ zip: value });
						}
					}}
					fieldname="zip"
					errors={errors}
				/>
				{/* <p>{formValues.dob?.toDateString()}</p> */}
				{/* <AddressFormSection set_addressFormValidate={set_addressFormValidate} /> */}
			</Stack>

			{/* <FormAlerts formStateErrors={errors} /> */}
			<OperationStatusErrorDisplay opStat={apiResponse} />
			{/* <Button
				type={"button"}
				onClick={() => {
					send();
				}}
			>
				send
			</Button> */}
		</CardContent>
	);
}

export { CariskConnectionAddonForm, connectCariskPlanX };
export type { CariskConnectFormValues, CariskSignUpPrimaryFormValues };
