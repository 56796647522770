import { Box, Button, Typography } from "@mui/material";
import { ReactNode, useMemo, useState } from "react";
import { palette } from "theme";
import { Loader } from "../Loaders/Loader";

type LoadMorePaginatorProps = {
	pageSize?: number;
	data: Array<any> | null;
	renderItem: (item: any, index?: number) => ReactNode;
	retrieveItems?: (data: Array<any>) => any;
	hasNextBatch?: boolean;
	loadingNextBatch?: boolean;
	noDataMessage?: string;
};
const LoadMorePaginator = ({ pageSize: _pageSize, data, renderItem, retrieveItems, hasNextBatch, loadingNextBatch, noDataMessage }: LoadMorePaginatorProps) => {
	let pageSize = _pageSize ?? 10;
	const [currentPage, set_currentPage] = useState<number>(1);

	const pageCount = useMemo(() => {
		if (data) {
			return Math.ceil(data.length / pageSize);
		}
	}, [data, pageSize]);

	const pageContent = useMemo(() => {
		if (data) {
			let pageData = data.slice(0, pageSize * (currentPage - 1) + pageSize);
			return (
				<Box style={{ flexBasis: 1, flexGrow: 1, width: "100%" }}>
					{pageData.map((item: any, i) => {
						if (loadingNextBatch && currentPage === pageCount && i + 1 === pageData.length) {
							return (
								<Box key="plusLoader">
									{renderItem(item, i)}
									<Loader key="loader" />
								</Box>
							);
						}
						return renderItem(item, i);
					})}
				</Box>
			);
		} else {
			return null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, pageSize, currentPage, renderItem, loadingNextBatch, hasNextBatch]);

	if (pageCount && currentPage > pageCount) {
		set_currentPage(1);
	}
	return (
		<Box data-component="Paginator" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
			{pageContent}
			{data && data.length > 0 ? (
				<>
					<Typography variant="tiny-title" style={{ marginTop: "16px", marginBottom: "16px" }}>
						Showing{" "}
						{(() => {
							let v = pageSize * currentPage;
							return v <= data.length ? v : data.length;
						})()}{" "}
						of {data.length}
						{hasNextBatch ? (
							<Box component="span" sx={{ color: palette.core.electricblue }}>
								+
							</Box>
						) : null}
					</Typography>
					{pageSize * currentPage >= data.length ? (
						currentPage === 1 ? null : (
							<Button
								variant="outlined"
								onClick={() => {
									set_currentPage(1);
								}}
							>
								View Less
							</Button>
						)
					) : (
						<Button
							variant="outlined"
							onClick={() => {
								set_currentPage(currentPage + 1);
							}}
						>
							View More
						</Button>
					)}
				</>
			) : data && data.length === 0 && !loadingNextBatch ? (
				<Box style={{ padding: "2rem" }}>
					<Typography variant="description-paragraph">{noDataMessage ? noDataMessage : "No Results"}</Typography>
				</Box>
			) : null}
		</Box>
	);
};

export { LoadMorePaginator };
