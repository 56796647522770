import { Grid } from "@mui/material";
import { ConsumerGetConditionDetailResponseModel, ConsumerGetConditionDetailResult } from "api";
import { ContentSection } from "common/components/Containers/ContentSection";
import { DiagnosisDetailOption } from "common/context/MainContext";
import { ReactNode, useMemo } from "react";
import { UseQueryResult } from "react-query";
import { DiagnosisDetailDescription } from "./DiagnosisDetailDescription";
import { DiagnosisDetailTopDoctors } from "./DiagnosisDetailTopDoctors";

type DiagnosisDetailOverviewProps = {
	diagnosisDetailOption?: DiagnosisDetailOption | null;
	queryData?: ConsumerGetConditionDetailResponseModel | null;
	searchRadius: number;
	set_searchRadius: React.Dispatch<React.SetStateAction<number>>;
	conditionDetailResult: UseQueryResult<ConsumerGetConditionDetailResult, unknown>;
	//diagnosisId: string;
	medlineErrorRefreshButton: ReactNode;
};

const DiagnosisDetailOverview = ({
	diagnosisDetailOption,
	queryData,
	searchRadius,
	set_searchRadius,
	conditionDetailResult,
	medlineErrorRefreshButton,
}: //diagnosisId,
DiagnosisDetailOverviewProps) => {
	const showTopDoctors = useMemo(() => {
		// console.debug({ diagnosisDetailOption, consumerMedicalCodeGetConditionDetailResponseModel });
		if (diagnosisDetailOption && diagnosisDetailOption.topDoctors) {
			if (diagnosisDetailOption.topDoctors === "hide") {
				return false;
			}
			if (diagnosisDetailOption.topDoctors === "only_IcdRangeLookupTaxonomy") {
				if (queryData && queryData.doctorsInAreaTaxonomyApproachMethod && queryData.doctorsInAreaTaxonomyApproachMethod === "IcdRangeLookupTaxonomy") {
					return true;
				}
				return false;
			}
		}
		if (queryData && queryData.treatingTaxonomies === null) {
			return false;
		}
		return true;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [diagnosisDetailOption, queryData]);
	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<ContentSection title="Description">
					{/* {String(conditionDetailResult.isLoading)} */}
					<DiagnosisDetailDescription
						conditionDetailResult={conditionDetailResult}
						// queryData={queryData}
						// diagnosisId={diagnosisId}
						medlineErrorRefreshButton={medlineErrorRefreshButton}
					/>
				</ContentSection>
			</Grid>
			{/* {diagnosisDetailOption?.topDoctors}:{String(showTopDoctors)} */}
			{showTopDoctors ? (
				<Grid item xs={12}>
					{queryData ? (
						<DiagnosisDetailTopDoctors
							getConditionDetailResponse={queryData}
							searchRadius={searchRadius}
							set_searchRadius={set_searchRadius}
							queryConditionDetailResult={conditionDetailResult}
						/>
					) : null}
				</Grid>
			) : null}
		</Grid>
	);
};

export { DiagnosisDetailOverview };
