import { useMemo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { CloseButton } from "../Buttons/CloseButton";
import { styleConverter } from "../../utilities/styleConverter";
import { palette } from "theme";
import { AchievementType, event_type_definitions } from "common/definitions/EventDefinitions";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import { ModelsUSPSTF } from "api";
import { useQueryClient } from "react-query";
import { X_updateRemindersUspstfCodes } from "common/requests/patient_data/X_updateRemindersUspstfCodes";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";

interface AchievementTileProps {
	achievementType: AchievementType;
	title: string;
	completed?: boolean;
	closeAction?: (x?: any) => any;
	uspstf: ModelsUSPSTF;
}

const AchievementTile = ({ closeAction, title, achievementType, completed, uspstf }: AchievementTileProps) => {
	const queryClient = useQueryClient(); // not having this here breaks the invalidateQueries
	let x = event_type_definitions[achievementType];

	let titleColorMarkup = useMemo(() => {
		return styleConverter.textColorMarkupX(title, palette.gray.darker);
	}, [title]);

	if (!x) {
		window.RadiusCare.log_issue({ message: `Unrecognized achievementType: ${achievementType}`, file: "AchievementTile" });
		return null;
	}

	return (
		<Box data-componentname="EventTile-Outer" sx={{ height: "100%" }}>
			<Stack
				className="achievements-tile"
				justifyContent={"space-between"}
				sx={{
					height: "100%",
					width: "100%",
					padding: "1rem",
					borderRadius: "1rem",
					boxSizing: "border-box",
					backgroundColor: palette.gray.light,
					borderWidth: "2px",
					borderStyle: "solid",
					borderColor: completed === true ? palette.core.forestgreen : palette.gray.light,
				}}
			>
				<Stack flexDirection="row">
					<Box sx={{ color: x.color, flexGrow: 0 }}>{x.icon}</Box>
					<Box sx={{ paddingLeft: ".5rem" }}>
						<Typography sx={{ color: palette.gray.dark, fontWeight: 700 }}>
							{x.default_text}{" "}
							{completed ? (
								<>
									{" "}
									-{" "}
									<Typography component="span" sx={{ color: palette.core.forestgreen, fontWeight: 700 }}>
										Completed
									</Typography>
								</>
							) : null}
						</Typography>

						<Typography
							sx={{
								fontSize: "1.25rem",
								color: palette.gray.darker,
								"& .colorText": {
									color: x.color,
								},
							}}
						>
							{titleColorMarkup}
						</Typography>
					</Box>

					{uspstf.userAddedReminder ? (
						<Box sx={{ flexGrow: 100, display: "flex", justifyContent: "flex-end" }}>
							<Box>
								<HealthIcon iconSVG={health_icons_svg.faBellOn} />
							</Box>
						</Box>
					) : null}
				</Stack>
				<Stack
					justifyContent="flex-end"
					alignSelf={"self-end"}
					sx={{
						width: "100%",
						"& .MuiButton-root": { width: { xs: "100%", sm: "10rem" } },
						flexDirection: { xs: "column%", sm: "row" },
						paddingTop: ".5rem",
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
					}}
				>
					<Box sx={{ width: { xs: "100%", sm: "auto" } }}>
						{uspstf.completedByCPTCode ? null : ( // <p>Identified fulfilling Procedure Code: CPT {uspstf.completedByCPTCode}</p>
							<OptionsButton
								onClick={() => {
									console.debug(uspstf);
								}}
								content={
									<Box>
										{uspstf.manuallyCompleted ? (
											<Button
												variant="text"
												fullWidth
												sx={{ textAlign: "center" }}
												onClick={() => {
													X_updateRemindersUspstfCodes({ uspstfCodesCompleted: [String(uspstf.id)], removeItems: true }, queryClient);
												}}
											>
												Un-Mark Completed
											</Button>
										) : null}
										{uspstf.userHasCompleted ? null : (
											<Button
												variant="text"
												fullWidth
												sx={{ textAlign: "center" }}
												onClick={async () => {
													await X_updateRemindersUspstfCodes({ uspstfCodesCompleted: [String(uspstf.id)] }, queryClient);
												}}
											>
												Mark Completed
											</Button>
										)}
										{uspstf.manuallyIgnored ? (
											<Button
												variant="text"
												fullWidth
												sx={{ textAlign: "center" }}
												onClick={() => {
													X_updateRemindersUspstfCodes({ uspstfCodesIgnore: [String(uspstf.id)], removeItems: true }, queryClient);
												}}
											>
												Un-Ignore
											</Button>
										) : uspstf.userHasCompleted ? null : (
											<Button
												variant="text"
												fullWidth
												sx={{ textAlign: "center" }}
												onClick={() => {
													X_updateRemindersUspstfCodes({ uspstfCodesIgnore: [String(uspstf.id)] }, queryClient);
												}}
											>
												Ignore
											</Button>
										)}
										{uspstf.userAddedReminder ? (
											<Button
												variant="text"
												fullWidth
												sx={{ textAlign: "center" }}
												onClick={() => {
													// console.debug(uspstf);
													X_updateRemindersUspstfCodes({ uspstfCodes: [String(uspstf.id)], removeItems: true });
												}}
											>
												Remove Reminder
											</Button>
										) : null}
									</Box>
								}
							/>
						)}
					</Box>
					{completed ? null : (
						<Box
							sx={{
								width: { xs: "100%", sm: "auto" },
								paddingTop: { xs: ".5rem", sm: "0" },
								paddingLeft: { xs: "0", sm: ".25rem" },
							}}
						>
							{uspstf.userAddedReminder ? null : (
								// <Button
								// 	size="small"
								// 	sx={{ opacity: 0.25 }}
								// 	color="error"
								// 	onClick={() => {
								// 		// alert("Remind Me");
								// 		console.debug(uspstf);
								// 		X_updateRemindersUspstfCodes({ uspstfCodes: [String(uspstf.id)], removeItems: true });
								// 	}}
								// >
								// 	Remove Reminder
								// </Button>
								<Button
									color="error"
									onClick={() => {
										// alert("Remind Me");
										console.debug(uspstf);
										X_updateRemindersUspstfCodes({ uspstfCodes: [String(uspstf.id)] });
									}}
								>
									Remind Me
								</Button>
							)}
						</Box>
					)}
				</Stack>

				{closeAction ? (
					<Box style={{ position: "relative", width: "20px" }}>
						<CloseButton
							onClick={(x) => {
								closeAction(x);
							}}
							right={-8}
							top={-8}
						/>
					</Box>
				) : null}
			</Stack>
		</Box>
	);
};

export { AchievementTile };
