/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerRetrieveGoogleMapsLocationResult,
  RetrieveGoogleMapsLocationParams
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const retrieveGoogleMapsLocation = (
    params?: RetrieveGoogleMapsLocationParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerRetrieveGoogleMapsLocationResult>(
      {url: `/api/consumer/Location/RetrieveGoogleMapsLocation`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getRetrieveGoogleMapsLocationQueryKey = (params?: RetrieveGoogleMapsLocationParams,) => [`/api/consumer/Location/RetrieveGoogleMapsLocation`, ...(params ? [params]: [])];

    
export type RetrieveGoogleMapsLocationQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveGoogleMapsLocation>>>
export type RetrieveGoogleMapsLocationQueryError = unknown

export const useRetrieveGoogleMapsLocation = <TData = Awaited<ReturnType<typeof retrieveGoogleMapsLocation>>, TError = unknown>(
 params?: RetrieveGoogleMapsLocationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveGoogleMapsLocation>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveGoogleMapsLocationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveGoogleMapsLocation>>> = ({ signal }) => retrieveGoogleMapsLocation(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveGoogleMapsLocation>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

