import { Box, SxProps } from "@mui/material";
import { icons } from "theme";
import Icon from "../Icons/Icon";

type OpenCloseArrowProps = {
	open: boolean;
	absoluteRight?: boolean;
};
const OpenCloseArrow = ({ open, absoluteRight }: OpenCloseArrowProps) => {
	const sx: SxProps = {
		transform: `rotate(${open ? "0deg" : "180deg"})`,
		transition: "transform 750ms",
		padding: 0,
		margin: 0,
		width: "14px",
		height: "100%",
	};
	if (absoluteRight) {
		sx.position = "absolute";
		sx.right = 4;
	}
	return (
		<Box sx={sx}>
			<Icon icon={icons.angleDown} />
		</Box>
	);
};

export { OpenCloseArrow };
