import { Box, Grid, Stack, Typography } from "@mui/material";
import { DashboardMenu } from "../../DashboardMenu";
import { KeyFindings } from "./components/KeyFindings";
import { Reminders } from "./components/Reminders";
import { ContentSection } from "../../../common/components/Containers/ContentSection";
import { TotalCareScore } from "./components/TotalCareScore";
import { InfoPopper } from "common/components/DisplayElements/InfoPopper";
import { ConsumerRetrievePatientHealthDataResponseModel_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { ProcessedResponse } from "common/utilities/apiResponseReader";
import { UseQueryResult } from "react-query";
import { ListDisplay } from "common/components/DisplayElements/ListDisplay";
import { DialogManager } from "common/managers/DialogManager";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { styleConverter } from "common/utilities/styleConverter";
import { palette } from "theme";
import { NewDiagnosisAlert } from "common/components/DisplayElements/NewDiagnosisAlert";
import { SimpleModal } from "common/components/Modals/SimpleModal";
import { useEffect, useMemo, useState } from "react";
import { ConsumerKeyFindingsResponseModel, ConsumerOperationStatusCareScoreResponseModel, JobExecution } from "api";

type CareOverviewProps = {
	patientEOBData: UseQueryResult<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>, unknown>;
	careScoreData: UseQueryResult<ConsumerOperationStatusCareScoreResponseModel, unknown>;
	keyFindings: ConsumerKeyFindingsResponseModel | null;
};

const CareOverview = ({ patientEOBData, careScoreData, keyFindings }: CareOverviewProps) => {
	const [executionJobModalOpen, set_executionJobModalOpen] = useState<null | Array<string>>(null);
	const [executionJobModalLastShown] = useState<number>(0);

	const requeryInterval = 60000;

	const jobExecutions = useMemo<JobExecution[] | null>(() => {
		if (patientEOBData.data?.data?.jobExecutions) {
			if (Array.isArray(patientEOBData.data.data.jobExecutions)) {
				return patientEOBData.data.data.jobExecutions;
			} else {
				return [patientEOBData.data.data.jobExecutions] as JobExecution[];
			}
		}
		return null;
	}, [patientEOBData]);

	useEffect(() => {
		if (jobExecutions) {
			let pending_status: Array<string> = [];
			jobExecutions.forEach((je) => {
				if (je.jobState !== "Completed") {
					pending_status.push(je.jobState.toLowerCase());
				}
			});

			if (pending_status.indexOf("retrieving") !== -1) {
				if (Date.now() - executionJobModalLastShown > requeryInterval - 1000) {
					console.debug({ pending_status }, "Identified Pending ExecutionJob for Patient Data");
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [jobExecutions]);

	return (
		<Box>
			<DashboardMenu />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<ContentSection
						title="Your Total Care Score"
						titleBarContent={
							<InfoPopper>
								<Typography variant="h4">Your Total Care Score</Typography>
								<Typography>Your total care score is calculated based on the ratings of the doctors and hospitals you go to.</Typography>
								<Typography>
									Radius Care has amassed a database of millions of US healthcare transactions including ratings and reviews tied to most
									doctors and facilities. The displayed scores are compiled from past patient ratings, patient feedback, quality scores and
									outcomes data.
								</Typography>
								<Typography>* Radius Care updates ratings details on a monthly basis.</Typography>
							</InfoPopper>
						}
					>
						<TotalCareScore careScoreData={careScoreData} />
					</ContentSection>
				</Grid>
				<Grid item xs={12} md={6}>
					<ContentSection title="Key Findings">
						<KeyFindings keyFindings={keyFindings} patientEOBData={patientEOBData} />
					</ContentSection>
				</Grid>
				<Grid item xs={12} md={6}>
					<ContentSection title="Reminders">
						<Reminders />
					</ContentSection>
				</Grid>
			</Grid>

			{careScoreData?.data?.data?.newDiagnosis && careScoreData.data.data.newDiagnosis.length > 0 ? (
				<NewDiagnosisAlert
					content={
						<>
							<Stack
								sx={{
									padding: "1rem",
									width: "100%",
									boxSizing: "border-box",
									"& li": {
										backgroundColor: palette.gray.light,
										marginBottom: ".5rem",
										borderRadius: ".5rem",
										color: palette.error.main,
									},
								}}
							>
								{careScoreData.data.data.newDiagnosis ? (
									<ListDisplay
										title=""
										items={careScoreData.data.data.newDiagnosis?.map((d) => {
											let icd10 = StaticDataManager.data.ICD10CM_Package.code_map.get(d);
											if (icd10) {
												return (
													<Stack>
														<Box
															component={"span"}
															sx={{
																color: palette.error.main,
																cursor: "pointer",
															}}
															onClick={() => {
																DialogManager.open("diagnosis-details", icd10?.ICD10Code);
															}}
														>
															{styleConverter.textColorMarkupX(icd10.Description, palette.error.main)}
														</Box>
													</Stack>
												);
											}
											return styleConverter.textColorMarkupX(d, palette.error.main);
										})}
									/>
								) : null}
							</Stack>
						</>
					}
				/>
			) : null}
			<SimpleModal
				open={!!executionJobModalOpen}
				onClose={function () {
					set_executionJobModalOpen(null);
				}}
			>
				<Typography variant="tile-header-dark">Your Health Records are currently being retrieved from your Provider</Typography>
				<br />
				<br />
				<Typography variant="description-paragraph">You will see additional data in your account once this process has completed</Typography>
			</SimpleModal>

			{/* {executionJobModalLastShown} */}
		</Box>
	);
};

export { CareOverview };
