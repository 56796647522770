import { Box, Stack } from "@mui/material";
import { ConsumerCostOfCareResponseModel } from "api";
import { StandardRadialBarChart } from "common/components/Charts/StandardRadialBarChart";
import { StandardStackedBarChart } from "common/components/Charts/StandardStackedBarChart";
import { EventTypeNames, event_type_definitions } from "common/definitions/EventDefinitions";
import displayConverter from "common/utilities/displayConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { useMemo } from "react";
import { theme } from "theme";

type CostOfCareProps = {
	costOfCareData: ConsumerCostOfCareResponseModel;
};
const CostOfCare = ({ costOfCareData }: CostOfCareProps) => {
	const radialData = useMemo(() => {
		console.debug({ costOfCareData });
		let data = [
			{ key: "medication", count: costOfCareData.totals.medicationCount, color: styleConverter.getPaletteColor(event_type_definitions.medication.color) },
			{ key: "treatments", count: costOfCareData.totals.treatmentCount, color: styleConverter.getPaletteColor(event_type_definitions.treatments.color) },
			{ key: "provider_visit", count: costOfCareData.totals.providerCount, color: styleConverter.getPaletteColor(event_type_definitions.provider.color) },
			// {
			// 	key: "medication",
			// 	count: costOfCareData.savingsModel.medicationCost,
			// 	color: styleConverter.getPaletteColor(event_type_definitions.medication.color),
			// },
			// {
			// 	key: "treatments",
			// 	count: costOfCareData.savingsModel.hospitalCost,
			// 	color: styleConverter.getPaletteColor(event_type_definitions.treatments.color),
			// },
			// {
			// 	key: "provider_visit",
			// 	count: costOfCareData.savingsModel.providerCost,
			// 	color: styleConverter.getPaletteColor(event_type_definitions.provider.color),
			// },
		];

		let colors = data.map((d) => d.color);
		return { colors, data };
	}, [costOfCareData]);

	const monthlyData = useMemo(() => {
		let data = costOfCareData.months.map((m) => {
			return {
				date: `${m.name} ${m.year}`,
				lab_test: m.labTestCount,
				medication: m.medicationCount,
				month: m.name,
				procedure: m.procedureCount,
				provider_visit: m.providerCount,
				treatments: m.treatmentCount,
			};
		});

		let dataKeys: Array<{ key: EventTypeNames; color?: string }> = (
			["medication", "treatments", "provider_visit"].filter((x: string) => {
				if (x === "date" || x === "month") {
					return false;
				}
				return true;
			}) as Array<EventTypeNames>
		).map((type) => {
			return { key: type, color: styleConverter.getPaletteColor(event_type_definitions[type]?.color) };
		});

		const dataLabels: { [key in EventTypeNames]?: string } = {};
		dataKeys.forEach((keydef) => {
			dataLabels[keydef.key] = event_type_definitions[keydef.key]?.default_text;
		});
		return { data, dataKeys, dataLabels };
	}, [costOfCareData]);

	return (
		<Box>
			{radialData ? (
				<Stack
					sx={{
						flexDirection: "row",
						justifyContent: "center",
						width: "100%",
						[theme.breakpoints.down("sm")]: {
							flexDirection: "column",
						},
					}}
				>
					<Stack alignItems={"center"} justifyContent={"center"} sx={{ width: { xs: "100%", sm: "25%" } }}>
						<StandardRadialBarChart
							data={radialData.data}
							nameKey="key"
							dataKey="count"
							innerRadius={50}
							outerRadius={80}
							responsiveHeight={300}
							colors={radialData.colors}
							// showCustom={<Box style={{ fontSize: "1.2rem" }}>{displayConverter.toUSD(costOfCareData.totals.totalCost, { cents: false })}</Box>}
							showCustom={
								<Box style={{ fontSize: "1.2rem" }}>
									{displayConverter.toUSD(costOfCareData.savingsModel.combinedCategoryCost, { cents: false })}
								</Box>
							}
						/>
					</Stack>
					<Stack sx={{ flex: "1" }}>
						<StandardStackedBarChart
							legendVerticalAlign="top"
							data={monthlyData.data}
							yKeys={monthlyData.dataKeys}
							xKey={"month"} // date
							stack={false}
							keyLabels={monthlyData.dataLabels}
							minHeight={200}
						/>
					</Stack>
				</Stack>
			) : null}
		</Box>
	);
};

export { CostOfCare };
