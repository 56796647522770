import { lighten, Stack, TextField, TextFieldProps } from "@mui/material";
import { FormErrorDisplayInline } from "common/forms/FormAlerts";
import { FormErrors } from "common/forms/formUtilities";
import { useMemo } from "react";
import { colorTransforms, palette } from "theme";

type ErrorableInputProps = {
	errors?: null | false | FormErrors;
	fieldname?: string;
};

function useInlineError(fieldname?: string, errors?: null | false | FormErrors) {
	const error = useMemo(() => {
		let e = errors;
		let f = fieldname;
		if (e && f) {
			if (e[f]) {
				return e[f];
			}
		}
		return null;
	}, [errors, fieldname]);

	return error;
}

type TextFieldPropsX = TextFieldProps & ErrorableInputProps;

const StandardTextField = (props: TextFieldPropsX = {}, control: any) => {
	// const error = useMemo(() => {
	// 	let e = props.errors;
	// 	let f = props.fieldname;
	// 	if (e && f) {
	// 		if (e[f]) {
	// 			return e[f];
	// 		}
	// 	}
	// 	return null;
	// }, [props.errors, props.fieldname]);

	const error = useInlineError(props.fieldname, props.errors);

	let styles: any = {
		"& .MuiInput-root > input": {
			padding: "4px 8px 5px 8px", // match StandardSelect height/position
		},
		"& .MuiInput-root:before": { borderWidth: "2px", borderColor: palette.gray.dark },
		"& .MuiInputBase-root.MuiInput-root:hover:not(.Mui-disabled):before": { borderColor: lighten(palette.core.blue, colorTransforms.lightenUp) },
		"& .MuiInput-root:after": { borderWidth: "2px", borderColor: palette.core.blue },
	};
	if (error || props.hasOwnProperty("data-styletype")) {
		if (error || (props as any)["data-styletype"] === "errordisplay") {
			styles = {
				"& .MuiInput-root > input": {
					padding: "4px 8px 5px 8px",
				},
				"& .MuiInput-root:after": { borderWidth: "2px", borderColor: palette.error.main },
				"& .MuiInput-root:hover:not(.Mui-disabled):before": { borderWidth: "2px", borderColor: palette.error.main },
				"& .MuiInput-root:before": { borderWidth: "2px", borderColor: palette.error.main },
				"& .MuiInput-root:focus": { borderWidth: "2px", borderColor: palette.error.main },
				"& > label": { color: palette.error.main + " !important" },
				"& .MuiInput-root": { color: palette.gray.darker, borderWidth: "2px", borderColor: palette.error.main },
				"& .MuiFormHelperText-root": { color: palette.error.main },
			};
		} else if ((props as any)["data-styletype"] === "infodisplay") {
			styles = {
				"& .MuiInput-root > input": {
					padding: "4px 8px 5px 8px",
				},
				"& .MuiInput-root:after": { borderWidth: "2px", borderColor: palette.gray.darker },
				"& .MuiInput-root:before": { borderWidth: "2px", borderColor: palette.gray.darker },
				"& .MuiInput-root:focus": { borderWidth: "2px", borderColor: palette.gray.main },
				"& > label": { color: "#848484 !important" },
				"& .MuiInput-root": { color: palette.gray.darker, borderWidth: "2px", borderColor: palette.gray.darker },
			};
		}
	}
	return (
		<Stack>
			<TextField {...props} variant="standard" sx={Object.assign(styles, props.sx ?? {})} />
			{error ? <FormErrorDisplayInline error={error} /> : null}
		</Stack>
	);
};

export { StandardTextField, useInlineError };

export type { ErrorableInputProps };
