import { suggestSimilarDoctors, ConsumerDoctorSuggestSimilarDoctorsRequestModel, ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel } from "api";
import { useQuery, UseQueryResult } from "react-query";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";

async function suggestSimilarDoctorsX(
	params: ConsumerDoctorSuggestSimilarDoctorsRequestModel
): Promise<ProcessedResponse<ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel | null>> {
	let result = await suggestSimilarDoctors(params);
	return apiResponseReader.processResponse(result as unknown as ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel);
}

function useQ_suggestSimilarDoctors(
	paramsX: ConsumerDoctorSuggestSimilarDoctorsRequestModel
): UseQueryResult<ProcessedResponse<ConsumerDoctorOperationStatusSuggestSimilarDoctorsResponseModel>> {
	return useQuery(
		[
			"clientlocation",
			"suggestSimilarDoctors",
			`suggestSimilarDoctors[
				npi-${paramsX.npi}
				_inNetwork-${paramsX.inNetwork}
				_lat-${paramsX.clientLocation.latitude}
				_long-${paramsX.clientLocation.longitude}
				_zip-${paramsX.clientLocation.zipcode}
				_dist-${paramsX.clientLocation.distanceRadiusMiles}`,
		],
		suggestSimilarDoctorsX.bind(null, paramsX),
		{
			staleTime: Infinity,
			cacheTime: Infinity,
		}
	);
}

export { useQ_suggestSimilarDoctors };
