import { AppBar, Box, Container, Grid, Typography, Button } from "@mui/material";
import { MyCostGetProviderSavingsResult, useGetProviderSavings } from "api";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { ClinicTile } from "common/components/Tiles/ClinicTile";
import { GeolocationContext } from "common/context/GeolocationContext";
import { ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { DisplayInfo } from "common/definitions/EntityDefinitions";
import { DialogManager } from "common/managers/DialogManager";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { useContext, useEffect, useMemo } from "react";
import { style } from "theme";

function ProviderSavingsDetails() {
	const geo = useContext(GeolocationContext);
	useEffect(() => {
		GeolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
	}, [geo.position]);

	const location = useMemo(() => {
		GeolocationManager.getPosition();
		return GeolocationManager.clientLocation({ useAccountPrefs: true });
	}, []);

	const { data } = useGetProviderSavings({
		"clientLocation.distanceRadiusMiles": 25,
		"clientLocation.latitude": location.latitude ?? 0,
		"clientLocation.longitude": location.longitude ?? 0,
		"clientLocation.zipcode": location.zipcode ?? "",
	});

	console.debug(data);

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Provider Pricing</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("provider-savings-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth="md" sx={{ py: "1rem" }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<ContentSection title="Description">
							<Typography>
								For routine healthcare events like vaccinations, cold and flu treatments, and other minor healthcare events - clinics and
								community health centers are often less expensive and may save you money. Below are a list of area care locations which may be
								of lower cost to you
							</Typography>
						</ContentSection>
					</Grid>
					<Grid item xs={12}>
						<ContentSection title="Area Care">
							{(data as MyCostGetProviderSavingsResult)?.clinics?.map((clinic) => {
								const address = "";
								let distanceInfo: DisplayInfo = {
									title: "Distance",
									value: clinic.distanceMiles ?? "??",
								};
								let entity: ProviderEntity = {
									entityTypeName: "provider",
									title: clinic.name ?? "",
									descriptor: address ?? "",
									displayInfo: [distanceInfo],
								};

								let directionsOnClick = () => {
									window.open(clinic.directionUrl, "_blank");
								};
								let websiteOnClick = () => {
									window.open(clinic.url, "_blank");
								};
								let priceList = () => {
									window.open("https://www.cvs.com/minuteclinic/services/price-lists", "_blank");
								};

								let content = (
									<Box>
										<Button variant="text" onClick={websiteOnClick}>
											Visit Website
										</Button>
										<Button variant="text" onClick={directionsOnClick}>
											Get Directions
										</Button>
										<Button variant="text" onClick={priceList}>
											Price List
										</Button>
									</Box>
								);
								return (
									<Box sx={{ paddingBottom: ".5rem" }}>
										<ClinicTile url={clinic.url} Entity={entity} content={content} />
									</Box>
								);
							})}
						</ContentSection>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}

export { ProviderSavingsDetails };
