import { Box, Grid, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { ContentSection } from "common/components/Containers/ContentSection";
import { useContext, useMemo } from "react";
import { GeolocationContext } from "common/context/GeolocationContext";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";
import { useQ_findHospitalsByLocation } from "./requests/useQ_findHospitalsByLocation";
import { OptimizeCareSimpleHospitalTile } from "common/components/Tiles/OptimizeCareSimpleHospitalTile";

function HospitalsList() {
	const geoContext = useContext(GeolocationContext);

	const clientLocation = useMemo(() => {
		return geoContext.geolocationManager.clientLocation();
	}, [geoContext]);

	const hospitalsByLocationResult = useQ_findHospitalsByLocation({ clientLocation });

	return (
		<QueryStatusDisplay queryResult={hospitalsByLocationResult}>
			{hospitalsByLocationResult?.data?.data?.data?.hospitals && hospitalsByLocationResult.data.data.data.hospitals.length > 0 ? (
				<Grid item xs={12}>
					<ContentSection title={"Hospitals"}>
						<LoadMorePaginator
							pageSize={5}
							data={hospitalsByLocationResult.data.data.data.hospitals.map((h, i) => {
								return (
									<Box key={i} sx={{ paddingBottom: ".5rem" }}>
										<OptimizeCareSimpleHospitalTile hospital={h} />
									</Box>
								);
							})}
							renderItem={(providerTile: any, i?: number) => {
								return providerTile;
							}}
						/>
					</ContentSection>
				</Grid>
			) : (
				<Typography sx={{ margin: "32px" }}>
					{hospitalsByLocationResult?.data?.data?.data?.distanceRadiusMiles
						? `No Hospitals found within ${hospitalsByLocationResult?.data?.data?.data.distanceRadiusMiles} miles.`
						: "No local hospitals were found"}
				</Typography>
			)}
		</QueryStatusDisplay>
	);
}

export { HospitalsList };
