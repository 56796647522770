import {
	ConsumerMedicalCodeGetAllHCPCSCodesResult,
	ConsumerMedicalCodeGetIcd10CmCodesPackageResponseModel,
	ConsumerMedicalCodeGetICD10CMCodesPackageResult,
	getAllHCPCSCodes,
	getICD10CMCodesPackage,
	getProviderTaxonomies,
	ModelsICD10Short,
	retrieveApplicationDefinitions,
} from "api";
import { StandardOption } from "common/types/generic";
import { providerTaxonomyMapper, ProviderTaxonomyPackage } from "common/utilities/providerTaxonomyMapper";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { apiResponseCache } from "common/utilities/apiResponseCache";
import {
	ModelsHCPCS,
	ModelsCPTCategory,
	ModelsICD10Range,
	ModelsICD10SubRange,
	ModelsICD10AlphaCategory,
	ConsumerDoctorGetProviderTaxonomiesResult,
	DataSourceOptionPrimary,
} from "../../../api/backend.schemas";

type StaticDataDefinitionsResult = {
	providerTaxonomyPackage: ProviderTaxonomyPackage;
	ICD10CM_Package: ICD10_Codes_Package;
	HCPCS: PackagedHCPCSCodes;
	// static hardcoded definitions
	providerTypes: Array<StandardOption<string, string>>;
	physicianTypes: Array<StandardOption<string, string>>;
	facilityTypes: Array<StandardOption<string, string>>; // FindCareProviders
	medicationBrandTypes: Array<StandardOption<string, string>>; // FindCareMedication
	genders: Array<StandardOption<string, string>>; // FindCareProviders
	conditions: Array<StandardOption<string, string>>; // FindCareMedication // from singlecare
	languages: Array<StandardOption<string, string>>; // FindCareProviders
	insuranceOptions: Array<DataSourceOptionPrimary>;
	providerTaxonomyClassifications: Array<string>;
};

const cacheValidMinutes = 24 * 60 * 7 * 4; /* 4 weeks*/

async function retrieveStaticDataDefinitions(): Promise<StaticDataDefinitionsResult> {
	let result = await new Promise(async (resolve, _reject) => {
		// let baseUrl = await getAppBaseUrl();
		// let taxonomy_codes = await apiRequest({ url: `${baseUrl}/data/nucc_provider_taxonomy_codes.csv` });
		// let codes = await csvParser.parseCSV(taxonomy_codes as string, { header: true });

		const API_Definitions = await retrieveApplicationDefinitions({ includeConditionRangesIcd10: false, includeTaxonomyClassifications: true });

		if (API_Definitions.data === null) {
			_reject();
			return;
		}

		// API_Definitions.data.insuranceOptions

		const provider_taxonomy_package = await retrieveProviderTaxonomies(API_Definitions.data.cacheRenewValues.providerTaxonomies);

		const ICDO10_X = await retrieve_ICD10_Codes_Package(API_Definitions.data.cacheRenewValues.icd10);

		const HCPCS_X = await retrieveHCPCS(API_Definitions.data.cacheRenewValues.hcpcs);

		// console.debug({ API_Definitions });
		// console.debug("HCPCS_X", HCPCS_X);

		// console.debug("ICDO10_X", ICDO10_X);
		// setTimeout(() => {
		return resolve(
			Object.assign(
				{
					ICD10CM_Package: ICDO10_X,
					HCPCS: HCPCS_X,
					providerTaxonomyPackage: provider_taxonomy_package,
				},
				API_Definitions.data
			)
		);
		// }, 500);
	});
	(window as any).static_data = result;
	return result as StaticDataDefinitionsResult;
}

export { retrieveStaticDataDefinitions };
export type { StaticDataDefinitionsResult };

/*
██████  ██████   ██████  ██    ██ ██ ██████  ███████ ██████      ████████  █████  ██   ██  ██████  ███    ██  ██████  ███    ███ ██    ██ 
██   ██ ██   ██ ██    ██ ██    ██ ██ ██   ██ ██      ██   ██        ██    ██   ██  ██ ██  ██    ██ ████   ██ ██    ██ ████  ████  ██  ██  
██████  ██████  ██    ██ ██    ██ ██ ██   ██ █████   ██████         ██    ███████   ███   ██    ██ ██ ██  ██ ██    ██ ██ ████ ██   ████   
██      ██   ██ ██    ██  ██  ██  ██ ██   ██ ██      ██   ██        ██    ██   ██  ██ ██  ██    ██ ██  ██ ██ ██    ██ ██  ██  ██    ██    
██      ██   ██  ██████    ████   ██ ██████  ███████ ██   ██        ██    ██   ██ ██   ██  ██████  ██   ████  ██████  ██      ██    ██ 
*/

const retrieveProviderTaxonomies = async (forceRenewCacheToken: string): Promise<ProviderTaxonomyPackage | null> => {
	let cacheRule = (response: any) => {
		let valid =
			typeof response === "object" &&
			response.response &&
			Array.isArray(response.response.providerTaxonomies) &&
			response.response.providerTaxonomies.length > 0;
		return valid;
	};
	const response = (await apiResponseCache.checkCache(
		"getProviderTaxonomies",
		async (useCacheApi: boolean) => {
			console.info("CALLING getProviderTaxonomies API");
			return await getProviderTaxonomies({ useCacheApi: useCacheApi });
		},
		{ cacheValidMinutes: cacheValidMinutes, forceRenewCacheToken: forceRenewCacheToken, cacheRule }
	)) as ConsumerDoctorGetProviderTaxonomiesResult;

	if (!response) {
		window.RadiusCare.log_issue({ file: "useQ_staticDataDefinitions", message: "FAILED retrieveProviderTaxonomies" });
	}
	if (!apiResponseReader.typeCheck_ModelsOperationStatus(response)) {
		let provider_taxonomy_package: ProviderTaxonomyPackage | null = null;
		if (response && !apiResponseReader.typeCheck_ModelsOperationStatus(response) && response.response?.providerTaxonomies) {
			provider_taxonomy_package = providerTaxonomyMapper.compileTaxonomyCodes(response.response.providerTaxonomies);
		}
		return provider_taxonomy_package;
	}
	return null;
};

/*
██   ██  ██████ ██████   ██████ ███████ 
██   ██ ██      ██   ██ ██      ██      
███████ ██      ██████  ██      ███████ 
██   ██ ██      ██      ██           ██ 
██   ██  ██████ ██       ██████ ███████ 
*/

export type PackagedHCPCSCodes = {
	codes: Array<ModelsHCPCS>;
	cpt_category: Array<ModelsCPTCategory>;
	lookups: {
		code: Map<string, ModelsHCPCS>;
		cpt_category: Map<string, ModelsCPTCategory>;
	};
};

const retrieveHCPCS = async (forceRenewCacheToken: string): Promise<PackagedHCPCSCodes> => {
	let cacheRule = (response: any) => {
		let valid = typeof response === "object" && response.response && Array.isArray(response.response?.hcpcs) && response.response.hcpcs.length > 0;
		return valid;
	};
	const hcpcs = (await apiResponseCache.checkCache(
		"getHCPCSCodes",
		async (useCacheApi: boolean) => {
			console.info("CALLING getAllHCPCSCodes API");
			return await getAllHCPCSCodes({ useCacheApi: useCacheApi });
		},
		{ cacheValidMinutes: 24 * 60, forceRenewCacheToken: forceRenewCacheToken, cacheRule }
	)) as ConsumerMedicalCodeGetAllHCPCSCodesResult;

	let codes: ModelsHCPCS[];
	let codes_map = new Map<string, ModelsHCPCS>();
	if (hcpcs?.response?.hcpcs) {
		codes = hcpcs.response?.hcpcs;
		codes.forEach((x) => {
			codes_map.set(x.code, x);
		});
	} else {
		codes = [];
		window.RadiusCare.log_issue({ file: "useQ_staticDataDefinitions", message: "FAILED retrieveHCPCS" });
	}
	let cpt_category: ModelsCPTCategory[];
	let cpt_category_map = new Map<string, ModelsCPTCategory>();
	if (hcpcs?.response?.cptCategory) {
		cpt_category = hcpcs.response.cptCategory;

		cpt_category.forEach((x) => {
			cpt_category_map.set(x.cpt_category, x);
		});
	} else {
		cpt_category = [];
	}

	return { codes: codes, cpt_category: cpt_category, lookups: { code: codes_map, cpt_category: cpt_category_map } };
};

/*
██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████     ██████   █████   ██████ ██   ██  █████   ██████  ███████ 
██ ██      ██   ██ ███ ██  ████     ██      ██    ██ ██   ██ ██      ██          ██   ██ ██   ██ ██      ██  ██  ██   ██ ██       ██      
██ ██      ██   ██  ██ ██ ██ ██     ██      ██    ██ ██   ██ █████   ███████     ██████  ███████ ██      █████   ███████ ██   ███ █████   
██ ██      ██   ██  ██ ████  ██     ██      ██    ██ ██   ██ ██           ██     ██      ██   ██ ██      ██  ██  ██   ██ ██    ██ ██      
██  ██████ ██████   ██  ██████       ██████  ██████  ██████  ███████ ███████     ██      ██   ██  ██████ ██   ██ ██   ██  ██████  ███████
*/
export type ICD10 = {
	ICD10Code: string;
	alphaCategory?: string;
	rangeCategory?: string;
	subrangeCategory?: string;
	Description: string;
	Title?: string;
	Alpha?: ModelsICD10AlphaCategory;
	Range?: ModelsICD10Range | ModelsICD10SubRange;
};

export type Condition = {
	name: string;
	id: string;
	icd10_codes: SubRange[];
};

export type SubRange = {
	range: string;
	description: string;
};

type ICD10_Codes_Package = {
	codes: Array<ICD10>;
	code_map: Map<string, ICD10>;
	alpha_category_map: Map<string, ModelsICD10AlphaCategory>;
	range_category_map: Map<string, ModelsICD10Range>;
	subrange_category_map: Map<string, ModelsICD10Range>;
	range_category_map_to_codes: Map<string, ICD10[]>;
	range_categories: Array<ModelsICD10Range>;
};
const retrieve_ICD10_Codes_Package = async function (forceRenewCacheToken: string): Promise<ICD10_Codes_Package> {
	let cacheRule = (response: any) => {
		let valid =
			typeof response === "object" &&
			response.response &&
			Array.isArray(response.response.icd) &&
			Array.isArray(response.response.rangeCategory) &&
			Array.isArray(response.response.alphaCategory) &&
			response.response.icd.length > 0 &&
			response.response.rangeCategory.length > 0 &&
			response.response.alphaCategory.length > 0;
		return valid;
	};

	const request_result: ConsumerMedicalCodeGetICD10CMCodesPackageResult = (await apiResponseCache.checkCache(
		"getICD10CMCodesPackage",
		async (useCacheApi: boolean) => {
			console.info("CALLING getICD10CMCodesPackage API");
			// 			console.debug(`
			//  ██████  █████  ██      ██      ██ ███    ██  ██████
			// ██      ██   ██ ██      ██      ██ ████   ██ ██
			// ██      ███████ ██      ██      ██ ██ ██  ██ ██   ███
			// ██      ██   ██ ██      ██      ██ ██  ██ ██ ██    ██
			//  ██████ ██   ██ ███████ ███████ ██ ██   ████  ██████

			// ██  ██████ ██████   ██  ██████   ██████ ███    ███
			// ██ ██      ██   ██ ███ ██  ████ ██      ████  ████
			// ██ ██      ██   ██  ██ ██ ██ ██ ██      ██ ████ ██
			// ██ ██      ██   ██  ██ ████  ██ ██      ██  ██  ██
			// ██  ██████ ██████   ██  ██████   ██████ ██      ██

			//  █████  ██████  ██
			// ██   ██ ██   ██ ██
			// ███████ ██████  ██
			// ██   ██ ██      ██
			// ██   ██ ██      ██
			// 			`);
			return await getICD10CMCodesPackage({ useCacheApi: useCacheApi });
		},
		{ cacheValidMinutes: 60 * 24 * 7, forceRenewCacheToken: forceRenewCacheToken, cacheRule }
	)) as ConsumerMedicalCodeGetICD10CMCodesPackageResult;

	const api_ICD10package = request_result?.response as ConsumerMedicalCodeGetIcd10CmCodesPackageResponseModel | undefined;
	if (!api_ICD10package) {
		window.RadiusCare.log_issue({ file: "useQ_staticDataDefinitions", message: "FAILED retrieve_ICD10_Codes_Package" });
	}
	console.debug("api_ICD10package", api_ICD10package);

	const alpha_category_map = new Map();
	api_ICD10package?.alphaCategory.forEach((x: ModelsICD10AlphaCategory) => {
		alpha_category_map.set(x.alpha, x);
	});

	const range_category_map: Map<string, ModelsICD10Range> = new Map();
	api_ICD10package?.rangeCategory.forEach((x: ModelsICD10Range) => {
		// console.debug(x);
		range_category_map.set(x.range, x);
	});

	const subrange_category_map: Map<string, ModelsICD10Range> = new Map();
	api_ICD10package?.rangeCategory.forEach((x: ModelsICD10Range) => {
		x.sub_ranges?.forEach((s) => {
			subrange_category_map.set(s.range, x);
		});
		range_category_map.set(x.range, x);
	});

	const range_category_map_to_codes: Map<string, Array<ICD10>> = new Map();
	api_ICD10package?.rangeCategory.forEach((x: any) => {
		range_category_map_to_codes.set(x.range, []);
	});

	let codes: Array<ICD10> | undefined = api_ICD10package?.icd
		.filter((x) => x.code !== null)
		.map((x: ModelsICD10Short, i: number) => {
			let alpha_category = alpha_category_map.get(x.code.slice(0, 1).toUpperCase());
			let alpha_category_name;
			if (alpha_category) {
				alpha_category_name = alpha_category.category;
			}

			let code: ICD10 = {
				ICD10Code: x.code,
				Title: x.title ?? undefined,
				alphaCategory: alpha_category_name,
				Alpha: alpha_category,
				Range: undefined,
				rangeCategory: undefined,
				subrangeCategory: undefined,
				Description: x.description,
			};

			let range = range_category_map.get(x.range);
			if (range) {
				code.Range = range;
				// Add code to ParentRange entry in range_category_map_to_codes also
				if (range.parent_range) {
					let parent_range = range_category_map.get(range.parent_range);
					if (parent_range) {
						let parent_rangeCodes = range_category_map_to_codes.get(parent_range.range);
						if (parent_rangeCodes) {
							parent_rangeCodes.push(code);
						}
					}
				}
			}

			let rangeCodes = range_category_map_to_codes.get(x.range);
			if (rangeCodes) {
				rangeCodes.push(code);
			}

			return code;
		});

	let code_map = new Map();

	codes?.forEach((x: ICD10) => {
		if (x.ICD10Code) {
			code_map.set(x.ICD10Code, x);
		} else {
			console.error("missing icd code");
		}
	});

	let alphabetized_range_categories = api_ICD10package?.rangeCategory.sort((a, b) => {
		if (a.description && b.description) {
			return a.description > b.description ? 1 : -1; // will not be equal
		} else if (a.description || b.description) {
			return a.description ? 1 : -1;
		}
		return 0;
	});

	return {
		codes: codes ?? [],
		code_map,
		alpha_category_map,
		range_category_map,
		subrange_category_map,
		range_categories: alphabetized_range_categories ?? [],
		range_category_map_to_codes,
	};
};
