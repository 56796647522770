import { Button, SxProps } from "@mui/material";
import { palette } from "theme";

type SlimHollowButtonProps = React.PropsWithChildren<{
	onClick?: (x: any) => any;
	jscss?: React.CSSProperties;
	size?: "tiny";
	special_style?: "dark";
	type?: "submit";
	color?: string;
	solidColor?: boolean;
	fontSize?: string;
	padding?: string;
	disabled?: boolean;
}>;

const SlimHollowButton = ({ children, onClick, jscss, size, special_style, type, color, solidColor, fontSize, padding, disabled }: SlimHollowButtonProps) => {
	const style: SxProps = {
		borderStyle: "solid",
		borderWidth: "2px",
		borderColor: color ?? palette.core.electricblue,
		color: color ?? palette.core.electricblue,
		backgroundColor: "transparent",
		padding: padding ?? "8px 16px",
		fontSize: fontSize ?? "1rem",
		textTransform: "none",
		minHeight: "1.8rem",
		marginRight: "4px",
		marginLeft: "4px",
		borderRadius: "2rem",
		"&:hover": {
			backgroundColor: "transparent",
		},
	};

	if (solidColor) {
		style.backgroundColor = style.borderColor;
		style.color = palette.gray.light;
		style["&:hover"] = { opacity: 0.5, backgroundColor: style.backgroundColor } as CIM;
	}

	if (size === "tiny") {
		style.fontSize = ".8rem";
		style.minHeight = ".8rem";
		style.padding = "2px 8px";
	}

	if (special_style === "dark") {
		style.borderColor = palette.gray.darker;
		style.color = palette.gray.darker;
	}

	if (disabled === true) {
		style.opacity = 0.5;
		style.cursor = "default !important";
	}

	if (!jscss) {
		jscss = {};
	}
	Object.assign(style, jscss);
	return (
		<Button sx={style} onClick={disabled ? undefined : onClick}>
			{children}
		</Button>
	);
};

export { SlimHollowButton };
