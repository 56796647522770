import { Box, Typography } from "@mui/material";
import { palette } from "theme";
import { HealthIcon, HealthIconProps } from "../Icons/HealthIcon";

type IconLineAlertProps = {
	text?: string;
	onClick?: () => any;
	onClickText?: string;
	healthIconProps: HealthIconProps;
};

const IconLineAlert = ({ text, onClick, onClickText, healthIconProps }: IconLineAlertProps) => {
	return (
		<Box style={{ textAlign: "left", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
			<Box
				style={{
					flexGrow: 0,
					height: "32px",
					minHeight: "32px",
					maxHeight: "32px",
					width: "32px",
					minWidth: "32px",
					maxWidth: "32px",
					borderRadius: "50%",
					backgroundColor: "white",
					margin: "8px",
					borderStyle: "solid",
					borderColor: palette.gray.main,
					borderWidth: "2px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<HealthIcon {...healthIconProps} />
			</Box>
			<Typography style={{ padding: "0px 8px", display: "inline-block" }}>{text}</Typography>
			{onClick ? (
				<>
					&nbsp;-&nbsp;{" "}
					<Typography variant={"clickable-blue"} style={{ flexGrow: 1 }} onClick={onClick}>
						{onClickText ? onClickText : "Click to View"}
					</Typography>
				</>
			) : null}
		</Box>
	);
};

export { IconLineAlert };
