import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/material";
import AuthProvider from "account/auth/AuthProvider";
import AccountPublicRoutes from "account/router/AccountPublicRoutes";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { ExternalRouter, RouterMain } from "RouterMain";
import { QueryClient, QueryClientProvider } from "react-query";
import { MainContextProvider } from "common/context/MainContext";
import { AccountManagerContextProvider } from "common/managers/AccountManager";
import { StyleContextProvider } from "common/context/StyleContext";
import { GeolocationContextProvider } from "common/context/GeolocationContext";
import { LogIssueReport, WindowRadiusCare } from "common/types/globalModule";
import { clearJwtToken, setJwtToken } from "api_extended/client";
import { AuthState, getAuthSession } from "account/auth/context";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DeviceContextProvider } from "common/context/DeviceContext";

const queryClient = new QueryClient();
const windowRadiusCare: WindowRadiusCare = {
	queryClient: queryClient,
	log_issue(report: LogIssueReport) {
		console.info("LOG_ISSUE", report);
	},
};
window.RadiusCare = windowRadiusCare;

function onAuthStateChange(state: AuthState) {
	if (state.authenticated) {
		setJwtToken(async () => (await getAuthSession(state.user)).getIdToken().getJwtToken());
	} else {
		clearJwtToken();
	}
}

function App() {
	return (
		<Box>
			<ThemeProvider theme={theme}>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<SnackbarProvider autoHideDuration={2000}>
						<QueryClientProvider client={queryClient}>
							<BrowserRouter>
								<ExternalRouter>
									<AuthProvider onStateChange={onAuthStateChange} publicRoutes={<AccountPublicRoutes />}>
										<AccountManagerContextProvider>
											<GeolocationContextProvider>
												<MainContextProvider>
													<DeviceContextProvider>
														<StyleContextProvider>
															<RouterMain />
														</StyleContextProvider>
													</DeviceContextProvider>
												</MainContextProvider>
											</GeolocationContextProvider>
										</AccountManagerContextProvider>
									</AuthProvider>
								</ExternalRouter>
							</BrowserRouter>
						</QueryClientProvider>
					</SnackbarProvider>
				</LocalizationProvider>
			</ThemeProvider>
		</Box>
	);
}

export default App;
