/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerMobileOperationStatusCostOfCareResponseModelMobile,
  ConsumerCostOfCareRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const costOfCareMobile = (
    consumerCostOfCareRequest: ConsumerCostOfCareRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileOperationStatusCostOfCareResponseModelMobile>(
      {url: `/api/consumer/ConsumerMobile_CostOfCare/CostOfCareMobile`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerCostOfCareRequest
    },
      options);
    }
  


    export type CostOfCareMobileMutationResult = NonNullable<Awaited<ReturnType<typeof costOfCareMobile>>>
    export type CostOfCareMobileMutationBody = ConsumerCostOfCareRequest
    export type CostOfCareMobileMutationError = unknown

    export const useCostOfCareMobile = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof costOfCareMobile>>, TError,{data: ConsumerCostOfCareRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof costOfCareMobile>>, {data: ConsumerCostOfCareRequest}> = (props) => {
          const {data} = props ?? {};

          return  costOfCareMobile(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof costOfCareMobile>>, TError, {data: ConsumerCostOfCareRequest}, TContext>(mutationFn, mutationOptions)
    }
    