import { Box } from "@mui/material";
import UserLocationSettings from "account/pages/myProfile/UserDetails/UserLocationSettings";

import { SimpleModal } from "./SimpleModal";

const UserLocationSettingsModal = ({ open, onClose }: { open: boolean; onClose: () => any }) => {
	return (
		<SimpleModal
			open={open}
			onClose={async () => {
				// console.debug("closed modal");
				// set_modalOpen(false);
				onClose();
			}}
		>
			<Box>
				<UserLocationSettings allowUseCurrent={false} onSuccessCallback={onClose} />
			</Box>
		</SimpleModal>
	);
};

export { UserLocationSettingsModal };
