import { AppBar, Box, Container, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { style } from "theme";
import CodeVerificationForm from "../../forms/CodeVerificationForm";

type CodeVerificationPageProps = {
	username?: string;
	hideBackButton?: boolean;
};

function CodeVerificationPage({ username, hideBackButton }: CodeVerificationPageProps) {
	return (
		<>
			{" "}
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Verify Email</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("verify-email");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Container maxWidth={"xs"} sx={{ display: "flex", height: "100vh", alignItems: "center", justifyContent: "center" }}>
				<CodeVerificationForm username={username} hideBackButton={hideBackButton} />
			</Container>
		</>
	);
}
export default CodeVerificationPage;
