import { Button, Grid, Stack } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { useContext, useMemo } from "react";
import { AccountManagerContext } from "common/managers/AccountManager";
import { DialogManager } from "common/managers/DialogManager";

function GeneralInfo() {
	const accountContext = useContext(AccountManagerContext);

	const [consumerUser, mostRecentPatientDemographic] = useMemo(() => {
		let userDetails = accountContext.accountManager.userDetails;
		return [userDetails?.consumerUser, userDetails?.patientDemographics ? userDetails?.patientDemographics[0] : undefined];
	}, [accountContext.accountManager.userDetails]);

	const name = useMemo(() => {
		if (mostRecentPatientDemographic && mostRecentPatientDemographic.name) {
			return mostRecentPatientDemographic.name;
		}
		let name = consumerUser?.name;
		if (name) {
			return name;
		}
		return null;
	}, [consumerUser, mostRecentPatientDemographic]);

	return (
		<ContentSection title="General Info">
			{consumerUser ? (
				<Grid container spacing={2} sx={{ "& .MuiFormControl-root": { width: "100%" } }}>
					<Grid item xs={12} sm={4}>
						<StandardTextField
							data-styletype="infodisplay"
							label="Name"
							value={name ?? "-"}
							InputProps={{
								readOnly: true,
							}}
							disabled={!name}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StandardTextField
							data-styletype="infodisplay"
							label="Date of Birth"
							//value={birthDate ?? "-"}
							// Dan - matching changes made by Mark fixing birthday offset
							value={mostRecentPatientDemographic?.birth_date_string ?? consumerUser?.dateOfBirthString ?? "-"}
							InputProps={{
								readOnly: true,
							}}
							// disabled={!birthDate}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<StandardTextField
							data-styletype="infodisplay"
							label="Gender"
							value={consumerUser.gender ?? "-"}
							InputProps={{
								readOnly: true,
							}}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<StandardTextField
							data-styletype="infodisplay"
							label="Mobile Number"
							value={consumerUser?.contact?.phone ?? "-"}
							InputProps={{
								readOnly: true,
							}}
							disabled={!consumerUser?.contact?.phone}
							variant="standard"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<StandardTextField
							data-styletype="infodisplay"
							label="Email Address"
							value={consumerUser?.email ?? "-"}
							InputProps={{
								readOnly: true,
							}}
							disabled={!consumerUser?.email}
							variant="standard"
						/>
					</Grid>
				</Grid>
			) : null}

			<Stack sx={{ paddingTop: "1rem", flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end" }}>
				<Button href="/signout">Logout</Button>
				<Button
					sx={{ marginLeft: { xs: "0", sm: ".5rem" }, marginTop: { xs: ".5rem", sm: "0" } }}
					onClick={() => {
						DialogManager.open("update-info");
					}}
				>
					Update Info
				</Button>
				<Button
					sx={{ marginLeft: { xs: "0", sm: ".5rem" }, marginTop: { xs: ".5rem", sm: "0" } }}
					onClick={() => DialogManager.open("change-password")}
				>
					Change Password
				</Button>
			</Stack>
		</ContentSection>
	);
}

export { GeneralInfo };
