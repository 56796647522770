import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ModelsDoctorRecord, ModelsExplanationOfBenefitProviderAssessment } from "api";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import StandardLineChart from "common/components/Charts/StandardLineChart";
import { IconLineAlert } from "common/components/DisplayElements/IconLineAlert";
import { ListDisplay } from "common/components/DisplayElements/ListDisplay";
import { StarRating } from "common/components/DisplayElements/StarRating";
import { AvatarDisplay } from "common/components/Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { DoctorRecord_Enhanced, ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { DisplayInfo, Entity, entity_actions, entity_types } from "common/definitions/EntityDefinitions";
import { DialogManager } from "common/managers/DialogManager";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { X_addUserIgnoreItems } from "common/requests/patient_data/X_addUserIgnoreItems";
import { convertDatesToChartDataByMonth } from "common/utilities/chartDataConverters";
import { dateConverter } from "common/utilities/dateConverter";
import displayConverter from "common/utilities/displayConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { useQueryClient } from "react-query";
import { palette } from "theme";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { ReactNode } from "react";

const ProviderAssessmentDetailsSection = ({
	provAssessment,
}: {
	provAssessment: ModelsExplanationOfBenefitProviderAssessment;
	providerEntity?: ProviderEntity;
}) => {
	let chartData;
	if (provAssessment.dates) {
		chartData = convertDatesToChartDataByMonth(provAssessment.dates);
	}

	let displayInfo: Array<DisplayInfo> = [
		{ title: "Records", value: provAssessment.count ?? "?", colorType: undefined },
		// {
		// 	title: "Date of Last Recorded Visit",
		// 	value: assessedProvider.dates
		// 		? dateConverter["YYYY-MM-DD_to_mM/dD/YY"](assessedProvider.dates[assessedProvider.dates.length - 1])
		// 		: "Unknown",
		// 	colorType: undefined,
		// },
		{
			title: "Est Total Payments",
			value: displayConverter.dataTypeConvert("USD", provAssessment.paymentAmountTotal ?? "?"),
			colorType: "USD",
		},
	];

	return (
		<Box>
			<Box sx={{ paddingY: "1rem" }}>
				{chartData ? (
					<StandardLineChart
						data={chartData}
						xKey={"yyyymm"}
						yKeys={[{ dataKey: "count" }]}
						responsiveHeight={100}
						keyLabels={{ count: "Encounters" }}
						xTickLine
						xTickFormatter={(x, y) => {
							return dateConverter["YYYY-MM_to_mM/YY"](x);
						}}
						yDomain={[0, "dataMax" as CIM]}
						dotSize={3}
					/>
				) : null}
			</Box>
			<Grid container spacing={2}>
				{displayInfo.map((di, i) => {
					return (
						<Grid key={i} item xs={12} sm={6}>
							<Box
								key={i}
								style={{
									border: `solid 2px ${palette.gray.main}`,
									backgroundColor: palette.core.lightBackground,

									padding: "1rem",
									borderRadius: ".5rem",
									textAlign: "center",
								}}
							>
								<Typography
									sx={{
										fontSize: { xs: "1.25rem", sm: "1.75rem" },
										color: di.colorType ? styleConverter.colorTypeAssign(di.colorType, di.value) : palette.gray.darker,
										fontWeight: "500",
										textAlign: "center",
									}}
								>
									{di.dataType ? displayConverter.dataTypeConvert(di.dataType, di.value) : di.value}
								</Typography>
								<Typography sx={{ color: palette.gray.dark, fontWeight: 500, textAlign: "center" }}>{di.title}</Typography>
							</Box>
						</Grid>
					);
				})}
				<Grid item xs={12} sm={6}>
					<ListDisplay
						title="This provider diagnosed you with"
						items={Object.keys(provAssessment.diagnosisCodes).map((k, i) => {
							let x = provAssessment.diagnosisCodes[k];

							let icd10 = StaticDataManager.retrieveValue.icd10cm(k);
							if (icd10) {
								return (
									<Box
										key={i}
										sx={{
											color: palette.core.blue,
											cursor: "pointer",
										}}
										onClick={() => {
											DialogManager.open("diagnosis-details", icd10?.ICD10Code);
										}}
									>
										{icd10.Description}
									</Box>
								);
							}
							return <span>{x.id}</span>;
						})}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Box sx={{ borderLeft: { xs: "none", sm: `2px solid ${palette.gray.dark}` }, paddingLeft: { xs: "0", sm: "1rem" }, height: "100%" }}>
						<ListDisplay
							title="Treatments by this provider"
							items={Object.keys(provAssessment.productOrServiceCodes).map((k, i) => {
								let x = provAssessment.productOrServiceCodes[k];
								const medication = DynamicDataManager.ndcs.drugData_NDCMap.get(x.id);
								console.log("MED", medication);
								let hcpcs = StaticDataManager.retrieveValue.hcpcs(k);
								if (medication) {
									return (
										<Box
											key={i}
											sx={{
												color: palette.core.blue,
												cursor: "pointer",
											}}
											onClick={() => {
												DialogManager.open("medication-details", medication.ndc); // do we need procedure-details screen?
											}}
										>
											{medication.name}
										</Box>
									);
								} else if (hcpcs) {
									return (
										<Box
											key={i}
											// sx={{
											// 	color: palette.core.blue,
											// 	cursor: "pointer",
											// }}
											// onClick={() => {
											// 	DialogManager.open("medication-details", hcpcs?.code); // do we need procedure-details screen?
											// }}
										>
											{hcpcs.description}
										</Box>
									);
								}
								return <span>{x.id}</span>;
							})}
						/>
					</Box>
				</Grid>
				<Grid item xs={12} sx={{ display: "none" }}>
					<IconLineAlert
						text="Higher rated providers are available to you in your area"
						onClick={() => {
							DialogManager.open("suggest-similar-providers", provAssessment.providerNpi);
						}}
						healthIconProps={{ iconSVG: health_icons_svg.faBellOn, size: 18, color: palette.core.preventative }}
					/>
					<IconLineAlert
						text="A Procedure performed at this visit is less expensive at other locations"
						healthIconProps={{ iconSVG: health_icons_svg.dollarsign, color: palette.core.green }}
					/>
					<IconLineAlert
						text="Higher rated providers are available to you in your area"
						onClick={() => {
							DialogManager.open("suggest-similar-providers", provAssessment.providerNpi);
						}}
						healthIconProps={{ iconSVG: health_icons_svg.outpatient, color: palette.core.purple }}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export { ProviderAssessmentDetailsSection };

const ProviderOptionsActions = ({ providerEntity, providerCareAlertsIgnored }: { providerEntity: ProviderEntity; providerCareAlertsIgnored?: boolean }) => {
	const queryClient = useQueryClient();
	if (providerEntity._providerRecord?.radiusRating && providerEntity._providerRecord?.radiusRating < 4 && providerCareAlertsIgnored !== true) {
		return (
			<Button
				color="error"
				onClick={() => {
					DialogManager.open("care-alert", providerEntity?._providerRecord?.npi ?? undefined);
				}}
			>
				Care Alert
			</Button>
		);
	}
	return (
		<OptionsButton
			content={
				<Box>
					<Button
						variant="text"
						onClick={() => {
							entity_actions.view_details.action(providerEntity);
						}}
					>
						View Profile
					</Button>
					<Button
						variant="text"
						onClick={() => {
							entity_actions.view_details.action(providerEntity);
						}}
					>
						Call Office
					</Button>
					<Button
						variant="text"
						onClick={() => {
							entity_actions.suggest_similar.action(providerEntity);
						}}
					>
						Suggest Similar
					</Button>
					{providerCareAlertsIgnored === true && providerEntity?._providerRecord?.npi ? (
						<Button
							variant="text"
							onClick={async () => {
								await X_addUserIgnoreItems(
									{
										careAlertNPIs: [providerEntity?._providerRecord?.npi as string],
										removeItems: true,
									},
									queryClient
								);
							}}
						>
							Re-Enable Care Alert
						</Button>
					) : null}
					<Button variant="text" disabled>
						Pay Copay
					</Button>
					<Button variant="text" disabled>
						Make Appointment
					</Button>
				</Box>
			}
		/>
	);
};

export { ProviderOptionsActions };

function HealthIconForDoctorRecord(provider: DoctorRecord_Enhanced | ModelsDoctorRecord | undefined): ReactNode {
	let entityType = entity_types.provider;

	if (provider) {
		if (provider.entityTypeCode === "1") {
			return (
				<HealthIcon
					color={entityType.iconColor}
					size={48}
					iconSVG={provider.genderCode === "M" ? health_icons_svg.doctormale : health_icons_svg.doctorfemale}
				/>
			);
		} else if (provider.entityTypeCode === "2") {
			entityType = entity_types.facility;
			return <HealthIcon color={entityType.iconColor} size={48} iconSVG={entityType.iconSVG} />;
		}
	}
	return <HealthIcon color={entityType.iconColor} size={48} iconSVG={entityType.iconSVG} />;
}

export { HealthIconForDoctorRecord };

/*
██████  ██████   ██████  ██    ██ ██ ██████  ███████ ██████       █████  ██    ██  █████  ████████  █████  ██████      ██████  ██ ███████ ██████  ██       █████  ██    ██ 
██   ██ ██   ██ ██    ██ ██    ██ ██ ██   ██ ██      ██   ██     ██   ██ ██    ██ ██   ██    ██    ██   ██ ██   ██     ██   ██ ██ ██      ██   ██ ██      ██   ██  ██  ██  
██████  ██████  ██    ██ ██    ██ ██ ██   ██ █████   ██████      ███████ ██    ██ ███████    ██    ███████ ██████      ██   ██ ██ ███████ ██████  ██      ███████   ████   
██      ██   ██ ██    ██  ██  ██  ██ ██   ██ ██      ██   ██     ██   ██  ██  ██  ██   ██    ██    ██   ██ ██   ██     ██   ██ ██      ██ ██      ██      ██   ██    ██    
██      ██   ██  ██████    ████   ██ ██████  ███████ ██   ██     ██   ██   ████   ██   ██    ██    ██   ██ ██   ██     ██████  ██ ███████ ██      ███████ ██   ██    ██  
*/

const ProviderAvatarDisplay = ({ providerEntity, entity }: { providerEntity?: ProviderEntity; entity?: Entity }) => {
	if (!providerEntity) {
		if (!entity) {
			return null;
		}
		providerEntity = entity as ProviderEntity;
	}

	let providerRecord = providerEntity._providerRecord;
	return (
		<Stack sx={{ flexDirection: "row", alignItems: "center" }}>
			<AvatarDisplay
				imageAlt={providerEntity.title}
				imageSrc={providerRecord?.imageUrl ?? ""}
				icon={
					HealthIconForDoctorRecord(providerRecord)
					// <HealthIcon
					// 	color={provEntityType.iconColor ?? palette.core.electricblue}
					// 	size={48}
					// 	iconSVG={providerRecord?.genderCode === "M" ? health_icons_svg.doctormale : health_icons_svg.doctorfemale}
					// />
				}
				inNetwork={!!providerEntity._providerRecord?.inNetwork}
			/>
			{/* ENTITY DETAILS */}
			<Box sx={{ paddingLeft: ".5rem" }}>
				{!!providerEntity._providerRecord?.inNetwork ? (
					<Stack flexDirection={"row"}>
						<Box sx={{ color: palette.core.forestgreen }} component="img" src={"../images/datasource/IconInNetwork.svg"} alt="In Network Icon" />
						<Typography sx={{ color: palette.core.forestgreen, pl: 0.5 }}>In Network</Typography>
					</Stack>
				) : null}
				{providerRecord?.taxonomies.length ? (
					<Box>
						{providerRecord.taxonomies.map((taxonomyDefinition, i) => {
							if (i === 0) {
								return (
									<Typography sx={{ color: palette.gray.dark, fontWeight: 500 }} key={taxonomyDefinition.code}>
										{taxonomyDefinition.displayName}
									</Typography>
								);
							}
							return null;
						})}
					</Box>
				) : null}
				<Typography
					component="div"
					sx={{
						fontSize: "1.25rem",
						color: !!providerEntity._providerRecord?.inNetwork ? palette.core.forestgreen : palette.core.blue,
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}
				>
					<Box
						sx={{
							cursor: "pointer",
						}}
						onClick={() => {
							console.log("click", providerEntity);
							entity_actions.view_details.action(providerEntity);
						}}
					>
						{providerEntity.title}
					</Box>
				</Typography>
				<StarRating rating={providerEntity.radiusRating} adjustValueForStarFit />
			</Box>
		</Stack>
	);
};

export { ProviderAvatarDisplay };
