import { Box } from "@mui/material";
import { useQ_medicationPharmacies } from "details/medicationDetails/requests/useQ_medicationPharmacies";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useContext, useEffect, useMemo, useRef } from "react";
import { MedicationDetailDataPackage, SingleCareMedicationDetail } from "details/medicationDetails/requests/useQ_getDrugStructureData";
import { StyleContext } from "common/context/StyleContext";
import { GeolocationContext } from "common/context/GeolocationContext";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { SlimHollowButton } from "common/components/Buttons/SlimHollowButton";

import { ModelsRxSenseTieredPricingPharmacyPricing } from "api";
// eslint-disable-next-line react/jsx-pascal-case
import { MDP_PharmaciesFilters as MDPPharmaciesFilters } from "./MDP_PharmaciesFilters";
import { MedicationPharmacyTile } from "common/components/Tiles/MedicationPharmacyTile";

type MDP_PharmaciesProps = {
	drugStructureData: MedicationDetailDataPackage;
	medicationDetailItem: SingleCareMedicationDetail | null;
	set_medicationDetailItem: React.Dispatch<React.SetStateAction<null | SingleCareMedicationDetail>>;
};

const MDP_Pharmacies = ({ drugStructureData, medicationDetailItem, set_medicationDetailItem }: MDP_PharmaciesProps) => {
	//GEOLOCATION
	const geo = useContext(GeolocationContext);
	// const [zipcode, set_zipcode] = useState<string>(geo.geolocationManager.clientLocation().zipcode ?? "");
	// const [zipcodeDisplayValue, set_zipcodeDisplayValue] = useState<string>(zipcode);
	useEffect(() => {
		geo.geolocationManager.getPosition(); // triggers retrieve of geolocation values by the context/manager
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [geo.position]);

	/////
	const pharmacyPricingResponse = useQ_medicationPharmacies({
		medicationDetailItem,
		clientLocation: geo.geolocationManager.clientLocation({ useAccountPrefs: true }),
	});

	const styleContext = useContext(StyleContext);

	const parentRef = useRef<HTMLElement>();

	const narrow = useMemo(() => {
		if (parentRef.current) {
			if (parentRef.current.scrollWidth < 800) {
				return true;
			}
		}
		return false;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [styleContext.lastTriggered, parentRef.current]);

	/*
██████  ███████ ███    ██ ██████  ███████ ██████      ██ ██████  ███████ ████████ ██    ██ ██████  ███    ██ 
██   ██ ██      ████   ██ ██   ██ ██      ██   ██    ██  ██   ██ ██         ██    ██    ██ ██   ██ ████   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████    ██   ██████  █████      ██    ██    ██ ██████  ██ ██  ██ 
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██  ██    ██   ██ ██         ██    ██    ██ ██   ██ ██  ██ ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ ██     ██   ██ ███████    ██     ██████  ██   ██ ██   ████ 
                                                                                                             
*/

	return (
		<Box ref={parentRef}>
			<Box>
				{!geo.geolocationManager.clientLocation().zipcode &&
				!(geo.position && geo.position.coords && geo.position.coords.latitude && geo.position.coords.longitude) ? (
					<SlimHollowButton
						onClick={() => {
							GeolocationManager.getPosition({ type: "manual" });
						}}
					>
						Enable Location Permissions
					</SlimHollowButton>
				) : null}
				{/* <Box sx={{ paddingBottom: "4px", display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
					<Typography variant="tiny-title">{zipcode ? `Searching by Zip Code ${zipcode}` : "Searching by your location"}</Typography>
					<Typography component="div" variant="form-label">
						ZIP Code
					</Typography>
					<StandardTextField
						sx={{ "& input": { color: zipcode ? palette.core.electricblue : undefined } }}
						placeholder="5 digit zip code"
						value={zipcodeDisplayValue}
						onChange={(x: React.ChangeEvent<HTMLInputElement>) => {
							let value = displayConverter.filterNonNumeric(x.target.value);
							if (value.length <= 5) {
								set_zipcodeDisplayValue(value);
								if (value.length === 5) {
									set_zipcode(value);
								} else {
									set_zipcode("");
								}
							}
						}}
					/>
				</Box> */}
			</Box>
			{/* 
			// eslint-disable-next-line react/jsx-pascal-case */}
			<MDPPharmaciesFilters
				narrow={narrow}
				drugStructureData={drugStructureData}
				medicationDetailItem={medicationDetailItem}
				set_medicationDetailItem={set_medicationDetailItem}
			/>

			<QueryStatusDisplay queryResult={pharmacyPricingResponse}>
				{/* {pharmacyPricingResponse.data?.data?.ndc} */}
				{pharmacyPricingResponse.data && Array.isArray(pharmacyPricingResponse.data?.data?.pharmacyPricings)
					? pharmacyPricingResponse.data.data?.pharmacyPricings.map((m: ModelsRxSenseTieredPricingPharmacyPricing, i: number) => {
							return (
								<Box key={i} sx={{ paddingBottom: ".5rem" }}>
									<MedicationPharmacyTile key={i} RxSensePharmacyPricing={m} />
								</Box>
							);
					  })
					: "No Results"}
			</QueryStatusDisplay>
		</Box>
	);
};

export { MDP_Pharmacies };
