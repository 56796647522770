import React from "react";
import { IMaskInput } from "react-imask";

interface MaskedInputProps {
	name: string;
	onChange: (event: { target: { name: string; value: string } }) => void;
}

export const PhoneMaskedInput = React.forwardRef<HTMLElement, MaskedInputProps>(function MaskedInput(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			mask="{+1} (000) 000-0000"
			placeholderChar="#"
			overwrite
			{...other}
			inputRef={ref}
			onAccept={(value: any) => {
				onChange({ target: { name: props.name, value } });
			}}
		/>
	);
});

export const EmailMaskedInput = React.forwardRef<HTMLElement, MaskedInputProps>(function MaskedInput(props, ref) {
	const { onChange, ...other } = props;
	return (
		<IMaskInput
			mask={/^\S*@?\S*$/}
			placeholderChar="#"
			overwrite
			{...other}
			inputRef={ref}
			onAccept={(value: any) => {
				onChange({ target: { name: props.name, value } });
			}}
		/>
	);
});
