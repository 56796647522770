import { LineChart, Line, Tooltip, XAxis, YAxis, ResponsiveContainer, Legend } from "recharts";
import { Box, LinearProgress } from "@mui/material";
import { palette } from "../../../theme";
import * as CSS from "csstype";
import { HorizontalAlignmentType, VerticalAlignmentType } from "recharts/types/component/DefaultLegendContent";
import { useContext } from "react";
import { StyleContext } from "common/context/StyleContext";
import displayConverter, { DataDisplayType } from "common/utilities/displayConverter";

const ChartTooltipLabel = {
	display: "none",
};

interface StandardLineChartProps {
	data: Array<{ [key: string]: any }>;
	xKey: string;
	yKeys: Array<{ dataKey: string; color?: CSS.Property.Color }>;
	legend?: boolean;
	legendAlign?: HorizontalAlignmentType;
	legendVerticalAlign?: VerticalAlignmentType;
	displayYAxis?: boolean;
	yDomain?: Array<number>;
	responsiveHeight?: number;
	lineType?: "monotone" | "linear";
	keyLabels?: { [key: string]: string };
	keyFormats?: { [key: string]: DataDisplayType };
	xTickLine?: boolean;
	yTickLine?: boolean;
	xTickFormatter?: ((value: any, index: number) => string) | undefined; // xTickFormatter -- function to display the value below the X-Axis tick line - return the string to display
	xAxisLabelStyle?: React.CSSProperties | any;
	dotSize?: number;
}

const StandardLineChart = (props: StandardLineChartProps) => {
	const { lastTriggered } = useContext(StyleContext);
	return (
		<>
			{props.xAxisLabelStyle ? (
				<Box
					style={{ width: 0 }}
					sx={{
						"& + div.recharts-responsive-container svg.recharts-surface g.xAxis g.recharts-cartesian-axis-tick text.recharts-text.recharts-cartesian-axis-tick-value":
							props.xAxisLabelStyle,
					}}
				/>
			) : null}
			<ResponsiveContainer key={lastTriggered} height={props.responsiveHeight} width="100%">
				{props.data.length > 0 ? (
					<LineChart data={props.data}>
						{props.legend ? (
							<Legend
								verticalAlign={props.legendVerticalAlign}
								align={props.legendAlign}
								iconType="circle"
								formatter={(key) => {
									return (
										<span style={{ color: palette.gray.darker, textTransform: "capitalize" }}>
											{props.keyLabels && props.keyLabels[key] ? props.keyLabels[key] : key}
										</span>
									);
								}}
							/>
						) : null}
						{props.yKeys.map((yk, x) => {
							return (
								<Line
									type={props.lineType ?? "monotone"}
									key={x}
									dataKey={yk.dataKey}
									stroke={yk.color ?? palette.core.forestgreen}
									dot={<CustomizedDot color={yk.color ?? palette.core.forestgreen} dotSize={props.dotSize} data={{ x, yk }} />}
								/>
							);
						})}
						<XAxis
							dataKey={props.xKey}
							interval={"preserveStartEnd"}
							tickLine={!!props.xTickLine}
							tickFormatter={props.xTickFormatter}
							// tick={<CustomizedAxisTick />}
						/>
						<YAxis hide={!props.displayYAxis && !props.yTickLine} domain={props.yDomain} tickLine={props.yTickLine} allowDecimals={false} />

						<Tooltip
							labelStyle={ChartTooltipLabel}
							formatter={(value: string | number, key: string) => {
								return [
									props.keyFormats && props.keyFormats[key] ? displayConverter.dataTypeConvert(props.keyFormats[key], value) : value,
									props.keyLabels && props.keyLabels[key] ? props.keyLabels[key] : key,
								];
							}}
						/>
					</LineChart>
				) : (
					<LinearProgress color={"inherit"} />
				)}
			</ResponsiveContainer>
		</>
	);
};

// Saving for potential later use
// class CustomizedAxisTick extends PureComponent {
// 	render() {
// 		const { x, y, stroke, payload } = this.props as any;

// 		return (
// 			<g transform={`translate(${x},${y})`}>
// 				<g transform={`translate(-4,14)`}>
// 					<text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(22.5)">
// 						{payload.value}
// 					</text>
// 				</g>
// 			</g>
// 		);
// 	}
// }

const CustomizedDot = (props: { color?: string; cx?: number; cy?: number; dotSize?: number | null; data?: any }) => {
	let { color, cx, cy, dotSize } = props;
	let radius = dotSize !== undefined ? dotSize : 4;
	if (radius === null) {
		return null;
	}
	let displayValue: number | null = null;
	if ((props as CIM)?.payload && (props as CIM).dataKey) {
		displayValue = (props as CIM)?.payload[(props as CIM).dataKey];
	}
	return (
		<>
			<svg x={(cx as number) - radius} y={(cy as number) - radius} width={radius * 2} height={radius * 2} fill={color} viewBox="0 0 100 100">
				<circle cx="48" cy="48" r="48" />
			</svg>
			{displayValue && displayValue > 0 ? (
				<svg x={(cx as number) - radius} y={cy as number} width={100} height={150} fill={color} stroke="solid" viewBox="0 0 150 150">
					<text x={0} y={-20} width={100} height={110}>
						{displayValue}
					</text>
				</svg>
			) : null}
		</>
	);
};

export default StandardLineChart;
