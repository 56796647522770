import { Button } from "@mui/material";

type FormSubmitButtonProps = React.PropsWithChildren<{
	onClick?: (x: any) => any;
	jscss?: React.CSSProperties;
	size?: "tiny";
	disabled?: boolean;
	noSubmit?: boolean;
}>;

const FormSubmitButton = ({ children, onClick, disabled, noSubmit }: FormSubmitButtonProps) => {
	return (
		<Button onClick={onClick} disabled={disabled} type={noSubmit !== true ? "submit" : undefined}>
			{children}
		</Button>
	);
};

export { FormSubmitButton };
