import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FullscreenLoader } from "common/components/Loaders/FullscreenLoader";

class SpecialInstructions {
  target?: string;
  force?: () => any;
  intializedAt?: number;

  redirect = (target: string) => {
    this.target = target;
    if (this.force) {
      this.force();
    }
  };
  constructor() {
    if (!(SpecialInstructions as any).instance) {
      this.intializedAt = Date.now();
      (SpecialInstructions as any).instance = this;
    }
    return (SpecialInstructions as any).instance;
  }
}
const special_instructions = new SpecialInstructions();
export { special_instructions };

const Redirecter = ({ force }: { force: () => any }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (special_instructions.target) {
      let t = special_instructions.target;
      special_instructions.target = undefined;
      navigate(t, { replace: true });
      force();
    }
  });
  return <FullscreenLoader />;
};
export { Redirecter };
