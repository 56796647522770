import { Box, Button, Card, CardContent, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import Container from "@mui/material/Container";
import AccountPageWrapper from "account/components/AccountPageWrapper";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { useNavigate } from "react-router-dom";
import { palette } from "theme";

function AccountActive({ useAccountPageWrapper }: { useAccountPageWrapper: boolean }) {
	const navigate = useNavigate();

	const partialAccountActive = (
		<Container maxWidth="sm" sx={{ py: 14 }}>
			<Card elevation={4}>
				<CardContent>
					<Box sx={{ p: 1 }}>
						<Typography variant="h1" color={palette.core.darkblue}>
							Your Radius Account is Now Active!
						</Typography>
						<Typography pt={2}>
							However, your health insurance provider is not supplying your health details at this time, please contact your Benefits Manager to
							learn how you can connect to Radius Care.
						</Typography>
						<Typography fontWeight={"medium"} py={2}>
							In the meantime, here's what you can access:
						</Typography>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										width: "100%",
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.lavender} size={48} iconSVG={health_icons_svg.doctorfemale} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center" }}>
											Our one-of-a-kind doctor finder best care by provider type.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/dashboard/myoverview");
										}}
										sx={{ minWidth: "15rem" }}
									>
										Find a Provider
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										width: "100%",
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.preventative} size={48} iconSVG={health_icons_svg.stethoscope} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center", width: "100%" }}>
											Trusted facts and detailed information on conditions and medication.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/healthsnapshot/visits");
										}}
										sx={{ minWidth: "15rem" }}
									>
										Research Conditions
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										width: "100%",
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.green} size={48} iconSVG={health_icons_svg.pill3} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center" }}>
											Find the lowest price prescriptions within your radius.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/findcare/providers");
										}}
										sx={{ minWidth: "15rem" }}
									>
										Look Up Medications
									</Button>
								</Stack>
							</Grid>
							<Grid item xs={12} md={6} display={"flex"}>
								<Stack
									sx={{
										backgroundColor: palette.gray.light,
										borderRadius: "1rem",
										p: 2,
										width: "100%",
									}}
									alignItems={"center"}
									justifyContent={"space-between"}
								>
									<Box>
										<Box>
											<HealthIcon color={palette.core.blue} size={48} iconSVG={health_icons_svg.trophy} />
										</Box>

										<Typography sx={{ color: palette.gray.darker, py: 1, textAlign: "center" }}>
											Increase your health achievements - learn what screenings and test may be due.
										</Typography>
									</Box>
									<Button
										onClick={() => {
											navigate("/findcare/providers");
										}}
										sx={{ minWidth: "15rem" }}
									>
										Activate Achievements
									</Button>
								</Stack>
							</Grid>
						</Grid>

						<Typography fontWeight={"medium"} pt={2}>
							Once your insurance company is available to connect, you will be able to enjoy all the features that Radius Care provides including:
						</Typography>
						<Typography component="div" textAlign={"start"} color={"black"}>
							<List
								sx={{
									listStyleType: "disc",
									pl: 4,
									"& .MuiListItem-root": {
										display: "list-item",
									},
								}}
							>
								<ListItem disablePadding>Your free health score</ListItem>
								<ListItem disablePadding>Your doctors, conditions, treatments, medications and new diagnoses all in one place</ListItem>
								<ListItem disablePadding>Auto-reminders about upcoming appointments and suggested screenings</ListItem>
								<ListItem disablePadding>Your Total Cost of Care</ListItem>
							</List>
						</Typography>
						<Typography variant="h3" pt={2} textAlign={"center"}>
							Don't worry, we will notify you, as soon as your insurance provider supplies your data.
						</Typography>
						<Box sx={{ display: "flex", justifyContent: "center" }}>
							<Button
								sx={{ minWidth: "15rem" }}
								onClick={() => {
									navigate("/findcare/providers");
								}}
							>
								Go to Dashboard
							</Button>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Container>
	);

	if (useAccountPageWrapper) {
		return <AccountPageWrapper>{partialAccountActive}</AccountPageWrapper>;
	}
	return partialAccountActive;
	// return (
	// 	<AccountPageWrapper>
	// 		<Container maxWidth="sm" sx={{ mt: 6 }}>
	// 			<Card elevation={4}>
	// 				<CardContent>
	// 					<Box sx={{ p: 1 }}>
	// 						<Typography variant="h1" color={palette.core.darkblue}>
	// 							Your Radius Account is Now Active!
	// 						</Typography>
	// 						<Typography pt={2}>
	// 							However, your health insurance provider is not supplying your health details at this time, please contact your Benefits Manager
	// 							to learn how you can connect to Radius Care.
	// 						</Typography>
	// 						<Typography fontWeight={"medium"} pt={2}>
	// 							In the meantime, here's what you can access:
	// 						</Typography>
	// 						<Box
	// 							sx={{
	// 								backgroundColor: palette.gray.light,
	// 								borderRadius: "1rem",
	// 								display: "flex",
	// 								alignItems: "center",
	// 								p: 2,
	// 								mt: 2,
	// 							}}
	// 						>
	// 							<Box>
	// 								<HealthIcon color={palette.core.lavender} size={48} iconSVG={health_icons_svg.doctorfemale} />
	// 							</Box>

	// 							<Typography sx={{ color: palette.gray.darker, flex: 1, pl: 1 }}>
	// 								Our One-of-a-Kind Doctor Finder Best Care By Provider Type
	// 							</Typography>
	// 						</Box>
	// 						<Box
	// 							sx={{
	// 								backgroundColor: palette.gray.light,
	// 								borderRadius: "1rem",
	// 								display: "flex",
	// 								alignItems: "center",
	// 								p: 2,
	// 								mt: 1,
	// 							}}
	// 						>
	// 							<Box>
	// 								<HealthIcon color={palette.core.preventative} size={48} iconSVG={health_icons_svg.stethoscope} />
	// 							</Box>

	// 							<Typography sx={{ color: palette.gray.darker, flex: 1, pl: 1 }}>
	// 								Trusted Facts and detailed information on Conditions and Medication
	// 							</Typography>
	// 						</Box>
	// 						<Box
	// 							sx={{
	// 								backgroundColor: palette.gray.light,
	// 								borderRadius: "1rem",
	// 								display: "flex",
	// 								alignItems: "center",
	// 								p: 2,
	// 								mt: 1,
	// 							}}
	// 						>
	// 							<Box>
	// 								<HealthIcon color={palette.core.green} size={48} iconSVG={health_icons_svg.pill3} />
	// 							</Box>

	// 							<Typography sx={{ color: palette.gray.darker, flex: 1, pl: 1 }}>
	// 								Find the lowest price prescriptions within your radius
	// 							</Typography>
	// 						</Box>
	// 						<Typography fontWeight={"medium"} pt={2}>
	// 							Once your insurance company is available to connect, you will be able to enjoy all the features that Radius Care provides
	// 							including:
	// 						</Typography>
	// 						<Typography component="div" textAlign={"start"} color={"black"}>
	// 							<List
	// 								sx={{
	// 									listStyleType: "disc",
	// 									pl: 4,
	// 									"& .MuiListItem-root": {
	// 										display: "list-item",
	// 									},
	// 								}}
	// 							>
	// 								<ListItem disablePadding>Your free health score</ListItem>
	// 								<ListItem disablePadding>Your doctors, conditions, treatments, medications and new diagnoses all in one place</ListItem>
	// 								<ListItem disablePadding>Auto-reminders about upcoming appointments and suggested screenings</ListItem>
	// 								<ListItem disablePadding>Your Total Cost of Care</ListItem>
	// 							</List>
	// 						</Typography>
	// 						<Typography variant="h3" pt={2} textAlign={"center"}>
	// 							Don't worry, we will notify you, as soon as your insurance provider supplies your data.
	// 						</Typography>
	// 						<Box sx={{ display: "flex", justifyContent: "center" }}>
	// 							<Button
	// 								sx={{ padding: 1.5 }}
	// 								onClick={() => {
	// 									navigate("/findcare/providers");
	// 								}}
	// 							>
	// 								Find Care
	// 							</Button>
	// 						</Box>
	// 					</Box>
	// 				</CardContent>
	// 			</Card>
	// 		</Container>
	// 	</AccountPageWrapper>
	// );
}

export { AccountActive };
