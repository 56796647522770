import { Box } from "@mui/material";
import { palette } from "theme";

const RadiusSquareLogo = () => {
	return (
		<Box
			component={"img"}
			alt="radius care logo"
			src={"/images/radius-care-logo.svg"}
			sx={{
				height: "2.5rem",
				minHeight: "2.5rem",
				maxHeight: "2.5rem",
				width: "2.5rem",
				minWidth: "2.5rem",
				maxWidth: "2.5rem",
				objectFit: "cover",
				objectPosition: "0%",
				overflow: "hidden",
				backgroundColor: palette.core.lightBackground,
				borderRadius: "8px",
				padding: "8px",
				flexGrow: 0,
				flexShrink: 0,
				// [theme.breakpoints.down("sm")]: {
				// 	height: "2.5rem",
				// },
				// [theme.breakpoints.up("sm")]: {
				// 	height: "3.5rem",
				// },
			}}
		></Box>
	);
};

export { RadiusSquareLogo };
