import { FindCareConditions } from "findCare/pages/conditions/FindCareConditions";
import { FindCareMedication } from "findCare/pages/medication/FindCareMedication";
import { FindCareProviders } from "findCare/pages/providers/FindCareProviders";
import { Routes, Route, Navigate } from "react-router";

function FindCareRoutes() {
  return (
    <Routes>
      <Route path={"providers"} element={<FindCareProviders />} />
      <Route path={"medication"} element={<FindCareMedication />} />
      <Route path={"conditions"} element={<FindCareConditions />} />
      <Route path={"*"} element={<Navigate to="providers" />} />
    </Routes>
  );
}

export { FindCareRoutes };
