import { Box, Stack } from "@mui/material";
import { palette } from "theme";
import { ReactNode } from "react";

type FormContainerProps = React.PropsWithChildren<{
	topContent?: ReactNode;
	bottomContent?: ReactNode;
	noTopAndBottom?: boolean;
}>;

function FormContainer(props: FormContainerProps) {
	return (
		<Stack
			justifyContent={"center"}
			alignItems={"center"}
			sx={{
				position: "relative",
				maxWidth: "600px",
				width: "90%",
				// mt: "80px",
				margin: "120px 0px",
			}}
		>
			{props.noTopAndBottom ? null : (
				<Stack
					justifyContent={"flex-end"}
					alignItems={"center"}
					sx={{
						position: "absolute",
						backgroundColor: `${palette.core.lightblue}`,
						top: 0,
						bottom: 0,
						height: "100%",
						width: "calc(100% - 4rem)",
						zIndex: 1,
						borderRadius: "2rem",
						boxShadow: 4,
					}}
				>
					<Stack
						justifyContent={"center"}
						sx={{
							width: "100%",
							textAlign: "left",
							p: 2,
							height: "6rem",
							boxSizing: "border-box",
						}}
					>
						{props.bottomContent}
					</Stack>
				</Stack>
			)}

			<Box
				sx={{
					zIndex: 2,
					width: "100%",
					marginBottom: "6rem",
				}}
			>
				{props.children}
			</Box>
		</Stack>
	);
}

export default FormContainer;
