import { Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { HealthSnapshotProvidersPanel } from "./HealthSnapshotProvidersPanel";
import { HealthSnapshotProvidersKeyFindings } from "./HealthSnapshotProvidersKeyFindings";
import { EventDescription } from "common/definitions/EventDefinitions";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { ReactNode } from "react";

type HealthSnapshotProvidersProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
	keyFindings: EventDescription[];
	healthPlanReauthTag: ReactNode;
};

const HealthSnapshotProviders = ({ patientEOBData, keyFindings, healthPlanReauthTag }: HealthSnapshotProvidersProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<ContentSection title="Care Team" titleBarContent={healthPlanReauthTag}>
					<HealthSnapshotProvidersPanel patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
			<Grid item xs={12} md={4}>
				<ContentSection title="Key Findings">
					<HealthSnapshotProvidersKeyFindings keyFindings={keyFindings} patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
		</Grid>
	);
};
export { HealthSnapshotProviders };
