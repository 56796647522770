import { Box, Grid } from "@mui/material";
import { GetDoctorsByLocationParams, ConsumerDoctorGetDoctorsByZipCodesResponseModel, ConsumerDoctorGetDoctorsByNPIsResponseModel } from "api";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardOption } from "common/types/generic";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { Throttle } from "common/utilities/throttle";
import { FindCareMenu } from "findCare/FindCareMenu";
import {
	DoctorsByLocationData,
	GetDoctorsByLocationParamsX,
	Process_DoctorsZipCoordinateResult,
	useQ_retrieveProvidersByLocation,
} from "findCare/requests/useQ_retrieveProvidersByLocation";
import { retrieveProvidersByZipCodes } from "findCare/requests/useQ_retrieveProvidersByZipCodes";
import { useContext, useEffect, useMemo, useState } from "react";
import { FindCareProviderFilters } from "./FindCareProviderFilters";
import { FindCareProviderResults } from "./FindCareProviderResults";
import { AccountManagerContext } from "common/managers/AccountManager";
import { UserLocationSettingsModal } from "common/components/Modals/UserLocationSettingsModal";

type SearchProvidersQueryParams = {
	providerType?: StandardOption<string, string>;
	facilityType?: StandardOption<string, string>;
	physicianType?: StandardOption<string, string>;
	gender?: StandardOption<string, string>;
	languages?: Array<string | number>;
	classification?: StandardOption<string, string>;
	distance?: number | null;
};

const build_GetDoctorsByLocationParamsX = (
	providerType: StandardOption<string, string>,
	facilityType: StandardOption<string, string>,
	physicianType: StandardOption<string, string>,
	gender: StandardOption<string, string>,
	languages: Array<string | number>,
	classification: StandardOption<string, string>,
	distance: number | null,
	zipcode: string | null,
	rating: number | null,
	inNetwork: boolean
): GetDoctorsByLocationParamsX => {
	let params: GetDoctorsByLocationParams = {
		providerType: providerType.value,
		facilityType: facilityType.value,
		physicianType: physicianType.value,
		gender: gender?.value,
		languages: languages?.join(",") ?? "",
		classification: classification?.value ?? "",
		rating: rating ?? undefined,
		inNetwork: inNetwork,
	};
	if (params.providerType === "physician") {
		params.facilityType = undefined;
		if (params.physicianType !== "specialists" || params.classification === "") {
			params.classification = undefined;
		}
	} else if (params.providerType === "facility") {
		params.physicianType = undefined;
		params.gender = undefined;
		params.languages = undefined;
		params.classification = undefined;
	}
	let clientLocation = GeolocationManager.clientLocation({ useAccountPrefs: false });

	if (distance) {
		clientLocation.distanceRadiusMiles = distance;
	}
	if (zipcode) {
		clientLocation.zipcode = zipcode;
	}

	let x: GetDoctorsByLocationParamsX = { params, clientLocation };
	if (!clientLocation.zipcode && !clientLocation.latitude && !clientLocation.longitude) {
		x.noRequest = true;
	}
	return x;
};

const FindCareProviders = ({ demo }: { demo?: boolean }) => {
	const [locationModalOpen, set_locationModalOpen] = useState<boolean>(false);
	useEffect(() => {
		let cl = GeolocationManager.clientLocation();
		if (!cl.zipcode) {
			set_locationModalOpen(true);
		}

		(async () => {
			// GeolocationManager.getPosition();
			await GeolocationManager.getPosition().catch((e) => {
				//initial trigger to retrieve or access existing geolocation
				console.debug(e);
				return null;
			});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const accountContext = useContext(AccountManagerContext);

	// const hasProviderOptions = useMemo(() => {
	// 	if (accountContext?.careCollaborationUserDetails?.activeCareCollaboratorAuthorization) {
	// 		if (
	// 			accountContext?.careCollaborationUserDetails?.consumerUserDetails?.userAssessments?.providerOptions &&
	// 			accountContext?.careCollaborationUserDetails?.consumerUserDetails?.userAssessments?.providerOptions.length > 0
	// 		) {
	// 			return true;
	// 		}
	// 	} else if (
	// 		accountContext?.accountManager?.userDetails?.userAssessments?.providerOptions &&
	// 		accountContext?.accountManager?.userDetails?.userAssessments?.providerOptions.length > 0
	// 	) {
	// 		return true;
	// 	}
	// 	return false;
	// }, [
	// 	accountContext?.accountManager?.userDetails,
	// 	accountContext?.updateTrigger,
	// 	accountContext?.careCollaborationUserDetails?.activeCareCollaboratorAuthorization,
	// 	accountContext?.careCollaborationUserDetails?.consumerUserDetails?.userAssessments?.providerOptions,
	// ]);

	const [rating, set_rating] = useState<number | null>(2.5);
	const [inNetwork, set_inNetwork] = useState<boolean>(!!accountContext.activeProviderOptions);
	const [providerType, set_providerType] = useState<StandardOption<string, string>>({ name: "Doctor", value: "physician" });
	const [facilityType, set_facilityType] = useState<StandardOption<string, string>>({ name: "", value: "" });
	const [physicianType, set_physicianType] = useState<StandardOption<string, string>>({ name: "All", value: "all" });
	const [classification, set_classification] = useState<StandardOption<string, string>>({ name: "", value: "" });
	const [gender, set_gender] = useState<StandardOption<string, string>>({ name: "All", value: "all" });
	const [languages, set_languages] = useState<Array<string | number>>([]);
	const [distance, set_distance] = useState<number | null>(null);

	const [zipcode, set_zipcode] = useState<string | null>(null);

	useEffect(() => {
		if (accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip) {
			set_zipcode(accountContext.accountManager.userDetails.consumerUser.location.zip);
		}
	}, [accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip, accountContext?.updateTrigger]);

	const [loadingNextBatch, set_loadingNextBatch] = useState<boolean>(false);
	const [hasNextBatch, set_hasNextBatch] = useState<boolean>(true);
	const [doctorSearchResults, set_doctorSearchResults] = useState<DoctorsByLocationData | undefined | null>(undefined);
	const [nextBatchInProgress, set_nextBatchInProgress] = useState(0);

	const [doctorsByLocationParams, set_doctorsByLocationParams] = useState<GetDoctorsByLocationParamsX>(
		build_GetDoctorsByLocationParamsX(providerType, facilityType, physicianType, gender, languages, classification, distance, zipcode, rating, inNetwork)
	);

	const doctorsByLocationResponse = useQ_retrieveProvidersByLocation(doctorsByLocationParams);

	const throttle = useMemo<Throttle>(() => {
		return new Throttle(() => null, 2000, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	throttle.action = (args) => {
		args as SearchProvidersQueryParams;
		let p = build_GetDoctorsByLocationParamsX(
			args.providerType,
			args.facilityType,
			args.physicianType,
			args.gender,
			args.languages,
			args.classification,
			args.distance,
			args.zipcode,
			args.rating,
			args.inNetwork
		);
		if (set_doctorsByLocationParams) {
			set_doctorsByLocationParams(p);
		}
	};

	useEffect(() => {
		throttle.trigger({ providerType, facilityType, physicianType, classification, gender, languages, distance, zipcode, rating, inNetwork });
		set_hasNextBatch(true);
		set_loadingNextBatch(false);
		set_nextBatchInProgress(0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [providerType, facilityType, physicianType, classification, gender, languages, distance, zipcode, rating, inNetwork]);

	useEffect(() => {
		if (doctorsByLocationResponse.data?.data) {
			// let data = doctorsByLocationResponse.data?.data;
			if (
				apiResponseReader.typeCheck_ProcessedResponse(doctorsByLocationResponse.data) &&
				doctorsByLocationResponse.data.data &&
				!apiResponseReader.typeCheck_ModelsOperationStatus(doctorsByLocationResponse.data.data)
			) {
				// if (!apiResponseReader.typeCheck_ModelsOperationStatus(doctorsByLocationResponse.data.data)) {
				set_doctorSearchResults(doctorsByLocationResponse.data.data);
				console.log("DOC SEARCH RESULT", doctorsByLocationResponse.data.data);
				return;
				// } else {
				// 	console.debug("Unhandled DATA");
				// 	console.debug(doctorsByLocationResponse.data.data);
				// }
			} else if (apiResponseReader.typeCheck_ModelsOperationStatus(doctorsByLocationResponse.data.data)) {
				set_doctorSearchResults(doctorsByLocationResponse.data.data);
				console.debug("ModelsOperationStatus");
				console.debug(doctorsByLocationResponse.data.data);
				return;
			}
		}
		set_doctorSearchResults(null);
	}, [doctorsByLocationResponse.status, doctorsByLocationResponse.data, doctorsByLocationResponse]);

	/*
██████  ███████ ████████ ██████  ██ ███████ ██    ██ ███████     ███    ██ ███████ ██   ██ ████████     ██████   █████  ████████  ██████ ██   ██ 
██   ██ ██         ██    ██   ██ ██ ██      ██    ██ ██          ████   ██ ██       ██ ██     ██        ██   ██ ██   ██    ██    ██      ██   ██ 
██████  █████      ██    ██████  ██ █████   ██    ██ █████       ██ ██  ██ █████     ███      ██        ██████  ███████    ██    ██      ███████ 
██   ██ ██         ██    ██   ██ ██ ██       ██  ██  ██          ██  ██ ██ ██       ██ ██     ██        ██   ██ ██   ██    ██    ██      ██   ██ 
██   ██ ███████    ██    ██   ██ ██ ███████   ████   ███████     ██   ████ ███████ ██   ██    ██        ██████  ██   ██    ██     ██████ ██   ██*/
	// Either use next batch of NPIs from DoctorStubs or next batch of ZipCodes+Filters
	const retrieveNextBatch = async (currentCount: number) => {
		let shouldAttemptRetrieveNextBatch = true;
		if (doctorSearchResults && doctorSearchResults.nextBatchIndex >= doctorSearchResults.zipBatches.length) {
			// if all the batches of doctorRecordsBatches have been populated then there are no more to retrieve
			set_hasNextBatch(false);
			shouldAttemptRetrieveNextBatch = false;
		}

		if (nextBatchInProgress === currentCount) {
			// skip if already requested for the current batch // double+ click
			shouldAttemptRetrieveNextBatch = false;
		}

		if (doctorSearchResults && shouldAttemptRetrieveNextBatch) {
			set_nextBatchInProgress(currentCount);
			// let { recordBatchCount } = assess_current_results(doctorSearchResults);

			if (doctorSearchResults.zipBatches.length > doctorSearchResults.nextBatchIndex) {
				// Use Next batch of zipcodes and resend filters
				set_loadingNextBatch(true);
				await retrieveProvidersByZipCodes({
					clientLocation: doctorsByLocationParams.clientLocation,
					params: Object.assign({ zipCodes: doctorSearchResults.zipBatches[doctorSearchResults.nextBatchIndex] }, doctorsByLocationParams.params),
				}).then((r) => {
					doctorSearchResults.nextBatchIndex++;
					assimilate_batch(r);
				});
			}

			if (doctorSearchResults.nextBatchIndex >= doctorSearchResults.zipBatches.length) {
				set_hasNextBatch(false);
			}
		}
	};

	const assimilate_batch = (r: ProcessedResponse<any> | ConsumerDoctorGetDoctorsByZipCodesResponseModel | ConsumerDoctorGetDoctorsByNPIsResponseModel) => {
		if (!doctorSearchResults) {
			//error
			return;
		}
		if (!apiResponseReader.typeCheck_ProcessedResponse(r)) {
			if (r.doctorRecords?.length) {
				// console.log("request next batch");
				doctorSearchResults.doctorRecords = doctorSearchResults.doctorRecords.concat(r.doctorRecords);
				doctorSearchResults.providerEntities = doctorSearchResults.providerEntities.concat(...Process_DoctorsZipCoordinateResult(r.doctorRecords));
				set_doctorSearchResults(Object.assign({}, doctorSearchResults));
			} else {
				//error
				// console.log("error?");
				set_nextBatchInProgress(0);
			}
			set_loadingNextBatch(false);
		}
	};
	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ */
	return (
		<Box>
			<FindCareMenu />
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<ContentSection title="Filters">
						<FindCareProviderFilters
							rating={rating}
							set_rating={set_rating}
							inNetwork={inNetwork}
							set_inNetwork={accountContext.activeProviderOptions ? set_inNetwork : null}
							providerType={providerType}
							set_providerType={set_providerType}
							facilityType={facilityType}
							set_facilityType={set_facilityType}
							physicianType={physicianType}
							set_physicianType={set_physicianType}
							gender={gender}
							set_gender={set_gender}
							languages={languages}
							set_languages={set_languages}
							classification={classification}
							set_classification={set_classification}
							distance={distance}
							set_distance={set_distance}
							zipcode={zipcode}
							set_zipcode={set_zipcode}
							set_locationModalOpen={
								accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip ? undefined : set_locationModalOpen.bind(null, true)
							}
						/>
						<UserLocationSettingsModal
							open={locationModalOpen}
							onClose={() => {
								// alert("close");
								set_locationModalOpen(false);
								console.debug();
							}}
						/>
						{/* <button
							onClick={() => {
								set_locationModalOpen(true);
							}}
						>
							{accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip ?? "..."}
						</button> */}
					</ContentSection>
				</Grid>
				<Grid item xs={12} md={8}>
					<ContentSection title="Results">
						{/* <LocationPermissionCheck> */}
						{/* <pre>{JSON.stringify(doctorSearchResults, null, 4)}</pre> */}
						<FindCareProviderResults
							doctorsByLocationQueryResult={doctorsByLocationResponse}
							data={doctorSearchResults}
							retrieveNextBatch={retrieveNextBatch}
							loadingNextBatch={loadingNextBatch}
							hasNextBatch={hasNextBatch}
						/>
						{/* </LocationPermissionCheck> */}
					</ContentSection>
				</Grid>
			</Grid>
		</Box>
	);
};

export { FindCareProviders };
