import { Box, Switch, SwitchProps, Typography } from "@mui/material";
import { CSSProperties, useMemo } from "react";

interface StandardSwitchProps extends SwitchProps {
	text?: string;
	textStyle?: CSSProperties;
}
const StandardSwitch = (props: StandardSwitchProps) => {
	const textStyle: CSSProperties = useMemo(() => {
		let sty: CSSProperties = { display: "flex", flexDirection: "column", justifyContent: "center" };
		if (props.textStyle) {
			Object.assign(sty, props.textStyle);
		}
		return sty;
	}, [props.textStyle]);

	const propsX = useMemo(() => {
		const propsX: SwitchProps = Object.assign({}, props);
		delete (propsX as any).text;
		delete (propsX as any).textStyle;
		return propsX;
	}, [props]);
	return (
		<Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
			<Switch {...propsX} />
			{props.text ? (
				<Typography component="div" style={textStyle}>
					{props.text}
				</Typography>
			) : null}
		</Box>
	);
};

export { StandardSwitch };
