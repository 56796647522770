import { Button, ButtonGroup } from "@mui/material";
import { StandardOption } from "common/types/generic";
import { palette } from "theme";

type ButtonBarProps = {
	buttons: Array<StandardOption<string, string | number | boolean>>;
	selected: string | number | boolean;
	setSelected: (x: any) => any;
	equalSize?: boolean;
};

const ButtonBar = ({ buttons, selected, setSelected, equalSize }: ButtonBarProps) => {
	return (
		<ButtonGroup
			variant="contained"
			aria-label="outlined primary button group"
			sx={{
				backgroundColor: palette.gray.main,
				borderRadius: "0.5rem",
				boxShadow: "none",
				display: "flex",
				flexDirection: "row",
				alignItems: "stretch",
				justifyContent: "stretch",
				"& > button": {
					transition: "background-color .5s, color .5s",
					flexGrow: 1,
					flexBasis: equalSize ? 1 : undefined,
					fontWeight: "500",
					fontSize: "1rem",
					borderWidth: "2px !important",
					borderStyle: "solid",
					borderColor: "transparent !important",
					padding: "0.5rem",
					borderRadius: "0.5rem",
					backgroundColor: "transparent",
					color: palette.gray.dark,
					cursor: "pointer",
					boxSizing: "border-box",
				},
				"& > button:not(.active):hover": {
					backgroundColor: palette.gray.dark,
					color: "#ffffff",
				},
				"& > button.active": {
					backgroundColor: palette.core.blue,
					borderColor: palette.core.blue,
					color: "white",
				},
			}}
		>
			{buttons.map((b: { name: string; value: string | number | boolean }, i: number) => {
				return (
					<Button
						style={{
							textTransform: "none",
							borderRadius: "0.5rem",
						}}
						key={i}
						className={selected === b.value ? "active" : ""}
						onClick={() => {
							setSelected(b.value);
						}}
					>
						{b.name}
					</Button>
				);
			})}
		</ButtonGroup>
	);
};

export { ButtonBar };
