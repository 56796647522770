import { Box } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { EventTileX } from "common/components/Tiles/EventTile";
import { EventDescription } from "common/definitions/EventDefinitions";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";

type HealthSnapshotMedicationsKeyFindingsProps = {
	keyFindings: EventDescription[];
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotMedicationsKeyFindings = ({ keyFindings, patientEOBData }: HealthSnapshotMedicationsKeyFindingsProps) => {
	return (
		<Box style={{ display: "flex", flexGrow: 0, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
			<QueryStatusDisplay queryResult={patientEOBData}>
				{keyFindings
					? keyFindings.map((r, i) => {
							return <EventTileX key={i} eventDescription={r} />;
					  })
					: "No Results"}
			</QueryStatusDisplay>
		</Box>
	);
};

export { HealthSnapshotMedicationsKeyFindings };
