import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { InviteCareCollaborator } from "./InviteCareCollaborator";
// import { InviteCareCollaborator } from "details/inviteCareCollaborator/InviteCareCollaborator";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const InviteCareCollaboratorDialog = () => {
	const location = useLocation();
	const { inviteCareCollaboratorDisplay } = useContext(DialogsContext);

	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("invite-care-collaborator");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("invite-care-collaborator");
			setSearchParams(searchParams);
			inviteCareCollaboratorDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "invite-care-collaborator": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["invite-care-collaborator"].open = open;
		DialogManager.launchers["invite-care-collaborator"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== inviteCareCollaboratorDisplay?.current) {
			inviteCareCollaboratorDisplay.set_current(d_query);
		}
	}, [d_query, inviteCareCollaboratorDisplay]);

	return (
		<Dialog id="InviteCareCollaboratorDialog" fullScreen open={!!inviteCareCollaboratorDisplay.current} onClose={close} TransitionComponent={Transition}>
			{inviteCareCollaboratorDisplay.current ? (
				<InviteCareCollaborator npi={inviteCareCollaboratorDisplay.current} />
			) : // <p>Invite Care Collaborator</p>
			null}
		</Dialog>
	);
};

export { InviteCareCollaboratorDialog };
