import { ReactNode, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Checkbox, IconButton, Link, Stack, TextField, Typography } from "@mui/material";
import { icons } from "theme";
import Icon from "common/components/Icons/Icon";
import { FormAlerts } from "common/forms/FormAlerts";
import { FormValidater } from "common/forms/FormValidater";
import { addConsumerUser } from "api/consumer-users/consumer-users";
import { ConsumerAddConsumerUserResult, ConsumerNameValue, ModelsOperationStatus } from "api";
import FormContainer from "./FormContainer";
import { retrievePasswordRequirements } from "../../api_mobile/definitions/definitions";
import { FormErrors, formUtilities, FormValidationResponse, GenericFormValidationResponse } from "common/forms/formUtilities";

interface SignUpFormProps {
	// onSignIn(username: string, password: string, target?: string): void;
	onSignIn: (username: string, password: string, target?: string) => Promise<any>;
	onSignInTarget?: string;
	additionalContent?: ReactNode;
	addonFormSendBefore?: () => Promise<boolean | ModelsOperationStatus>;
	addonFormValidate?: () => FormValidationResponse<CIM>;
}

type SignUpFormValues = {
	email: string;
	password: string;
	terms: boolean;
};

function SignUpForm({ onSignIn, onSignInTarget = "/connect-healthplan", additionalContent, addonFormValidate, addonFormSendBefore }: SignUpFormProps) {
	const [passwordReq, set_passwordReq] = useState<null | string>(null);
	const [passwordRegexes, set_passwordRegexes] = useState<Array<ConsumerNameValue>>([]);
	const [errors, set_errors] = useState<FormErrors | undefined>();

	useEffect(() => {
		retrievePasswordRequirements().then((x) => {
			if (x) {
				if (x.passwordRequirements) {
					set_passwordReq("(" + x.passwordRequirements + ")");
				}
				if (x.regexRequirements) {
					set_passwordRegexes(x.regexRequirements);
				}
			}
		});
	}, []);

	const [formValues, dispatch_formValues] = useReducer(
		(x: SignUpFormValues, action: Partial<SignUpFormValues>) => {
			return formUtilities.formValuesReducer<SignUpFormValues>(x, action);
		},
		{ email: "", password: "", terms: true }
	);

	/*
██    ██  █████  ██      ██ ██████   █████  ████████ ███████ 
██    ██ ██   ██ ██      ██ ██   ██ ██   ██    ██    ██      
██    ██ ███████ ██      ██ ██   ██ ███████    ██    █████   
 ██  ██  ██   ██ ██      ██ ██   ██ ██   ██    ██    ██      
  ████   ██   ██ ███████ ██ ██████  ██   ██    ██    ███████ 
                                                            
*/
	const validate = useMemo(() => {
		return (): GenericFormValidationResponse => {
			console.debug("validate Signup");
			const errs: FormErrors = {};

			if (!formValues.email) {
				errs.firstname = { type: "required", message: "Email Address is Required" };
			} else {
				let eCheck = FormValidater.email(formValues.email, "Email Address must be valid");
				if (typeof eCheck === "string") {
					errs.email = { type: "required", message: eCheck };
				}
			}

			if (!formValues.password) {
				errs.password = { type: "required", message: "Password is Required" };
			} else {
				let pCheck = FormValidater.regexCheck(formValues.password, passwordRegexes);
				if (typeof pCheck === "string") {
					errs.password = { type: "required", message: pCheck };
				}
			}
			if (formValues.terms !== true) {
				errs.terms = { type: "required", message: "You must accept the Terms of Service and Privacy Policy" };
			}

			set_errors(errs);
			return { isValid: Object.getOwnPropertyNames(errs).length === 0, errors: errs, values: formValues };
		};
	}, [formValues, set_errors, passwordRegexes]);

	const [passwordVisibility, setPasswordVisibility] = useState(false);
	const [requestInProgress, setRequestInProgress] = useState(false);

	const label = { inputProps: { "aria-label": "Checkbox demo" } };

	const submitSignUp = async () => {
		console.debug("submitSignUp");

		setRequestInProgress(true);
		let [email, password] = [formValues.email, formValues.password];
		email = email.toLowerCase();
		addConsumerUser({ email, password })
			.then(async (r: ConsumerAddConsumerUserResult) => {
				setRequestInProgress(false);
				if (!r?.response?.success) {
					set_errors({
						email: {
							type: "custom",
							message: r?.response?.errorMessage ?? "An error occurred",
						},
					});
				} else if (r?.response?.data?.email) {
					if (addonFormSendBefore) {
						await addonFormSendBefore();
					}
					onSignIn(email, password, onSignInTarget);
				}
			})
			.catch((r) => {
				console.debug("ERROR:", r);
				setRequestInProgress(false);
				set_errors({
					email: {
						type: "custom",
						message: "The request could not be completed",
					},
				});
			});
	};

	const handleSubmit = (a: any) => {
		console.debug(a);
		console.debug("handle_submit");
		let signUpIsValid = validate();

		let addonFormIsValid = true;
		if (addonFormValidate) {
			let addonFormValidityResponse = addonFormValidate();
			console.debug({ addonFormValidityResponse });
			addonFormIsValid = addonFormValidityResponse.isValid;
		}

		if (signUpIsValid.isValid && addonFormIsValid) {
			submitSignUp();
		}
	};
	/*
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	*/
	const formRef = useRef<HTMLFormElement | null>(null);
	return (
		<FormContainer
			bottomContent={
				<Box style={{ textAlign: "center" }}>
					<Link href="/signin" sx={{ color: "core.darkblue", fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
						Cancel
					</Link>
				</Box>
			}
		>
			<Card id="registrationForm" ref={formRef as CIM} component="form" elevation={4}>
				<CardContent>
					<Typography variant="h1" component="h1">
						Sign up
					</Typography>
					<Typography sx={{ pb: 4 }}>Ready to start on a path to better health?</Typography>
					<Stack spacing={2}>
						<TextField
							label="Enter your email address"
							variant="standard"
							value={formValues.email}
							onChange={(x) => {
								dispatch_formValues({ email: x.target.value });
							}}
						/>
						<Stack
							style={{
								width: "100%",
								position: "relative",
							}}
						>
							<TextField
								label="Create a password"
								type={passwordVisibility ? "text" : "password"}
								variant="standard"
								value={formValues.password}
								onChange={(x) => {
									dispatch_formValues({ password: x.target.value });
								}}
							/>
							<IconButton
								style={{
									position: "absolute",
									right: 8,
									bottom: 0,
									opacity: 0.4,
								}}
								onClick={() => {
									setPasswordVisibility(!passwordVisibility);
								}}
							>
								{passwordVisibility ? <Icon icon={icons.eyeSlash} /> : <Icon icon={icons.eye} />}
							</IconButton>
						</Stack>
						<Box>{passwordReq}</Box>
						<Stack
							sx={{
								flexDirection: "row",
								justifyContent: "flex-start",
								alignItems: "center",
							}}
						>
							<Checkbox
								sx={{
									borderRadius: 2,
									width: 18,
									height: 18,
									margin: "10px",
									"&.Mui-checked": {
										color: "primary.attention",
										backgroundColor: "black",
									},
								}}
								{...label}
								checked={formValues.terms}
								onChange={(x) => {
									dispatch_formValues({ terms: x.target.checked });
								}}
							/>
							<Typography>
								I agree to the{" "}
								<Link href="https://radiuscare.com/privacy-policy" target={"_blank"}>
									Privacy Policy
								</Link>{" "}
								&{" "}
								<Link href="https://radiuscare.com/terms-of-service" target={"_blank"}>
									Terms of Service
								</Link>
							</Typography>
						</Stack>
					</Stack>
					<FormAlerts formStateErrors={errors} />
				</CardContent>
				{additionalContent ? additionalContent : null}
				<CardActions sx={{ justifyContent: "right", marginTop: 0, paddingTop: 0 }}>
					<Button type="button" onClick={handleSubmit} sx={{ backgroundColor: "core.darkblue" }} disabled={requestInProgress}>
						Sign up
					</Button>
				</CardActions>
			</Card>
		</FormContainer>
	);
}

export default SignUpForm;
