import { AppBar, Box, Button, Container, Stack, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { palette, style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { useState, useContext } from "react";
import { inviteCareCollaborator } from "../../../api/consumer-care-collaborator/consumer-care-collaborator";
import { ConsumerOperationStatusInviteCareCollaboratorResponse, ModelsOperationStatus } from "api";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useSnackbar } from "notistack";

const validateForm = (email: string): boolean => {
	let indexAt = email.indexOf("@");
	let indexDot = email.lastIndexOf("."); // Changed - resolved bug not allowing [].[]@[].[]
	if (indexAt > 0 && indexDot !== -1 && indexDot > indexAt + 1 && indexDot < email.length - 1) {
		return true;
	}
	return false;
};
function InviteCareCollaborator({ npi }: { npi: string }) {
	const account = useContext(AccountManagerContext);

	const [email, set_email] = useState<string>("");
	const [requestInProcess, set_requestInProcess] = useState<boolean>(false);
	const [errors, set_errors] = useState<null | { [key: string]: string }>(null);
	const [inviteSucceeded, set_inviteSucceeded] = useState<true | null>(null);

	const [refreshTrigger, update_refreshTrigger] = useState<number>(0);
	const [invitationResponse, set_invitationResponse] = useState<ConsumerOperationStatusInviteCareCollaboratorResponse | null>(null);

	const { enqueueSnackbar } = useSnackbar();

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Invite Care Collaborator</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("invite-care-collaborator");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
				<Container maxWidth={"xs"}>
					<ContentSection title="Enter Email Below">
						<StandardTextField
							sx={{ mb: 2 }}
							data-styletype="infodisplay"
							variant="filled"
							placeholder="Email"
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								set_email(e.currentTarget.value ?? "");
								if (errors !== null) {
									set_errors(null);
								}
								if (invitationResponse !== null) {
									set_invitationResponse(null);
								}
								if (inviteSucceeded !== null) {
									set_inviteSucceeded(null);
								}
							}}
							value={email}
							error={!!errors?.email}
							helperText={errors?.email}
						/>

						<QueryStatusDisplay operationStatus={invitationResponse as ModelsOperationStatus} />

						<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
							{inviteSucceeded ? (
								<Box>
									<Box
										sx={{
											padding: "1.5rem",
											borderRadius: "1rem",
											textAlign: "center",
											borderStyle: "solid",
											borderWidth: "2px",
											backgroundColor: palette.gray.light,
											borderColor: palette.success.main,
											color: palette.success.main,
										}}
									>
										<Typography
											sx={{
												fontSize: "1.25rem",
											}}
										>
											Invitation to {email} was sent successfully.
										</Typography>
									</Box>
									<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
										<Button sx={{ mt: 2 }} onClick={DialogManager.launchers["invite-care-collaborator"].close}>
											Close
										</Button>
									</Box>
								</Box>
							) : (
								<Button
									sx={{ mt: 2 }}
									disabled={requestInProcess}
									onClick={async () => {
										if (validateForm(email)) {
											set_requestInProcess(true);
											set_errors(null);
											let response = await inviteCareCollaborator({ inviteEmail: email });
											if (response && response.success && response.data && response.data.collaboratorEmail === email) {
												set_inviteSucceeded(true);
												enqueueSnackbar(`Invitation sent to ${email}`, {
													variant: "success",
												});
												DialogManager.close("invite-care-collaborator");
											}
											set_invitationResponse(response as ConsumerOperationStatusInviteCareCollaboratorResponse);
											set_requestInProcess(false);
											update_refreshTrigger(refreshTrigger + 1);
											account.refreshCollaborators();
										} else {
											set_errors({ email: "Please enter a valid email" });
										}
									}}
								>
									Send Invite
								</Button>
							)}
						</Box>
					</ContentSection>
				</Container>
			</Stack>
		</>
	);
}

export { InviteCareCollaborator };
