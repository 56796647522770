import Box from "@mui/material/Box";
import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { Controller, useForm } from "react-hook-form";
import { AccountManagerContext } from "common/managers/AccountManager";
import { useContext } from "react";
import { changePasswordComplete } from "api";
import { useSnackbar } from "notistack";
import { AppBar, Container, Stack, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { style } from "theme";

interface ChangePasswordProps {
	current: string;
	new: string;
	confirm: string;
}

interface UserInfo {
	username: string;
}

function ChangePassword(props: UserInfo) {
	const { handleSubmit, getValues, control } = useForm<ChangePasswordProps>({
		mode: "onChange",
		defaultValues: {},
	});

	const { enqueueSnackbar } = useSnackbar();

	function successCallback() {
		enqueueSnackbar("Password updated", {
			variant: "success",
		});
	}

	function failureCallback(err: string) {
		enqueueSnackbar("Error: " + err, {
			variant: "error",
		});
	}

	const user = useContext(AccountManagerContext);

	const ChangePassword = () => {
		return new Promise((resolve, reject) => {
			if (user.user) {
				user.user.changePassword(getValues("current"), getValues("new"), function (err, result) {
					if (err) {
						failureCallback(err.message || JSON.stringify(err));
						return;
					} else {
						successCallback();
						changePasswordComplete();
					}
				});
			}
		});
	};

	const onSubmit = handleSubmit(() => {
		ChangePassword();
	});

	return (
		<>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">Change Password</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("change-password");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
				<Container maxWidth={"xs"}>
					<Box onSubmit={onSubmit} component="form">
						<ContentSection flexGrow={1} title="Change Password">
							<Box sx={{ paddingTop: "1rem" }}>
								<Controller
									name="current"
									control={control}
									defaultValue={""}
									rules={{
										required: "Required",
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<StandardTextField
											data-styletype="infodisplay"
											label="Current Password"
											variant="filled"
											type={"password"}
											sx={{ flexGrow: 1, width: "100%", paddingBottom: ".5rem" }}
											value={value}
											onChange={onChange}
											error={!!error}
											helperText={error ? error.message : null}
										/>
									)}
								/>
								<Controller
									name="new"
									control={control}
									defaultValue={""}
									rules={{
										required: "Required",
										minLength: {
											value: 8,
											message: "Password minumum 8 characters",
										},
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<StandardTextField
											data-styletype="infodisplay"
											label="New Password"
											variant="filled"
											type={"password"}
											sx={{ flexGrow: 1, width: "100%", paddingBottom: ".5rem" }}
											value={value}
											onChange={onChange}
											error={!!error}
											helperText={error ? error.message : null}
										/>
									)}
								/>
								<Controller
									name="confirm"
									control={control}
									defaultValue={""}
									rules={{
										required: "Required",
										minLength: {
											value: 8,
											message: "Password minumum 8 characters",
										},
										validate: (value) => value === getValues("new"),
									}}
									render={({ field: { onChange, value }, fieldState: { error } }) => (
										<StandardTextField
											data-styletype="infodisplay"
											label="Confirm Password"
											variant="filled"
											type={"password"}
											sx={{ flexGrow: 1, width: "100%", paddingBottom: ".5rem" }}
											value={value}
											onChange={onChange}
											error={!!error}
											helperText={error ? error.message : null}
										/>
									)}
								/>
								<Box sx={{ paddingTop: "1rem", display: "flex", margin: "auto", justifyContent: "flex-end" }}>
									<FormSubmitButton
										onClick={() => {
											onSubmit();
											DialogManager.close("change-password");
										}}
									>
										Change Password
									</FormSubmitButton>
								</Box>
							</Box>
						</ContentSection>
					</Box>
				</Container>
			</Stack>
		</>
	);
}

export default ChangePassword;
