import React, { useState, useContext, useMemo, useEffect } from "react";
import { Backdrop, Box, ButtonBase, Stack, Typography } from "@mui/material";
import { LeftNavigationDrawer } from "./drawers/LeftNavigationDrawer";
import { RightAccountDrawer } from "./drawers/RightAccountDrawer/RightAccountDrawer";
import { RightAlertsDrawer } from "./drawers/RightAlertsDrawer";
import { icons, palette, style, theme } from "../theme";
import { TopSearchDrawer } from "./drawers/TopSearchDrawer";
import { StyleContext } from "../common/context/StyleContext";
import { useQ_retrievePatientEOBDataX } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { RadiusHeader } from "./header/RadiusHeader";
import { DialogContainer } from "./DialogContainer";
import { AccountManagerContext, AccountManagerDisplayModalTypes } from "common/managers/AccountManager";
import { SimpleModal } from "common/components/Modals/SimpleModal";
import { PatientDataAuthorizationItem } from "../api/backend.schemas";
import { AcceptedCareCollaborations } from "account/pages/myProfile/CareCollaborators/AcceptedCareCollaborations";
import Icon from "common/components/Icons/Icon";
import { useLocation } from "react-router-dom";

type Props = React.PropsWithChildren<{}>;

function MainLayout({ children }: Props) {
	// same call occurs in DashboardRoutes - only one request should go out to API
	const patientEOBData = useQ_retrievePatientEOBDataX({
		startDate: null,
		endDate: null,
		includeLookUps: false,
		includeKeyFindings: true,
		clientLocation: GeolocationManager.clientLocation(),
		// invalidateOnDateOnly: true,
		caller: "MainLayout",
	});

	const [topSearchOpen, set_topSearchOpen] = useState(false);
	const [leftNavOpen, set_leftNavOpen] = useState(true);
	const [rightAccountOpen, set_rightAccountOpen] = useState(false);
	const [rightAlertsOpen, set_rightAlertsOpen] = useState(false);

	const location = useLocation();

	const styleContext = useContext(StyleContext);

	const anyOpenWings = useMemo(() => {
		return topSearchOpen || leftNavOpen || rightAccountOpen || rightAlertsOpen;
	}, [topSearchOpen, leftNavOpen, rightAccountOpen, rightAlertsOpen]);

	const openWingsOnSmallDevice = useMemo(() => {
		return anyOpenWings && styleContext.screenWidth <= theme.breakpoints.values.md;
	}, [anyOpenWings, styleContext.screenWidth]);

	const closeAllWings = useMemo(() => {
		return () => {
			if (topSearchOpen) {
				set_topSearchOpen(false);
			}
			if (leftNavOpen) {
				set_leftNavOpen(false);
			}
			if (rightAccountOpen) {
				set_rightAccountOpen(false);
			}
			if (rightAlertsOpen) {
				set_rightAlertsOpen(false);
			}
		};
	}, [set_topSearchOpen, set_leftNavOpen, set_rightAccountOpen, set_rightAlertsOpen, topSearchOpen, leftNavOpen, rightAccountOpen, rightAlertsOpen]);

	const account = useContext(AccountManagerContext);

	const activeCareCollaboration = useMemo(() => {
		if (account.careCollaborations?.active) {
			return account.careCollaborations.active;
		}
		return null;
	}, [account.careCollaborations?.active]);

	const nonPrimarySelectedPatientItems = useMemo(() => {
		// console.debug("PDAs");
		// console.debug(account.accountManager.userDetails?.patientDataAuthorizations);
		if (account.accountManager.userDetails?.patientDataAuthorizations) {
			let nonPrimaryPDAs: Array<PatientDataAuthorizationItem> = [];
			for (var i = 0; i < account.accountManager.userDetails.patientDataAuthorizations.length; i++) {
				let pda = account.accountManager.userDetails.patientDataAuthorizations[i];
				if (pda.activePatientItem && pda.activePatientItem.patientType !== "Main") {
					nonPrimaryPDAs.push(pda.activePatientItem);
				}
			}
			if (nonPrimaryPDAs.length > 0) {
				return nonPrimaryPDAs;
			}
		}
		return null;
	}, [account.accountManager.userDetails?.patientDataAuthorizations]);

	const accountManagerContext = useContext(AccountManagerContext);

	const [accountDisplayModalType, set_accountDisplayModalType] = useState<AccountManagerDisplayModalTypes>(null);

	const closeWingsIfMinScreenWidth = useMemo(() => {
		if (styleContext.screenWidth <= theme.breakpoints.values.md) {
			return closeAllWings;
		}
		return () => null;
	}, [closeAllWings, styleContext.screenWidth]);

	useEffect(() => {
		if (
			location.pathname === "/dashboard/myoverview" &&
			!account.careCollaboratorAlertClosed &&
			accountDisplayModalType === "InactiveCollaborationWithNoDirectDatasource"
		) {
			set_accountDisplayModalType(accountManagerContext.displayModal);
		}
	}, [account.careCollaboratorAlertClosed, accountDisplayModalType, accountManagerContext.displayModal, location.pathname]);

	const [paddingLeft, paddingRight] = useMemo(() => {
		let xxl = theme.breakpoints.values.xl + 300;
		return [
			leftNavOpen &&
			(styleContext.screenWidth < theme.breakpoints.values.xl || (styleContext.screenWidth < xxl && rightAccountOpen)) &&
			styleContext.screenWidth > theme.breakpoints.values.sm
				? `${style.leftNavWidth}px`
				: "0px",
			rightAccountOpen && styleContext.screenWidth < xxl && styleContext.screenWidth > theme.breakpoints.values.lg ? `${style.rightAccountWidth}` : "0px",
		];
	}, [leftNavOpen, rightAccountOpen, styleContext.screenWidth]);

	return (
		<Stack
			flexDirection="column"
			alignItems="center"
			justifyContent="stretch"
			sx={{
				width: "100%",
				minHeight: "100vh",
				backgroundColor: palette.gray.light,
			}}
		>
			<RadiusHeader
				topSearchOpen={topSearchOpen}
				set_topSearchOpen={set_topSearchOpen}
				leftNavOpen={leftNavOpen}
				set_leftNavOpen={set_leftNavOpen}
				rightAccountOpen={rightAccountOpen}
				set_rightAccountOpen={set_rightAccountOpen}
				rightAlertsOpen={rightAlertsOpen}
				set_rightAlertsOpen={set_rightAlertsOpen}
			/>
			{activeCareCollaboration || nonPrimarySelectedPatientItems ? (
				<Stack
					sx={{
						position: "relative",
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "row",
						backgroundColor: palette.core.forestgreen,
						color: "#ffffff",
						padding: ".5rem",
						boxSizing: "border-box",
						textAlign: "center",
					}}
				>
					<Box style={{ paddingLeft: paddingLeft, paddingRight: paddingRight, transition: "padding 0.25s" }}>
						{activeCareCollaboration ? (
							<Typography sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
								Viewing Health Records for {activeCareCollaboration.ownerDisplayName ?? activeCareCollaboration.email}
							</Typography>
						) : nonPrimarySelectedPatientItems ? (
							nonPrimarySelectedPatientItems.map((x) => {
								return (
									<Typography key={x.id} sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}>
										Viewing Health Records for {x.demographicInfo?.name ? x.demographicInfo.name : "(Un-named Sub Patient)"}
									</Typography>
								);
							})
						) : null}
					</Box>
				</Stack>
			) : null}
			<Stack
				flexDirection="row"
				justifyContent="center"
				alignItems="stretch"
				flexGrow="1"
				sx={{
					width: "100%",
					backgroundColor: palette.gray.light,
				}}
			>
				<TopSearchDrawer open={topSearchOpen} setOpen={set_topSearchOpen} offset={styleContext.headerOffset} />
				<LeftNavigationDrawer open={leftNavOpen} setOpen={set_leftNavOpen} offset={styleContext.headerOffset} />
				<RightAccountDrawer
					open={rightAccountOpen}
					setOpen={set_rightAccountOpen}
					patientEOBData={patientEOBData}
					closeWingsIfMinScreenWidth={closeWingsIfMinScreenWidth}
					offset={styleContext.headerOffset}
				/>
				<RightAlertsDrawer open={rightAlertsOpen} setOpen={set_rightAlertsOpen} offset={styleContext.headerOffset} />
				<DialogContainer />
				<Backdrop sx={{ backgroundColor: "rgba(0,0,0,0.125)", zIndex: 1 }} open={openWingsOnSmallDevice} onClick={closeAllWings} />
				<Stack
					sx={{
						flexGrow: 1,
						maxWidth: theme.breakpoints.values.lg,
						paddingLeft: paddingLeft, //leftNavOpen && styleContext.screenWidth < 1600 && styleContext.screenWidth > 700 ? `${style.leftNavWidth}px` : "0px",
						paddingRight: paddingRight,
						//rightAccountOpen && styleContext.screenWidth < 1600 && styleContext.screenWidth > 970 ? `${style.rightAccountWidth}` : "0px",
						[theme.breakpoints.down("xs")]: {},
						transition: "padding 0.25s",
					}}
				>
					<Box
						data-component="MainLayoutInner"
						sx={{
							p: 2,
							//marginTop: styleContext.headerOffset + "px"
						}}
					>
						{children}
					</Box>
				</Stack>
			</Stack>
			<SimpleModal
				open={accountDisplayModalType === "InactiveCollaborationWithNoDirectDatasource"}
				onClose={() => {
					set_accountDisplayModalType(null);
					account.set_careCollaboratorAlertClosed?.(true);
				}}
				maxWidth={600}
			>
				<Box style={{ paddingBottom: "20px" }}>
					<Box style={{ paddingBottom: "20px" }}>
						<Box sx={{ display: "flex", justifyContent: "flex-end", ml: 2 }}>
							<ButtonBase
								onClick={() => {
									set_accountDisplayModalType(null);
									account.set_careCollaboratorAlertClosed?.(true);
								}}
								sx={{
									position: "relative",
									height: "2.5rem",
									width: "2.5rem",
									borderRadius: "50%",
									border: "2px solid",
									borderColor: palette.core.blue,
									color: palette.core.blue,
									boxShadow: 4,
									display: "flex",
									justifyContent: "flex-end",
									"& svg": {
										color: palette.core.blue,
										fontSize: "1.75rem",
									},
								}}
							>
								<Icon fixedWidth icon={icons.close} />
							</ButtonBase>
						</Box>
						<Typography>No direct Insurance Plan exists on your account.</Typography>

						<Typography>
							Would you like to activate
							{accountManagerContext.careCollaborations?.receivedAccepted && accountManagerContext.careCollaborations?.receivedAccepted.length > 1
								? " a "
								: " your "}
							Care Collaboration?
						</Typography>
					</Box>
					{/* <ExistingCareCollaborations displayLocation="sidebar" noContentSectionContainers /> */}
					{/* <ReceivedCareCollaborations allowRemove={false} /> */}
					<AcceptedCareCollaborations allowRemove={false} />
				</Box>
			</SimpleModal>
		</Stack>
	);
}

export { MainLayout };
