import AccountPageWrapper from "account/components/AccountPageWrapper";
import { Routes, Route, useParams } from "react-router-dom";
import ConnectHealthPlanPage from "account/pages/connectHealthPlan/ConnectHealthPlanPage";
import { ConnectHealthPlanAuthorize } from "account/pages/connectHealthPlan/ConnectHealthPlanAuthorize";
import { ConnectHealthPlanReceive } from "account/pages/connectHealthPlan/ConnectHealthPlanReceive";
import { getDatasourceDefinitionByDataSourceID } from "common/definitions/DataSourceDefinitions";
import { CariskSuccess } from "account/pages/connectHealthPlan/_common/CariskSuccess";

export function ConnectHealthPlanRouter({ wrap }: { wrap?: boolean }) {
	let params = useParams();
	console.debug({ params });
	//  /connect-healthplan/authorize/healthplan:medicare:learnmore/
	//  /connect-healthplan/receive/healthplan:medicare/
	let healthplanX: any = params["*"]?.split("healthplan:")[1]?.split("/")[0]?.split("?")[0];
	console.debug(params["*"]);
	console.debug({ healthplanX });
	let learn_more;
	let healthplan;
	if (healthplanX) {
		learn_more = healthplanX.endsWith(":learnmore");
		healthplan = getDatasourceDefinitionByDataSourceID(healthplanX)?.healthplan;
	}

	const routes = (
		<Routes>
			<Route path="authorize/*" element={<ConnectHealthPlanAuthorize healthplan={healthplan} learn_more={learn_more} />} />
			<Route path="receive/*" element={<ConnectHealthPlanReceive healthplan={healthplan} />} />
			<Route path="carisk-success/*" element={<CariskSuccess useAccountPageWrapper={false} />} />
			<Route path={"*"} element={<ConnectHealthPlanPage />} />
		</Routes>
	);

	if (wrap === false) {
		return routes;
	} else {
		return <AccountPageWrapper>{routes}</AccountPageWrapper>;
	}
}
