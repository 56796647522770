import { ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel, updateRemindersUspstfCodes } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { QueryClient } from "react-query";

const X_updateRemindersUspstfCodes = async (params: ConsumerPatientHealthDataUpdateRemindersUSPSTFRequestModel, queryClient?: QueryClient) => {
	// console.group("X_addUserIgnoreItems");
	console.log("params", params);
	let response = await updateRemindersUspstfCodes(params);
	console.debug(response);

	queryClient = queryClient ?? window.RadiusCare.queryClient; // window.RadiusCare.queryClient does not usually work ?? can troubleshoot or wrap useQueryClient in a function that will continually reset it to latest maybe? it checks out as === to useQueryClient()
	queryClient?.invalidateQueries("retrieveRecommendedUSPSTF");
	queryClient?.invalidateQueries("retrievePatientEOBDataX");
	queryClient?.invalidateQueries("useKeyFindings");

	AccountManager.setUser("REFRESH");
	return response;
};

export { X_updateRemindersUspstfCodes };
