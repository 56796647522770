import { Box, Stack } from "@mui/material";
import { theme } from "theme";

type Props = React.PropsWithChildren<{}>;
export default function AccountPageWrapper({ children }: Props) {
	return (
		<Stack
			alignItems="center"
			justifyContent="center"
			sx={{
				minHeight: "100vh",
				background: "linear-gradient(180deg, rgba(228,229,245,1) 0%, rgba(255,255,255,1) 75%)",
			}}
		>
			<Stack
				justifyContent={"center"}
				sx={{
					position: "fixed",
					top: 0,
					left: 0,
					width: "100%",
					height: "80px",
					backgroundColor: "white",
					boxShadow: 4,
				}}
			>
				<Box
					component={"img"}
					alt="radius care logo"
					src={"/images/radius-care-logo.svg"}
					sx={{
						[theme.breakpoints.down("sm")]: {
							height: "2.5rem",
						},
						[theme.breakpoints.up("sm")]: {
							height: "3.5rem",
						},
					}}
				></Box>
			</Stack>
			{children}
		</Stack>
	);
}
