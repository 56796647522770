import React, { Dispatch, useMemo, useRef } from "react";
import { Box, Stack } from "@mui/material";
import { ProfileBadge } from "./ProfileBadge";
import { MenuBadge } from "./MenuBadge";
import { style, theme } from "../../theme";
import { SearchBadge } from "./SearchBadge";
import { AlertBadge } from "./AlertBadge";
import { AccountManager } from "common/managers/AccountManager";
import { InstallAppBanner } from "main/components/InstallAppBanner";

type RadiusHeaderProps = {
	topSearchOpen: boolean;
	set_topSearchOpen: Dispatch<React.SetStateAction<boolean>>;
	leftNavOpen: boolean;
	set_leftNavOpen: Dispatch<React.SetStateAction<boolean>>;
	rightAccountOpen: boolean;
	set_rightAccountOpen: Dispatch<React.SetStateAction<boolean>>;
	rightAlertsOpen: boolean;
	set_rightAlertsOpen: Dispatch<React.SetStateAction<boolean>>;
};

function RadiusHeader({
	topSearchOpen,
	set_topSearchOpen,
	leftNavOpen,
	set_leftNavOpen,
	rightAccountOpen,
	set_rightAccountOpen,
	rightAlertsOpen,
	set_rightAlertsOpen,
}: RadiusHeaderProps) {
	let alertOn = useRef(false);
	useMemo(() => {
		if (AccountManager.userDetails?.reminders) {
			// console.log(AccountManager.userDetails?.reminders);
			alertOn.current = false;
			AccountManager.userDetails.reminders.forEach((reminder, i) => {
				if (reminder.isAlert) {
					alertOn.current = true;
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AccountManager.userDetails]);
	const toggleTopSearchDrawer = () => {
		set_topSearchOpen(!topSearchOpen);
	};
	const toggleLeftNavDrawer = () => {
		if (!leftNavOpen) {
			closeSearchIfOpen();
		}
		set_leftNavOpen(!leftNavOpen);
	};
	const toggleRightAccountDrawer = () => {
		if (rightAccountOpen) {
			set_rightAccountOpen(false);
		} else {
			closeSearchIfOpen();
			set_rightAccountOpen(true);
			set_rightAlertsOpen(false);
		}
	};
	const toggleRightAlertsDrawer = () => {
		if (rightAlertsOpen) {
			set_rightAlertsOpen(false);
		} else {
			closeSearchIfOpen();
			set_rightAlertsOpen(true);
			set_rightAccountOpen(false);
		}
	};
	const closeSearchIfOpen = () => {
		if (topSearchOpen) {
			set_topSearchOpen(false);
		}
	};

	return (
		<>
			<InstallAppBanner />
			<Stack
				alignItems="center"
				justifyContent="space-between"
				flexDirection={"column"}
				data-component="Header"
				sx={{
					position: "sticky",
					top: "0",
					left: "0",
					width: "100%",
					boxSizing: "border-box",
					height: style.headerHeight,
					background: "#ffffff",
					boxShadow: 4,
					zIndex: 10,
				}}
			>
				<Stack
					alignItems="center"
					justifyContent="space-between"
					flexDirection={"row"}
					// data-component="Header"
					sx={{
						width: "100%",
						boxSizing: "border-box",
						height: style.headerHeight,
						background: "#ffffff",
					}}
				>
					<Stack
						data-id="menu_search_box"
						flexDirection="row"
						justifyContent="space-evenly"
						alignItems="center"
						sx={{ flexGrow: 1, padding: 0, maxWidth: 160, minWidth: 80 }}
					>
						<MenuBadge onClick={toggleLeftNavDrawer} active={leftNavOpen} />
						<SearchBadge onClick={toggleTopSearchDrawer} active={topSearchOpen} />
					</Stack>

					<Box
						component={"img"}
						alt="radius care logo"
						src={"/images/radius-care-logo.svg"}
						sx={{
							[theme.breakpoints.down("sm")]: {
								height: "2.5rem",
							},
							[theme.breakpoints.up("sm")]: {
								height: "3.5rem",
							},
						}}
					></Box>

					<Stack
						data-id="account_alert_box"
						flexDirection="row"
						justifyContent="space-evenly"
						alignItems="center"
						sx={{ flexGrow: 1, padding: 0, maxWidth: 160, minWidth: 80 }}
					>
						<AlertBadge alert={alertOn.current} onClick={toggleRightAlertsDrawer} active={rightAlertsOpen} />
						<ProfileBadge onClick={toggleRightAccountDrawer} active={rightAccountOpen} />
					</Stack>
				</Stack>
			</Stack>
		</>
	);
}

export { RadiusHeader };
