import { Box, Stack } from "@mui/material";
import { AlertDescription } from "common/definitions/AlertDefinitions";
import { UseQueryResult } from "react-query";
import { ProcessedResponse } from "common/utilities/apiResponseReader";
import { ConsumerRetrievePatientHealthDataResponseModel_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { KeyFindingsTileX } from "common/components/Tiles/KeyFindingsTile";
import { useMemo, ReactNode } from "react";
import { CONVERT_DoctorRecord_Entity, Entity } from "common/definitions/EntityDefinitions";
import { dateConverter } from "common/utilities/dateConverter";
import { ConsumerKeyFindingsResponseModel, ModelsExplanationOfBenefitTreatmentAssessment } from "api";
import { AchievementTile } from "common/components/Tiles/AchievementTile";
import { AchievementType } from "common/definitions/EventDefinitions";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { MedicationTile } from "common/components/Tiles/MedicationTile";
import { DialogManager } from "common/managers/DialogManager";
import { DoctorTile } from "common/components/Tiles/DoctorTile";
import { ConditionSearchTile } from "common/components/Tiles/ConditionSearchTile";
import { renderOptionsButton } from "common/components/Tiles/common/TilesCommon";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { ModelsExplanationOfBenefitDiagnosisAssessment_Extended } from "../../../../api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { useKeyFindings } from "common/requests/patient_data/X_getKeyFindings";
import { AccountManager } from "common/managers/AccountManager";
import { ErrorDisplay } from "common/components/DisplayElements/ErrorDisplay";

type KeyFindingsProps = {
	patientEOBData: UseQueryResult<ProcessedResponse<ConsumerRetrievePatientHealthDataResponseModel_Extended | null>, unknown>;
	keyFindings: ConsumerKeyFindingsResponseModel | null;
};
const KeyFindings = (props: KeyFindingsProps) => {
	/*
██████  ███████  ██████ ███████ ███    ██ ████████     ██████  ██  █████   ██████  ███    ██  ██████  ███████ ██ ███████ 
██   ██ ██      ██      ██      ████   ██    ██        ██   ██ ██ ██   ██ ██       ████   ██ ██    ██ ██      ██ ██      
██████  █████   ██      █████   ██ ██  ██    ██        ██   ██ ██ ███████ ██   ███ ██ ██  ██ ██    ██ ███████ ██ ███████ 
██   ██ ██      ██      ██      ██  ██ ██    ██        ██   ██ ██ ██   ██ ██    ██ ██  ██ ██ ██    ██      ██ ██      ██ 
██   ██ ███████  ██████ ███████ ██   ████    ██        ██████  ██ ██   ██  ██████  ██   ████  ██████  ███████ ██ ███████
*/
	const consumerUserEmail = AccountManager.userDetails?.consumerUser?.email;
	const keyFindingsQueryResult = useKeyFindings(consumerUserEmail);
	// console.log("Key findings from props", props.keyFindings);
	const keyFindings = useMemo(() => {
		if (props.keyFindings === null) {
			return keyFindingsQueryResult.data?.data;
		} else {
			return props.keyFindings;
		}
	}, [keyFindingsQueryResult, props.keyFindings]);

	const mostRecentDiagnosis: Array<ModelsExplanationOfBenefitDiagnosisAssessment_Extended> | null = useMemo(() => {
		if (keyFindings?.mostRecentDiagnosis?.subItems?.length) {
			return keyFindings?.mostRecentDiagnosis.subItems.map((x: ModelsExplanationOfBenefitDiagnosisAssessment_Extended) => {
				x.ICD10Definition = StaticDataManager.retrieveValue.icd10cm(x.diagnosisCode);
				return x;
			});
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyFindings?.mostRecentDiagnosis]);
	/*
██████  ███████  ██████ ███████ ███    ██ ████████     ███    ███ ███████ ██████  ███████ 
██   ██ ██      ██      ██      ████   ██    ██        ████  ████ ██      ██   ██ ██      
██████  █████   ██      █████   ██ ██  ██    ██        ██ ████ ██ █████   ██   ██ ███████ 
██   ██ ██      ██      ██      ██  ██ ██    ██        ██  ██  ██ ██      ██   ██      ██ 
██   ██ ███████  ██████ ███████ ██   ████    ██        ██      ██ ███████ ██████  ███████
*/
	const mostRecentMedication = useMemo(() => {
		if (keyFindings?.mostRecentMedication?.subItems?.length) {
			return keyFindings.mostRecentMedication.subItems.map((x: ModelsExplanationOfBenefitTreatmentAssessment) => {
				// x.ICD10Definition = StaticDataManager.retrieveValue.icd10cm(x.diagnosisCode);
				DynamicDataManager.ndcs.drugData_NDCMap.get(x.code);
				return x;
			});
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyFindings?.mostRecentMedication]);

	/*
██████  ███████  ██████        ███████  ██████ ██████  ███████ ███████ ███    ██ ██ ███    ██  ██████  ███████ 
██   ██ ██      ██             ██      ██      ██   ██ ██      ██      ████   ██ ██ ████   ██ ██       ██      
██████  █████   ██             ███████ ██      ██████  █████   █████   ██ ██  ██ ██ ██ ██  ██ ██   ███ ███████ 
██   ██ ██      ██                  ██ ██      ██   ██ ██      ██      ██  ██ ██ ██ ██  ██ ██ ██    ██      ██ 
██   ██ ███████  ██████ ██     ███████  ██████ ██   ██ ███████ ███████ ██   ████ ██ ██   ████  ██████  ███████
// All uncompleted recommended screenings
*/
	const recommendedScreenings = useMemo(() => {
		if (keyFindings?.recommendedScreenings?.subItems?.length) {
			let detailsSection = keyFindings.recommendedScreenings.subItems.map((uspstf, i) => {
				return (
					<Box key={i} sx={{ paddingBottom: ".5rem" }}>
						<AchievementTile
							key={uspstf.id}
							achievementType={uspstf.inferredType as AchievementType}
							title={(uspstf as CIM).markupTitle ?? ""}
							completed={uspstf.userHasCompleted ?? false}
							uspstf={uspstf}
						/>
					</Box>
				);
			});
			return {
				type: "screening",
				title: "Recommended Screenings",
				value: keyFindings.recommendedScreenings.count,
				detailsSection: detailsSection,
			} as AlertDescription;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyFindings?.recommendedScreenings]);
	/*
██████  ███████  ██████        ██    ██  █████   ██████  ██████ ██ ███    ██ ███████ ███████ 
██   ██ ██      ██             ██    ██ ██   ██ ██      ██      ██ ████   ██ ██      ██      
██████  █████   ██             ██    ██ ███████ ██      ██      ██ ██ ██  ██ █████   ███████ 
██   ██ ██      ██              ██  ██  ██   ██ ██      ██      ██ ██  ██ ██ ██           ██ 
██   ██ ███████  ██████ ██       ████   ██   ██  ██████  ██████ ██ ██   ████ ███████ ███████ 
// All uncompleted recommended vaccines
*/
	const recommendedVaccinations = useMemo(() => {
		if (keyFindings?.recommendedVaccinations?.subItems?.length) {
			let detailsSection = keyFindings.recommendedVaccinations.subItems.map((uspstf, i) => {
				return (
					<Box key={i} sx={{ paddingBottom: ".5rem" }}>
						<AchievementTile
							key={uspstf.id}
							achievementType={uspstf.inferredType as AchievementType}
							title={(uspstf as CIM).markupTitle ?? ""}
							completed={uspstf.userHasCompleted ?? false}
							uspstf={uspstf}
						/>
					</Box>
				);
			});
			return {
				type: "vaccination",
				title: "Recommended Vaccinations",
				value: keyFindings.recommendedVaccinations.count,
				detailsSection: detailsSection,
			} as AlertDescription;
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyFindings?.recommendedScreenings]);
	/*
███    ██ ███████ ██     ██     ██████   ██████   ██████ ████████  ██████  ██████  ███████ 
████   ██ ██      ██     ██     ██   ██ ██    ██ ██         ██    ██    ██ ██   ██ ██      
██ ██  ██ █████   ██  █  ██     ██   ██ ██    ██ ██         ██    ██    ██ ██████  ███████ 
██  ██ ██ ██      ██ ███ ██     ██   ██ ██    ██ ██         ██    ██    ██ ██   ██      ██ 
██   ████ ███████  ███ ███      ██████   ██████   ██████    ██     ██████  ██   ██ ███████
*/
	const newAreaDoctors = useMemo(() => {
		if (keyFindings?.newAreaDoctors?.providersByClassification) {
			let newDocsByType = keyFindings.newAreaDoctors.providersByClassification;
			let expandedContent: Array<ReactNode> = [];
			Object.keys(newDocsByType).forEach((key) => {
				if (newDocsByType[key].length) {
					let typeSectionTiles: Array<ReactNode> = [];
					newDocsByType[key]
						.sort((a: CIM, b: CIM) => {
							a = a.enumerationDate;
							b = b.enumerationDate;
							return a > b ? -1 : b > a ? 1 : 0;
						})
						.forEach((prov, i) => {
							let ent = CONVERT_DoctorRecord_Entity(prov, "AUTO");
							ent.descriptor = "";
							if (prov.enumerationDate) {
								ent.displayInfo.push({ title: "Added On", value: dateConverter["YYYY-MM-DD_to_mM/dD/YY"](prov.enumerationDate) });
							}
							typeSectionTiles.push(
								<Box key={i} sx={{ paddingBottom: ".5rem" }}>
									<DoctorTile key={i} Entity={ent} />
								</Box>
							);
						});
					expandedContent.push(<Box key={key}>{typeSectionTiles}</Box>);
				}
			});
			if (expandedContent.length) {
				return (
					<KeyFindingsTileX
						key="newAreaDoctors"
						alertDescription={{
							title: "New Area Doctors",
							type: "diagnosis",
							value: keyFindings.newAreaDoctors.count ?? "?",
						}}
						detailsSection={<>{expandedContent}</>}
					/>
				);
			}
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyFindings?.newAreaDoctors?.providersByClassification]);

	const keyFindingsIsEmpty = useMemo(() => {
		if (newAreaDoctors || recommendedVaccinations || recommendedScreenings || mostRecentMedication || mostRecentDiagnosis) {
			return false;
		}
		return true;
	}, [newAreaDoctors, recommendedVaccinations, recommendedScreenings, mostRecentMedication, mostRecentDiagnosis]);
	/*
██████  ███████ ███    ██ ██████  ███████ ██████  
██   ██ ██      ████   ██ ██   ██ ██      ██   ██ 
██████  █████   ██ ██  ██ ██   ██ █████   ██████  
██   ██ ██      ██  ██ ██ ██   ██ ██      ██   ██ 
██   ██ ███████ ██   ████ ██████  ███████ ██   ██ 
*/
	return (
		<Box sx={{ width: "100%" }}>
			<QueryStatusDisplay queryResult={keyFindingsQueryResult} showAsLoading={KeyFindings === null}>
				<Stack sx={{ width: "100%" }}>
					{mostRecentDiagnosis ? (
						<KeyFindingsTileX
							alertDescription={{
								title: "Most Recent Diagnosis",
								type: "diagnosis",
								value: mostRecentDiagnosis.length,
							}}
							detailsSection={
								<>
									{mostRecentDiagnosis.map((x, i) => {
										try {
											let ent: Entity = {
												entityTypeName: "condition",
												imageSrc: "",
												title: x.ICD10Definition?.Description ?? x.diagnosisCode,
												descriptor: "",
												displayInfo: [],
												_data: x,
											};
											return (
												<Box key={i} sx={{ paddingBottom: ".5rem" }}>
													<ConditionSearchTile
														key={ent._data.diagnosisCode}
														Entity={ent}
														content={renderOptionsButton("View Details", () => {
															DialogManager.open("diagnosis-details", ent._data.diagnosisCode ?? "MISSING CODE");
														})}
													/>
												</Box>
											);
										} catch (er) {
											console.debug("Caught Failure", x);
										}
										return null;
									})}
								</>
							}
						/>
					) : null}
					{mostRecentMedication ? (
						<KeyFindingsTileX
							alertDescription={{
								title: "Most Recent Medication",
								type: "medication",
								value: mostRecentMedication.length,
							}}
							detailsSection={
								<>
									{mostRecentMedication.map((x, i) => {
										try {
											let ent: Entity = {
												entityTypeName: "medication",
												imageSrc: "",
												title: (x as CIM)?._ndc_data?.name ?? x.code,
												descriptor: "",
												displayInfo: [],
												_data: x,
											};
											return (
												<Box key={i} sx={{ paddingBottom: ".5rem" }}>
													<MedicationTile
														key={i}
														Entity={ent}
														content={renderOptionsButton("View Details", () => {
															DialogManager.open("medication-details", ent._data.code ?? "MISSING CODE");
														})}
													/>
												</Box>
											);
										} catch (er) {
											console.debug("Caught Failure", x);
										}
										return null;
									})}
								</>
							}
						/>
					) : null}
					{recommendedScreenings ? (
						<KeyFindingsTileX
							key={"recommendedScreenings"}
							alertDescription={recommendedScreenings}
							detailsSection={recommendedScreenings.detailsSection}
						/>
					) : null}
					{recommendedVaccinations ? (
						<KeyFindingsTileX
							key={"recommendedVaccinations"}
							alertDescription={recommendedVaccinations}
							detailsSection={recommendedVaccinations.detailsSection}
						/>
					) : null}
					{newAreaDoctors}
					{keyFindingsIsEmpty ? (
						props.patientEOBData?.data?.errorMessage ? (
							<QueryStatusDisplay queryResult={props.patientEOBData} />
						) : (
							<ErrorDisplay errorType={"NEUTRAL"} errorMessage={"No Key Findings"} />
						)
					) : null}
				</Stack>
			</QueryStatusDisplay>
		</Box>
	);
};

export { KeyFindings };
