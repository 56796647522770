import { AdaptiveMenu } from "common/components/Menus/AdaptiveMenu";
import { Box } from "@mui/material";
import { NavigationItemHorizontal } from "main/navigation/NavigationItemHorizontal";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";

const ManageAccountMenu = () => {
	return (
		<Box>
			<AdaptiveMenu id="ManageAccountMenu">
				<NavigationItemHorizontal label="Profile" icon={<HealthIcon iconSVG={health_icons_svg.person} />} to="/account/profile" />
				<NavigationItemHorizontal
					label="Care Collaborations"
					icon={<HealthIcon iconSVG={health_icons_svg.group_discussion_meeting_x3} />}
					to="/account/carecollaborators"
				/>
			</AdaptiveMenu>
		</Box>
	);
};

export { ManageAccountMenu };
