import { Typography } from "@mui/material";
import { palette } from "theme";

const SectionLabel = ({ children }: React.PropsWithChildren<{}>) => (
	<Typography
		sx={{
			backgroundColor: palette.gray.main,
			padding: ".6rem 1.4rem .6rem 1.4rem",
			margin: 0,
			textAlign: "left",
			fontWeight: 600,
			fontSize: "1rem",
			color: palette.gray.darker,
			textTransform: "uppercase",
		}}
	>
		{children}
	</Typography>
);
// ... import not working on dev build
export { SectionLabel };
