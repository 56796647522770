import { FormError } from "./FormAlerts";

interface FormUtilities {
	formValuesReducer: <Type>(state: Type, action: Partial<Type>) => Type;
	combineValidationResponses: <Type>(fvrs: Array<GenericFormValidationResponse>) => FormValidationResponse<Type>;
}

// interface TypedFormValidationResponse<T1> {
// 	isValid: boolean;
// 	values?: T1;
// 	errors?: FormErrors;
// }

interface FormValidationResponse<T1> {
	isValid: boolean;
	values?: T1;
	errors?: FormErrors;
}

interface GenericFormValidationResponse extends FormValidationResponse<{ [key: string]: any }> {}

const formUtilities: FormUtilities = {
	formValuesReducer: <Type>(state: Type, action: Partial<Type>) => {
		/*
		Add like this in useReducer for typescript/babel
		(x: FormValues, action: Partial<FormValues>) => {
			return formUtilities.formValuesReducer<FormValues>(x, action);
		}
		*/
		return Object.assign({}, state, action);
	},

	combineValidationResponses: <Type>(fvrs: Array<GenericFormValidationResponse>) => {
		let combinedResponse = { isValid: true, errors: {}, values: {} };
		fvrs.forEach((fvr: GenericFormValidationResponse) => {
			if (!fvr.isValid) {
				combinedResponse.isValid = false;
			}
			Object.assign(combinedResponse.errors, fvr.errors);
			Object.assign(combinedResponse.values, fvr.values);
		});
		return combinedResponse as FormValidationResponse<Type>;
	},
};

export { formUtilities };

interface FormErrorX extends FormError {
	type?: string;
}
type FormErrors = { [key: string]: FormErrorX };

export type { FormUtilities, FormErrors, FormValidationResponse, GenericFormValidationResponse, FormErrorX };
