import { Box, Button, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { RadiusSquareLogo } from "common/components/Logo/RadiusSquareLogo";
import { DeviceContext } from "common/context/DeviceContext";
import { StyleContext } from "common/context/StyleContext";
import { useContext } from "react";
import { palette } from "theme";

function InstallAppBanner() {
	const deviceContext = useContext(DeviceContext);
	const { screenWidth } = useContext(StyleContext);
	if (!deviceContext.installAppBannerIsOpen) {
		return null;
	}
	return (
		<Box
			style={{
				backgroundColor: palette.gray.main,
				top: "0",
				left: "0",
				margin: 0,
				zIndex: 100,
				width: "100%",
				height: deviceContext.installAppBannerHeight + "px",
				minHeight: deviceContext.installAppBannerHeight + "px",
				maxHeight: deviceContext.installAppBannerHeight + "px",
				display: "flex",
				flexDirection: "row",
				justifyContent: "space-evenly",
				alignItems: "stretch",
				overflow: "hidden",
			}}
		>
			<Box
				data-name="close"
				style={{
					flexGrow: 0,
					width: "48px",
					maxWidth: "48px",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<CloseButton onClick={deviceContext.closeInstallAppBanner} styleType="simple" />
				</Box>
			</Box>
			<Box
				style={{
					flexGrow: 1,
					display: "flex",
					flexDirection: "row",
					justifyContent: "flex-start",
					alignItems: "center",
				}}
			>
				{screenWidth > 300 ? <RadiusSquareLogo /> : null}
				<Box
					data-name="title"
					style={{
						flexGrow: 1,
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-start",
						alignItems: "flex-start",
						padding: "8px",
					}}
				>
					{screenWidth > 450 ? (
						<Typography sx={{ color: palette.core.blue, fontWeight: "1000", fontSize: "1.5rem" }}>
							Radius Care{screenWidth > 625 ? <Box component="span">: Consumer App</Box> : null}
						</Typography>
					) : null}
					<Typography sx={{ color: palette.gray.dark, fontWeight: "500", fontSize: "1rem" }}>
						Get the{screenWidth > 375 ? <Box component="span"> Radius Care </Box> : null} App
						{screenWidth > 715 ? <Box component="span"> now for your mobile device</Box> : null}
					</Typography>
				</Box>
			</Box>
			<Box
				data-name="install"
				style={{
					flexGrow: 0,
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					padding: "8px",
				}}
			>
				<Button
					sx={{ borderRadius: "8px", padding: "8px", fontSize: "1.5em", minWidth: screenWidth > 375 ? "6rem" : "4rem" }}
					onClick={deviceContext.activateAppLink}
				>
					INSTALL
				</Button>
			</Box>
		</Box>
	);
}

export { InstallAppBanner };
