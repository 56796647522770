import { Card, CardContent } from "@mui/material";
import FormContainer from "../../../forms/FormContainer";
import { ProgressDisplay } from "common/components/StepProgress/ProgressDisplay";
import { useEffect, useState } from "react";
import { connectMedicareAccount } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { useNavigate } from "react-router-dom";
// import { retrieveMedicareData } from "../../../../api/consumer-users/consumer-users";
import { local_origin } from "config";
// import { SimpleModal } from "common/components/Modals/SimpleModal";
import { ModelsOperationStatus } from "../../../../api/backend.schemas";
import { AccountActive } from "../_common/AccountActive";

function MedicareReceive({ searchParams }: { searchParams: URLSearchParams }) {
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<null | string>(null);
	// const [demoModalContent, set_demoModalContent] = useState<boolean | string>(false);

	const [connectionResponse, set_connectionResponse] = useState<null | ModelsOperationStatus>(null);

	const navigate = useNavigate();

	let state = searchParams.get("state");
	let code = searchParams.get("code");

	const connectMedicare = async () => {
		let email_ = AccountManager.username;
		// Getting the email_ from the B64 'state' value will work but the API is set up (intentionally) to require being logged in. So it will fail on the backend.
		// comment:  if (!email_ && state) {
		// comment:    email_ = base64Converter.b64DecodeUnicode(state);
		// comment:    email_ = email_.substring(0, email_.lastIndexOf(":"));
		// comment:  }

		if (code && state && email_) {
			let last_sent_state = localStorage.getItem("MedicareLastAuthenticationState");
			if (last_sent_state === state && false) {
				// code can only be used once - track and skip if its already been sent to avoid error - could also be checked on back end
				// makeDataRequest(email_);
			} else {
				console.debug("authRequest", {
					code,
					state,
					email: email_,
					origin: await local_origin(),
				});
				localStorage.setItem("MedicareLastAuthenticationState", state);
				let authResult = apiResponseReader.processResponse(
					await connectMedicareAccount({
						code,
						state,
						email: email_,
						origin: await local_origin(),
					})
				);
				console.debug("connectMedicareAccount Response:", authResult);
				set_connectionResponse(authResult.data);
				if (authResult.success && authResult.data && authResult.data.success) {
					setProgress(25);
					setTimeout(() => {
						setProgress(51);
						setTimeout(() => {
							setProgress(90);
							setTimeout(() => {
								setProgress(100);
								setTimeout(() => {
									AccountManager.setUser("REFRESH");
									navigate("/dashboard/myoverview");
								}, 500);
							}, 500);
						}, 1000);
					}, 1000);
				} else {
					if (authResult?.data?.errorMessage) {
						setError(authResult.data.errorMessage);
					}
				}
			}
		}
	};

	// const makeDataRequest = (email: string) => {
	// 	setProgress(51);
	// 	setTimeout(async () => {
	// 		console.debug("retrieveMedicareData request", { email, origin: await local_origin() });
	// 		let dataResult = apiResponseReader.processResponse(await retrieveMedicareData({ email, origin: await local_origin() }));
	// 		if (dataResult.success) {
	// 			console.debug("retrieveMedicareData Response:", dataResult);
	// 			let data: any = dataResult.data;
	// 			// displayMakeRequestResult(data, set_demoModalContent);

	// 			setProgress(100);
	// 			// setTimeout(() => {
	// 			//   navigate("/dashboard");
	// 			// }, 2000);
	// 		} else {
	// 			setError(dataResult.errorMessage);
	// 		}
	// 	}, 1000);
	// };

	useEffect(() => {
		connectMedicare();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, state]);

	return (
		<FormContainer noTopAndBottom>
			{apiResponseReader.typeCheck_ModelsOperationStatus(connectionResponse) && !connectionResponse.success ? (
				<AccountActive useAccountPageWrapper={false} />
			) : (
				<Card
					sx={{
						width: "100%",
						marginTop: "1rem",
						boxSizing: "border-box",
					}}
				>
					<CardContent>
						<ProgressDisplay
							progress={progress}
							type={"onboarding"}
							error={error}
							failureDisplay={<AccountActive useAccountPageWrapper={false} />}
						/>
					</CardContent>
				</Card>
			)}
		</FormContainer>
	);
}

export { MedicareReceive };

///
// function displayMakeRequestResult(response: any, setter?: (x: string) => any) {
// 	console.debug("displayMakeRequestResult");
// 	console.debug(response);
// 	if (response.success && response.data) {
// 		try {
// 			console.debug(response.data.eob);
// 			console.debug("EOB", JSON.parse(response.data.eob));
// 			if (setter) {
// 				console.log("setter");
// 				setter(JSON.stringify(JSON.parse(response.data.eob), null, 2));
// 			}
// 		} catch (e) {
// 			console.debug("failed to parse json", e);
// 		}
// 	}
// 	if (response.data.coverage) {
// 		try {
// 			console.debug("COVERAGE", JSON.parse(response.data.coverage));
// 		} catch (e) {
// 			console.debug("failed to parse json", e);
// 		}
// 	}
// 	if (response.data.patient) {
// 		try {
// 			console.debug("PATIENT", JSON.parse(response.data.patient));
// 		} catch (e) {
// 			console.debug("failed to parse json", e);
// 		}
// 	}
// }
