import { InputAdornment, Typography } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import { ReactNode } from "react";
import { StandardTextField } from "./StandardTextField";

type Multiple = boolean | undefined;
type DisableClearable = boolean | undefined;
type FreeSolo = boolean | undefined;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface StandardAutoCompleteProps<T, T2, T3, T4> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, "renderInput"> {
	icon?: ReactNode;
}

const StandardAutocomplete = <T, Multiple, DisableClearable, FreeSolo>(props: StandardAutoCompleteProps<T, Multiple, DisableClearable, FreeSolo>) => {
	return (
		<Autocomplete
			sx={{
				"& .MuiInputBase-root": {
					padding: "4px 8px 5px 8px",
				},
				"& .MuiAutocomplete-tag.MuiChip-root:nth-of-type(2)": {
					//The pseudo class ":nth-child" is potentially unsafe when doing server-side rendering. Try changing it to ":nth-of-type"
					maxWidth: props.icon ? "calc(100% - 40px)" : undefined, // prevent first tag from overlapping icon if present
				},
				...props.sx,
			}}
			{...props}
			renderOption={(a, b) => {
				let style: any = {};
				if (
					(props.value && Array.isArray(props.value) && props.value.indexOf(typeof b === "string" ? b : (b as unknown as any)?.value) !== -1) ||
					props.value === b
				) {
					style = {};
				}
				let label: string = typeof b === "string" ? b : typeof b === "object" && b && (b as CIM).value ? (b as CIM).value : b;
				return (
					<Typography {...a} style={style}>
						{label}
					</Typography>
				);
			}}
			renderInput={(params) => (
				<StandardTextField
					variant="standard"
					{...params}
					label=""
					// placeholder={props.placeholder ? props.placeholder : undefined}
					InputProps={{
						...params.InputProps,
						startAdornment: [
							props.icon ? (
								<InputAdornment position="start" key="InsertedIcon">
									{props.icon}
								</InputAdornment>
							) : null,
							params.InputProps.startAdornment, // non standard inserting icon before this -- seems to work perfectly so far
						],
					}}
				/>
			)}
		/>
	);
};

export { StandardAutocomplete };
