import { ReactNode } from "react";
import { ButtonBase, Typography, Stack, Box } from "@mui/material";
import { Link, useLocation, useMatch } from "react-router-dom";
import { theme } from "theme";

interface NavigationItemHorizontalProps {
	label: string;
	icon: ReactNode;
	to: string;
	additionalMatches?: Array<string>;
	onClick?: () => any;
	isActive?: boolean;
}

function NavigationItemHorizontal({ label, icon, to, additionalMatches, onClick, isActive }: NavigationItemHorizontalProps) {
	const location = useLocation();
	const partialPathname = location.pathname.split("/")[1];
	let match: any = useMatch({
		path: to,
	});
	if (!match && additionalMatches !== undefined) {
		match = additionalMatches.some((x) => x === partialPathname);
	}

	return (
		<ButtonBase
			component={onClick ? Box : Link}
			to={to}
			className={match || isActive ? "active" : undefined}
			onClick={onClick}
			sx={{
				position: "relative",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				fontSize: "1.25rem",
				color: "gray.dark",
				borderBottom: "solid 2px",
				padding: "1rem",
				margin: "0 .5rem 1rem .5rem",
				borderColor: "transparent",
				[theme.breakpoints.down("sm")]: {
					minWidth: "4rem",
				},
				[theme.breakpoints.up("sm")]: {
					minWidth: "13rem",
				},
				"&:hover": {
					borderColor: "core.blue",
					color: "core.blue",
				},
				"&.active, &.active:hover": {
					borderColor: "core.blue",
					color: "core.blue",
				},
				"& .MuiTypography-root": {
					[theme.breakpoints.down("sm")]: {
						display: "none",
					},
				},
			}}
		>
			<Stack justifyContent="center" alignItems="center" sx={{ flexDirection: { xs: "column", sm: "row" } }}>
				{icon}
				<Typography
					component="div"
					sx={{
						fontSize: "1rem",
						lineHeight: "1.25rem",
						whiteSpace: "nowrap",
						margin: "0 0 0 .5rem",
					}}
				>
					{label}
				</Typography>
			</Stack>
		</ButtonBase>
	);
}

export { NavigationItemHorizontal };
