/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  AdminGetPatientRiskModelScoresResult,
  ProvidersRiskModelFilterCriteria,
  AdminGetPaginatedMembersListResult,
  AdminGetPaginatedMembersListRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const getPatientRiskModelScores = (
    providersRiskModelFilterCriteria: ProvidersRiskModelFilterCriteria,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<AdminGetPatientRiskModelScoresResult>(
      {url: `/api/admin/RiskModel/GetPatientRiskModelScores`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: providersRiskModelFilterCriteria
    },
      options);
    }
  


    export type GetPatientRiskModelScoresMutationResult = NonNullable<Awaited<ReturnType<typeof getPatientRiskModelScores>>>
    export type GetPatientRiskModelScoresMutationBody = ProvidersRiskModelFilterCriteria
    export type GetPatientRiskModelScoresMutationError = unknown

    export const useGetPatientRiskModelScores = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPatientRiskModelScores>>, TError,{data: ProvidersRiskModelFilterCriteria}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPatientRiskModelScores>>, {data: ProvidersRiskModelFilterCriteria}> = (props) => {
          const {data} = props ?? {};

          return  getPatientRiskModelScores(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getPatientRiskModelScores>>, TError, {data: ProvidersRiskModelFilterCriteria}, TContext>(mutationFn, mutationOptions)
    }
    export const getPaginatedMembersList = (
    adminGetPaginatedMembersListRequest: AdminGetPaginatedMembersListRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<AdminGetPaginatedMembersListResult>(
      {url: `/api/admin/RiskModel/GetPaginatedMembersList`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: adminGetPaginatedMembersListRequest
    },
      options);
    }
  


    export type GetPaginatedMembersListMutationResult = NonNullable<Awaited<ReturnType<typeof getPaginatedMembersList>>>
    export type GetPaginatedMembersListMutationBody = AdminGetPaginatedMembersListRequest
    export type GetPaginatedMembersListMutationError = unknown

    export const useGetPaginatedMembersList = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPaginatedMembersList>>, TError,{data: AdminGetPaginatedMembersListRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPaginatedMembersList>>, {data: AdminGetPaginatedMembersListRequest}> = (props) => {
          const {data} = props ?? {};

          return  getPaginatedMembersList(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof getPaginatedMembersList>>, TError, {data: AdminGetPaginatedMembersListRequest}, TContext>(mutationFn, mutationOptions)
    }
    