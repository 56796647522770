const rangePercentConverter = {
  mapPercentToRange: (percent: number, min?: number, max?: number) => {
    let rangedValue = percent;
    if (min !== undefined && max !== undefined) {
      let size = max - min;
      rangedValue = (size * percent) / 100 + min;
    }
    return rangedValue;
  },
  mapRangeValueToPercent: (value: number, min: number, max: number) => {
    let size = max - min;
    value = value - min;
    return (value / size) * 100;
  },
  toAdjustedRange(val1: number, rangeSize1: number, rangeSize2: number) {
    let percent = val1 / rangeSize1;
    return rangeSize2 * percent;
  },
};

export { rangePercentConverter };
