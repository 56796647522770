import { Box, MenuItem, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { StandardOption } from "common/types/generic";
import { StaticDataDefinitionsContext } from "common/context/MainContext";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { ModelsICD10Range } from "api";

type FindCareConditionFiltersProps = {
	conditionType: StandardOption<string, string>;
	set_conditionType: (option: StandardOption<string, string>) => void;
};

const FindCareConditionFilters = ({ conditionType, set_conditionType }: FindCareConditionFiltersProps) => {
	const SDDC = useContext(StaticDataDefinitionsContext);
	const conditionRangeOptions = useMemo(() => {
		let parent_ranges = SDDC.ICD10CM_Package.range_categories.filter((x) => {
			return x.sub_ranges.length > 0;
		});
		let numbered_ranges: Array<ModelsICD10Range> = [];
		let named_ranges: Array<ModelsICD10Range> = [];
		for (let i = 0; i < parent_ranges.length; i++) {
			let index = parent_ranges[i].description.indexOf(".");
			if (index !== -1 && index < 4) {
				numbered_ranges.push(parent_ranges[i]);
			} else {
				named_ranges.push(parent_ranges[i]);
			}
		}

		numbered_ranges.sort((a, b) => {
			let aN = parseInt(a.description.split(".")[0]);
			let bN = parseInt(b.description.split(".")[0]);
			return aN > bN ? 1 : aN < bN ? -1 : 0;
		});

		return [...numbered_ranges, ...named_ranges];
	}, [SDDC]);

	return (
		<Box style={{ paddingBottom: "1rem" }}>
			<Typography id="condition-select-label" variant="h5" marginBottom={".5rem"}>
				Condition Type
			</Typography>
			<StandardSelect
				labelId="condition-select-label"
				id="condition-select"
				variant="standard"
				value={conditionType.value}
				onChange={(x) => {
					let rangeCat = SDDC.ICD10CM_Package.range_category_map.get(x.target.value as string);
					if (rangeCat) {
						set_conditionType({ name: rangeCat.range, value: rangeCat.range });
					}
				}}
			>
				<MenuItem
					sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }}
					value={"All"}
					data-rangecategory={"All"}
				>
					{"All Conditions"}
				</MenuItem>
				{conditionRangeOptions.map((c, i) => {
					return (
						<MenuItem
							sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }}
							key={i}
							value={c.range}
							data-rangecategory={c}
						>
							{c.description}
						</MenuItem>
					);
				})}
			</StandardSelect>
		</Box>
	);
};

export { FindCareConditionFilters };
