import { Stack, SxProps } from "@mui/material";

const Loader = ({ sx, size }: { sx?: SxProps; size?: "small" }) => {
	let sxProp = {
		flexBasis: 1,
		flexGrow: 1,
		padding: "2rem",
		"& > img": { width: "6rem", height: "6rem" },
	};

	if (sx) {
		sxProp = Object.assign(sxProp, sx);
	}

	if (size) {
		if (size === "small") {
			sxProp = Object.assign(sxProp, { "& > img": { width: "3rem", height: "3rem" } });
		}
	}

	return (
		<Stack data-componentname="Loader" justifyContent={"center"} alignItems={"center"} sx={sxProp}>
			<img alt="radius care logo" src={"/images/radius-loading-indicator.svg"} />
		</Stack>
	);
};

export { Loader };
