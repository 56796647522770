import { Card, CardContent } from "@mui/material";
import FormContainer from "../../../forms/FormContainer";
import { ProgressDisplay } from "common/components/StepProgress/ProgressDisplay";
import { useEffect, useState } from "react";
import { connectHorizonBlueAccount, getUserDetails, ModelsOperationStatus } from "api";
import { AccountManager } from "common/managers/AccountManager";
import { apiResponseReader } from "common/utilities/apiResponseReader";
import { useNavigate } from "react-router-dom";
import { local_origin } from "config";
import { AccountActive } from "../_common/AccountActive";
// import PartialAccountActive from "../PartialAccountActive";

function HorizonBlueReceive({ searchParams }: { searchParams: URLSearchParams }) {
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<null | string>(null);
	const [connectionResponse, set_connectionResponse] = useState<null | ModelsOperationStatus>(null);
	const navigate = useNavigate();

	let state = searchParams.get("state");
	let code = searchParams.get("code");
	console.debug({ state, code });

	const connectHorizonBlue = async (ignore_last_sent_state: boolean = false) => {
		let email_ = AccountManager.username;
		// Getting the email_ from the B64 'state' value will work but the API is set up (intentionally) to require being logged in. So it will fail on the backend.
		// comment:  if (!email_ && state) {
		// comment:    email_ = base64Converter.b64DecodeUnicode(state);
		// comment:    email_ = email_.substring(0, email_.lastIndexOf(":"));
		// comment:  }

		if (code && state && email_) {
			let last_sent_state = localStorage.getItem("HorizonBlueLastAuthenticationState");
			if (last_sent_state === state && !ignore_last_sent_state) {
				// code can only be used once - track and skip if its already been sent to avoid error - could also be checked on back end
				let dets = await getUserDetails();
				console.debug({ dets });
				if (!apiResponseReader.typeCheck_ModelsOperationStatus(dets) && dets?.patientDataAuthorizations) {
					console.debug("dets.patientDataAuthorizations");
					console.debug(dets.patientDataAuthorizations);
					if (
						dets.patientDataAuthorizations.some((x) => {
							return x.state === state;
						})
					) {
						// respect last_sent_state - account was already added
						console.log("Authorization already found for state:" + state);
						navigate("/connect-healthplan");
					} else {
						connectHorizonBlue(true);
					}
				}
			} else {
				localStorage.setItem("HorizonBlueLastAuthenticationState", state);
				console.debug("authRequest", {
					code,
					state,
					email: email_,
					origin: await local_origin(),
				});
				let authResult = apiResponseReader.processResponse(
					await connectHorizonBlueAccount({
						code,
						state,
						email: email_,
						origin: await local_origin(),
					})
				);
				console.debug("connectHorizonBlueAccount Response:", authResult);
				set_connectionResponse(authResult.data);
				if (authResult.success && authResult.data) {
					setProgress(25);
					setTimeout(() => {
						if (email_) {
							setTimeout(() => {
								setProgress(51);
								setTimeout(() => {
									setProgress(90);
									setTimeout(() => {
										setProgress(100);
										setTimeout(() => {
											AccountManager.setUser("REFRESH");
											navigate("/dashboard");
										}, 500);
									}, 500);
								}, 1000);
							}, 1000);
						} else {
							console.error("Email not found");
						}
					}, 1000);
				} else {
					setError(authResult.errorMessage);
				}
			}
		}
	};

	useEffect(() => {
		connectHorizonBlue();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code, state]);

	return (
		<FormContainer noTopAndBottom>
			{apiResponseReader.typeCheck_ModelsOperationStatus(connectionResponse) && !connectionResponse.success ? (
				<AccountActive useAccountPageWrapper={false} />
			) : (
				<Card
					sx={{
						width: "100%",
						marginTop: "1rem",
						boxSizing: "border-box",
					}}
				>
					<CardContent>
						<ProgressDisplay
							progress={progress}
							type={"onboarding"}
							error={error}
							failureDisplay={<AccountActive useAccountPageWrapper={false} />}
						/>
					</CardContent>
				</Card>
			)}
		</FormContainer>
	);
}

export { HorizonBlueReceive };
