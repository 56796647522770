import { Box, Button, Stack } from "@mui/material";
import displayConverter from "common/utilities/displayConverter";
import { palette } from "theme";
import { ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { ProviderAvatarDisplay } from "./DetailsSections/ProviderAssessmentDetailsSection";

interface ProviderProfileTileProps {
	Entity: ProviderEntity;
}
const ProviderProfileTile = ({ Entity }: ProviderProfileTileProps) => {
	return (
		<Stack
			data-componentname="ProviderProfileTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				overflow: "hidden",
			}}
		>
			<Stack
				sx={{
					justifyContent: "flex-start",
					alignItems: { xs: "flex-start", sm: "center" },
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Stack
					sx={{
						flex: "1",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
					}}
				>
					<ProviderAvatarDisplay providerEntity={Entity} />
				</Stack>
				<Box
					sx={{
						justifyContent: "center",
						alignItems: "center",
						width: { xs: "100%", sm: "auto" },
						paddingTop: { xs: ".5rem", sm: "0" },
						"& .MuiButton-root": { width: { xs: "100%", sm: "auto" } },
					}}
				>
					<Button href={"tel:" + Entity._providerRecord?.phoneNumber}>
						Call: {Entity._providerRecord?.phoneNumber && displayConverter.formatPhoneNumber(Entity._providerRecord?.phoneNumber)}
					</Button>
				</Box>
			</Stack>
		</Stack>
	);
};

export { ProviderProfileTile };
