import { ReactNode, useMemo, useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { styleConverter } from "../../utilities/styleConverter";
import { palette } from "theme";
import { event_type_definitions } from "common/definitions/EventDefinitions";
import { AlertDescription } from "common/definitions/AlertDefinitions";
import { OpenCloseArrow } from "../UIParticles/OpenCloseArrow";
import { TilesDetailsSection } from "./common/TilesDetailsSection";

interface KeyFindingsTileXProps {
	alertDescription: AlertDescription;
	detailsSection?: ReactNode;
}

const KeyFindingsTileX = ({ alertDescription, detailsSection }: KeyFindingsTileXProps) => {
	let t = event_type_definitions[alertDescription.type];
	let type_style = event_type_definitions[alertDescription.type];
	if (t) {
		return (
			<KeyFindingsTile
				color={t.color}
				title={alertDescription.title}
				value={alertDescription.value}
				link_target={type_style.default_link}
				detailsSection={detailsSection}
			/>
		);
	} else {
		return null;
	}
};

interface KeyFindingsTileProps {
	color: string;
	title: string;
	value: string | number;
	link_target?: string;
	detailsSection?: ReactNode;
}

const KeyFindingsTile = ({ title, value, color, detailsSection }: KeyFindingsTileProps) => {
	const [detailsOpen, set_detailsOpen] = useState(false);

	let titleColorMarkup = useMemo(() => {
		return styleConverter.textColorMarkup(title);
	}, [title]);

	return (
		<Box
			data-componentname="KeyFindingsTile-Outer"
			sx={{
				paddingBottom: "1rem",
				marginBottom: "1rem",
				borderBottom: "2px solid",
				borderColor: palette.gray.main,
			}}
		>
			<Stack
				alignItems={"center"}
				sx={{
					width: "100%",
					flexDirection: { xs: "column", sm: "row" },
					alignItems: { xs: "flex-start", sm: "center" },
				}}
			>
				<Stack alignItems={"center"} sx={{ flex: 1, flexDirection: "row", paddingBottom: { xs: ".5rem", sm: "0" } }}>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
						sx={{
							backgroundColor: palette.gray.light,
							width: "3rem",
							height: "3rem",
							borderRadius: ".5rem",
						}}
					>
						<Typography
							sx={{
								color: color,
								fontSize: "1.5rem",
								lineHeight: "1rem",
								paddingLeft: "auto",
								paddingRight: "auto",
								textAlign: "center",
								fontWeight: 700,
							}}
						>
							{value}
						</Typography>
					</Stack>
					<Box>
						<Typography
							sx={{
								color: palette.gray.dark,
								fontSize: "1.25rem",
								paddingLeft: ".5rem",
								paddingRight: ".5rem",
								textAlign: "left",
								fontWeight: 700,
							}}
						>
							<span
								dangerouslySetInnerHTML={{
									__html: titleColorMarkup,
								}}
							></span>
						</Typography>
					</Box>
				</Stack>

				<Stack flexDirection={"row"} justifyContent={"flex-end"} sx={{ width: { xs: "100%", sm: "auto" } }}>
					<Button
						variant="outlined"
						sx={{ width: { xs: "100%", sm: "auto" } }}
						onClick={() => {
							set_detailsOpen(!detailsOpen);
						}}
					>
						{detailsOpen ? "Close" : "View"}&nbsp;
						<OpenCloseArrow open={detailsOpen} />
					</Button>
				</Stack>
			</Stack>
			<TilesDetailsSection open={detailsOpen} set_open={set_detailsOpen}>
				{detailsSection}
			</TilesDetailsSection>
		</Box>
	);
};

export { KeyFindingsTile, KeyFindingsTileX };
