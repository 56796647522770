import { Box, Stack, Typography } from "@mui/material";
import { Entity, entity_types } from "common/definitions/EntityDefinitions";
import { palette } from "theme";
import { StarRating } from "../DisplayElements/StarRating";
import { AvatarDisplay } from "../Icons/AvatarDisplay";
import { HealthIcon, health_icons_svg } from "../Icons/HealthIcon";
import { ReactNode } from "react";
import { isProviderEntity } from "common/definitions/entity/ProviderEntity";
import { isMedicationEntity } from "common/definitions/entity/MedicationEntity";
import { isConditionEntity } from "common/definitions/entity/ConditionEntity";
import { OptionsButton } from "../Buttons/OptionsButton";
import { DialogManager } from "common/managers/DialogManager";
import { renderDisplayInfo } from "./common/TilesCommon";

interface HospitalTileProps {
	Entity: Entity;
	Npi: string | undefined;
	content?: ReactNode;
	radiusRating?: number | null;
}
const HospitalTile = ({ Entity, Npi, content, radiusRating }: HospitalTileProps) => {
	const { entityTypeName, title, displayInfo } = Entity;
	const entityType = entity_types[entityTypeName];

	return (
		<Box
			data-componentname="HospitalTile"
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				boxSizing: "border-box",
				overflow: "hidden",
			}}
		>
			<Stack sx={{ flexDirection: { xs: "column", sm: "row" }, alignItems: "center" }}>
				<Stack sx={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-start", flex: 1, width: { xs: "100%", sm: "auto" } }}>
					<AvatarDisplay
						imageAlt={title}
						imageSrc={Entity._providerRecord?.imageUrl ?? ""}
						icon={<HealthIcon color={entityType.iconColor ?? palette.core.electricblue} size={48} iconSVG={health_icons_svg.surgical_center} />}
					/>
					<Box sx={{ paddingLeft: ".5rem" }}>
						<Typography component="div" sx={{ color: palette.core.blue, fontSize: "1.25rem", cursor: "pointer" }}>
							{isProviderEntity(Entity) || isMedicationEntity(Entity) || isConditionEntity(Entity) ? (
								<Box
									onClick={() => {
										DialogManager.open("provider-details", Npi ?? "MISSING NPI");
									}}
								>
									{title}
								</Box>
							) : (
								title
							)}
						</Typography>
						{typeof radiusRating === "number" ? <StarRating rating={radiusRating} adjustValueForStarFit /> : null}
					</Box>
				</Stack>
				<Box
					sx={{ paddingTop: { xs: ".5rem", sm: "0" }, width: { xs: "100%", sm: "auto" }, "& .MuiButton-root": { width: { xs: "100%", sm: "auto" } } }}
				>
					{displayInfo
						? displayInfo.map((di, i) => {
								return renderDisplayInfo(di, i);
						  })
						: null}
					<OptionsButton content={content} />
				</Box>
			</Stack>
		</Box>
	);
};

export { HospitalTile };
