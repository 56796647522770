import { createContext, useContext } from "react";
import { CognitoUserPool, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";

import { getRuntimeConfig } from "config";
const { Cognito } = getRuntimeConfig();

const poolData = {
  UserPoolId: Cognito.UserPoolId,
  ClientId: Cognito.ClientId,
};

export const userPool = new CognitoUserPool(poolData);

export interface Authenticated {
  authenticated: true;
  user: CognitoUser;
}

export interface Unauthenticated {
  authenticated: false;
}

export type AuthState = Authenticated | Unauthenticated;
type AuthStateHandle = AuthState & { update(next: AuthState): void };

export const AuthContext = createContext<AuthStateHandle>({
  authenticated: false,
  update() {
    // no-op
  },
});

export function useAuthState() {
  const { update, ...state } = useContext(AuthContext);

  return {
    state,
    signOut() {
      if (state.authenticated) {
        state.user.signOut();
      }
      update({ authenticated: false });
    },
  };
}

export function useAuthUser() {
  const auth = useContext(AuthContext);

  if (!auth.authenticated) throw new Error("User is not authenticated.");

  return auth.user;
}

export function getAuthSession(user: CognitoUser): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) =>
    user.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) reject(err);
      else resolve(session);
    })
  );
}
