import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPasswordPage from "../pages/public/ForgotPassword/ForgotPasswordPage";
import ResetPasswordPage from "../pages/public/ResetPassword/ResetPasswordPage";
import SignInPage from "../pages/public/SignInPage";
import SignOutPage from "../pages/public/SignOutPage";
import SignUpPage from "account/pages/public/SignUpPage";
import AccountPageWrapper from "account/components/AccountPageWrapper";
import CodeVerificationPage from "account/pages/public/CodeVerificationPage";
import { CariskSignUpAndConnect } from "account/pages/CariskCigna/CariskSignUpAndConnect";

export default function AccountPublicRoutes() {
	return (
		<AccountPageWrapper>
			<Routes>
				<Route path="/signin" element={<SignInPage />} />
				<Route path="/signup" element={<SignUpPage />} />
				<Route path="/carisk-cigna-signup" element={<CariskSignUpAndConnect />} />
				<Route path="/signout" element={<SignOutPage />} />
				<Route path="/forgot-password" element={<ForgotPasswordPage />} />
				<Route path="/reset-password" element={<ResetPasswordPage />} />
				<Route path="/code-verification/*" element={<CodeVerificationPage />} />
				<Route path="*" element={<Navigate replace to="/signin" />} />
			</Routes>
		</AccountPageWrapper>
	);
}
