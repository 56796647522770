import { useEffect, useRef, useState, useContext } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { icons } from "theme";
import Icon from "common/components/Icons/Icon";
import CodeInput from "common/forms/CodeInput";
import { confirmVerificationCode, ConsumerResendVerificationCodeResponseModel, ConsumerResendVerificationCodeResult, resendVerificationCode } from "api";
import { FormAlerts, RequestStatus, FormError } from "common/forms/FormAlerts";
import { LinkLike } from "common/components/Buttons/LinkLike";
import { AccountManagerContext } from "common/managers/AccountManager";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";

type CodeVerificationFormProps = {
	username?: string;
	hideBackButton?: boolean;
};

function CodeVerificationForm({ username, hideBackButton }: CodeVerificationFormProps) {
	const nav = useNavigate();
	let params = new URLSearchParams(window.location.search);
	const email = username ?? params.get("email");
	const url_code = params.get("code");

	const [verifiedStatusCheck, set_verifiedStatusCheck] = useState<"Verified" | "NotVerified" | "Pending" | "NotSent">("NotSent");

	const [code, setCode] = useState<Array<string>>([]);
	const [codeValid, setCodeValid] = useState<boolean>(false);
	const [formErrors, setFormErrors] = useState<Array<FormError>>([]);
	const [requestStatus, setRequestStatus] = useState<RequestStatus>({
		status: null,
	});
	const [autoSent, setAutoSent] = useState<boolean>(false);

	const AccountManager = useContext(AccountManagerContext);
	useEffect(() => {
		if (email && verifiedStatusCheck === "NotSent") {
			(async () => {
				let statusCheckResponse = await resendVerificationCode({ email, checkStatus: true });
				if (statusCheckResponse.hasOwnProperty("response")) {
					let response: undefined | ConsumerResendVerificationCodeResponseModel = (statusCheckResponse as ConsumerResendVerificationCodeResult)
						.response;
					if (response && response.success) {
						if ((response.data as any).hasOwnProperty("VerificationCodeStatus")) {
							set_verifiedStatusCheck((response.data as any).VerificationCodeStatus);
						}
					}
				}
			})();
		}
	}, [verifiedStatusCheck, email]);

	const codeSetter = (newValue: Array<string>) => {
		let val = newValue.join("");
		if (val.length === 4) {
			setCodeValid(true);
		} else {
			setCodeValid(false);
		}
		setCode(newValue);
	};

	const submit = (code_: Array<string>) => {
		if (email) {
			sendVerify({
				email: email,
				verificationCode: code_.join(""),
			}).then((x) => {
				AccountManager.accountManager.setUser("REFRESH");
			});
		}
	};

	const sendVerify = async (request: { email: string; verificationCode: string }) => {
		setFormErrors([]);
		setRequestStatus({ status: "pending" });
		let result = await confirmVerificationCode(request);

		if ((result as any)?.errorResult) {
			setFormErrors([
				{
					message: (result as any)?.errorResult.errorMessage ?? "An Error Occurred",
				},
			]);
			setRequestStatus({ status: null });
		} else if ((result as any)?.response.success === false) {
			setFormErrors([
				{
					message: (result as any)?.response.errorMessage ?? "An Error Occurred",
				},
			]);
			setRequestStatus({ status: null });
		} else if ((result as any)?.response.success === true) {
			setRequestStatus({ status: "success" });
			setTimeout(() => {
				DialogManager.close("verify-email");
			}, 1500);
		} else {
			setRequestStatus({ status: null });
		}
	};

	if (url_code && email) {
		if (!autoSent) {
			let c = url_code.split("");
			codeSetter(c);
			setAutoSent(true);
			submit(c);
		}
	}

	const continueButtonRef = useRef<any>(null);

	let action = (
		<Button
			ref={continueButtonRef}
			onClick={() => {
				submit(code);
			}}
			disabled={!(codeValid && requestStatus.status == null)}
		>
			Continue
		</Button>
	);

	let content;
	if (verifiedStatusCheck === "Verified") {
		content = <Typography>{email} is already verified</Typography>;
		action = (
			<Button
				onClick={() => {
					nav("/dashboard");
				}}
			>
				Continue
			</Button>
		);
	} else if (requestStatus.status === "success") {
		content = <Typography>{email} has been verified</Typography>;
	} else if (email) {
		content = (
			<>
				<CodeInput
					length={4}
					code={code}
					setCode={codeSetter}
					focusBeyond={() => {
						continueButtonRef.current?.focus();
						setTimeout(() => {
							// requires delay to function the first time
							continueButtonRef.current?.focus();
						}, 250);
					}}
				/>
				<Typography sx={{ py: 2 }}>
					Didn't recieve a code?{" "}
					<LinkLike
						onClick={async () => {
							await resendVerificationCode({ email: email });
						}}
					>
						Resend Code
					</LinkLike>
				</Typography>
			</>
		);
	} else {
		content = <Typography> Email address could not be found </Typography>;
	}

	return (

			
				<ContentSection>
					<Stack sx={{ flexDirection: "row", justifyContent: "flex-start", pb: 2 }}>
						{hideBackButton ? null : (
							<Box sx={{ paddingRight: "12px" }}>
								<Typography variant="h1" component="h1">
									<Link to="/" style={{ color: "inherit" }}>
										<Icon icon={icons.arrowLeft} />
									</Link>
								</Typography>
							</Box>
						)}

						<Box>
							<Typography variant="h1" component="h1">
								Code Verification
							</Typography>
							{email ? (
								<Typography>
									Please enter the code we sent to:{" "}
									<Typography component={"span"} sx={{ fontWeight: 700 }}>
										{email}
									</Typography>
								</Typography>
							) : null}
						</Box>
					</Stack>
					{content}
					<FormAlerts errors={formErrors} />
					<Stack flexDirection={"row"} justifyContent={"flex-end"}>
						{action}
					</Stack>
				</ContentSection>
			

	);
}

export default CodeVerificationForm;
