import { getUserDetails } from "api";

const X_getUserDetails = async () => {
	let response = await getUserDetails();
	console.count("X_getUserDetails");
	// console.trace();
	return response;
};

export { X_getUserDetails };
