import { Box, Stack, Typography } from "@mui/material";
import { ModelsExplanationOfBenefit } from "api";
import { CONVERT_DoctorRecord_Entity, CREATE_UNKNOWN_DoctorRecord_Entity, Entity } from "common/definitions/EntityDefinitions";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { StaticDataManager } from "common/managers/StaticDataManager";
import { styleConverter } from "common/utilities/styleConverter";
import { dateConverter } from "common/utilities/dateConverter";
import { palette } from "theme";
import { ListDisplay } from "../DisplayElements/ListDisplay";
// import { StarRating } from "../DisplayElements/StarRating";
// import { HealthIcon } from "../Icons/HealthIcon";
import { DialogManager } from "common/managers/DialogManager";
import { DoctorRecord_Enhanced, ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { ProviderAvatarDisplay, ProviderOptionsActions } from "./DetailsSections/ProviderAssessmentDetailsSection";

interface VisitTileProps {
	visit_eob: ModelsExplanationOfBenefit;
	objectFit?: "contain" | "cover";
}
const VisitTile = ({ visit_eob, objectFit }: VisitTileProps) => {
	let providerX: DoctorRecord_Enhanced | null = null;
	if (visit_eob.providerNpi) {
		providerX = DynamicDataManager.npis.doctorRecord_NPIMap.get(visit_eob.providerNpi) ?? null;
	}

	let organizationX: DoctorRecord_Enhanced | null = null;
	if (visit_eob.organizationNpi) {
		organizationX = DynamicDataManager.npis.doctorRecord_NPIMap.get(visit_eob.organizationNpi) ?? null;
	}

	let providerEntity: null | ProviderEntity = null;
	let organizationEntity: null | Entity = null;
	if (providerX) {
		providerEntity = CONVERT_DoctorRecord_Entity(providerX);
	}

	if (organizationX) {
		organizationEntity = CONVERT_DoctorRecord_Entity(organizationX);
	}

	if (!providerEntity && !organizationEntity) {
		providerEntity = CREATE_UNKNOWN_DoctorRecord_Entity({});
	}

	let taxonomy_string = "";
	if (providerX && providerX.taxonomies.length > 0) {
		taxonomy_string = providerX?.taxonomies.map((x) => x.displayName).join(",");
	}

	// const report = () => {
	// 	console.debug({ visit_eob, providerX, providerEntity, organizationX, organizationEntity });
	// };
	// console.group("VisitTile");
	// console.debug(providerEntity);
	// console.debug(visit_eob);

	// console.debug(visit_eob.providerCareAlertsIgnored);

	// console.groupEnd();

	return (
		<Box
			style={{
				backgroundColor: palette.gray.light,
				overflow: "hidden",
				borderRadius: "1rem",
			}}
		>
			<Box style={{ width: "100%", textAlign: "left", padding: "1rem", boxSizing: "border-box", backgroundColor: palette.gray.dark }}>
				<Typography variant="h4" sx={{ color: "#ffffff", marginBottom: "0" }}>
					{(taxonomy_string !== "" ? `${taxonomy_string} | ` : "") +
						dateConverter["YYYY-MM-DD_to_mM/dD/YY"](visit_eob.date ?? visit_eob.billStartDate)}
				</Typography>
			</Box>
			<Stack sx={{ flexDirection: { xs: "column", md: "row" }, padding: "1rem" }}>
				{(providerEntity && providerX) || (organizationEntity && organizationX) ? (
					<Box sx={{ flex: "1", padding: "1rem" }}>
						{providerEntity && providerX ? <ProviderAvatarDisplay providerEntity={providerEntity} /> : null}
						{organizationEntity && organizationX ? <ProviderAvatarDisplay entity={organizationEntity} /> : null}
						{/* {organizationEntity && organizationX ? (
							<Stack sx={{ flexDirection: "row" }}>
								<Avatar
									sx={{
										borderColor: palette.gray.main,
										backgroundColor: "white",
										width: 72,
										height: 72,
										"& > img": { objectFit: objectFit ?? "cover" },
									}}
									alt={organizationEntity.title}
									src={organizationEntity.imageSrc}
								>
									<HealthIcon
										color={palette.core.electricblue}
										size={48}
										iconSVG={(entity_types as CIM)[organizationEntity.entityTypeName as CIM]?.iconSVG}
									/>
								</Avatar>
								<Box sx={{ paddingLeft: ".5rem" }}>
									<Typography sx={{ color: palette.gray.dark, fontWeight: 500 }}>
										{organizationX?.taxonomies.map((x) => x.displayName).join(",")}
									</Typography>
									<Typography style={{ fontSize: "1.25rem", color: palette.core.blue }}>
										{organizationEntity.title}
										{organizationEntity.title === "HMH HOSPITALS CORPORATION"
											? (() => {
													console.debug(organizationEntity);
													console.debug(visit_eob);
													report();
													return "BuggerAll! XXX";
											  })()
											: null}
										{organizationEntity.title === "LABORATORY CORPORATION OF AMERICA HOLDINGS"
											? (() => {
													console.debug(organizationEntity);
													console.debug(visit_eob);
													report();
													return "BuggerAll! XXX";
											  })()
											: null}
									</Typography>
									<StarRating rating={organizationX.radiusRating} adjustValueForStarFit />
								</Box>
							</Stack>
						) : null} */}
					</Box>
				) : null}
				{visit_eob && visit_eob.diagnosisCodes ? (
					<Box
						sx={{
							flex: "1",
							borderLeft: { xs: "none", md: `2px solid ${palette.gray.dark}` },
							borderTop: { xs: `2px solid ${palette.gray.dark}`, md: "none" },
							padding: "1rem",
						}}
					>
						<ListDisplay
							title="This provider diagnosed you with"
							items={visit_eob.diagnosisCodes?.map((d) => {
								let icd10 = StaticDataManager.data.ICD10CM_Package.code_map.get(d);
								if (icd10) {
									return (
										<Box
											component={"span"}
											sx={{
												color: palette.core.blue,
												cursor: "pointer",
											}}
											onClick={() => {
												DialogManager.open("diagnosis-details", icd10?.ICD10Code);
											}}
										>
											{styleConverter.textColorMarkupX(icd10.Description, palette.core.electricblue, "font-weight:400;font-size:1.1rem;")}
										</Box>
									);
								}
								let icd10NoDecimal = StaticDataManager.data.ICD10CM_Package.code_map.get(d.replace(".", ""));
								if (icd10NoDecimal) {
									return (
										<Box
											component={"span"}
											sx={{
												color: palette.core.blue,
												cursor: "pointer",
											}}
											onClick={() => {
												DialogManager.open("diagnosis-details", icd10NoDecimal?.ICD10Code);
											}}
										>
											{styleConverter.textColorMarkupX(
												icd10NoDecimal.Description,
												palette.core.electricblue,
												"font-weight:400;font-size:1.1rem;"
											)}
										</Box>
									);
								}
								return styleConverter.textColorMarkupX(d, palette.core.electricblue, "font-weight:400;font-size:1.1rem;");
							})}
						/>
					</Box>
				) : null}
				{visit_eob && visit_eob.productOrServiceCodes ? (
					<Box
						sx={{
							flex: "1",
							borderLeft: { xs: "none", md: `2px solid ${palette.gray.dark}` },
							borderTop: { xs: `2px solid ${palette.gray.dark}`, md: "none" },
							padding: "1rem",
						}}
					>
						<ListDisplay
							title="Recent Treatments"
							items={visit_eob.productOrServiceCodes.map((d) => {
								let hcpcs_code = StaticDataManager.data.HCPCS.lookups.code.get(d) ?? null;
								// let cpt_category;
								// if (hcpcs_code && hcpcs_code.cpt_category) {
								// 	cpt_category = StaticDataManager.data.HCPCS.lookups.cpt_category.get(hcpcs_code.cpt_category) ?? null;
								// }
								let content = hcpcs_code ? hcpcs_code.description : "Unidentified Procedure/Medication: " + d;

								return styleConverter.textColorMarkupX(content, palette.core.electricblue, "font-weight:400;font-size:1.1rem;");
							})}
						/>
					</Box>
				) : null}
			</Stack>
			{providerEntity && providerX ? (
				<Stack
					sx={{
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "flex-end",
						padding: "1rem",
						"& .MuiButton-root, .view-options-btn ": { width: { xs: "100%", md: "auto" } },
					}}
				>
					<ProviderOptionsActions providerEntity={providerEntity} providerCareAlertsIgnored={visit_eob.providerCareAlertsIgnored} />
				</Stack>
			) : null}
		</Box>
	);
};

export { VisitTile };
