type DialogLauncher = { open: (x?: any) => any; close: (x?: any) => any };
type DialogTypes =
	| "invite-care-collaborator"
	| "provider-savings-details"
	| "medication-savings-details"
	| "care-maintenance-savings-details"
	| "diagnosis-details"
	| "medication-details"
	| "provider-details"
	| "hospital-details"
	| "care-score-details"
	| "optimize-care-ratings"
	| "suggest-similar-providers"
	| "care-alert"
	| "provide-feedback"
	| "update-info"
	| "change-password"
	| "verify-email"
	| "add-care-plan"
	| "activate-achievements"
	| "accarent-alert";
type DialogTypesLauncher = {
	[key in DialogTypes]: DialogLauncher;
};
class DialogManagerClass {
	intializedAt?: number;
	open = (dialogType: DialogTypes, value?: string | number) => {
		console.debug("open", dialogType, value);
		this.launchers[dialogType].open(value);
	};
	close = (dialogType: DialogTypes, value?: string | number) => {
		console.debug("close", dialogType, value);
		this.launchers[dialogType].close(value);
	};
	closeAll = () => {
		// console.log("CLOSEALL DIALOGS");
		Object.getOwnPropertyNames(this.launchers).forEach((type: string) => {
			// console.log(type);
			this.launchers[type as DialogTypes].close();
		});
	};
	launchers: DialogTypesLauncher = {
		// launcher functions are set in Dialog components
		"medication-savings-details": { open: () => null, close: () => null },
		"provider-savings-details": { open: () => null, close: () => null },
		"care-maintenance-savings-details": { open: () => null, close: () => null },
		"diagnosis-details": { open: () => null, close: () => null },
		"medication-details": { open: () => null, close: () => null },
		"provider-details": { open: () => null, close: () => null },
		"hospital-details": {
			open: () => null,
			close: () => null,
		},
		"optimize-care-ratings": { open: () => null, close: () => null },
		"suggest-similar-providers": { open: () => null, close: () => null },
		"care-alert": { open: () => null, close: () => null },
		"invite-care-collaborator": { open: () => null, close: () => null },
		"provide-feedback": { open: () => null, close: () => null },
		"update-info": { open: () => null, close: () => null },
		"change-password": { open: () => null, close: () => null },
		"verify-email": { open: () => null, close: () => null },
		"add-care-plan": { open: () => null, close: () => null },
		"activate-achievements": { open: () => null, close: () => null },
		"accarent-alert": { open: () => null, close: () => null },
		"care-score-details": {
			open: function (x?: any) {
				throw new Error("Function not implemented.");
			},
			close: () => null,
		},
	};
	constructor() {
		if (!(DialogManagerClass as any).instance) {
			this.intializedAt = Date.now();
			(DialogManagerClass as any).instance = this;
		}
		return (DialogManagerClass as any).instance;
	}
}

const DialogManager = new DialogManagerClass();
(window as CIM).DialogManager = DialogManager;
export { DialogManager };
