import { ModelsProviderTaxonomy } from "api";
import { mapper } from "./mapper";

interface ProviderTaxonomyPackage {
  Classification_Map: Map<string, Array<ProviderTaxonomyDefinition>>;
  Classification_Values: Array<string>;
  Code_Map: Map<string, ProviderTaxonomyDefinition>;
  Code_Values: Array<string>;
  // DisplayName_Map: Map<string, ProviderTaxonomyDefinition>;
  // DisplayName_Values: Array<string>;
  Grouping_Map: Map<string, Array<ProviderTaxonomyDefinition>>;
  Grouping_Values: Array<string>;
  Specialization_Map: Map<string, Array<ProviderTaxonomyDefinition>>;
  Specialization_Values: Array<string>;
}
// type ProviderTaxonomyDefinition = {
//   Classification: string;
//   Code: string;
//   Definition: string;
//   DisplayName: string;
//   Grouping: string;
//   Notes: string;
//   Section: string;
//   Specialization: string;
// };

interface ProviderTaxonomyDefinition extends ModelsProviderTaxonomy {}

export type { ProviderTaxonomyDefinition, ProviderTaxonomyPackage };

const providerTaxonomyMapper = {
  compileTaxonomyCodes: (flat_taxonomies: Array<ProviderTaxonomyDefinition>): ProviderTaxonomyPackage => {
    let maps = {
      Classification_Map: new Map(),
      Classification_Values: [] as Array<string | number | boolean>,
      Code_Map: new Map(),
      Code_Values: [] as Array<string | number | boolean>,
      // DisplayName_Map: new Map(),
      // DisplayName_Values: [] as Array<string | number | boolean>,
      Grouping_Map: new Map(),
      Grouping_Values: [] as Array<string | number | boolean>,
      Specialization_Map: new Map(),
      Specialization_Values: [] as Array<string | number | boolean>,
    };

    mapper.mapAdd(maps.Classification_Map, "classification", flat_taxonomies);
    mapper.mapAdd(maps.Code_Map, "code", flat_taxonomies, { singleItemNotArray: true });
    // mapper.mapAdd(maps.DisplayName_Map, "displayName", flat_taxonomies);
    mapper.mapAdd(maps.Grouping_Map, "grouping", flat_taxonomies);
    mapper.mapAdd(maps.Specialization_Map, "specialization", flat_taxonomies);

    maps.Classification_Values = mapper.uniqueValues("classification", flat_taxonomies);
    maps.Code_Values = mapper.uniqueValues("code", flat_taxonomies);
    // maps.DisplayName_Values = mapper.uniqueValues("displayName", flat_taxonomies);
    maps.Grouping_Values = mapper.uniqueValues("grouping", flat_taxonomies);
    maps.Specialization_Values = mapper.uniqueValues("specialization", flat_taxonomies);

    return maps as ProviderTaxonomyPackage;
  },
};

export { providerTaxonomyMapper };
