import Box from "@mui/material/Box";
import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { ConsumerSendUserFeedbackResult, sendUserFeedback } from "api";
import { AppBar, Container, MenuItem, Stack, Typography } from "@mui/material";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { DialogManager } from "common/managers/DialogManager";
import { style, palette } from "theme";
import { StandardSelect } from "common/components/Inputs/StandardSelect";

interface FeedbackProps {
	subject?: string;
	feedback?: string;
}

function ProvideFeedback() {
	const { handleSubmit, getValues, control } = useForm<FeedbackProps>({
		mode: "onChange",
		defaultValues: {},
	});
	const { enqueueSnackbar } = useSnackbar();

	const onSubmit = handleSubmit(() => {
		return sendUserFeedback({
			message: getValues("feedback"),
			subject: getValues("subject"),
		}).then((value) => {
			if ((value as ConsumerSendUserFeedbackResult)?.operationStatus?.success) {
				successCallback();
			} else {
				failureCallback();
			}
			console.log("Val", value);
		});
	});

	function successCallback() {
		DialogManager.close("provide-feedback");
		enqueueSnackbar("Feedback submitted", {
			variant: "success",
		});
	}

	function failureCallback() {
		enqueueSnackbar("Error while submitting feedback", {
			variant: "error",
		});
	}

	return (
		<>
			<AppBar sx={{ position: "relative" }}>
				<Box
					style={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: palette.core.lightBackground,
					}}
				>
					<Typography variant="h1" style={{ fontWeight: "bold", color: palette.gray.darker }}>
						Website Feedback
					</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("provide-feedback");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<Stack sx={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
				<Container onSubmit={onSubmit} maxWidth="sm" component="form">
					<ContentSection title="Have feedback, an idea or suggestion?  Share it here!">
						<br />
						<Box sx={{ paddingBottom: "1rem" }}>
							<Controller
								control={control}
								name={"subject"}
								defaultValue={"Website Issues"}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										<Typography id="feedback-category-label" sx={{ fontWeight: 700 }}>
											Subject
										</Typography>
										<StandardSelect
											value={value}
											defaultValue={"Website Issues"}
											labelId="feedback-category-label"
											onChange={(...args) => {
												onChange(...args);
											}}
										>
											<MenuItem value={"Website Issues"}>Website Issues</MenuItem>
											<MenuItem value={"Product Support"}>Product Support</MenuItem>
											<MenuItem value={"Product Feedback & Ideas"}>Product Feedback & Ideas</MenuItem>
										</StandardSelect>
									</>
								)}
							/>
						</Box>
						<Box sx={{ paddingBottom: "1rem" }}>
							<Controller
								control={control}
								name={"feedback"}
								rules={{ required: "Message required" }}
								render={({ field: { onChange, value }, fieldState: { error } }) => (
									<>
										<Typography sx={{ fontWeight: 700 }}>Comments</Typography>
										<StandardTextField
											data-styletype="infodisplay"
											variant="filled"
											multiline
											rows={4}
											sx={{ flexGrow: 1, width: "100%" }}
											value={value}
											onChange={(...args) => {
												onChange(...args);
											}}
											error={!!error}
											helperText={error ? error.message : null}
										/>
									</>
								)}
							/>
						</Box>
						<Stack alignItems={"flex-end"}>
							<FormSubmitButton>Submit</FormSubmitButton>
						</Stack>
					</ContentSection>
				</Container>
			</Stack>
		
		</>
	);
}

export default ProvideFeedback;
