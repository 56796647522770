import { ReactNode, useState } from "react";
import { Box, Button, Card, CardActions, Link } from "@mui/material";
import { FormValidationResponse } from "common/forms/formUtilities";
import { CariskConnectionAddonForm, CariskConnectFormValues } from "account/pages/CariskCigna/CariskConnectionAddonForm";
import FormContainer from "account/forms/FormContainer";
import { CarePlanConfigurationCignaCariskConnectionInformation, ModelsOperationStatus } from "api";
import { apiResponseReader } from "common/utilities/apiResponseReader";

interface CariskConnectFormProps {
	onSuccess: () => any;
	defaultValues?: Partial<CarePlanConfigurationCignaCariskConnectionInformation>;
	onSubmit?: () => any;
	errorContent?: ReactNode | null;
	// addonFormSendBefore: () => Promise<boolean>;
	// addonFormValidate: () => FormValidationResponse<CariskConnectFormValues>;
}

function CariskConnectForm({ onSuccess, defaultValues, onSubmit, errorContent }: CariskConnectFormProps) {
	// const [errors, set_errors] = useState<FormErrors | undefined>();

	const [cariskFormSend, set_cariskFormSend] = useState<(() => Promise<boolean | ModelsOperationStatus>) | undefined>();

	const [cariskFormValidate, set_cariskFormValidate] = useState<(() => FormValidationResponse<CariskConnectFormValues>) | undefined>();

	const [requestInProgress, setRequestInProgress] = useState(false);

	const submitSignUp = async () => {
		console.debug("submitSignUp");

		setRequestInProgress(true);

		if (cariskFormSend) {
			let response = await cariskFormSend();
			setRequestInProgress(false);
			console.debug(response);

			if (response === true || (apiResponseReader.typeCheck_ModelsOperationStatus(response) && response.success)) {
				// if (onSuccess) {
				console.debug("On Success++++++++++++++++++++++++++++++++++");
				// alert("run onSuccess");
				onSuccess();
				// }
			}
		}
	};

	const handleSubmit = async (a: any) => {
		if (onSubmit) {
			onSubmit();
		}
		console.debug("handleSubmit");
		if (cariskFormValidate) {
			let addonFormValidityResponse = cariskFormValidate();
			console.debug({ addonFormValidityResponse });
			if (addonFormValidityResponse.isValid) {
				console.debug("submitSignUp");
				await submitSignUp();
			}
		}
	};
	/*
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	
	*/
	return (
		<FormContainer
			bottomContent={
				<Box style={{ textAlign: "center" }}>
					<Link href="/signin" sx={{ color: "core.darkblue", fontSize: { xs: "1.25rem", sm: "1.5rem" } }}>
						Cancel
					</Link>
				</Box>
			}
		>
			<Card id="ConnectCariskAccountForm" component="form" elevation={4}>
				<CariskConnectionAddonForm
					set_cariskFormSend={set_cariskFormSend}
					set_cariskFormValidate={set_cariskFormValidate}
					sendMode="MakeRequest"
					displayType="Primary"
					defaultValues={defaultValues}
				/>
				{errorContent ? <Box style={{ marginBottom: "32px", width: "100%" }}>{errorContent}</Box> : null}
				<CardActions sx={{ justifyContent: "right", marginTop: 0, paddingTop: 0 }}>
					<Button type="button" onClick={handleSubmit} sx={{ backgroundColor: "core.darkblue" }} disabled={requestInProgress}>
						Connect Care Risk Account
					</Button>
				</CardActions>
			</Card>
		</FormContainer>
	);
}

export { CariskConnectForm };
