import { useState, useEffect, useMemo, ReactNode } from "react";
import { Cell, RadialBar, RadialBarChart } from "recharts";
import { palette } from "../../../theme";
import { Box, Grid } from "@mui/material";
import LegendRow from "./LegendRow";
import displayConverter from "../../utilities/displayConverter";
interface StandardRadialBarChartProps {
	data: Array<{
		[attr: string]: string | number | boolean | undefined;
	}>;
	colors?: Array<any>;
	maxValue?: number;
	dataKey: string;
	nameKey: string;
	showAverage?: true;
	showCustom?: string | ReactNode;
	width?: number;
	height?: number;
	innerRadius?: number;
	outerRadius?: number;

	showLegend?: boolean;
	standardLegendVerticalAlign?: "top" | "middle" | "bottom";
	legendIcon?: "line" | "plainline" | "square" | "rect" | "circle" | "cross" | "diamond" | "star" | "triangle" | "wye";

	displayPercentage?: boolean;
	responsiveHeight?: number;

	startAngle?: "normal" | "straightup";
}

function StandardRadialBarChart(props: StandardRadialBarChartProps) {
	const [dataTimeStamp, setDataTimeStamp] = useState(Date.now());
	useEffect(() => {
		// force rerender and animate
		setDataTimeStamp(Date.now());
	}, [props.data]);

	const height = props.height ?? 250;
	const width = props.width ?? 250;

	const angles = { start: 405, end: 45 };
	switch (props.startAngle) {
		case "straightup":
			angles.start = 90;
			angles.end = -270;
			break;
		case "normal":
		case undefined:
		default:
			break;
	}

	const specs = useMemo(() => {
		let data = [...props.data];
		let colors = props.colors ? [...props.colors] : [];
		let total = 0;
		for (const value of props.data) {
			total += value[props.dataKey] as number;
		}
		let average = Math.round((total / data.length) * 10) / 10;
		if (props.maxValue) {
			data.unshift({
				maxValueDef: true,
				[props.dataKey]: props.maxValue,
				[props.nameKey]: "maxValue",
			});
			colors.unshift("transparent");
		}
		return { data, colors, average, total };
	}, [props.data, props.maxValue, props.colors, props.dataKey, props.nameKey]);

	let centerLabelContent = null;
	let centerLabel = null;
	if (props.showCustom) {
		centerLabelContent = props.showCustom;
	} else if (specs.average) {
		centerLabelContent = specs.average;
	}
	if (centerLabelContent) {
		centerLabel = (
			<div
				style={{
					position: "absolute",
					top: height / 2 - 20,
					left: width / 2 - 30,
					fontSize: "1.6rem",
					fontWeight: "bold",
					height: 40,
					width: 60,
					textAlign: "center",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				{centerLabelContent}
			</div>
		);
	}

	const chart = (
		<Box style={{ position: "relative" }}>
			<RadialBarChart
				width={width}
				height={height}
				innerRadius={props.maxValue ? "25%" : "40%"}
				outerRadius="100%"
				data={specs.data}
				startAngle={angles.start}
				endAngle={angles.end}
				barGap={30}
				barCategoryGap={"20%"}
				key={dataTimeStamp}
			>
				{/* <Tooltip /> */}
				<RadialBar label={false} background={false} dataKey={props.dataKey}>
					{specs.data.map((x, index) => {
						if (x.maxValueDef) {
							return <Cell key={`cell-${index}`} fill={"transparent"} />;
						}
						return <Cell key={`cell-${index}`} fill={specs.colors ? specs.colors[index] : palette.gray.dark} />;
					})}
				</RadialBar>
			</RadialBarChart>
			{centerLabel}
		</Box>
	);

	if (props.showLegend) {
		return (
			<Grid container sx={{ height: "100%" }}>
				<Grid
					xs={12}
					sm={5}
					md={4}
					item
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Box>
						{specs.data.map((row, x) => {
							if (row.maxValueDef) {
								return null;
							}
							return (
								<LegendRow
									key={x}
									item={{
										name: row[props.nameKey] as string,
										value: props.displayPercentage
											? displayConverter.toPercent((row[props.dataKey] as number) / specs.total)
											: (row[props.dataKey] as number),
									}}
									color={specs.colors.length > x ? specs.colors[x] : palette.gray.dark}
								/>
							);
						})}
					</Box>
				</Grid>
				<Grid
					xs={12}
					sm={7}
					md={8}
					item
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{chart}
				</Grid>
			</Grid>
		);
	}

	return chart;
}

export { StandardRadialBarChart };
