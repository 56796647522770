import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { DialogsContext, DiagnosisDetailOption } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DiagnosisDetails } from "details/diagnosisDetails/DiagnosisDetails";
import { DialogManager } from "common/managers/DialogManager";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const DiagnosisDetailDialog = () => {
	const location = useLocation();
	const { diagnosisDetailDisplay } = useContext(DialogsContext);
	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("diagnosis-details");
	//const [hideDoctors, setHideDoctors] = useState<"hidetopdoctors"|"OnlyShow_IcdRangeLookupTaxonomy">(null);
	const [options, setOptions] = useState<DiagnosisDetailOption | null>(null);
	useEffect(() => {
		if (diagnosisDetailDisplay.current === null) {
			diagnosisDetailDisplay.set_options(null);
			setOptions(null);
		}
		if (diagnosisDetailDisplay.options !== null) {
			setOptions(diagnosisDetailDisplay.options);
			diagnosisDetailDisplay.set_options(null);
		}
	}, [diagnosisDetailDisplay]);
	const close = useMemo(() => {
		return () => {
			searchParams.delete("diagnosis-details");
			setSearchParams(searchParams);
			diagnosisDetailDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "diagnosis-details": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["diagnosis-details"].open = open;
		DialogManager.launchers["diagnosis-details"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== diagnosisDetailDisplay?.current) {
			diagnosisDetailDisplay.set_current(d_query);
		}
	}, [d_query, diagnosisDetailDisplay]);

	return (
		<Dialog id="DiagnosisDialog" fullScreen open={!!diagnosisDetailDisplay.current} onClose={close} TransitionComponent={Transition}>
			{diagnosisDetailDisplay.current ? <DiagnosisDetails diagnosisID={diagnosisDetailDisplay.current} diagnosisDetailOption={options} /> : null}
		</Dialog>
	);
};

export { DiagnosisDetailDialog };
