import { Box, List, ListItem, Typography } from "@mui/material";
import { ReactNode } from "react";
import { palette } from "theme";

type ListDisplayProps = {
	title?: string;
	items: Array<ReactNode>;
};

const ListDisplay = ({ title, items }: ListDisplayProps) => {
	return (
		<Box style={{ textAlign: "left" }}>
			<Typography sx={{ fontSize: "1.25rem", fontWeight: 700 }}>{title}</Typography>
			<List style={{ padding: 0 }}>
				{items.map((x, i) => {
					return (
						<ListItem
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "stretch",
								alignItems: "flex-start",
								padding: ".5rem 0px",
								fontSize: "1.25rem",
							}}
							key={i}
						>
							<Box
								style={{
									flexGrow: 0,
									height: ".5rem",
									minHeight: ".5rem",
									maxHeight: ".5rem",
									width: ".5rem",
									minWidth: ".5rem",
									maxWidth: ".5rem",
									borderRadius: "6px",
									backgroundColor: palette.gray.dark,
									margin: ".75rem",
								}}
							/>
							<Box style={{ flexGrow: 1 }}>{x}</Box>
						</ListItem>
					);
				})}
			</List>
		</Box>
	);
};

export { ListDisplay };
