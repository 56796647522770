import { Dialog, Slide, SlideProps } from "@mui/material";
import { forwardRef, useContext, useEffect, useMemo } from "react";
import { DialogsContext } from "common/context/MainContext";
import { useLocation, useSearchParams } from "react-router-dom";
import { DialogManager } from "common/managers/DialogManager";
import { AccountManagerContext } from "common/managers/AccountManager";
import ActivateAchievements from "account/components/ActivateAchievements";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ActivateAchievementsDialog = () => {
	const location = useLocation();
	const { activateAchievementsDisplay } = useContext(DialogsContext);
	const accountContext = useContext(AccountManagerContext);
	let consumerUser = accountContext.accountManager.userDetails?.consumerUser;
	const [searchParams, setSearchParams] = useSearchParams();
	const d_query = searchParams.get("activate-achievements");

	const close = useMemo(() => {
		return () => {
			searchParams.delete("activate-achievements");
			setSearchParams(searchParams);
			activateAchievementsDisplay.set_current(null);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	const open = useMemo(() => {
		return (x: string) => {
			setSearchParams({ "activate-achievements": x });
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSearchParams, searchParams, location.pathname]);

	useEffect(() => {
		DialogManager.launchers["activate-achievements"].open = open;
		DialogManager.launchers["activate-achievements"].close = close;
	}, [close, open]);

	useEffect(() => {
		if (d_query && d_query !== activateAchievementsDisplay?.current) {
			activateAchievementsDisplay.set_current(d_query);
		}
	}, [d_query, activateAchievementsDisplay]);

	return (
		<Dialog id="ActivateAchievementsDialog" fullScreen open={!!activateAchievementsDisplay.current} onClose={close} TransitionComponent={Transition}>
			{activateAchievementsDisplay.current && consumerUser && <ActivateAchievements />}
		</Dialog>
	);
};

export { ActivateAchievementsDialog };
