import { Box, Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { DashboardMenu } from "../../DashboardMenu";
import { AchievementsToConsider } from "./components/AchievementsToConsider";
import { CurrentHealthAchievements } from "./components/CurrentHealthAchievements";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { useState } from "react";
import { AchievementType } from "common/definitions/EventDefinitions";
import { useQ_retrieveRecommendedUSPSTF } from "common/requests/codes_and_definitions/useQ_retrieveRecommendedUSPSTF";

const Achievements = () => {
	let [categoryFilter, set_categoryFilter] = useState<"ALL" | AchievementType>("ALL");

	const getRecommendedUSPSTFResponse = useQ_retrieveRecommendedUSPSTF();

	return (
		<Box>
			<DashboardMenu />
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<ContentSection flexGrow={1} title="Your Current Health Achievements">
						<QueryStatusDisplay queryResult={getRecommendedUSPSTFResponse}>
							{getRecommendedUSPSTFResponse?.data?.data?.data ? (
								<CurrentHealthAchievements
									uspstfItems={getRecommendedUSPSTFResponse.data.data.data.uspstf}
									completions={getRecommendedUSPSTFResponse.data.data.data.completions}
									categoryFilter={categoryFilter}
									set_categoryFilter={set_categoryFilter}
								/>
							) : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
				<Grid item xs={12}>
					<ContentSection flexGrow={1} title="Achievements to Consider">
						<QueryStatusDisplay queryResult={getRecommendedUSPSTFResponse}>
							{getRecommendedUSPSTFResponse?.data?.data?.data ? (
								<AchievementsToConsider categoryFilter={categoryFilter} uspstfItems={getRecommendedUSPSTFResponse.data.data.data.uspstf} />
							) : null}
						</QueryStatusDisplay>
					</ContentSection>
				</Grid>
			</Grid>
		</Box>
	);
};
export { Achievements };
