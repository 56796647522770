import { HealthIcon } from "common/components/Icons/HealthIcon";
import { health_icons_svg } from "../../common/components/Icons/HealthIcon";
import { IconToggle } from "common/components/Icons/IconToggle";

type AlertBadgeProps = {
	onClick: () => any;
	active?: boolean;
	alert?: boolean;
};

function AlertBadge({ onClick, active, alert }: AlertBadgeProps) {
	return (
		<IconToggle
			activeColor={alert ? "#d92450" : undefined}
			color={alert ? "#d92450" : undefined}
			onClick={onClick}
			icon={<HealthIcon iconSVG={health_icons_svg.faBellOn} />}
			active={active}
		/>
	);
}

export { AlertBadge };
