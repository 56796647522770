import { Box, Button, MenuItem, Typography } from "@mui/material";
import { ConsumerGetConditionDetailResponseModel, ModelsProviderTaxonomy } from "api";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardSelect } from "common/components/Inputs/StandardSelect";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";
import { DoctorTile } from "common/components/Tiles/DoctorTile";
import { DoctorRecord_Enhanced, ProviderEntity } from "common/definitions/entity/ProviderEntity";
import { CONVERT_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { DialogManager } from "common/managers/DialogManager";
import displayConverter from "common/utilities/displayConverter";
import { useQ_retrieveProvidersByLocation } from "findCare/requests/useQ_retrieveProvidersByLocation";
import { useContext, useMemo, useState } from "react";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { ConsumerGetConditionDetailResult, ModelsDoctorRecord } from "../../../../api/backend.schemas";
import { UseQueryResult } from "react-query";
import { AccountManagerContext } from "common/managers/AccountManager";
import { InNetworkSelect } from "common/components/Inputs/InNetworkSelect";

type DiagnosisDetailTopDoctorsProps = {
	getConditionDetailResponse: ConsumerGetConditionDetailResponseModel;
	searchRadius: number;
	set_searchRadius: React.Dispatch<React.SetStateAction<number>>;
	queryConditionDetailResult: UseQueryResult<ConsumerGetConditionDetailResult, unknown>;
};

function sortDoctorRecords(a?: DoctorRecord_Enhanced | ModelsDoctorRecord, b?: DoctorRecord_Enhanced | ModelsDoctorRecord) {
	if (!a || !b) {
		return !a ? (!b ? 0 : 1) : -1;
	}
	if (a.radiusRating !== b.radiusRating) {
		return a.radiusRating ? (b.radiusRating ? (a.radiusRating > b.radiusRating ? -1 : 1) : -1) : 1;
	}
	if (a.distanceMiles !== b.distanceMiles) {
		return a.distanceMiles ? (b.distanceMiles ? (a.distanceMiles > b.distanceMiles ? 1 : -1) : -1) : 1;
	}
	return 0;
}
export { sortDoctorRecords };

const searchRadiusSelectOptions = [5, 10, 25, 50, 100].map((x) => {
	let s = String(x);
	return (
		<MenuItem sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }} key={s} value={s}>
			{s} Miles
		</MenuItem>
	);
});

// const inNetworkSelectOptions = [false, true].map((x) => {
// 	let s = String(x);
// 	return (
// 		<MenuItem sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }} key={s} value={s}>
// 			{s} Miles
// 		</MenuItem>
// 	);
// });

const DiagnosisDetailTopDoctors = ({
	getConditionDetailResponse,
	searchRadius,
	set_searchRadius,
	queryConditionDetailResult,
}: DiagnosisDetailTopDoctorsProps) => {
	const accountContext = useContext(AccountManagerContext);
	const [taxonomyFilter, set_taxonomyFilter] = useState<string | null>(null);
	const [inNetwork, set_inNetwork] = useState<boolean>(!!accountContext.activeProviderOptions);
	//const [searchRadius, set_searchRadius] = useState<number>(25);

	const [taxonomyFilterCode] = useMemo(() => {
		if (taxonomyFilter) {
			return taxonomyFilter.split("||");
		}
		return [null, null];
	}, [taxonomyFilter]);

	const [validTaxonomyRelationship, treatingTaxonomies] = useMemo(() => {
		const validTaxonomyRelationship = getConditionDetailResponse.doctorsInAreaTaxonomyApproachMethod === "IcdRangeLookupTaxonomy";

		const treatingTaxonomies = getConditionDetailResponse.treatingTaxonomies;

		return [validTaxonomyRelationship, treatingTaxonomies];
	}, [getConditionDetailResponse]);

	const providersSearchResult = useQ_retrieveProvidersByLocation({
		clientLocation: GeolocationManager.clientLocation({ distanceRadiusMilesOverride: searchRadius }),
		params: {
			providerType: "physician",
			physicianType: "specialists",
			taxonomyCodes:
				taxonomyFilterCode ?? // IF NOT SPECIFIED THEN SEND ALL
				treatingTaxonomies?.reduce((accumulator, curValue) => {
					return accumulator + "||" + curValue.code ?? "NULL";
				}, ""),
			inNetwork: inNetwork,
		},
		// noRequest: taxonomyFilterCode || inNetwork ? false : true, // if no filter then no request
	});

	const unfilteredConvertedDoctors: Array<ProviderEntity> | null = useMemo(() => {
		// prioritize the direct useQ_retrieveProvidersByLocation call
		if (providersSearchResult.data?.data?.providerEntities) {
			return providersSearchResult.data.data.providerEntities;
		}

		if (Array.isArray(getConditionDetailResponse?.doctorsInArea) && getConditionDetailResponse.doctorsInArea.length > 0) {
			return getConditionDetailResponse.doctorsInArea.map((doctor, i) => {
				return CONVERT_DoctorRecord_Entity(doctor, "AUTO");
			});
		}
		return null;
	}, [getConditionDetailResponse, providersSearchResult]);

	const doctorTiles = useMemo(() => {
		if (Array.isArray(unfilteredConvertedDoctors)) {
			return unfilteredConvertedDoctors
				?.filter((doctor: ProviderEntity) => {
					if (!taxonomyFilterCode) {
						return true;
					}
					return doctor._providerRecord?.taxonomies.some((t) => {
						if (t.code === taxonomyFilterCode) {
							return true;
						}
						return false;
					});
				})
				.sort((_a, _b) => {
					return sortDoctorRecords(_a._providerRecord, _b._providerRecord);
				})
				.map((doctor, i) => {
					let phone = displayConverter.formatPhoneNumber(doctor._providerRecord?.phoneNumber ?? "");
					//t entity = CONVERT_DoctorRecord_Entity(doctor, "AUTO");
					doctor.descriptor = doctor._providerRecord?.specialties ?? "";

					let content = (
						<Box>
							<Button
								variant="text"
								onClick={() => {
									DialogManager.open("provider-details", doctor._providerRecord?.npi ?? "MISSING NPI");
								}}
							>
								View Profile
							</Button>
							<Button variant="text">{phone}</Button>
						</Box>
					);

					return (
						<Box key={i} sx={{ paddingBottom: ".5rem" }}>
							<DoctorTile key={i} Entity={doctor} content={content} />
						</Box>
					);
				})
				.slice(0, 25);
		}
		return null;
	}, [unfilteredConvertedDoctors, taxonomyFilterCode]);

	const taxonomySelectOptions = useMemo(() => {
		if (!treatingTaxonomies || treatingTaxonomies.length <= 1) {
			return null;
		}
		return treatingTaxonomies.map((t: ModelsProviderTaxonomy, i) => {
			return (
				<MenuItem
					sx={{ whiteSpace: "pre-wrap", maxWidth: "20rem", paddingBottom: ".5rem", paddingTop: ".5rem" }}
					key={t.code}
					value={`${t.code}||${t.displayName}`}
					data-taxonomy={t}
				>
					{t.displayName}
				</MenuItem>
			);
		});
	}, [treatingTaxonomies]);

	return (
		<ContentSection title="Top Doctors In Your Area">
			<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "8px" }}>
				<Box style={{ maxWidth: "160px", flexGrow: "1", paddingRight: "8px" }}>
					<StandardSelect
						label="Search Radius"
						labelId="search-radius-select-label"
						id="search-radius-select"
						variant="standard"
						value={String(searchRadius) ?? ""}
						onChange={(x) => {
							if (typeof x.target.value === "string") {
								set_searchRadius(parseInt(x.target.value));
							}
						}}
					>
						{searchRadiusSelectOptions}
					</StandardSelect>
				</Box>
				{(validTaxonomyRelationship || !validTaxonomyRelationship) && treatingTaxonomies && treatingTaxonomies.length > 1 ? (
					<Box style={{ maxWidth: "360px", flexGrow: "1", paddingRight: "8px" }}>
						<StandardSelect
							label="Provider Type"
							labelId="taxonomy-select-label"
							id="taxonomy-select"
							variant="standard"
							value={taxonomyFilter ?? ""}
							nullable
							onChange={(x) => {
								// console.debug(x, x.target.value);
								if (typeof x.target.value === "string") {
									if (x.target.value === "CLEAR_SELECTION") {
										set_taxonomyFilter(null);
									} else {
										set_taxonomyFilter(x.target.value);
									}
								}
							}}
						>
							{taxonomySelectOptions}
						</StandardSelect>
					</Box>
				) : validTaxonomyRelationship && treatingTaxonomies && treatingTaxonomies.length > 0 ? (
					<Typography> {treatingTaxonomies[0].displayName} </Typography>
				) : null}
				{accountContext.activeProviderOptions ? (
					<Box style={{ maxWidth: "160px", flexGrow: "1", paddingRight: "8px" }}>
						<InNetworkSelect set_inNetwork={set_inNetwork} inNetwork={inNetwork} />
						{/* <StandardSelect
							label="In Network"
							labelId="in-network-select-label"
							id="in-network-select"
							variant="standard"
							value={inNetwork ? "TRUE" : "FALSE"}
							onChange={(x) => {
								set_inNetwork(x.target.value === "TRUE");
							}}
						>
							{inNetworkSelectOptions}
						</StandardSelect> */}
					</Box>
				) : null}
			</Box>
			<QueryStatusDisplay queryResult={providersSearchResult} forceRenderChildren={providersSearchResult.data?.errorCode === "NO-REQUEST"}>
				<QueryStatusDisplay queryResult={queryConditionDetailResult}>
					<LoadMorePaginator
						pageSize={5}
						data={doctorTiles}
						renderItem={(providerTile: any, i?: number) => {
							return providerTile;
						}}
						noDataMessage={`No results found within ${searchRadius} miles`}
					/>
				</QueryStatusDisplay>
			</QueryStatusDisplay>
		</ContentSection>
	);
};

export { DiagnosisDetailTopDoctors };
