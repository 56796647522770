import { getDoctorsByNPIs, ModelsDoctorRecord } from "api";
import { DoctorRecord_Enhanced, ENHANCE_DoctorRecord } from "common/definitions/entity/ProviderEntity";
import { DynamicDataManager } from "common/managers/DynamicDataManager";

async function GetProviderMapByNPIs(commaDelimitedNPIs: string): Promise<Map<string, ModelsDoctorRecord> | false> {
	if (commaDelimitedNPIs === "") {
		return DynamicDataManager.npis.doctorRecord_NPIMap;
	}
	let npiMap = DynamicDataManager.npis.doctorRecord_NPIMap;
	let npis = commaDelimitedNPIs.split(",");
	npis = npis.filter((npi) => (npiMap.has(npi) ? false : true));
	if (npis.length > 0) {
		let getDoctorsByNPIs_response: CIM = await getDoctorsByNPIs({ npis: npis.join(",") });
		if (Array.isArray(getDoctorsByNPIs_response?.response?.doctorRecords)) {
			// minor glitch where un-found npis will continue to be requested
			getDoctorsByNPIs_response.response.doctorRecords.forEach((x: ModelsDoctorRecord) => {
				if (x.npi) {
					x = ENHANCE_DoctorRecord(x);
					npiMap.set(x.npi as string, x as DoctorRecord_Enhanced);
				}
			});
		}
	}
	return npiMap;
}
export { GetProviderMapByNPIs };
