import Icon from "common/components/Icons/Icon";
import { icons } from "theme";
import { NavigationItemHorizontal } from "../main/navigation/NavigationItemHorizontal";
import { HealthIcon, health_icons_svg } from "../common/components/Icons/HealthIcon";
import { AdaptiveMenu } from "common/components/Menus/AdaptiveMenu";
import { Box } from "@mui/material";

const DashboardMenu = () => {
	return (
		<Box>
			<AdaptiveMenu id="DashboardMenu">
				<NavigationItemHorizontal label="Your Overview" icon={<Icon icon={icons.heartPulse} />} to="/dashboard/myoverview" />
				<NavigationItemHorizontal label="Your Cost" icon={<HealthIcon iconSVG={health_icons_svg.moneybag} />} to="/dashboard/mycost" />
				<NavigationItemHorizontal label="Your Achievements" icon={<HealthIcon iconSVG={health_icons_svg.trophy} />} to="/dashboard/achievements" />
			</AdaptiveMenu>
		</Box>
	);
};

export { DashboardMenu };
