import { AppBar, Box, Container, Grid, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ContentSection } from "common/components/Containers/ContentSection";
import OverviewMap from "common/components/Maps/OverviewMap";
import Geocode from "react-geocode";
import { useQ_getSimpleHospital } from "./requests/useQ_getSimpleHospital";

function HospitalDetail({ id }: { id: string }) {
	const [latlng, set_latlng] = useState<{ lat: number; lng: number } | null>(null);
	const getSimpleHospitalResponse = useQ_getSimpleHospital({ id });

	const [hospital, address] = useMemo(() => {
		if (getSimpleHospitalResponse?.data?.data?.response?.data.simpleHospital) {
			let hospital = getSimpleHospitalResponse?.data?.data?.response?.data.simpleHospital;
			let address: string | null = null;
			if (hospital && hospital.address) {
				address = hospital.address;
			}
			return [hospital, address];
		}
		return [null, null];
	}, [getSimpleHospitalResponse]);

	useEffect(() => {
		Geocode.setApiKey("AIzaSyD6W44D2JL7oInVPYV_Jua5kJUlKHMZo6Q");
		Geocode.fromAddress(address ? address : "Unknown").then(
			(response) => {
				const location = response.results[0].geometry.location;
				set_latlng({ lat: location.lat, lng: location.lng });
			},
			(error) => {
				console.error(error);
			}
		);
	}, [address]);

	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">{hospital?.name}</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("hospital-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<QueryStatusDisplay queryResult={getSimpleHospitalResponse}>
				<Container maxWidth="md" sx={{ py: "1rem" }}>
					{hospital ? (
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ContentSection title="General Info">
									<Box style={{ justifyContent: "center", display: "flex", flexDirection: "column", flex: 1 }}>
										<Box sx={{ flexDirection: "column", paddingTop: "1rem", paddingBottom: "1rem" }}>
											<Typography sx={{ fontWeight: "bold" }}>Hospital Name</Typography>
											<Typography>{hospital.name}</Typography>
										</Box>{" "}
										<Box sx={{ display: "flex", flexDirection: "row", paddingBottom: "1rem" }}>
											<Box flex={1} sx={{ flexDirection: "column" }}>
												<Typography sx={{ fontWeight: "bold" }}>Overview</Typography>
												<Typography>{hospital.overview}</Typography>
											</Box>
										</Box>
									</Box>
								</ContentSection>
							</Grid>
							<Grid item xs={12}>
								<ContentSection title="Location & Contact">
									<Box style={{ display: "flex", width: "100%", paddingBottom: "1rem", paddingTop: ".5rem" }}>
										<Box sx={{ flex: "50%", paddingRight: "1rem", borderRadius: ".25rem", minHeight: "15rem" }}>
											{latlng ? <OverviewMap center={latlng} /> : <Typography>Map data not available.</Typography>}
										</Box>
										<Box sx={{ display: "flex", flexDirection: "column", flex: "50%" }}>
											<Typography sx={{ fontWeight: "bold" }}>Hospital Address</Typography>
											<Typography>
												{address}

												{` ${hospital.state} ${hospital.zip}`}
											</Typography>
										</Box>
									</Box>
								</ContentSection>
							</Grid>
						</Grid>
					) : (
						<Typography>Hospital {id} not found</Typography>
					)}
				</Container>
			</QueryStatusDisplay>
		</Box>
	);
}

export { HospitalDetail };
