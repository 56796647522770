import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { styled } from "@mui/system";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

// const Icon = styled(FontAwesomeIcon)({}); // Ended up failing with change of FontAwesomeIcon type imports from "@fortawesome/pro-solid-svg-icons" & "@fortawesome/free-solid-svg-icons"
// As a grumpy old man I feel like this sort of <del>over</del><ins>highly</ins>-engineered 'magic' creates a lot of unhelpful obfuscation

const Icon = (props: { icon: IconDefinition | any | string; [key: string]: any }) => {
	return <FontAwesomeIcon {...props} icon={props.icon as CIM} />;
};

export default Icon;
export { Icon };
