import { Box } from "@mui/material";
import { ReactNode } from "react";

const Step = ({
  label,
  filled,
  last,
  color,
  description,
}: {
  label: string | number;
  filled: boolean;
  last: boolean;
  color: string;
  description: ReactNode;
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          // paddingTop: "8px",
        }}
      >
        <Box
          sx={{
            width: "52px",
            height: "52px",
            borderRadius: "26px",
            backgroundColor: filled ? color : "white",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            color: "white",
            borderStyle: "solid",
            borderColor: color,
            borderWidth: "4px",
            boxSizing: "border-box",
          }}
        >
          <Box
            style={{
              fontSize: "20px",
              marginTop: "-4px",
              color: filled ? "white" : "gray",
              fontWeight: "600",
            }}
          >
            {label}
          </Box>
        </Box>
        {!last ? <ProgressBar color={color} /> : null}
      </Box>
      <Box
        style={{
          paddingLeft: "16px",
          paddingRight: "8px",
          paddingTop: "0px",
          paddingBottom: "24px",
          marginTop: "-8px",
          textAlign: "left",
        }}
      >
        {description}
      </Box>
    </Box>
  );
};

const ProgressBar = ({ color }: { color: string }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        minHeight: "32px",
        width: "52px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          minHeight: "32px",
          width: "4px",
          backgroundColor: color,
        }}
      />
    </Box>
  );
};

type VerticalDescriptionStepsProps = {
  steps: Array<ReactNode>;
  current: number;
  color?: string;
};
const VerticalDescriptionSteps = ({
  steps,
  current,
  color,
}: VerticalDescriptionStepsProps) => {
  let stepDisplay = [];
  for (let i = 0; i < steps.length; i++) {
    stepDisplay.push(
      <Step
        label={i + 1}
        description={steps[i]}
        filled={i < current}
        key={i}
        last={i === steps.length - 1}
        color={color ?? "core.electricblue"}
      />
    );
  }
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
        marginTop: "16px",
      }}
    >
      {stepDisplay}
    </Box>
  );
};

export { VerticalDescriptionSteps };
