import { Entity } from "../EntityDefinitions";
import { ModelsRxSenseTreatmentResult } from "../../../api/backend.schemas";

interface MedicationEntity extends Entity {
	entityTypeName: "medication";
	_medicationRecord?: ModelsRxSenseTreatmentResult;
}

// type SingleCareTreatmentItem = {
//   display_name: string;
//   hit_type: string;
//   id: string;
//   is_generic: boolean;
//   location: { lat: number; lon: number };
//   ndc: string;
//   score: number;
//   seo_name: string;
// };

const isMedicationEntity = (x: Entity): x is MedicationEntity => {
	if (x.entityTypeName === "medication") {
		return true;
	}
	return false;
};

export { isMedicationEntity };
export type { MedicationEntity };
