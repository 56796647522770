import { ConsumerCareScoreValue } from "api";

type DataDisplayType = "USD";

export type { DataDisplayType };

const displayConverter = {
	dataTypeConvert(dataType: DataDisplayType | undefined, value: string | number | boolean) {
		switch (dataType) {
			case "USD":
				if (typeof value === "number") {
					return displayConverter.toUSD(value);
				} else {
					return value;
				}
		}
		return value;
	},
	insertThousandsCommas(value: number | string | undefined) {
		if (value === undefined) {
			return "";
		}
		let s = Array.from(String(value));

		let decimal_offset = s.indexOf(".") === -1 ? 0 : s.length - s.indexOf(".");
		for (let i = 3 + decimal_offset; s.length - i > 0; i += 4) {
			s.splice(s.length - i, 0, ",");
		}
		return s.join("");
	},
	toUSD(value: number | undefined, options?: { cents?: boolean }) {
		if (value === undefined) {
			return "";
		}
		// add commas and round to two decimals
		// return "$" + displayConverter.insertThousandsCommas(value % 1 > 0 || options?.cents ? value.toFixed(2) : value);
		return (
			"$" +
			displayConverter.insertThousandsCommas(
				value % 1 > 0 ? value.toFixed(!options || (options.cents !== false && options.cents !== undefined) ? 2 : 0) : value
			)
		);
	},
	toPercent(value: number | undefined, options?: { plus_minus?: boolean }) {
		if (value === undefined) {
			return "";
		}
		return (options?.plus_minus ? (value > 0 ? "+" : "-") : "") + Math.round(value * 100) + "%";
	},
	display(value: string | number | undefined): string {
		if (value === undefined) {
			return "";
		}
		return String(value);
	},
	filterNonNumeric(value: string) {
		return value.replace(/\D/g, "");
	},
	leftPadNumberToString(n: number, padCount: number) {
		//1 tens, 2hundreds, 3thousands etc
		let s = String(n);
		return s.padStart(padCount, "0");
	},
	roundToHalf(x: number) {
		let whole = Math.floor(x);
		let perc = x % 1;
		if (perc !== 0 && perc > 0.25) {
			if (perc >= 0.75) {
				perc = 1;
			} else {
				perc = 0.5;
			}
		} else {
			perc = 0;
		}
		return whole + perc;
	},
	displayConsumerCareScoreValueFraction(careScoreValue: ConsumerCareScoreValue, displayValueMax: number = 10): string {
		if (careScoreValue.score !== undefined && careScoreValue.maxScoreValue !== undefined) {
			let score = careScoreValue.score / careScoreValue.maxScoreValue;
			return `${displayConverter.roundToHalf(score * displayValueMax)}/${displayValueMax}`;
		}
		return `?/${displayValueMax}`;
	},
	formatPhoneNumber(n: string) {
		let cleaned = ("" + n).replace(/\D/g, "");
		let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
		if (match) {
			return "(" + match[1] + ") " + match[2] + "-" + match[3];
		}
		return null;
	},
};

export default displayConverter;
