import { Box, FormControl, InputLabel, Select, MenuItem, List, ListItem, Stack, Typography } from "@mui/material";
import { HealthIcon, health_icons_svg } from "common/components/Icons/HealthIcon";
import { Link } from "react-router-dom";
import { palette } from "theme";
import { config } from "config";
import { DataSourceOptionSecondary } from "api";

type AnthemProps = {
	setAnthemPlan: any;
	healthPlanOptions: Array<DataSourceOptionSecondary> | null;
};

function AnthemDropdown(props: AnthemProps) {
	const environment = config.Environment.ENVIRONMENT_NAME;

	return (
		<>
			<Box paddingTop={1}>
				<FormControl fullWidth>
					<InputLabel variant="standard">Choose your Anthem plan</InputLabel>

					{environment === "Production" ? (
						<>
							<Select
								defaultValue="Amerigroup"
								variant="standard"
								onChange={(x) => {
									console.debug(x.target.value);
									props.setAnthemPlan(x.target.value as CIM);
								}}
							>
								{props.healthPlanOptions?.map((x) => {
									return (
										<MenuItem key={x.planCode} value={x.planCode}>
											{x.displayName}
										</MenuItem>
									);
								})}
								{/* <MenuItem value={"Amerigroup"}>Amerigroup</MenuItem>
								<MenuItem value={"Amerigroup Medicaid"}>Amerigroup Medicaid</MenuItem>
								<MenuItem value={"Anthem"}>Anthem</MenuItem>
								<MenuItem value={"Anthem Blue Cross CA"}>Anthem Blue Cross CA</MenuItem>
								<MenuItem value={"Anthem Medicaid"}>Anthem Medicaid</MenuItem>
								<MenuItem value={"Blue Cross Blue Shield Kansas Medicare Part D"}>Blue Cross Blue Shield Kansas Medicare Part D</MenuItem>
								<MenuItem value={"Blue Cross Blue Shield Kansas City"}>Blue Cross Blue Shield Kansas City</MenuItem>
								<MenuItem value={"Blue Medicare Advantage"}>Blue Medicare Advantage</MenuItem>
								<MenuItem value={"Blue Rx New York"}>Blue Rx New York</MenuItem>
								<MenuItem value={"Clear Health Alliance"}>Clear Health Alliance</MenuItem>
								<MenuItem value={"Dell Children's Health Plan"}>Dell Children's Health Plan</MenuItem>
								<MenuItem value={"Empire Blue"}>Empire Blue</MenuItem>
								<MenuItem value={"Empire Blue Medicaid"}>Empire Blue Medicaid</MenuItem>
								<MenuItem value={"Healthy Blue LA"}>Healthy Blue LA</MenuItem>
								<MenuItem value={"Healthy Blue LA Medicaid"}>Healthy Blue LA Medicaid</MenuItem>
								<MenuItem value={"Healthy Blue MO"}>Healthy Blue MO</MenuItem>
								<MenuItem value={"Healthy Blue MO Medicaid"}>Healthy Blue MO Medicaid</MenuItem>
								<MenuItem value={"Healthy Blue NC Medicaid"}>Healthy Blue NC Medicaid</MenuItem>
								<MenuItem value={"Healthy Blue NE"}>Healthy Blue NE</MenuItem>
								<MenuItem value={"Healthy Blue North Carolina"}>Healthy Blue North Carolina</MenuItem>
								<MenuItem value={"Healthy Blue SC"}>Healthy Blue SC</MenuItem>
								<MenuItem value={"Highmark Western New York"}>Highmark Western New York</MenuItem>
								<MenuItem value={"Simply Healthcare Medicaid"}>Simply Healthcare Medicaid</MenuItem>
								<MenuItem value={"Simply Healthcare Medicare"}>Simply Healthcare Medicare</MenuItem>
								<MenuItem value={"Summit Community Care"}>Summit Community Care</MenuItem>
								<MenuItem value={"Unicare"}>Unicare</MenuItem>
								<MenuItem value={"Unicare Massachusetts"}>Unicare Massachusetts</MenuItem>
								<MenuItem value={"Unicare Medicaid"}>Unicare Medicaid</MenuItem>
								<MenuItem value={"Wellpoint"}>Wellpoint</MenuItem> */}
							</Select>
						</>
					) : (
						<>
							<Select
								defaultValue="medicare"
								variant="standard"
								onChange={(x) => {
									console.debug(x.target.value);
									props.setAnthemPlan(x.target.value as CIM);
								}}
							>
								<MenuItem value={"test"}>SANDBOX TEST</MenuItem>
							</Select>
						</>
					)}
				</FormControl>
			</Box>
			<Box
				sx={{
					borderRadius: "1rem",
					fontSize: "1.25rem",
					p: 2,
					mt: 2,
					backgroundColor: palette.gray.light,
				}}
			>
				<Stack flexDirection={"row"} sx={{ pb: 1, pt: 1 }}>
					<Box>
						<HealthIcon color={palette.error.main} size={24} iconSVG={health_icons_svg.faBellOn} />
					</Box>

					<Typography sx={{ pl: 1, fontWeight: 500, color: palette.error.main }}>Anthem only shares data for select insurance plans.</Typography>
				</Stack>
				<Typography sx={{ fontWeight: 700 }}>Supported Plans:</Typography>
				<Typography component="div">
					<List
						sx={{
							listStyleType: "disc",
							pl: 4,
							"& .MuiListItem-root": {
								display: "list-item",
							},
						}}
					>
						<ListItem disablePadding>Anthem Medicare Advantage Plans</ListItem>
						<ListItem disablePadding>Anthem Health Plans Purchased on Healthcare.gov</ListItem>
						<ListItem disablePadding>Anthem Medicaid Plans</ListItem>
					</List>
				</Typography>
				<Stack component="div" sx={{ flexDirection: "row", justifyContent: "flex-end", pt: 2 }}>
					<Link to={"/account-active"}>
						<Typography sx={{ fontSize: "1rem", color: palette.core.blue }}>Skip Connection</Typography>
					</Link>
				</Stack>
			</Box>
		</>
	);
}

export default AnthemDropdown;
