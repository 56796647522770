import { ConsumerTreatmentGetDrugStructureDataResponseModel, ConsumerTreatmentGetDrugStructureDataResult, getDrugStructureData } from "api";
import { apiResponseReader, ProcessedResponse } from "common/utilities/apiResponseReader";
import { useQuery, UseQueryResult } from "react-query";

type UnprocessedSingleCareMedicationDetailSingleCareResponseData = Array<{
	Key: string;
	Value: Array<{
		Key: string;
		Value: Array<{
			Key: string;
			Value: Array<{
				Key: string;
				Value: SingleCareMedicationDetail;
			}>;
		}>;
	}>;
}>;

interface ConsumerTreatmentGetDrugStructureDataResult_X extends Omit<ConsumerTreatmentGetDrugStructureDataResult, "response"> {
	response: ConsumerTreatmentGetDrugStructureDataResponseModel_X;
}

interface ConsumerTreatmentGetDrugStructureDataResponseModel_X extends Omit<ConsumerTreatmentGetDrugStructureDataResponseModel, "queryResult"> {
	queryResult: MedicationDetailDataPackage;
}

interface MedicationDetailDataPackage {
	[key: string]: { [key: string]: { [key: string]: { [key: string]: SingleCareMedicationDetail } } };
}

interface SingleCareMedicationDetail {
	CanonicalURL: string;
	DeaClassCode: string;
	Description: string;
	DisplayQuantity: number;
	Dosage: string;
	Form: string;
	FullName: string;
	GPI: string;
	Inactive: boolean;
	IsGeneric: boolean;
	MarketingForm: string;
	MarketingName: string;
	MarketingSEOName: string;
	MetaDescription: string;
	MetaTitle: string;
	NDC: string;
	Name: string;
	NumScripts: number;
	PackageQuantity: number;
	PackageSize: number;
	PackageSizeUOM: string;
	PriceUsualAndCustomary: number;
	Quantity: number;
	Repack: boolean;
	RxNorm: string;
	SEOName: string;
	SEONoIndex: true;
	Strength: number;
	StrengthString: string;
	StrengthUnitOfMeasure: string;
	TopPrescriptionSortOrder: number;
	Treatment: string;
	UnitDoseUnitUsePkgCode: string;
	_path: string;
}

const CONVERT_UnprocessedSingleCareMedicationDetailSingleCareResponseData = (
	response: UnprocessedSingleCareMedicationDetailSingleCareResponseData
): MedicationDetailDataPackage => {
	const dataPackage: MedicationDetailDataPackage = {};
	let L1: any; //Products
	let L2: any; //Form
	let L3: any; //Dosage
	// let L4: any; //Count
	response.forEach((V1) => {
		L1 = {};
		V1.Value.forEach((V2) => {
			L2 = {};
			V2.Value.forEach((V3) => {
				L3 = {};
				V3.Value.forEach((V4) => {
					V4.Value._path = `${V1.Key}<|>${V2.Key}<|>${V3.Key}<|>${V4.Key}`;
					L3[V4.Key] = V4.Value;
				});
				L2[V3.Key] = L3;
			});
			L1[V2.Key] = L2;
		});
		dataPackage[V1.Key] = L1;
	});
	// console.debug("dataPackage", dataPackage);
	return dataPackage;
};

type GetDrugStructureDataParams = { medicationID: string };

async function GetDrugStructureData({
	medicationID,
}: GetDrugStructureDataParams): Promise<ConsumerTreatmentGetDrugStructureDataResult_X | ProcessedResponse<any>> {
	let result_X = await getDrugStructureData({ query: medicationID });

	if (result_X)
		if (!apiResponseReader.typeCheck_ModelsOperationStatus(result_X) && result_X.response?.queryResult) {
			// console.log("parse result_X.response?.queryResult");
			// console.debug(result_X.response);

			console.debug(result_X.response.drugStructureData);
			let queryResult: any;
			try {
				queryResult = JSON.parse(result_X.response.queryResult);
				// console.debug(queryResult);
			} catch (e) {
				console.error("Failed to parse GetDrugStructureData queryResult");
				return apiResponseReader.createProcessedResponse({
					errorMessage: "Failed to parse GetDrugStructureData queryResult",
					_debug: result_X.response.queryResult,
				});
			}

			if (queryResult.hasOwnProperty("Value")) {
				(result_X as any).response.queryResult = CONVERT_UnprocessedSingleCareMedicationDetailSingleCareResponseData(queryResult.Value);
				return result_X as unknown as ConsumerTreatmentGetDrugStructureDataResult_X;
			}

			// console.debug(result_X);
		}
	return apiResponseReader.createProcessedResponse({ _debug: result_X });
}

function useQ_getDrugStructureData(params: GetDrugStructureDataParams): UseQueryResult<ConsumerTreatmentGetDrugStructureDataResult_X | ProcessedResponse<any>> {
	return useQuery(["GetDrugStructureData", `GetDrugStructureData${JSON.stringify(params)}`], GetDrugStructureData.bind(null, params));
}

export { useQ_getDrugStructureData };
export type { MedicationDetailDataPackage, SingleCareMedicationDetail };
