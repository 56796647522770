import { lighten, MenuItem, Select, SelectProps, Typography } from "@mui/material";
import { DateRangeSelection, StandardOption } from "common/types/generic";
import { dateConverter, DateRangeSelect_IntervalType } from "common/utilities/dateConverter";
import { useEffect, useMemo, useState } from "react";
import { colorTransforms, palette } from "theme";

// type DateRangeSelect_IntervalType = "month";
interface DateRangeSelectProps extends SelectProps {
	nullable?: boolean; // props.onChange must handle the "CLEAR_SELECTION" value
	dateRangeOptions?: Array<StandardOption<string, number | string>>;
	dateRangeSelection: DateRangeSelection;
	intervalType?: DateRangeSelect_IntervalType;
}

const defaultDateRangeOptions_Months: Array<StandardOption<string, number>> = [
	{ name: "Past 1 Month", value: 1 },
	{ name: "Past 3 Months", value: 3 },
	{ name: "Past 6 Months", value: 6 },
	{ name: "Past 12 Months", value: 12 },
	{ name: "Past 2 Years", value: 24 },
	{ name: "Past 3 Years", value: 36 },
	{ name: "Past 4 Years", value: 48 },
	{ name: "Past 5 Years", value: 60 },
];

const DateRangeSelect = (props: DateRangeSelectProps) => {
	const [internalStartValue, set_internalStartValue] = useState<number | string>("");
	// const [internalEndValue, set_internalEndValue] = useState<number | "">("");

	let { intervalType, dateRangeOptions, dateRangeSelection } = props;
	intervalType = intervalType ?? "month";
	// REMOVE NON SelectProps properties from DateRangeSelectProps
	const scrubbedProps: SelectProps = useMemo(() => {
		let o: SelectProps = Object.assign({}, props);
		delete (o as CIM).nullable;
		delete (o as CIM).dateRangeOptions;
		delete (o as CIM).dateRangeSelection;
		o.value = internalStartValue;
		return o;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props, internalStartValue]);

	/// CACHE the options values using defaults if not provided
	const options = useMemo(() => {
		let options =
			dateRangeOptions ??
			(() => {
				switch (intervalType) {
					case "month":
					default:
						return defaultDateRangeOptions_Months;
				}
			})();

		return options;
	}, [dateRangeOptions, intervalType]);

	// cache the options list render
	const optionsRender = useMemo(() => {
		return options.map((option, i) => {
			return (
				<MenuItem key={i} value={option.value}>
					{option.name}
				</MenuItem>
			);
		});
	}, [options]);

	const updateOnChange_startDate = (val: number | string) => {
		const parsedVal = parseInt(String(val));
		if (!Number.isNaN(parsedVal)) {
			let new_date = dateConverter.getDateForMonthsAgo(parsedVal);
			dateRangeSelection.set_startDate(new_date);
			let v = dateConverter.reactiveSetValueFromDate(new_date, options, intervalType as DateRangeSelect_IntervalType);
			set_internalStartValue(v);
		} else {
			set_internalStartValue(val);
			var d = new Date(new Date().getFullYear(), 0, 1);
			dateRangeSelection.set_startDate(d);
		}
	};

	useEffect(() => {
		// Set intial selection value to dateRangeSelection.startDate
		if (dateRangeSelection.startDate && internalStartValue === "") {
			let v = dateConverter.reactiveSetValueFromDate(dateRangeSelection.startDate, options, intervalType as DateRangeSelect_IntervalType);
			set_internalStartValue(v);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Select
				{...scrubbedProps}
				onChange={(x, y) => {
					console.log("onChange manual select change");
					let val = x.target.value;
					if (typeof val === "number" || typeof val === "string") {
						updateOnChange_startDate(val);
					}
				}}
				variant="standard"
				sx={{
					color: palette.gray.dark,
					fontWeight: "500",
					"&::before": { borderWidth: 2, borderColor: palette.gray.dark },
					"&:hover:not(.Mui-disabled)::before": { borderWidth: 2, borderColor: lighten(palette.core.blue, colorTransforms.lightenUp) },
					"&:not(.Mui-disabled)::after": { borderWidth: 2, borderColor: palette.core.blue },
					"& .MuiSelect-select": { borderTopLeftRadius: "8px", borderTopRightRadius: "8px", paddingLeft: "8px" },
				}}
			>
				{props.nullable ? (
					<MenuItem key="CLEAR_SELECTION" value={"CLEAR_SELECTION"}>
						<Typography style={{ color: "gray" }}>Clear Selection</Typography>
					</MenuItem>
				) : null}
				{optionsRender}
			</Select>
			{/* <p>
				-{String(scrubbedProps.value)}-{String(dateRangeSelection.startDate)}
			</p> */}
		</>
	);
};

export { DateRangeSelect };
