import { Box, lighten, MenuItem, Select, SelectProps, Typography } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { colorTransforms, palette } from "theme";

interface StandardSelectProps extends SelectProps {
	nullable?: boolean; // props.onChange must handle the "CLEAR_SELECTION" value
	label?: string;
	placeholder?: string;
	adaptive_label?: boolean;
	label_after?: ReactNode;
}

const StandardSelect = (props: StandardSelectProps = {}) => {
	const scrubbedProps = useMemo(() => {
		let o = Object.assign({}, props);
		delete o.nullable;
		delete o.adaptive_label;
		delete o.label_after;
		return o;
	}, [props]);

	return (
		<Box style={{ width: "100%", position: "relative" }}>
			{props.label && props.labelId ? (
				props.adaptive_label ? (
					<Typography
						component="label"
						id={props.labelId}
						style={{
							fontWeight: "800",
							lineHeight: "1.4375em",
							letterSpacing: "0.00938em",
							padding: 0,
							display: "block",
							transformOrigin: "top left",
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
							maxWidth: "100%",
							position: "absolute",
							left: 0,
							top: -16,
							transition: "all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
							color: palette.gray.dark,
						}}
						sx={
							props.value === null || props.value === ""
								? { transform: "translate(0, 20px) scale(1)", color: palette.gray.dark }
								: {
										transform: "translate(0, 0px) scale(.75)",
								  }
						}
					>
						{props.label}
					</Typography>
				) : (
					<Typography
						id={props.labelId}
						variant="h5"
						marginBottom={".5rem"}
						component="div"
						sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
					>
						{props.label} {props.label_after ? props.label_after : null}
					</Typography>
				)
			) : null}
			{props.placeholder && !props.value ? (
				<Box style={{ display: "block", height: "0px", fontWeight: "500", paddingLeft: "8px" }}>
					<MenuItem>
						<Typography style={{ color: "gray", fontWeight: 500 }}>{props.placeholder}</Typography>
					</MenuItem>
				</Box>
			) : null}
			<Select
				{...scrubbedProps}
				variant="standard"
				style={{
					whiteSpace: "normal",
				}}
				sx={{
					width: "100%",
					color: palette.gray.dark,
					fontWeight: "500",
					"&::before": { borderWidth: 2, borderColor: palette.gray.dark },
					"&:hover:not(.Mui-disabled)::before": { borderWidth: 2, borderColor: lighten(palette.core.blue, colorTransforms.lightenUp) },
					"&:not(.Mui-disabled)::after": { borderWidth: 2, borderColor: palette.core.blue },
					"& .MuiSelect-select": {
						borderTopLeftRadius: "8px",
						borderTopRightRadius: "8px",
						paddingLeft: "8px",
						whiteSpace: "normal",
						textOverflow: "initial",
					},
				}}
			>
				{props.nullable ? (
					<MenuItem key="CLEAR_SELECTION" value={"CLEAR_SELECTION"}>
						<Typography style={{ color: "gray" }}>Clear Selection</Typography>
					</MenuItem>
				) : null}
				{props.children}
			</Select>
		</Box>
	);
};

export { StandardSelect };
