import { Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { EventDescription } from "common/definitions/EventDefinitions";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { ReactNode } from "react";
import { HealthSnapshotMedicationsKeyFindings } from "./HealthSnapshotMedicationsKeyFindings";
import { HealthSnapshotMedicationsPanel } from "./HealthSnapshotMedicationsPanel";

type HealthSnapshotMedicationsProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
	keyFindings: EventDescription[];
	healthPlanReauthTag: ReactNode;
};

const HealthSnapshotMedications = ({ patientEOBData, keyFindings, healthPlanReauthTag }: HealthSnapshotMedicationsProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={8}>
				<ContentSection title="Medications" titleBarContent={healthPlanReauthTag}>
					<HealthSnapshotMedicationsPanel patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
			<Grid item xs={12} md={4}>
				<ContentSection title="Key Findings">
					<HealthSnapshotMedicationsKeyFindings keyFindings={keyFindings} patientEOBData={patientEOBData} />
				</ContentSection>
			</Grid>
		</Grid>
	);
};

export { HealthSnapshotMedications };
