import { AppBar, Box, Container, Grid, Typography } from "@mui/material";
import { useQ_providerDetail } from "details/providerDetails/requests/useQ_providerDetail";
import { useMemo } from "react";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { CloseButton } from "common/components/Buttons/CloseButton";
import { style } from "theme";
import { DialogManager } from "common/managers/DialogManager";
import { ConsumerDoctorGetProviderDetailResponseModel } from "api";
import { CONVERT_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { ConstructName_ModelDoctorRecord } from "common/definitions/entity/ProviderEntity";
import { ContentSection } from "common/components/Containers/ContentSection";
import { ProviderProfileTile } from "common/components/Tiles/ProviderProfileTile";
import displayConverter from "common/utilities/displayConverter";
import AddressMap from "common/components/Maps/AddressMap";

function ProviderDetail({ providerID }: { providerID: string }) {
	const requestResponse = useQ_providerDetail({ npi: providerID });
	const providerDetail: ConsumerDoctorGetProviderDetailResponseModel | null = requestResponse.data?.response ?? null;
	const prov_entity = useMemo(() => {
		if (providerDetail) {
			return CONVERT_DoctorRecord_Entity(providerDetail.doctorRecord);
		}
		return null;
	}, [providerDetail]);

	const providerAddress = useMemo(() => {
		if (providerDetail?.doctorRecord?.address) {
			let docRecord = providerDetail.doctorRecord;
			return `${docRecord.address} ${docRecord.city}, ${docRecord.state}`;
		}
		return null;
	}, [providerDetail]);

	const showEducationSection = useMemo(() => {
		if (
			providerDetail?.doctorRecord?.entityTypeCode === "1" &&
			(providerDetail?.doctorRecord.education !== "" ||
				providerDetail.doctorRecord.hgEducation !== "" ||
				providerDetail?.doctorRecord.certifications !== "" ||
				providerDetail?.doctorRecord.publications !== "")
		) {
			return true;
		} else {
			return false;
		}
	}, [
		providerDetail?.doctorRecord.certifications,
		providerDetail?.doctorRecord.education,
		providerDetail?.doctorRecord?.entityTypeCode,
		providerDetail?.doctorRecord.hgEducation,
		providerDetail?.doctorRecord.publications,
	]);

	const overview = useMemo(() => {
		if (providerDetail?.doctorRecord.overview !== "") {
			return providerDetail?.doctorRecord.overview;
		}
		if (providerDetail.doctorRecord.hgOverview !== "") {
			return providerDetail.doctorRecord.hgOverview;
		}
		return undefined;
	}, [providerDetail?.doctorRecord.hgOverview, providerDetail?.doctorRecord.overview]);

	const subSpecialties: string[] | undefined = useMemo(() => {
		if (providerDetail?.doctorRecord.subSpecialties && providerDetail?.doctorRecord.subSpecialties !== "") {
			return providerDetail?.doctorRecord.subSpecialties.split(",");
		}
		return undefined;
	}, [providerDetail?.doctorRecord.subSpecialties]);

	const formattedAddress = providerDetail?.doctorRecord.city + ", " + providerDetail?.doctorRecord.state + " " + providerDetail?.doctorRecord.zip;
	return (
		<Box>
			<AppBar sx={{ position: "sticky", backgroundColor: "#ffffff !important" }}>
				<Box
					sx={{
						height: style.headerHeight,
						width: "100%",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#ffffff !important",
					}}
				>
					<Typography variant="h1">{providerDetail?.doctorRecord ? ConstructName_ModelDoctorRecord(providerDetail.doctorRecord) : null}</Typography>
					<CloseButton
						onClick={() => {
							DialogManager.close("provider-details");
						}}
						right={12}
						styleType="big-blue"
					/>
				</Box>
			</AppBar>
			<QueryStatusDisplay queryResult={requestResponse}>
				<Container maxWidth="md" sx={{ py: "1rem" }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<ContentSection title="General Info">
								<Box style={{ justifyContent: "center", display: "flex", flexDirection: "column", flex: 1 }}>
									<Box>{prov_entity ? <ProviderProfileTile Entity={prov_entity} /> : null}</Box>
									{overview && (
										<Box sx={{ flexDirection: "column", paddingTop: "1rem", paddingBottom: "1rem" }}>
											<Typography sx={{ fontWeight: "bold" }}>Overview</Typography>
											<Typography>{overview}</Typography>
										</Box>
									)}
									{providerDetail?.doctorRecord?.entityTypeCode === "1" && (
										<Box sx={{ display: "flex", flexDirection: "row", paddingBottom: "1rem" }}>
											<Box flex={1} sx={{ flexDirection: "column" }}>
												<Typography sx={{ fontWeight: "bold" }}>Experience</Typography>
												<Typography>{providerDetail?.doctorRecord.yearsExperience + "+ Yrs Experience"}</Typography>
											</Box>
											<Box flex={1} sx={{ flexDirection: "column" }}>
												<Typography sx={{ fontWeight: "bold" }}>Specialties</Typography>
												<Typography>
													{providerDetail?.doctorRecord.specialties !== ""
														? providerDetail?.doctorRecord.specialties
														: providerDetail.doctorRecord.hgSpecialty !== ""
														? providerDetail?.doctorRecord.hgSpecialty
														: "N/A"}
												</Typography>
											</Box>
											{subSpecialties && (
												<Box flex={1} sx={{ flexDirection: "column" }}>
													<Typography sx={{ fontWeight: "bold" }}>Sub specialties</Typography>
													{subSpecialties.map((sp) => {
														return <Typography key={sp}>{sp}</Typography>;
													})}
												</Box>
											)}
										</Box>
									)}
								</Box>
							</ContentSection>
						</Grid>
						<Grid item xs={12}>
							{showEducationSection && (
								<ContentSection title="Education and Experience">
									<Box style={{ justifyContent: "center", display: "flex", flexDirection: "column", paddingBottom: ".5rem" }}>
										{(providerDetail?.doctorRecord.education !== "" || providerDetail?.doctorRecord.hgEducation !== "") && (
											<Box sx={{ flexDirection: "column", paddingTop: "1rem" }}>
												<Typography sx={{ fontWeight: "bold" }}>Education</Typography>
												<Typography>
													{providerDetail?.doctorRecord.education !== ""
														? providerDetail?.doctorRecord.education
														: providerDetail.doctorRecord.hgEducation !== ""
														? providerDetail?.doctorRecord.hgEducation
														: "N/A"}
												</Typography>
											</Box>
										)}
										{providerDetail?.doctorRecord.certifications !== "" && (
											<Box sx={{ display: "flex", flexDirection: "row", paddingTop: "1rem" }}>
												<Box flex={1} sx={{ flexDirection: "column" }}>
													<Typography sx={{ fontWeight: "bold" }}>Certifications & Licensure</Typography>
													<Typography>{providerDetail?.doctorRecord.certifications}</Typography>
												</Box>
											</Box>
										)}
										{providerDetail?.doctorRecord.publications !== "" && (
											<Box sx={{ display: "flex", flexDirection: "row", paddingTop: "1rem" }}>
												<Box flex={1} sx={{ flexDirection: "column" }}>
													<Typography sx={{ fontWeight: "bold" }}>Publications</Typography>
													<Typography>{providerDetail?.doctorRecord.publications}</Typography>
												</Box>
											</Box>
										)}
									</Box>
								</ContentSection>
							)}
						</Grid>
						<Grid item xs={12}>
							<ContentSection title="Location & Contact">
								<Box style={{ display: "flex", width: "100%", paddingBottom: "1rem", paddingTop: ".5rem" }}>
									<Box sx={{ flex: "50%", paddingRight: "1rem", borderRadius: ".25rem", minHeight: "15rem" }}>
										<AddressMap address={providerAddress} zoom={17} />
									</Box>
									<Box sx={{ display: "flex", flexDirection: "column", flex: "50%" }}>
										<Typography sx={{ fontWeight: "bold" }}>Address</Typography>
										<Typography>{providerDetail?.doctorRecord.address !== "" ? providerDetail?.doctorRecord.address : "N/A"}</Typography>
										<Typography>{formattedAddress ?? "N/A"}</Typography>
										<Typography>
											{(providerDetail?.doctorRecord.phoneNumber &&
												displayConverter.formatPhoneNumber(providerDetail?.doctorRecord.phoneNumber)) ??
												"N/A"}
										</Typography>
										{providerDetail?.doctorRecord.hgOfficeHours !== "" && (
											<>
												<Typography sx={{ fontWeight: "bold", paddingTop: "1rem" }}>Hours</Typography>
												<Typography>{providerDetail?.doctorRecord.hgOfficeHours}</Typography>
											</>
										)}
									</Box>
								</Box>
								<Typography sx={{ fontWeight: "bold" }}>* Always confirm the location of your appointment prior to visit</Typography>
							</ContentSection>
						</Grid>
					</Grid>
				</Container>
			</QueryStatusDisplay>
		</Box>
	);
}

export { ProviderDetail };
