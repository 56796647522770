/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useQuery
} from 'react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  ConsumerOperationStatusInviteCareCollaboratorResponse,
  InviteCareCollaboratorParams,
  ConsumerOperationStatusRetrieveCareCollaborationsResponse,
  ModelsOperationStatus,
  RevokeInvitedCareCollaboratorParams,
  RemoveReceivedCareCollaboratorParams,
  DismissInactiveSentCareCollaborationParams,
  DismissInactiveReceivedCareCollaborationParams,
  AcceptCareCollaborationParams,
  ConsumerOperationStatusAcceptCareCollaborationResponse,
  SetActiveCareCollaborationParams,
  ConsumerGetCurrentCareCollaborationUserDetailsResult
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const inviteCareCollaborator = (
    params?: InviteCareCollaboratorParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerOperationStatusInviteCareCollaboratorResponse>(
      {url: `/api/consumer/ConsumerCareCollaborator/InviteCareCollaborator`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getInviteCareCollaboratorQueryKey = (params?: InviteCareCollaboratorParams,) => [`/api/consumer/ConsumerCareCollaborator/InviteCareCollaborator`, ...(params ? [params]: [])];

    
export type InviteCareCollaboratorQueryResult = NonNullable<Awaited<ReturnType<typeof inviteCareCollaborator>>>
export type InviteCareCollaboratorQueryError = unknown

export const useInviteCareCollaborator = <TData = Awaited<ReturnType<typeof inviteCareCollaborator>>, TError = unknown>(
 params?: InviteCareCollaboratorParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof inviteCareCollaborator>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getInviteCareCollaboratorQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof inviteCareCollaborator>>> = ({ signal }) => inviteCareCollaborator(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof inviteCareCollaborator>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const retrieveCareCollaborations = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerOperationStatusRetrieveCareCollaborationsResponse>(
      {url: `/api/consumer/ConsumerCareCollaborator/RetrieveCareCollaborations`, method: 'get', signal
    },
      options);
    }
  

export const getRetrieveCareCollaborationsQueryKey = () => [`/api/consumer/ConsumerCareCollaborator/RetrieveCareCollaborations`];

    
export type RetrieveCareCollaborationsQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveCareCollaborations>>>
export type RetrieveCareCollaborationsQueryError = unknown

export const useRetrieveCareCollaborations = <TData = Awaited<ReturnType<typeof retrieveCareCollaborations>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveCareCollaborations>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveCareCollaborationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveCareCollaborations>>> = ({ signal }) => retrieveCareCollaborations(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof retrieveCareCollaborations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const revokeInvitedCareCollaborator = (
    params?: RevokeInvitedCareCollaboratorParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/ConsumerCareCollaborator/RevokeInvitedCareCollaborator`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getRevokeInvitedCareCollaboratorQueryKey = (params?: RevokeInvitedCareCollaboratorParams,) => [`/api/consumer/ConsumerCareCollaborator/RevokeInvitedCareCollaborator`, ...(params ? [params]: [])];

    
export type RevokeInvitedCareCollaboratorQueryResult = NonNullable<Awaited<ReturnType<typeof revokeInvitedCareCollaborator>>>
export type RevokeInvitedCareCollaboratorQueryError = unknown

export const useRevokeInvitedCareCollaborator = <TData = Awaited<ReturnType<typeof revokeInvitedCareCollaborator>>, TError = unknown>(
 params?: RevokeInvitedCareCollaboratorParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof revokeInvitedCareCollaborator>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRevokeInvitedCareCollaboratorQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof revokeInvitedCareCollaborator>>> = ({ signal }) => revokeInvitedCareCollaborator(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof revokeInvitedCareCollaborator>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const removeReceivedCareCollaborator = (
    params?: RemoveReceivedCareCollaboratorParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/ConsumerCareCollaborator/RemoveReceivedCareCollaborator`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getRemoveReceivedCareCollaboratorQueryKey = (params?: RemoveReceivedCareCollaboratorParams,) => [`/api/consumer/ConsumerCareCollaborator/RemoveReceivedCareCollaborator`, ...(params ? [params]: [])];

    
export type RemoveReceivedCareCollaboratorQueryResult = NonNullable<Awaited<ReturnType<typeof removeReceivedCareCollaborator>>>
export type RemoveReceivedCareCollaboratorQueryError = unknown

export const useRemoveReceivedCareCollaborator = <TData = Awaited<ReturnType<typeof removeReceivedCareCollaborator>>, TError = unknown>(
 params?: RemoveReceivedCareCollaboratorParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof removeReceivedCareCollaborator>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRemoveReceivedCareCollaboratorQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof removeReceivedCareCollaborator>>> = ({ signal }) => removeReceivedCareCollaborator(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof removeReceivedCareCollaborator>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const dismissInactiveSentCareCollaboration = (
    params?: DismissInactiveSentCareCollaborationParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/ConsumerCareCollaborator/DismissInactiveSentCareCollaboration`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getDismissInactiveSentCareCollaborationQueryKey = (params?: DismissInactiveSentCareCollaborationParams,) => [`/api/consumer/ConsumerCareCollaborator/DismissInactiveSentCareCollaboration`, ...(params ? [params]: [])];

    
export type DismissInactiveSentCareCollaborationQueryResult = NonNullable<Awaited<ReturnType<typeof dismissInactiveSentCareCollaboration>>>
export type DismissInactiveSentCareCollaborationQueryError = unknown

export const useDismissInactiveSentCareCollaboration = <TData = Awaited<ReturnType<typeof dismissInactiveSentCareCollaboration>>, TError = unknown>(
 params?: DismissInactiveSentCareCollaborationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dismissInactiveSentCareCollaboration>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDismissInactiveSentCareCollaborationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dismissInactiveSentCareCollaboration>>> = ({ signal }) => dismissInactiveSentCareCollaboration(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof dismissInactiveSentCareCollaboration>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const dismissInactiveReceivedCareCollaboration = (
    params?: DismissInactiveReceivedCareCollaborationParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/ConsumerCareCollaborator/DismissInactiveReceivedCareCollaboration`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getDismissInactiveReceivedCareCollaborationQueryKey = (params?: DismissInactiveReceivedCareCollaborationParams,) => [`/api/consumer/ConsumerCareCollaborator/DismissInactiveReceivedCareCollaboration`, ...(params ? [params]: [])];

    
export type DismissInactiveReceivedCareCollaborationQueryResult = NonNullable<Awaited<ReturnType<typeof dismissInactiveReceivedCareCollaboration>>>
export type DismissInactiveReceivedCareCollaborationQueryError = unknown

export const useDismissInactiveReceivedCareCollaboration = <TData = Awaited<ReturnType<typeof dismissInactiveReceivedCareCollaboration>>, TError = unknown>(
 params?: DismissInactiveReceivedCareCollaborationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof dismissInactiveReceivedCareCollaboration>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getDismissInactiveReceivedCareCollaborationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof dismissInactiveReceivedCareCollaboration>>> = ({ signal }) => dismissInactiveReceivedCareCollaboration(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof dismissInactiveReceivedCareCollaboration>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const acceptCareCollaboration = (
    params?: AcceptCareCollaborationParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ModelsOperationStatus>(
      {url: `/api/consumer/ConsumerCareCollaborator/AcceptCareCollaboration`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getAcceptCareCollaborationQueryKey = (params?: AcceptCareCollaborationParams,) => [`/api/consumer/ConsumerCareCollaborator/AcceptCareCollaboration`, ...(params ? [params]: [])];

    
export type AcceptCareCollaborationQueryResult = NonNullable<Awaited<ReturnType<typeof acceptCareCollaboration>>>
export type AcceptCareCollaborationQueryError = unknown

export const useAcceptCareCollaboration = <TData = Awaited<ReturnType<typeof acceptCareCollaboration>>, TError = unknown>(
 params?: AcceptCareCollaborationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof acceptCareCollaboration>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getAcceptCareCollaborationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof acceptCareCollaboration>>> = ({ signal }) => acceptCareCollaboration(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof acceptCareCollaboration>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const setActiveCareCollaboration = (
    params?: SetActiveCareCollaborationParams,
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerOperationStatusAcceptCareCollaborationResponse>(
      {url: `/api/consumer/ConsumerCareCollaborator/SetActiveCareCollaboration`, method: 'get',
        params, signal
    },
      options);
    }
  

export const getSetActiveCareCollaborationQueryKey = (params?: SetActiveCareCollaborationParams,) => [`/api/consumer/ConsumerCareCollaborator/SetActiveCareCollaboration`, ...(params ? [params]: [])];

    
export type SetActiveCareCollaborationQueryResult = NonNullable<Awaited<ReturnType<typeof setActiveCareCollaboration>>>
export type SetActiveCareCollaborationQueryError = unknown

export const useSetActiveCareCollaboration = <TData = Awaited<ReturnType<typeof setActiveCareCollaboration>>, TError = unknown>(
 params?: SetActiveCareCollaborationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof setActiveCareCollaboration>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSetActiveCareCollaborationQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof setActiveCareCollaboration>>> = ({ signal }) => setActiveCareCollaboration(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof setActiveCareCollaboration>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export const getCurrentCareCollaborationUserDetails = (
    
 options?: SecondParameter<typeof apiRequest>,signal?: AbortSignal
) => {
      return apiRequest<ConsumerGetCurrentCareCollaborationUserDetailsResult>(
      {url: `/api/consumer/ConsumerCareCollaborator/GetCurrentCareCollaborationUserDetails`, method: 'get', signal
    },
      options);
    }
  

export const getGetCurrentCareCollaborationUserDetailsQueryKey = () => [`/api/consumer/ConsumerCareCollaborator/GetCurrentCareCollaborationUserDetails`];

    
export type GetCurrentCareCollaborationUserDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getCurrentCareCollaborationUserDetails>>>
export type GetCurrentCareCollaborationUserDetailsQueryError = unknown

export const useGetCurrentCareCollaborationUserDetails = <TData = Awaited<ReturnType<typeof getCurrentCareCollaborationUserDetails>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCurrentCareCollaborationUserDetails>>, TError, TData>, request?: SecondParameter<typeof apiRequest>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCurrentCareCollaborationUserDetailsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentCareCollaborationUserDetails>>> = ({ signal }) => getCurrentCareCollaborationUserDetails(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCurrentCareCollaborationUserDetails>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

