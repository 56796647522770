import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { ModelsExplanationOfBenefitDiagnosisAssessment } from "api";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import StandardLineChart from "common/components/Charts/StandardLineChart";
import { CONVERT_DoctorRecord_Entity, DisplayInfo, Entity, entity_actions } from "common/definitions/EntityDefinitions";
import { DynamicDataManager } from "common/managers/DynamicDataManager";
import { convertDatesToChartDataByMonth } from "common/utilities/chartDataConverters";
import { dateConverter } from "common/utilities/dateConverter";
import displayConverter from "common/utilities/displayConverter";
import { styleConverter } from "common/utilities/styleConverter";
import { useMemo } from "react";
import { palette } from "theme";
import { ProviderAvatarDisplay } from "./ProviderAssessmentDetailsSection";

const ConditionAssessmentDetailsSection = ({
	conditionAssessment,
	conditionEntity,
}: {
	conditionAssessment: ModelsExplanationOfBenefitDiagnosisAssessment;
	conditionEntity?: Entity;
}) => {
	const firstDiagnosedProviderEntity = useMemo(() => {
		// console.debug(conditionAssessment?.npIsByDates);
		let doctorRecord = DynamicDataManager.npis.doctorRecord_NPIMap.get(conditionAssessment?.npIsByDates[0]?.npi);
		if (doctorRecord) {
			return CONVERT_DoctorRecord_Entity(doctorRecord);
		}
		return null;
	}, [conditionAssessment]);

	// console.debug({ conditionAssessment, conditionEntity, firstDiagnosedProviderEntity });
	// console.debug(firstDiagnosedProviderEntity)
	let chartData;
	if (conditionAssessment.dates) {
		chartData = convertDatesToChartDataByMonth(conditionAssessment.dates);
	}

	let displayInfo: Array<DisplayInfo> = [
		{
			title: "Diagnosed By",
			value: "",
			explicitNode: firstDiagnosedProviderEntity ? (
				<Stack alignItems={"center"}>
					<ProviderAvatarDisplay providerEntity={firstDiagnosedProviderEntity} />
				</Stack>
			) : null,
		},
		{
			title: "Date of First Diagnosis",
			value: dateConverter["YYYY-MM-DD_to_mM/dD/YY"](conditionAssessment.dates[0]),
		},
		{ title: "Diagnosis Count", value: conditionAssessment.count ?? "?", colorType: undefined },
		{
			title: "Est Total Payments",
			value: displayConverter.dataTypeConvert("USD", conditionAssessment.paymentAmountTotal ?? "?"),
			colorType: "USD",
		},
	];
	console.debug("condition assess");

	return (
		<Box>
			<Box sx={{ paddingY: "1rem" }}>
				{chartData ? (
					<StandardLineChart
						data={chartData}
						xKey={"yyyymm"}
						yKeys={[{ dataKey: "count" }]}
						responsiveHeight={100}
						keyLabels={{ count: "Encounters" }}
						xTickLine
						xTickFormatter={(x, y) => {
							return dateConverter["YYYY-MM_to_mM/YY"](x);
						}}
						yDomain={[0, "dataMax" as CIM]}
						dotSize={3}
					/>
				) : null}
			</Box>

			<Box>
				<Grid container spacing={2}>
					{displayInfo.map((di, i) => {
						return (
							<Grid key={i} item xs={12} sm={6}>
								<Stack
									key={i}
									sx={{
										border: `solid 2px ${palette.gray.main}`,
										backgroundColor: "#ffffff",
										padding: "1rem",
										borderRadius: ".5rem",
										height: "100%",
										boxSizing: "border-box",
										alignContent: "center",
										justifyContent: "center",
									}}
								>
									{di.explicitNode ? (
										di.explicitNode
									) : (
										<Typography
											sx={{
												fontSize: { xs: "1.25rem", sm: "1.75rem" },
												color: di.colorType ? styleConverter.colorTypeAssign(di.colorType, di.value) : palette.gray.darker,
												fontWeight: "500",
												textAlign: "center",
											}}
										>
											{di.dataType ? displayConverter.dataTypeConvert(di.dataType, di.value) : di.value}
										</Typography>
									)}
									<Typography sx={{ color: palette.gray.dark, fontWeight: 500, textAlign: "center" }}>{di.title}</Typography>
								</Stack>
							</Grid>
						);
					})}
				</Grid>
			</Box>
		</Box>
	);
};

export { ConditionAssessmentDetailsSection };

const ConditionOptionsActions = ({ conditionEntity }: { conditionEntity: Entity }) => {
	return (
		<OptionsButton
			content={
				<Box>
					<Button
						variant="text"
						onClick={() => {
							entity_actions.view_details.action(conditionEntity);
						}}
					>
						View Details
					</Button>
				</Box>
			}
		/>
	);
};

export { ConditionOptionsActions };
