import { createContext, useCallback, useEffect, useMemo, useState } from "react";

import MobileDetect from "mobile-detect";

type MobileDeviceTypes = "ANDROID" | "IOS" | "OTHER";

const installBannerHeightPx = 96;

const applinks: { [key in MobileDeviceTypes]: string } = {
	IOS: "https://apps.apple.com/us/app/radius-care-consumer-health/id1671815630",
	ANDROID: "https://play.google.com/store/apps/details?id=com.radiuscare.customerportal",
	OTHER: "https://play.google.com/store/apps/details?id=com.radiuscare.customerportal",
};

const DeviceContext = createContext<{
	mobileDeviceType: MobileDeviceTypes | null;
	installAppBannerIsOpen: boolean;
	installAppBannerHeight: number;
	installAppBannerHeightCurrent: number;
	closeInstallAppBanner: () => any;
	applinks: { [key in MobileDeviceTypes]: string };
	activateAppLink: () => any;
}>({
	mobileDeviceType: null,
	installAppBannerIsOpen: false,
	installAppBannerHeight: installBannerHeightPx,
	installAppBannerHeightCurrent: 0,
	closeInstallAppBanner: () => null,
	applinks: applinks,
	activateAppLink: () => null,
});

const checkDevice: {
	action: () => void;
} = {
	action: () => {},
};

window.addEventListener("resize", () => {
	checkDevice.action();
});

function DeviceContextProvider(props: React.PropsWithChildren<{}>) {
	const [deviceOS, set_deviceOS] = useState<MobileDeviceTypes | null>(null);
	const [installBannerOpen, set_installBannerOpen] = useState<boolean>(false);
	const [lastTriggered, set_lastTriggered] = useState<number>(0);

	checkDevice.action = () => {
		set_lastTriggered(Date.now());
	};
	const closeInstallAppBanner = useCallback(() => {
		localStorage.setItem("InstallAppBannerLastClosed", String(Date.now()));
		set_installBannerOpen(false);
	}, [set_installBannerOpen]);

	const activateAppLink = useCallback(() => {
		if (deviceOS !== null) {
			console.debug(deviceOS);
			window.location.href = applinks[deviceOS];
		}
	}, [deviceOS]);

	useEffect(() => {
		const timeToWait = 1000 * 60 * 60 * 12; // 12hours
		let closedAt = localStorage.getItem("InstallAppBannerLastClosed");
		if (closedAt) {
			let t = parseInt(closedAt);
			if (!isNaN(t)) {
				if (t + timeToWait > Date.now()) {
					let elapsed = Date.now() - t;
					console.debug(
						"Install App Banner was closed " +
							Math.round((elapsed / 60000) * 100) / 100 +
							" minutes ago: " +
							timeToWait / (1000 * 60 * 60) +
							" hour hold"
					);
					set_installBannerOpen(false);
					return;
				}
			}
		}

		let md = new MobileDetect(window.navigator.userAgent);
		let _deviceOS: MobileDeviceTypes | null = null;
		let os = md.os();

		if (os) {
			let lc_os = os.toLowerCase();

			if (lc_os.indexOf("android") !== -1) {
				_deviceOS = "ANDROID";
			} else if (lc_os.indexOf("ios") !== -1) {
				_deviceOS = "IOS";
			}
		}
		set_deviceOS(_deviceOS);
		if (_deviceOS !== null) {
			set_installBannerOpen(true);
		} else {
			set_installBannerOpen(false);
		}
	}, [lastTriggered]);

	const value = useMemo(() => {
		return {
			mobileDeviceType: deviceOS,
			closeInstallAppBanner: closeInstallAppBanner,
			installAppBannerIsOpen: installBannerOpen,
			installAppBannerHeight: installBannerHeightPx,
			installAppBannerHeightCurrent: installBannerOpen ? installBannerHeightPx : 0,
			applinks: applinks,
			activateAppLink: activateAppLink,
		};
	}, [activateAppLink, installBannerOpen, closeInstallAppBanner, deviceOS]);

	return <DeviceContext.Provider value={value}>{props.children}</DeviceContext.Provider>;
}

export { DeviceContextProvider, DeviceContext };
