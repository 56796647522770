/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * Radius Care API
 * OpenAPI spec version: v1
 */
import {
  useMutation
} from 'react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from 'react-query'
import type {
  ConsumerMobileOperationStatusHealthSnapshotResponseModel,
  ConsumerMobileHealthSnapshotRequest
} from '../backend.schemas'
import { apiRequest } from '../../api_extended/client'


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const healthSnapShot = (
    consumerMobileHealthSnapshotRequest: ConsumerMobileHealthSnapshotRequest,
 options?: SecondParameter<typeof apiRequest>,) => {
      return apiRequest<ConsumerMobileOperationStatusHealthSnapshotResponseModel>(
      {url: `/api/consumer/ConsumerMobile_HealthSnapshot/HealthSnapShot`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: consumerMobileHealthSnapshotRequest
    },
      options);
    }
  


    export type HealthSnapShotMutationResult = NonNullable<Awaited<ReturnType<typeof healthSnapShot>>>
    export type HealthSnapShotMutationBody = ConsumerMobileHealthSnapshotRequest
    export type HealthSnapShotMutationError = unknown

    export const useHealthSnapShot = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof healthSnapShot>>, TError,{data: ConsumerMobileHealthSnapshotRequest}, TContext>, request?: SecondParameter<typeof apiRequest>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof healthSnapShot>>, {data: ConsumerMobileHealthSnapshotRequest}> = (props) => {
          const {data} = props ?? {};

          return  healthSnapShot(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof healthSnapShot>>, TError, {data: ConsumerMobileHealthSnapshotRequest}, TContext>(mutationFn, mutationOptions)
    }
    