import { Box, Grid } from "@mui/material";
import { ContentSection } from "common/components/Containers/ContentSection";
import { StandardOption } from "common/types/generic";
import { FindCareMenu } from "findCare/FindCareMenu";
import { useQ_retrieveSearchTreatments } from "findCare/requests/useQ_retrieveSearchTreatments";
import { useState } from "react";
import { FindCareMedicationFilters } from "./FindCareMedicationFilters";
import { FindCareMedicationResults } from "./FindCareMedicationResults";

const FindCareMedication = () => {
	const [medicationBrandType, set_medicationBrandType] = useState<StandardOption<string, string>>({ name: "All", value: "all" });
	const [condition, set_condition] = useState<StandardOption<string, string>>({ name: "All", value: "All" });
	const searchTreatmentResult = useQ_retrieveSearchTreatments({
		query: condition.value,
	});

	return (
		<Box>
			<FindCareMenu />
			<Grid container spacing={2}>
				<Grid item xs={12} md={4}>
					<ContentSection title="Filters">
						<FindCareMedicationFilters
							medicationBrandType={medicationBrandType}
							set_medicationBrandType={set_medicationBrandType}
							condition={condition}
							set_condition={set_condition}
						/>
					</ContentSection>
				</Grid>
				<Grid item xs={12} md={8}>
					<ContentSection title="Results">
						<FindCareMedicationResults medicationBrandType={medicationBrandType} condition={condition} medicationResults={searchTreatmentResult} />
					</ContentSection>
				</Grid>
			</Grid>
		</Box>
	);
};

export { FindCareMedication };
