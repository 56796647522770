import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { editConsumerUserLocation } from "api/consumer-users/consumer-users";
import { StandardTextField } from "common/components/Inputs/StandardTextField";
import { FormSubmitButton } from "common/components/Buttons/FormSubmitButton";
import displayConverter from "common/utilities/displayConverter";
import { ButtonBar } from "common/components/Buttons/ButtonBar";
import { GeolocationManager } from "common/managers/GeolocationManager";
import { GeolocationContext } from "common/context/GeolocationContext";
import { AccountManagerContext } from "common/managers/AccountManager";

interface LocationFormFields {
	zip: string;
	lat: number;
	long: number;
}

type UserLocationSettingsProps = {
	// zipCode?: string;
	allowUseCurrent?: boolean;
	onSuccessCallback?: () => any;
};

function UserLocationSettings({ allowUseCurrent, onSuccessCallback }: UserLocationSettingsProps) {
	const [locationType, set_locationType] = useState<"zip" | "coords">("zip");
	const accountContext = useContext(AccountManagerContext);
	const [zipDisplayValue, set_zipDisplayValue] = useState<string>(accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip ?? "");
	const [error, setError] = useState<false | string>(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (locationType === "coords") {
			GeolocationManager.getPosition();
		}
	}, [locationType]);

	useEffect(() => {
		if (accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip) {
			set_zipDisplayValue(accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip);
		}
	}, [accountContext?.accountManager?.userDetails?.consumerUser?.location?.zip]);

	const geoContext = useContext(GeolocationContext);

	/// Form Logic
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit } = useForm<LocationFormFields>({
		defaultValues: {},
	});
	const onSubmit = handleSubmit(() => {
		if (zipDisplayValue.length === 5) {
			setError(false);
			return editConsumerUserLocation({
				"location.zip": zipDisplayValue,
				"location.latitude": 0,
				"location.longitude": 0,
			}).then(onAPISuccess, onAPIFailure);
		} else {
			setError("Zip Code Must Be 5 Digits");
		}
	});

	const use_CurrentLocation = () => {
		return editConsumerUserLocation({
			"location.zip": "nullify",
			"location.latitude": 0,
			"location.longitude": 0,
		}).then(() => {
			set_zipDisplayValue("");
			onAPISuccess();
		}, onAPIFailure);
	};

	function onAPISuccess() {
		enqueueSnackbar("Location Saved", {
			variant: "success",
		});
		if (accountContext) {
			accountContext.accountManager.setUser("REFRESH");
			queryClient?.invalidateQueries("useKeyFindings");
		} else {
			window.RadiusCare.log_issue({ message: "account context not defined", file: "UserLocationSettings" });
		}
		if (onSuccessCallback) {
			onSuccessCallback();
		}
	}
	function onAPIFailure() {
		enqueueSnackbar("Error saving changes", {
			variant: "error",
		});
	}

	return (
		<Box onSubmit={onSubmit} component="form">
			<Box sx={{ textAlign: "left", paddingBottom: "1rem" }}>
				<Typography>Set your zip code for better performance</Typography>
			</Box>
			{allowUseCurrent === false ? null : (
				<ButtonBar
					buttons={[
						{ name: "Zip Code", value: "zip" },
						{ name: "Current Location", value: "coords" },
					]}
					selected={locationType}
					setSelected={set_locationType}
					equalSize={true}
				/>
			)}

			{locationType === "zip" || allowUseCurrent === false ? (
				<StandardTextField
					id="zipCodeInput"
					value={zipDisplayValue}
					data-styletype={error ? "errordisplay" : "infodisplay"}
					helperText={error ?? undefined}
					label="Zip Code"
					variant="filled"
					onChange={(event: any) => {
						let newval = displayConverter.filterNonNumeric(event.target.value);
						if (newval.length > 5) {
							newval = newval.substring(0, 5);
						}
						set_zipDisplayValue(newval);
					}}
					sx={{ flexGrow: 1, width: "100%", mt: 2 }}
				/>
			) : null}

			{locationType === "coords" && allowUseCurrent !== false ? (
				<>
					<StandardTextField
						id="LatitudeDisplay"
						value={geoContext.position?.coords.latitude ?? "--"}
						data-styletype={"infodisplay"}
						label="Latitude"
						disabled
						variant="filled"
						sx={{ flexGrow: 1, width: "100%" }}
					/>
					<StandardTextField
						id="LongitudeDisplay"
						value={geoContext.position?.coords.longitude ?? "--"}
						data-styletype={"infodisplay"}
						label="Longitude"
						disabled
						variant="filled"
						sx={{ flexGrow: 1, width: "100%" }}
					/>
				</>
			) : null}

			<Stack sx={{ paddingTop: "1rem", flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end" }}>
				{locationType === "zip" ? (
					<FormSubmitButton>Save Location</FormSubmitButton>
				) : (
					<FormSubmitButton
						noSubmit
						onClick={() => {
							use_CurrentLocation();
						}}
					>
						Use Current Location
					</FormSubmitButton>
				)}
			</Stack>
		</Box>
	);
}

export default UserLocationSettings;
