import displayConverter from "./displayConverter";

function convertDatesToChartDataByMonth(standardDateStrings: Array<string>): Array<{ count: number; year: number; month: number; yyyymm: string }> {
	// yyyy-mm-dd
	let current_year = new Date().getFullYear();
	let chartDataByMonth_Precursor: { [key_year: number]: { [key_month: number]: { count: number } } } = {};
	let chartDataByMonth: Array<{ count: number; year: number; month: number; yyyymm: string }> = [];
	standardDateStrings.forEach((dateString: string) => {
		let ymd = dateString.split("-").map((x) => parseInt(x));
		if (ymd.length === 3) {
			if (chartDataByMonth_Precursor[ymd[0]] === undefined) {
				chartDataByMonth_Precursor[ymd[0]] = {};
			}
			if (chartDataByMonth_Precursor[ymd[0]][ymd[1]] === undefined) {
				chartDataByMonth_Precursor[ymd[0]][ymd[1]] = { count: 1 };
			} else {
				chartDataByMonth_Precursor[ymd[0]][ymd[1]].count++;
			}
		} else {
			window.RadiusCare.log_issue({ message: "Invalid Date Format", file: "HealthSnapshotProvidersPanel.tsx", details: dateString });
		}
	});

	let years: Array<number> = Object.keys(chartDataByMonth_Precursor).map((y) => parseInt(y));
	years.sort();

	for (let year = years[0]; year <= current_year; year++) {
		for (let m = 1; m <= 12; m++) {
			if (chartDataByMonth_Precursor[year] !== undefined && chartDataByMonth_Precursor[year][m] !== undefined) {
				chartDataByMonth.push({
					year: year,
					month: m,
					yyyymm: `${year}-${displayConverter.leftPadNumberToString(m, 2)}`,
					count: chartDataByMonth_Precursor[year][m].count,
				});
			} else {
				chartDataByMonth.push({ year: year, month: m, yyyymm: `${year}-${displayConverter.leftPadNumberToString(m, 2)}`, count: 0 });
			}
		}
	}
	return chartDataByMonth;
}

export { convertDatesToChartDataByMonth };
