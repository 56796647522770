import { Box, Button, Stack, Typography } from "@mui/material";
import { CareCollaboratorAuthorization, revokeInvitedCareCollaborator } from "api";
import { AccountManagerContext } from "common/managers/AccountManager";
import { palette } from "theme";
import { useContext, useMemo, useState } from "react";
import { dateConverter } from "common/utilities/dateConverter";
import { dismissInactiveSentCareCollaboration } from "../../../../api/consumer-care-collaborator/consumer-care-collaborator";
import { SimpleModal } from "common/components/Modals/SimpleModal";
import { OptionsButton } from "common/components/Buttons/OptionsButton";
import { styleConverter } from "common/utilities/styleConverter";

function SentCareColloboraterAuth({
	careCollaboratorAuthorization,
	allowRevoke,
}: {
	careCollaboratorAuthorization: CareCollaboratorAuthorization;
	allowRevoke: boolean;
}) {
	const [activateRevokeModal, set_activateRevokeModal] = useState<boolean>(false);
	const account = useContext(AccountManagerContext);

	const options = useMemo(() => {
		let dismissable =
			(!careCollaboratorAuthorization.isAuthorized &&
				(careCollaboratorAuthorization.status === "REVOKED" || careCollaboratorAuthorization.status === "REMOVED_BY_COLLABORATOR")) ||
			careCollaboratorAuthorization.status === "PENDING";

		let revokable = careCollaboratorAuthorization.isAuthorized && allowRevoke;
		if (!dismissable && !revokable) {
			return null;
		}
		return (
			<OptionsButton
				content={
					<Box>
						{revokable ? (
							<Button
								variant="text"
								color="error"
								onClick={async () => {
									set_activateRevokeModal(true);
								}}
							>
								Revoke
							</Button>
						) : null}
						{dismissable ? (
							<Button
								variant="text"
								onClick={async () => {
									await dismissInactiveSentCareCollaboration({
										id: careCollaboratorAuthorization.id,
										collaboratorEmail: careCollaboratorAuthorization.collaboratorEmail,
									});
									account.refreshCollaborators();
								}}
							>
								Dismiss
							</Button>
						) : null}
					</Box>
				}
				// content={
				// 	<Box style={{ display: "flex", flexDirection: "column" }} sx={{ "& > .MuiButton-root:not(:last-child)": { marginBottom: "16px" } }}>
				// 		{revokable ? (
				// 			<Button
				// 				variant="outlined"
				// 				color="error"
				// 				onClick={async () => {
				// 					set_activateRevokeModal(true);
				// 					// await revokeInvitedCareCollaborator({ collaboratorEmail: careCollaboratorAuthorization.collaboratorEmail });
				// 					// account.refreshCollaborators();
				// 				}}
				// 			>
				// 				Revoke
				// 			</Button>
				// 		) : null}
				// 		{dismissable ? (
				// 			<Button
				// 				variant="outlined"
				// 				sx={{ ml: { xs: 0, sm: 1 } }}
				// 				onClick={async () => {
				// 					await dismissInactiveSentCareCollaboration({
				// 						id: careCollaboratorAuthorization.id,
				// 						collaboratorEmail: careCollaboratorAuthorization.collaboratorEmail,
				// 					});
				// 					account.refreshCollaborators();
				// 				}}
				// 			>
				// 				Dismiss
				// 			</Button>
				// 		) : null}{" "}
				// 	</Box>
				// }
			/>
		);
	}, [careCollaboratorAuthorization, allowRevoke, account]);

	const displayName = useMemo(() => {
		return careCollaboratorAuthorization.collaboratorDisplayName
			? `${careCollaboratorAuthorization.collaboratorDisplayName} (${careCollaboratorAuthorization.collaboratorEmail})`
			: careCollaboratorAuthorization.collaboratorEmail;
	}, [careCollaboratorAuthorization]);

	return (
		<Box
			sx={{
				backgroundColor: palette.gray.light,
				padding: "1rem",
				borderRadius: "1rem",
				// mb: 1,
			}}
		>
			<Stack sx={{ alignItems: "center", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" } }}>
				<Stack sx={{ flex: "1", justifyContent: "flex-end", flexDirection: { xs: "column", sm: "row" } }}>
					<Box sx={{ pr: { xs: 0, sm: 4 }, textAlign: "left", flexGrow: 1 }}>
						<Typography sx={{ fontWeight: 700 }}>Sent To:</Typography>
						<Typography>
							{styleConverter.textColorMarkupX(
								`<<${displayName}>>	on <<${dateConverter["YYYY-MM-DD_to_mM/dD/YY"](careCollaboratorAuthorization.createdAt)}>>`
							)}
						</Typography>
					</Box>
					<Box style={{ textAlign: "left", width: "8rem" }}>
						<Typography sx={{ fontWeight: 700 }}>Status:</Typography>
						<Typography
							sx={{
								color: careCollaboratorAuthorization.isAuthorized ? palette.core.forestgreen : palette.error.main,
							}}
						>
							{careCollaboratorAuthorization.status}
						</Typography>
					</Box>
				</Stack>
				<Stack
					sx={{
						flexDirection: { xs: "column", sm: "row" },
						justifyContent: "flex-end",
					}}
				>
					{options}
				</Stack>
			</Stack>
			<SimpleModal
				open={activateRevokeModal}
				title="Revoke Care Collaboration"
				onClose={() => {
					set_activateRevokeModal(false);
				}}
			>
				<Button
					color="error"
					fullWidth={true}
					variant="outlined"
					sx={{ mb: 1, mt: 2 }}
					onClick={async () => {
						await revokeInvitedCareCollaborator({ collaboratorEmail: careCollaboratorAuthorization.collaboratorEmail });
						account.refreshCollaborators();
						set_activateRevokeModal(false);
					}}
				>
					Confirm
				</Button>
				<Button
					variant="outlined"
					fullWidth={true}
					onClick={async () => {
						set_activateRevokeModal(false);
					}}
				>
					Cancel
				</Button>
			</SimpleModal>
		</Box>
	);
}

export { SentCareColloboraterAuth };
