import { Box } from "@mui/material";
import { ModelsExplanationOfBenefitProviderAssessment_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";
import { ProviderHistoryTile } from "common/components/Tiles/ProviderHistoryTile";
import { CONVERT_DoctorRecord_Entity, CREATE_UNKNOWN_DoctorRecord_Entity } from "common/definitions/EntityDefinitions";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { useMemo } from "react";
import { ModelsDoctorRecord } from "../../../api/backend.schemas";

type HealthSnapshotProvidersPanelProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotProvidersPanel = ({ patientEOBData }: HealthSnapshotProvidersPanelProps) => {
	const providerTiles = useMemo(() => {
		if (patientEOBData.data?.data?.providerAssessments) {
			let npis = Object.keys(patientEOBData.data.data.providerAssessments);
			let providerLookup: { [key: string]: ModelsDoctorRecord } = {};
			npis.forEach((npi) => {
				let x = (patientEOBData.data?.data?.providerAssessments as CIM)[npi];
				if (x && x.doctorRecord) {
					providerLookup[npi] = x.doctorRecord;
				}
			});

			let providerAssessments: ModelsExplanationOfBenefitProviderAssessment_Extended[] = Object.keys(patientEOBData.data?.data?.providerAssessments).map(
				(npi, i) => {
					return (patientEOBData.data?.data?.providerAssessments as CIM)[npi];
				}
			);
			providerAssessments.sort((a: ModelsExplanationOfBenefitProviderAssessment_Extended, b: ModelsExplanationOfBenefitProviderAssessment_Extended) => {
				let al: any = a.dates?.length;
				let bl: any = b.dates?.length;
				if (!al || !bl) {
					if (!al && !bl) return 0;
					if (!al) return 1;
					return -1;
				}
				al = (a.dates as CIM)[(a.dates?.length as CIM) - 1];
				bl = (b.dates as CIM)[(b.dates?.length as CIM) - 1];
				if (al > bl) return -1;
				if (bl > al) return 1;
				return 0;
			});

			return providerAssessments.map((assessedProvider, i) => {
				// let dateOfLastVisitText = undefined;

				// if (assessedProvider.dates) {
				// 	dateOfLastVisitText = (
				// 		<Typography sx={{ color: palette.gray.dark }}>
				// 			{`Last Recorded Visit: ${dateConverter["YYYY-MM-DD_to_mM/dD/YY"](assessedProvider.dates[assessedProvider.dates.length - 1])}`}
				// 		</Typography>
				// 	);
				// }

				if (assessedProvider && assessedProvider?.doctorRecord) {
					let prov_ent = CONVERT_DoctorRecord_Entity(assessedProvider.doctorRecord);
					return (
						<Box key={i} sx={{ paddingBottom: ".5rem" }}>
							<ProviderHistoryTile
								key={assessedProvider.doctorRecord.npi}
								providerEntity={prov_ent}
								provAssessment={assessedProvider}
								// detailsSection={
								// 	<ProviderAssessmentDetailsSection providerEntity={prov_ent} provAssessment={assessedProvider} providerLookup={providerLookup} />
								// }
							/>
						</Box>
					);
				} else {
					return (
						// Extent of changes - decided to create specific component rather than over-complicated SearchResultTile now that the provider display has diverged significantly
						<Box sx={{ paddingBottom: ".5rem" }}>
							<ProviderHistoryTile
								key={i}
								providerEntity={CREATE_UNKNOWN_DoctorRecord_Entity({ npi: assessedProvider.providerNpi })}
								provAssessment={assessedProvider}
								// detailsSection={<ProviderAssessmentDetailsSection provAssessment={assessedProvider} providerLookup={providerLookup} />}
							/>
						</Box>
					);
				}
			});
		} else {
			return "No Results";
		}
	}, [patientEOBData]);

	return (
		<Box style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<LoadMorePaginator
					pageSize={5}
					data={(providerTiles as any[]) ?? null}
					renderItem={(tile: any, i?: number) => {
						return tile;
					}}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

export { HealthSnapshotProvidersPanel };
