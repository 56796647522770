import { Box, Typography } from "@mui/material";
import { QueryStatusDisplay } from "common/components/Loaders/QueryStatusDisplay";
import { ModelsExplanationOfBenefitDiagnosisAssessment } from "api";
import { useMemo } from "react";
import { Entity } from "common/definitions/EntityDefinitions";
import { ModelsExplanationOfBenefitDiagnosisAssessment_Extended } from "api_extended/consumer-patient-health-data/consumer-patient-health-data_extended";
import { convertDatesToChartDataByMonth } from "common/utilities/chartDataConverters";
import StandardLineChart from "common/components/Charts/StandardLineChart";
import { dateConverter } from "common/utilities/dateConverter";
import { useQ_retrievePatientEOBDataX_Response } from "common/requests/patient_data/useQ_retrievePatientEOBData";
import { ConditionHistoryTile } from "common/components/Tiles/ConditionHistoryTile";
import { LoadMorePaginator } from "common/components/Paginator/LoadMorePaginator";

type HealthSnapshotConditionsPanelProps = {
	patientEOBData: useQ_retrievePatientEOBDataX_Response;
};

const HealthSnapshotConditionsPanel = ({ patientEOBData }: HealthSnapshotConditionsPanelProps) => {
	const conditions = useMemo(() => {
		let c: Entity[] = [];

		if (patientEOBData?.data?.data?.diagnosisAssessments) {
			let diagnosisAssessments = patientEOBData?.data?.data?.diagnosisAssessments;
			Object.keys(diagnosisAssessments).forEach((k: string) => {
				let x: ModelsExplanationOfBenefitDiagnosisAssessment_Extended = diagnosisAssessments[k] as ModelsExplanationOfBenefitDiagnosisAssessment;
				if (x.ICD10Definition) {
					c.push({
						entityTypeName: "condition",
						imageSrc: "",
						title: x.ICD10Definition.Description,
						descriptor: `First Diagnosed: ${dateConverter["YYYY-MM-DD_to_mM/dD/YY"](x.dates?.[0])}`,
						actions: [], // "view_care_path", "learn_more", "find_doctor"
						displayInfo: [
							{ title: "Diagnosis Count", value: x.count },
							{ title: "Est. Total Payments", value: x.paymentAmountTotal, colorType: "USD", dataType: "USD" },
						],
						_data: x,
					} as Entity);
				} else {
					window.RadiusCare.log_issue({
						file: "HealthSnapshotConditionsPanel",
						message: `missing x.ICD10Definition for ${x.diagnosisCode}`,
						details: x,
					});
				}
			});
		}

		c.sort((a: Entity, b: Entity) => {
			let al = a._data.dates?.length;
			let bl = b._data.dates?.length;
			if (!al || !bl) {
				if (!al && !bl) return 0;
				if (!al) return 1;
				return -1;
			}
			al = a._data.dates[0];
			bl = b._data.dates[0];
			if (al > bl) return -1;
			if (bl > al) return 1;
			return 0;
		});

		return c;
	}, [patientEOBData.data?.data]);

	const conditionTiles = useMemo(() => {
		return conditions.map((conditionEntity, i) => {
			return (
				<Box key={i} sx={{ paddingBottom: ".5rem" }}>
					<ConditionHistoryTile key={i} conditionEntity={conditionEntity} conditionAssessment={conditionEntity._data} />
				</Box>
			);
		});
	}, [conditions]);
	return (
		<Box style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch" }}>
			<QueryStatusDisplay queryResult={patientEOBData}>
				<LoadMorePaginator
					pageSize={10}
					data={conditionTiles}
					renderItem={(providerTile: any, i?: number) => {
						return providerTile;
					}}
				/>
			</QueryStatusDisplay>
		</Box>
	);
};

export { HealthSnapshotConditionsPanel };

const ConditionAssessmentDetailsSection = ({ assessment }: { assessment: ModelsExplanationOfBenefitDiagnosisAssessment }) => {
	let chartData;
	if (assessment.dates) {
		chartData = convertDatesToChartDataByMonth(assessment.dates);
	}
	return (
		<Box>
			<Typography component="div" variant="subsection-title" style={{ textAlign: "left", margin: "16px 0px" }}>
				Diagnosis History
			</Typography>

			<Box style={{ width: "100%", minHeight: "100px" }}>
				{chartData ? (
					<StandardLineChart
						data={chartData}
						xKey={"yyyymm"}
						yKeys={[{ dataKey: "count" }]}
						responsiveHeight={100}
						keyLabels={{ count: "Diagnosis Occurrences" }}
						xTickLine
						xTickFormatter={(x, y) => {
							return dateConverter["YYYY-MM_to_mM/YY"](x);
						}}
						yDomain={[0, "dataMax" as CIM]}
						dotSize={3}
					/>
				) : null}
			</Box>
		</Box>
	);
};

export { ConditionAssessmentDetailsSection };
